import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {BigInfoData} from '../layout/infoblocks/big-info-detail/big-info-data';

@Injectable()
export class HomeDataService {

  homeData: Subject<BigInfoData[]> = new BehaviorSubject<BigInfoData[]>(null);

  constructor() {
  }

  public getInfoBlocks(): Subject<BigInfoData[]> {
    this.initHomedata();
    return this.homeData;
  }
  private initHomedata(): void {
    // Get from Rest API?
    const blocks: BigInfoData[] = new Array<BigInfoData>();
    blocks.push({type: 'text', data: 'Ontdek Somtoday Connect. Met Somtoday Connect heb je de regie over met ' +
        'welke applicaties jouw school veilig ' +
        'koppelt en kan je dit volledig beheren. Daarbij geven we je met eenvoudige dashboards handvatten' +
        ' om leermiddelen optimaal in te zetten. ' +
        'Zo kun je sturen op het gebruik van deze leermiddelen en je inkoopbeleid afstemmen op de behoefte.' +
        'Om de school de regie te geven over het effectief inzetten van digitale leermiddelen\n' +
        'in de klas introduceren wij Somtoday Connect. Somtoday Connect bevat onder\n' +
        'andere een dashboard dat de activatiegraad en het gebruik van digitale leermiddelen in\n' +
        'de school toont. Zo behoud je grip en kun je actief monitoren en sturen op digitaal\n' +
        'onderwijs. Om Somtoday Connect goed aan te laten sluiten op de behoefte hebben wij\n' +
        'met 15 pilotscholen samengewerkt die hebben geholpen de koers te bepalen.\n'});
    blocks.push({type: 'image', data: 'assets/img/tablet.png'});
    blocks.push({type: 'text', data: 'Nog geen toegang tot Somtoday Connect? Neem contact op ' +
        'met uw relatiebeheerder.'});

    this.homeData.next(blocks);
  }
}

