import { Component, OnInit } from '@angular/core';
import {TableCellComponent} from '../table/table.model';
import {waarschuwing} from '../../services/activatie.service';

@Component({
  selector: 'app-geactiveerd-totaal',
  templateUrl: './geactiveerd-totaal.component.html',
  styleUrls: ['./geactiveerd-totaal.component.scss']
})
export class GeactiveerdTotaalComponent implements TableCellComponent<number> {
  data: number;
}
