import {BaseFacade} from '../../../state/base.facade';
import {Store} from '@ngrx/store';
import {AppState} from '../../../state/app.state';
import {selectKlasdataPerLeerlingMetNaam, selectRMedewerkersVanKlas, selectSelectie, selectVorigeEnVolgendeLesgroep} from './klassendashboard.selector';
import {selectLoadLeerlingen} from '../leerlingen/leerlingen.selector';
import {Observable} from 'rxjs';
import {loadLeerlingen} from '../schooldashboard/schooldashboard.actions';
import {LesgroepDetailData} from '../../klassendashboard/klassendashboard.model';
import {filter, map, withLatestFrom} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import { RMedewerker } from 'src/app/services/somtoday.service';

@Injectable()
export class KlassendashboardFacade extends BaseFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }

  selectKlasdataPerLeerlingMetNaam(): Observable<[LesgroepDetailData, boolean]> {
    return this.selectWithDatumProp(selectKlasdataPerLeerlingMetNaam).pipe(
        filter(v => ![null, undefined].includes(v)),
        withLatestFrom(this.select(selectLoadLeerlingen))
      );
  }

  loadLeerlingen() {
    this.dispatchDatumAction(loadLeerlingen);
  }

  selectVorigeEnVolgendeLesgroep<T>(transform: (v: string) => T): Observable<[T, T]> {
    return this.select(selectVorigeEnVolgendeLesgroep)
      .pipe(
        filter(({vorige, volgende}) => ![vorige, volgende].includes(undefined)),
        map(({vorige, volgende}) => [transform(vorige), transform(volgende)])
      );
  }

  selectSelectie(): Observable<string> {
    return this.select(selectSelectie);
  }

  public selectMedewerkers(): Observable<RMedewerker[]> {
    return this.select(selectRMedewerkersVanKlas);
  }
}
