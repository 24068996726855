<div class="controls">
  <div class="groep">
    <div>Groepeer op:</div>
    <app-toggle-buttons [trueLabel]="trueLabel" [falseLabel]="falseLabel" [grayed]="grayed"
                        (changed)="onGroeperingChanged($event)"
                        [state]="state">
    </app-toggle-buttons>
  </div>
  <app-datumbereik [studiewijzerdb]="studiewijzer"></app-datumbereik>
</div>
