import {AfterViewChecked, Component, ElementRef, ViewChild} from '@angular/core';
import {TableCellComponent} from '../table/table.model';

@Component({
  selector: 'app-key-cell',
  templateUrl: './key-cell.component.html',
  styleUrls: ['./key-cell.component.scss']
})
export class KeyCellComponent implements TableCellComponent<string>, AfterViewChecked {
  data: string;
  tooltip: string;

  @ViewChild('ref', {static: true})
  private ref: ElementRef;

  ngAfterViewChecked(): void {
    const div = this.ref && this.ref.nativeElement;
    if (div && div.clientWidth > 0) {
      div.style.width = div.clientWidth + 'px';
      div.style.whiteSpace = 'nowrap';
      if (div.clientWidth < div.scrollWidth) {
        this.tooltip = this.data;
      }
    }
  }
}
