import {Component, Input} from '@angular/core';
import {TableCellComponent} from '../table/table.model';
import {RangelinechartCellModel} from './rangelinechart-cell.model';

@Component({
  selector: 'app-rangelinechart-cell',
  templateUrl: './rangelinechart-cell.component.html',
  styleUrls: ['./rangelinechart-cell.component.scss']
})
export class RangelinechartCellComponent implements TableCellComponent<RangelinechartCellModel> {
  @Input()
  data: RangelinechartCellModel;
}
