import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import {StudiewijzerKleuren} from '../studiewijzer-kleuren';

@Component({
  selector: 'app-studiewijzer-kleuren-demo',
  templateUrl: './studiewijzer-kleuren-demo.component.html',
  styleUrls: ['./studiewijzer-kleuren-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudiewijzerKleurenDemoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  public studiewijzerKleuren(): string[] {
    return StudiewijzerKleuren.getKleuren();
  }

}
