import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {combineLatest} from 'rxjs';
import {map} from 'rxjs/operators';
import {AppState} from '../../state/app.state';
import {Store} from '@ngrx/store';
import {selectVestigingBevatLicenties} from '../../old-dashboarding/state/schooldashboard/schooldashboard.selectors';
import {selectVestigingBevatStudiewijzers} from '../../old-dashboarding/state/studiewijzerlinkjes/studiewijzerlinkjes.selectors';

@Component({
  selector: 'app-placeholder',
  templateUrl: './placeholder.component.html',
  styleUrls: ['./placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlaceholderComponent {
  @Input()
  studiewijzerdb: boolean;

  vestigingBevatData = combineLatest([this.store.select(selectVestigingBevatLicenties),
    this.store.select(selectVestigingBevatStudiewijzers)]).pipe(
      map(([licenties, studiewijzers]) => this.studiewijzerdb ? studiewijzers : licenties));

  get licentieOfStudiewijzer(): string {
    return this.studiewijzerdb ? 'actieve studiewijzers' : 'digitale licenties';
  }

  constructor(private store: Store<AppState>) { }
}
