import {AfterViewInit, Directive, HostListener, OnInit, ViewContainerRef} from '@angular/core';

@Directive({
  selector: '[appFillScreen]'
})
export class FillScreenDirective implements AfterViewInit {
  constructor(private viewContainerRef: ViewContainerRef) {
  }

  ngAfterViewInit(): void {
    this.fillScreen();
  }

  private fillScreen() {
    const el = this.viewContainerRef.element.nativeElement as HTMLElement;
    if (window.innerHeight === window.document.documentElement.scrollHeight) {
      const bodyTop = window.document.body.getBoundingClientRect().top;
      const elTop = el.getBoundingClientRect().top;
      const preventScrollCorrection = 25;
      const height = window.innerHeight - bodyTop - elTop - preventScrollCorrection;
      el.style.minHeight = height + 'px';
    }
  }

  @HostListener('window:resize')
  onResize() {
    this.fillScreen();
  }
}
