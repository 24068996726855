import { Component } from '@angular/core';
import {TableCellComponent} from '../table/table.model';

@Component({
  selector: 'app-icon-header-cell',
  templateUrl: './icon-header-cell.component.html',
  styleUrls: ['./icon-header-cell.component.scss']
})
export class IconHeaderCellComponent implements TableCellComponent<string> {
  data: string;
}
