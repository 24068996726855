import {Component, OnInit, ChangeDetectionStrategy, Input} from '@angular/core';
import {ExportoverzichtFacade} from '../state/exportoverzicht/exportoverzicht.facade';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginatorComponent implements OnInit {

  private PAGE_BREAKPOINT = 9;

  public activePage = 1;

  @Input()
  public pages: number;

  constructor(private facade: ExportoverzichtFacade) { }

  ngOnInit(): void {
  }

  public pageSelected(page: number): void {
    if (page) {
      this.activePage = page;
      this.facade.setPage(page);
    }
  }

  public getPages(): (number | undefined)[] {
    if (this.pages < this.PAGE_BREAKPOINT) {
      // gewoon een array van de getallen
      return Array.from(Array(this.pages).keys()).map(x => x + 1);
    }

    // hoe de array van getallen eruit komt te zien als knopjes (n = activePage, m = pages):
    // standaard: 1, 2, ..., n-2, n-1, n-0, n+1, n+2, ..., m-1, m)
    // als n > 0 && n < 5, dan 1, 2, 3, 4, 5, ..., m-1, m
    // als n > m-4 && n <= m , dan 1, 2, ..., m-4, m-3, m-2, m-1, m
    if (this.activePage > 0 && this.activePage <= Math.floor(this.PAGE_BREAKPOINT / 2)) {
      return [1, 2, 3, 4, 5, undefined, this.pages - 1, this.pages];
    }

    if (this.activePage > this.pages - Math.floor(this.PAGE_BREAKPOINT / 2)) {
      return [1, 2, undefined, this.pages - 4, this.pages - 3, this.pages - 2, this.pages - 1, this.pages];
    }

    return [1, 2, undefined, this.activePage - 2, this.activePage - 1, this.activePage, this.activePage + 1, this.activePage + 2,
      undefined, this.pages - 1, this.pages];
  }
}
