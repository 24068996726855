import {compose, createSelector} from '@ngrx/store';
import {selectSchooldashboard} from '../../../state/app.state';
import {RangeLinechartModel} from '../../../layout/range-linechart/range-linechart.model';
import {RangeModel} from '../../../layout/model/range.model';
import {RMedewerker} from '../../../services/somtoday.service';
import {selectVak} from '../../../state/router/router.selectors';

export interface LesGroepDagGebruikData {
  vak: string;
  onderwijssoort: string;
  lesgroepNaam: string;
  lesgroepUuid: string;
  lesgroepLeerjaar: number;
  leerlingenAantal: number;
  leerlingenGeactiveerd: number;
  waarvanActief: number;
  lesgroepDagData: RangeLinechartModel[];
  gebruikPerLeerlingPerWeek: number;
  docentUUIDs: string[];
  docenten: RMedewerker[];
}

export interface LesGroepDagGebruikTotaal {
  vak: string;
  onderwijssoort: string;
  leerlingenAantal: number;
  leerlingenGeactiveerd: RangeModel;
  waarvanActief: RangeModel;
  lesgroepDagData: RangeLinechartModel[];
  gebruikPerLeerlingPerWeek: RangeModel;
}

export interface VakDataParams {
  vak: string;
  onderwijssoort: string;
}

export interface VakData {
  data: LesGroepDagGebruikData[];
  totaal: LesGroepDagGebruikTotaal;
  nav: {
    vorige: VakDataParams,
    volgende: VakDataParams
  };
  hoofdniveau: string;
  subniveau: string;
}

export const selectVakData =
  appState => appState.schooldashboard.berekendeVakData;

const mapBy = <V, K>(getKey: (item: V) => K) => (items: V[]): Map<K, V> => items.reduce((a, v) => a.set(getKey(v), v), new Map<K, V>());

const join = <K, L, V>(keys: Map<K, L[]>) => (values: Map<L, V>) => [...keys.entries()]
  .reduce((a, [k, v]) => a.set(k, v.map(vv => values.get(vv)).filter(Boolean)), new Map<K, V[]>());

export const selectLesgroepMedewerkers = createSelector(
  selectSchooldashboard,
  ({docenten, medewerkers}) => compose(
    join(new Map<string, string[]>(Object.entries(docenten))),
    mapBy<RMedewerker, string>(v => v.UUID)
  )(medewerkers)
);

export const selectVakDataMetDocenten = createSelector(
  selectVakData,
  selectLesgroepMedewerkers,
  (vakData, docenten, _): VakData => ({
    ...vakData,
    data: vakData.data.map(item => ({...item, docenten: docenten.get(item.lesgroepUuid) || []}))
  })
);

export const selectLesgroepen = createSelector(
  selectSchooldashboard,
  ({ruweSchooldashboardData}) => [...new Set(ruweSchooldashboardData.ruweVakData.map(d => d.lesgroepUuid).filter(Boolean))]
);

export const selectDocentenVanVak = createSelector(
  selectSchooldashboard,
  selectVak,
  ({ruweSchooldashboardData, docenten}, vak) => {
    return [...new Set<string>(
      ruweSchooldashboardData.ruweVakData
        .filter(v => v.vak === vak)
        .map(v => docenten[v.lesgroepUuid] || [])
        .reduce((a, v) => [...a, ...v], []))
    ];
  }
);
