import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import {environment} from '../environments/environment';

@Injectable()
export class Oauth2TokenInterceptor implements HttpInterceptor {

  constructor(public oAuthService: OAuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const credentials = this.oAuthService.getAccessToken();
    const idtoken = this.oAuthService.getIdToken();
    if (credentials) {
      const authHeaders = {
        Authorization: `Bearer ${credentials}`
      };
      if (idtoken && request.url && (request.url.startsWith(environment.apiUrl) || request.url.startsWith(environment.dashboardApiUrl))) {
        authHeaders['X-Userinfo'] = idtoken;
      }
      request = request.clone({
        setHeaders: authHeaders
      });
    }

    return next.handle(request);
  }
}
