import { AVGSemScope } from '../../generated/avg-client';

export const semScopeDescriptions: Scope[] = [
    {
        name: 'Student',
        fields: [{
                name: 'eck-id'
            }, {
                name: 'voornaam'
            }, {
                name: 'achternaam'
            }, {
                name: 'voorvoegsels'
            }, {
                name: 'school',
                description: 'Naam en SchoolId van de Vestiging waarop de leerling geplaatst is.'
            }, {
                name: 'vakkeuzes',
                description: 'Welke vakken de leerling volgt.'
            }
        ],
        mappedScope: AVGSemScope.SisStudent
    }, {
        name: 'Teacher',
        fields: [{
                name: 'eck-id'
            }, {
                name: 'voornaam'
            }, {
                name: 'achternaam'
            }, {
                name: 'voorvoegsels'
            }, {
                name: 'school',
                description: 'Naam en SchoolId van de Vestiging waarop de docent werkzaam is.'
            }, {
                name: 'lesgroepen'
            }
        ],
        mappedScope: AVGSemScope.SisTeacher
    }, {
        name: 'Group',
        fields: [{
                name: 'naam'
            }, {
                name: 'school',
                description: 'Naam en SchoolId van de Vestiging van de lesgroep.'
            }, {
                name: 'leerlingen',
                description: 'Namen en eck-ids van de leerlingen in de lesgroep.'
            }, {
                name: 'docenten',
                description: 'Namen en eck-ids van de docenten van de lesgroep.'
            }, {
                name: 'schooljaar'
            }
        ],
        nestings: [{
                name: 'vakken',
                fields: [{
                        name: 'vakcode'
                    }, {
                        name: 'afkorting'
                    }, {
                        name: 'naam'
                    }, {
                        name: 'niveau',
                        description: 'Bijvoorbeeld HAVO-2 of Technasium-1.'
                    }, {
                        name: 'onderwijssoorten'
                    }
                ]
            }
        ],
        mappedScope: AVGSemScope.SisGroup
    }, {
        name: 'Student-teacher-delivery',
        fields: [{
                name: 'eck-id'
            }, {
                name: 'geboortedatum'
            }, {
                name: 'email adres'
            }, {
                name: 'postcode'
            }, {
                name: 'straat'
            }, {
                name: 'huisnummer'
            }, {
                name: 'plaats'
            }, {
                name: 'land'
            }, {
                name: 'voornaam'
            }, {
                name: 'achternaam'
            }, {
                name: 'voorvoegsels'
            }
        ],
        mappedScope: AVGSemScope.SisStudentTeacherDelivery
    }];

export interface Scope {
    name: string;
    fields: Field[];
    nestings?: Scope[];
    mappedScope?: AVGSemScope;
}

export interface Field {
    name: string;
    description?: string;
}