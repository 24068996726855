import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  HostListener,
  Input,
  OnDestroy
} from '@angular/core';
import {BehaviorSubject, combineLatest, Subscription} from 'rxjs';
import { PopoutButtonViewModel } from '../popout-button/popout-button.component';
import { SelectLeerlingenViewModel } from '../select-leerlingen/select-leerlingen-component-view-model';
import {SelectVestigingenViewModel} from '../select-vestigingen/select-vestigingen.component';
import { KoppelingAanmakenStap1ComponentViewModel, KoppelingAanmakenStap1ComponentViewModelParent } from './koppeling-aanmaken-stap1-component-view-model';
import {map, withLatestFrom} from 'rxjs/operators';

@Component({
    selector: 'app-koppeling-aanmaken-stap1',
    templateUrl: './koppeling-aanmaken-stap1.component.html',
    styleUrls: ['./koppeling-aanmaken-stap1.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class KoppelingAanmakenStap1Component implements OnInit, OnDestroy {
    @ViewChild('vestigingSelectie') vestigingSelectie: ElementRef;

    @ViewChild('leerlingenSelectie') leerlingSelectie: ElementRef;

    @Input()
    public viewModel: KoppelingAanmakenStap1ComponentViewModelParent;

    public componentViewModel: KoppelingAanmakenStap1ComponentViewModel;

    public vestigingenViewModel: SelectVestigingenViewModel & PopoutButtonViewModel;

    public leerlingenViewModel: SelectLeerlingenViewModel & PopoutButtonViewModel;

    public geselecteerdeVestigingen: string[] = [];

    public geselecteerdeVestigingenToDeleteInfoBlock: string = null;

    public selectedOnderwijssoorten: string = '';

    public selectedLeerjaren: string = '';

    public selectedVakken: string = '';

    public toonVestigingFilters: boolean = false;

    public isSelecteerAlleLeerlingen: boolean = true;

    public toonLeerlingFilters: boolean = false;

    private subscriptions: Subscription[] = [];

    ngOnInit(): void {
        this.componentViewModel = new KoppelingAanmakenStap1ComponentViewModel(this.viewModel);

        this.vestigingenViewModel = {
            onGetVestigingen: this.componentViewModel.onGetVestigingenSelectie,
            onGetIsOpen: this.componentViewModel.onGetIsVestigingSelectieOpen,
            doSetIsOpen: this.componentViewModel.doSetIsVestigingSelectieOpen,
            onGetCaption: new BehaviorSubject('Vestigingen selecteren'),
            onGetIconUrl: new BehaviorSubject('../../assets/img/icons/Symbool/Locatie.svg'),
            doUpdateSelection: this.componentViewModel.doSetVestigingenSelectie,
            contentIsLoaded: new BehaviorSubject(false)
        };

        this.leerlingenViewModel = {
            onGetAlleOnderwijssoorten: this.componentViewModel.onGetAlleOnderwijssoorten,
            onGetSelection: this.componentViewModel.onGetLeerlingFilters,
            doSetIsOpen: this.componentViewModel.doSetIsLeerlingSelectieOpen,
            doSubmitSelection: this.componentViewModel.doSubmitLeerlingFilters,
            onGetCaption: new BehaviorSubject('Leerlingen filteren'),
            onGetIconUrl: new BehaviorSubject('../../assets/img/icons/Symbool/Leerlingen_groen.svg'),
            onGetIsOpen: this.componentViewModel.onGetIsLeerlingSelectieOpen,
            contentIsLoaded: new BehaviorSubject(false)
        };

        this.subscriptions.push(this.viewModel.onGetVestigingenSelectie.subscribe(rows => {
            this.geselecteerdeVestigingen = rows?.filter(r => r.selected).map(r => r.vestiging.naam);
            this.toonVestigingFilters = this.geselecteerdeVestigingen !== null && this.geselecteerdeVestigingen !== undefined && this.geselecteerdeVestigingen.length > 0;
        }));

        this.componentViewModel.doSetWindowHeight.next(window.innerHeight);

        this.subscriptions.push(this.componentViewModel.doSetIsVestigingSelectieOpen.subscribe(_ => this.componentViewModel.doSetVestigingPanelOffset.next(this.vestigingSelectie?.nativeElement?.getBoundingClientRect()?.y)));
        this.subscriptions.push(this.componentViewModel.doSetIsLeerlingSelectieOpen.subscribe(_ => this.componentViewModel.doSetLeerlingPanelOffset.next(this.leerlingSelectie?.nativeElement?.getBoundingClientRect()?.y)));

        this.subscriptions.push(this.viewModel.onGetLeerlingFilters.subscribe(filters => {
            this.toonLeerlingFilters = filters.validSelection;
            this.selectedOnderwijssoorten = filters.alleOnderwijssoortenGeselecteerd ? 'Alle onderwijssoorten' : filters.onderwijssoortNamen.join(', ');
            this.selectedLeerjaren = filters.alleLeerjarenGeselecteerd ? 'Alle leerjaren' : (filters.leerjaren.length > 1 ? 'Leerjaren ' : 'Leerjaar ') + filters.leerjaren.join(', ');
            this.selectedVakken = filters.alleVakkenGeselecteerd ? 'Alle vakken' : filters.vakNamen.join(', ');
        }));

        this.subscriptions.push(combineLatest([this.viewModel.onGetLeerlingFilters, this.viewModel.onGetVestigingenSelectie, this.componentViewModel.doSetIsSelectAlleLeerlingen]).pipe(
          withLatestFrom(this.componentViewModel.onGetVestigingenSelectieFiltered)
        ).subscribe(
          ([[l, vRows, alleLeerlingenGeselecteerd], vRowsFiltered]) => {
            this.componentViewModel.doSetVestigingenSelectieFiltered.next(vRows);
            if (!(l.alleOnderwijssoortenGeselecteerd && l.alleLeerjarenGeselecteerd && l.alleVakkenGeselecteerd) && !alleLeerlingenGeselecteerd) {
              vRows
                .filter(v => v.selected)
                .map(v => v.vestiging)
                .map(v => {
                  let deleteVestiging = false;
                  const os = v.aangebodenOnderwijssoorten;
                  if (!l.alleOnderwijssoortenGeselecteerd) {
                    const result = os.map(x => x.afkorting)
                      .filter(x => l.geselecteerdeOnderwijssoorten.includes(x));
                    deleteVestiging = (result.length === 0);
                  }
                  if (!l.alleLeerjarenGeselecteerd && !deleteVestiging) {
                    const result = os.map(x => x.leerjaren)
                      .reduce((x, y) => x.concat(y), [])
                      .filter(x => l.geselecteerdeLeerjaren.includes(x));
                    deleteVestiging = (result.length === 0);
                  }
                  if (!l.alleVakkenGeselecteerd && !deleteVestiging) {
                    const result = os.map(x => x.aangebodenVakken)
                      .reduce((x, y) => x.concat(y), [])
                      .reduce((x, y) => x.concat(y.UUIDs), [] as string[])
                      .filter(x => l.geselecteerdeVakken.includes(x));
                    deleteVestiging = (result.length === 0);
                  }
                  const updatedVRowsFiltered = vRowsFiltered.map(row => (row.vestiging.UUID === v.UUID) ? {...row, selected: !deleteVestiging} : row);
                  this.componentViewModel.doSetVestigingenSelectieFiltered.next(updatedVRowsFiltered);
                  vRowsFiltered = updatedVRowsFiltered;
                });
            }
        }));

        this.subscriptions.push(
          combineLatest([this.viewModel.onGetVestigingenSelectieFiltered, this.viewModel.onGetVestigingenSelectie]).pipe(
          map(([filteredVestigingen, vestigingen]) => {
            filteredVestigingen = filteredVestigingen.filter(fv => !fv.selected);
            return vestigingen.filter(v => v.selected).filter(v => filteredVestigingen.find(fv => fv.vestiging.UUID === v.vestiging.UUID));
          })
        ).subscribe( (vestigingenToDelete) => {
            if (vestigingenToDelete.length === 1) {
              this.geselecteerdeVestigingenToDeleteInfoBlock = 'Er zijn geen leerlingen op ' +
                vestigingenToDelete.map(v => v.naam)[0] +
                ' die onder het gekozen filter vallen. Daarom wordt er voor deze vestiging geen koppeling aangemaakt of bewerkt.';
            } else if (vestigingenToDelete.length > 1) {
              this.geselecteerdeVestigingenToDeleteInfoBlock = 'Er zijn geen leerlingen op ' +
                vestigingenToDelete.map(v => v.naam).join(', ').replace(new RegExp(',([^,]*)$'), ' en$1') +
                ' die onder het gekozen filter vallen. Daarom worden er voor deze vestigingen geen koppelingen aangemaakt of bewerkt.';
            } else {
              this.geselecteerdeVestigingenToDeleteInfoBlock = null;
            }
        }));

        this.subscriptions.push(this.componentViewModel.doSetIsSelectAlleLeerlingen.subscribe(v => this.isSelecteerAlleLeerlingen = v));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.componentViewModel.onDestroy();
    }

    public openVestigingenSelectiePanel(panelMustOpen: boolean): void {
        this.componentViewModel.doSetIsVestigingSelectieOpen.next(panelMustOpen);
    }

    public selecteerAlleLeerlingen(selectAlleLeerlingen: boolean): void {
        this.isSelecteerAlleLeerlingen = selectAlleLeerlingen;
        this.componentViewModel.doSetIsSelectAlleLeerlingen.next(selectAlleLeerlingen);
        this.componentViewModel.doSetToonLeerlingSelectie.next(!selectAlleLeerlingen);
        this.componentViewModel.doSubmitLeerlingFilters.next({...this.componentViewModel.doSubmitLeerlingFilters.getValue(), alleLeerlingenGeselecteerd: selectAlleLeerlingen});
    }

    public openLeerlingenSelectiePanel(panelMustOpen: boolean): void {
        this.componentViewModel.doSetIsLeerlingSelectieOpen.next(panelMustOpen);
    }

    @HostListener('window:resize', ['$event'])
    onWindowResize(): void {
        this.componentViewModel.doSetWindowHeight.next(window.innerHeight);

        this.componentViewModel.doSetVestigingPanelOffset.next(this.vestigingSelectie?.nativeElement?.getBoundingClientRect()?.y);
        this.componentViewModel.doSetLeerlingPanelOffset.next(this.leerlingSelectie?.nativeElement?.getBoundingClientRect()?.y);
    }

    @HostListener('window:scroll', ['$event'])
    onWindowScroll(): void {
        this.componentViewModel.doSetScrollOffset.next(window.pageYOffset);

        this.componentViewModel.doSetVestigingPanelOffset.next(this.vestigingSelectie?.nativeElement?.getBoundingClientRect()?.y);
        this.componentViewModel.doSetLeerlingPanelOffset.next(this.leerlingSelectie?.nativeElement?.getBoundingClientRect()?.y);
    }
}
