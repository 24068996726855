import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

@Component({
    selector: 'app-input-radiobutton',
    templateUrl: './input-radiobutton.component.html',
    styleUrls: ['./input-radiobutton.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputRadiobuttonComponent implements OnInit {

    public radioClass: Observable<string>;
    
    public labelClass: Observable<string>;

    @Input()
    public viewModel: InputRadiobuttonComponentViewModel;

    constructor() { }

    ngOnInit(): void {
        this.radioClass = this.viewModel.onGetActive.pipe(map(a => a ? 'radiobutton-active' : 'radiobutton-inactive'));
        this.labelClass = this.viewModel.onGetActive.pipe(map(a => a ? 'bodyContentBold' : 'bodyContent'));
    }
}

export interface InputRadiobuttonComponentViewModel {
    onGetActive: Observable<boolean>;
    onGetLabel: Observable<string>;

    click: () => void;
}
