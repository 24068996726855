<div class="samenvatting">
  <div class="linkerkolom">
    <app-klassendashboard-samenvatting-sidebar [aantalLeerlingen]="store.getAantalLeerlingen() | async"
                                               [naam]="store.getLesgroepNaam() | async"
                                               [medewerkers$]="medewerkers$" >
    </app-klassendashboard-samenvatting-sidebar>
  </div>
  <div class="rechterkolom">
    <app-klassendashboard-samenvatting-grafiek [grafiekdata$]="store.getLeermiddelGrafiek() | async" [vanaf]="settings.getBegindatum() | async" [totenmet]="settings.getEinddatum() | async">
    </app-klassendashboard-samenvatting-grafiek>
    <app-klassendashboard-samenvatting-table [tabeldata$]="store.getLeermiddelTabel()" [lesgroepNaam]="store.getLesgroepNaam() | async">
    </app-klassendashboard-samenvatting-table>
  </div>
</div>
