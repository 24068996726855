import {createSelector} from '@ngrx/store';
import {selectSchooldashboard} from '../../../state/app.state';
import {RLeerling} from '../../../services/somtoday.service';
import {selectLoading} from '../../../state/request-count/request-count.selectors';

export const selectLeerlingenMap = createSelector(
  selectSchooldashboard,
  ({leerlingen}) => leerlingen.reduce((a, v) => a.set(v.UUID, v), new Map<string, RLeerling>())
);

export const selectLoadLeerlingen = createSelector(
  selectSchooldashboard,
  selectLoading,
  ({loadLeerlingen}, isLoading) => loadLeerlingen && !isLoading
);
