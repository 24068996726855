import { MenuService } from './menu.service';
import { DeviceService } from '../services/device.service';

export abstract class BaseMenu {

    constructor(protected menuService: MenuService, protected deviceService: DeviceService) { }

    public openMenu(menu: string, event?) {
        this.menuService.openMenu(menu);
        if (event) {
            event.stopPropagation();
        }
    }

    public closeMenu(menu: string, event?) {
        this.menuService.closeMenu(menu);
        if (event) {
            event.stopPropagation();
        }
    }

    public closeAllMenus(event?) {
        this.menuService.closeAllMenus();
        if (event) {
            event.stopPropagation();
        }
    }

    public onMouseEnter(menu: string, event?) {
        if (this.deviceService.isPhone()) {
            return;
        }
        this.openMenu(menu, event);
        if (event) {
            event.stopPropagation();
        }
    }

    public onMouseLeave(menu: string, event?) {
        if (this.deviceService.isPhone()) {
            return;
        }
        this.closeMenu(menu, event);
    }
}
