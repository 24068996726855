<div class="content">
    <p class="bodyContentSemi">Stap 1/3 - Doelgroep</p>
    <div class="scrollContainer">
        <div class="filters">
            <div class="vestigingSelectie" #vestigingSelectie>
                <app-popout-button *ngIf="(componentViewModel.onGetToonVestigingSelectieButton | async)"
                    [viewModel]="vestigingenViewModel">
                    <ng-template>
                        <app-select-vestigingen [viewModel]="vestigingenViewModel"></app-select-vestigingen>
                    </ng-template>
                </app-popout-button>
                <div *ngIf="toonVestigingFilters" class="geselecteerdeVestigingen">
                    <div class="filterHeader vestigingen">
                        <p class="bodyContentSemi">Vestigingen</p>
                        <div class="potlood icon" (click)="openVestigingenSelectiePanel(true)"></div>
                    </div>
                    <div class="vestigingenList">
                        <ul>
                            <ng-container *ngFor="let row of geselecteerdeVestigingen;">
                                <li>
                                    <div class="vestiging">
                                        <div class="locatie icon"></div>
                                        <p class="bodySmallContentSemi">{{row}}</p>
                                    </div>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="leerlingenSelectie" #leerlingenSelectie
                *ngIf="componentViewModel.onGetToonLeerlingenToggleButton | async">
                <p class="bodyContentSemi">Van welke leerlingen mag de data gedeeld worden?</p>
                <div class="shrinkButton">
                    <app-toggle-buttons [trueLabel]="'Alle leerlingen'" [falseLabel]="'Een deel'"
                        [state]="isSelecteerAlleLeerlingen" (changed)="selecteerAlleLeerlingen($event)">
                    </app-toggle-buttons>
                </div>

                <div *ngIf="componentViewModel.onGetToonLeerlingSelectie | async">
                    <app-popout-button *ngIf="!(componentViewModel.onGetToonGeselecteerdeLeerlingen | async)"
                        [viewModel]="leerlingenViewModel">
                        <ng-template>
                            <app-select-leerlingen [viewModel]="leerlingenViewModel"></app-select-leerlingen>
                        </ng-template>
                    </app-popout-button>
                    <div *ngIf="toonLeerlingFilters" class="geselecteerdeLeerlingen">
                        <div class="filterHeader leerlingen">
                            <p class="bodyContentSemi">Filters op leerlingen</p>
                            <div class="potlood icon" (click)="openLeerlingenSelectiePanel(true)"></div>
                        </div>
                        <app-info-blok *ngIf="geselecteerdeVestigingenToDeleteInfoBlock" [infoText]="geselecteerdeVestigingenToDeleteInfoBlock">
                        </app-info-blok>
                        <div class="commaSeparatedList">
                            <div class="mortarBoard icon"></div>
                            <p class="bodySmallContentSemi">{{selectedOnderwijssoorten}}</p>
                        </div>
                        <div class="commaSeparatedList">
                            <div class="mortarBoard icon"></div>
                            <p class="bodySmallContentSemi">{{selectedLeerjaren}}</p>
                        </div>
                        <div class="commaSeparatedList">
                            <div class="group icon"></div>
                            <p class="bodySmallContentSemi">{{selectedVakken}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="componentViewModel.onGetToonInfoBlock | async">
              <app-info-blok [infoText]="'Bij het aanmaken van deze koppeling kun je enkel aangeven welke datavelden de koppelpartij kan ontvangen. Je kunt niet aangeven voor welke specifieke doelgroep deze data wordt gedeeld, want de leerling of diens ouders/verzorgers kunnen hiervoor zelf toestemming geven. Dit gebeurt bij de eerste keer dat de leerling inlogt bij ' + (componentViewModel.onGetKoppelpartijNaam | async) + '.'">
              </app-info-blok>
            </div>
        </div>
    </div>
</div>
