<app-connect-loader [loading]="loading | async">
<div class="contentBox">
  <app-go-back-header [viewmodel]="goBackHeaderViewModel"></app-go-back-header>
  <div class="container">
    <app-koppeling-header
      [viewModel]="headerViewModel"
    >
      <app-koppeling-menu [viewModel]="menuViewModel"></app-koppeling-menu>
    </app-koppeling-header>
    <div class="paginaContent">
      <app-koppeling-omschrijving *ngIf="omschrijvingTab | async"></app-koppeling-omschrijving>
      <app-koppeling-datatoegang *ngIf="datatoegangTab | async"></app-koppeling-datatoegang>
    </div>
  </div>
</div>

<app-modal *ngIf="showVerwijderModal" (closeModal)="cancelDeleteKoppeling()">
    <div class="modal__header">
      <div class="header__icon svg_icon-warning"></div>
      <p class="header__title bodyContentBold">Koppeling opheffen</p>
      <div class="close" (click)="cancelDeleteKoppeling()"></div>
    </div>
    <div class="modal__body">
      <p class="body__text bodyContent">Voor welke vestigingen wil je deze koppeling opheffen? {{ (koppelpartij$ | async)?.koppelpartijNaam }} zal geen data van die vestigingen meer ontvangen via Somtoday Connect. </p>
      <div class="modal__selection">
        <div class="selection__title">
          <p class="bodyContentSemi">Vestigingen</p>
          <div class="selectAll" (click)="selectAlleVestigingenToDelete()">
            <i class="svg_icon-checkbox" [class]="(gekoppeldeVestigingen$ | async)?.length === koppelingenToDelete.length ? 'checked' : 'unchecked'"></i>
            <p class="bodyContent">Alles</p>
          </div>
        </div>
        <div *ngFor="let row of (gekoppeldeVestigingen$ | async)" class="selection__option" (click)="selectVestigingToDelete(row.UUID)" >
          <i class="svg_icon-checkbox" [class]="getCheckboxClass(row.UUID)"></i>
          <p class="bodyContent">{{row.naam}}</p>
        </div>
      </div>
    </div>
    <div class="modal__buttons modal__buttons--no_border">
      <div class="button" (click)="cancelDeleteKoppeling()">
        <p class="bodySmallContentSemi">Annuleer</p>
      </div>
      <div [class]="getDeleteButtonClass()" (click)="continueDeleteKoppeling()">
        <i class="svg_icon-ontkoppel"></i>
        <p class="bodySmallContentSemi">
          Ontkoppel {{koppelingenToDelete.length > 0 ? '(' + koppelingenToDelete.length + ')' : ''}}
        </p>
      </div>
    </div>
</app-modal>

  <app-modal *ngIf="showBewerkModal" (closeModal)="cancelBewerkKoppeling()">
    <div class="modal__header">
      <div class="header__icon svg_icon-bewerken"></div>
      <p class="header__title bodyContentBold">Koppeling bewerken</p>
      <div class="close" (click)="cancelBewerkKoppeling()"></div>
    </div>
    <div class="modal__body">
      <p class="body__text bodyContent">Onderstaande vestigingen zijn gegroepeerd per koppeling. De vestigingen die onder dezelfde koppeling vallen delen dezelfde datavelden voor dezelfde doelgroepen in dezelfde periode met {{ (koppelpartij$ | async)?.koppelpartijNaam }}.</p>
      <p class="body__text bodyContentSemi">Welke koppeling wil je aanpassen?</p>
      <div class="modal__selection">
        <div *ngFor="let koppelingen of (sortedKoppelingen$ | async)" class="selection__option selection__option--with_hover" (click)="continueBewerkKoppeling(koppelingen)" >
          <p class="bodyContentSemi">
            <span *ngFor="let koppeling of koppelingen">
              {{koppeling.vestiging.naam}}<span *ngIf="koppelingen.indexOf(koppeling) != (koppelingen.length - 1)">, </span>
            </span>
          </p>
          <i class="icon-arrow"></i>
        </div>
        <div *ngIf="koppelingenBeschikbaarForKoppelpartij$ | async" class="selection__option selection__option--with_hover" (click)="addKoppeling()">
          <p class="bodyContentSemi blue">Andere vestiging toevoegen</p>
          <i class="icon-arrow"></i>
        </div>
      </div>
    </div>
  </app-modal>

</app-connect-loader>
