import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import {SchooldashboardNewFacade} from '../../state/schooldashboard/schooldashboard.facade';
import {DashboardsettingsFacade} from '../../state/dashboardsettings/dashboardsettings.facade';

@Component({
  selector: 'app-schooldashboard-table',
  templateUrl: './schooldashboard-table.component.html',
  styleUrls: ['./schooldashboard-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SchooldashboardTableComponent implements OnInit {

  constructor(public settings: DashboardsettingsFacade, public store: SchooldashboardNewFacade) { }

  ngOnInit(): void {
  }

}
