export function sort<T>(arr: T[], projection: (T) => string): T[] {
    if (arr === null || arr === undefined) {
        return arr;
    }
    return [...arr].sort((a, b) => projection(a)?.toLowerCase().localeCompare(projection(b)?.toLowerCase()));
}

export function sortByNumberThenByString<T>(arr: T[], numProj: (T) => number, strProj: (T) => string): T[] {
  if (arr === null || arr === undefined) {
    return arr;
  }

  return [...arr].sort((a, b) => {
    if (numProj(a) !== numProj(b)) {
      return numProj(a) - numProj(b);
    }

    return strProj(a)?.toLowerCase().localeCompare(strProj(b)?.toLowerCase());
  });
}
