import {BaseFacade} from '../base.facade';
import {AppState} from '../app.state';
import {Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {selectKoppelingen, selectSemParties} from './privacydashboard.selectors';
import {AVGExtendedKoppelpartij} from '../../dto/avg-dashboard-model-classes';
import { AVGSemPartyMarketplaceEntry } from 'src/generated/avg-client';

@Injectable()
export class PrivacydashboardFacade extends BaseFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }

  selectGekoppeldePartijen(): Observable<AVGExtendedKoppelpartij[]> {
    return this.select(selectKoppelingen);
  }

  selectSemMarketplaceEntries(): Observable<AVGSemPartyMarketplaceEntry[]> {
      return this.select(selectSemParties);
  }
}

