import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-icon-keper',
  templateUrl: './icon-keper.component.svg',
  styleUrls: ['./icon-keper.component.scss'],
})
export class IconKeperComponent {
  @Input() active: boolean;

  constructor() {
  }
}
