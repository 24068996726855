import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ExportoverzichtFacade} from 'src/app/state/exportoverzicht/exportoverzicht.facade';
import {localDateStringToDisplayDateString} from 'src/app/stateless/moments';
import {byStringProp} from 'src/app/stateless/sorting';
import {AVGExport} from 'src/generated/avg-client';

@Component({
  selector: 'app-exportoverzicht-page',
  templateUrl: './exportoverzicht-page.component.html',
  styleUrls: ['./exportoverzicht-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportoverzichtPageComponent implements OnInit {

  private EXPORT_PAGE_SIZE = 20;

  public rows$: Observable<ExportoverzichtTableDatum[]>;

  public activeSubRow: number | null = null;

  public isSidebarOpen$: Observable<boolean>;

  public isLoading$: Observable<boolean>;

  public hasExports$: Observable<boolean>;

  public pageSize$: Observable<number>;

  constructor(
    public facade: ExportoverzichtFacade,
  ) {
  }

  ngOnInit(): void {
    this.isSidebarOpen$ = this.facade.getSidebarOpenState();

    this.isLoading$ = this.facade.getLoadingState();

    this.facade.getSidebarOpenState();

    this.rows$ = this.facade.getFilteredExports().pipe(map(exports => {
      const datums: ExportoverzichtTableDatum[] = [];
      if (exports) {
        for (const exp of exports) {
          let datum: ExportoverzichtTableDatum = datums.find(d => d.datum === exp.datum);
          if (datum === undefined) {
            datum = {datum: exp.datum, subrows: []};
            datums.push(datum);
          }

          datum.subrows.push({...exp, icon: this.iconOf(exp), type: this.typeOf(exp)});
        }
      }

      return datums
        .sort(byStringProp(row => row.datum))
        .map(d => ({
          subrows: d.subrows.sort(byStringProp(subrow => subrow.tijd)),
          datum: localDateStringToDisplayDateString(d.datum)
        }));
    }));

    this.hasExports$ = this.rows$.pipe(map(rows => rows.length > 0));

    this.pageSize$ = this.facade.getAantalExports().pipe(map(n => Math.ceil(n / this.EXPORT_PAGE_SIZE)));
  }

  public toggleActive(id): void {
    if (this.activeSubRow === id) {
      return this.activeSubRow = null;
    }
    this.activeSubRow = id;
  }


  private iconOf(exp: AVGExport): string {
    switch (exp.type) {
      case 'DataProviderObjectExporter': {
        return 'export';
      }
      case 'SelectieLeerlingExporter':
      case 'InrichtingExporter':
      case 'VakkenExporter':
      case 'BookmasterExporter':
      case 'StamgroepindelingenExporter':
      case 'WolfExporter':
      case 'UntisExporter':
      case 'VakkeuzesExporter':
      case 'CITOVolgsysteemExporter':
      case 'UmExporter':
      case 'LesgroepindelingenExporter':
      case 'StamgroepenExporter':
      case 'SchrapkaartenExporter':
      case 'VakkeuzesPerOpleidingExporter':
      case 'LesgroepenExporter': {
        return 'cog';
      }
      case 'DataPanelExportJob': {
        return 'search';
      }
      default: {
        return 'export';
      }
    }
  }

  private typeOf(exp: AVGExport): string {
    switch (exp.type) {
      case 'DataProviderObjectExporter': {
        return 'Vrije export';
      }
      case 'SelectieLeerlingExporter':
      case 'InrichtingExporter':
      case 'VakkenExporter':
      case 'BookmasterExporter':
      case 'StamgroepindelingenExporter':
      case 'VerzorgerExporter':
      case 'WolfExporter':
      case 'UntisExporter':
      case 'VakkeuzesExporter':
      case 'CITOVolgsysteemExporter':
      case 'UmExporter':
      case 'LesgroepindelingenExporter':
      case 'StamgroepenExporter':
      case 'SchrapkaartenExporter':
      case 'VakkeuzesPerOpleidingExporter':
      case 'LesgroepenExporter': {
        return 'Beheer export';
      }
      case 'DataPanelExportJob': {
        return 'Zoekresultaten';
      }
      case 'BusinessObjectsRapportageJob': {
        return 'BO vrij rapporteren';
      }
      default: {
        return 'Export';
      }
    }
  }

  public onSidebarClose(): void {
    this.facade.closeSidebarRequested();
  }

}

export class ExportoverzichtTableDatum {
  datum: string;
  subrows: ExportoverzichtTableRow[];
}

export class ExportoverzichtTableRow {
  datum?: string;
  id?: number;
  medewerker?: string;
  tijd?: string;
  type?: string;
  icon: string;
}
