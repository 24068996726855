import {Component, OnInit, ChangeDetectionStrategy, OnDestroy} from '@angular/core';
import {Leermiddel, Vak} from '../../state/leermiddelvakkoppeling/leermiddelvakkoppeling.state';
import {LeermiddelvakkoppelingNewFacade} from '../../state/leermiddelvakkoppeling/leermiddelvakkoppeling.facade';
import {map, tap} from 'rxjs/operators';
import {sort} from '../../stateless/string';
import {combineLatest, Subscription} from 'rxjs';

@Component({
  selector: 'app-leermiddel-vak-koppeling-sidebar-selected',
  templateUrl: './leermiddel-vak-koppeling-sidebar-selected.component.html',
  styleUrls: ['./leermiddel-vak-koppeling-sidebar-selected.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeermiddelVakKoppelingSidebarSelectedComponent implements OnInit, OnDestroy {

    public leermiddel: Leermiddel;

    public top: number = 250;

    public cooldown: boolean = true;

    public gekoppeldeVakken: Vak[];

    private subscriptions: Subscription[] = [];

    constructor(public store: LeermiddelvakkoppelingNewFacade) {
    }

    ngOnInit(): void {
        this.subscriptions.push(
            combineLatest([this.store.getSelectedLeermiddelVakKoppeling()])
              .subscribe(([ lmData ]) => {
                if (lmData != null) {
                  // Beetje hacky om style zo aan te passen
                  // Maar wel het makkelijkst omdat we niet snel weten op welke positie het leermiddel component staat
                  this.top = lmData.top;
                  this.leermiddel = lmData.leermiddel;
                  this.gekoppeldeVakken = sort([...this.leermiddel.gekoppeldAanVakken], vak => vak.naam);
                }
              }),
              );
        setTimeout(() => this.cooldown = false, 200);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    toggleKoppeling(vak: Vak): void {
        if (this.gekoppeldeVakken.find(v => v.UUID === vak.UUID)) {
            this.gekoppeldeVakken = this.gekoppeldeVakken.filter(v => v.UUID !== vak.UUID);
        } else {
            this.gekoppeldeVakken = sort([...this.gekoppeldeVakken, vak], v => v.naam);
        }
    }

    public isSelected(vak: Vak): boolean {
        return !!this.gekoppeldeVakken.find(v => v.UUID === vak.UUID);
    }

    sluit(): void {
        if (this.cooldown) {
            return;
        }

        // Check of er wel wijzigingen zijn gedaan voordat we storeLeermiddel aanroepen
        if (
            this.gekoppeldeVakken.length !== this.leermiddel.gekoppeldAanVakken.length ||
            this.gekoppeldeVakken.filter(
                v => !!this.leermiddel.gekoppeldAanVakken.find(vak => vak.UUID === v.UUID)
            ).length !== this.gekoppeldeVakken.length) {
            this.store.storeLeermiddel({...this.leermiddel, gekoppeldAanVakken: this.gekoppeldeVakken});
        }

        this.store.setSelectedLeermiddelVakKoppeling(null);
    }
  }
