<app-connect-loader [loading]="loading | async">
  <div class="contentbox">
    <div class="headerrow">
      <app-terugknop [urlOmNaarTerugTeGaan]="goBack" [caption]="'Terug naar schooloverzicht'"></app-terugknop>
      <app-vak-onderwijs-switch [subNiveau]="truncateTitel(leermiddel)"></app-vak-onderwijs-switch>
      <app-datumbereik [studiewijzerdb]="false"></app-datumbereik>
    </div>
    <div class="banner">
      <i class="icon-vak-dashboard"></i>
      <span *ngIf="leermiddelData?.totaal">{{leermiddelData?.totaal.leermiddel}}</span>
    </div>
    <app-table *ngIf="leermiddelData?.totaal" [model]="leermiddelData | vakloosleermiddelTable"></app-table>
  </div>
</app-connect-loader>
