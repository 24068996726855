import {Injectable} from '@angular/core';
import {Actions, createEffect} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {catchError, filter, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {setStudiewijzerLinkjesDetailLoading, setSWItemDetails} from '../state/schooldashboard/schooldashboard.actions';
import {AppState, selectSchooldashboard} from '../../state/app.state';
import {selectAppFilter} from '../../state/app.selectors';
import {
  DashboardFilterInput,
  SwDetailsPerPlatformGQL,
  SwDetailsPerVakGQL,
  SwLinkjesGebruikDetails,
  SwLinkjesPerPlatformOfVak
} from '../../../generated/graphql';
import {payload} from '../../state/payload';
import {selectSelectedSWItem} from '../state/studiewijzerlinkjes/studiewijzerlinkjes.selectors';
import {Observable, of} from 'rxjs';
import {reportError} from '../../state/errors/errors.actions';

@Injectable()
export class StudiewijzerlinkjeselectionEffect {
  $ = createEffect(() => this.store.select(selectSelectedSWItem).pipe(
    filter(v => v !== null),
    withLatestFrom(this.store.select(selectAppFilter)),
    filter(([currentPlatform, currentFilter]) =>
      hasFilterChanged(this.prevFilter, currentFilter) || currentPlatform.naam !== this.prevPlatform),
    tap(([_, current]) => {
      this.prevFilter = current;
      this.store.dispatch(setStudiewijzerLinkjesDetailLoading());
    }),
    withLatestFrom(this.store.select(selectSchooldashboard).pipe(map(v => v.groeperenOpPlatform))),
    switchMap(([[platform, current], groeperenOpPlatform]) => this.fetch(current, platform, groeperenOpPlatform)),
    map(payload),
    map(setSWItemDetails),
    catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
  ));

  private prevFilter: DashboardFilterInput;

  private prevPlatform: string;

  constructor(private actions$: Actions,
              private store: Store<AppState>,
              private platformgql: SwDetailsPerPlatformGQL,
              private vakgql: SwDetailsPerVakGQL) {
  }

  private fetch(f: DashboardFilterInput,
                p: SwLinkjesPerPlatformOfVak,
                groeperenOpPlatform: boolean): Observable<SwLinkjesGebruikDetails[]> {
    if (groeperenOpPlatform) {
      return this.platformgql.fetch({filter: f, platform: p.naam}).pipe(map(({data}) => data.detailsPerPlatform));
    } else {
      return this.vakgql.fetch({filter: f, vak: p.naam}).pipe(map(({data}) => data.detailsPerVak));
    }
  }
}

const hasFilterChanged = (prev: DashboardFilterInput = {}, current: DashboardFilterInput = {}): boolean =>
  current.vestiging !== prev.vestiging || current.periode !== prev.periode;
