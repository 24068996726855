import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {combineLatest} from 'rxjs';
import {StudiewijzerDonutViewModel} from '../studiewijzer-donut/studiewijzer-donut.component';
import {StudiewijzerlinkjesOverzichtViewModel} from '../studiewijzerlinkjes-overzicht/studiewijzerlinkjes-overzicht.component';
import {SwLinkjesGebruikDetails, SwLinkjesPerPlatformOfVak} from '../../../generated/graphql';
import {map} from 'rxjs/operators';
import {StudiewijzerlinkjesDetailViewModel} from '../studiewijzerlinkjes-detail/studiewijzerlinkjes-detail.component';
import {StudiewijzerlinkjesFacade} from '../../old-dashboarding/state/studiewijzerlinkjes/studiewijzerlinkjes.facade';

@Component({
  selector: 'app-studiewijzerlinkjesdashboard',
  templateUrl: './studiewijzerlinkjesdashboard.component.html',
  styleUrls: ['./studiewijzerlinkjesdashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class StudiewijzerlinkjesdashboardComponent implements OnInit {
  public viewModel: StudiewijzerDonutViewModel & StudiewijzerlinkjesOverzichtViewModel & StudiewijzerlinkjesDetailViewModel;
  datumBevatData = this.store.selectStudiewijzerLinkjesPerPlatform().pipe(map(data => data[0] !== undefined));
  vestigingBevatData = this.store.selectVestigingBevatLicenties();
  loading = this.store.selectStudiewijzerLinkjesOverzichtLoading();
  groeperenOpPlatform = this.store.selectIsGroeperenOpPlatform();
  toonDetails$ = combineLatest([
    this.store.selectCurrentSWItem(),
    this.store.selectStudiewijzerlinkjesDetailsPerPlatform()
  ]).pipe(map(([item, data]) => item != null && data != null));

  constructor(private store: StudiewijzerlinkjesFacade) { }

  ngOnInit(): void {
    const self = this;
    this.viewModel = {
      onGetGroeperingOpPlatform: this.store.selectIsGroeperenOpPlatform(),
      onGetData: this.store.selectStudiewijzerLinkjesPerPlatform(),
      onGetAantalLesgroepen: this.store.selectAantalLesgroepenMetStudiewijzerlinks(),
      selectItem(item: SwLinkjesPerPlatformOfVak) {
        self.store.setSelectedSWItem(item);
      },
      onGetCurrentItem: this.store.selectSelectedSWItem(),
      onGetDetails: this.store.selectStudiewijzerlinkjesDetailsPerPlatform(),
      sluitItem() {
        self.store.closeSelectedSWItem();
      },
      selectDetail(detail: SwLinkjesGebruikDetails) {
        self.store.setSelectedSWItemDetail(detail);
      }
    };
  }

  onGroeperingChanged(event: boolean) {
    this.viewModel.sluitItem();
    this.store.setGroeperenOpPlatform(event);
  }
}
