<div class="koppelpartijLogo">
  <img *ngIf="koppelpartij.koppelpartijLogoUrl; else defaultLogo" src="{{ koppelpartij.koppelpartijLogoUrl }}">
  <ng-template #defaultLogo><img src="../../../assets/img/icons/Symbool/Koppelpartij_icon_placeholder.svg"></ng-template>
</div>
<div class="textWrapper">
  <p class="bodyContentSemi" [class]="koppelpartij.koppelingen?.length ? 'small' : ''">
    {{koppelpartij.koppelpartijNaam}}
  </p>
  <span *ngIf="koppelpartij.koppelingen?.length" class="labelLabelSemi label"><i class="icon-check"></i>Gekoppeld</span>
  <p class="bodySmallContent">{{koppelpartij.koppelpartijKorteOmschrijving}}</p>
</div>
<i class="icon-arrow"></i>
