import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

export enum Metrics {
  PAGELOAD = 'page_view',
  LOGINSTART = 'login_start',
  VESTIGINGCHANGE = 'vestiging_change',
  LOGINDONE = 'login_done',
  DATERANGECHANGE = 'periode_change'
}

@Injectable()
export class AnalyticsService {

  logEvent(name: Metrics, url?: string) {
    // @ts-ignore
    const gtag = window['gtag'];
    if (gtag) {
      gtag('js', new Date());
      // @ts-ignore
      gtag('config', environment.analyticsId, { 'page_path': url });
      gtag('event', name, { page_path: url });
      return;
    }
    console.log('GA not ready, could not log event: ' + name);
  }

}
