import {Component, OnInit, ChangeDetectionStrategy, Input} from '@angular/core';
import {KlassendashboardNewFacade} from '../../state/klassendashboard/klassendashboard.facade';
import {LesgroepDetails} from '../../old-dashboarding/state/klassendashboard/klassendashboard.selector';
import {Observable} from 'rxjs';
import {RMedewerker} from '../../services/somtoday.service';

@Component({
  selector: 'app-klassendashboard-samenvatting-sidebar',
  templateUrl: './klassendashboard-samenvatting-sidebar.component.html',
  styleUrls: ['./klassendashboard-samenvatting-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KlassendashboardSamenvattingSidebarComponent implements OnInit {

  @Input()
  aantalLeerlingen: number;

  @Input()
  naam: string;

  @Input()
  medewerkers$: Observable<string[]>;

  constructor(public store: KlassendashboardNewFacade) { }

  ngOnInit(): void {
  }

}
