import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy } from '@angular/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map, tap, withLatestFrom } from 'rxjs/operators';

@Component({
    selector: 'app-input-toggle',
    templateUrl: './input-toggle.component.html',
    styleUrls: ['./input-toggle.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputToggleComponent implements OnInit, OnDestroy {
    @Input()
    public viewModel: InputToggleViewModel;

    public onGetToggleClass: Observable<string>;

    private isActive = false;

    private isOn = false;

    private subscriptions: Subscription[] = [];

    constructor() { }

    ngOnInit(): void {
        this.viewModel.onGetActive.subscribe(active => this.isActive = active);
        this.viewModel.onGetState.subscribe(on => this.isOn = on);
        this.onGetToggleClass = combineLatest([this.viewModel.onGetActive, this.viewModel.onGetState]).pipe(
            map(([active, state]) => (active ? 'active ' : 'inactive ') + (state ? 'on' : 'off'))
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    public onClick(): void {
        if (this.isActive) {
            this.viewModel.onToggle(!this.isOn);
        }
    }
}

export class InputToggleViewModel {
    onGetState: Observable<boolean>;
    onGetActive: Observable<boolean>;

    onToggle: (newState: boolean) => void;
}
