import {Pipe, PipeTransform} from '@angular/core';
import {RMedewerker} from '../services/somtoday.service';

// TODO: Delete als nieuwe dashboards live is
@Pipe({name: 'medewerkerVoornaamAchternaamAfkorting'})
export class MedewerkerVoornaamAchternaamAfkorting implements PipeTransform {
  transform(medewerker: RMedewerker): string {
    const voornaam = medewerker.roepnaam ? medewerker.roepnaam : medewerker.voorletters ? medewerker.voorletters : 'dhr/mevr.';
    const achternaam = medewerker.achternaam ? medewerker.achternaam : '';
    const afkorting = medewerker.afkorting;

    return voornaam + ' ' + achternaam + ' (' + afkorting + ')';
  }
}
