<div *ngIf="grafiekAvailable$ | async" class="infoBlok">
  <div class="titel">
    <h1 class="bodyContentSemi">Gebruik per week</h1>
  </div>
  <div class="content">
    <app-multi-range-week-bar-chart [viewModel]="grafiekViewModel"></app-multi-range-week-bar-chart>
  </div>
</div>

<div *ngIf="tabelAvailable$ | async" class="infoBlok">
  <div class="titel">
    <h1 class="bodyContentSemi">Accountinformatie</h1>
  </div>
  <div class="content">
    <table>
      <tr>
        <th class="bodySmallContentSemi"><span class="flex">
          {{ (viewModel.webserviceNaam | async) === 'UWLR' ? 'Aanbieder' : 'Account'}}
          <i class="info"></i>
          <div class="tooltip">
            <p class="bodySmallContent">{{ (viewModel.webserviceNaam | async) === 'UWLR' ?
              'Dit zijn aanbieders waarmee een UWLR sleutel gedeeld is' :
              'Dit zijn Somtoday-accounts die via hun rol recht hebben op deze webservice'
              }}</p>
          </div>
        </span></th>
        <th class="bodySmallContentSemi">Dataverzoeken <br> afgelopen maand</th>
        <th class="bodySmallContentSemi"><span class="flex">
          Vanaf <br> aantal IP
          <i class="info"></i>
          <div class="tooltip align-right">
            <p class="bodySmallContent">Het IP adres is de digitale locatie vanaf waar dit account gebruikt wordt. Hoe meer IP-adressen wij registreren, hoe hoger het risico. Mogelijk wordt het account gebruikt door meerdere (ongeautoriseerde) partijen </p>
          </div>
        </span></th>
        <th class="bodySmallContentSemi"><span class="flex">
          Kan <br> bewerken
          <i class="info"></i>
          <div class="tooltip align-right">
            <p class="bodySmallContent">Behalve data lezen, kan dit account ook data bewerken, dit brengt mogelijk risico’s met zich mee</p>
          </div>
        </span></th>
        <th class="bodySmallContentSemi"><span class="flex">
          Laatst actief
          <i class="info"></i>
          <div class="tooltip align-center">
            <p class="bodySmallContent">Laatste datum waarop data is opgehaald. Door inactieve accounts te blokkeren voorkom je misbruik. Deze data is beschikbaar vanaf september 2021.</p>
          </div>
        </span></th>
      </tr>
      <ng-container *ngFor="let row of (viewModel.webserviceAccountGebruik | async)">
        <tr *ngIf="!((viewModel.webserviceNaam | async) === 'UWLR' && !row.naam)">
          <td class="bodyContent"><span class="flex">
            {{row.naam}}
            <i *ngIf="row.meerdereRechten" class="warning"></i>
            <div *ngIf="row.meerdereRechten" class="tooltip">
              <p class="bodySmallContent">Let op, dit account heeft ook toegang tot andere webservices</p>
            </div>
          </span></td>
          <td class="bodyContent">{{row.countLaatsteMaand}}</td>
          <td class="bodyContent">{{row.aantalIPAdressen}}</td>
          <td *ngIf="(viewModel.webserviceNaam | async) === 'UM'" class="bodyContent">{{row.heeftSchrijfrechten ? 'Ja' : 'Nee'}}</td>
          <td *ngIf="(viewModel.webserviceNaam | async) === 'UWLR'"  class="bodyContent">Nee</td>
          <td  *ngIf="['WIS', 'Cup', 'Stage', 'HRM'].includes(viewModel.webserviceNaam | async)" class="bodyContent">Ja</td>
          <td class="bodyContent">{{getDate(row.laatsteAanroep)}}</td>
        </tr>
      </ng-container>
    </table>
  </div>
</div>

<div *ngIf="!(grafiekAvailable$ | async) && !(tabelAvailable$ | async)" class="infoBlok">
  <div class="content">
    <p class="bodySmallContent"><em>Er heeft dit schooljaar (nog) geen dataverkeer plaatsgevonden via deze webservice</em></p>
  </div>
</div>

<div *ngIf="(grafiekAvailable$ | async) && !(tabelAvailable$ | async)" class="infoBlok">
  <div class="titel">
    <h1 class="bodyContentSemi">Accountinformatie</h1>
  </div>
  <div class="content">
    <p class="bodySmallContent"><em>Er heeft afgelopen maand geen dataverkeer plaatsgevonden via deze webservice</em></p>
  </div>
</div>
