import { createReducer, on } from '@ngrx/store';
import moment from 'moment';
import { BackofficeEtlTaskEvent, BackofficeEtlTaskExecution, BackofficeEtlTaskStep, BackofficeOrganization, BackofficeSchoolYear } from '../../../generated/backoffice-client';
import { addFeedback, cleanUpFeedback, schoolYearStored, schoolYearsFetched, organizationsFetched, organizationSaved, setSyncsPage, syncsFetched, taskSelected, taskSchoolYearsFetched, taskOrganizationsFetched, taskFailedOrgsFetched, taskEventsFetched, taskStepsFetched, setSyncsSortColumn } from './dashboard-backoffice.actions';

const initialState: DashboardBackofficeState = {
    feedback: [],
    schoolYears: null,
    organizations: null,
    syncs: {
        pagedSyncs: [],
        currentPageIndex: 0,
        pageSize: 20,
        orderBy: 'started_at',
        descending: false,
        count: null
    },
    selectedTask: null,
    selectedTaskSchoolYears: null,
    selectedTaskOrganizations: null,
    selectedTaskFailedOrgs: null,
    selectedTaskStepsLeft: null,
    selectedTaskEvents: null,
    selectedTaskEventsLastId: null
};

export const dashboardBackofficeReducer = createReducer(
    initialState,

    on(addFeedback, (state, { feedback }) => ({ ...state, feedback: [...state.feedback, feedback] })),
    on(cleanUpFeedback, (state) => ({ ...state, feedback: state.feedback.filter(fb => fb.time.isAfter(moment().subtract({ seconds: 30 }))) })),

    on(schoolYearsFetched, (state, { schoolYears }) => ({ ...state, schoolYears })),
    on(schoolYearStored, (state, { schoolYear }) => ({ ...state, schoolYears: [...state.schoolYears.filter(sj => sj.name !== schoolYear.name), schoolYear] })),

    on(organizationsFetched, (state, { organizations }) => ({ ...state, organizations })),
    on(organizationSaved, (state, { organization }) => ({ ...state, organizations: [...state.organizations.filter(o => o.uuid !== organization.uuid), organization] })),

    on(syncsFetched, (state, { syncs, page, count }) => ({ ...state, syncs: { ...state.syncs, pagedSyncs: setPage(state.syncs.pagedSyncs, syncs, page), count } })),
    on(setSyncsPage, (state, { page }) => ({ ...state, syncs: { ...state.syncs, currentPageIndex: page } })),
    on(setSyncsSortColumn, (state, { column }) => ({ ...state, syncs: { ...state.syncs, orderBy: column } })),

    on(taskSelected, (state, { task }) => ({ 
        ...state, 
        selectedTask: task, 
        selectedTaskSchoolYears: null, 
        selectedTaskOrganizations: null,
        selectedTaskFailedOrgs: null,
        selectedTaskEvents: null,
        selectedTaskStepsLeft: null,
        selectedTaskEventsLastId: null
    })),
    on(taskSchoolYearsFetched, (state, { schoolYears }) => ({ ...state, selectedTaskSchoolYears: schoolYears })),
    on(taskOrganizationsFetched, (state, { organizations }) => ({ ...state, selectedTaskOrganizations: organizations })),
    on(taskFailedOrgsFetched, (state, { organizations }) => ({ ...state, selectedTaskFailedOrgs: organizations })),
    on(taskEventsFetched, (state, { events }) => {
        let newEventsLog = [];

        if(state.selectedTaskEvents !== null)
        {
            state.selectedTaskEvents.forEach(e => {
                newEventsLog.push(e);
            });
        }
        events.forEach(e => {
            newEventsLog.push(e);
        });

        let lastEventId = newEventsLog.length === 0 ? null : newEventsLog[newEventsLog.length - 1]?.id;

        const newState = { ...state, selectedTaskEvents: newEventsLog, selectedTaskEventsLastId: lastEventId };
        return newState;
    }),
    on(taskStepsFetched, (state, { steps }) => ({ ...state, selectedTaskStepsLeft: steps })) 
);

export interface DashboardBackofficeState {
    feedback: [];
    schoolYears: BackofficeSchoolYear[] | null;
    organizations: BackofficeOrganization[] | null;
    syncs: Syncs;
    selectedTask: BackofficeEtlTaskExecution | null;
    selectedTaskSchoolYears: BackofficeSchoolYear[] | null;
    selectedTaskOrganizations: BackofficeOrganization[] | null;
    selectedTaskFailedOrgs: BackofficeOrganization[] | null;
    selectedTaskStepsLeft: BackofficeEtlTaskStep[] | null;
    selectedTaskEvents: BackofficeEtlTaskEvent[] | null;
    selectedTaskEventsLastId: number | null;
}

export interface Syncs {
    pagedSyncs: BackofficeEtlTaskExecution[][];
    currentPageIndex: number;
    pageSize: number;
    orderBy: string;
    descending: boolean;
    count: number;
}


function setPage(pages: BackofficeEtlTaskExecution[][], syncs: BackofficeEtlTaskExecution[], page: number): BackofficeEtlTaskExecution[][] {
    const newInstanceOfMap = [...pages];
    newInstanceOfMap[page] = syncs;
    return newInstanceOfMap;
}
