import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './table/table.component';
import {MatTableModule} from '@angular/material/table';
import { CellContentComponent } from './cell-content/cell-content.component';
import { LabelCellComponent } from './label-cell/label-cell.component';
import { PercentLabelComponent } from './percent-label/percent-label.component';
import { ActivatiegetalCellComponent } from './activatiegetal-cell/activatiegetal-cell.component';
import {LayoutModule} from '../layout/layout.module';
import { RangebarCellComponent } from './rangebar-cell/rangebar-cell.component';
import { RangelinechartCellComponent } from './rangelinechart-cell/rangelinechart-cell.component';
import { ClickableRowIconCellComponent } from './clickable-row-icon-cell/clickable-row-icon-cell.component';
import { HelpHeaderCellComponent } from './help-header-cell/help-header-cell.component';
import { KeyCellComponent } from './key-cell/key-cell.component';
import { JustifiedNumberCellComponent } from './justified-number-cell/justified-number-cell.component';
import { LesgroepActivatiegetalCellComponent } from './lesgroep-activatiegetal-cell/lesgroep-activatiegetal-cell.component';
import { ProgressbarCellComponent } from './progressbar-cell/progressbar-cell.component';
import { LinechartCellComponent } from './linechart-cell/linechart-cell.component';
import { GeactiveerdComponent } from './geactiveerd/geactiveerd.component';
import { GeactiveerdTotaalComponent } from './geactiveerd-totaal/geactiveerd-totaal.component';
import { GebruikperweekComponent } from './gebruikperweek/gebruikperweek.component';
import { LeerlingnaamActivatiegetalCellComponent } from './leerlingnaam-activatiegetal-cell/leerlingnaam-activatiegetal-cell.component';
import { LaatstActiefMetIconCellComponent } from './laatst-actief-met-icon-cell/laatst-actief-met-icon-cell.component';
import { LeermiddelnaamCellComponent } from './leermiddelnaam-cell/leermiddelnaam-cell.component';
import { RightAlignedCenterCellComponent } from './right-aligned-center-cell/right-aligned-center-cell.component';
import { PercentageIndicatieCellComponent } from './percentage-indicatie-cell/percentage-indicatie-cell.component';
import { LesgroepDocentenCellComponent } from './lesgroep-docenten-cell/lesgroep-docenten-cell.component';
import { TitelondertitelCellComponent } from './titelondertitel-cell/titelondertitel-cell.component';
import { IconHeaderCellComponent } from './icon-header-cell/icon-header-cell.component';
import { VakkenCellComponent } from './vakken-cell/vakken-cell.component';
import { LeermiddelUitklapCellComponent } from './leermiddel-uitklap-cell/leermiddel-uitklap-cell.component';

@NgModule({
  declarations: [
    TableComponent,
    CellContentComponent,
    LabelCellComponent,
    PercentLabelComponent,
    ActivatiegetalCellComponent,
    RangebarCellComponent,
    RangelinechartCellComponent,
    ClickableRowIconCellComponent,
    HelpHeaderCellComponent,
    KeyCellComponent,
    JustifiedNumberCellComponent,
    LesgroepActivatiegetalCellComponent,
    ProgressbarCellComponent,
    LinechartCellComponent,
    GeactiveerdComponent,
    GeactiveerdTotaalComponent,
    GebruikperweekComponent,
    LeerlingnaamActivatiegetalCellComponent,
    LaatstActiefMetIconCellComponent,
    LeermiddelnaamCellComponent,
    RightAlignedCenterCellComponent,
    PercentageIndicatieCellComponent,
    LesgroepDocentenCellComponent,
    TitelondertitelCellComponent,
    IconHeaderCellComponent,
    VakkenCellComponent,
    LeermiddelUitklapCellComponent],
    imports: [
        CommonModule,
        MatTableModule,
        LayoutModule
    ],
  exports: [
    TableComponent
  ],
  entryComponents: [
    LabelCellComponent,
    PercentLabelComponent,
    ActivatiegetalCellComponent,
    RangebarCellComponent,
    RangelinechartCellComponent,
    ClickableRowIconCellComponent,
    HelpHeaderCellComponent,
    KeyCellComponent,
    JustifiedNumberCellComponent,
    LesgroepActivatiegetalCellComponent,
    ProgressbarCellComponent,
    LinechartCellComponent,
    GeactiveerdComponent,
    GeactiveerdTotaalComponent,
    GebruikperweekComponent,
    LeerlingnaamActivatiegetalCellComponent,
    LaatstActiefMetIconCellComponent,
    LeermiddelnaamCellComponent,
    IconHeaderCellComponent,
    VakkenCellComponent,
    LeermiddelUitklapCellComponent
  ]
})
export class TableModule { }
