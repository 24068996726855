import {Component, ElementRef} from '@angular/core';
import {TableCellComponent} from '../table/table.model';
import {LeermiddelVakKoppelingInfo} from '../../../generated/graphql';
import {LeermiddelvakkoppelingFacade} from '../../old-dashboarding/state/leermiddelvakkoppeling/leermiddelvakkoppeling.facade';

@Component({
  selector: 'app-vakken-cell',
  templateUrl: './vakken-cell.component.html',
  styleUrls: ['./vakken-cell.component.scss']
})
export class VakkenCellComponent implements TableCellComponent<LeermiddelVakKoppelingInfo> {
  data: LeermiddelVakKoppelingInfo;

  constructor(private facade: LeermiddelvakkoppelingFacade,
              private elRef: ElementRef) {
  }

  selecteer() {
    this.facade.setSelectedLeermiddelVakKoppeling([this.data, this.elRef.nativeElement.getBoundingClientRect().top]);
  }

  ontkoppel() {
    const info = {...this.data};
    info.vakken = [];
    this.facade.updateLeermiddelVakKoppeling(info);
  }
}
