import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {
  constructor() {
  }

  public translate(str: string): string {
    str = this._replaceProblematicAbbreviations(str);
    str = this._stripArrayIndex(str);

    const parts = this._splitOnPeriods(str);
    parts[0] = parts[0].split('$')[0];

    for (let i = 0; i < parts.length; i++) {
      if (i === 0)
        parts[i] = this._capitalize(parts[i]);
      else
        parts[i] = this._deCamelCase(parts[i]);
    }

    parts.reverse();

    return parts.map(this._prettifyKnownAbbreviations).join(' van ');
  }

  private _replaceProblematicAbbreviations(str: string): string {
    return str
      .replace('Geb.datum', 'Geboortedatum')
      .replace('$VRIJ_VELD$', 'vrij veld ');
  }

  private _splitOnPeriods(str: string): string[] {
    let buff: string = '';
    let arr: string[] = [];
    for (let i = 0; i < str.length; i++) {

      if (str.charAt(i) === '.' && !this._isKnownAbbreviation(buff) && !this._isPunctuation(str, i)) {
        // treat abbreviations like 't.b.v.' containing multiple periods separately
        let charsRemainingInMultiPeriodAbbr = this._isStartOfMultiPeriodAbbreviation(buff, str.substring(i));

        if (charsRemainingInMultiPeriodAbbr !== 0) {
          buff += str.substring(i, i + charsRemainingInMultiPeriodAbbr + 1);
          i += charsRemainingInMultiPeriodAbbr;
        } else {
          // flush buffer to array
          arr.push(buff);
          buff = '';
        }
      } else {
        buff += str.charAt(i);
      }
    }

    if (buff.length > 0)
      arr.push(buff);

    if (arr.length === 0)
      arr.push('');

    return arr;
  }

  // Removes array index ('[42]' in 'foo[42]') from the given string
  private _stripArrayIndex(str: string): string {
    let openingBracket: number = -1;
    let closingBracket: number = -1;
    for (let i = 0; i < str.length; i++) {
      let char = str.charAt(i);
      if (char === '[') {
        openingBracket = i;
      } else if (openingBracket !== -1 && char === ']') {
        closingBracket = i;

        let replacementForArrayIndex = '';
        if (str.length > closingBracket + 1 && this._isAlphaNumeric(str.charAt(closingBracket + 1)))
          replacementForArrayIndex = '.';

        str = str.replace(str.substring(openingBracket, closingBracket + 1), replacementForArrayIndex);

        i = i - (closingBracket - openingBracket);
        openingBracket = -1;
        closingBracket = -1;
      } else if (openingBracket !== -1 && closingBracket === -1 && !(char >= '0' && char <= '9')) {
        openingBracket = -1;
      }
    }

    return str;
  }

  private _isKnownAbbreviation(str: string): boolean {
    str = str.toLowerCase();
    return str === 'll'
      || str === 'leerl'
      || str === 'llnr'
      || str === 'tel'
      || str === 'leerli'
      || str === 'etc'
      || str === 'lj'
      || str === 'afk';
  }

  private _isPunctuation(str: string, idx: number): boolean {
    // next char is not aplhanumeric or EOL or previous char is not alphanumeric.
    return ((idx + 1 < str.length) && !this._isAlphaNumeric(str.charAt(idx + 1)))
      || (idx === (str.length - 1))
      || (idx > 0 && !this._isAlphaNumericOrArrayBracket(str.charAt(idx - 1)));
  }

  private _isAlphaNumericOrArrayBracket(char: string): boolean {
    return char === ']' || this._isAlphaNumeric(char);
  }

  private _isAlphaNumeric(char: string): boolean {
    return !(new RegExp('[!-\/:-@[-`{-~]').test(char));
  }

  // returns # remaining chars in abbreviation excluding the first period and before.
  private _isStartOfMultiPeriodAbbreviation(str: string, remainingStr: string): number {
    let startOfStr = str.toLowerCase() + remainingStr.toLowerCase();
    if (startOfStr.substring(str.length - 1).startsWith('t.b.v.'))
      return 4;
    if (startOfStr.substring(str.length - 3).startsWith('adv. lj.'))
      return 4;
    return 0;
  }

  private _deCamelCase(str: string): string {
    if (str === 'SyncKey')
      return str;

    let prettyString: string = '';
    for (let i = 0; i < str.length; i++) {
      let c = str.charAt(i);
      if (this._isCapitalLetter(c) && i > 0 &&
        // Abbreviations such as BRP or NRO or caps following a special character may remain capitalized.
        !(this._isCapitalLetter(str.charAt(i - 1)) || !this._isAlphaNumeric(str.charAt(i - 1)))) {
        c = ' ' + c.toLowerCase();
      }

      prettyString = prettyString + c;
    }

    return prettyString;
  }

  private _isCapitalLetter(char: string): boolean {
    return char === char.toUpperCase() && char !== char.toLowerCase()
  }

  private _capitalize(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  private _prettifyKnownAbbreviations(str: string): string {
    return str
      .replace('brp', 'BRP')
      .replace('lj.', 'leerjaar')
      .replace('Lj.', 'Leerjaar')
      .replace('Lj', 'Leerjaar')
      .replace('adv.', 'advies')
      .replace('lln.', 'leerlingen')
      .replace('Llnr.', 'Leerlingnummer')
      .replace('Llnr', 'Leerlingnummer')
      .replace('ll.nr', 'leerlingnummer')
      .replace('Ll.nr', 'Leerlingnummer')
      .replace('oin', 'OIN')
      .replace('adv.', 'advies')
      .replace('Adv.', 'Advies')
      .replace('borapport', 'BOrapport')
      .replace(' nAW', ' NAW')
      .replace(' naW', ' NAW')
      .replace(' naw', ' NAW');
  }
}
