import { createReducer, on } from '@ngrx/store';
import {DashboardSettingsState, Vestiging} from './dashboardsettings.state';
import { datumbereikenFetched, setVestigingen } from './dashboardsettings.actions';
import { findWithHighestDateAndSmallestPeriod } from '../../stateless/datumbereik';
import moment from 'moment';

const initialState: DashboardSettingsState = {
    vestigingen: null,
    defaultVestiging: null,
    datumbereiken: null,
    defaultPeriode: 'WEEK',
    defaultEpochSeconds: moment().startOf('isoWeek').seconds()
};

export const dashboardSettingsReducer = createReducer(
    initialState,
    on(setVestigingen, (state, { value }) => ({
      ...state,
      vestigingen: value,
      defaultVestiging: (value.length > 0 ? findEesteVestigingMetData(value) : null)
    })),
    on(datumbereikenFetched, (state, { value }) => {
        if (!value || value.length === 0) {
            return {
                ...state,
                datumbereiken: [],
                defaultEpochSeconds: 0,
                defaultPeriode: 'WEEK'
            };
        }
        const rangeWithHighestDateAndSmallestPeriod = findWithHighestDateAndSmallestPeriod(value, state.defaultEpochSeconds, state.defaultPeriode);
        return {
            ...state,
            datumbereiken: value,
            defaultEpochSeconds: rangeWithHighestDateAndSmallestPeriod.epochSeconds,
            defaultPeriode: rangeWithHighestDateAndSmallestPeriod.periode
        };
    })
);

function findEesteVestigingMetData(vestigingen: Vestiging[]): Vestiging {
  return vestigingen.find(v => v.heeftData);
}
