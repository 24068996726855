import {LeermiddelVakKoppelingInfo, VakInfo} from '../../../generated/graphql';

export interface LeermiddelvakkenlijstData {
  naam: string;
  afkorting: string;
  id: number;
  geselecteerd: boolean;
}

export function createTabelData(data: LeermiddelVakKoppelingInfo, vakken: VakInfo[]): LeermiddelvakkenlijstData[] {
  if (!vakken || vakken.length === 0) {
    return null;
  }
  const tabelData: LeermiddelvakkenlijstData[] = [];
  const afkortingen = data.vakken.map(v => v?.afkorting);
  vakken.map(row => tabelData.push({
    naam: row.naam,
    afkorting: row.afkorting,
    id: row.id,
    geselecteerd: afkortingen ? afkortingen.includes(row.afkorting) : false
  }));
  return tabelData.sort((a, b) => {
    const vergelijkTitel = a.naam.localeCompare(b.naam);
    if (vergelijkTitel === 0) {
      return a.afkorting.localeCompare(b.afkorting);
    }
    return vergelijkTitel;
  });
}
