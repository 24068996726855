import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, HostListener } from '@angular/core';
import {
    selectGoBackURL,
    selectSemPartyDetails
} from '../state/privacydashboard/privacydashboard.selectors';
import { Store } from '@ngrx/store';
import { AppState } from '../state/app.state';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { KoppelingHeaderButton, KoppelingHeaderViewModel } from '../layout/koppeling-header/koppeling-header.component';
import { map, takeWhile } from 'rxjs/operators';
import { PrivacydashboardFacade } from '../state/privacydashboard/privacydashboard.facade';
import { GoBackHeaderViewModel } from '../layout/go-back-header/go-back-header.component';
import { KoppelingMenuItem, KoppelingMenuViewModel } from '../layout/koppeling-menu/koppeling-menu.component';
import { AVGSemPartyViewPageEntry } from 'src/generated/avg-client';
import { fetchSemPartyDetails, updateSemPartyConsent } from '../state/privacydashboard/privacydashboard.actions';
import { selectKoppelpartijParam } from '../state/router/router.selectors';
import { SemKoppelingSchoolPanelViewModel } from '../layout/sem-koppeling-school-panel/sem-koppeling-school-panel.component';

@Component({
    selector: 'app-sem-koppelpartij-details-page',
    templateUrl: './sem-koppelpartij-details-page.component.html',
    styleUrls: ['./sem-koppelpartij-details-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SemKoppelpartijDetailsPageComponent implements OnInit, OnDestroy {

    private subscriptions = [];

    public semPartyDetails: Observable<AVGSemPartyViewPageEntry>;
    public schools: Observable<SemKoppelingSchoolPanelViewModel[]>;

    public goBackHeaderViewModel: GoBackHeaderViewModel;
    public headerViewModel: KoppelingHeaderViewModel;
    public menuViewModel: KoppelingMenuViewModel;

    public omschrijvingTab: Observable<boolean>;
    public datatoegangTab: Observable<boolean>;

    private menuItemOmschrijving: KoppelingMenuItem = { caption: 'Omschrijving', key: 'omschrijving' };
    private menuItemDatatoegang: KoppelingMenuItem = { caption: 'Datatoegang', key: 'datatoegang' };

    public onGetShowModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public backURL: Observable<string>;

    public loading = this.store.selectLoading();

    @HostListener('window:click', ['$event'])
    clickout(): void {
        this.headerViewModel.showMenu.next(false);
    }

    constructor(private store: PrivacydashboardFacade, private appState: Store<AppState>) { }

    ngOnInit(): void {
        this.appState.select(selectKoppelpartijParam)
        .pipe(takeWhile(semPartyUUID => !semPartyUUID, true)).subscribe((semPartyUUID) => {
            if (semPartyUUID) {
                this.appState.dispatch(fetchSemPartyDetails({semPartyUUID}));
            }
        });

        this.semPartyDetails = this.appState.select(selectSemPartyDetails);
        this.schools = this.semPartyDetails.pipe(map(party => party.consent.map(consent => ({
            onGetSchoolName: of(consent.vestigingDescription),
            onGetSchoolId: of(consent.digiDeliveryId),
            onGetSemPartyName: of(party.name),
            onGetLocalConsent: of(consent.localConsent),
            onGetRemoteConsent: of(consent.remoteConsent),
            onGetIsWorking: of(false),
            doSetLocalConsent: (newConsent: boolean) => this.appState.dispatch(updateSemPartyConsent({semPartyUUID: party.uuid, digiDeliveryId: consent.digiDeliveryId, consent: newConsent}))
        }))));

        this.backURL = this.appState.select(selectGoBackURL);

        this.loadGoBackHeaderViewModel();
        this.loadMenuViewModel();
        this.loadHeaderViewModel();
    }

    loadGoBackHeaderViewModel(): void {
        this.subscriptions.push(this.backURL.subscribe(url => {
            if (url) {
                this.goBackHeaderViewModel = {
                    title: null,
                    backUrl: url,
                    backTitle: 'Terug naar overzicht'
                };
            }
        }));
    }

    loadMenuViewModel(): void {
        const activeMenuKey: BehaviorSubject<string> = new BehaviorSubject(this.menuItemOmschrijving.key);
        const menuItems: BehaviorSubject<KoppelingMenuItem[]> = new BehaviorSubject<KoppelingMenuItem[]>([this.menuItemOmschrijving, this.menuItemDatatoegang]);

        this.menuViewModel = {
            onGetMenuItems: menuItems,
            onGetActiveKey: activeMenuKey,

            doSetMenuItems: menuItems,
            doSetActiveKey: activeMenuKey
        };

        this.omschrijvingTab = this.menuViewModel.onGetActiveKey.pipe(map(k => k === 'omschrijving'));
        this.datatoegangTab = this.menuViewModel.onGetActiveKey.pipe(map(k => k === 'datatoegang'));
        this.backURL.pipe(map(url => url.includes('aanbod') ? activeMenuKey.next(this.menuItemOmschrijving.key) : activeMenuKey.next(this.menuItemDatatoegang.key)));
    }

    loadHeaderViewModel(): void {
        const buttonStyle: Observable<ButtonStyle> = of({text: 'Koppeling beheren', class: 'green'});

        this.headerViewModel = {
            img: this.semPartyDetails.pipe(map(s => s?.image ? s?.image : 'assets/img/icons/Symbool/Koppelpartij_icon_placeholder.svg')),
            title: this.semPartyDetails.pipe(map(s => s?.name)),
            subTitle: this.semPartyDetails.pipe(map(s => s?.organizationName)),
            button: buttonStyle.pipe(map(style => style.text)),
            buttonClass: buttonStyle.pipe(map(style => style.class)),
            buttonIcon: of(''),
            onButtonClick: () => this.openKoppelingBeheerModal(),
            menu: new BehaviorSubject<KoppelingHeaderButton[]>([]),
            showMenu: new BehaviorSubject<boolean>(false)
        };
    }

    public openKoppelingBeheerModal(): void {
        this.onGetShowModal.next(true);
    }

    public closeKoppelingBeheerModal(): void {
        this.onGetShowModal.next(false);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}

interface ButtonStyle {
    class: string;
    text: string;
}
