import {ChangeDetectionStrategy, Component, HostListener, Input} from '@angular/core';
import {background_4, background_1} from '../../../shared/colors';
import {FONT_FAMILY} from '../../../shared/fonts';
import {LineChartModel} from './line-chart.model';
import {GoogleChartInterface} from 'ng2-google-charts';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LineChartComponent {
  @Input() set model(model: LineChartModel) {
    const {table, colors} = model;
    // @ts-ignore
    this.chartData.options.hAxis.title = table[0][0];
    this.chartData.dataTable = table;
    if (colors) {
      Object.assign(this.chartData.options, {colors});
    }
    if (this.chartData.component) {
      this.chartData.component.draw();
    }
  }

  chartData: GoogleChartInterface = {
    chartType: 'LineChart',
    dataTable: [],
    options: {
      hAxis: {
        titleTextStyle: { italic: false }
      },
      vAxis: {
        minValue: 0,
        maxValue: 1,
        gridlines: {
          color: 'none'
        },
        baseline: { color: 'none' },
        format: 'percent'
      },
      chartArea: {
        backgroundColor: {
          fill: background_4,
          stroke: background_1,
          strokeWidth: 1
        },
        left: 40,
        right: 100
      },
      backgroundColor: { fill: 'none' },
      legend: { alignment: 'center' },
      fontName: FONT_FAMILY
    }
  };

  @HostListener('window:resize')
  onResize() {
    if (this.chartData.component) {
      this.chartData.component.draw();
    }
  }
}
