<div class="header">
    <p class="subHeading">Vestigingen</p>
    <div [class]="toevoegenButtonStyle" (click)="selectieSelecteren()">
        <p class="aantal bodyHeading">{{aantalGeselecteerd}}</p>
        <p class="toevoegen bodySmallContentSemi">Kiezen</p>
    </div>
    <div class="contain-sluiten" ><div class="sluiten" (click)="sluiten()"></div></div>
</div>
<div class="scrollContainer">
  <div *ngIf="infoBlock" class="vestigingen-infoblok">
    <i class="icon-info"></i>
    <p class="bodySmallContent">{{infoBlock}}</p>
  </div>
  <ul>
    <ng-container *ngFor="let row of rows;">
        <li>
            <div class="vestiging" (click)="onRowClicked(row)">
                <div class="checkbox"><i [class]="row.selected ? 'checked' : 'unchecked'"></i></div>
                <p class="bodyContent naam">{{row.vestiging.naam}}</p>
            </div>
        </li>
    </ng-container>
  </ul>
</div>
