import {AfterViewInit, Component, ElementRef, HostListener, Input, ViewChild} from '@angular/core';
import {accent_positive_2} from '../../shared/colors';
import {FONT_FAMILY} from '../../shared/fonts';
import {RangeLinechartVoorBazenBannerModel} from '../range-linechart/range-linechart.model';
import {SharedVariablesService} from '../../services/sharedVariables.service';
import {periodeNaarDatums} from '../../services/datumbereik';
import * as m from 'moment';
import {GoogleChartInterface} from 'ng2-google-charts';

@Component({
  selector: 'app-range-linechart-voor-bazenbanner',
  templateUrl: './range-linechart-voor-bazenbanner.component.html',
  styleUrls: ['./range-linechart-voor-bazenbanner.component.scss']
})
export class RangeLinechartVoorBazenbannerComponent implements AfterViewInit {
  data: RangeLinechartVoorBazenBannerModel[];

  gemiddelde: number;

  @Input()
  set gemGebruik(gemGebruik: number) {
    this.gemiddelde = Math.round(gemGebruik * 10) / 10;
  }

  margins: [number, number];

  @ViewChild('getal')
  private getalContainer: ElementRef;

  bgGetalContainerWidth: string;

  @Input()
  bgcolor: string;

  @Input()
  set model(data: RangeLinechartVoorBazenBannerModel[]) {
    this.data = data;
    this.chartData.dataTable.rows = data.map(({date, value}) => {
      return ({c: [{v: date}, {v: value}]});
    });
    const dates = data.map(({date}) => date).sort((a, b) => a.getTime() - b.getTime());
    if (dates.length > 0) {
      (this.chartData.options as any).hAxis.ticks = [dates[0], dates[dates.length - 1]];
    }
    if (this.chartData.component) {
      this.chartData.component.draw();
    }
  }

  chartData: GoogleChartInterface = {
    chartType: 'AreaChart',
    dataTable: {
      cols: [
        {type: 'date', label: 'datum'},
        {type: 'number', label: 'gebruik'}
      ],
      rows: []
    },
    options: {
      hAxis: {
        gridlines: {
          color: 'none'
        },
        format: 'd MMM',
        ticks: [],
        viewWindowMode: 'maximized'
      },
      vAxis: {
        minValue: 0,
        gridlines: {
          color: 'none'
        },
        baseline: { color: 'none' },
        textPosition: 'none'
      },
      chartArea: {
        width: '100%',
        height: '100%',
        bottom: 20,
        left: 0,
        right: 0
      },
      backgroundColor: { fill: 'none' },
      legend: 'none',
      fontName: FONT_FAMILY,
      pointsVisible: false,
      colors: [accent_positive_2],
      areaOpacity: 0.05
    }
  };

  constructor(
    private service: SharedVariablesService
  ) { }

  onReady() {
    const cli = this.chartData.component.wrapper.getChart().getChartLayoutInterface();
    const {width} = cli.getChartAreaBoundingBox();
    if (this.service.periode) {
      this.margins = [
        cli.getXLocation(m(periodeNaarDatums(this.service.periode).from).toDate() as any),
        width - cli.getXLocation(m(periodeNaarDatums(this.service.periode).to).toDate() as any)
      ]; }
  }

  @HostListener('window:resize')
  onResize() {
    if (this.chartData.component) {
      this.chartData.component.draw();
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.getalContainer) {
        const el = this.getalContainer.nativeElement as HTMLElement;
        this.bgGetalContainerWidth = `${el.clientWidth + 32}px`;
      }
    });
  }
}
