import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { AVGSemPartyMarketplaceEntry } from 'src/generated/avg-client';

@Component({
    selector: 'app-sem-koppelpartij-tegel',
    templateUrl: './sem-koppelpartij-tegel.component.html',
    styleUrls: ['./sem-koppelpartij-tegel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '(click)': 'onSemPartyClicked(semParty.uuid)'
    }
})
export class SemKoppelpartijTegelComponent implements OnInit {

    @Input()
    semParty: AVGSemPartyMarketplaceEntry;

    @Output()
    openSemParty = new EventEmitter<string>();

    constructor() { }

    ngOnInit(): void {
    }

    onSemPartyClicked(uuid: string): void {
        this.openSemParty.emit(uuid);
    }
}
