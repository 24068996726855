import {Component, OnInit, ChangeDetectionStrategy, Input} from '@angular/core';
import {TableCellComponent} from '../table/table.model';
import {LesgroepDocentenCellModel} from './lesgroep-docenten-cell.model';

@Component({
  selector: 'app-lesgroep-docenten-cell',
  templateUrl: './lesgroep-docenten-cell.component.html',
  styleUrls: ['./lesgroep-docenten-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LesgroepDocentenCellComponent implements OnInit, TableCellComponent<LesgroepDocentenCellModel[]> {
  @Input()
  data: LesgroepDocentenCellModel[];

  eerste: string;

  rest: string;

  ngOnInit(): void {
    if (this.data && this.data.length) {
      this.data.sort((a, b) => a.achternaam.toLocaleUpperCase().localeCompare(b.achternaam.toLocaleUpperCase()));
      const [eerste, ...rest] = this.data;
      const naam = ({voornaam, tussenvoegsel, achternaam}: LesgroepDocentenCellModel) =>
        [voornaam, tussenvoegsel, achternaam].filter(v => !!v).join(' ');
      this.eerste = naam(eerste);
      this.rest = rest.reduce((acc, v, i, arr) =>
        acc + (i > 0 ? i === arr.length - 1 ? ' en ' : ', ' : '') + naam(v), '');
    }
  }
}
