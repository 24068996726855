import { ChangeDetectionStrategy, Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { primary_3, background_1 } from '../../shared/colors';
import { FONT_FAMILY } from '../../shared/fonts';
import { GoogleChartInterface } from 'ng2-google-charts';
import { RangeLinechartModelKeyBased } from '../range-linechart/range-linechart.model';
import { Observable, Subscription } from 'rxjs';

@Component({
    selector: 'app-range-linechart-keybased-new',
    templateUrl: './range-linechart-keybased-new.component.html',
    styleUrls: ['./range-linechart-keybased-new.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RangeLinechartKeybasedNewComponent implements OnInit, OnDestroy {
    public showTooltip = false;
    public tooltip: string;
    public gebruikOpPunt: string;

    public chartData: GoogleChartInterface;

    @Input()
    public viewModel: RangeLinechartKeybasedViewModel;

    private range: RangeLinechartModelKeyBased[] = [];

    private subscriptions: Subscription[] = [];

    ngOnInit(): void {
        this.prepareChartData();

        this.subscriptions.push(
            this.viewModel.onGetRange.subscribe((range) => {
                this.draw(range);
                this.range = range;
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    private prepareChartData(): void {
        this.chartData = {
            chartType: 'LineChart',
            dataTable: {
                cols: [
                    { type: 'string', label: 'datum' },
                    { type: 'number', label: 'activatie' },
                    { id: 'i0', type: 'number', p: { role: 'interval' } },
                    { id: 'i1', type: 'number', p: { role: 'interval' } }
                ],
                rows: []
            },
            options: {
                height: 60,
                width: this.calculateWidth(),
                hAxis: {
                    gridlines: {
                        color: 'none'
                    },
                    ticks: [],
                    textPosition: 'none'
                },
                vAxis: {
                    minValue: 0,
                    gridlines: {
                        color: 'none'
                    },
                    baseline: { color: 'none' },
                    textPosition: 'none'
                },
                chartArea: {
                    width: '100%',
                    height: '100%',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: {
                        stroke: background_1,
                        strokeWidth: 1
                    }
                },
                backgroundColor: { fill: 'none' },
                legend: 'none',
                intervals: { style: 'area', color: background_1 },
                fontName: FONT_FAMILY,
                tooltip: {
                    trigger: 'none'
                },
                pointsVisible: false,
                colors: [primary_3],
                areaOpacity: 0.0,
            }
        };
    }

    private draw(range: RangeLinechartModelKeyBased[]): void {
        if (!this.chartData || !this.chartData.dataTable || !range || range.length === 0) {
            return;
        }

        this.chartData.dataTable.rows = range.map(({ key, values }) => {
            const { min, avg, max } = values;
            return ({ c: [{ v: key }, { v: avg }, { v: min }, { v: max }] });
        });

        if (range.length > 0) {
            (this.chartData.options as any).hAxis.ticks = [0, range.length - 1];
            (this.chartData.options as any).hAxis.viewWindow = { min: 0.5, max: range.length - 0.5 };
        }

        this.chartData.component?.draw();
    }

    public onMouseMove(event: MouseEvent): void {
        this.showTooltip = true;
        const { width } = this.chartData.component.wrapper.getChart().getChartLayoutInterface().getChartAreaBoundingBox();
        if (width > 0 && this.range?.length > 0) {
            const row = Math.round((this.range.length - 1) * event.offsetX / width);
            const selected = this.range[row];
            this.gebruikOpPunt = selected.values.avg?.toFixed(1) + ' keer';
            this.tooltip = selected.key;
        }
    }

    public onMouseLeave(): void {
        this.showTooltip = false;
    }

    @HostListener('window:resize')
    onResize(): void {
        if (this.chartData.component !== null && this.chartData.component !== undefined) {
            this.chartData.options.width = this.calculateWidth();
            this.chartData.component.draw();
        }
    }

    private calculateWidth(): number {
        if (window.innerWidth > 1200) {
            return 280;
        }
        return 200;
    }
}

export class RangeLinechartKeybasedViewModel {
    onGetRange: Observable<RangeLinechartModelKeyBased[]>;
    onGetGemiddeldGebruik: Observable<string>;
}
