import {Injectable} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {stringify} from 'query-string';
import {Periode} from './datumbereik';
import {SharedVariablesService} from './sharedVariables.service';

@Injectable()
export class UrlService {

  constructor(  private router: Router,
                private route: ActivatedRoute,
                private service: SharedVariablesService) { }

  public updateQueryParams(url: any, params: Params) {
    const query = {...this.route.snapshot.queryParams, ...params};
    this.router.navigateByUrl(`${url.join('/')}?${stringify(query)}`);
  }

  public navigateToUrl(url: any) {
    this.router.navigateByUrl(`${url}?${stringify(this.route.snapshot.queryParams)}`);
  }

  handleQueryParams(q: Params) {
    if (q.groeperen !== undefined) {
      this.service.groeperenOpVak = q.groeperen !== 'onderwijssoort';
    }
    if (q.periode !== undefined) {
      this.service.periode = q.periode as Periode;
    }
    if (q.vak !== undefined) {
      this.service.openVakken = new Set(Array.isArray(q.vak) ? q.vak : [q.vak]);
    }
    if (q.ows !== undefined) {
      this.service.openOnderwijssoorten = new Set(Array.isArray(q.ows) ? q.ows : [q.ows]);
    }
    if (q.vestiging !== undefined) {
      this.service.setFilterVestiging(q.vestiging);
    }
  }
}
