import {Injectable} from '@angular/core';
import {AppState, selectSchooldashboard} from '../../state/app.state';
import {Store} from '@ngrx/store';
import {createEffect} from '@ngrx/effects';
import {catchError, filter, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {selectAppFilter} from '../../state/app.selectors';
import {setStudiewijzerLinkjes, setStudiewijzerLinkjesOverzichtLoading} from '../state/schooldashboard/schooldashboard.actions';
import {payload} from '../../state/payload';
import {StudiewijzerlinksService} from '../../services/studiewijzerlinks.service';
import {initialState} from '../state/schooldashboard/schooldashboard.reducer';
import {of} from 'rxjs';
import {reportError} from '../../state/errors/errors.actions';

@Injectable()
export class GroeperenOpPlatformEffect {
  $ = createEffect(() => this.store.select(selectSchooldashboard).pipe(
    map(v => v.groeperenOpPlatform),
    filter(v => v !== this.prev),
    tap(v => {
      this.prev = v;
      this.store.dispatch(setStudiewijzerLinkjesOverzichtLoading());
    }),
    withLatestFrom(this.store.select(selectAppFilter)),
    switchMap(([isPerPlatform, f]) => this.service.fetch(f, isPerPlatform)),
    map(payload),
    map(setStudiewijzerLinkjes),
    catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
  ));

  private prev = initialState.groeperenOpPlatform;

  constructor(private store: Store<AppState>, private service: StudiewijzerlinksService) {
  }
}
