import {Component} from '@angular/core';
import {TableCellComponent} from '../table/table.model';
import {LeermiddelnaamCellModel} from './leermiddelnaam-cell.model';

@Component({
  selector: 'app-leermiddelnaam-cell',
  templateUrl: './leermiddelnaam-cell.component.html',
  styleUrls: ['./leermiddelnaam-cell.component.scss']
})
export class LeermiddelnaamCellComponent implements TableCellComponent<LeermiddelnaamCellModel> {
  data: LeermiddelnaamCellModel;
}
