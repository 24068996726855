import {Injectable} from '@angular/core';
import {BehaviorSubject, forkJoin, Observable, of, Subject} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {OAuthService} from 'angular-oauth2-oidc';
import {TableModel} from '../table/table/table.model';
import {DataPerLeerling} from '../old-dashboarding/klassendashboard/klassendashboard.model';
import {catchError, map, tap} from 'rxjs/operators';
import {stringifyUrl} from 'query-string';
import {
  AVGExtendedKoppeling,
  AVGExtendedKoppelpartij,
  AVGExtendedVeldpermissie,
  AVGExtendedVestiging,
  AVGOAuthClient,
  AVGOrganisatie,
  AVGVestiging,
  AVGWritePermissie
} from '../dto/avg-dashboard-model-classes';
import {Koppelpartij} from '../state/dev-koppelpartij/dev-koppelpartij.state';
import {
  ExtendedKoppeling,
  Webservice,
  WebserviceAccountGebruik,
  WebserviceDataPoint
} from '../state/privacydashboard/privacydashboard.state';

@Injectable()
export class SomtodayService {
  private leerlingen$ = new Subject<TableModel<DataPerLeerling>>();

  private medewerkers$ = new Subject<RMedewerker[]>();

  private debug = false;

  constructor(
    private http: HttpClient,
    private oauthService: OAuthService
  ) {
  }

  get leerlingen(): Subject<TableModel<DataPerLeerling>> {
    return this.leerlingen$;
  }

  get medewerkers(): Subject<RMedewerker[]> {
    return this.medewerkers$;
  }

  public fetchLeerlingen(leerlingUUIDs: string[]): Observable<RLeerling[]> {
    if (leerlingUUIDs.length === 0) {
      return of([]);
    } else {
      const leerlingUUIDsAsQParams = leerlingUUIDs.map(uuid =>
        '\'' + uuid + '\'').join(',');

      const normaleLeerlingnamen: Observable<Response<RLeerling>> = this.http.get<Response<RLeerling>>(
        this.findSomtodayApiUrl() + 'leerlingen?q=UUID=' + leerlingUUIDsAsQParams
      );

      if (this.isBornego()) {
        const bornegoUUIDs = leerlingUUIDs.map(uuid => uuid.toUpperCase()).map(uuid =>
          '\'' + uuid + '\'').join(',');

        const bornegoLeerlingnamen: Observable<Response<RLeerling>> = this.http.get<Response<RLeerling>>(
          this.findSomtodayApiUrl() + 'leerlingen?q=UUID=' + bornegoUUIDs
        );

        return forkJoin([normaleLeerlingnamen, bornegoLeerlingnamen]).pipe(
          map(([{items: normalos}, {items: bornegos}]) => [
            ...normalos,
            ...bornegos.map(l => ({...l, UUID: l.UUID.toLowerCase()}))
          ])
        );
      } else {
        return normaleLeerlingnamen.pipe(map(r => r.items));
      }
    }
  }

  public fetchLeerlingNamen(leerlingUUIDs: string[]): Observable<{uuid: string, naam: string}[]> {
    let url = this.findSomtodayApiUrl() + 'leermiddeldashboard/leerlingen?';
    leerlingUUIDs.forEach(llUUID => {
      url = url + 'uuids=' + llUUID + '&';
    });
    return this.http.get<RLeerling[]>(url).pipe(map((leerlingen: RLeerling[]) => leerlingen.map(leerling => {
      return Object.assign({uuid: leerling.UUID, naam: this.extractNaam(leerling)});
    })));
  }

  public fetchMedewerkers(docentUUIDs: string[]): Observable<RMedewerker[]> {
    if (docentUUIDs.length === 0) {
      return of([]);
    } else {
      const docentUUIDsAsQParams = docentUUIDs.map(uuid => '\'' + uuid + '\'').join(',');

      const normaleMedewerkers: Observable<Response<RMedewerker>> = this.http.get<Response<RMedewerker>>(
        this.findSomtodayApiUrl() + 'medewerkers?me=false&q=uuid=' + docentUUIDsAsQParams);

      if (this.isBornego()) {
        const bornegoUUIDs = docentUUIDs.map(uuid => uuid.toUpperCase()).map(uuid => '\'' + uuid + '\'').join(',');

        const bornegoMedewerkers: Observable<Response<RMedewerker>> = this.http.get<Response<RMedewerker>>(
          this.findSomtodayApiUrl() + 'medewerkers?me=false&q=uuid=' + bornegoUUIDs);

        return forkJoin([normaleMedewerkers, bornegoMedewerkers]).pipe(
          map(([{items: standaard}, {items: bornegos}]) => [
            ...standaard,
            ...bornegos.map(mw => ({...mw, UUID: mw.UUID.toLowerCase()}))
          ]));
      } else {
        return normaleMedewerkers.pipe(map(response => response.items));
      }
    }
  }

  public fetchMedewerkerNamen(medewerkerUUIDs: string[]): Observable<{uuid: string, naam: string}[]> {
    let url = this.findSomtodayApiUrl() + 'leermiddeldashboard/medewerkers?';
    medewerkerUUIDs.forEach(mwUUID => {
        if (mwUUID !== null) {
            url = url + 'uuids=' + mwUUID + '&';
        }
    });
    return this.http.get<RMedewerker[]>(url).pipe(map((medewerkers: RMedewerker[]) => medewerkers.map(medewerker => {
        return Object.assign({uuid: medewerker.UUID, naam: this.extractNaam(medewerker)});
      })));
  }

  private extractNaam(persoon: RMedewerker | RLeerling): string {
    let fullName = '';
    if (persoon.roepnaam) { fullName = persoon.roepnaam; }
    if (persoon.voorvoegsel) { fullName = fullName.length > 0 ? fullName + ' ' + persoon.voorvoegsel : persoon.voorvoegsel; }
    if (persoon.achternaam) { fullName = fullName.length > 0 ? persoon.achternaam + ', ' + fullName : persoon.achternaam; }
    return fullName;
  }

  public fetchMe(): Observable<RMedewerker> {
    const url = stringifyUrl({
      url: this.findSomtodayApiUrl() + 'medewerkers',
      query: {
        me: true,
        additional: ['email', 'avg-dashboard-inzage', 'leermiddel-dashboard-inzage', 'avg-dashboard-toegang', 'organisatieNaam']
      }
    });
    return this.http.get<Response<RMedewerker>>(url).pipe(map(({items: [me]}) => me),
      map((me: RMedewerker) => {
        me.isLandelijkBeheerSupport = me.additionalObjects?.organisatieNaam === 'LAB' && me.nummer === -10;
        return me;
    }));
  }

  public fetchOAuthClient(omgeving: string, oauthclient: string): Observable<AVGOAuthClient> {
    if (this.debug) {
      console.log('calls fetchOAuthClient()');
    }
    const url = stringifyUrl({
      url: `${this.findSomtodayApiUrl()}avgdashboardsupport/oauthclient/${omgeving}/${oauthclient}`,
      query: {}
    });
    return this.http.get<AVGOAuthClient>(url).pipe(
      tap(payload => {
        if (this.debug) {
          console.log('fetchOAuthClient()');
          console.log(payload);
        }
      }),
    );
  }

  public fetchOrganisaties(): Observable<AVGOrganisatie[]> {
    if (this.debug) {
      console.log('calls fetchOrganisaties()');
    }
    const url = stringifyUrl({
      url: `${this.findSomtodayApiUrl()}avgdashboardsupport/organisatie`,
      query: {}
    });
    return this.http.get<AVGOrganisatie[]>(url).pipe(tap(payload => {
      if (this.debug) {
        console.log('fetchOrganisaties()');
        console.log(payload);
      }
    }));
  }

  public fetchSchrijfpermissies(): Observable<AVGWritePermissie[]> {
    if (this.debug) {
      console.log('calls fetchSchrijfpermissies()');
    }
    const url = stringifyUrl({
      url: `${this.findSomtodayApiUrl()}avgdashboardsupport/schrijfpermissie`,
      query: {}
    });
    return this.http.get<AVGWritePermissie[]>(url).pipe(tap(payload => {
      if (this.debug) {
        console.log('fetchSchrijfpermissies()');
        console.log(payload);
      }
    }));
  }

  public fetchLeespermissies(): Observable<AVGExtendedVeldpermissie[]> {
    if (this.debug) {
      console.log('calls fetchLeespermissies()');
    }
    const url = stringifyUrl({
      url: `${this.findSomtodayApiUrl()}avgdashboardsupport/leespermissie`,
      query: {}
    });
    return this.http.get<AVGExtendedVeldpermissie[]>(url).pipe(tap(payload => {
      if (this.debug) {
        console.log('fetchLeespermissies()');
        console.log(payload);
      }
    }));
  }

  public fetchEndpoints(): Observable<string[]> {
    if (this.debug) {
      console.log('calls fetchEndpoints()');
    }
    const url = stringifyUrl({
      url: `${this.findSomtodayApiUrl()}avgdashboardsupport/endpoint`,
      query: {}
    });
    return this.http.get<string[]>(url).pipe(tap(payload => {
      if (this.debug) {
        console.log('fetchEndpoints()');
        console.log(payload);
      }
    }));
  }

  public fetchKoppelpartijen(): Observable<AVGExtendedKoppelpartij[]> {
    if (this.debug) {
      console.log('calls fatchKoppelpartijen()');
    }
    const url = stringifyUrl({
      url: `${this.findSomtodayApiUrl()}avgdashboard/koppelpartij`,
      query: {}
    });
    return this.http.get<AVGExtendedKoppelpartij[]>(url).pipe(tap(payload => {
      if (this.debug) {
        console.log('fetchKoppelpartijen()');
        console.log(payload);
      }
    }));
  }

  public fetchKoppelpartij(koppelpartijNaam: string): Observable<AVGExtendedKoppelpartij> {
    if (this.debug) {
      console.log('calls fetchKoppelpartij(' + koppelpartijNaam + ')');
    }
    const url = stringifyUrl({
      url: `${this.findSomtodayApiUrl()}avgdashboard/koppelpartij/${koppelpartijNaam}`,
      query: {}
    });
    return this.http.get<AVGExtendedKoppelpartij>(url).pipe(tap(payload => {
      if (this.debug) {
        console.log('fetchKoppelpartij(' + koppelpartijNaam + ')');
        console.log(payload);
      }
    }));
  }

  public fetchKoppelpartijByOAuthClient(omgeving: string, oauthclient: string): Observable<Koppelpartij> {
    if (this.debug) {
      console.log('calls fetchKoppelpartij for oauthclient: ' + oauthclient + ' on: ' + omgeving);
    }
    const url = stringifyUrl({
      url: `${this.findSomtodayApiUrl()}avgdashboardsupport/oauthclient/${omgeving}/${oauthclient}/koppelpartij`,
      query: {}
    });
    return this.http.get<Koppelpartij>(url).pipe(tap(payload => {
      if (this.debug) {
        console.log('fetchKoppelpartij for oauthclient: ' + oauthclient + ' on: ' + omgeving);
        console.log(payload);
      }
    }));
  }

  public putKoppelpartij(koppelpartij: Koppelpartij): Observable<Koppelpartij> {
    if (this.debug) {
      console.log('calls putKoppelpartij(' + koppelpartij + ')');
    }
    const url = stringifyUrl({
      url: `${this.findSomtodayApiUrl()}avgdashboardsupport/koppelpartij/${koppelpartij.uuid}`,
      query: {}
    });
    return this.http.put<Koppelpartij>(url, koppelpartij).pipe(tap(payload => {
      if (this.debug) {
        console.log('putKoppelpartij()');
        console.log(payload);
      }
    }));
  }

  public postKoppelpartij(koppelpartij: Koppelpartij): Observable<Koppelpartij> {
    if (this.debug) {
      console.log('calls postKoppelpartij(' + koppelpartij + ')');
    }
    const url = stringifyUrl({
      url: `${this.findSomtodayApiUrl()}avgdashboardsupport/koppelpartij`,
      query: {}
    });
    return this.http.post<Koppelpartij>(url, koppelpartij).pipe(tap(payload => {
      if (this.debug) {
        console.log('postKoppelpartij()');
        console.log(payload);
      }
    }));
  }

  public fetchKoppeling(koppelpartijNaam: string, vestigingUUIDs: string[]): Observable<ExtendedKoppeling[] > {
    if (this.debug) {
      console.log('calls fetchKoppeling(' + koppelpartijNaam + ', [' + vestigingUUIDs.join(', ') + '])');
    }
    const url = stringifyUrl({
      url: `${this.findSomtodayApiUrl()}avgdashboard/koppelpartij/${koppelpartijNaam}/koppeling`,
      query: {vestigingUUIDs}
    });
    return this.http.get<ExtendedKoppeling[]>(url).pipe(tap(payload => {
      if (this.debug) {
        console.log('fetchKoppeling(' + koppelpartijNaam + ', [' + vestigingUUIDs.join(', ') + '])');
        console.log(payload);
      }
    }));
  }

  public fetchVestigingInrichting(vestigingUUIDs: string[]): Observable<AVGExtendedVestiging[]> {
    if (this.debug) {
      console.log('calls fetchVestigingInrichting([' + vestigingUUIDs.join(', ') + '])');
    }
    const url = stringifyUrl({
      url: `${this.findSomtodayApiUrl()}avgdashboard/vestiging/inrichting`,
      query: {vestigingUUIDs}
    });
    return this.http.get<AVGExtendedVestiging[]>(url).pipe(tap(payload => {
      if (this.debug) {
        console.log('fetchVestigingInrichting([' + vestigingUUIDs.join(', ') + '])');
        console.log(payload);
      }
    }));
  }

  public fetchVestigingen(): Observable<AVGVestiging[]> {
    if (this.debug) {
      console.log('calls fetchVestigingen()');
    }
    const url = stringifyUrl({
      url: `${this.findSomtodayApiUrl()}avgdashboard/vestiging`,
      query: {}
    });
    return this.http.get<AVGVestiging[]>(url).pipe(tap(payload => {
      if (this.debug) {
        console.log('fetchVestigingen()');
        console.log(payload);
      }
    }));
  }

  public postKoppeling(koppeling: AVGExtendedKoppeling, koppelpartijUUID: string): Observable<AVGExtendedKoppeling> {
    if (this.debug) {
      console.log('calls postKoppeling()');
    }
    const url = stringifyUrl({
      url: `${this.findSomtodayApiUrl()}avgdashboard/koppelpartij/${koppelpartijUUID}/koppeling`,
      query: {}
    });
    return this.http.post<AVGExtendedKoppeling>(url, koppeling).pipe(tap(payload => {
      if (this.debug) {
        console.log('postKoppeling()');
        console.log(payload);
      }
    }));
  }

  public deleteKoppeling(koppelpartijUUID: string, vestigingUUID: string): Observable<string> {
      if (this.debug) {
          console.log('calls deleteKoppeling()');
      }
      const url = stringifyUrl({
        url: `${this.findSomtodayApiUrl()}avgdashboard/koppelpartij/${encodeURIComponent(koppelpartijUUID)}/koppeling`,
        query: vestigingUUID ? {vestiging: vestigingUUID} : {}
      });
      return this.http.delete<boolean>(url, {observe: 'response', reportProgress: true}).pipe(
          tap(okResponse => {
              if (this.debug) {
                  console.log('deleteKoppeling()');
              }
              console.log(okResponse);
          }),
          map(response => (response.ok) ? koppelpartijUUID : null),
          catchError(_ => {
              return new BehaviorSubject(null);
          })
      );
  }

  public postSimpeleKoppeling(koppelpartijUUID: string): Observable<AVGExtendedKoppelpartij> {
    if (this.debug) {
      console.log('calls postSimpeleKoppeling()');
    }
    const url = stringifyUrl({
      url: `${this.findSomtodayApiUrl()}avgdashboard/koppelpartij/${koppelpartijUUID}/simpeleKoppeling`,
      query: {}
    });
    return this.http.post<AVGExtendedKoppelpartij>(url, {}).pipe(
      tap(payload => {
        if (this.debug) {
          console.log('postKoppeling()');
          console.log(payload);
        }
      })
    );
  }

  public deleteSimpeleKoppeling(koppelpartijUUID: string): Observable<AVGExtendedKoppelpartij> {
      if (this.debug) {
          console.log('calls deleteSimpeleKoppeling()');
      }
      const url = stringifyUrl({
        url: `${this.findSomtodayApiUrl()}avgdashboard/koppelpartij/${encodeURIComponent(koppelpartijUUID)}/simpeleKoppeling`,
        query: {}
      });
      return this.http.delete<AVGExtendedKoppelpartij>(url).pipe(
          tap(payload => {
              if (this.debug) {
                  console.log('deleteSimpeleKoppeling()');
                  console.log(payload);
              }
          })
      );
  }

  public postOpLeerlingGeautoriseerdeKoppeling(koppeling: AVGExtendedKoppeling, koppelpartijUUID: string): Observable<AVGExtendedKoppeling[]> {
    if (this.debug) {
      console.log('calls postOpLeerlingGeautoriseerdeKoppeling()');
    }
    const url = stringifyUrl({
      url: `${this.findSomtodayApiUrl()}avgdashboard/koppelpartij/${koppelpartijUUID}/opLeerlingGeautoriseerdeKoppeling`,
      query: {}
    });
    return this.http.post<AVGExtendedKoppeling[]>(url, koppeling).pipe(
      tap(payload => {
        if (this.debug) {
          console.log('postOpLeerlingGeautoriseerdeKoppeling()');
          console.log(payload);
        }
      })
    );
  }

  public fetchWebservices(): Observable<Webservice[]> {
    if (this.debug) {
      console.log('calls fetchWebservices()');
    }
    const url = stringifyUrl({
      url: `${this.findSomtodayApiUrl()}avgdashboard/webservices`,
      query: {}
    });
    return this.http.get<Webservice[]>(url).pipe(tap(payload => {
      if (this.debug) {
        console.log('fetchWebservices()');
        console.log(payload);
      }
    }));
  }

  public fetchWebserviceAccountGebruik(webserviceType: string): Observable<WebserviceAccountGebruik[]> {
    if (this.debug) {
      console.log('calls fetchWebserviceAccountGebruik()');
    }
    const url = stringifyUrl({
      url: `${this.findSomtodayApiUrl()}avgdashboard/webservice/accountgebruik`,
      query: {webservice: webserviceType}
    });
    return this.http.get<WebserviceAccountGebruik[]>(url).pipe(tap(payload => {
      if (this.debug) {
        console.log('fetchWebserviceAccountGebruik()');
        console.log(payload);
      }
    }));
  }

  public fetchWebserviceWeekGebruik(webserviceType: string): Observable<WebserviceDataPoint[]> {
    if (this.debug) {
      console.log('calls fetchWebserviceWeekGebruik()');
    }
    const url = stringifyUrl({
      url: `${this.findSomtodayApiUrl()}avgdashboard/webservice/gebruik`,
      query: {webservice: webserviceType}
    });
    return this.http.get<WebserviceDataPoint[]>(url).pipe(tap(payload => {
      if (this.debug) {
        console.log('fetchWebserviceWeekGebruik()');
        console.log(payload);
      }
    }));
  }

  public fetchChangelog(): Observable<string> {
    if (this.debug) {
      console.log('calls fetchChangelog');
    }
    const url = stringifyUrl({
      url: `${this.findSomtodayApiUrl()}connect/documented/changelog`,
      query: {}
    });
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.get(url, { headers, responseType: 'text' })
      .pipe(
        tap(payload => {
          if (this.debug) {
            console.log('fetchChangelog');
            console.log(payload);
          }
        })
      );
  }

  private findSomtodayApiUrl(): string {
    return `${this.oauthService.getCustomTokenResponseProperty('somtoday_api_url')}/rest/v1/`;
  }

  private isBornego(): boolean {
    const claims: any = this.oauthService.getIdentityClaims();
    if (!claims) {
      return false;
    }

    const instellingsUUID = (claims.sub as string).substring(0, 36);

    return instellingsUUID === '3f3fe45d-4e36-4431-98d2-d3cd828649f7';
  }
}

export const fetchMedewerkers = (service: SomtodayService) => (uuids: string[]) =>
  service.fetchMedewerkers(uuids).pipe(catchError(() => of<RMedewerker[]>([])));

// COBRA REST Infra objecten

interface Response<T> {
  items: T[];
}

export interface Link {
  id: number;
  rel: string;
  type: string;
  href: string;
}

export interface Permission {
  full: string;
  type: string;
  operations: string[];
  instances: string[];
}

// COBRA REST Entiteiten

export interface RLeerling {
  $type: string;
  links: Link[];
  permissions: Permission[];
  additionalObjects: {};
  UUID: string;
  leerlingnummer: number;
  roepnaam: string;
  achternaam: string;
  voorvoegsel: string;
  email: string;
  geboortedatum: string;
  geslacht: string;
}

export enum Geslacht {
  MAN,
  VROUW,
  ONBEKEND
}

export interface RMedewerker {
  $type: string;
  links: Link[];
  permissions: Permission[];
  additionalObjects: {
    email?: string;
    'avg-dashboard-inzage': boolean;
    'leermiddel-dashboard-inzage'?: boolean;
    'avg-dashboard-toegang': 'GEEN TOEGANG' | 'BEPERKTE TOEGANG' | 'VOLLEDIGE TOEGANG';
    organisatieNaam: string;
  };
  UUID: string;
  nummer: number;
  afkorting: string;
  achternaam: string;
  geslacht: Geslacht;
  voorvoegsel: string;
  voorletters: string;
  roepnaam: string;
  isLandelijkBeheerSupport: boolean;
}

export interface ROrganisatie {
  naam: string;
  UUID: string;
}

export interface Changelog {
  markdown: string;
}
