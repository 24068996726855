import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';
import { ChartType, ChartOptions } from 'chart.js';
import {SingleDataSet, PluginServiceGlobalRegistrationAndOptions, Color} from 'ng2-charts';
import {
  primary_3, graph2, graph1
} from '../../../shared/colors';
import {FONT_FAMILY} from '../../../shared/fonts';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';

@Component({
  selector: 'app-enkel-activatiegraad-lesgroepen-new',
  templateUrl: './enkel-activatiegraad-lesgroepen-new.component.html',
  styleUrls: ['../widget-base/widget-base.component.scss', './enkel-activatiegraad-lesgroepen-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class EnkelActivatiegraadLesgroepenNewComponent implements OnInit, OnDestroy {
  @Input() public procentGeactiveerd$: Observable<number>;

  public hasData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private subscriptions: Subscription[] = [];

  public doughnutChartData: SingleDataSet;
  public doughnutChartColors: Color[];
  public doughnutChartOptions: ChartOptions;
  public doughnutChartType: ChartType;
  public doughnutChartPlugins: PluginServiceGlobalRegistrationAndOptions[];

  ngOnInit(): void {
    this.subscriptions.push(this.procentGeactiveerd$.subscribe(p => {
      this.hasData.next(false);
      this.doughnutChartData = [p, 100 - p];
      this.hasData.next(this.doughnutChartData.map(v => v !== null && v !== undefined).reduce((previous, current) => previous && current));
    }));

    this.doughnutChartColors = [{backgroundColor: [graph1, graph2]}];
    this.doughnutChartOptions = {
        responsive: true,
        legend: {
          display: false,
        },
        cutoutPercentage: 80,
        tooltips: {
          enabled: false
        },
        hover: {mode: null},
        elements: {
          arc: {
            borderWidth: 0
          }
        },
        layout: {
          padding: {
            bottom: 10
          }
        }
    };
    this.doughnutChartType = 'doughnut';
    this.doughnutChartPlugins = [{
        afterDraw(chart: any): void {
          const ctx = chart.ctx;
          const txt = chart.tooltip._data.datasets[0].data[0]?.toFixed(0) + '%';
          const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
          const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2) + 2;

          ctx.font = `35px ${FONT_FAMILY}`;
          ctx.fillStyle = primary_3;
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';

          ctx.fillText(txt, centerX, centerY );
        }
      }];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

