import {AfterViewChecked, Component, DoCheck, HostListener, OnDestroy, OnInit} from '@angular/core';
import {graph1} from '../../shared/colors';
import {FONT_FAMILY} from '../../shared/fonts';
import {GoogleChartInterface} from 'ng2-google-charts';
import {SchooldashboardNewFacade} from '../../state/schooldashboard/schooldashboard.facade';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-range-linechart-voor-bazenbanner-new',
  templateUrl: './range-linechart-voor-bazenbanner-new.component.html',
  styleUrls: ['./range-linechart-voor-bazenbanner-new.component.scss']
})
export class RangeLinechartVoorBazenbannerNewComponent implements OnInit, OnDestroy, DoCheck, AfterViewChecked {
  public chartData: GoogleChartInterface;

  private subscriptions: Subscription[] = [];
  private firstDraw = false;

  constructor(public store: SchooldashboardNewFacade) {
  }

  ngOnInit(): void {
    this.chartData = this.initChartData();
    /*
      google-charts bug: als je te vroeg tekent, dan weet hij nog niet wat de width/height van het parent component zijn, dus wordt die
      default op 400x200 gezet. Daarom hacky obv de innerWidth de size zetten, dit gaat goed omdat alle componenten links van het huidige
      component een vaste breedte hebben. We zitten nu op 6.1.0, misschien is dit gefixed in 7.0.0 als dit ooit geupdate wordt.

      De magic numbers zijn de breedte van de navbar + donut + witte stuk ertussen
     */
    this.setWidth(window.innerWidth - (70 + 366 + 55));

    this.subscriptions.push(this.store.getLeermiddelGebruik().pipe(
    ).subscribe(lg => {
        if (lg) {
          this.chartData.dataTable.rows = lg.map(({datum, gebruik}) => {
            return ({c: [{v: datum}, {v: gebruik?.toFixed(0)}]});
          });
          const maxHeight = Math.max(...lg.map(x => x.gebruik));
          this.chartData.options.vAxis.viewWindow = {
            minValue: 0,
            maxValue: maxHeight === 0 ? 1 : maxHeight,
          };
          this.redraw();
        }
      }));
  }

  private redraw(): void {
    if (this.chartData?.component?.getDataTable()) {
      this.chartData.component.draw();
    }
  }

  private initChartData(): GoogleChartInterface {
    return {
      chartType: 'AreaChart',
      dataTable: {
        cols: [
          {type: 'string', label: 'datum'},
          {type: 'number', label: 'gebruik'}
        ],
        rows: []
      },
      options: {
        hAxis: {
          gridlines: {
            color: 'none'
          },
          ticks: [],
          viewWindowMode: 'maximized',
          textPosition: 'none'
        },
        vAxis: {
          minValue: 0,
          gridlines: {
            color: 'none'
          },
          baseline: {color: 'none'},
          textPosition: 'none'
        },
        chartArea: {
          left: 7,
          right: 7,
          top: 7,
          bottom: 7,
        },
        backgroundColor: {fill: 'none'},
        legend: 'none',
        fontName: FONT_FAMILY,
        pointsVisible: false,
        colors: [graph1],
        areaOpacity: 0.05
      }
    };
  }

  ngDoCheck(): void {
    if (!this.firstDraw && !this.chartData.component?.getDataTable()) {
      this.redraw();
      this.firstDraw = true;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngAfterViewChecked(): void {
    this.redraw();
  }

  setWidth(width: any): void {
    if (this.chartData.options) {
      this.chartData.options.width = width;
      this.chartData.options.chartArea.width = width;
    }
  }

  @HostListener('window:resize')
  onResize(): void {
    this.setWidth('100%');
    this.redraw();
  }
}
