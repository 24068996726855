import {Component, OnInit, ChangeDetectionStrategy, Input} from '@angular/core';
import {Observable} from 'rxjs';
import {localDateStringToShortDisplayDateString} from '../../stateless/moments';
import {
  WebserviceAccountGebruik,
  WebserviceWeekGebruik
} from '../../state/privacydashboard/privacydashboard.state';
import {map} from 'rxjs/operators';
import { MultiRangeWeekBarChartViewModel } from '../multi-range-week-bar-chart/multi-range-week-bar-chart.component';

@Component({
  selector: 'app-webservice-gebruik',
  templateUrl: './webservice-gebruik.component.html',
  styleUrls: ['./webservice-gebruik.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WebserviceGebruikComponent implements OnInit {
  public grafiekAvailable$: Observable<boolean>;
  public tabelAvailable$: Observable<boolean>;

  @Input()
  viewModel: WebserviceGebruikViewModel;

  public grafiekViewModel: MultiRangeWeekBarChartViewModel;

  constructor() { }

  ngOnInit(): void {
    this.grafiekAvailable$ = this.viewModel.webserviceWeekGebruik.pipe(map(wg => wg?.datapoints?.length > 0));
    this.tabelAvailable$ = this.viewModel.webserviceAccountGebruik.pipe(map(ag => ag?.length > 0));
    this.grafiekViewModel = {
      onGetDataPoints: this.viewModel.webserviceWeekGebruik.pipe(map(wg => wg?.datapoints)),
      onGetToonSchrijfacties: this.viewModel.webserviceNaam.pipe(map(name => name !== 'UWLR')),
    };
  }

  getDate(date: string): string {
    return localDateStringToShortDisplayDateString(date);
  }
}

export interface WebserviceGebruikViewModel {
  webserviceNaam: Observable<string>;
  webserviceAccountGebruik: Observable<WebserviceAccountGebruik[]>;
  webserviceWeekGebruik: Observable<WebserviceWeekGebruik>;
}
