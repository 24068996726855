import {ChangeDetectionStrategy, Component, Input, OnChanges} from '@angular/core';
import { ChartType, ChartOptions } from 'chart.js';
import {
  SingleDataSet,
  PluginServiceGlobalRegistrationAndOptions
} from 'ng2-charts';
import { actiefGebruik, geenActivatie, welActivatieGeenGebruik} from '../../../shared/colors';
import { Leermiddelinfo, } from './leermiddelinfo.model';
import {FONT_FAMILY} from '../../../shared/fonts';

@Component({
  selector: 'app-enkel-leermiddel-activatie-of-gebruik',
  templateUrl: './enkel-leermiddel-activatie-of-gebruik.component.html',
  styleUrls: ['../widget-base/widget-base.component.scss', './enkel-leermiddel-activatie-of-gebruik.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnkelLeermiddelActivatieOfGebruikComponent implements OnChanges {
  @Input() public leermiddelinfo: Leermiddelinfo;
  public doughnutChartData: SingleDataSet;
  public leermiddelNaam: string;

  public doughnutChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      display: false,
      position: 'right'
    },
    cutoutPercentage: 70,
    tooltips: {
      enabled: false
    }
  };
  public doughnutChartType: ChartType = 'doughnut';
  public doughnutChartLabels = ['Actief gebruik', 'Weinig of geen gebruik', 'Niet geactiveerd'];
  public doughnutChartColors = [{backgroundColor: [actiefGebruik, welActivatieGeenGebruik, geenActivatie, ]}];
  public doughnutChartPlugins: PluginServiceGlobalRegistrationAndOptions[] = [{
    afterDraw(chart: any) {
      const ctx = chart.ctx;
      const total = chart.tooltip._data.datasets[0].data.reduce((sum, num) => sum + num);
      let txt = '';

      if (chart.active !== undefined && chart.tooltip._active[0] !== undefined) {
        const percentage = Math.round((chart.tooltip._data.datasets[0].data[chart.tooltip._active[0]._index] / total * 100));
        txt = percentage + '%';
      } else {
        const percentage = Math.round((chart.tooltip._data.datasets[0].data[0] / total * 100));
        txt = percentage + '%';
      }

      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
      const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);

      const fontSizeToUse = 30;
      ctx.font = fontSizeToUse + `px ${FONT_FAMILY}`;
      ctx.fillStyle = 'black';

      ctx.fillText(txt, centerX + 5, centerY);
    }
  }];

  ngOnChanges(): void {
    this.doughnutChartData = this.leermiddelinfo.getallen;
    this.leermiddelNaam = this.leermiddelinfo.leermiddelNaam;
  }
}
