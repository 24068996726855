import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy } from '@angular/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { InputToggleViewModel } from '../input-toggle/input-toggle.component';

@Component({
    selector: 'app-sem-koppeling-school-panel',
    templateUrl: './sem-koppeling-school-panel.component.html',
    styleUrls: ['./sem-koppeling-school-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SemKoppelingSchoolPanelComponent implements OnInit, OnDestroy {

    @Input()
    public viewModel: SemKoppelingSchoolPanelViewModel;

    public onGetStatusText: Observable<string>;

    public onGetIcon: Observable<string>;

    public toggleViewModel: InputToggleViewModel;

    private subscriptions: Subscription[] = [];

    constructor() { }

    ngOnInit(): void {
        const consentState = combineLatest([this.viewModel.onGetLocalConsent, this.viewModel.onGetRemoteConsent, this.viewModel.onGetIsWorking]);

        this.onGetStatusText = consentState.pipe(
            withLatestFrom(this.viewModel.onGetSemPartyName),
            map(([[local, remote, _], name]) => {
                if (local && remote) {
                    return 'Goedkeuring gegeven in beide systemen.';
                }
                else if (local) {
                    return 'Goedkeuring gegeven in Somtoday, niet in ' + name + '.';
                }
                else if (remote) {
                    return 'Goedkeuring gegeven in ' + name + ', niet in Somtoday.';
                }
                else {
                    return 'Niet gekoppeld in beide systemen.';
                }
            })
        );
        
        this.onGetIcon = consentState.pipe(
            map(([local, remote, working]) => {
                if(working) {
                    return 'spinner';
                }
                else if (local && remote) {
                    return 'icon ok';
                }
                else if (remote) {
                    return 'icon warning'
                }
                else return 'icon';
            })
        );

        this.toggleViewModel = {
            onGetActive: this.viewModel.onGetIsWorking.pipe(map(isWorking => !isWorking)),
            onGetState: this.viewModel.onGetLocalConsent,
            onToggle: this.viewModel.doSetLocalConsent
        };
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}

export class SemKoppelingSchoolPanelViewModel {
    onGetSchoolName: Observable<string>;
    onGetSchoolId: Observable<string>;
    onGetSemPartyName: Observable<string>;
    onGetLocalConsent: Observable<boolean>;
    onGetRemoteConsent: Observable<boolean>;
    onGetIsWorking: Observable<boolean>;

    doSetLocalConsent: (consent: boolean) => void;
}
