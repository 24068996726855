import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {routerNavigatedAction} from '@ngrx/router-store';
import {catchError, filter, map, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';
import {getDashboardPath} from '../state/router/router.selectors';
import {of} from 'rxjs';
import {reportError} from '../state/errors/errors.actions';
import {payload} from '../state/payload';
import {DashboardingService} from '../services/dashboarding.service';
import {DashboardsettingsFacade} from '../state/dashboardsettings/dashboardsettings.facade';
import {
  dashboardSettingsLoaded,
  datumbereikenFetched,
  fetchDatumbereiken,
  setVestigingen,
  vestigingChanged,
} from '../state/dashboardsettings/dashboardsettings.actions';
import {routingConstants} from '../stateless/routing.constants';

@Injectable()
export class DashboardsettingsEffect {
  $ = createEffect(() => this.actions$.pipe(
    ofType(routerNavigatedAction),
    filter(({payload: p}) =>
      getDashboardPath(p?.routerState?.url) === routingConstants.SCHOOL_DASHBOARD_NEW ||
      getDashboardPath(p?.routerState?.url) === routingConstants.VAK_DASHBOARD_NEW ||
      getDashboardPath(p?.routerState?.url) === routingConstants.KLASSEN_DASHBOARD_NEW ||
      getDashboardPath(p?.routerState?.url) === routingConstants.STUDIEWIJZER_LINKJES_NEW
    ),
    withLatestFrom(this.settings.hasAvailableVestigingen()),
    filter(([_, hasAvailableVestigingen]) => !hasAvailableVestigingen),
    switchMap(() => this.dashboardingService.fetchVestigingen()
      .pipe(
        map(vestigingen => vestigingen.sort((a, b) => (a.naam > b.naam) ? 1 : -1)),
        mergeMap(vestigingen => [setVestigingen(payload(vestigingen)), fetchDatumbereiken(null)]),
        catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
      )),
    catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
  ));

  constructor(private actions$: Actions, private dashboardingService: DashboardingService, private settings: DashboardsettingsFacade) {
  }
}

@Injectable()
export class DoFetchDatumbereikenVanVestiging {
  $ = createEffect(() => this.actions$.pipe(
    ofType(fetchDatumbereiken, vestigingChanged),
    map(({value: vestiging}) => vestiging),
    withLatestFrom(this.settings.getSelectedVestiging()),
    switchMap(([vestigingParam, vestigingUrl]) =>
      this.dashboardingService.fetchDatumbereikenVanVestiging(vestigingParam != null ? vestigingParam : vestigingUrl)
        .pipe(
          mergeMap(item => [datumbereikenFetched(payload(item)), dashboardSettingsLoaded()]),
          catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
        )),
    catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
  ));

  constructor(
    private actions$: Actions,
    private dashboardingService: DashboardingService,
    private settings: DashboardsettingsFacade) {
  }
}
