import {Observable} from 'rxjs';
import {selectQueryParam, selectRouteParam} from '../router/router.selectors';
import {map, filter, withLatestFrom} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {BaseFacade} from '../base.facade';
import {Store} from '@ngrx/store';
import {AppState, selectKlassendashboard} from '../app.state';
import {
  selectAantalLeerlingen, selectDocentNamen,
  selectDocentUUIDs,
  selectGezamenlijkGebruik,
  selectIndividueelGebruik,
  selectIndividueelGebruikPerLeerling,
  selectLeerlingUUIDs, selectLeermiddelGrafiek, selectLeermiddelTabel, selectLesgroepNaam,
  selectLesgroepUUID, selectNextLesgroep, selectNextLesgroepPathElements, selectPrevLesgroepPathElements,
  selectTotaalActivatie,
  selectTotaalGebruikPerWeek,
  selectTotaalRecentActief
} from './klassendashboard.selectors';
import {
  GezamenlijkGebruik,
  IndividueelGebruik,
  LeermiddelGrafiek,
  LeermiddelTabel,
  IndividueelGebruikLeerlingRegel,
  IndividueelGebruikLeermiddelRegel,
} from './klassendashboard.state';

@Injectable()
export class KlassendashboardNewFacade extends BaseFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }

  isIndividueelGeselecteerd(): Observable<boolean> {
    return this.select(selectQueryParam('selectie')).pipe(map(value => value !== 'samenvatting'));
  }

  isSamenvattingGeselecteerd(): Observable<boolean> {
    return this.select(selectQueryParam('selectie')).pipe(map(value => value === 'samenvatting'));
  }

  getLesgroepUUIDURL(): Observable<string> {
    return this.select(selectRouteParam('lesgroepuuid'));
  }

  getLesgroepUUIDState(): Observable<string>{
    return this.select(selectLesgroepUUID);
  }

  getLesgroepNaam(): Observable<string> {
    return this.select(selectLesgroepNaam);
  }

  getIndividueelGebruik(): Observable<IndividueelGebruik> {
    return this.select(selectIndividueelGebruik);
  }

  hasIndividueelGebruik(): Observable<boolean> {
    return this.select(selectKlassendashboard).pipe(
      withLatestFrom(this.getLesgroepUUIDURL()),
      map(([klassendashboard, lesgroepUrlUUID]) => klassendashboard.individueelGebruik != null && lesgroepUrlUUID === klassendashboard.lesgroepUUID));
  }

  getGezamenlijkGebruik(): Observable<GezamenlijkGebruik> {
    return this.select(selectGezamenlijkGebruik);
  }

  hasGezamenlijkGebruik(): Observable<boolean> {
    return this.select(selectKlassendashboard).pipe(
      withLatestFrom(this.getLesgroepUUIDURL()),
      map(([klassendashboard, lesgroepUrlUUID]) => klassendashboard.gezamenlijkGebruik != null && lesgroepUrlUUID === klassendashboard.lesgroepUUID));
  }

  getUniqueDocentUUIDs(): Observable<string[]> {
    return this.select(selectDocentUUIDs).pipe(map(uuids => ([...new Set(uuids)])));
  }

  getDocentNamen(): Observable<string[]> {
    return this.select(selectDocentNamen);
  }

  getUniqueLeerlingUUIDs(): Observable<string[]> {
    return this.select(selectLeerlingUUIDs).pipe(map(uuids => ([...new Set(uuids)])));
  }

  getTotaalActivatie(): Observable<number> {
    return this.select(selectTotaalActivatie);
  }

  getTotaalRecentActief(): Observable<number> {
    return this.select(selectTotaalRecentActief);
  }

  getTotaalGebruikPerWeek(): Observable<number> {
    return this.select(selectTotaalGebruikPerWeek);
  }

  getIndividueelGebruikPerLeerling(): Observable<IndividueelGebruikLeerlingRegel[]> {
    return this.select(selectIndividueelGebruikPerLeerling);
  }

  getLeermiddelRegelsVoorLeerling(uuid: string): Observable<IndividueelGebruikLeermiddelRegel[]> {
    return this.getIndividueelGebruikPerLeerling().pipe(
      filter(regels => !!regels),
      map(leerlingRegels => leerlingRegels.find(leerlingRegel => leerlingRegel.leerlingUUID === uuid)),
      filter(leerlingRegel => !!leerlingRegel),
      map(leerlingRegel => leerlingRegel.leermiddelRegels)
    );
  }

  getLeermiddelNamenVoorLeerling(uuid: string): Observable<string[]> {
    return this.getLeermiddelRegelsVoorLeerling(uuid).pipe(
      map(leermiddelRegels => leermiddelRegels.map(leermiddelRegel => leermiddelRegel.leermiddelNaam))
    );
  }

  getLeermiddelLaatstActiefVoorLeerling(uuid: string): Observable<string[]> {
    return this.getLeermiddelRegelsVoorLeerling(uuid).pipe(
      map(leermiddelRegels => leermiddelRegels.map(leermiddelRegel => leermiddelRegel.laatstActief))
    );
  }

  getLeermiddelGebruikVoorLeerling(uuid: string): Observable<{gebruikPerWeek: number, gebruikTrend: number}[]> {
    return this.getLeermiddelRegelsVoorLeerling(uuid).pipe(
      map(leermiddelRegels => leermiddelRegels.map(leermiddelRegel => ({gebruikPerWeek: leermiddelRegel.gebruikPerWeek, gebruikTrend: leermiddelRegel.gebruikTrend})))
    );
  }

  getLeermiddelGebruikVoorLeerlingAlsGeactiveerd(uuid: string): Observable<{gebruikPerWeek: number, gebruikTrend: number}[]> {
    return this.getLeermiddelGebruikVoorLeerling(uuid).pipe(
      withLatestFrom(this.getLeermiddelLaatstActiefVoorLeerling(uuid)),
      map(([gebruik, geactiveerd]) => {
        for (let i = 0; i < gebruik.length; i++) {
          gebruik[i] = geactiveerd[i] ? gebruik[i] : null;
        }
        return gebruik;
      })
    );
  }

  getAantalGeactiveerdeLicentiesVoorLeerling(uuid: string): Observable<number> {
    return this.getLeermiddelLaatstActiefVoorLeerling(uuid).pipe(
      map(laatstActief => laatstActief.filter(a => a !== null).length)
    );
  }
  getAantalLeerlingen(): Observable<number> {
    return this.select(selectAantalLeerlingen);
  }

  getLeermiddelGrafiek(): Observable<LeermiddelGrafiek[]> {
    return this.select(selectLeermiddelGrafiek);
  }

  getLeermiddelTabel(): Observable<LeermiddelTabel[]> {
    return this.select(selectLeermiddelTabel);
  }

  getPrevPathElements(): Observable<string[]> {
    return this.select(selectPrevLesgroepPathElements);
  }

  getNextPathElements(): Observable<string[]> {
    return this.select(selectNextLesgroepPathElements);
  }

  isNavigeerbaar(): Observable<boolean> {
    return this.select(selectNextLesgroep).pipe(
    withLatestFrom(this.getLesgroepUUIDURL()),
    map(([nextLesgroepUUID, huidigeLesgroepUUID]) => nextLesgroepUUID !== null && nextLesgroepUUID !== huidigeLesgroepUUID));
  }
}
