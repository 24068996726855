import {Pipe, PipeTransform} from '@angular/core';
import {RLeerling} from '../services/somtoday.service';

@Pipe({name: 'leerlingAchternaamVoornaam'})
export class LeerlingAchternaamVoornaam implements PipeTransform {
  public transform(leerling: RLeerling): string {
    return leerlingAchternaamVoornaam(leerling);
  }
}

export function leerlingAchternaamVoornaam(leerling: RLeerling): string {
  if (leerling === null || leerling === undefined) {
    return 'onbekend';
  }
  const achternaam = leerling.achternaam;
  const voornaam = leerling.roepnaam;
  const voorvoegsel = leerling.voorvoegsel ? leerling.voorvoegsel : '';
  return voorvoegsel + ' ' + achternaam + ', ' + voornaam;
}
