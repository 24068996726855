import {Action, Store} from '@ngrx/store';
import {AppState} from './app.state';
import {Payload} from './payload';
import {Observable} from 'rxjs';

export const dispatch = (store: Store<AppState>) => (action: Action) => store.dispatch(action);

export type Mapper<T, P> = (s: AppState, p: Payload<P>) => T;

export const apply = <T, P>(selector: Mapper<T, P>) => (props: Payload<P>) => (store: Store<AppState>) =>
  store.select(selector, props);

export const select = <T>(store: Store<AppState>) => (fn: (s: Store<AppState>) => Observable<T>) => fn(store);
