import {Component, OnInit, ChangeDetectionStrategy, HostListener, OnDestroy} from '@angular/core';
import {VakdashboardNewFacade} from '../../state/vakdashboard/vakdashboard.facade';
import {ModalwindowService} from '../../services/modalwindow.service';
import {RangeLinechartModelKeyBased} from '../../layout/range-linechart/range-linechart.model';
import {LesgroepInformatieRegel, TotaalInformatieRegel} from '../../state/vakdashboard/vakdashboard.state';
import {Subscription, BehaviorSubject, Observable, of} from 'rxjs';
import {map, withLatestFrom} from 'rxjs/operators';
import {Router} from '@angular/router';
import { RangeLinechartKeybasedViewModel } from 'src/app/layout/range-linechart-keybased-new/range-linechart-keybased-new.component';
import {Datumbereik, PERIODE} from '../../state/dashboardsettings/dashboardsettings.state';
import {routingConstants} from '../../stateless/routing.constants';
import { RangeBarNewModel } from 'src/app/layout/range-bar-new/range-bar-new.component';

@Component({
  selector: 'app-vakdashboard-table',
  templateUrl: './vakdashboard-table.component.html',
  styleUrls: ['./vakdashboard-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VakdashboardTableComponent implements OnInit, OnDestroy {
  public loading: Observable<boolean> = this.store.selectLoading();

  public resize: BehaviorSubject<void> = new BehaviorSubject<void>(null);

  public totaalrijLinechartViewModel: RangeLinechartKeybasedViewModel;

  public totaalrijRangeBarNewViewModel: RangeBarNewModel;

  private vak: string;
  private os: string;

  private subs: Subscription[] = [];

  constructor(public store: VakdashboardNewFacade, private modalwindowService: ModalwindowService, private router: Router) { }

  ngOnInit(): void {
    this.totaalrijLinechartViewModel = {
      onGetGemiddeldGebruik: this.store.getTotaalRegel().pipe(withLatestFrom(this.store.getPeriode())).pipe(map(this.getGemiddeldGebruik)),
      onGetRange: this.store.getGebruikTotaal()
    };
    
    this.totaalrijRangeBarNewViewModel = {
        min: this.store.getRecentActiefTotaal().pipe(map(recentActiefTotaal => recentActiefTotaal?.min)),
        avg: this.store.getRecentActiefTotaal().pipe(map(recentActiefTotaal => recentActiefTotaal?.avg)),
        max: this.store.getRecentActiefTotaal().pipe(map(recentActiefTotaal => recentActiefTotaal?.max)),
        tot: this.store.getRecentActiefTotaal().pipe(map(recentActiefTotaal => recentActiefTotaal?.tot))
    };

    this.subs.push(this.store.getVakNaamURL().subscribe(vak => this.vak = vak));
    this.subs.push(this.store.getOnderwijssoortNaamURL().subscribe(os => this.os = os));
  }

  public getRowLinechartViewModel(row: LesgroepInformatieRegel): RangeLinechartKeybasedViewModel {
      return {
          onGetRange: of(row).pipe(map(this.getRangelineChart)),
          onGetGemiddeldGebruik: of(row).pipe(withLatestFrom(this.store.getPeriode())).pipe(map(this.getGemiddeldGebruik))
      };
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  public getRangelineChart(row: LesgroepInformatieRegel): RangeLinechartModelKeyBased[] {
    return row?.lesgroepGebruik.map(lg => ({key: lg.key, values: {min: lg.gebruik, avg: lg.gebruik, max: lg.gebruik}}));
  }

  public getGemiddeldGebruik([row, periode]: [TotaalInformatieRegel | LesgroepInformatieRegel, PERIODE]): string {
   const footerStepByPeriode = periode === 'JAAR' ? 'maand' : 'dag';
   return row?.gemiddeldGebruik?.toFixed(1) + ' keer per ' + footerStepByPeriode;
  }

  public openActiefHelp(): void {
    this.modalwindowService.toonOnderwerp('actief');
  }

  public openGebruikHelp(): void {
    this.modalwindowService.toonOnderwerp('gebruik');
  }

  getDocentNamen(row: LesgroepInformatieRegel): string {
    let result = '';
    if (row?.docentNamen) {
      for (const docent of row.docentNamen.slice(0, 3)) {
        result = result.concat(docent + '<br>');
      }
      if (row.docentNamen.length > 3) {
        result = result.concat('<span class="label"> +' + (row.docentNamen.length - 3) + '</span>');
      }
      if (row.docentNamen.length === 0) {
        result = '-';
      }
    }
    return result;
  }

  public getLesgroepNaam(row: LesgroepInformatieRegel): Observable<string> {
    return this.resize.pipe(
      map(() => {
        let lesgroepNaam = row.lesgroepNaam;
        if (window.innerWidth < 1200) {
          if (lesgroepNaam.length > 12) {
            lesgroepNaam = lesgroepNaam.substr(0, 12).trim().concat('...');
          }
        }
        return lesgroepNaam;
      })
    );
  }

  @HostListener('window:resize')
  onResize(): void {
    this.resize.next();
  }

  navigeerNaarKlassendashboard(row: LesgroepInformatieRegel): void {
    this.router.navigate([routingConstants.KLASSEN_DASHBOARD_NEW, this.vak, this.os, row?.lesgroepUUID], {queryParamsHandling: 'preserve'});
  }
}
