export interface RangeModel {
  min: number;
  avg: number;
  max: number;
  tot?: number;
}

export function isValid(range: RangeModel) {
  const {min = 0, avg = 0, max = 0, tot } = range || {} as RangeModel;
  return ([min, avg, max].every(v => v >= 0) &&
    max >= min && max >= avg && avg >= min) &&
    (tot === undefined || [min, avg, max].every(v => v <= tot));
}

export interface RangeModelBuilder {
  update(value: number);
  build(): RangeModel;
}

export function rangeModelBuilder(tot?: number): RangeModelBuilder {
  let min;
  let avg = 0;
  let max = 0;
  let updateCount = 0;
  return {
    update(value: number) {
      updateCount++;
      if (min === undefined || min > value) {
        min = value;
      }
      avg += value;
      if (max < value) {
        max = value;
      }
    },
    build() {
      return {
        min,
        avg: updateCount ? avg / updateCount : 0,
        max,
        tot
      };
    }
  };
}
