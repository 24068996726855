<div class="widget-tile">
  <span>{{ widgetDataHolder.title }}</span>
  <canvas baseChart
          [data]="pieChartData"
          [labels]="pieChartLabels"
          [chartType]="pieChartType"
          [options]="pieChartOptions"
          [colors]="pieChartColors"
          [plugins]="pieChartPlugins"
          [legend]="pieChartLegend">
  </canvas>
</div>
