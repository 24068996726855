import {ChangeDetectionStrategy, Component, Input, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';

@Component({
  selector: '' +
    'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabComponent {
  @Input() label;
  @Input() active = false;

  /** Template inside the TabComponent view that contains an `<ng-content>`. */
  @ViewChild('tabTemplate', {static: true}) template: TemplateRef<any>;

  constructor(private _viewContainerRef: ViewContainerRef,) {
  }

}
