import {Component, OnInit, ChangeDetectionStrategy, Input} from '@angular/core';
import {TerugKnopViewmodel} from '../terug-knop-new/terug-knop-new.component';
import {LesgroepSwitchViewmodel} from '../klassendashboard-lesgroep-switch/klassendashboard-lesgroep-switch.component';
import {KlassendashboardNewFacade} from '../../state/klassendashboard/klassendashboard.facade';
import {DatepickerComponentViewModel} from '../datepicker/datepicker.component';

@Component({
  selector: 'app-klassendashboard-controls-header',
  templateUrl: './klassendashboard-controls-header.component.html',
  styleUrls: ['./klassendashboard-controls-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KlassendashboardControlsHeaderComponent implements OnInit {

  @Input()
  viewmodel: LesgroepControlsHeaderViewModel;

  constructor(public store: KlassendashboardNewFacade) { }

  ngOnInit(): void {
  }

}

export interface LesgroepControlsHeaderViewModel {
  terugKnopViewmodel: TerugKnopViewmodel;
  lesgroepSwitchViewmodel: LesgroepSwitchViewmodel;
  datepickerViewmodel: DatepickerComponentViewModel;
}

