import {BaseFacade} from '../../../state/base.facade';
import {Injectable} from '@angular/core';
import {AppState} from '../../../state/app.state';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {UitgeverOfVakGebruikVoorPeriode} from '../../../../generated/graphql';
import {selectLeermiddelendashboardData} from './leermiddelendashboard.selectors';
import {selectGroeperenopUitgever} from '../../../state/router/router.selectors';
import {setLeermiddelendashboardGroepering} from '../schooldashboard/schooldashboard.actions';

@Injectable()
export class LeermiddelendashboardFacade extends BaseFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }

  selectData(): Observable<UitgeverOfVakGebruikVoorPeriode[]> {
    return this.select(selectLeermiddelendashboardData);
  }

  selectGroeperenOpUitgever(): Observable<boolean> {
    return this.select(selectGroeperenopUitgever);
  }

  setGroepering(groeperenOpuitgever: boolean) {
    this.dispatch(setLeermiddelendashboardGroepering, groeperenOpuitgever);
  }
}
