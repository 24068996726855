import {Component, OnInit, OnDestroy, ChangeDetectionStrategy, Input} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {SubMenu} from '../app-routing.module';
import {SubMenuItem} from '../app-routing.module';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';

@Component({
  selector: 'app-sub-menu-left',
  templateUrl: './sub-menu-left.component.html',
  styleUrls: ['./sub-menu-left.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubMenuLeftComponent implements OnInit, OnDestroy {

  @Input() public menu: SubMenu;

  public currentURL$: Subject<string>;

  private subscriptions: Subscription[] = [];

  constructor( private router: Router ) { }

  ngOnInit(): void {
    this.currentURL$ = new BehaviorSubject<string>(this.router.url.split('?')[0]);
    this.subscriptions.push(this.router.events.subscribe(
      event => {
        if (event instanceof NavigationEnd) {
          this.currentURL$.next(event.urlAfterRedirects);
        }
      }
    ));
  }

  onClick(link: string) {
    this.router.navigateByUrl(link);
  }

  isActive(item: SubMenuItem): boolean {
    return this.router.url.split('?')[0] === item.url;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}

