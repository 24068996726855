<div class="controls">
    <div class="toggle">
        <div class="title">{{viewModel.onGetTitle | async}}</div>
        <div class="buttons">
            <button buttonstyle="flat" (click)="toggle()"
                [disabled]="viewModel.onGetLeftActive | async">{{viewModel.onGetLeftLabel | async}}</button>
            <button buttonstyle="flat" (click)="toggle()"
                [disabled]="viewModel.onGetRightActive | async">{{viewModel.onGetRightLabel | async}}</button>
        </div>
    </div>
    
    <div class="right">
        <app-datepicker [viewModel]="viewModel"></app-datepicker>
    </div>
</div>