import {onderwijssoortComparator} from './onderwijssoort.comparator';

export function onderwijssoortLeerjaarComparator<T>(getOnderwijssoort: (v: T) => string, getLeerjaar: (v: T) => number):
  (a: T, b: T) => number {
  return (a, b) => {
    const onderwijssoortCompared = onderwijssoortComparator(getOnderwijssoort)(a, b)
    if (onderwijssoortCompared !== 0) {
      return onderwijssoortCompared;
    }
    return getLeerjaar(a) - getLeerjaar(b);
  };
}
