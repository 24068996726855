<div *ngIf="(store.getAvailableVestigingen() | async)?.length > 0" (clickOutside)="expanded=false" [clickOutsideEvents]="'click,touchend'">
  <a (click)="expanded = !expanded">
    <img alt="Vestiging" src="../../../assets/img/icons/Symbool/map-marker.svg">
    <span>{{(store.getSelectedVestiging() | async)?.naam}}</span>
    <i [class]="expanded ? 'icon-arrow-close' : 'icon-arrow-open'"></i>
  </a>
  <div *ngIf="expanded" class="vestigingoverlay">
    <h3>Bekijk vestiging</h3>
    <ul>
      <li *ngFor="let vestiging of store.getAvailableVestigingen() | async"
          (click)="select(vestiging)"
          [class.selected]="(store.getSelectedVestiging() | async) === vestiging"
          [class.selectable]="vestiging?.heeftData"
      >
        <img alt="Vestiging" src="../../../assets/img/icons/Symbool/map-marker.svg">
        {{vestiging?.naam}}
      </li>
    </ul>
  </div>
</div>




