import {Injectable} from '@angular/core';
import {OAuthService} from 'angular-oauth2-oidc';
import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {
  AVGApiConfig,
  AVGApiService,
  AVGExport, AVGExportData,
  AVGExportDetails, AVGExportMederker,
  AVGFeedback,
  AVGSemConsent,
  AVGSemPartyMarketplaceEntry,
  AVGSemPartyViewPageEntry
} from 'src/generated/avg-client';
import {TranslateService} from './translate.service';

@Injectable()
export class AvgBackendService {
  private backendURL: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(
    private api: AVGApiService,
    private avgApiConfig: AVGApiConfig,
    private oauthService: OAuthService,
    private translateService: TranslateService
  ) {
    this.backendURL.pipe(distinctUntilChanged()).subscribe(url => this.avgApiConfig.rootUrl = url);
  }

  public getMarketplaceEntries(): Observable<AVGSemPartyMarketplaceEntry[]> {
    this.setSomtodayApiUrl();
    return this.api.getMarketplaceEntries();
  }

  public getPartyDetails(uuid: string): Observable<AVGSemPartyViewPageEntry> {
    this.setSomtodayApiUrl();
    return this.api.getPartyDetails({uuid});
  }

  public sendUserFeedback(opmerking: string, smiley: 'blij' | 'tevreden' | 'neutraal' | 'ontevreden' | 'boos', pagina: string, schermresolutie: string, userAgent: string): Observable<{body: AVGFeedback[]}> {
    this.setSomtodayApiUrl();
    const feedback: AVGFeedback = {
      tags: { opmerking: opmerking, smiley: smiley, pagina: pagina, schermresolutie: schermresolutie, userAgent: userAgent },
    };

    const requestBody = {
      body: feedback,
    };

    return this.api.setFeedback$Json$Response(requestBody);
  }

  public updateSemConsent(semPartyUuid: string, schoolId: string, consent: boolean): Observable<AVGSemConsent[]> {
    this.setSomtodayApiUrl();
    if (consent) {
      return this.api.setSemConsent({uuid: semPartyUuid, schoolId});
    } else {
      return this.api.unsetSemConsent({uuid: semPartyUuid, schoolId});
    }
  }

  public fetchExports(begindatum: string, einddatum: string, page: number, amount: number, medewerkerUUID?: string): Observable<AVGExportData> {
    this.setSomtodayApiUrl();
    return this.api.getExports({begindatum, einddatum, offset: (page - 1) * amount, amount, medewerkerUUID});
  }

  public fetchMedewerkers(begindatum: string, einddatum: string): Observable<AVGExportMederker[]> {
    this.setSomtodayApiUrl();
    return this.api.getExportMedewerkers({begindatum, einddatum});
  }

  public fetchExportDetails(id: number): Observable<{ id: number, exportDetails: AVGExportDetails }> {
    this.setSomtodayApiUrl();
    return this.api.getExportDetails({id}).pipe(
      map(({keys, entiteiten}) => {
        const translatedKeys = keys.map(key => this.translateService.translate(key));
        const translatedEntiteiten = entiteiten.map(entiteit => this.translateService.translate(entiteit));

        return {
          id,
          exportDetails: {
            keys: translatedKeys,
            entiteiten: translatedEntiteiten
          }
        };
      }),
      map(({id, exportDetails}) => {
        const {keys, entiteiten} = exportDetails;

        return {
          id,
          exportDetails: {
            keys: keys.sort(),
            entiteiten: entiteiten.sort()
          }
        };
      })
    );
  }

  private setSomtodayApiUrl(): void {
    this.backendURL.next(`${this.oauthService.getCustomTokenResponseProperty('somtoday_api_url')}/rest/v1`);
  }
}
