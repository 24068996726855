import { ConnectConfiguratie } from './environment.service';
import {EnabledConfig} from './required.connect.config';

class NightlyConfiguratie extends ConnectConfiguratie {
  configurationName(): string {
    return 'Nightly';
  }

  production(): boolean {
    return false;
  }

  DEBUG(): boolean {
    return true;
  }

  getPrefix(): string {
    return null;
  }
  isSecure(): boolean {
    return true;
  }
  idpUrl(): string {
    return `https://inloggen.nightly.somtoday.build`;
  }
  idpIssuer(): string {
    return `https://nightly.somtoday.build`;
  }
  apiUrl(): string {
    return `https://api.connect.nightly.somtoday.build/`;
  }

  dashboardApiUrl(): string {
    return `https://etl.connect.nightly.somtoday.build`;
  }

  frontendUrl(): string {
    return `https://connect.nightly.somtoday.build/oauth`;
  }

  validationJwks(): object {
    return {keys: [{kty: 'RSA', 'x5t#S256': 'eYAPEoWt_ocDoyNxXoCTUEKQ8Cmkg1SFDz56PhAZntQ', e: 'AQAB', use: 'sig', kid: 'iridiumidp-130745756910380870207532936531006548509', alg: 'RS256', n: '1OKB6WXhGubvdATCYMz1azc8hMZ3Kp7Y416uy5P8CqIFtkag1awCRvTevVSnwz0SFCQ7LiBvP7tG0HG4jPXgvGz3a0G3WmmWKBX-3wwYsy4j8hfsLQnI0KLCnPo1R91Qw0kdGZTKnII7FXcIB8dtfOYJwHg7ozXOEGRwUro8t8PNPcpS0hDFsiZMgcaAi69cF0EODO5JbEdV7N8hY0SbFwjTb6CbHQSehiUhKwztKn1dt8eFQ4si-3G6PNKKNJlgJzkQ5HZxG1pgEgtR1u-8q7FVHiSOYVCS4lawS21pqa_L3BELcWsk05Sj_1DZen6cCMAF3ELBPRM0BKyQANBlAQ'}]};
  }

  analyticsId(): string {
    return 'UA-163203739-2';
  }

  bureauICEKoppelpartijUUID(): string {
    return '10d97656-ed1e-4588-9834-aab9b53924e5';
  }

  newDashboardsEnabled(): EnabledConfig {
    return {
      enabled: true
    };
  }

  newStudiewijzerLinkjesEnabled(): EnabledConfig {
    return {
      enabled: true
    };
  }

  oldDashboardsEnabled(): EnabledConfig {
    return {
      enabled: true
    };
  }

  oldStudiewijzerLinkjesEnabled(): EnabledConfig {
    return {
      enabled: true
    };
  }
}

const testConfig: NightlyConfiguratie = new NightlyConfiguratie();

export const environment = testConfig.build();
