import { Component } from '@angular/core';
import {TableCellComponent} from '../table/table.model';

@Component({
  selector: 'app-geactiveerd',
  templateUrl: './geactiveerd.component.html',
  styleUrls: ['./geactiveerd.component.scss']
})
export class GeactiveerdComponent implements TableCellComponent<string> {
  data: string;
}
