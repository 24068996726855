import { Component} from '@angular/core';
import { Leermiddelinfo } from '../layout/widget/enkel-leermiddel-activatie-of-gebruik/leermiddelinfo.model';
import { Progressbarinfo } from '../layout/widget/progress-bar-activatiegraad/progress-barinfo.model';
import {Kengetal} from '../layout/widget/kengetal/kengetal.model';
import {primary_1, accent_positive_1, background_3} from '../shared/colors';
import {LineChartModel} from '../layout/widget/line-chart/line-chart.model';
import {RangeModel} from '../layout/model/range.model';
import {RangeLinechartModel} from '../layout/range-linechart/range-linechart.model';
import {environment} from '../../environments/environment';
import {OAuthService} from 'angular-oauth2-oidc';
import {Gebruiksaantallen, LeermiddelAantallen, OpleidingAantallen} from '../services/leermiddelgebruik.data-service';
import {MultiRangeWeekBarChartViewModel } from '../layout/multi-range-week-bar-chart/multi-range-week-bar-chart.component';
import { BehaviorSubject, Observable } from 'rxjs';
import {momentToLocalDate, startOfTerm} from '../stateless/moments';
import {WebserviceDataPoint} from '../state/privacydashboard/privacydashboard.state';
import { InputToggleViewModel } from '../layout/input-toggle/input-toggle.component';

@Component({
  selector: 'app-style-guide',
  templateUrl: './style-guide.component.html',
  styleUrls: ['./style-guide.component.scss']
})
export class StyleGuideComponent {
    private onGetIsFirstToggleActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    private onGetIsSecondToggleActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private onGetIsFirstToggleOn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private onGetIsSecondToggleOn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    public firstToggleViewModel: InputToggleViewModel = {
        onGetActive: this.onGetIsFirstToggleActive,
        onGetState: this.onGetIsFirstToggleOn,
        onToggle: (state: boolean) => {
            this.onGetIsSecondToggleActive.next(state);
            this.onGetIsFirstToggleOn.next(state);
        }
    };

    public secondToggleViewModel: InputToggleViewModel = {
        onGetActive: this.onGetIsSecondToggleActive,
        onGetState: this.onGetIsSecondToggleOn,
        onToggle: (state: boolean) => {
            this.onGetIsFirstToggleActive.next(state);
            this.onGetIsSecondToggleOn.next(state);
        }
    };

  gebruik: Gebruiksaantallen = createGebruiksAantallen();
  bgcolor = background_3;

  private barchartVullingen: WebserviceDataPoint[][] = [];
  private toonSchrijfacties: boolean[] = [];
  private currentBarchartIndex = -1;

  constructor(private oAuthService: OAuthService) {
    this.fakeKengetallen = [
      {kengetalInfo: 'leerlingen', aantal: [166]},
      {kengetalInfo: 'licenties geactiveerd', aantal: [132]},
      {kengetalInfo: 'wekelijks actief', aantal: [2, 3]},
      {kengetalInfo: 'maandelijks actief', aantal: [6, 7]}
    ];

    this.fakeKengetal = [{kengetalInfo: 'licenties geactiveerd', aantal: [132]}];
    this.multiRangeWeekBarChartViewModel = {
      onGetDataPoints: this.onGetDataPoints,
      onGetToonSchrijfacties: this.onGetToonSchrijfacties
    };
    this.volgendeBarChart();
  }

  public leermiddelinfo: Leermiddelinfo = {leermiddelNaam: 'VO Got it Rekenen Leerlinglicentie', getallen: [14, 138]};
  public leermiddelinfoDonut1: Leermiddelinfo = {getallen: [222, 95, 47], leermiddelNaam: 'Biologie voor jou'};
  public progressbarinfo: Progressbarinfo = {uitgever: 'Malmberg', getallenActivatieOfGebruik: [9, 1], extraBoodschap: 'klassen'};
  private kengetalCategorien = [
    'leerlingen', 'licenties geactiveerd', 'wekelijks actief', 'maandelijks actief'
  ];
  public fakeKengetallen: Kengetal[];
  public fakeKengetal: Kengetal[];

  public multiRangeWeekBarChartViewModel: MultiRangeWeekBarChartViewModel;
  private onGetDataPoints: BehaviorSubject<WebserviceDataPoint[]> = new BehaviorSubject([]);
  private onGetToonSchrijfacties: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public lineChartSubject: LineChartModel = {
      table: [
        ['week', 'RekenPlus', 'TaalActief'],
        ['21', .10, .30],
        ['22', .30, .50],
        ['23', .50, .40],
        ['24', .40, .60],
        ['25', .20, .70]
      ],
      colors: [primary_1, accent_positive_1]
  };

  public rangeLineChartModel: RangeLinechartModel[] = [
    {date: new Date(2019, 11, 1), values: {min: 20, avg: 30, max: 50, tot: 100}},
    {date: new Date(2019, 11, 2), values: {min: 10, avg: 40, max: 45, tot: 100}},
    {date: new Date(2019, 11, 3), values: {min: 30, avg: 35, max: 40, tot: 100}},
    {date: new Date(2019, 11, 4), values: {min: 40, avg: 50, max: 70, tot: 100}},
    {date: new Date(2019, 11, 5), values: {min: 20, avg: 60, max: 65, tot: 100}},
  ];

  get omgeving() {
    return environment;
  }

  public rangeBarModel: RangeModel =  {min: 30, avg: 50, max: 80, tot: 100};

  public veranderRangeBarmodel(): void {
    const r = () => getRandomGetal({min: 0, max: 100});
    const [min, avg, max, tot] = [r(), r(), r(), r()].sort((a, b) => a - b);
    this.rangeBarModel =  {min, avg, max, tot};
  }

  public veranderDonut1() {
    this.leermiddelinfoDonut1 = {
      leermiddelNaam: 'Biologie voor jou', getallen: [
        getRandomGetal({max: 200}),
        getRandomGetal({max: 200}),
        getRandomGetal({max: 200})
      ]
    };
  }

  public veranderActivatie(kleur: string) {
    if (kleur === 'rood') {
      this.leermiddelinfo = {leermiddelNaam: 'VO Got it Rekenen Leerlinglicentie', getallen: [14, 138]};
    } else if (kleur === 'geel') {
      this.leermiddelinfo = {leermiddelNaam: 'VO Got it Rekenen Leerlinglicentie', getallen: [76, 72]};
    } else if (kleur === 'groen') {
      this.leermiddelinfo = {leermiddelNaam: 'VO Got it Rekenen Leerlinglicentie', getallen: [144, 13]};
    } else {
      this.leermiddelinfo = {leermiddelNaam: 'VO Got it Rekenen Leerlinglicentie', getallen: [getRandomGetal(), getRandomGetal()]};
    }
  }

  public veranderBar(kleur: string) {
    if (kleur === 'rood') {
      this.progressbarinfo = {uitgever: 'Noordhoff', getallenActivatieOfGebruik: [1, 8], extraBoodschap: 'klassen'};
    } else if (kleur === 'geel') {
      this.progressbarinfo = {uitgever: 'Thieme Meulenhoff', getallenActivatieOfGebruik: [5, 5], extraBoodschap: 'klassen'};
    } else if (kleur === 'groen') {
      this.progressbarinfo = {uitgever: 'Malmberg', getallenActivatieOfGebruik: [9, 1], extraBoodschap: 'klassen'};
    } else {
      this.progressbarinfo = {
        uitgever: 'Malmberg',
        getallenActivatieOfGebruik: [getRandomGetal(), getRandomGetal()],
        extraBoodschap: 'klassen'
      };
    }
  }

  public veranderKengetallen() {
    const newKengetallen: Kengetal[] = [];
    for (let i = 0; i <= getRandomGetal({max: 10}); i++) {
      if (i % 2 === 0) {
        newKengetallen.push({
          kengetalInfo: this.kengetalCategorien[Math.floor(Math.random() * this.kengetalCategorien.length)],
          aantal: [getRandomGetal({max: 200})]
        });
      } else {
        newKengetallen.push({
          kengetalInfo: this.kengetalCategorien[Math.floor(Math.random() * this.kengetalCategorien.length)],
          aantal: [getRandomGetal({max: 100}), getRandomGetal({min: 100, max: 200})]
        });
      }
    }
    this.fakeKengetallen = newKengetallen;
    this.fakeKengetal = [{kengetalInfo: 'licenties geactiveerd', aantal: [getRandomGetal({max: 200})]}];
  }

  veranderGLineChart() {
    const r = (): number => Math.random();
    this.lineChartSubject = {
      table: [
        ['week', 'RekenPlus', 'TaalActief'],
        ['21', r(), r()],
        ['22', r(), r()],
        ['23', r(), r()],
        ['24', r(), r()],
        ['25', r(), r()]
      ]
    };
  }

  volgendeBarChart() {
    this.currentBarchartIndex += 1;
    while(this.barchartVullingen.length <= this.currentBarchartIndex)
    {
      this.barchartVullingen.push(createRandomBarChartInput());
      this.toonSchrijfacties.push(getRandomGetal() > 2);
    }
    this.onGetDataPoints.next(this.barchartVullingen[this.currentBarchartIndex]);
    this.onGetToonSchrijfacties.next(this.toonSchrijfacties[this.currentBarchartIndex]);
  }

  vorigeBarChart() {
    this.currentBarchartIndex -= 1;
    if(this.currentBarchartIndex === 0) this.currentBarchartIndex = this.barchartVullingen.length - 1;
    if(this.currentBarchartIndex < 0)
      this.volgendeBarChart();
    else {
      this.onGetDataPoints.next(this.barchartVullingen[this.currentBarchartIndex]);
      this.onGetToonSchrijfacties.next(this.toonSchrijfacties[this.currentBarchartIndex]);
    }
  }
}

function createRandomBarChartInput(): WebserviceDataPoint[] {
  const ret: WebserviceDataPoint[] = [];
  const aantalWeken = Math.floor(Math.random() * 51) + 1;
  for(let i: number = 0; i < aantalWeken; i++) {
    const valueThisWeek: WebserviceDataPoint = {eersteDagWeek: momentToLocalDate(startOfTerm().add(i, 'weeks')), aantalLeesacties: getRandomGetal({min: 0, max: 200}), aantalSchrijfacties: getRandomGetal({min: 0, max: 10})};
    ret.push(valueThisWeek);
  }
  return ret;
}

function getRandomGetal({min = 1, max = 9} = {}): number {
  return Math.floor(Math.random() * (max - min)) + min;
}

function createGebruiksAantallen(): Gebruiksaantallen {
  const leermiddelAantallen1: LeermiddelAantallen[] = [
    {naam: 'Getal en Ruimte 12e editie (2018)', aantalLicenties: 121, actiefGebruikt: 93, geactiveerd: 121},
    {naam: 'Op niveau 5e editie (2011)', aantalLicenties: 165, actiefGebruikt: 31, geactiveerd: 91},
    {naam: 'Feniks 3e editie (2007)', aantalLicenties: 165, actiefGebruikt: 0, geactiveerd: 1},
    {naam: 'Biologie voor jou 5e editie (2005)', aantalLicenties: 0, actiefGebruikt: 0, geactiveerd: 0}
  ];
  const leermiddelAantallen2: LeermiddelAantallen[] = [
    {naam: 'Bettermarks (2019)', aantalLicenties: 43, actiefGebruikt: 2, geactiveerd: 41},
    {naam: 'Impuls! 2e editie (2008)', aantalLicenties: 82, actiefGebruikt: 43, geactiveerd: 29},
    {naam: 'Grandes Lignes 6e editie (2018)', aantalLicenties: 63, actiefGebruikt: 57, geactiveerd: 40}
  ];
  const opleidingAantallen: OpleidingAantallen[] = [
    {naam: 'Havo/vwo 1', aantallenPerLeermiddel: leermiddelAantallen1},
    {naam: 'Havo/vwo 2', aantallenPerLeermiddel: leermiddelAantallen2}
  ];
  return ({aantallenPerOpleiding: opleidingAantallen});
}

