<div class="export-filter">
  <div class="export-filter__element grow">
    <app-datepicker
      alignment="left"
      display="alternative"
      [viewModel]="datumbereikFilterViewmodel"
    ></app-datepicker>
  </div>
  <div class="export-filter__element grow">
    <app-input-combobox [viewModel]="medewerkerFilterViewmodel"></app-input-combobox>
  </div>
  <div class="export-filter__element">
    <button class="button" buttonstyle="raised" color="green">zoeken</button>
  </div>

</div>
