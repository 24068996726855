import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {createPlatformVakTabelData, PlatformVakData} from './swplatformvak.model';
import {PlatformLocation} from '@angular/common';
import {ModalwindowService} from '../services/modalwindow.service';
import {ModalwindowFacade} from '../state/modalwindow/modalwindow.facade';
import {Koppelpartij } from '../state/privacydashboard/privacydashboard.state';
import { Router } from '@angular/router';
import { whatsnewUpdates} from '../whatsnew/updates';

@Component({
  selector: 'app-modalwindow',
  templateUrl: './modalwindow.component.html',
  styleUrls: ['./modalwindow.component.scss'],
})
export class ModalwindowComponent implements OnInit, OnDestroy {
  @Input()
  set onderwerp(onderwerp: string) {
    this.veranderSelectie(onderwerp);
  }

  @Input()
  set close(close: boolean) {
    if (close) {
      this.closeVenster();
    }
  }

  selectie: number;

  platformVakData: PlatformVakData;

  beschikbareKoppelingen: Koppelpartij[];

  private subscriptions: Subscription[] = [];

  verticalOffset: number;

  updates = whatsnewUpdates;

  constructor(
    private location: PlatformLocation,
    private modalwindowService: ModalwindowService,
    private store: ModalwindowFacade,
    private router: Router
  ) {
    // closes modal when back button is clicked
    location.onPopState(() => this.closeVenster());
  }

  ngOnInit() {
    this.verticalOffset = window.scrollY;
    if (this.selectie === 4) {
      this.subscriptions.push(
        this.store.selectStudiewijzerlinksPlatformVak().subscribe(value => {
          if (value) {
            this.platformVakData = {
              platform: value.naam,
              vak: value.vaknaam,
              tabelData: value.getallenPerOnderwijssoort ? createPlatformVakTabelData(value.getallenPerOnderwijssoort) : null};
          } else {
            this.platformVakData = null;
          }
        })
      );
    }
    else if(this.selectie === 6) {
      this.subscriptions.push(
        this.store.selectBeschikbareKoppelingen().subscribe(value => {
          if(value) {
            this.beschikbareKoppelingen = value;
          } else {
            this.beschikbareKoppelingen = null;
          }
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onScroll() {
    this.verticalOffset = window.scrollY;
  }

  closeVenster() {
    this.modalwindowService.closeWindow();
  }

  veranderSelectie(onderwerp: string) {
    const onderwerpen = ['actief', 'gebruik', 'gebruikPerWeek', 'klikratio', 'platformVak', 'landelijkVak', 'nieuweKoppeling', 'whatsnew'];
    this.selectie = onderwerpen.indexOf(onderwerp);
  }

  nieuweKoppeling(event: {uuid: string}){
    this.closeVenster();
    this.router.navigateByUrl('privacydashboard/koppelingaanmaken/' + encodeURIComponent(event.uuid));
  }

  doorverwijzingPartnerprogramma(): void {
    window.open('https://som.today/somtoday-connect-partnerprogramma/');
  }


}
