<div *ngIf="unauthenticated" class="uitleg">
  <h2>Je hebt met dit account geen toegang tot Somtoday Connect</h2>

  <div class="melding">Controleer bij de schooladministratie of je de benodigde rechten hebt. <br>
  Is je school nog niet in het bezit van <a href="https://som.today/somtoday-connect/" target="_blank">Somtoday Connect</a>
  neem dan contact op met jouw relatiebeheerder.</div>
</div>

<div *ngIf="geenVestigingen" class="uitleg">
  <h2>Je hebt met dit account geen toegang tot Somtoday Connect</h2>

  <div class="melding">Er zijn geen vestigingen gekoppeld aan het account waarmee je bent ingelogd. <br>
    Controleer bij de schooladministratie of dat klopt.</div>
</div>

<div *ngIf="ongeldigeVestiging" class="uitleg">
  <h2>Je hebt geen toegang tot de gegevens van deze vestiging</h2>

  <div class="melding">Je probeert de gegevens te bekijken van een vestiging die niet aan jouw account is gekoppeld.<br>
    Controleer bij je schooladministratie of dat klopt.</div>
</div>

<div *ngIf="geenToegangToPrivacydashboard" class="uitleg">
  <h2>Je hebt geen toegang tot het privacydashboard</h2>

  <div class="melding">Controleer bij de schooladministratie of je de benodigde rechten hebt.</div>
</div>

<div *ngIf="overig.length" class="uitleg">
  <h2>Sorry, er is iets fout gegaan</h2>

  <div class="melding">Probeer het nog een keer of meld je bij de
    <a href="https://servicedesk-beheer.som.today/support/home" target="_blank">service desk</a>.
    <p>Mogelijk kan het onderstaande daarbij helpen:</p>
    <ul>
      <li *ngFor="let melding of overig">{{melding}}</li>
    </ul>
  </div>
</div>

