import {Component, OnInit, ChangeDetectionStrategy, Input} from '@angular/core';
import {VeldOudeWebservice} from '../../state/privacydashboard/privacydashboard.state';
import {BehaviorSubject} from 'rxjs';
import {Risk} from '../webservice-tegel/webservice-tegel.component';

@Component({
  selector: 'app-webservice-datatoegang',
  templateUrl: './webservice-datatoegang.component.html',
  styleUrls: ['./webservice-datatoegang.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WebserviceDatatoegangComponent implements OnInit {

  @Input()
  viewModel: WebserviceDatatoegangViewModel;

  constructor() { }

  ngOnInit(): void {
  }

}

export interface WebserviceDatatoegangViewModel {
  webserviceNaam: BehaviorSubject<string>;
  webserviceRisico: BehaviorSubject<Risk>;
  webserviceRisicoTekst: BehaviorSubject<string>;
  toegangTekst: BehaviorSubject<string>;
  doelgroepenTekst: BehaviorSubject<string>;
  datatoegangTekst: BehaviorSubject<string>;
  datatoegangVelden: BehaviorSubject<VeldOudeWebservice[][]>;
}
