import { Component} from '@angular/core';
import {TableCellComponent} from '../table/table.model';
import {LesgroepActivatiegetalCellModel} from './lesgroep-activatiegetal-cell.model';

@Component({
  selector: 'app-lesgroep-activatiegetal-cell',
  templateUrl: './lesgroep-activatiegetal-cell.component.html',
  styleUrls: ['./lesgroep-activatiegetal-cell.component.scss']
})
export class LesgroepActivatiegetalCellComponent implements TableCellComponent<LesgroepActivatiegetalCellModel> {
  data: LesgroepActivatiegetalCellModel;
}
