import { Component } from '@angular/core';
import {HomeDataService} from './home.data-service';
import {BigInfoData} from '../layout/infoblocks/big-info-detail/big-info-data';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  homeData: BigInfoData[];
  introductionText: BigInfoData = {type: 'text', data:
      'Ontdek Somtoday Connect. Met Somtoday Connect heb je de regie over met ' +
        'welke applicaties jouw school veilig ' +
        'koppelt en kan je dit volledig beheren. Daarbij geven we je met eenvoudige dashboards handvatten' +
        ' om leermiddelen optimaal in te zetten. ' +
        'Zo kun je sturen op het gebruik van deze leermiddelen en je inkoopbeleid afstemmen op de behoefte.' +
        'Om de school de regie te geven over het effectief inzetten van digitale leermiddelen\n' +
        'in de klas introduceren wij Somtoday Connect. Somtoday Connect bevat onder\n' +
        'andere een dashboard dat de activatiegraad en het gebruik van digitale leermiddelen in\n' +
        'de school toont. Zo behoud je grip en kun je actief monitoren en sturen op digitaal\n' +
        'onderwijs. Om Somtoday Connect goed aan te laten sluiten op de behoefte hebben wij\n' +
        'met 15 pilotscholen samengewerkt die hebben geholpen de koers te bepalen.\n', fullRow: true};

  constructor(private homeDataService: HomeDataService) {
    homeDataService.getInfoBlocks().subscribe(result => {
      this.homeData = (result as BigInfoData[]);
    });
  }
}
