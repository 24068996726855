import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ApolloError} from 'apollo-client';
import {HttpErrorResponse} from '@angular/common/http';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {AppState} from '../state/app.state';
import {map, tap} from 'rxjs/operators';
import {reportError, setErrorMessages} from '../state/errors/errors.actions';
import {Payload, payload} from '../state/payload';
import {ModalwindowService} from '../services/modalwindow.service';
import {environment} from '../../environments/environment';

@Injectable()
export class ErrorsEffect {
  $ = createEffect(() => this.actions$.pipe(
    ofType(reportError),
    tap(() => this.modalwindowService.closeWindow()),
    tap(() => this.router.navigateByUrl('/error')),
    map(createMessages),
    map(payload),
    map(setErrorMessages)
  ));

  constructor(private actions$: Actions,
              private store: Store<AppState>,
              private router: Router,
              private modalwindowService: ModalwindowService) {
  }
}

function createMessages({value: error}: Payload<ApolloError>): Set<string> {
  if (environment.DEBUG) {
    console.log(error);
  }

  if (error.networkError) {
    if ((error.networkError as HttpErrorResponse).status === 401) {
      return new Set(['Unauthenticated']);
    } else {
      return new Set([error.message]);
    }
  } else if (error.graphQLErrors) {
    return error.graphQLErrors?.map((value) => value.message)
      .reduce((s, v) => s.add(v), new Set<string>()) || new Set<string>();
  } else {
    return new Set([error.message]);
  }
}
