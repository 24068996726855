import {DocentenEffect} from '../old-dashboarding/effects/docenten.effect';
import {ErrorsEffect} from './errors.effect';
import {KlassendashboardEffect} from '../old-dashboarding/effects/klassendashboard.effect';
import {LeerlingenEffect} from '../old-dashboarding/effects/leerlingen.effect';
import {MeEffect} from './me.effect';
import {MedewerkersEffect} from '../old-dashboarding/effects/medewerkers.effect';
import {SchooldashboardEffect} from '../old-dashboarding/effects/schooldashboard.effect';
import {StudiewijzerlinkjesEffect} from '../old-dashboarding/effects/studiewijzerlinkjes.effect';
import {StudiewijzerlinkjeselectionEffect} from '../old-dashboarding/effects/studiewijzerlinkjeselection.effect';
import {StudiewijzerlinkjesdetailselectionEffect} from '../old-dashboarding/effects/studiewijzerlinkjesdetailselection.effect';
import {LeermiddelvakkoppelingEffect} from '../old-dashboarding/effects/leermiddelvakkoppeling.effect';
import {
  RefreshGeslecteerdeKoppelpartijEffect,
  FetchGeslecteerdeKoppelingenEffect,
  DeleteKoppeling,
  PrivacydashboardEffect,
  FetchGeselecteerdeWebserviceEffect,
  FetchWebservicesEffect,
  FetchWebserviceWeekGebruikEffect,
  FetchWebserviceAccountGebruikEffect,
  VestigingenEffect,
  SaveKoppeling,
  RefreshPrivacydashboardEffect,
  SaveOpLeerlingGeautoriseerdeKoppeling,
  SemEffects,
} from './privacydashboard.effect';
import {GroeperenOpPlatformEffect} from '../old-dashboarding/effects/groeperen-op-platform-effect.service';
import {VestigingEffect} from '../old-dashboarding/effects/vestiging.effect';
import {ClaimsEffect} from './claims.effect';
import {LeermiddelendashboardEffect} from '../old-dashboarding/effects/leermiddelendashboard.effect';
import {
  FetchKoppelpartij,
  FetchLeespermissies,
  FetchSchrijfpermissies,
  FetchEndpoints,
  FetchOAuthClient,
  FetchOrganisaties,
  SaveKoppelpartij
} from './koppelpartij-bewerken.effect';
import {
  PrivacydashboardPreviewEffect,
  PrivacydashboardPreviewGeselecteerdeKoppelpartijEffect,
  PrivacydashboardPreviewSaveKoppeling,
  PrivacydashboardPreviewDeleteKoppeling
} from './privacydashboard-preview.effect';
import {DashboardsettingsEffect, DoFetchDatumbereikenVanVestiging} from './dashboardsettings.effect';
import {
  KlassendashboardDocentenEffect,
  KlassendashboardIndividueelEffect,
  KlassendashboardLeerlingenEffect,
  KlassendashboardSamenvattingEffect,
  InvalidKlassendashboardEffect
} from './klassendashboard.effect';
import {
  BazenbannerEffect,
  SchooldashboardOnderwijssoortDetailsEffect,
  SchooldashboardOnderwijssoortenEffect,
  SchooldashboardVakDetailsEffect,
  SchooldashboardVakkenEffect,
  InvalidSchooldashboardEffect,
  OngekoppeldeLeermiddelenEffect
} from './schooldashboard.effect';
import {
  StudiewijzerlinkjesPlatformenEffect,
  StudiewijzerlinkjesVakkenEffect, StudiewijzerOnderwijssoortLeerjaarInzichtEffect,
  StudiewijzerPlatformDetailsEffect, StudiewijzerVakDetailsEffect
} from './studiewijzerlinkjes.effect';
import {
  VakdashboardDocentenEffect,
  VakdashboardEffect,
  VakdashboardVakkenEffect,
  VakdashboardOnderwijssoortenEffect,
  InvalidVakdashboardEffect
} from './vakdashboard.effect';
import {
  LeermiddelenEffect,
  KoppelLeermiddelVakkenEffect
} from './leermiddelvakkoppeling.effect';
import { DashboardBackofficeEffects } from './dashboard-backoffice.effect';
import { ExportoverzichtEffects } from './exportoverzicht.effect';


export const effects = [
  DocentenEffect,
  ErrorsEffect,
  KlassendashboardEffect,
  LeerlingenEffect,
  MeEffect,
  MedewerkersEffect,
  SchooldashboardEffect,
  StudiewijzerlinkjesEffect,
  GroeperenOpPlatformEffect,
  StudiewijzerlinkjeselectionEffect,
  StudiewijzerlinkjesdetailselectionEffect,
  LeermiddelvakkoppelingEffect,
  RefreshGeslecteerdeKoppelpartijEffect,
  FetchGeslecteerdeKoppelingenEffect,
  PrivacydashboardEffect,
  VestigingEffect,
  VestigingenEffect,
  ClaimsEffect,
  LeermiddelendashboardEffect,
  FetchWebservicesEffect,
  FetchWebserviceAccountGebruikEffect,
  FetchWebserviceWeekGebruikEffect,
  FetchGeselecteerdeWebserviceEffect,
  DeleteKoppeling,
  SaveKoppeling,
  RefreshPrivacydashboardEffect,
  FetchKoppelpartij,
  FetchLeespermissies,
  FetchSchrijfpermissies,
  FetchEndpoints,
  FetchOAuthClient,
  FetchOrganisaties,
  SaveKoppelpartij,
  PrivacydashboardPreviewEffect,
  PrivacydashboardPreviewGeselecteerdeKoppelpartijEffect,
  PrivacydashboardPreviewSaveKoppeling,
  PrivacydashboardPreviewDeleteKoppeling,
  SaveOpLeerlingGeautoriseerdeKoppeling,
  DashboardsettingsEffect,
  KlassendashboardIndividueelEffect,
  KlassendashboardSamenvattingEffect,
  KlassendashboardDocentenEffect,
  KlassendashboardLeerlingenEffect,
  InvalidKlassendashboardEffect,
  SchooldashboardVakkenEffect,
  SchooldashboardVakDetailsEffect,
  SchooldashboardOnderwijssoortenEffect,
  SchooldashboardOnderwijssoortDetailsEffect,
  InvalidSchooldashboardEffect,
  BazenbannerEffect,
  StudiewijzerlinkjesPlatformenEffect,
  StudiewijzerPlatformDetailsEffect,
  StudiewijzerlinkjesVakkenEffect,
  StudiewijzerVakDetailsEffect,
  StudiewijzerOnderwijssoortLeerjaarInzichtEffect,
  VakdashboardEffect,
  VakdashboardDocentenEffect,
  VakdashboardVakkenEffect,
  VakdashboardOnderwijssoortenEffect,
  InvalidVakdashboardEffect,
  LeermiddelenEffect,
  KoppelLeermiddelVakkenEffect,
  SemEffects,
  DoFetchDatumbereikenVanVestiging,
  DashboardBackofficeEffects,
  OngekoppeldeLeermiddelenEffect,
  ExportoverzichtEffects
];
