<h1>Overige webservices</h1>
<p class="bodyContent">Verouderde koppelingen, mogelijk met risico’s</p>
<div class="tegelWrapper">
  <div *ngFor="let ws of (webservices$ | async)">
    <app-webservice-tegel
      [icon]="(['WIS', 'UM'].includes(ws.service)) ? iconHighRisk : iconMediumRisk"
      [title]="ws.service"
      [txt]="getTxt(ws)"
      (openWebservice)="openWebservice(ws)"
    >
    </app-webservice-tegel>
  </div>
</div>
