import { Component, OnInit } from '@angular/core';
import {TableCellComponent} from '../table/table.model';

@Component({
  selector: 'app-label-cell',
  templateUrl: './label-cell.component.html',
  styleUrls: ['./label-cell.component.scss']
})
export class LabelCellComponent implements TableCellComponent<string> {
  data: string;
}
