import {Component, ChangeDetectionStrategy, Input} from '@angular/core';

@Component({
  selector: 'app-blauwe-balk',
  templateUrl: './blauwe-balk.component.html',
  styleUrls: ['./blauwe-balk.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlauweBalkComponent {
  @Input()
  iconUrl: string;
}
