import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { DatepickerComponentViewModel } from '../datepicker/datepicker.component';

@Component({
    selector: 'app-controls-new',
    templateUrl: './controls-new.component.html',
    styleUrls: ['./controls-new.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ControlsNewComponent {

    public leftButtonSelected: boolean;

    @Input()
    public viewModel: ControlsViewModel;

    constructor() { }

    public toggle(): void {
        this.viewModel.toggle();
    }
}

export interface ControlsViewModel extends DatepickerComponentViewModel {
    onGetTitle: Observable<string>;
    onGetLeftLabel: Observable<string>;
    onGetLeftActive: Observable<boolean>;
    onGetRightLabel: Observable<string>;
    onGetRightActive: Observable<boolean>;

    toggle: () => void;
}

