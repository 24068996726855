import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {loadLeerlingen, setLeerlingen} from '../state/schooldashboard/schooldashboard.actions';
import {map, switchMap, take} from 'rxjs/operators';
import {AppState} from '../../state/app.state';
import {Store} from '@ngrx/store';
import {payload} from '../../state/payload';
import {selectLeerlingUUIDs} from '../state/klassendashboard/klassendashboard.selector';
import {SomtodayService} from '../../services/somtoday.service';
import {pipe} from 'rxjs';
import {apply, select} from '../../state/store.fn';

@Injectable()
export class LeerlingenEffect {
  loadLeerlingen$ = createEffect(() => this.actions$.pipe(
    ofType(loadLeerlingen),
    switchMap(pipe(apply(selectLeerlingUUIDs), select(this.store), take(1))),
    switchMap(uuids => this.somtodayService.fetchLeerlingen(uuids)),
    map(payload),
    map(setLeerlingen)
  ));

  constructor(private actions$: Actions, private store: Store<AppState>, private somtodayService: SomtodayService) {
  }
}
