<div class="contentbox">
  <div class="lesgroep-titlebox">
    <div class="lesgroepnaam">{{naam}}</div>
    <div class="aantal-leerlingen">{{aantalLeerlingen}} leerlingen</div>
  </div>
  <div class="docenten-box">
    <p class="docenten-title">Docenten</p>
    <ul *ngIf="medewerkers$" class="bulletloze-lijst">
      <li class="docent-regel" *ngFor="let medewerker of (medewerkers$ | async)">{{medewerker}}</li>
    </ul>
  </div>
</div>


