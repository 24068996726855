import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../environments/environment';
import {LeermiddelLicentie} from 'contract-ts/connect';

@Injectable()
export class LeermiddelLicentieDataService {

  licenties$: Observable<LeermiddelLicentie[]>;

  private SERVICE_PATH = 'dashboard/leermiddellicentie/';

  constructor(private http: HttpClient) {
    this.licenties$ = this.http.get<LeermiddelLicentie[]>(`${environment.apiUrl}${this.SERVICE_PATH}all`);
  }

  public getLicenties(): Observable<LeermiddelLicentie[]> {
    return this.licenties$;
  }
}

