import { Component } from '@angular/core';
import {TableCellComponent} from '../table/table.model';
import {LeerlingnaamActivatiegetalCellModel} from './leerlingnaam-activatiegetal-cell.model';

@Component({
  selector: 'app-leerlingnaam-activatiegetal-cell',
  templateUrl: './leerlingnaam-activatiegetal-cell.component.html',
  styleUrls: ['./leerlingnaam-activatiegetal-cell.component.scss']
})
export class LeerlingnaamActivatiegetalCellComponent implements TableCellComponent<LeerlingnaamActivatiegetalCellModel> {
  data: LeerlingnaamActivatiegetalCellModel;
}
