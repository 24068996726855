import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {
  DashboardFilterInput,
  DocentenVanLesgroepGQL,
  LeermiddelenDashboardFilterInput,
  LeermiddelenDashboardGQL,
  LesgroepDetailDashboardData,
  LesgroepDetailDashboardFilterInput,
  LesgroepDetaildashboardGQL,
  LesgroepDetailSamenvattingDashboardGQL,
  LesgroepDetailSamenvattingData,
  LesgroepOverstijgendDagGebruik,
  LesgroepSchooldashboardGQL,
  OpleidingVakLesgroepDagGebruik,
  PeriodeInput,
  PlatformVak,
  StudiewijzerlinksFilterInput,
  StudiewijzerlinksPerPlatformGQL,
  StudiewijzerlinksPerVakGQL,
  StudiewijzerPlatformVakGQL,
  SwDetailsPerPlatformGQL,
  SwDetailsPerVakGQL,
  SwLinkjesGebruikDetails,
  SwLinkjesPerPlatformOfVak,
  UitgeverOfVakGebruikVoorPeriode,
  VakloosLeermiddelOpleidingDagGebruik,
  Vestiging
} from '../../generated/graphql';
import {map} from 'rxjs/operators';
import {BazenbannerModel, refreshBazenBannerModel} from '../layout/model/bazenbanner.model';
import * as m from 'moment';
import {Periode, periodeNaarDatums} from './datumbereik';
import {ErrorsEffect} from '../effects/errors.effect';
import {SchooldashboardModel} from '../old-dashboarding/schooldashboard/schooldashboard.model';
import {SchooldashboardDetailModel} from '../old-dashboarding/schooldashboard/schooldashboard.detail.model';
import {ApolloError} from 'apollo-client';
import {PeriodeOptie} from '../layout/datumbereik/datumbereik.component';
import {Queue} from './queue';
import {Store} from '@ngrx/store';
import {AppState} from '../state/app.state';
import {payload} from '../state/payload';
import {reportError} from '../state/errors/errors.actions';
import { SomtodayService } from './somtoday.service';
import { Router } from '@angular/router';

@Injectable()
export class SharedVariablesService {
  private vestigingen$: Subject<Vestiging[]> = new BehaviorSubject<Vestiging[]>([]);

  private selectedVestiging: Vestiging;

  private vestigingLicenties$ = new BehaviorSubject<boolean>(null);

  private vestigingStudiewijzers$ = new BehaviorSubject<boolean>(null);

  private bazenbannerModel$: Subject<BazenbannerModel> = new BehaviorSubject<BazenbannerModel>(
    {
      educatieveAanbieders: 0,
      lesgroepActivatieInProcenten: 0,
      licentiesPerLeerling: 0,
      linechartGebruik: [],
      gemActieveLeerlingenPerDag: 0,
      aantalGeactiveerdeLicenties: 0
    });

  private schoolDashBoardData$ = new BehaviorSubject<SchooldashboardModel[]>(null);

  private lesgroepDetailDashboardData$ = new BehaviorSubject<LesgroepDetailDashboardData>(null);

  private lesgroepDetailSamenvattingData$ = new BehaviorSubject<LesgroepDetailSamenvattingData>(null);

  private periodeChanged$ = new BehaviorSubject<Periode>(Periode.Week);

  private lastSWFilter: DashboardFilterInput;

  private lastSWDFilter: {key: string, filter: DashboardFilterInput};

  private filter_: DashboardFilterInput;

  private filterQueue_: Queue<DashboardFilterInput> = new Queue();

  private filterBusy_ = false;

  public lesgroepDetailFilter_: LesgroepDetailDashboardFilterInput;

  public lesgroepDetailSamenvattingFilter_: LesgroepDetailDashboardFilterInput;

  public lastLesgroepDetailFilter: LesgroepDetailDashboardFilterInput;

  public lastLesgroepDetailSamenvattingFilter: LesgroepDetailDashboardFilterInput;

  public swLinksFilter_: StudiewijzerlinksFilterInput;

  public lastSWLinksFilter: StudiewijzerlinksFilterInput;

  private leermiddelFilter_: LeermiddelenDashboardFilterInput;

  private lastLeermiddelFilter_: LeermiddelenDashboardFilterInput;

  private periode_: Periode;

  private opleidingVakLesgroepDagGebruik_: OpleidingVakLesgroepDagGebruik[];

  private vakloosdashboardData_: VakloosLeermiddelOpleidingDagGebruik[];

  private ongeclassificeerdeMethodeDagGebruik_: LesgroepOverstijgendDagGebruik[];

  private cache_: SchooldashboardCache = new SchooldashboardCache();

  vakloosdashboardData$ = new BehaviorSubject<VakloosLeermiddelOpleidingDagGebruik[]>(null);

  opleidingVakLesgroepDagGebruik$ = new BehaviorSubject<OpleidingVakLesgroepDagGebruik[]>(null);

  ongeclassificeerdeMethodeDagGebruik$ = new BehaviorSubject<LesgroepOverstijgendDagGebruik[]>(null);

  studiewijzerlinkjesPerPlatform$ = new BehaviorSubject<SwLinkjesPerPlatformOfVak[]>([]);

  aantalLesgroepenMetStudiewijzerlinks$ = new BehaviorSubject<number>(0);

  groeperenOpVak = true;

  swGroeperenOpPlatform = true;

  groeperenOpUitgever = true;

  onGetGroeperingOpPlatform$ = new BehaviorSubject<boolean>(true);

  /**
   * Dit is het id van het element waar naartoe moet worden gescrolled wanneer de gebruiker terugkeert op de pagina.
   */
  returnId: string;

  vestigingUUID$ = new Subject<string>();

  studiewijzerlinkjesDetailsPerPlatform$ = new BehaviorSubject<SwLinkjesGebruikDetails[]>([]);

  studiewijzerlinksPlatformVak$ = new BehaviorSubject<PlatformVak>(null);

  studiewijzerLinkjesOverzichtLoading$ = new BehaviorSubject<boolean>(false);

  studiewijzerLinkjesDetailLoading$ = new BehaviorSubject<boolean>(false);

  leermiddelenDashboardLoading$ = new BehaviorSubject<boolean>(false);

  leermiddelenDashboard$ = new BehaviorSubject<UitgeverOfVakGebruikVoorPeriode[]>([]);

  constructor(
    private dashboardQuery: LesgroepSchooldashboardGQL,
    private lesgroepDetailQuery: LesgroepDetaildashboardGQL,
    private lesgroepDetailSamenvattingQuery: LesgroepDetailSamenvattingDashboardGQL,
    private swpq: StudiewijzerlinksPerPlatformGQL,
    private swvq: StudiewijzerlinksPerVakGQL,
    private errorsService: ErrorsEffect,
    private swDetailsPerPlatformGQL: SwDetailsPerPlatformGQL,
    private studiewijzerPlatformVakGQL: StudiewijzerPlatformVakGQL,
    private swDetailsPerVakGQL: SwDetailsPerVakGQL,
    private docentenVanLesgroepGQL: DocentenVanLesgroepGQL,
    private leermiddelenDashboardGQL: LeermiddelenDashboardGQL,
    private store: Store<AppState>,
    private somtodayService: SomtodayService,
    private router: Router
  ) {
    this.periode = Periode.Week;
  }

  private openVakken_ = new Set<string>();

  private openOnderwijssoorten_ = new Set<string>();

  private selectedLesgroep: string;

  private selectedSWPlatform: string;

  private selectedSWVak: string;

  getoondePeriodeOpties: PeriodeOptie[] = [];

  resetData(): void {
    this.bazenbannerModel$.next({
      educatieveAanbieders: 0,
      lesgroepActivatieInProcenten: 0,
      licentiesPerLeerling: 0,
      linechartGebruik: [],
      gemActieveLeerlingenPerDag: 0,
      aantalGeactiveerdeLicenties: 0
    });
    this.swLinksFilter_ = null;
    this.lastSWLinksFilter = null;
    this.lastLesgroepDetailFilter = null;
    this.lastLesgroepDetailSamenvattingFilter = null;
    this.lesgroepDetailSamenvattingData$.next(null);
    this.lesgroepDetailDashboardData$.next(null);
    this.schoolDashBoardData$.next(null);
    this.filter_ = null;
    this.lastSWDFilter = null;
    this.lastSWFilter = null;
    this.vestigingen$.next(null);
    this.selectedVestiging = null;
    this.vestigingStudiewijzers$.next(null);
    this.vestigingLicenties$.next(null);
    this.onGetGroeperingOpPlatform$.next(true);
    this.swGroeperenOpPlatform = true;
    this.groeperenOpVak = true;
    this.aantalLesgroepenMetStudiewijzerlinks$.next(0);
    this.studiewijzerlinkjesPerPlatform$.next([]);
    this.opleidingVakLesgroepDagGebruik$.next(null);
    this.ongeclassificeerdeMethodeDagGebruik$.next(null);
    this.vakloosdashboardData$.next(null);
    this.cache_ = new SchooldashboardCache();
    this.studiewijzerLinkjesDetailLoading$.next(null);
    this.studiewijzerLinkjesOverzichtLoading$.next(null);
    this.studiewijzerlinksPlatformVak$.next(null);
    this.studiewijzerlinkjesDetailsPerPlatform$.next(null);
    this.vestigingUUID$.next(null);
    this.returnId = undefined;
    this.selectedSWVak = null;
    this.selectedSWPlatform = null;
    this.selectedLesgroep = null;
    this.openOnderwijssoorten = new Set<string>();
    this.periode = Periode.Week;
    this.leermiddelFilter_ = null;
    this.lastLeermiddelFilter_ = null;
    this.getoondePeriodeOpties = [];
  }
  set geselecteerdeVestiging(vestiging: Vestiging) {
    if (this.geselecteerdeVestiging !== vestiging) {
      this.selectedVestiging = vestiging;
      this.vestigingLicenties$.next(vestiging.vanafdatumLicenties !== null);
      this.vestigingStudiewijzers$.next(vestiging.vanafdatumStudiewijzers !== null);
      this.vestigingUUID$.next(vestiging.uuid);
    }
  }

  get geselecteerdeVestiging(): Vestiging {
    return this.selectedVestiging;
  }

  get vestigingBevatLicenties(): BehaviorSubject<boolean> {
    return this.vestigingLicenties$;
  }

  get vestigingBevatStudiewijzers(): BehaviorSubject<boolean> {
    return this.vestigingStudiewijzers$;
  }

  set geselecteerdeSWPlatform(platform: string) {
    if (this.selectedSWPlatform !== platform) {
      this.selectedSWPlatform = platform;
    }
    this.swLinksFilter_.platformnaam = platform;
  }

  get geselecteerdeSWPlatform(): string {
    return this.selectedSWPlatform;
  }

  set geselecteerdeSWVak(vak: string) {
    if (this.selectedSWVak !== vak) {
      this.selectedSWVak = vak;
    }
    this.swLinksFilter_.vaknaam = vak;
  }

  get geselecteerdeSWVak(): string {
    return this.selectedSWVak;
  }
  get lesgroepDetailSamenvattingData(): Subject<LesgroepDetailSamenvattingData> {
    return this.lesgroepDetailSamenvattingData$;
  }

  get vestigingen(): Subject<Vestiging[]> {
    return this.vestigingen$;
  }
  set periode(value: Periode) {
    this.periodeChanged$.next(value);
    this.periode_ = value;
    const {from, to} = periodeNaarDatums(value);
    const vanaf = m(from).format('YYYY-MM-DD');
    const totEnMet = m(to).format('YYYY-MM-DD');
    this.filter_ = {
      ...this.filter_,
      periode: value as unknown as PeriodeInput
    };
    this.lesgroepDetailFilter_ = {
      ...this.lesgroepDetailFilter_,
      vanaf,
      totEnMet
    };
    this.lesgroepDetailSamenvattingFilter_ = {
      ...this.lesgroepDetailSamenvattingFilter_,
      vanaf,
      totEnMet
    };
    this.swLinksFilter_ = {
      ...this.swLinksFilter_,
      vanaf,
      totenmet: totEnMet
    };
    this.leermiddelFilter_ = {
      ...this.leermiddelFilter_,
      periode: value as unknown as PeriodeInput
    };
  }

  get periode(): Periode {
    return this.periode_;
  }
  set openVakken(vakken: Set<string>) {
    this.openVakken_ = new Set<string>(vakken);
  }
  set openOnderwijssoorten(ows: Set<string>) {
    this.openOnderwijssoorten_ = new Set<string>(ows);
  }

  setFilterVestiging(uuid: string) {
    this.filter_.vestiging = uuid;
    this.swLinksFilter_.vestiging = uuid;
    this.leermiddelFilter_.vestiging = uuid;
  }
  hasLesgroepFilterChanged(): boolean {
    const{lesgroepUUID, vanaf, totEnMet} = this.lesgroepDetailFilter_;
    const{lesgroepUUID: lastLesgroepUUID, vanaf: lastVanaf, totEnMet: lastTotEnMet} = this.lastLesgroepDetailFilter
          || {} as LesgroepDetailDashboardFilterInput;
    return lesgroepUUID !== lastLesgroepUUID || vanaf !== lastVanaf || totEnMet !== lastTotEnMet;
  }

  hasLesgroepSamenvattingFilterchanged(): boolean {
    const {lesgroepUUID, vanaf, totEnMet} = this.lesgroepDetailSamenvattingFilter_;
    const {lesgroepUUID: lastLesgroepUUID, vanaf: lastVanaf, totEnMet: lastTotEnMet} = this.lastLesgroepDetailSamenvattingFilter
    || {} as LesgroepDetailDashboardFilterInput;
    return lesgroepUUID !== lastLesgroepUUID || vanaf !== lastVanaf || totEnMet !== lastTotEnMet;
  }

  hasStudiewijzerLinksFilterChanged(): boolean {
    const {vaknaam, vanaf, platformnaam, vestiging, totenmet} = this.swLinksFilter_;
    const {vaknaam: lastVaknaam, vanaf: lastVanaf, platformnaam: lastPlatformnaam, vestiging: lastVestiging, totenmet: lastTotenmet}
    = this.lastSWLinksFilter || {} as StudiewijzerlinksFilterInput;
    return vaknaam !== lastVaknaam || vanaf !== lastVanaf || platformnaam !== lastPlatformnaam
      || vestiging !== lastVestiging || totenmet !== lastTotenmet;
  }
  private internalFetchDashboardData(filter: DashboardFilterInput): void
  {
    this.cache_.clear();
    this.dashboardQuery.fetch({filter}).pipe(map(result => result.data))
        .subscribe((value) => {
          const toDate = v => v ? new Date(v) : m().add(1, 'd').toDate();
          const toDateOrNull = v => v ? new Date(v) : v;
          this.vestigingen$.next(value.vestigingen.map(v => ({...v, dataSinds: toDate(v.dataSinds),
            vanafdatumLicenties: toDateOrNull(v.vanafdatumLicenties), einddatumLicenties: toDateOrNull(v.einddatumLicenties),
            vanafdatumStudiewijzers: toDateOrNull(v.vanafdatumStudiewijzers),
            einddatumStudiewijzers: toDateOrNull(v.einddatumStudiewijzers)})));
          this.opleidingVakLesgroepDagGebruik_ = value.lesgroepschooldashboard.map(o => ({...o, dag: new Date(o.dag)}));
          this.vakloosdashboardData_ = value.vakloosschooldashboard.map(o => ({...o, dag: new Date(o.dag)}));
          this.ongeclassificeerdeMethodeDagGebruik_ = value.lesgroepOverstijgendDagGebruik.map(o => ({...o, dag: new Date(o.dag)}));
          this.vakloosdashboardData$.next(this.vakloosdashboardData_);
          this.opleidingVakLesgroepDagGebruik$.next(this.opleidingVakLesgroepDagGebruik_);
          this.ongeclassificeerdeMethodeDagGebruik$.next(this.ongeclassificeerdeMethodeDagGebruik_);
          this.refreshSchooldashboardModel();
          this.bazenbannerModel$.next(refreshBazenBannerModel(value, this.periode));
        },
          error => this.handleErrors(error),
        // when pipe done check for more filters on queue
          () => this.onFetchDashboardDataDone());
  }

  private onFetchDashboardDataDone(): void {
    if (!this.filterQueue_.isEmpty()) {
      this.internalFetchDashboardData(this.filterQueue_.dequeue());
    }
    else {
      this.filterBusy_ = false;
    }
  }

  fetchDocenten(lesgroepUUIDs: string[]): Observable<Map<string, string[]>> {
    return this.docentenVanLesgroepGQL.fetch({filter: this.filter_, lesgroepUUIDs})
      .pipe(
        map(result => result.data.docentenVanLesgroep),
        map(entries => entries.reduce((a, {key, value}) => a.set(key, value), new Map<string, string[]>()))
        );
  }
  public fetchLeermiddelenDashboardData(groeperenOpUitgever: boolean): boolean {
    this.groeperenOpUitgever = groeperenOpUitgever;
    this.leermiddelFilter_.groepeerOpUitgever = groeperenOpUitgever;
    if (this.isErIetsGewijzigdOpHetLeermiddelenDashboard(this.lastLeermiddelFilter_)) {
      this.leermiddelenDashboardLoading$.next(true);
      this.leermiddelenDashboardGQL.fetch({filter: this.leermiddelFilter_}).pipe(map(result => result.data))
          .subscribe(({vestigingen, leermiddelDashboard}) => {
              leermiddelDashboard.forEach(
                d => d.methodeDagGebruik.forEach(
                  mg => mg.leerlingDagGebruik.forEach(
                    g => g.date = new Date(g.date)
                  )));
              this.leermiddelenDashboard$.next(leermiddelDashboard);
              this.vestigingen$.next(vestigingen);
              this.leermiddelenDashboardLoading$.next(false);
            },
            error => this.handleErrors(error));
      this.lastLeermiddelFilter_ = {...this.leermiddelFilter_};
      return true;
    }
    return false;
  }

  public isGroeperingGewijzigd(groeperenOpPlatform: boolean): boolean {
      return this.swGroeperenOpPlatform !== groeperenOpPlatform;
  }
  private handleErrors(error: ApolloError) {
    this.store.dispatch(reportError(payload(error)));
  }

  refreshSchooldashboardModel() {
  }
  private isErIetsGewijzigdOpHetLeermiddelenDashboard(lastFilter: LeermiddelenDashboardFilterInput) {
    const {vestiging, periode, groepeerOpUitgever} = this.leermiddelFilter_;
    const {vestiging: lastVestiging, periode: lastPeriode, groepeerOpUitgever: lastGroepeerOpUitgever} = lastFilter || {};
    return vestiging !== lastVestiging || periode !== lastPeriode || groepeerOpUitgever !== lastGroepeerOpUitgever;
  }
}

class SchooldashboardCache {
  vak: SchooldashboardModel[];
  onderwijssoort: SchooldashboardModel[];
  details = new Map<string, SchooldashboardDetailModel[]>();
  clear() {
    this.vak = undefined;
    this.onderwijssoort = undefined;
    this.details.clear();
  }
}
