import {Pipe, PipeTransform} from '@angular/core';
import {SharedVariablesService} from '../../services/sharedVariables.service';
import {ModalwindowService} from '../../services/modalwindow.service';
import {createColumnDef, createDefaultHeaderCellDef, createModel, TableModel} from '../../table/table/table.model';
import {LeermiddelendashboardModel} from './leermiddelendashboard.model';
import {JustifiedNumberCellComponent} from '../../table/justified-number-cell/justified-number-cell.component';
import {ActivatiegetalCellComponent} from '../../table/activatiegetal-cell/activatiegetal-cell.component';
import {HelpHeaderCellComponent} from '../../table/help-header-cell/help-header-cell.component';
import {RangebarCellComponent} from '../../table/rangebar-cell/rangebar-cell.component';
import {RangebarCellModel} from '../../table/rangebar-cell/rangebar-cell.model';
import {RangelinechartCellComponent} from '../../table/rangelinechart-cell/rangelinechart-cell.component';
import {ClickableRowIconCellComponent} from '../../table/clickable-row-icon-cell/clickable-row-icon-cell.component';
import {GebruikOpDatum, MethodeGebruikVoorPeriode} from '../../../generated/graphql';
import {TitelondertitelCellComponent} from '../../table/titelondertitel-cell/titelondertitel-cell.component';
import {RangeLinechartModel} from '../../layout/range-linechart/range-linechart.model';

@Pipe({name: 'leermiddeldashboardTable'})
export class LeermiddelendashboardTablePipe implements PipeTransform {
  constructor(private service: SharedVariablesService, private modalwindowService: ModalwindowService) {
  }

  transform(data: MethodeGebruikVoorPeriode[], parent: LeermiddelendashboardModel): TableModel<MethodeGebruikVoorPeriode> {
    const getRowId = (m: MethodeGebruikVoorPeriode) =>  m.methodeNaam;
    const model = createModel(data, getRowId);
    model.rowsClickable = true;

    const key = createColumnDef<MethodeGebruikVoorPeriode>('key');
    key.header.getValue = () => 'Methode';
    key.body.style = {width: '15%'};
    key.body.component = TitelondertitelCellComponent;
    key.body.getValue = row => ({titel: row.methodeNaam, ondertitel: row.vakOfUitgeverNaam});

    const aantal = createColumnDef<MethodeGebruikVoorPeriode>('versies');
    aantal.header.getValue = () =>  'Versies';
    aantal.body.style = {width: '10%'};
    aantal.body.component = JustifiedNumberCellComponent;
    aantal.header.style = {textAlign: 'center'};

    const activatie = createColumnDef<MethodeGebruikVoorPeriode>('activatie', 'Activatie');
    activatie.body.component = ActivatiegetalCellComponent;
    activatie.body.getValue = rowModel => ({
      min: rowModel.activatiePercentage.min,
      max: rowModel.activatiePercentage.max,
      avg: rowModel.activatiePercentage.gemiddelde,
      tot: 1
    });
    activatie.body.style = {width: '10%'};

    const actief = createColumnDef<MethodeGebruikVoorPeriode>('actief');
    const actiefHulp = () => this.modalwindowService.toonOnderwerp('actief');
    actief.header = createDefaultHeaderCellDef('actief');
    actief.header.component = HelpHeaderCellComponent;
    actief.header.getValue = () => {
      return {title: 'Waarvan recent actief', help: actiefHulp};
    };
    actief.body = {
      component: RangebarCellComponent,
      getValue(rowModel: MethodeGebruikVoorPeriode): RangebarCellModel {
        return {
          model: {min: rowModel.recentActief.min, max: rowModel.recentActief.max, avg: rowModel.recentActief.gemiddelde, tot: 1},
          hidden: rowModel.activatiePercentage.max === 0,
          minGebruikPerWeek: rowModel.recentActief.min,
          maxGebruikPerWeek: rowModel.recentActief.min
        };
      },
      getRowspan(_: MethodeGebruikVoorPeriode): number {
        return 1;
      },
      style: {width: '30%'}
    };

    const gebruik = createColumnDef<MethodeGebruikVoorPeriode>('gebruik');
    const gebruiksHulp = () => this.modalwindowService.toonOnderwerp('gebruik');
    gebruik.header.component = HelpHeaderCellComponent;
    gebruik.header.getValue = () => ({title: 'Gebruik per licentie', help: gebruiksHulp});
    gebruik.body.component = RangelinechartCellComponent;
    gebruik.body.getValue = rowModel => ({
      model: rowModel.leerlingDagGebruik.map(fromDagGebruikToRangeLineChartModel),
      totaal: {
        min: rowModel.leerlingTotaalGebruik.min,
        avg: rowModel.leerlingTotaalGebruik.gemiddelde,
        max: rowModel.leerlingTotaalGebruik.max
      },
      hidden: rowModel.activatiePercentage.max === 0
    });
    gebruik.body.style = {width: '30%'};

    const icon = createColumnDef<MethodeGebruikVoorPeriode>('icon', '');
    icon.body.component = ClickableRowIconCellComponent;
    icon.body.getValue = () => undefined;
    icon.body.style = {width: '5%'};

    model.columnDefs = [key, aantal, activatie, actief, gebruik, icon];
    return model;

  }
}

function fromDagGebruikToRangeLineChartModel(daggebruik: GebruikOpDatum): RangeLinechartModel {
  return {
    date: new Date(daggebruik.date),
    values: {min: daggebruik.waardes.min, max: daggebruik.waardes.max, avg: daggebruik.waardes.gemiddelde}
  };
}
