<app-blauwe-balk iconUrl="../../assets/img/icons/Symbool/vakdashboard-wit.svg">
  {{store.getVakNaamURL() | async}} | {{store.getOnderwijssoortNaamURL() | async}}
</app-blauwe-balk>
<div class="table rows-clickable">
  <app-connect-loader [loading]="loading | async">
    <div class="tr">
      <div class="th">Lesgroep</div>
      <div class="th">Leerlingen</div>
      <div class="th">Docenten</div>
      <div class="th">Geactiveerde Licenties</div>
      <div class="th">Waarvan recent actief <i class="icon-vraagteken" (click)="openActiefHelp()"></i></div>
      <div class="th">Gebruik per licentie<i class="icon-vraagteken" (click)="openGebruikHelp()"></i></div>
      <div class="th"></div>
    </div>
    <div class="tr clickable" *ngFor="let row of (store.getRows() | async)" (click)="navigeerNaarKlassendashboard(row)">
      <div class="td"><span class="max-2-lines">{{getLesgroepNaam(row) | async}}</span></div>
      <div class="td extra-padding-left">{{row.aantalLeerlingen}}</div>
      <div class="td"><div class="no-wrap" [innerHTML]="getDocentNamen(row)"></div></div>
      <div class="td"><app-activatiegetal-lesgroepdashboard [totaal]="row.aantalGeactiveerd + row.aantalNietGeactiveerd" [geactiveerd]="row.aantalGeactiveerd"></app-activatiegetal-lesgroepdashboard></div>
      <div class="td"><app-progressbar [actief]="row.aantalRecentActief" [totaal]="row.aantalGeactiveerd"></app-progressbar></div>
      <div class="td"><app-range-linechart-keybased-new [viewModel]="getRowLinechartViewModel(row)"></app-range-linechart-keybased-new></div>
      <div class="td"><i class="icon-arrow"></i></div>
    </div>
    <div class="tr sticky-to-bottom">
      <div class="td">Totaal</div>
      <div class="td">{{(store.getTotaalRegel() | async)?.aantalLeerlingen}}</div>
      <div class="td"></div>
      <div class="td" *ngIf="store.getActivatieTotaal() | async"><app-activatiegetal [range]="store.getActivatieTotaal() | async"></app-activatiegetal></div>
      <div class="td" *ngIf="totaalrijRangeBarNewViewModel"><app-range-bar-new [range]="totaalrijRangeBarNewViewModel"></app-range-bar-new></div>
      <div class="td" *ngIf="store.getGebruikTotaal() | async"><app-range-linechart-keybased-new [viewModel]="totaalrijLinechartViewModel"></app-range-linechart-keybased-new></div>
      <div class="td"></div>
    </div>
  </app-connect-loader>
</div>
