import {Component, OnInit, ChangeDetectionStrategy, OnDestroy} from '@angular/core';
import {DashboardsettingsFacade} from '../../state/dashboardsettings/dashboardsettings.facade';
import {AnalyticsService, Metrics} from '../../services/analytics.service';
import {Subscription} from 'rxjs';
import {Datumbereik, Vestiging} from 'src/app/state/dashboardsettings/dashboardsettings.state';
import {SchooldashboardNewFacade} from '../../state/schooldashboard/schooldashboard.facade';
import {KlassendashboardNewFacade} from '../../state/klassendashboard/klassendashboard.facade';
import {VakdashboardNewFacade} from '../../state/vakdashboard/vakdashboard.facade';
import {Router} from '@angular/router';
import { routingConstants } from 'src/app/stateless/routing.constants';

@Component({
  selector: 'app-vestigingendropdown-new',
  templateUrl: './vestigingendropdown-new.component.html',
  styleUrls: ['./vestigingendropdown-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VestigingendropdownNewComponent implements OnInit, OnDestroy {
  public expanded = false;

  private subs: Subscription[] = [];
  private currentBereik: Datumbereik;

  constructor(public store: DashboardsettingsFacade,
              private schooldashboardStore: SchooldashboardNewFacade,
              private vakdashboardStore: VakdashboardNewFacade,
              private klassendashboardStore: KlassendashboardNewFacade,
              private analyticsService: AnalyticsService,
              private router: Router) {
  }

  public select(vestiging: Vestiging): void {
    if (!vestiging.heeftData) {
      return;
    }

    this.expanded = false;
    this.analyticsService.logEvent(Metrics.VESTIGINGCHANGE);

    this.router.navigate(['/' + routingConstants.SCHOOL_DASHBOARD_NEW], {
      queryParams: { vestiging: vestiging.uuid },
      queryParamsHandling: 'merge',
    });
    this.store.setSelectedVestiging(vestiging);
  }

  ngOnInit(): void {
    this.subs.push(this.store.getDatumbereik().subscribe((bereik) => {
      this.currentBereik = bereik;
    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
