import {createAction, props} from '@ngrx/store';
import { Feedback } from 'src/app/new-dashboard/backoffice-dashboard-new/backoffice-dashboard-new.component';
import { BackofficeEtlTaskEvent, BackofficeEtlTaskExecution, BackofficeEtlTaskStep, BackofficeOrganization, BackofficeSchoolYear } from '../../../generated/backoffice-client';
import { SyncConfig } from 'src/app/services/dashboard-backoffice.service';

export const addFeedback = createAction('[Dashboard Backoffice] Add Feedback', props<{ feedback: Feedback }>());

export const cleanUpFeedback = createAction('[Dashboard Backoffice] Clean up Feedback');

export const fetchSchoolYears = createAction('[Dashboard Backoffice] Fetch SchoolYears');

export const schoolYearsFetched = createAction('[Dashboard Backoffice] SchoolYears Fetched', props<{ schoolYears: BackofficeSchoolYear[] }>());

export const storeSchoolYear = createAction('[Dashboard Backoffice] Store SchoolYear', props<{ schoolYear: BackofficeSchoolYear }>());

export const schoolYearStored = createAction('[Dashboard Backoffice] SchoolYear Stored', props<{ schoolYear: BackofficeSchoolYear }>());

export const fetchOrganizations = createAction('[Dashboard Backoffice] Fetch Organizations');

export const organizationsFetched = createAction('[Dashboard Backoffice] Organizations Fetched', props<{ organizations: BackofficeOrganization[] }>());

export const saveOrganization = createAction('[Dashboard Backoffice] Save Organization', props<{ organization: BackofficeOrganization }>());

export const organizationSaved = createAction('[Dashboard Backoffice] Organization saved', props<{ organization: BackofficeOrganization }>());

export const startSync = createAction('[Dashboard Backoffice] Start sync', props<SyncConfig>());

export const fetchSyncs = createAction('[Dashboard Backoffice] Fetch Syncs', props<{ page: number }>());

export const syncsFetched = createAction('[Dashboard Backoffice] Syncs fetched', props<{ syncs: BackofficeEtlTaskExecution[], page: number, count: number }>());

export const setSyncsPage = createAction('[Dashboard Backoffice] Set Syncs Page', props<{ page: number }>());

export const setSyncsSortColumn = createAction('[Dashboard Backoffice] Set Syncs sort column', props<{ column: string }>());

export const taskSelected = createAction('[Dashboard Backoffice] Task Selected', props<{ task: BackofficeEtlTaskExecution }>());

export const fetchTaskSchoolYears = createAction('[Dashboard Backoffice] Fetch Task schoolYears', props<{ taskId: number }>());

export const taskSchoolYearsFetched = createAction('[Dashboard Backoffice] Task schoolYears Fetched', props<{ schoolYears: BackofficeSchoolYear[] }>());

export const fetchTaskOrganizations = createAction('[Dashboard Backoffice] Fetch Task organizations', props<{ taskId: number }>());

export const taskOrganizationsFetched = createAction('[Dashboard Backoffice] Task organizations Fetched', props<{ organizations: BackofficeOrganization[] }>());

export const fetchTaskFailedOrgs = createAction('[Dashboard Backoffice] Fetch Task failed organizations', props<{ taskId: number }>());

export const taskFailedOrgsFetched = createAction('[Dashboard Backoffice] Task failed organizations Fetched', props<{ organizations: BackofficeOrganization[] }>());

export const fetchTaskEvents = createAction('[Dashboard Backoffice] Fetch Task Events', props<{ task: number }>());

export const taskEventsFetched = createAction('[Dashboard Backoffice] Task events Fetched', props<{ events: BackofficeEtlTaskEvent[] }>());

export const fetchTaskSteps = createAction('[Dachboard Backoffice] Fetch Task Steps', props<{ task: number }>());

export const taskStepsFetched = createAction('[Dashboard Backoffice] Task steps Fetched', props<{ steps: BackofficeEtlTaskStep[] }>());

export const taskPollStatus = createAction('[Dashboard Backoffice] Poll selected task status');

export const stopSelectedTask = createAction('[Dashboard Backoffice] Stop selected task');

export const resumeSelectedTask = createAction('[Dashboard Backoffice] Resume selected task');
