import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {ExportoverzichtFacade} from '../../../state/exportoverzicht/exportoverzicht.facade';

@Component({
  selector: 'app-exportoverzicht-list',
  templateUrl: './exportoverzicht-list.component.html',
  styleUrls: ['./exportoverzicht-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportoverzichtListComponent implements OnInit {
  @Input()
  list: string[];

  slicedList: string[];

  numberOfColumns: number;

  moreKeysAmount: number;

  private get NUMBER_OF_KEYS_TO_SHOW(): number {
    return 19;
  }

  constructor(private facade: ExportoverzichtFacade) {
  }

  ngOnInit(): void {
    const sum = this.list.length - this.NUMBER_OF_KEYS_TO_SHOW;

    this.moreKeysAmount = sum <= 0 ? 0 : this.list.length;

    this.slicedList = this.list.slice(0, this.NUMBER_OF_KEYS_TO_SHOW);

    this.numberOfColumns = Math.ceil(this.slicedList.length / 5);
  }

  public openSidebarRequested() {
    this.facade.openSidebarRequested(this.list);
  }

}
