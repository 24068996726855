<div class="infoBlok">
  <div class="titel">
    <i class="koppeling"></i>
    <h1 class="bodyContentSemi">Toegang via {{viewModel.webserviceNaam | async }}</h1>
  </div>
  <div class="content">
    <div class="classification" [class]="viewModel.webserviceRisico | async">
      <i></i>
      <span class="labelLabelSemi label">
        {{viewModel.webserviceRisicoTekst | async}}
      </span>
    </div>
    <p class="bodySmallContent" [innerHTML]="viewModel.toegangTekst | async">
    </p>
  </div>
</div>

<div class="infoBlok">
  <div class="titel">
    <i class="groepen"></i>
    <h1 class="bodyContentSemi">Doelgroepen</h1>
  </div>
  <div class="content">
    <p class="bodySmallContent" [innerHTML]="viewModel.doelgroepenTekst | async">
    </p>
  </div>
</div>

<div class="infoBlok">
  <div class="titel">
    <i class="schild"></i>
    <h1 class="bodyContentSemi">Datatoegang</h1>
  </div>
  <div class="content">
    <p class="bodySmallContent" [innerHTML]="viewModel.datatoegangTekst | async">
    </p>
  </div>
</div>

<div class="veldentable">
  <ng-container *ngFor="let col of (viewModel.datatoegangVelden | async)">
    <div class="veldcolumn">
      <ng-container *ngFor="let veld of col;">
        <div class="veld">
          <p class="bodyContent veldnaam">{{veld.naam}}</p>
          <div *ngIf="veld.properties?.length > 0" class="remarkContainer">
            <ng-container *ngFor="let prop of veld.properties;">
              <div class="remark write">
                <div class="tooltip remarkTooltip">
                  <p class="bodySmallContent">{{prop.description}}</p>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
