import {Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef} from '@angular/core';
import {SchooldashboardNewFacade} from '../../state/schooldashboard/schooldashboard.facade';
import {SchooldashboardItemContent, SchooldashboardItemHeader} from '../../state/schooldashboard/schooldashboard.state';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {RangeModel} from '../../layout/model/range.model';
import {RangeLinechartModelKeyBased} from '../../layout/range-linechart/range-linechart.model';
import {ModalwindowService} from '../../services/modalwindow.service';
import {FilterService} from '../../services/filter.service';
import {map, take, takeWhile, withLatestFrom} from 'rxjs/operators';
import {Router} from '@angular/router';
import {DashboardsettingsFacade} from '../../state/dashboardsettings/dashboardsettings.facade';
import { RangeLinechartKeybasedViewModel } from 'src/app/layout/range-linechart-keybased-new/range-linechart-keybased-new.component';
import { of } from 'rxjs';
import {PERIODE} from '../../state/dashboardsettings/dashboardsettings.state';
import {routingConstants} from '../../stateless/routing.constants';
import { RangeBarNewModel } from 'src/app/layout/range-bar-new/range-bar-new.component';

@Component({
  selector: 'app-schooldashboard-table-row',
  templateUrl: './schooldashboard-table-row.component.html',
  styleUrls: ['./schooldashboard-table-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('openContent', [
      state('closed', style({height: '0px', minHeight: '0'})),
      state('open', style({height: '*'})),
      transition('open <=> closed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])]
})
export class SchooldashboardTableRowComponent implements OnInit {

  @Input()
  data: SchooldashboardItemHeader;

  public isContentOpen = false;
  public content: SchooldashboardItemContent[];

  private isGegroepeerdOpVak: boolean;

  constructor(private settings: DashboardsettingsFacade, private router: Router, public store: SchooldashboardNewFacade, private cdr: ChangeDetectorRef, private modalwindowService: ModalwindowService, private filterService: FilterService) { }

  ngOnInit(): void {
    this.settings.isGegroepeerdOpVak().pipe(take(1)).subscribe(isGegroepeerdOpVak => this.isGegroepeerdOpVak = isGegroepeerdOpVak);

    this.store.getOpenRows().pipe(take(1)).subscribe(rows => {
      if (rows?.includes(this.data.naam)) {
        this.toggle(true);
      }
    });
  }

  public toggle(init: boolean = false): void {
    if (this.content === undefined) {
        if (this.isGegroepeerdOpVak) {
          this.store.getVakContent(this.data.naam).pipe(
            takeWhile(_ => this.content === undefined)
          ).subscribe(content => {
            if (content != null) {
              this.content = content;
              this.isContentOpen = !this.isContentOpen;
              if (!init) {
                this.isContentOpen ?
                  this.filterService.addToFilterOption({
                    key: 'vak',
                    value: this.data.naam
                  }) :
                  this.filterService.deleteFromFilterOption({
                    key: 'vak',
                    value: this.data.naam
                  });
              }
              this.cdr.detectChanges();
            }
          });
        } else {
          this.store.getOnderwijssoortContent(this.data.naam).pipe(
            takeWhile(_ => this.content === undefined)
          ).subscribe(content => {
            if (content != null) {
              this.content = content;
              this.isContentOpen = !this.isContentOpen;
              if (!init) {
                this.isContentOpen ?
                  this.filterService.addToFilterOption({
                    key: 'ows',
                    value: this.data.naam
                  }) :
                  this.filterService.deleteFromFilterOption({
                    key: 'ows',
                    value: this.data.naam
                  });
              }
              this.cdr.detectChanges();
            }
          });
        }
    } else {
      this.isContentOpen = !this.isContentOpen;
      this.cdr.detectChanges();
      if (!init) {
        if (this.isGegroepeerdOpVak) {
          this.isContentOpen ? this.filterService.addToFilterOption({key: 'vak', value: this.data.naam}) : this.filterService.deleteFromFilterOption({key: 'vak', value: this.data.naam});
        } else {
          this.isContentOpen ? this.filterService.addToFilterOption({key: 'ows', value: this.data.naam}) : this.filterService.deleteFromFilterOption({key: 'ows', value: this.data.naam});
        }
      }
    }
  }

  public getRowLinechartViewModel(row: SchooldashboardItemContent): RangeLinechartKeybasedViewModel {
    return {
        onGetRange: of(row).pipe(map(this.getRangelineChart)),
        onGetGemiddeldGebruik: of(row).pipe(withLatestFrom(this.settings.getPeriodeOrDefault())).pipe(map(this.getGemiddeldGebruik))
    };
}

  public getActivatieGetal(row: SchooldashboardItemContent): RangeModel {
    return {min: row.laagsteActivatie, avg: row.activatiePercentage, max: row.hoogsteActivatie, tot: 100};
  }

  public getRangeBar(row: SchooldashboardItemContent): RangeBarNewModel {
    return {min: of(row.laagstePercentageActief), avg: of(row.gemiddeldPercentageActief), max: of(row.hoogstePercentageActief), tot: of(100)};
  }

  public getRangelineChart(row: SchooldashboardItemContent): RangeLinechartModelKeyBased[] {
    return row.leerlingGebruikBereik.map(lg => ({key: lg.key, values: {min: lg.minimaalLesgroepGebruik, avg: lg.gemiddeldLesgroepGebruik, max: lg.maximaalLesgroepGebruik, tot: 100}}));
  }

  public openActiefHelp(): void {
    this.modalwindowService.toonOnderwerp('actief');
  }

  public openGebruikHelp(): void {
    this.modalwindowService.toonOnderwerp('gebruik');
  }

  public openVakDashboard(row: SchooldashboardItemContent): void {
    const vak = this.isGegroepeerdOpVak ? this.data.naam : row.naam;
    const os = this.isGegroepeerdOpVak ? row.naam : this.data.naam;
    this.router.navigate([routingConstants.VAK_DASHBOARD_NEW, vak, os], {queryParamsHandling: 'preserve'});
  }

  public getGemiddeldGebruik([row, periode]: [SchooldashboardItemContent, PERIODE]): string {
    const footerStepByPeriode = periode === 'JAAR' ? 'maand' : 'dag';
    return row?.gemiddeldGebruik?.toFixed(1) + ' keer per ' + footerStepByPeriode;
  }
}
