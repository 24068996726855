import { Component, ChangeDetectionStrategy } from '@angular/core';
import {TableCellComponent} from '../table/table.model';
import {PercentageIndicatieCellModel} from './percentage-indicatie-cell.model';

@Component({
  selector: 'app-percentage-indicatie-cell',
  templateUrl: './percentage-indicatie-cell.component.html',
  styleUrls: ['./percentage-indicatie-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PercentageIndicatieCellComponent implements TableCellComponent<PercentageIndicatieCellModel> {
  data: PercentageIndicatieCellModel;

  get indicatie(): string {
    const {waarde, matig, laag} = this.data;
    if (waarde <= laag) {
      return 'laag';
    } else if (waarde <= matig) {
      return 'matig';
    } else {
      return 'hoog';
    }
  }
}
