import {Component, OnInit, ChangeDetectionStrategy, Input} from '@angular/core';
import {KlassendashboardNewFacade} from '../../state/klassendashboard/klassendashboard.facade';
import {Observable} from 'rxjs';
import {LeermiddelTabel} from '../../state/klassendashboard/klassendashboard.state';

@Component({
  selector: 'app-klassendashboard-samenvatting-table',
  templateUrl: './klassendashboard-samenvatting-table.component.html',
  styleUrls: ['./klassendashboard-samenvatting-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KlassendashboardSamenvattingTableComponent implements OnInit {

  @Input()
  tabeldata$: Observable<LeermiddelTabel[]>;

  @Input()
  lesgroepNaam: string;

  constructor(store: KlassendashboardNewFacade) { }

  ngOnInit(): void {
  }

}
