import {Component, ChangeDetectionStrategy, Input} from '@angular/core';
import {Type} from '../../../generated/graphql';

@Component({
  selector: 'app-type',
  templateUrl: './type.component.html',
  styleUrls: ['./type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TypeComponent {
  @Input()
  type: Type;
}
