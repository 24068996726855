<div class="multi-range-week-bar-chart-container">
    <google-chart class="chart" [data]="chart" (chartReady)="onDataReady$.next()"></google-chart>
    <div class="legenda-container">
        <div>
            <div class="legenda-box lees"></div>
            <p class="bodySmallContentSemi">Leesacties</p>
        </div>
        <div *ngIf="viewModel.onGetToonSchrijfacties | async">
            <div class="legenda-box schrijf"></div>
            <p class="bodySmallContentSemi">Schrijfacties</p>
        </div>
    </div>
</div>
