<div class="veld">
    <div *ngIf="(viewModel.onGetEndpoint | async)?.naam !='Vestiging'" class="iconContainer">
        <i [class]="optioneelCheckboxStyle(viewModel.onGetVeld | async)" (click)="onOptioneelClicked()"></i>
    </div>
    <div *ngIf="magVerplichtVinkjeTonen$ | async" class="iconContainer">
        <i [class]="verplichtCheckboxStyle(viewModel.onGetVeld | async)" (click)="onVerplichtClicked()"></i>
    </div>
    <div *ngIf="!(magVerplichtVinkjeTonen$ | async)" class="missingCheckBoxPadding"></div>
    <p class="bodyContent veldnaam">{{(viewModel.onGetVeld | async)?.naam}}</p>
    <div class="remarkContainer">
      <div [class]="propertyClass(viewModel.onGetVeld | async)" (click)="showTextField()"></div>
    </div>
    <app-input-textarea class="remarkInputField" *ngIf="textFieldOpened" [viewModel]="viewModelTextArea"></app-input-textarea>
</div>
