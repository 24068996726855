<app-connect-loader [loading]="loading | async">
  <div class="contentbox">
    <app-controls *ngIf="vestigingBevatData | async"
                  [studiewijzer]="false"
                  [grayed]="(bevatGeenData | async)"
                  [state]="groeperenOpUitgever | async"
                  trueLabel="Uitgever"
                  falseLabel="Vak"
                  (groeperingChange)="onGroeperingChanged($event)">
    </app-controls>
    <app-placeholder *ngIf="(bevatGeenData | async)" [studiewijzerdb]="false"></app-placeholder>
    <div [class]="row.open ? 'row open' : 'row'" *ngFor="let row of currentData | async">
      <div class="container" (click)="toggle(row)">
        <div class="key connect-brand">{{row.details.uitgeverOfVakNaam}}<i *ngIf="row.nietGeclassificeerd" (click)="toonNietGeclassificeerdHelp($event)" class="icon-vraagteken"></i></div>
        <div class="values">
          <div class="actief">{{row.details.percentageActieveLeerlingen | percent}} actieve licenties</div>
          <div class="frequentie">{{row.details.aantalActieveLeerlingenPerWeek | number:'1.1-1'}} keer p/w</div>
          <div class="icon connect-brand"><i [class]="row.open ? 'icon-arrow-close' : 'icon-arrow-open'"></i></div>
        </div>
      </div>
      <div class="details" [@detailExpand]="row.open ? 'expanded' : 'collapsed'">
        <app-table [model]="row.details.methodeDagGebruik | leermiddeldashboardTable:row"
                   (rowClick)="navigeerNaarMethode($event)"></app-table>
      </div>
    </div>
  </div>
</app-connect-loader>
