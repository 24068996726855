import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {finalize} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {AppState} from '../app/state/app.state';
import {decrease, increase} from '../app/state/request-count/request-count.actions';
import {Observable} from 'rxjs';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private store: Store<AppState>) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (['LeermiddelVakKoppelingInfo', 'LeermiddelVakKoppelingUpdate'].includes(request?.body?.operationName)) {
      return next.handle(request);
    }
    this.store.dispatch(increase());
    return next.handle(request).pipe(
      finalize(() => {
        this.store.dispatch(decrease());
      })
    );
  }
}
