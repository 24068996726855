import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AVGExtendedOnderwijssoort } from 'src/app/dto/avg-dashboard-model-classes';

export class SelectLeerlingenComponentViewModel {
    onGetAangebodenOnderwijssoorten: BehaviorSubject<OnderwijssoortRow[]> = new BehaviorSubject([]);
    onGetAangebodenLeerjaren: BehaviorSubject<LeerjaarRow[]> = new BehaviorSubject([]);
    onGetAangebodenVakken: BehaviorSubject<VakRow[]> = new BehaviorSubject([]);
    onGetSelectAlleOnderwijssoorten: Observable<boolean>;
    onGetSelectAlleLeerjaren: Observable<boolean>;
    onGetSelectAlleVakken: Observable<boolean>;
    onGetSubmitButtonActive: Observable<boolean>;
    onRedraw: Subject<void> = new Subject();

    doSubmitSelection: Subject<boolean> = new Subject();
    doSelectOnderwijssoort: Subject<OnderwijssoortRow> = new Subject();
    doSelectLeerjaar: Subject<LeerjaarRow> = new Subject();
    doSelectVak: Subject<VakRow> = new Subject();
    doSetSelectAlleOnderwijssoorten: BehaviorSubject<boolean> = new BehaviorSubject(false as boolean);
    doSetSelectAlleLeerjaren: BehaviorSubject<boolean> = new BehaviorSubject(false as boolean);
    doSetSelectAlleVakken: BehaviorSubject<boolean> = new BehaviorSubject(false as boolean);

    private onderwijssoorten: OnderwijssoortRow[] = [];
    private selectAlleOnderwijssoorten: boolean = false;
    private leerjaren: LeerjaarRow[] = [];
    private selectAlleLeerjaren: boolean = false;
    private internalUpdateAangebodenLeerjaren: BehaviorSubject<LeerjaarRow[]> = new BehaviorSubject([]);
    private updatingLeerjaren: boolean = false;
    private vakken: VakRow[] = [];
    private selectAlleVakken: boolean;

    private subscriptions = [];

    public constructor(private parent: SelectLeerlingenViewModel) {
        this.onGetSelectAlleOnderwijssoorten = this.doSetSelectAlleOnderwijssoorten;
        this.onGetSelectAlleLeerjaren = this.doSetSelectAlleLeerjaren;
        this.onGetSelectAlleVakken = this.doSetSelectAlleVakken;
    }

    public onInit(): void {
        this.subscriptions.push(this.parent.onGetAlleOnderwijssoorten.subscribe(onderwijssoorten => {
            const rows: OnderwijssoortRow[] = [];
            onderwijssoorten.forEach(os => {
                const naam = os.naam.toLowerCase();
                let existingRow = rows.find(r => r.naam === naam);
                if (existingRow === undefined) {
                    existingRow = { onderwijssoorten: [], naam: naam, selectable: true, selected: this.onderwijssoorten.find(p => p.naam === naam)?.selected || false };
                    rows.push(existingRow);
                }
                existingRow.onderwijssoorten.push(os);
            });

            this.onderwijssoorten = rows;
            this.onGetAangebodenOnderwijssoorten.next(rows);
        }));

        this.subscriptions.push(this.doSelectOnderwijssoort.subscribe(onderwijssoortRow => {
            this.onderwijssoorten.forEach(os => {
                if (os.naam === onderwijssoortRow.naam) os.selected = !os.selected;
            });

            this.selectAlleOnderwijssoorten = (this.onderwijssoorten.filter(os => os.selected).length === this.onderwijssoorten.filter(os => os.selectable).length);
            this.doSetSelectAlleOnderwijssoorten.next(this.selectAlleOnderwijssoorten);
            this.onGetAangebodenOnderwijssoorten.next(this.onderwijssoorten);
        }));

        this.subscriptions.push(this.doSetSelectAlleOnderwijssoorten.subscribe(selectAll => {
            if (this.selectAlleOnderwijssoorten !== selectAll) {
                this.selectAlleOnderwijssoorten = selectAll;
                this.onderwijssoorten = this.onderwijssoorten.map(os => {
                    os.selected = os.selectable && selectAll;
                    return os;
                });
                this.onGetAangebodenOnderwijssoorten.next(this.onderwijssoorten);
            }
        }));

        // Selectable van onderwijssoorten kan beïnvloed worden door geselecteerde leerjaren
        this.subscriptions.push(this.onGetAangebodenLeerjaren.subscribe(leerjaarRows => {
            const geselecteerdeLeerjaren = leerjaarRows.filter(row => row.selected).map(row => row.leerjaar);
            if (geselecteerdeLeerjaren.length === 0) {
                this.onderwijssoorten = this.onderwijssoorten.map(row => {
                    row.selectable = true;
                    return row;
                });
            }
            else {
                this.onderwijssoorten = this.onderwijssoorten.map(row => {
                    if (row.onderwijssoorten
                        .map(os => os.leerjaren)
                        .reduce((plj, clj) => plj.concat(clj), [])
                        .filter(lj => geselecteerdeLeerjaren.includes(lj)).length === 0) {
                        row.selectable = false;
                        row.selected = false;
                    }
                    else {
                      row.selectable = true;
                    }
                    return row;
                });
            }
            this.selectAlleOnderwijssoorten = (this.onderwijssoorten.filter(os => os.selected).length === this.onderwijssoorten.filter(os => os.selectable).length);
            this.doSetSelectAlleOnderwijssoorten.next(this.selectAlleOnderwijssoorten);
            this.onGetAangebodenOnderwijssoorten.next(this.onderwijssoorten);
        }));

        // Selectable van leerjaren kan beïnvloed worden door geselecteerde onderwijssoorten
        this.subscriptions.push(this.onGetAangebodenOnderwijssoorten.subscribe(onderwijssoorten => {
            if (this.updatingLeerjaren) return;
            this.leerjaren = this.populateLeerjaren(onderwijssoorten, this.leerjaren);
            this.selectAlleLeerjaren = (this.leerjaren.filter(lj => lj.selected).length === this.leerjaren.filter(lj => lj.selectable).length);
            this.doSetSelectAlleLeerjaren.next(this.selectAlleLeerjaren);
            this.internalUpdateAangebodenLeerjaren.next(this.leerjaren);
        }));

        // Voorkomt loop als je leerjaren aanpast en daardoor onderwijssoorten worden aangepast
        this.subscriptions.push(this.internalUpdateAangebodenLeerjaren.subscribe(leerjaren => {
            this.updatingLeerjaren = true;
            this.onGetAangebodenLeerjaren.next(leerjaren);
            this.updatingLeerjaren = false;
        }));

        this.subscriptions.push(this.doSelectLeerjaar.subscribe(row => {
            this.leerjaren.forEach(lj => {
                if (lj.leerjaar === row.leerjaar) lj.selected = !row.selected;
            });
            this.selectAlleLeerjaren = (this.leerjaren.filter(lj => lj.selected).length === this.leerjaren.filter(lj => lj.selectable).length);
            this.doSetSelectAlleLeerjaren.next(this.selectAlleLeerjaren);
            this.internalUpdateAangebodenLeerjaren.next(this.leerjaren);
        }));

        this.subscriptions.push(this.doSetSelectAlleLeerjaren.subscribe(selectAll => {
            if (this.selectAlleLeerjaren !== selectAll) {
                this.selectAlleLeerjaren = selectAll;
                this.leerjaren.forEach(lj => lj.selected = lj.selectable && selectAll);
                this.internalUpdateAangebodenLeerjaren.next(this.leerjaren);
            }
        }));

        // vakken zijn afhankelijk van onderwijssoorten en leerjaren
        this.subscriptions.push(combineLatest([this.onGetAangebodenOnderwijssoorten, this.onGetAangebodenLeerjaren])
            .subscribe(([aangebodenOnderwijssoorten, aangebodenLeerjaren]) => {
                this.vakken = this.populateVakken(aangebodenOnderwijssoorten, aangebodenLeerjaren, this.vakken);
                this.selectAlleVakken = ((this.vakken.filter(v => v.selected).length === this.vakken.length) && this.vakken.length > 0);
                this.doSetSelectAlleVakken.next(this.selectAlleVakken);
                this.onGetAangebodenVakken.next(this.vakken);
            }));

        this.subscriptions.push(this.doSelectVak.subscribe(row => {
            this.vakken.forEach(vak => {
                if (vak.naam === row.naam) vak.selected = !row.selected;
            });
            this.selectAlleVakken = ((this.vakken.filter(v => v.selected).length === this.vakken.length) && this.vakken.length > 0);
            this.doSetSelectAlleVakken.next(this.selectAlleVakken);
            this.onGetAangebodenVakken.next(this.vakken);
        }));

        this.subscriptions.push(this.doSetSelectAlleVakken.subscribe(selectAll => {
            if (this.selectAlleVakken !== selectAll) {
                this.selectAlleVakken = selectAll;
                this.vakken.forEach(vak => vak.selected = selectAll);
                this.onGetAangebodenVakken.next(this.vakken);
            }
        }));

        // Algemene huishoudelijke zaken

        this.onGetSubmitButtonActive = combineLatest([this.onGetAangebodenOnderwijssoorten, this.onGetAangebodenLeerjaren, this.onGetAangebodenVakken, this.onGetSelectAlleVakken])
            .pipe(map(([onderwijssoorten, leerjaren, vakken, selectAlleVakken]) => {
                return !!(onderwijssoorten.filter(row => row.selected).length
                  || leerjaren.filter(row => row.selected).length
                  || (vakken.filter(row => row.selected).length || selectAlleVakken));
            }));

        this.subscriptions.push(this.doSubmitSelection.subscribe(_ => {
            const filters = ({
                validSelection: true,
                alleLeerlingenGeselecteerd: false,
                onderwijssoortNamen: this.onderwijssoorten.filter(os => os.selected).map(os => os.naam),
                geselecteerdeOnderwijssoorten: this.onderwijssoorten
                    .filter(os => os.selected)
                    .map(os => os.onderwijssoorten)
                    .reduce((pos, cos) => pos.concat(cos), [])
                    .map(os => os.afkorting)
                    .reduce((pos, cos) => {
                        if (!pos.includes(cos)) pos.push(cos);
                        return pos;
                    }, []),
                alleOnderwijssoortenGeselecteerd: this.selectAlleOnderwijssoorten,
                leerjaren: this.leerjaren.filter(lj => lj.selected).map(lj => lj.leerjaar),
                geselecteerdeLeerjaren: this.leerjaren.map(lj => lj.leerjaar),
                alleLeerjarenGeselecteerd: this.selectAlleLeerjaren,
                vakNamen: this.vakken.filter(vak => vak.selected).map(vak => vak.naam),
                geselecteerdeVakken: this.vakken
                    .filter(vak => vak.selected)
                    .map(vak => vak.UUIDs)
                    .reduce((pv, cv) => pv.concat(cv), []),
                alleVakkenGeselecteerd: this.selectAlleVakken
            });

            if (this.leerjaren.filter(lj => lj.selected).length === 0) filters.alleLeerjarenGeselecteerd = true;
            if (this.onderwijssoorten.filter(os => os.selected).length === 0) filters.alleOnderwijssoortenGeselecteerd = true;
            if (this.vakken.filter(v => v.selected).length === 0) filters.alleVakkenGeselecteerd = true;

            this.parent.doSubmitSelection.next(filters);
            this.parent.doSetIsOpen.next(false);
        }));

        this.subscriptions.push(this.parent.onGetSelection.subscribe(selection => {
            this.onderwijssoorten.forEach(os => os.selected = false);
            this.leerjaren.forEach(lj => lj.selected = false);
            this.vakken.forEach(vak => vak.selected = false);

            selection.onderwijssoortNamen.forEach(naam => {this.onderwijssoorten.forEach(os => {if (os.naam === naam) os.selected = true; }); });
            this.selectAlleOnderwijssoorten = (this.onderwijssoorten.filter(os => os.selected).length === this.onderwijssoorten.filter(os => os.selectable).length);
            this.doSetSelectAlleOnderwijssoorten.next(this.selectAlleOnderwijssoorten);
            this.onGetAangebodenOnderwijssoorten.next(this.onderwijssoorten);

            selection.leerjaren.forEach(leerjaar => {this.leerjaren.forEach(lj => {if (lj.leerjaar === leerjaar) lj.selected = true; }); });
            this.selectAlleLeerjaren = (this.leerjaren.filter(lj => lj.selected).length === this.leerjaren.filter(lj => lj.selectable).length);
            this.doSetSelectAlleLeerjaren.next(this.selectAlleLeerjaren);
            this.internalUpdateAangebodenLeerjaren.next(this.leerjaren);

            selection.vakNamen.forEach(naam => {this.vakken.forEach(vak => {if (vak.naam === naam) vak.selected = true; }); });
            this.selectAlleVakken = ((this.vakken.filter(v => v.selected).length === this.vakken.length) && this.vakken.length > 0);
            this.doSetSelectAlleVakken.next(this.selectAlleVakken);
            this.onGetAangebodenVakken.next(this.vakken);
        }));
    }

    onDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    private populateLeerjaren(onderwijssoorten: OnderwijssoortRow[], leerjaren: LeerjaarRow[]): LeerjaarRow[] {
        if (leerjaren === null || leerjaren === undefined || leerjaren.length === 0) {
          leerjaren = [
            {leerjaar: 1, selectable: false, selected: false},
            {leerjaar: 2, selectable: false, selected: false},
            {leerjaar: 3, selectable: false, selected: false},
            {leerjaar: 4, selectable: false, selected: false},
            {leerjaar: 5, selectable: false, selected: false},
            {leerjaar: 6, selectable: false, selected: false},
          ];
        }
        leerjaren.forEach(lj => {
            lj.selectable = false;
        });
        if (onderwijssoorten.filter(row => row.selected).length === 0) {
          leerjaren.forEach(lj => lj.selectable = true);
        }
        onderwijssoorten.filter(row => row.selected).map(row => row.onderwijssoorten.map(i => i.leerjaren))
            .reduce((plj, clj) => plj.concat(clj), [])
            .reduce((plj, clj) => plj.concat(clj), [])
            .forEach(lj => {
                leerjaren.find(elj => elj.leerjaar === lj).selectable = true;
            });
        leerjaren.forEach(lj => {
            if (!lj.selectable) lj.selected = false;
        });
        return leerjaren;
    }

    private populateVakken(onderwijssoorten: OnderwijssoortRow[], leerjaren: LeerjaarRow[], vakken: VakRow[]): VakRow[] {
        if (vakken === null || vakken === undefined) {
          vakken = [];
        }
        const newVakken: VakRow[] = [];
        const alleLeerjarenImplied = leerjaren.filter(row => row.selected).length === 0;

        let osList = onderwijssoorten.filter(row => row.selected);
        if (osList.length === 0) osList = onderwijssoorten;

        osList
            .map(row => row.onderwijssoorten)
            .reduce((poss, coss) => poss.concat(coss), [])
            .map(os => os.aangebodenVakken)
            .reduce((pvakken, cvakken) => pvakken.concat(cvakken), [])
            .filter(vak => (leerjaren.find(lj => (lj.selected || alleLeerjarenImplied) && vak.leerjaren.includes(lj.leerjaar)) !== undefined))
            .forEach(vak => {
                const naam = vak.naam.toLowerCase();
                let row: VakRow = newVakken.find(v => v.naam === naam);
                if (row === undefined) {
                    const existingRow: VakRow = vakken.find(v => v.naam === naam);
                    row = { UUIDs: [], naam: naam, selected: existingRow?.selected || false, selectable: true };
                    newVakken.push(row);
                }
                vak.UUIDs.forEach(uuid => {
                    if (!row.UUIDs.includes(uuid)) row.UUIDs.push(uuid);
                });
            });
        return newVakken.sort((vak1, vak2) => {
            if (vak1.naam > vak2.naam) return 1;
            if (vak2.naam > vak1.naam) return -1;
            return 0;
        });
    }
}

interface OnderwijssoortRow {
    onderwijssoorten: AVGExtendedOnderwijssoort[];
    naam: string;
    selected: boolean;
    selectable: boolean;
}

interface LeerjaarRow {
    leerjaar: number;
    selectable: boolean;
    selected: boolean;
}

interface VakRow {
    naam: string;
    UUIDs: string[];
    selected: boolean;
    selectable: boolean;
}

export interface SelectLeerlingenViewModel {
    onGetAlleOnderwijssoorten: Observable<AVGExtendedOnderwijssoort[]>;
    onGetSelection: Observable<LeerlingFilters>;

    doSetIsOpen: BehaviorSubject<boolean>;
    doSubmitSelection: Subject<LeerlingFilters>;

    contentIsLoaded: BehaviorSubject<boolean>;
}

export interface LeerlingFilters {
    validSelection: boolean;
    alleLeerlingenGeselecteerd: boolean;
    onderwijssoortNamen: string[];
    geselecteerdeOnderwijssoorten: string[];
    alleOnderwijssoortenGeselecteerd: boolean;
    leerjaren: number[];
    geselecteerdeLeerjaren: number[];
    alleLeerjarenGeselecteerd: boolean;
    vakNamen: string[];
    geselecteerdeVakken: string[];
    alleVakkenGeselecteerd: boolean;
}
