<div class="container" (clickOutside)="close()">
  <div class="row-radio">
    <app-input-radiobutton [viewModel]="weekRadioButtonViewModel"></app-input-radiobutton>
    <app-input-radiobutton [viewModel]="maandRadioButtonViewModel"></app-input-radiobutton>
    <app-input-radiobutton [viewModel]="jaarRadioButtonViewModel"></app-input-radiobutton>
  </div>

  <div class="row-selector">
    <app-input-combobox
      [ngClass]="{'row-selector__grow': (active$ | async) === 'WEEK'}"
      *ngIf="(active$ | async) !== 'JAAR'"
      [viewModel]="leftComboViewModel"
    ></app-input-combobox>
    <app-input-combobox
      [ngClass]="{'row-selector__grow': (active$ | async) === 'JAAR'}"
      [viewModel]="rightComboViewModel"
    ></app-input-combobox>
  </div>
  <button buttonstyle="raised" color="blue" class="button" (click)="confirm()">Bevestig</button>
</div>
