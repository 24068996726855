import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Component, Inject} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {AVGApiService} from "../../generated/avg-client";
import {AvgBackendService} from "../services/avg-backend.service";
import {take} from "rxjs/operators";
import {Router} from "@angular/router";

export interface FeedbackData {
  score: 'blij' | 'tevreden' | 'neutraal' | 'ontevreden' | 'boos';
  message: string;
}

@Component({
  selector: 'app-user-feedback-dialog',
  templateUrl: 'user-feedback-dialog.component.html',
  styleUrls: ['./user-feedback-dialog.component.scss']
})
export class UserFeedbackDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<UserFeedbackDialogComponent>,
    private toastr: ToastrService,
    private api: AVGApiService,
    private avgBackend: AvgBackendService,
    @Inject(MAT_DIALOG_DATA) public model: FeedbackData,
    private router: Router
) {}

  cancel(): void {
    this.dialogRef.close();
  }

  submit(): void {
    if(this.model.score === null || this.model.score === undefined) {
      this.toastr.error("Kies één van de smileys.");
    }
    else {
      this.avgBackend.sendUserFeedback(this.model.message, this.model.score,
        this.router.url, 'Breedte: ' + window.screen.width + ', Hoogte: ' + window.screen.height, window.navigator.userAgent).pipe(
        take(1)).subscribe();

      this.toastr.success("Bedankt voor je feedback!")
      this.dialogRef.close();
    }
  }
}
