import {Component, OnInit, ChangeDetectionStrategy, Input} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-vak-onderwijs-switch-new',
  templateUrl: './vak-onderwijs-switch-new.component.html',
  styleUrls: ['./vak-onderwijs-switch-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VakOnderwijsSwitchNewComponent implements OnInit {

  @Input()
  viewmodel: VakOnderwijsSwitchViewmodel;

  @Input()
  navigeerbaar: boolean;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  public navigaarNaarVorige(): void {
    this.router.navigate(this.viewmodel.prevPathElements, {queryParamsHandling: 'preserve'});
  }

  public navigaarNaarVolgende(): void {
    this.router.navigate(this.viewmodel.nextPathElements, {queryParamsHandling: 'preserve'});
  }
}

export interface VakOnderwijsSwitchViewmodel {
  hoofdItem: string;
  subItem: string;
  prevPathElements: string[];
  nextPathElements: string[];
}
