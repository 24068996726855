import { Component, OnInit } from '@angular/core';
import {TableCellComponent} from '../table/table.model';

@Component({
  selector: 'app-clickable-row-icon-cell',
  templateUrl: './clickable-row-icon-cell.component.html',
  styleUrls: ['./clickable-row-icon-cell.component.scss']
})
export class ClickableRowIconCellComponent implements TableCellComponent<void> {
  data: any;
}
