<app-connect-loader [loading]="loading | async">
  <div class="contentbox">
    <app-controls *ngIf="vestigingBevatData | async"
                  [studiewijzer]="true"
                  [grayed]="!(datumBevatData | async)"
                  [state]="groeperenOpPlatform | async"
                  trueLabel="Platform"
                  falseLabel="Vak"
                  (groeperingChange)="onGroeperingChanged($event)">
    </app-controls>
    <app-placeholder *ngIf="!(datumBevatData | async)" [studiewijzerdb]="true"></app-placeholder>
    <app-blauwe-balk *ngIf="vestigingBevatData | async">Studiewijzerlinkjes</app-blauwe-balk>
    <div class="container" appFillScreen *ngIf="vestigingBevatData | async">
      <div class="overzicht">
        <app-studiewijzer-donut [viewModel]="viewModel"></app-studiewijzer-donut>
        <app-studiewijzerlinkjes-overzicht [viewModel]="viewModel" *ngIf="datumBevatData | async">
        </app-studiewijzerlinkjes-overzicht>
      </div>
      <app-studiewijzerlinkjes-detail *ngIf="toonDetails$ | async" [viewModel]="viewModel">
      </app-studiewijzerlinkjes-detail>
    </div>
  </div>
</app-connect-loader>
