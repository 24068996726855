import {Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

@Component({
    selector: 'app-select-leerjaren',
    templateUrl: './select-leerjaren.component.html',
    styleUrls: ['./select-leerjaren.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectLeerjarenComponent implements OnInit, OnDestroy {

    @Input()
    public viewModel: SelectLeerjarenViewModel;

    public isAllSelected = false;

    private subscriptions = [];

    constructor() { }

    ngOnInit(): void {
        this.subscriptions.push(this.viewModel.onGetSelectAlleLeerjaren.subscribe(isAllSelected => this.isAllSelected = isAllSelected));
        this.viewModel.contentIsLoaded.next(true);
    }

    ngOnDestroy(): void {
      this.viewModel.contentIsLoaded.next(false);
      this.subscriptions.forEach(s => s.unsubscribe());
    }

    public select(leerjaar: LeerjaarRow): void {
        if (leerjaar.selectable) {
          this.viewModel.doSelectLeerjaar.next(leerjaar);
        }
    }

    public setSelectAlleLeerjaren(selectAll: boolean): void {
        this.viewModel.doSetSelectAlleLeerjaren.next(selectAll);
    }

    public leerjaarButtonStyle(leerjaar: LeerjaarRow): string {
        return leerjaar?.selectable ? leerjaar.selected ? 'selected' : 'selectable' : 'unselectable';
    }
}

export interface SelectLeerjarenViewModel {
    onGetLeerjaren: Observable<LeerjaarRow[]>;
    onGetSelectAlleLeerjaren: Observable<boolean>;

    doSelectLeerjaar: Subject<LeerjaarRow>;
    doSetSelectAlleLeerjaren: Subject<boolean>;
    contentIsLoaded: BehaviorSubject<boolean>;
}

export interface LeerjaarRow {
    leerjaar: number;
    selectable: boolean;
    selected: boolean;
}
