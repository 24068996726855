import {Component, ChangeDetectionStrategy, EventEmitter, Output, Input} from '@angular/core';

@Component({
  selector: 'app-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ControlsComponent {
  @Output()
  groeperingChange = new EventEmitter<boolean>();

  @Input()
  trueLabel: string;

  @Input()
  falseLabel: string;

  @Input()
  state: boolean;

  @Input()
  grayed: boolean;

  @Input()
  studiewijzer: boolean;

  onGroeperingChanged(changed: boolean) {
    this.groeperingChange.emit(changed);
  }
}
