import {createSelector, createSelectorFactory, defaultMemoize, MemoizedSelector} from '@ngrx/store';
import {AppState, selectExportoverzicht} from '../app.state';
import {AVGExportDetails} from '../../../generated/avg-client';

const selectorFactory = createSelectorFactory(defaultMemoize);

export const selectExports = createSelector(selectExportoverzicht, (state) => state.exports?.map(exp => ({ ...exp, medewerker: state.medewerkerNamen.find(naam => naam.uuid === exp.medewerker)?.naam })));
export const selectAantalExports = createSelector(selectExportoverzicht, (state) => state.aantalExports);
export const selectExportDetails = createSelector(selectExportoverzicht, state => state.exportDetails);
export const selectExportDetailsById = (id: number): MemoizedSelector<AppState, AVGExportDetails> => {
  return selectorFactory(selectExportDetails, (exportDetails) => {
    return exportDetails[id];
  });
};
export const selectLoadingState = createSelector(selectExportoverzicht, (state) => state.isLoading);
export const selectFilter = createSelector(selectExportoverzicht, (state) => state.filter);
export const selectExportsPage = createSelector(selectExportoverzicht, (state) => state.page);
export const selectFilterBegindatum = createSelector(selectFilter, (state) => state.begindatum);
export const selectFilterEinddatum = createSelector(selectFilter, (state) => state.einddatum);
export const selectDatumBereiken = createSelector(selectFilter, (state) => state.datumBereiken);
export const selectDatumBereik = createSelector(selectFilter, (state) => state.datumBereik);
export const selectSidebar = createSelector(selectExportoverzicht, state => state.sidebar);
export const selectSidebarOpenState = createSelector(selectSidebar, (state) => state.open);
export const selectSidebarList = createSelector(selectSidebar, (state) => state.list);

export const selectMedewerkerNamen = createSelector(selectExportoverzicht, (state) => state.medewerkerNamen);
