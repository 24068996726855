import {Component} from '@angular/core';
import {TableCellComponent} from '../table/table.model';
import {GebruikperweekModel} from './gebruikperweek.model';

@Component({
  selector: 'app-gebruikperweek',
  templateUrl: './gebruikperweek.component.html',
  styleUrls: ['./gebruikperweek.component.scss']
})
export class GebruikperweekComponent implements TableCellComponent<GebruikperweekModel> {
  data: GebruikperweekModel;
}
