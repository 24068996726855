import {ChangeDetectionStrategy, Component} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../state/app.state';
import {selectInstellingsNaam} from '../../state/me/me.selectors';

@Component({
  selector: 'app-instelling',
  templateUrl: './instelling.component.html',
  styleUrls: ['./instelling.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstellingComponent {
  instelling = this.store.select(selectInstellingsNaam);

  constructor(private store: Store<AppState>) {}

}
