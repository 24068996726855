import {
  LesgroepOverstijgendDagGebruik,
  OpleidingVakLesgroepDagGebruik,
  VakloosLeermiddelOpleidingDagGebruik
} from '../../../generated/graphql';
import {Periode, periodeToAantalDagen, vandaag} from '../../services/datumbereik';
import {
  createLandelijkVakDetails, createOnderwijssoortDetails,
  createVakDetails,
  createVakloosDetails,
  SchooldashboardDetailModel
} from './schooldashboard.detail.model';
import {onderwijssoortComparator} from '../../services/onderwijssoort.comparator';
import {RuweSchooldashboardData} from '../state/schooldashboard/schooldashboard.state';

export const VAKOVERSTIJGEND = 'Vakoverstijgend';
export const ONGECLASSIFICEERDE_METHODE = 'Niet geclassificeerde methodes';

export interface SchooldashboardModel {
  key: string;
  actief: number;
  frequentie: number;
  open: boolean;
  details: SchooldashboardDetailModel[];
}

interface AccumulatorValue {
  key: string;
  actief: number;
  leerlingen: number;
  gebruik: number;
  groeperingen: string[];
}

export function createVakModel(
    vakData: OpleidingVakLesgroepDagGebruik[],
    vakloosData: VakloosLeermiddelOpleidingDagGebruik[],
    ongeclassificeerdeMethodeDagGebruik: LesgroepOverstijgendDagGebruik[],
    periode: Periode,
    isOpen: (key: string) => boolean,
    details: Map<string, SchooldashboardDetailModel[]>,
    datum?: Date,
  ): SchooldashboardModel[] {
  const aantalDagen = periodeToAantalDagen(periode, datum);
  const modelFn = toModel(aantalDagen, isOpen);
  const vakMap = vakData.reduce((a, v) => reduceData(v.vak, periode, a, v),
    new Map<string, AccumulatorValue>());
  const vakModel = Array.from(vakMap.values()).reduce(modelFn, [] as SchooldashboardModel[])
    .sort(onderwijssoortComparator(m => m.key));
  for (const key of vakMap.keys()) {
    details.set(key, createVakDetails(key, vakData, periode, vandaag()));
  }

  const vakloosMap = vakloosData.reduce(
    (a, v) => reduceVakloosData(periode, a, v),
    new Map<string, AccumulatorValue>());
  const vakloosModel = Array.from(vakloosMap.values()).reduce(modelFn, [] as SchooldashboardModel[]);
  details.set(VAKOVERSTIJGEND, createVakloosDetails(vakloosData, periode, vandaag()));

  const ongeclassificeerdeMethodeMap = ongeclassificeerdeMethodeDagGebruik.reduce(
      (a, v) => reduceOngeclassificeerdeMethodeData(periode, a, v, ONGECLASSIFICEERDE_METHODE),
      new Map<string, AccumulatorValue>());
  const ongeclassificeerdeMethodeModel = Array.from(ongeclassificeerdeMethodeMap.values())
    .reduce(modelFn, [] as SchooldashboardModel[]);
  details.set(ONGECLASSIFICEERDE_METHODE, createLandelijkVakDetails(ongeclassificeerdeMethodeDagGebruik, periode, vandaag()));

  return [...vakModel, ...vakloosModel, ...ongeclassificeerdeMethodeModel];
}

export function createOnderwijssoortModel(
  data: OpleidingVakLesgroepDagGebruik[],
  vakloosData: VakloosLeermiddelOpleidingDagGebruik[],
  ongeclassificeerdeMethodeDagGebruik: LesgroepOverstijgendDagGebruik[],
  periode: Periode,
  isOpen: (key: string) => boolean,
  details: Map<string, SchooldashboardDetailModel[]>,
  datum?: Date): SchooldashboardModel[] {
  const aantalDagen = periodeToAantalDagen(periode, datum);
  const vakMap = data.reduce((a, v) => reduceData(v.onderwijssoort, periode, a, v),
    new Map<string, AccumulatorValue>());
  for (const key of vakMap.keys()) {
    details.set(key, createOnderwijssoortDetails(key, data, ongeclassificeerdeMethodeDagGebruik, periode, vandaag()));
  }

  return [...vakMap.values()].reduce(toModel(aantalDagen, isOpen), [] as SchooldashboardModel[]).sort(onderwijssoortComparator(m => m.key));
}

function reduceData(
    key: string, periode: Periode, accumulator: Map<string, AccumulatorValue>, currentValue: OpleidingVakLesgroepDagGebruik
  ): Map<string, AccumulatorValue> {
  const {actieveLeerlingen, leerlingenInLesgroep, daggebruik, lesgroepUuid} = currentValue;
  if (accumulator.has(key)) {
    const value = accumulator.get(key);
    if (!value.groeperingen.includes(lesgroepUuid)) {
      value.actief += actieveLeerlingen;
      value.leerlingen += leerlingenInLesgroep;
      value.groeperingen.push(lesgroepUuid);
    }
    value.gebruik += daggebruik;
  } else {
    const value = {
      actief: actieveLeerlingen, leerlingen: leerlingenInLesgroep, gebruik: daggebruik, groeperingen: [lesgroepUuid], key, periode
    };
    accumulator.set(key, value);
  }
  return accumulator;
}

function reduceVakloosData(
    periode: Periode, accumulator: Map<string, AccumulatorValue>, currentValue: VakloosLeermiddelOpleidingDagGebruik
  ): Map<string, AccumulatorValue> {
  const key = VAKOVERSTIJGEND;
  const {actieveLeerlingen, leerlingen, daggebruik, titel, onderwijssoort, leerjaar} = currentValue;
  const groepering = `${titel}${onderwijssoort}${leerjaar}`;
  return reduceData(key, periode, accumulator,
    {actieveLeerlingen, leerlingenInLesgroep: leerlingen, daggebruik, lesgroepUuid: groepering});
}

function reduceOngeclassificeerdeMethodeData(
    periode: Periode, accumulator: Map<string, AccumulatorValue>, currentValue: LesgroepOverstijgendDagGebruik, key: string
): Map<string, AccumulatorValue> {
  const {aantalLeerlingen, displayName, gebruikVandaag, aantalActieveGebruikers} = currentValue;
  const groepering = `${displayName}`;
  return reduceData(key, periode, accumulator, {
    actieveLeerlingen: aantalActieveGebruikers,
    leerlingenInLesgroep: aantalLeerlingen,
    daggebruik: gebruikVandaag,
    lesgroepUuid: groepering
  });
}

function toModel(aantalDagen: number, isOpen: (key: string) => boolean) {
  return (accumulator: SchooldashboardModel[], value: AccumulatorValue) => {
    const {actief, leerlingen, gebruik, key = 'Overig'} = value;
    accumulator.push({
      key,
      actief: actief / leerlingen,
      frequentie: actief > 0 ? 7 * gebruik / (actief * aantalDagen) : 0,
      open: isOpen(key),
      details: []
    });
    return accumulator;
  };
}
