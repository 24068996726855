import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {
  KoppelingAanmakenStap3ComponentViewModel,
  KoppelingAanmakenStap3ComponentViewModelParent
} from './koppeling-aanmaken-stap3-component-view-model';
import {SelectDateViewModel} from '../select-date/select-date.component';
import {map, withLatestFrom} from 'rxjs/operators';
import {getEindSchooljaar} from '../../services/datumbereik';

@Component({
    selector: 'app-koppeling-aanmaken-stap3',
    templateUrl: './koppeling-aanmaken-stap3.component.html',
    styleUrls: ['./koppeling-aanmaken-stap3.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class KoppelingAanmakenStap3Component implements OnInit, OnDestroy {

    public toonUitleg = false;
    public checked = false;
    public leerlingSelectieText = 'Alle leerlingen';

    @Input()
    public viewModel: KoppelingAanmakenStap3ComponentViewModelParent;

    public ingangsdatumPickerViewModel: SelectDateViewModel;

    public einddatumPickerViewModel: SelectDateViewModel;

    public basisvelden: Observable<string>;

    public vestigingen: Observable<string>;

    public writevelden: Observable<string>;

    public extraVelden: Observable<string>;

    public koppelpartijNaam: Observable<string>;

    constructor() { }

    public componentViewModel: KoppelingAanmakenStap3ComponentViewModel;

    private subscriptions: Subscription[] = [];

    ngOnInit(): void {
        this.componentViewModel = new KoppelingAanmakenStap3ComponentViewModel(this.viewModel);
        this.basisvelden = this.componentViewModel.onGetAantalBasisvelden.pipe(map(n => n === 1 ? '1 basisveld' : n + ' basisvelden'));
        this.vestigingen = this.componentViewModel.onGetAantalVestigingen.pipe(
          map(n => {
            switch (n) {
              case 0: return 'Alle vestigingen';
              case 1: return '1 vestiging';
              default: return n + ' vestigingen';
            }
          })
        );
        this.writevelden = this.componentViewModel.onGetAantalWritePermissions.pipe(map(n => n ? (n === 1 ? '1 domein terugschrijven' : n + ' domeinen terugschrijven') : null));
        this.extraVelden = this.componentViewModel.onGetAantalExtraVelden.pipe(map(n => n === 1 ? '1 extra veld' : n + ' extra velden'));
        this.koppelpartijNaam = this.componentViewModel.onGetKoppelpartijNaam;
        this.subscriptions.push(this.componentViewModel.onGetIsSelectAlleLeerlingen.pipe(
          withLatestFrom(this.componentViewModel.isOpLeerlingNiveauGeautoriseerd)
        ).subscribe(([alleLeerlingen, isOpLeerlingNiveauGeautoriseerd]) => {
            this.leerlingSelectieText = isOpLeerlingNiveauGeautoriseerd ? 'Leerling geeft zelf toestemming' : (alleLeerlingen ? 'Alle leerlingen' : 'Een deel van de leerlingen');
        }));
        this.subscriptions.push(this.componentViewModel.onGetIsAkkoord.subscribe(akkoord => {
            this.checked = akkoord;
        }));
        this.ingangsdatumPickerViewModel = {
            onGetSelectedDate: this.componentViewModel.onGetIngangsdatum,
            onGetFeedback: this.componentViewModel.onGetIngangsdatumFeedback,
            doSetSelectedDate: this.componentViewModel.doSetIngangsdatum,
            isDisabled: this.componentViewModel.onGetIngangsdatum.pipe(map(d => (d === null)))
        };
        this.einddatumPickerViewModel = {
            onGetSelectedDate: this.componentViewModel.onGetEinddatum,
            onGetFeedback: this.componentViewModel.onGetEinddatumFeedback,
            onGetHasClearIcon: new BehaviorSubject(true),
            onGetNoSelectionPlaceholder: new BehaviorSubject('Geen einddatum'),
            onGetStartingView: new BehaviorSubject(getEindSchooljaar()),
            doSetSelectedDate: this.componentViewModel.doSetEinddatum
        };
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    public checkboxClass(): string {
        return this.checked ? 'checked' : 'unchecked';
    }

    public toggleCheckbox(): void {
        this.componentViewModel.doSetIsAkkoord.next(!this.checked);
    }

    public showUitleg(): void {
        this.toonUitleg = true;
    }

    public closeUitleg(): void {
        this.toonUitleg = false;
    }
}
