import {ChangeDetectionStrategy, Component} from '@angular/core';
import {LeermiddelendashboardModel} from './leermiddelendashboard.model';
import {MethodeGebruikVoorPeriode} from '../../../generated/graphql';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ModalwindowService} from '../../services/modalwindow.service';
import {LeermiddelendashboardFacade} from '../state/leermiddelendashboard/LeermiddelendashboardFacade';
import {map} from 'rxjs/operators';
import * as array from 'fp-ts/Array';

const ONGECLASSIFICEERD = 'Niet geclassificeerd';

@Component({
  selector: 'app-leermiddelendashboard',
  templateUrl: './leermiddelendashboard.component.html',
  styleUrls: ['./leermiddelendashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])]
})
export class LeermiddelendashboardComponent {
  currentData = this.store.selectData().pipe(map(array.map(details =>
    ({details, open: false, nietGeclassificeerd: details.uitgeverOfVakNaam === ONGECLASSIFICEERD}) as LeermiddelendashboardModel)
  ));

  bevatGeenData = this.store.selectData().pipe(map(data => !data[0]));

  vestigingBevatData = this.store.selectVestigingBevatLicenties();

  loading = this.store.selectLoading();

  groeperenOpUitgever = this.store.selectGroeperenOpUitgever();

  constructor(
    private store: LeermiddelendashboardFacade,
    private modalwindowService: ModalwindowService
  ) { }

  onGroeperingChanged(event: boolean) {
    this.store.setGroepering(event);
  }

  toggle(row: LeermiddelendashboardModel) {
    row.open = !row.open;
  }

  navigeerNaarMethode(_: MethodeGebruikVoorPeriode) {
    console.log('Not implemented yet');
  }

  toonNietGeclassificeerdHelp(event: Event) {
    this.modalwindowService.toonOnderwerp('landelijkVak');
    event.stopPropagation();
  }
}
