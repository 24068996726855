import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Component({
    selector: 'app-toggle-buttons',
    templateUrl: './toggle-buttons.component.html',
    styleUrls: ['./toggle-buttons.component.scss']
})
export class ToggleButtonsComponent{
    @Input()
    trueLabel;

    @Input()
    falseLabel;

    @Input()
    state = true;

    @Input()
    grayed;

    @Output()
    private changed = new EventEmitter<boolean>();

    change() {
        this.state = !this.state;
        this.changed.emit(this.state);
    }
}
