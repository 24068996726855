<app-connect-loader [loading]="loading | async">
  <div class="contentbox">
    <div class="headerrow">
      <app-terugknop [urlOmNaarTerugTeGaan]="goBack" [caption]="'Terug naar vak'"></app-terugknop>
      <app-vak-onderwijs-switch *ngIf="vorigeUri || volgendeUri"
                                [vorige]="vorigeUri" [volgende]="volgendeUri"
                                [subNiveau]="lesgroepDetailData.lesgroepNaam">
      </app-vak-onderwijs-switch>
      <app-datumbereik [studiewijzerdb]="false"></app-datumbereik>
    </div>

    <div class="banner">
      <i class="icon-klassendashboard-wit"></i>
      <div class="tekst">
        <span *ngIf="lesgroepDetailData">{{lesgroepDetailData.lesgroepNaam}}</span>
        <span class="leermiddelnaam" *ngIf="lesgroepDetailData && !lesgroepDetailData.meestGebruikteMethode.startsWith('Let op')"> | {{lesgroepDetailData.meestGebruikteMethode}}</span>
      </div>
      <div class="knoppen">
        <div class="individueel" (click)="onSelectieChanged('individueel')" [class.selected]="selectie == 'individueel'">
          Individueel
        </div>
        <div class="samenvatting" (click)="onSelectieChanged('samenvatting')" [class.selected]="selectie == 'samenvatting'">
          Samenvatting
        </div>
      </div>
    </div>

    <app-lesgroep-detail-samenvatting-dashboard *ngIf="selectie === 'samenvatting'" ></app-lesgroep-detail-samenvatting-dashboard>

    <app-table *ngIf="selectie === 'individueel' && lesgroepDetailData" [model]="lesgroepDetailData | klassendashboardTable"></app-table>

  </div>
</app-connect-loader>
