import moment, { Moment, unitOfTime } from 'moment';
import {Datumbereik, PERIODE} from '../state/dashboardsettings/dashboardsettings.state';
import {Periode} from '../services/datumbereik';

// Geeft het datumbereik terug met de kleinste periode en de hoogste begindatum.
export function findWithHighestDateAndSmallestPeriod(datumbereiken: Datumbereik[], relativeToEpochSeconds: number, periode: PERIODE): Datumbereik {
    let highestDateForWeek: Datumbereik = null;
    let highestDateForMonth: Datumbereik = null;
    let highestDateForYear: Datumbereik = null;

    datumbereiken.forEach(db => {
        if (db.periode === 'WEEK'
          && (highestDateForWeek === null || db.epochSeconds > highestDateForWeek.epochSeconds)
          && (!relativeToEpochSeconds || db.epochSeconds <= relativeToEpochSeconds)) {
            highestDateForWeek = db;
        }
        else if (db.periode === 'MAAND'
          && (highestDateForMonth === null || db.epochSeconds > highestDateForMonth.epochSeconds)
          && (!relativeToEpochSeconds || db.epochSeconds <= relativeToEpochSeconds)) {
            highestDateForMonth = db;
        }
        else if (db.periode === 'JAAR'
          && (highestDateForYear === null || db.epochSeconds > highestDateForYear.epochSeconds)
          && (!relativeToEpochSeconds || db.epochSeconds <= relativeToEpochSeconds)) {
            highestDateForYear = db;
        }
    });

    switch (periode) {
      case 'JAAR': return highestDateForYear ? highestDateForYear : highestDateForMonth ? highestDateForMonth : highestDateForWeek;
      case 'MAAND': return highestDateForMonth ? highestDateForMonth : highestDateForWeek ? highestDateForWeek : highestDateForYear;
      case 'WEEK':
      default: return highestDateForWeek ? highestDateForWeek : highestDateForMonth ? highestDateForMonth : highestDateForYear;
    }
}

export function defaultDatumbereik(): Datumbereik {
    return {
        epochSeconds: moment().startOf('isoWeek' as unitOfTime.StartOf).unix(),
        periode: 'WEEK',
        hasData: true
    };
}

export function toEpochSeconds(backendString: string): number {
    const datum = moment(backendString);
    datum.startOf('day');
    datum.locale('NL-nl');
    return datum.unix();
}

function fromEpochSeconds(epochSeconds: number): Moment {
    const datum = moment(epochSeconds * 1000);
    datum.locale('NL-nl');
    return datum;
}

export function backendString(epochSeconds: number): string {
    return fromEpochSeconds(epochSeconds).format('YYYY-MM-DD');
}

export function displayString(epochSeconds: number): string {
    return fromEpochSeconds(epochSeconds).format('DD MMM YYYY');
}

export function endOfPeriodDisplayString(datumbereik: Datumbereik): string {
    switch (datumbereik.periode) {
        case 'WEEK':
            return fromEpochSeconds(datumbereik.epochSeconds).add(6, 'days').format('DD MMM YYYY');
        case 'MAAND':
            return fromEpochSeconds(datumbereik.epochSeconds).add(1, 'months').subtract(1, 'day').format('DD MMM YYYY');
        case 'JAAR':
            return fromEpochSeconds(datumbereik.epochSeconds).add(1, 'year').subtract(1, 'day').format('DD MMM YYYY');
    }
}

export function datumbereikSearchEpochSeconds(datumParam: string, periodeParam: PERIODE): number {
    const mom = moment(datumParam).add(2, 'hours');
    mom.locale('NL-nl');
    return mom.unix();
}

export function dayMonth(epochSeconds: number): string {
    return fromEpochSeconds(epochSeconds).format('DD MMM').replace('.', '');
}

export function dayMonthEndWeek(epochSeconds: number): string {
    const begindatum = fromEpochSeconds(epochSeconds);
    const einddatum = (begindatum.date() > 24 && begindatum.month() === 6) ? begindatum.date(31).month(6) : begindatum.add(7 - begindatum.isoWeekday(), 'days');
    return einddatum.format('DD MMM').replace('.', '');
}

export function week(epochSeconds: number): string {
    const weeknummer = fromEpochSeconds(epochSeconds).week();
    const start = dayMonth(epochSeconds);
    const eind = dayMonthEndWeek(epochSeconds);
    return `Week ${weeknummer} (${start} - ${eind})`;
}

export function month(epochSeconds: number): string {
    return capitalize(fromEpochSeconds(epochSeconds).format('MMMM'));
}

export function monthYear(epochSeconds: number): string {
    return capitalize(fromEpochSeconds(epochSeconds).format('MMMM YYYY'));
}

export function year(epochSeconds: number): string {
    return fromEpochSeconds(epochSeconds).format('YYYY');
}

export function schoolyear(epochSeconds: number): string {
  const huidigJaar = getBeginSchooljaar(fromEpochSeconds(epochSeconds));

  return `${huidigJaar.format('YYYY')}/${huidigJaar.add(1, 'year').format('YYYY')}`;
}

export function schoolYearStartingYear(epochSeconds: number): number 
{
    return getBeginSchooljaar(fromEpochSeconds(epochSeconds)).get('year');
}

export function formatPeriode(datumbereik: Datumbereik): string {
    switch (datumbereik.periode) {
        case 'WEEK':
            return week(datumbereik.epochSeconds);
        case 'MAAND':
            return monthYear(datumbereik.epochSeconds);
        case 'JAAR':
            return schoolyear(datumbereik.epochSeconds);
    }
}

export function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function getBeginSchooljaar(date?: Moment): Moment {
  return date.month() < 7
    ? date.year(date.year() - 1).month(7).date(1)
    : date.month(7).date(1);
}

export function getMatchingWeek(datumbereiken: Datumbereik[], toMatch: Datumbereik): Datumbereik {
  switch (toMatch.periode) {
    case Periode.Week:
      // Week -> week: Weeknummers komen overeen (gelijke nummers, anders null)
      return datumbereiken.find(db => fromEpochSeconds(db.epochSeconds).week() === fromEpochSeconds(toMatch.epochSeconds).week() && db.hasData);
    case Periode.Maand:
      // Maand -> week: De maandag van de week valt in de maand (1e met data)
      return datumbereiken.find(db => fromEpochSeconds(db.epochSeconds).month() === fromEpochSeconds(toMatch.epochSeconds).month() && db.hasData);
    default:
      return null;
  }
}

export function getMatchingMaand(datumbereiken: Datumbereik[], toMatch: Datumbereik): Datumbereik {
  switch (toMatch.periode) {
    case Periode.Week:
      // Maand -> week: De maandag van de week valt in de maand (1e met data)
      return datumbereiken.find(db => fromEpochSeconds(db.epochSeconds).month() === fromEpochSeconds(toMatch.epochSeconds).month() && db.hasData);
    case Periode.Maand:
      // Maand -> maand: De maanden moeten overeen komen (gelijke maanden, anders null)
      return datumbereiken.find(db => fromEpochSeconds(db.epochSeconds).month() === fromEpochSeconds(toMatch.epochSeconds).month() && db.hasData);
    default:
      return null;
  }
}
