<div class="table rows-clickable">
  <div class="tr">
    <div class="th">Leerling</div>
    <div class="th">Leermiddel</div>
    <div class="th">Laatst actief</div>
    <div class="th">Gebruik per week<i class="icon-vraagteken" (click)="openGebruikHelp()"></i></div>
  </div>
  <div class="tr" *ngFor="let leerling of (store.getIndividueelGebruikPerLeerling() | async)">
    <div class="td leerlingnaam">
      <div class="bolletje" [class.red]="(store.getAantalGeactiveerdeLicentiesVoorLeerling(leerling.leerlingUUID) | async) === 0">
        <span>{{ store.getAantalGeactiveerdeLicentiesVoorLeerling(leerling.leerlingUUID) | async }}</span>
      </div>
      <span class="naam max-4-lines">{{ leerling.leerlingNaam }}</span>
    </div>
    <div class="td split">
        <div *ngFor="let leermiddelNaam of (store.getLeermiddelNamenVoorLeerling(leerling.leerlingUUID) | async)">
          <span class="max-2-lines">{{ leermiddelNaam }}</span>
        </div>
        <div *ngIf="(store.getLeermiddelNamenVoorLeerling(leerling.leerlingUUID) | async)?.length == 0">
          <span><i>Geen leermiddelen beschikbaar</i></span>
        </div>
    </div>
    <div class="td split laatstActief">
        <div *ngFor="let laatstActief of (store.getLeermiddelLaatstActiefVoorLeerling(leerling.leerlingUUID) | async)">
          <div *ngIf="!laatstActief" class="i kruis"></div>
          <span>{{ laatstActief ? laatstActief : 'Niet geactiveerd' }}</span>
        </div>
    </div>
    <div class="td split gebruikperweek">
        <div *ngFor="let gebruik of (store.getLeermiddelGebruikVoorLeerlingAlsGeactiveerd(leerling.leerlingUUID) | async)">
          <ng-container *ngIf="gebruik">
            <span>{{ gebruik.gebruikPerWeek.toFixed(1) }}</span>
            <div [class]="'i trend ' + getTrendClass(gebruik.gebruikTrend)"></div>
          </ng-container>
        </div>
    </div>
  </div>
  <div class="tr sticky-to-bottom totaal">
    <div class="td">Totaal</div>
    <div class="td">
      <div class="vink i"></div>
      <span>{{ (store.getTotaalActivatie() | async)?.toFixed(0)}}%</span>
    </div>
    <div class="td">
      <span>{{ (store.getTotaalRecentActief() | async)?.toFixed(0)}}% recent actief</span>
    </div>
    <div class="td gebruikperweek">
      <span>{{(store.getTotaalGebruikPerWeek() | async)?.toFixed(1)}}</span>
    </div>
  </div>
</div>
