import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {LeermiddelvakkoppelingFacade as LeermiddelvakkoppelingOldFacade} from '../old-dashboarding/state/leermiddelvakkoppeling/leermiddelvakkoppeling.facade';
import { AppState } from '../state/app.state';
import { LeermiddelvakkoppelingNewFacade } from '../state/leermiddelvakkoppeling/leermiddelvakkoppeling.facade';
import { selectInstellingsNaam } from '../state/me/me.selectors';

@Component({
  selector: 'app-vestigingsinstellingen',
  templateUrl: './vestigingsinstellingen.component.html',
  styleUrls: ['./vestigingsinstellingen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VestigingsinstellingenComponent implements OnInit, OnDestroy {

  private unsubscribe: Subject<void> = new Subject<void>();
  
  expanded = false;

  instellingNaam: string;

  constructor(private leermiddelVakKoppelingOldStore: LeermiddelvakkoppelingOldFacade, private leermiddelVakKoppelingStore: LeermiddelvakkoppelingNewFacade,
    private store: Store<AppState>) {
    this.store.select(selectInstellingsNaam)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(n => this.instellingNaam = n);
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  toggle(): void {
    this.expanded = !this.expanded;
  }

  close(): void {
    if (this.expanded) {
      this.expanded = false;
    }
  }

  openLeermiddelVakKoppeling(): void {
    this.leermiddelVakKoppelingOldStore.setLeermiddelVakKoppelingOpen(true);
  }

  openLeermiddelVakKoppelingNew(): void {
    this.leermiddelVakKoppelingStore.setLeermiddelVakKoppelingOpen(true);
  }
  
  isNietProductieOfTest(): boolean {
    return 'Test' !== environment.configurationName && 'Productie' !== environment.configurationName;
  }
  
  toonLeermiddelVakKoppelingKnop(oud: boolean): boolean {
    return (!oud) == environment.newDashboardsEnabled.enabled;
  }

  isProductie(): boolean
  {
    return 'Productie' === environment.configurationName;
  }

  isTest(): boolean  {
    return 'Test' === environment.configurationName;
  }

  isTestCollege(): boolean {
    console.log(this.instellingNaam);
    return this.instellingNaam === 'Test College';
  }
}
