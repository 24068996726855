/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { BackofficeApiConfig } from '../backoffice-api-config';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BackofficeEtlTaskEvent } from '../models/backoffice-etl-task-event';
import { BackofficeEtlTaskExecution } from '../models/backoffice-etl-task-execution';
import { BackofficeEtlTaskExecutionResponse } from '../models/backoffice-etl-task-execution-response';
import { BackofficeEtlTaskStep } from '../models/backoffice-etl-task-step';
import { BackofficeOrganization } from '../models/backoffice-organization';
import { BackofficeSchoolYear } from '../models/backoffice-school-year';

@Injectable({
  providedIn: 'root',
})
export class BackofficeApiService extends BaseService {
  constructor(
    config: BackofficeApiConfig,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation connectBackofficeEtlTaskGet
   */
  static readonly ConnectBackofficeEtlTaskGetPath = '/connect/backoffice/etl/task';

  /**
   * Informs of the configuration of the ETL tasks within the given range.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `connectBackofficeEtlTaskGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  connectBackofficeEtlTaskGet$Response(params?: {

    /**
     * Amount of records to return at once. Default is 20.
     */
    page_size?: number;

    /**
     * 0-based index of the requested page. Defaults to 0.
     */
    page_number?: number;

    /**
     * The key by which to order the results. Default is &#x27;ID&#x27;. Not case sensitive.
     */
    order_by?: string;

    /**
     * Order by the order_key descending. Defaults to false.
     */
    descending?: boolean;
  }): Observable<StrictHttpResponse<BackofficeEtlTaskExecutionResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BackofficeApiService.ConnectBackofficeEtlTaskGetPath, 'get');
    if (params) {
      rb.query('page_size', params.page_size, {});
      rb.query('page_number', params.page_number, {});
      rb.query('order_by', params.order_by, {});
      rb.query('descending', params.descending, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BackofficeEtlTaskExecutionResponse>;
      })
    );
  }

  /**
   * Informs of the configuration of the ETL tasks within the given range.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `connectBackofficeEtlTaskGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  connectBackofficeEtlTaskGet(params?: {

    /**
     * Amount of records to return at once. Default is 20.
     */
    page_size?: number;

    /**
     * 0-based index of the requested page. Defaults to 0.
     */
    page_number?: number;

    /**
     * The key by which to order the results. Default is &#x27;ID&#x27;. Not case sensitive.
     */
    order_by?: string;

    /**
     * Order by the order_key descending. Defaults to false.
     */
    descending?: boolean;
  }): Observable<BackofficeEtlTaskExecutionResponse> {

    return this.connectBackofficeEtlTaskGet$Response(params).pipe(
      map((r: StrictHttpResponse<BackofficeEtlTaskExecutionResponse>) => r.body as BackofficeEtlTaskExecutionResponse)
    );
  }

  /**
   * Path part for operation connectBackofficeEtlTaskPost
   */
  static readonly ConnectBackofficeEtlTaskPostPath = '/connect/backoffice/etl/task';

  /**
   * Starts an ETL task
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `connectBackofficeEtlTaskPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  connectBackofficeEtlTaskPost$Response(params?: {

    /**
     * First date in date range.
     */
    date_range_start?: string;

    /**
     * Last date in date range. If equal to date_range_start sync will only run for this date. Otherwise sync will run for both dates and all in between.
     */
    date_range_end?: string;

    /**
     * Indicates if the extraction step should be executed. Default is true.
     */
    extract?: boolean;

    /**
     * Indicates if the transformation step should be executed. Default is true.
     */
    transform?: boolean;

    /**
     * Indicates if the load step should be executed. Default is true.
     */
    load?: boolean;

    /**
     * Freeform information stored with the ETL task status.
     */
    remark?: string;

    /**
     * List of school years to include in the extraction. Default is only the current school year.
     */
    schoolYears?: Array<string>;

    /**
     * List of organizations to include in the extraction. Default is any organization with the Connect or Connect Sync feature active.
     */
    organizations?: Array<string>;

    /**
     * Response will be postponed until ETL task is finished. Response body will include final state of execution.
     */
    block_until_finished?: boolean;
  }): Observable<StrictHttpResponse<BackofficeEtlTaskExecution>> {

    const rb = new RequestBuilder(this.rootUrl, BackofficeApiService.ConnectBackofficeEtlTaskPostPath, 'post');
    if (params) {
      rb.query('date_range_start', params.date_range_start, {});
      rb.query('date_range_end', params.date_range_end, {});
      rb.query('extract', params.extract, {});
      rb.query('transform', params.transform, {});
      rb.query('load', params.load, {});
      rb.query('remark', params.remark, {});
      rb.query('schoolYears', params.schoolYears, {});
      rb.query('organizations', params.organizations, {});
      rb.query('block_until_finished', params.block_until_finished, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BackofficeEtlTaskExecution>;
      })
    );
  }

  /**
   * Starts an ETL task
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `connectBackofficeEtlTaskPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  connectBackofficeEtlTaskPost(params?: {

    /**
     * First date in date range.
     */
    date_range_start?: string;

    /**
     * Last date in date range. If equal to date_range_start sync will only run for this date. Otherwise sync will run for both dates and all in between.
     */
    date_range_end?: string;

    /**
     * Indicates if the extraction step should be executed. Default is true.
     */
    extract?: boolean;

    /**
     * Indicates if the transformation step should be executed. Default is true.
     */
    transform?: boolean;

    /**
     * Indicates if the load step should be executed. Default is true.
     */
    load?: boolean;

    /**
     * Freeform information stored with the ETL task status.
     */
    remark?: string;

    /**
     * List of school years to include in the extraction. Default is only the current school year.
     */
    schoolYears?: Array<string>;

    /**
     * List of organizations to include in the extraction. Default is any organization with the Connect or Connect Sync feature active.
     */
    organizations?: Array<string>;

    /**
     * Response will be postponed until ETL task is finished. Response body will include final state of execution.
     */
    block_until_finished?: boolean;
  }): Observable<BackofficeEtlTaskExecution> {

    return this.connectBackofficeEtlTaskPost$Response(params).pipe(
      map((r: StrictHttpResponse<BackofficeEtlTaskExecution>) => r.body as BackofficeEtlTaskExecution)
    );
  }

  /**
   * Path part for operation connectBackofficeEtlCloudschedulerTaskPost
   */
  static readonly ConnectBackofficeEtlCloudschedulerTaskPostPath = '/connect/backoffice/etl/cloudscheduler/task';

  /**
   * Starts an ETL task
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `connectBackofficeEtlCloudschedulerTaskPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  connectBackofficeEtlCloudschedulerTaskPost$Response(params?: {

    /**
     * Reference date used in the ETL process. Default is today.
     */
    reference_date?: string;

    /**
     * Indicates if the extraction step should be executed. Default is true.
     */
    extract?: boolean;

    /**
     * Indicates if the transformation step should be executed. Default is true.
     */
    transform?: boolean;

    /**
     * Indicates if the load step should be executed. Default is true.
     */
    load?: boolean;

    /**
     * Freeform information stored with the ETL task status.
     */
    remark?: string;

    /**
     * List of school years to include in the extraction. Default is only the current school year.
     */
    schoolYears?: Array<string>;

    /**
     * List of organizations to include in the extraction. Default is any organization with the Connect or Connect Sync feature active.
     */
    organizations?: Array<string>;

    /**
     * Response will be postponed until ETL task is finished. Response body will include final state of execution.
     */
    block_until_finished?: boolean;
  }): Observable<StrictHttpResponse<BackofficeEtlTaskExecution>> {

    const rb = new RequestBuilder(this.rootUrl, BackofficeApiService.ConnectBackofficeEtlCloudschedulerTaskPostPath, 'post');
    if (params) {
      rb.query('reference_date', params.reference_date, {});
      rb.query('extract', params.extract, {});
      rb.query('transform', params.transform, {});
      rb.query('load', params.load, {});
      rb.query('remark', params.remark, {});
      rb.query('schoolYears', params.schoolYears, {});
      rb.query('organizations', params.organizations, {});
      rb.query('block_until_finished', params.block_until_finished, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BackofficeEtlTaskExecution>;
      })
    );
  }

  /**
   * Starts an ETL task
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `connectBackofficeEtlCloudschedulerTaskPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  connectBackofficeEtlCloudschedulerTaskPost(params?: {

    /**
     * Reference date used in the ETL process. Default is today.
     */
    reference_date?: string;

    /**
     * Indicates if the extraction step should be executed. Default is true.
     */
    extract?: boolean;

    /**
     * Indicates if the transformation step should be executed. Default is true.
     */
    transform?: boolean;

    /**
     * Indicates if the load step should be executed. Default is true.
     */
    load?: boolean;

    /**
     * Freeform information stored with the ETL task status.
     */
    remark?: string;

    /**
     * List of school years to include in the extraction. Default is only the current school year.
     */
    schoolYears?: Array<string>;

    /**
     * List of organizations to include in the extraction. Default is any organization with the Connect or Connect Sync feature active.
     */
    organizations?: Array<string>;

    /**
     * Response will be postponed until ETL task is finished. Response body will include final state of execution.
     */
    block_until_finished?: boolean;
  }): Observable<BackofficeEtlTaskExecution> {

    return this.connectBackofficeEtlCloudschedulerTaskPost$Response(params).pipe(
      map((r: StrictHttpResponse<BackofficeEtlTaskExecution>) => r.body as BackofficeEtlTaskExecution)
    );
  }

  /**
   * Path part for operation connectBackofficeEtlTaskTaskIdGet
   */
  static readonly ConnectBackofficeEtlTaskTaskIdGetPath = '/connect/backoffice/etl/task/{task_id}';

  /**
   * Informs of the configuration of the ETL task with the given ID.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `connectBackofficeEtlTaskTaskIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  connectBackofficeEtlTaskTaskIdGet$Response(params: {

    /**
     * ID as unique identifier of the requested task.
     */
    task_id: number;
  }): Observable<StrictHttpResponse<BackofficeEtlTaskExecution>> {

    const rb = new RequestBuilder(this.rootUrl, BackofficeApiService.ConnectBackofficeEtlTaskTaskIdGetPath, 'get');
    if (params) {
      rb.path('task_id', params.task_id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BackofficeEtlTaskExecution>;
      })
    );
  }

  /**
   * Informs of the configuration of the ETL task with the given ID.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `connectBackofficeEtlTaskTaskIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  connectBackofficeEtlTaskTaskIdGet(params: {

    /**
     * ID as unique identifier of the requested task.
     */
    task_id: number;
  }): Observable<BackofficeEtlTaskExecution> {

    return this.connectBackofficeEtlTaskTaskIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<BackofficeEtlTaskExecution>) => r.body as BackofficeEtlTaskExecution)
    );
  }

  /**
   * Path part for operation connectBackofficeEtlTaskTaskIdEventsGet
   */
  static readonly ConnectBackofficeEtlTaskTaskIdEventsGetPath = '/connect/backoffice/etl/task/{task_id}/events';

  /**
   * Reports back events for the ETL task with the given ID.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `connectBackofficeEtlTaskTaskIdEventsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  connectBackofficeEtlTaskTaskIdEventsGet$Response(params: {

    /**
     * ID as unique identifier of the requested task.
     */
    task_id: number;

    /**
     * ID of the last event that the client received
     */
    from?: number;
  }): Observable<StrictHttpResponse<Array<BackofficeEtlTaskEvent>>> {

    const rb = new RequestBuilder(this.rootUrl, BackofficeApiService.ConnectBackofficeEtlTaskTaskIdEventsGetPath, 'get');
    if (params) {
      rb.path('task_id', params.task_id, {});
      rb.query('from', params.from, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BackofficeEtlTaskEvent>>;
      })
    );
  }

  /**
   * Reports back events for the ETL task with the given ID.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `connectBackofficeEtlTaskTaskIdEventsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  connectBackofficeEtlTaskTaskIdEventsGet(params: {

    /**
     * ID as unique identifier of the requested task.
     */
    task_id: number;

    /**
     * ID of the last event that the client received
     */
    from?: number;
  }): Observable<Array<BackofficeEtlTaskEvent>> {

    return this.connectBackofficeEtlTaskTaskIdEventsGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<BackofficeEtlTaskEvent>>) => r.body as Array<BackofficeEtlTaskEvent>)
    );
  }

  /**
   * Path part for operation connectBackofficeEtlTaskTaskIdSchoolYearsGet
   */
  static readonly ConnectBackofficeEtlTaskTaskIdSchoolYearsGetPath = '/connect/backoffice/etl/task/{task_id}/schoolYears';

  /**
   * Reports back the School Years for the ETL task with the given ID.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `connectBackofficeEtlTaskTaskIdSchoolYearsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  connectBackofficeEtlTaskTaskIdSchoolYearsGet$Response(params: {

    /**
     * ID as unique identifier of the requested task.
     */
    task_id: number;
  }): Observable<StrictHttpResponse<Array<BackofficeSchoolYear>>> {

    const rb = new RequestBuilder(this.rootUrl, BackofficeApiService.ConnectBackofficeEtlTaskTaskIdSchoolYearsGetPath, 'get');
    if (params) {
      rb.path('task_id', params.task_id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BackofficeSchoolYear>>;
      })
    );
  }

  /**
   * Reports back the School Years for the ETL task with the given ID.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `connectBackofficeEtlTaskTaskIdSchoolYearsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  connectBackofficeEtlTaskTaskIdSchoolYearsGet(params: {

    /**
     * ID as unique identifier of the requested task.
     */
    task_id: number;
  }): Observable<Array<BackofficeSchoolYear>> {

    return this.connectBackofficeEtlTaskTaskIdSchoolYearsGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<BackofficeSchoolYear>>) => r.body as Array<BackofficeSchoolYear>)
    );
  }

  /**
   * Path part for operation connectBackofficeEtlTaskTaskIdOrganizationsGet
   */
  static readonly ConnectBackofficeEtlTaskTaskIdOrganizationsGetPath = '/connect/backoffice/etl/task/{task_id}/organizations';

  /**
   * Reports back the Organizations for the ETL task with the given ID.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `connectBackofficeEtlTaskTaskIdOrganizationsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  connectBackofficeEtlTaskTaskIdOrganizationsGet$Response(params: {

    /**
     * ID as unique identifier of the requested task.
     */
    task_id: number;
  }): Observable<StrictHttpResponse<Array<BackofficeOrganization>>> {

    const rb = new RequestBuilder(this.rootUrl, BackofficeApiService.ConnectBackofficeEtlTaskTaskIdOrganizationsGetPath, 'get');
    if (params) {
      rb.path('task_id', params.task_id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BackofficeOrganization>>;
      })
    );
  }

  /**
   * Reports back the Organizations for the ETL task with the given ID.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `connectBackofficeEtlTaskTaskIdOrganizationsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  connectBackofficeEtlTaskTaskIdOrganizationsGet(params: {

    /**
     * ID as unique identifier of the requested task.
     */
    task_id: number;
  }): Observable<Array<BackofficeOrganization>> {

    return this.connectBackofficeEtlTaskTaskIdOrganizationsGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<BackofficeOrganization>>) => r.body as Array<BackofficeOrganization>)
    );
  }

  /**
   * Path part for operation connectBackofficeEtlTaskTaskIdFailedOrganizationsGet
   */
  static readonly ConnectBackofficeEtlTaskTaskIdFailedOrganizationsGetPath = '/connect/backoffice/etl/task/{task_id}/failedOrganizations';

  /**
   * Reports back the failed Organizations for the ETL task with the given ID.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `connectBackofficeEtlTaskTaskIdFailedOrganizationsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  connectBackofficeEtlTaskTaskIdFailedOrganizationsGet$Response(params: {

    /**
     * ID as unique identifier of the requested task.
     */
    task_id: number;
  }): Observable<StrictHttpResponse<Array<BackofficeOrganization>>> {

    const rb = new RequestBuilder(this.rootUrl, BackofficeApiService.ConnectBackofficeEtlTaskTaskIdFailedOrganizationsGetPath, 'get');
    if (params) {
      rb.path('task_id', params.task_id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BackofficeOrganization>>;
      })
    );
  }

  /**
   * Reports back the failed Organizations for the ETL task with the given ID.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `connectBackofficeEtlTaskTaskIdFailedOrganizationsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  connectBackofficeEtlTaskTaskIdFailedOrganizationsGet(params: {

    /**
     * ID as unique identifier of the requested task.
     */
    task_id: number;
  }): Observable<Array<BackofficeOrganization>> {

    return this.connectBackofficeEtlTaskTaskIdFailedOrganizationsGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<BackofficeOrganization>>) => r.body as Array<BackofficeOrganization>)
    );
  }

  /**
   * Path part for operation connectBackofficeEtlTaskTaskIdStepsGet
   */
  static readonly ConnectBackofficeEtlTaskTaskIdStepsGetPath = '/connect/backoffice/etl/task/{task_id}/steps';

  /**
   * Reports back steps left for the ETL task with the given ID.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `connectBackofficeEtlTaskTaskIdStepsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  connectBackofficeEtlTaskTaskIdStepsGet$Response(params: {

    /**
     * ID as unique identifier of the requested task.
     */
    task_id: number;
  }): Observable<StrictHttpResponse<Array<BackofficeEtlTaskStep>>> {

    const rb = new RequestBuilder(this.rootUrl, BackofficeApiService.ConnectBackofficeEtlTaskTaskIdStepsGetPath, 'get');
    if (params) {
      rb.path('task_id', params.task_id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BackofficeEtlTaskStep>>;
      })
    );
  }

  /**
   * Reports back steps left for the ETL task with the given ID.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `connectBackofficeEtlTaskTaskIdStepsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  connectBackofficeEtlTaskTaskIdStepsGet(params: {

    /**
     * ID as unique identifier of the requested task.
     */
    task_id: number;
  }): Observable<Array<BackofficeEtlTaskStep>> {

    return this.connectBackofficeEtlTaskTaskIdStepsGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<BackofficeEtlTaskStep>>) => r.body as Array<BackofficeEtlTaskStep>)
    );
  }

  /**
   * Path part for operation connectBackofficeEtlTaskTaskIdStopPost
   */
  static readonly ConnectBackofficeEtlTaskTaskIdStopPostPath = '/connect/backoffice/etl/task/{task_id}/stop';

  /**
   * Stops a running ETL task. If the task is not runing anymore, this call will be ignored.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `connectBackofficeEtlTaskTaskIdStopPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  connectBackofficeEtlTaskTaskIdStopPost$Response(params: {

    /**
     * ID as unique identifier of the task.
     */
    task_id: number;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, BackofficeApiService.ConnectBackofficeEtlTaskTaskIdStopPostPath, 'post');
    if (params) {
      rb.path('task_id', params.task_id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Stops a running ETL task. If the task is not runing anymore, this call will be ignored.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `connectBackofficeEtlTaskTaskIdStopPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  connectBackofficeEtlTaskTaskIdStopPost(params: {

    /**
     * ID as unique identifier of the task.
     */
    task_id: number;
  }): Observable<string> {

    return this.connectBackofficeEtlTaskTaskIdStopPost$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation connectBackofficeEtlTaskTaskIdResumePost
   */
  static readonly ConnectBackofficeEtlTaskTaskIdResumePostPath = '/connect/backoffice/etl/task/{task_id}/resume';

  /**
   * Resume a stopped ETL task. If the task has no steps left, this call will be ignored.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `connectBackofficeEtlTaskTaskIdResumePost()` instead.
   *
   * This method doesn't expect any request body.
   */
  connectBackofficeEtlTaskTaskIdResumePost$Response(params: {

    /**
     * ID as unique identifier of the task.
     */
    task_id: number;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, BackofficeApiService.ConnectBackofficeEtlTaskTaskIdResumePostPath, 'post');
    if (params) {
      rb.path('task_id', params.task_id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Resume a stopped ETL task. If the task has no steps left, this call will be ignored.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `connectBackofficeEtlTaskTaskIdResumePost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  connectBackofficeEtlTaskTaskIdResumePost(params: {

    /**
     * ID as unique identifier of the task.
     */
    task_id: number;
  }): Observable<string> {

    return this.connectBackofficeEtlTaskTaskIdResumePost$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation connectBackofficeEtlSchoolYearGet
   */
  static readonly ConnectBackofficeEtlSchoolYearGetPath = '/connect/backoffice/etl/school_year';

  /**
   * Lists all school years registered in the backoffice.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `connectBackofficeEtlSchoolYearGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  connectBackofficeEtlSchoolYearGet$Response(params?: {
  }): Observable<StrictHttpResponse<Array<BackofficeSchoolYear>>> {

    const rb = new RequestBuilder(this.rootUrl, BackofficeApiService.ConnectBackofficeEtlSchoolYearGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BackofficeSchoolYear>>;
      })
    );
  }

  /**
   * Lists all school years registered in the backoffice.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `connectBackofficeEtlSchoolYearGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  connectBackofficeEtlSchoolYearGet(params?: {
  }): Observable<Array<BackofficeSchoolYear>> {

    return this.connectBackofficeEtlSchoolYearGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<BackofficeSchoolYear>>) => r.body as Array<BackofficeSchoolYear>)
    );
  }

  /**
   * Path part for operation connectBackofficeEtlSchoolYearSchoolYearNameGet
   */
  static readonly ConnectBackofficeEtlSchoolYearSchoolYearNameGetPath = '/connect/backoffice/etl/school_year/{school_year_name}';

  /**
   * Returns school year with given name.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `connectBackofficeEtlSchoolYearSchoolYearNameGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  connectBackofficeEtlSchoolYearSchoolYearNameGet$Response(params: {

    /**
     * Name of the school year
     */
    school_year_name: string;
  }): Observable<StrictHttpResponse<BackofficeSchoolYear>> {

    const rb = new RequestBuilder(this.rootUrl, BackofficeApiService.ConnectBackofficeEtlSchoolYearSchoolYearNameGetPath, 'get');
    if (params) {
      rb.path('school_year_name', params.school_year_name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BackofficeSchoolYear>;
      })
    );
  }

  /**
   * Returns school year with given name.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `connectBackofficeEtlSchoolYearSchoolYearNameGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  connectBackofficeEtlSchoolYearSchoolYearNameGet(params: {

    /**
     * Name of the school year
     */
    school_year_name: string;
  }): Observable<BackofficeSchoolYear> {

    return this.connectBackofficeEtlSchoolYearSchoolYearNameGet$Response(params).pipe(
      map((r: StrictHttpResponse<BackofficeSchoolYear>) => r.body as BackofficeSchoolYear)
    );
  }

  /**
   * Path part for operation connectBackofficeEtlSchoolYearSchoolYearNamePut
   */
  static readonly ConnectBackofficeEtlSchoolYearSchoolYearNamePutPath = '/connect/backoffice/etl/school_year/{school_year_name}';

  /**
   * Stores the new or updated school yaar.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `connectBackofficeEtlSchoolYearSchoolYearNamePut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  connectBackofficeEtlSchoolYearSchoolYearNamePut$Response(params: {

    /**
     * Name of the school year
     */
    school_year_name: string;
    body: BackofficeSchoolYear
  }): Observable<StrictHttpResponse<BackofficeSchoolYear>> {

    const rb = new RequestBuilder(this.rootUrl, BackofficeApiService.ConnectBackofficeEtlSchoolYearSchoolYearNamePutPath, 'put');
    if (params) {
      rb.path('school_year_name', params.school_year_name, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BackofficeSchoolYear>;
      })
    );
  }

  /**
   * Stores the new or updated school yaar.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `connectBackofficeEtlSchoolYearSchoolYearNamePut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  connectBackofficeEtlSchoolYearSchoolYearNamePut(params: {

    /**
     * Name of the school year
     */
    school_year_name: string;
    body: BackofficeSchoolYear
  }): Observable<BackofficeSchoolYear> {

    return this.connectBackofficeEtlSchoolYearSchoolYearNamePut$Response(params).pipe(
      map((r: StrictHttpResponse<BackofficeSchoolYear>) => r.body as BackofficeSchoolYear)
    );
  }

  /**
   * Path part for operation connectBackofficeEtlOrganizationGet
   */
  static readonly ConnectBackofficeEtlOrganizationGetPath = '/connect/backoffice/etl/organization/';

  /**
   * Returns some statistics regarding organizations previously involved in ETL tasks.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `connectBackofficeEtlOrganizationGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  connectBackofficeEtlOrganizationGet$Response(params?: {
  }): Observable<StrictHttpResponse<Array<BackofficeOrganization>>> {

    const rb = new RequestBuilder(this.rootUrl, BackofficeApiService.ConnectBackofficeEtlOrganizationGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BackofficeOrganization>>;
      })
    );
  }

  /**
   * Returns some statistics regarding organizations previously involved in ETL tasks.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `connectBackofficeEtlOrganizationGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  connectBackofficeEtlOrganizationGet(params?: {
  }): Observable<Array<BackofficeOrganization>> {

    return this.connectBackofficeEtlOrganizationGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<BackofficeOrganization>>) => r.body as Array<BackofficeOrganization>)
    );
  }

  /**
   * Path part for operation connectBackofficeEtlOrganizationPost
   */
  static readonly ConnectBackofficeEtlOrganizationPostPath = '/connect/backoffice/etl/organization/';

  /**
   * Updates the given Organization to set attributes like 'protected' that are not determined by Somtoday Core.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `connectBackofficeEtlOrganizationPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  connectBackofficeEtlOrganizationPost$Response(params: {
    body: BackofficeOrganization
  }): Observable<StrictHttpResponse<BackofficeOrganization>> {

    const rb = new RequestBuilder(this.rootUrl, BackofficeApiService.ConnectBackofficeEtlOrganizationPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BackofficeOrganization>;
      })
    );
  }

  /**
   * Updates the given Organization to set attributes like 'protected' that are not determined by Somtoday Core.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `connectBackofficeEtlOrganizationPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  connectBackofficeEtlOrganizationPost(params: {
    body: BackofficeOrganization
  }): Observable<BackofficeOrganization> {

    return this.connectBackofficeEtlOrganizationPost$Response(params).pipe(
      map((r: StrictHttpResponse<BackofficeOrganization>) => r.body as BackofficeOrganization)
    );
  }

}
