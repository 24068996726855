import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {SwLinkjesPerPlatformOfVak} from '../../../generated/graphql';
import {StudiewijzerKleuren} from '../studiewijzer-kleuren';
import {Observable, Subject, Subscription} from 'rxjs';
import {SwLinkjesPerPlatformOfVakModel} from '../../old-dashboarding/state/studiewijzerlinkjes/studiewijzerlinkjes.selectors';

interface StudiewijzerlinkjesOverzichtData {
  platform: SwLinkjesPerPlatformOfVakModel;
  kleur: string;
}

@Component({
  selector: 'app-studiewijzerlinkjes-overzicht',
  templateUrl: './studiewijzerlinkjes-overzicht.component.html',
  styleUrls: ['./studiewijzerlinkjes-overzicht.component.scss']
})
export class StudiewijzerlinkjesOverzichtComponent implements OnInit, OnDestroy {
  titel: string;

  public data: StudiewijzerlinkjesOverzichtData[];

  @Input()
  public viewModel: StudiewijzerlinkjesOverzichtViewModel;

  private currentItem: SwLinkjesPerPlatformOfVak;

  linksWidth = '1rem';

  private subscriptions: Subscription[] = [];

  ngOnInit() {
    this.subscriptions.push(this.viewModel.onGetGroeperingOpPlatform.subscribe(
      groeperingIsPlatform => this.titel = groeperingIsPlatform ? 'Platformen' : 'Vakken')
    );
    this.subscriptions.push(this.viewModel.onGetData.subscribe(data => {
      const grootsteGetal = data.reduce((acc, val) => val.links > acc ? val.links : acc, 0);
      this.linksWidth = Math.ceil(`${grootsteGetal}`.length / 2) + 'rem';
      const kleuren = StudiewijzerKleuren.iterate();
      this.data = data.map(platform => ({platform, kleur: platform.naam === 'Overig' ? kleuren.last() : kleuren.next()}));
    }));
    this.subscriptions.push(this.viewModel.onGetCurrentItem.subscribe(item => this.currentItem = item));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  isCurrentItem(item: SwLinkjesPerPlatformOfVak): boolean {
    return item.naam === this.currentItem?.naam;
  }

  isOverig(item: StudiewijzerlinkjesOverzichtData): boolean {
    return item.platform.overig;
  }

  getBoxStyle(item: StudiewijzerlinkjesOverzichtData): object {
    return item.platform.overig ? {'background-color': 'none'} : {'background-color': item.kleur};
  }

  showDetails(item: StudiewijzerlinkjesOverzichtData) {
    if (item.platform.naam !== 'Overig') {
      this.viewModel.selectItem(item.platform);
    }
  }
}

export interface StudiewijzerlinkjesOverzichtViewModel {
  onGetData: Observable<SwLinkjesPerPlatformOfVakModel[]>;
  onGetCurrentItem: Observable<SwLinkjesPerPlatformOfVak>;
  onGetGroeperingOpPlatform: Observable<boolean>;
  selectItem(item: SwLinkjesPerPlatformOfVak);
}
