<div *ngIf="userAuthenticated(); else loginAction" class="userinfo" >
  <a (clickOutside)="close()" (click)="toggle()">
    <span class="initials login-button-ignore-autoclose">{{initials}}</span>
    <i [class]="expanded ? 'icon-arrow-close' : 'icon-arrow-open'"></i>
  </a>
  <a *ngIf="expanded" class="loguit" (click)="removeAuthentication()">
    <i class="icon-uitloggen"></i>
    <span>Uitloggen</span>
  </a>
</div>

<ng-template #loginAction>
  <div>
    <button buttonstyle="raised" color="blue" (click)="startAuthentication()">Login</button>
  </div>
</ng-template>




