import {Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy} from '@angular/core';
import {BehaviorSubject, combineLatest} from 'rxjs';
import { map } from 'rxjs/operators';
import { SelectLeerjarenViewModel } from '../select-leerjaren/select-leerjaren.component';
import { SelectTableViewModel } from '../select-table/select-table.component';
import { SelectLeerlingenViewModel, SelectLeerlingenComponentViewModel } from './select-leerlingen-component-view-model';

@Component({
    selector: 'app-select-leerlingen',
    templateUrl: './select-leerlingen.component.html',
    styleUrls: ['./select-leerlingen.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectLeerlingenComponent implements OnInit, OnDestroy {
    @Input()
    private viewModel: SelectLeerlingenViewModel;

    public onderwijssoortenSelectTableViewModel: SelectTableViewModel;

    public selectLeerjarenViewModel: SelectLeerjarenViewModel;

    public vakkenSelectTableViewModel: SelectTableViewModel;

    public maxSelectionTablesHeightStyle: string = '';

    public submitButtonStyle: string = 'submitButton';

    private submitButtonActive: boolean = false;

    private subscriptions = [];

    private componentViewModel: SelectLeerlingenComponentViewModel;

    ngOnInit(): void {
        this.componentViewModel = new SelectLeerlingenComponentViewModel(this.viewModel);

        this.onderwijssoortenSelectTableViewModel = {
            onGetTitle: new BehaviorSubject('Onderwijssoorten'),
            onGetHasSelectAll: new BehaviorSubject(true),
            onGetIsAllSelected: this.componentViewModel.doSetSelectAlleOnderwijssoorten,
            onGetRows: this.componentViewModel.onGetAangebodenOnderwijssoorten,
            onGetPlaceholderText: new BehaviorSubject(null),
            doSelectRow: this.componentViewModel.doSelectOnderwijssoort,
            doSelectAll: this.componentViewModel.doSetSelectAlleOnderwijssoorten,
            contentIsLoaded: new BehaviorSubject<boolean>(false)
        };

        this.selectLeerjarenViewModel = {
            onGetLeerjaren: this.componentViewModel.onGetAangebodenLeerjaren,
            onGetSelectAlleLeerjaren: this.componentViewModel.onGetSelectAlleLeerjaren,
            doSelectLeerjaar: this.componentViewModel.doSelectLeerjaar,
            doSetSelectAlleLeerjaren: this.componentViewModel.doSetSelectAlleLeerjaren,
            contentIsLoaded: new BehaviorSubject<boolean>(false)
        };

        this.vakkenSelectTableViewModel = {
            onGetTitle: new BehaviorSubject('Vakken'),
            onGetHasSelectAll: new BehaviorSubject(true),
            onGetIsAllSelected: this.componentViewModel.doSetSelectAlleVakken,
            onGetRows: this.componentViewModel.onGetAangebodenVakken,
            onGetPlaceholderText: new BehaviorSubject('Voor de geselecteerde Onderwijssoorten en leerjaren zijn geen vakken beschikbaar.'),
            doSelectRow: this.componentViewModel.doSelectVak,
            doSelectAll: this.componentViewModel.doSetSelectAlleVakken,
            contentIsLoaded: new BehaviorSubject<boolean>(false)
        };

        this.componentViewModel.onInit();

        this.subscriptions.push(this.componentViewModel.onGetSubmitButtonActive.subscribe(isActive => {
            this.submitButtonActive = isActive;
            this.submitButtonStyle = isActive ? 'submitButton' : 'submitButton disabled';
        }));

        this.subscriptions.push(
          combineLatest([this.onderwijssoortenSelectTableViewModel.contentIsLoaded, this.selectLeerjarenViewModel.contentIsLoaded, this.vakkenSelectTableViewModel.contentIsLoaded])
            .pipe(
              map(([os, lj, v]) => os && lj && v)
            ).subscribe(loaded => {
              this.viewModel.contentIsLoaded.next(loaded);
          }));
    }

    ngOnDestroy(): void {
        this.viewModel.contentIsLoaded.next(false);
        this.subscriptions.forEach(s => s.unsubscribe());
        this.componentViewModel.onDestroy();
    }

    public sluiten(): void {
        this.viewModel.doSetIsOpen.next(false);
    }

    public selectieSelecteren(): void {
        if (this.submitButtonActive) {
          this.componentViewModel.doSubmitSelection.next(true);
          this.sluiten();
        }
    }
}
