import {createAction, props} from '@ngrx/store';
import {
  OnderwijssoortLeerjaarInzicht,
  StudiewijzerDashboardItem,
  StudiewijzerPlatformOfVakItem,
} from './studiewijzerlinkjes.state';

export const setStudiewijzerlinkjesAlgemeen = createAction('[Studiewijzerlinkjes] Set studiewijzerlinkjes algemeen', props<{ totaalAantalLinks: number, totaalAantalLesgroepen: number }>());

export const setStudiewijzerlinkjesVakken = createAction('[Studiewijzerlinkjes] Set studiewijzerlinkjes vakken', props<{ vakGebruik: StudiewijzerDashboardItem[] }>());
export const setStudiewijzerLinkjesVakDetails = createAction('[Studiewijzerlinkjes] Set studiewijzerlinkjes vakdetails', props<{ details: StudiewijzerPlatformOfVakItem[], naam: string }>());
export const fetchStudiewijzerLinkjesVakDetails = createAction('[Studiewijzerlinkjes] Fetch studiewijzerlinkjes vakdetails', props<{vakNaam: string}>());

export const setStudiewijzerlinkjesPlatformen = createAction('[Studiewijzerlinkjes] Set studiewijzerlinkjes platformen', props<{ platformGebruik: StudiewijzerDashboardItem[] }>());
export const setStudiewijzerLinkjesPlatformDetails = createAction('[Studiewijzerlinkjes] Set studiewijzerlinkjes platformdetails', props<{ details: StudiewijzerPlatformOfVakItem[], naam: string }>());
export const fetchStudiewijzerLinkjesPlatformDetails = createAction('[Studiewijzerlinkjes] Fetch studiewijzerlinkjes platformdetails', props<{ platformNaam: string}>());

export const setStudiewijzerLinkjesOnderwijssoortLeerjaarInzicht = createAction('[Studiewijzerlinkjes] Set studiewijzerlinkjes onderwijssoortLeerjaarInzicht', props<{ onderwijssoortLeerjaarInzicht: OnderwijssoortLeerjaarInzicht[], platformNaam: string, vakNaam: string }>());
export const fetchStudiewijzerLinkjesOnderwijssoortLeerjaarInzicht = createAction('[Studiewijzerlinkjes] Fetch studiewijzerlinkjes onderwijssoortLeerjaarInzicht', props<{ platformNaam: string, vakNaam: string }>());

export const resetStudiewijzerLinkjes = createAction('[Studiewijzerlinkjes] Reset studiewijzerlinkjes');
