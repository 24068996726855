import {Pipe, PipeTransform} from '@angular/core';
import {createColumnDef, createModel, TableModel} from '../../table/table/table.model';
import {SwLinkjesGebruikDetails} from '../../../generated/graphql';
import {ModalwindowService} from '../../services/modalwindow.service';
import {HelpHeaderCellComponent} from '../../table/help-header-cell/help-header-cell.component';
import {ClickableRowIconCellComponent} from '../../table/clickable-row-icon-cell/clickable-row-icon-cell.component';
import {RightAlignedCenterCellComponent} from '../../table/right-aligned-center-cell/right-aligned-center-cell.component';
import {PercentageIndicatieCellComponent} from '../../table/percentage-indicatie-cell/percentage-indicatie-cell.component';
import {KeyCellComponent} from '../../table/key-cell/key-cell.component';

@Pipe({name: 'swdetailmodel'})
export class StudiewijzerlinkjesDetail implements PipeTransform {
  constructor(private modalwindowService: ModalwindowService) {
  }

  transform(value: SwLinkjesGebruikDetails[], isPlatform: boolean): TableModel<SwLinkjesGebruikDetails> {
    const getRowId = start => _ => `id${start++}`;
    const model = createModel(value, getRowId(1));
    model.rowsClickable = true;
    model.dense = true;

    const naam = createColumnDef('naam', `Actieve ${isPlatform ? 'vaksecties' : 'platformen'}`);
    naam.header.style = {fontSize: '16px'};
    naam.body.component = KeyCellComponent;
    naam.body.style = {width: '40%'};

    const links = createColumnDef('links', 'Links');
    links.header.style = {textAlign: 'center'};
    links.body.component = RightAlignedCenterCellComponent;
    const grootsteGetal = value.map(v => v.links).reduce((acc, v) => v > acc ? v : acc, 0);
    const width = Math.ceil(`${grootsteGetal}`.length / 2);
    links.body.getValue = (rowModel: SwLinkjesGebruikDetails) => ({value: rowModel.links, width});
    links.body.style = {width: '30%'};

    const klikratio = createColumnDef('klikratio');
    const help = () => this.modalwindowService.toonOnderwerp('klikratio');
    klikratio.header.component = HelpHeaderCellComponent;
    klikratio.header.getValue = () => ({title: 'Klikratio', help});
    klikratio.body.component = PercentageIndicatieCellComponent;
    klikratio.body.getValue = (rowModel: SwLinkjesGebruikDetails) =>
      ({waarde: rowModel.klikratio, matig: .6, laag: .3, titel: 'Klikratio'});

    const icon = createColumnDef('icon', '');
    icon.body.component = ClickableRowIconCellComponent;
    icon.body.style = {textAlign: 'right'};

    model.columnDefs = [naam, links, klikratio, icon];
    return model;
  }
}
