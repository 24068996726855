import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-schoolyear-row',
    templateUrl: './schoolyear-row.component.html',
    styleUrls: ['./schoolyear-row.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SchoolyearRowComponent implements OnInit {

    @Input()
    public schoolYear: EditableSchoolYear;

    @Output()
    public save = new EventEmitter<{name?: string, include_from?: string, include_until?: string}>();

    constructor() { }

    ngOnInit(): void {
    }

    public editClicked(): void {
        this.schoolYear.editing = true;
    }

    public saveClicked(name: string, include_from: string, include_until: string): void {
        this.save.emit({ name, include_from, include_until });
        this.schoolYear.editing = false;
    }
}

export interface EditableSchoolYear {
    name?: string;
    include_from: string;
    include_until: string;
    editing: boolean;
};
