<div class="tab-row">
  <div class="tab-row__tabs">
    <div
      role="tablist"
      *ngFor="let tab of tabs; let i = index"
      (click)="_setActiveTab(tab)"
    >
      <div
        class="tab-row__label"
        role="tab"
        id="{{'tab_' + i}}"
        [class.is-active]="selectedTab === tab"
        [attr.aria-selected]="selectedTab === tab"
        [attr.aria-controls]="'tab-panel_' + i"
      >
        {{tab.label}}
      </div>
    </div>
  </div>

  <div *ngFor="let tab of tabs; let i = index">
    <div
      class="tab-row__content"
      id="{{'tab-panel_' + i}}"
      role="tabpanel"
      [class.is-active]="selectedTab === tab"
      [attr.aria-labelledby]="'tab_' + i"
    >
      <ng-container *ngTemplateOutlet="tab.template"></ng-container>
    </div>
  </div>
</div>
