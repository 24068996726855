import {createReducer, on} from '@ngrx/store';
import {reset} from '../../../state/app.actions';
import {LeermiddelvakkoppelingState} from './leermiddelvakkoppeling.state';
import {
  setLeermiddelVakKoppelingData, setLeermiddelVakKoppelingOpen,
  setLeermiddelVakKoppelingLoading, setSelectedLeermiddelVakKoppeling,
  updateLeermiddelVakKoppelingData
} from './leermiddelvakkoppeling.actions';

const initialState: LeermiddelvakkoppelingState = {
  leermiddelVakKoppelingOpen: false,
  leermiddelVakKoppelingLoading: false,
  leermiddelVakKoppelingData: [],
  vestigingVakkenData: [],
  gekoppeldeLeermiddelen: [],
  selectedLeermiddelVakKoppeling: null
};

export const leermiddelvakkoppelingReducer = createReducer(
  initialState,
  on(setLeermiddelVakKoppelingData, (state, {value}) =>
    ({...state,
      leermiddelVakKoppelingData: value.leermiddelVakKoppelingInfo,
      leermiddelVakKoppelingLoading: false,
      vestigingVakkenData: value.vestigingsVakken,
      gekoppeldeLeermiddelen: value.leermiddelVakKoppelingInfo.filter(d => d.vakken.length !== 0).map(d => d.titel)})),
  on(setLeermiddelVakKoppelingOpen, (state, {value}) =>
    ({...state,
      leermiddelVakKoppelingOpen: value,
      selectedLeermiddelVakKoppeling: null,
      gekoppeldeLeermiddelen: state.leermiddelVakKoppelingData.filter(d => d.vakken.length !== 0).map(d => d.titel)
    })),
  on(setLeermiddelVakKoppelingLoading, (state, {value}) => ({...state, leermiddelVakKoppelingLoading: value})),
  on(updateLeermiddelVakKoppelingData, (state, {value}) => {
    const data = state.leermiddelVakKoppelingData.filter(d => d.titel !== value.titel);
    const huidig = {...state.leermiddelVakKoppelingData.filter(d => d.titel === value.titel)[0]};
    huidig.vakken = value.vakken;
    data.push(huidig);
    return ({...state, leermiddelVakKoppelingData:
        data.sort((a, b) => a.titel.localeCompare(b.titel, undefined, {ignorePunctuation: true}))});
  }),
  on(setSelectedLeermiddelVakKoppeling, (state, {value}) => ({...state, selectedLeermiddelVakKoppeling: value})),
  on(reset, _ => initialState)
);
