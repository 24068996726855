import {createReducer, on} from '@ngrx/store';

import {SchooldashboardNewState} from './schooldashboard.state';
import {
  ongekoppeldeLeermiddelenFetched,
  ongekoppeldeLeermiddelenRegelsFetched,
  resetSchooldashboard,
  setBazenbanner, setSchooldashboardOnderwijssoortDetails,
  setSchooldashboardOnderwijssoorten,
  setSchooldashboardVakDetails,
  setSchooldashboardVakken
} from './schooldashboard.actions';
import {map} from 'rxjs/operators';

const initialState: SchooldashboardNewState = {
  bazenbanner: null,
  vakken: [],
  onderwijssoorten: [],
  ongekoppeldeLeermiddelen: null
};

export const schooldashboardNewReducer = createReducer(
    initialState,
    on(setSchooldashboardVakken, (state, {value}) => ({...state, vakken: value.map(v => ({header: v, content: null}))})),
    on(setSchooldashboardVakDetails, (state, {vakdetails, naam}) => ({
        ...state, vakken: state.vakken.map(v => {
          if (v.header.naam === naam) {
            return ({...v, content: vakdetails});
          }
          return v;
      })
    })
    ),

    on(setSchooldashboardOnderwijssoorten, (state, {value}) => ({...state,
      onderwijssoorten: value.map(v => ({header: v, content: null}))
    })),
    on(setSchooldashboardOnderwijssoortDetails, (state, {onderwijssoortdetails, naam}) => ({
      ...state, onderwijssoorten: state.onderwijssoorten.map(v => {
        if (v.header.naam === naam) {
          return ({...v, content: onderwijssoortdetails});
        }
        return v;
      })
    })
    ),

    on(setBazenbanner, (state, {value}) => ({...state, bazenbanner: value})),

    on(ongekoppeldeLeermiddelenFetched, (state, { ongekoppeldeLeermiddelen }) => ({ ...state, ongekoppeldeLeermiddelen: { header: ongekoppeldeLeermiddelen, content: null } })),
    on(ongekoppeldeLeermiddelenRegelsFetched, (state, { regels }) => ({ ...state, ongekoppeldeLeermiddelen: { header: state.ongekoppeldeLeermiddelen.header, content: regels } })),

    on(resetSchooldashboard, _ => initialState)
);
