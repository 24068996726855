import {Pipe, PipeTransform} from '@angular/core';
import {SchooldashboardDetailModel} from './schooldashboard.detail.model';
import {ONGECLASSIFICEERDE_METHODE, SchooldashboardModel, VAKOVERSTIJGEND} from './schooldashboard.model';
import {createColumnDef, createDefaultHeaderCellDef, createModel, TableModel} from '../../table/table/table.model';
import {KeyCellComponent} from '../../table/key-cell/key-cell.component';
import {JustifiedNumberCellComponent} from '../../table/justified-number-cell/justified-number-cell.component';
import {ActivatiegetalCellComponent} from '../../table/activatiegetal-cell/activatiegetal-cell.component';
import {HelpHeaderCellComponent} from '../../table/help-header-cell/help-header-cell.component';
import {RangebarCellComponent} from '../../table/rangebar-cell/rangebar-cell.component';
import {RangelinechartCellComponent} from '../../table/rangelinechart-cell/rangelinechart-cell.component';
import {ClickableRowIconCellComponent} from '../../table/clickable-row-icon-cell/clickable-row-icon-cell.component';
import {ModalwindowService} from '../../services/modalwindow.service';
import {RangebarCellModel} from '../../table/rangebar-cell/rangebar-cell.model';

@Pipe({name: 'schooldashboardTable'})
export class SchooldashboardTable implements PipeTransform {
  constructor(private modalwindowService: ModalwindowService) {}

  transform(
    data: SchooldashboardDetailModel[], parent: SchooldashboardModel, groeperenOpVak: boolean
  ): TableModel<SchooldashboardDetailModel> {
    const getRowId = (m: SchooldashboardDetailModel) => {
      if (m.parent.ean !== undefined) {
        return m.parent.ean;
      } else {
        return [m.parent.parent, m.parent.key].join('-');
      }
    };
    const model = createModel(data, getRowId);
    model.rowsClickable = (parent.key !== ONGECLASSIFICEERDE_METHODE);
    const key = createColumnDef<SchooldashboardDetailModel>('key');
    key.header.getValue = () => {
      let title;
      if (parent.key === VAKOVERSTIJGEND) {
        title = 'Leermiddelen';
      } else if (parent.key === ONGECLASSIFICEERDE_METHODE) {
        title = 'Methode';
      } else if (groeperenOpVak) {
        title = 'Niveau';
      } else {
        title = 'Vak';
      }
      return title;
    };
    key.body.style = {width: '15%'};
    key.body.component = KeyCellComponent;


    const aantal = createColumnDef<SchooldashboardDetailModel>('aantal');
    const parentKey = parent.key;
    aantal.header.getValue = () => {
      if(parentKey === VAKOVERSTIJGEND)
        return 'Opleidingen';
      else if(parentKey === ONGECLASSIFICEERDE_METHODE)
        return 'Aantal leerlingen';
      return 'Lesgroepen';
    }
    aantal.body.style = {width: '10%'};
    aantal.body.component = JustifiedNumberCellComponent;
    aantal.header.style = {textAlign: 'center'};

    const activatie = createColumnDef<SchooldashboardDetailModel>('activatie', 'Activatie');
    activatie.body.component = ActivatiegetalCellComponent;
    activatie.body.getValue = rowModel => rowModel.activatie;
    activatie.body.style = {width: '10%'};

    const actief = createColumnDef<SchooldashboardDetailModel>('actief');
    const actiefHulp = () => this.modalwindowService.toonOnderwerp('actief');
    actief.header = createDefaultHeaderCellDef('actief');
    actief.header.component = HelpHeaderCellComponent;
    actief.header.getValue = () => {
        return {title: 'Waarvan recent actief', help: actiefHulp};
      };
    actief.body = {
      component: RangebarCellComponent,
      getValue(rowModel: SchooldashboardDetailModel): RangebarCellModel {
        let minGebruik;
        let maxGebruik = 0;
        for (const gebruikEntry of rowModel.gebruik) {
          const minGebruikFromEntry = gebruikEntry.values.min;
          const maxGebruikFromEntry = gebruikEntry.values.max;
          if (minGebruikFromEntry < minGebruik) { minGebruik = minGebruikFromEntry; }
          if (maxGebruikFromEntry > maxGebruik) { maxGebruik = maxGebruikFromEntry; }
        }
        if (minGebruik === undefined) {
          minGebruik = 0;
        }
        return {model: rowModel.actief, hidden: rowModel.activatie.max === 0, minGebruikPerWeek: minGebruik, maxGebruikPerWeek: maxGebruik};
      },
      getRowspan(_: SchooldashboardDetailModel): number {
        return 1;
      },
      style: {width: '30%'}
    };

    const gebruik = createColumnDef<SchooldashboardDetailModel>('gebruik');
    const gebruiksHulp = () => this.modalwindowService.toonOnderwerp('gebruik');
    gebruik.header.component = HelpHeaderCellComponent;
    gebruik.header.getValue = () => ({title: 'Gebruik per leerling', help: gebruiksHulp});
    gebruik.body.component = RangelinechartCellComponent;
    gebruik.body.getValue = rowModel => ({model: rowModel.gebruik, totaal: rowModel.totaal, hidden: rowModel.activatie.max === 0});
    gebruik.body.style = {width: '30%'};

    const icon = createColumnDef<SchooldashboardDetailModel>('icon', '');
    icon.body.component = ClickableRowIconCellComponent;
    icon.body.getValue = () => undefined;
    icon.body.style = {width: '5%'};

    model.columnDefs = [key, aantal, activatie, actief, gebruik, icon];
    return model;
  }
}
