<div class="container">
  <div *ngIf="valid; else invalid"
       [class.show-percentages]="displayPercentages"
       (mousemove)="onMouseMove($event)" (mouseleave)="onMouseLeave()">
    <div class="left-percent">0</div>
    <div class="right-percent">100</div>
    <div class="avg" [style.left]="avgOffset | percent"></div>
    <div class="dot" [ngStyle]="dotStyle">&middot;</div>
    <div class="baseline">
      <div class="range" [style.left]="minOffset | percent" [style.width]="rangeWidth | percent"></div>
    </div>
    <div class="values">
      <div class="onderschrift">{{actualTooltip}}</div>
      <div class="gebruik">Tussen {{minOffset * 100 | number: '1.0-0'}} - {{maxOffset * 100 | number: '1.0-0'}} %</div>
    </div>
  </div>
  <ng-template #invalid>Ongeldige gegevens {{range | json}}.</ng-template>
</div>
