import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import {SchooldashboardNewFacade} from '../../state/schooldashboard/schooldashboard.facade';

@Component({
  selector: 'app-bazenbanner',
  templateUrl: './bazenbanner.component.html',
  styleUrls: ['./bazenbanner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BazenbannerComponent implements OnInit {

  constructor(public store: SchooldashboardNewFacade) { }

  ngOnInit(): void {

  }

}
