import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  LesgroepDetailDashboardFilterInput,
  LesgroepDetaildashboardGQL,
  LesgroepDetaildashboardMetSamenvattingGQL,
  LesgroepDetailSamenvattingDashboardGQL
} from '../../../generated/graphql';
import {KlasdashboardData, setKlasData} from '../state/schooldashboard/schooldashboard.actions';
import {catchError, filter, map, switchMap, take, withLatestFrom} from 'rxjs/operators';
import {AppState} from '../../state/app.state';
import {compose, Store} from '@ngrx/store';
import {payload} from '../../state/payload';
import {selectIsSamenvattingSelected, selectLesgroepDetailFilter} from '../state/klassendashboard/klassendashboard.selector';
import {rehydrateVestigingen} from '../../services/vestiging.service';
import {routerNavigatedAction} from '@ngrx/router-store';
import {vandaag} from '../../services/datumbereik';
import {getDashboardPath} from '../../state/router/router.selectors';
import {Observable, of, pipe} from 'rxjs';
import {apply, select} from '../../state/store.fn';
import {reportError} from '../../state/errors/errors.actions';
import {routingConstants} from '../../stateless/routing.constants';

@Injectable()
export class KlassendashboardEffect {
  $ = createEffect(() => this.actions$.pipe(
    ofType(routerNavigatedAction),
    filter(({payload: p}) => getDashboardPath(p.routerState.url) === routingConstants.KLASSEN_DASHBOARD_OLD),
    switchMap(
      compose(
        take(1),
        select<LesgroepDetailDashboardFilterInput>(this.store),
        apply<LesgroepDetailDashboardFilterInput, Date>(selectLesgroepDetailFilter),
        payload,
        vandaag
      )
    ),
    withLatestFrom(this.store.select(selectIsSamenvattingSelected)),
    map(([current, isSamenvatting]) => ({current, isSamenvatting, filterHasChanged: hasFilterChanged(this.prev, current)})),
    filter(({isSamenvatting, filterHasChanged}) => filterHasChanged || isSamenvatting),
    switchMap(({current, isSamenvatting, filterHasChanged}) => this.fetch(current, isSamenvatting, filterHasChanged)),
    map(pipe(payload, setKlasData)),
    catchError(err => of(err).pipe(map(payload), map(reportError)))
  ));

  private prev: LesgroepDetailDashboardFilterInput;

  constructor(private actions$: Actions,
              private store: Store<AppState>,
              private lesgroepDetaildashboardGQL: LesgroepDetaildashboardGQL,
              private lesgroepDetailSamenvattingDashboardGQL: LesgroepDetailSamenvattingDashboardGQL,
              private lesgroepDetaildashboardMetSamenvattingGQL: LesgroepDetaildashboardMetSamenvattingGQL) {
  }

  private fetch(f: LesgroepDetailDashboardFilterInput, isSamenvatting: boolean, filterHasChanged: boolean): Observable<KlasdashboardData> {
    let gql;
    if (isSamenvatting && !filterHasChanged) {
      gql = this.lesgroepDetailSamenvattingDashboardGQL;
    } else if (isSamenvatting && filterHasChanged) {
      gql = this.lesgroepDetaildashboardMetSamenvattingGQL;
    } else if (!isSamenvatting && filterHasChanged) {
      gql = this.lesgroepDetaildashboardGQL;
    } else {
      return of({vestigingen: []} as KlasdashboardData);
    }
    return gql.fetch({filter: f}).pipe(map(({data}) => ({
      vestigingen: rehydrateVestigingen(data.vestigingen),
      klasData: data.getDataPerLeerling,
      klasSamenvatting: data.getSamenvattingData
    })));
  }
}

export const hasFilterChanged = (prev: LesgroepDetailDashboardFilterInput = {}, current: LesgroepDetailDashboardFilterInput = {}) =>
  prev.lesgroepUUID !== current.lesgroepUUID || prev.vanaf !== prev.vanaf || prev.totEnMet !== current.totEnMet;
