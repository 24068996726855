<app-connect-loader [loading]="loading | async">
    <h1>Gekoppelde producten</h1>
    <button buttonstyle="infoButton" (click)="aanbodOpenen()">
        <div class="infoButtonText bodySmallContentSemi">Bekijk het volledige aanbod</div>
    </button>

    <div class="koppelpartijTable">
        <div class="regel placeholder" *ngIf="!(koppelpartijen$ | async).length">
            <div class="koppelpartij nohand">
                <p class="headingHead name">Er zijn nog geen koppelingen</p>
            </div>
        </div>
        <ng-container *ngFor="let koppelpartij of huidigeKoppelpartijen() | async;">
            <div class="regel" (click)="koppelingOpenen(koppelpartij.uuid)">
                <div class="koppelpartij">
                    <p class="headingHead name">{{koppelpartij.koppelpartijNaam}}</p>
                </div>
            </div>
        </ng-container>
        <ng-container *ngFor="let party of getConnectedSemParties() | async;">
          <div class="regel" (click)="semKoppelingOpenen(party.uuid)">
            <div class="koppelpartij">
              <p class="headingHead name">{{party.name}}</p>
              <p class="label labelLabelSemi">Vanaf {{party.consentSince}}</p>
            </div>
          </div>
        </ng-container>
        <ng-container *ngFor="let koppelpartij of toekomstigeKoppelpartijen() | async;">
          <div class="regel" (click)="koppelingOpenen(koppelpartij.uuid)">
            <div class="koppelpartij">
              <p class="headingHead name">{{koppelpartij.koppelpartijNaam}}</p>
              <p class="label labelLabelSemi">Vanaf {{geldigVanaf(koppelpartij)}}</p>
            </div>
          </div>
        </ng-container>
        <ng-container *ngFor="let party of getUnconnectedSemParties() | async;">
          <div class="regel" (click)="semKoppelingOpenen(party.uuid)">
            <div class="koppelpartij">
              <p class="headingHead name">{{party.name}}</p>
              <p class="label labelLabelSemi">Vanaf {{party.consentSince}}</p>
            </div>
          </div>
        </ng-container>
    </div>
</app-connect-loader>
