import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {ExportoverzichtFacade} from '../../../state/exportoverzicht/exportoverzicht.facade';
import {map, tap} from 'rxjs/operators';
import {AVGExportDetails} from '../../../../generated/avg-client';

@Component({
  selector: 'app-exportoverzicht-details',
  templateUrl: './exportoverzicht-details.component.html',
  styleUrls: ['./exportoverzicht-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportoverzichtDetailsComponent implements OnInit {
  @Input()
  public id: number;

  exportDetails$: Observable<AVGExportDetails>;

  constructor(private facade: ExportoverzichtFacade) {
  }

  ngOnInit(): void {
    this.exportDetails$ = this.facade.getExportDetails(this.id).pipe(
      tap((exportDetails) => {
        if (!exportDetails) {
          this.facade.fetchExportDetails(this.id);
        }
      }),
      map(exportDetails => {
        return exportDetails;
      })
    );
  }
}
