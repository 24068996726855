<div class="uitleg">
  <h2>{{status}}</h2>

  <div *ngIf="details" class="melding">
    Details:
    <p>{{details}}</p>
    <p>{{message}}</p>

    <button *ngIf="retryLoginButton" (click)="retryLogin()" buttonstyle="raised" color="blue">Stuur mij door</button>
  </div>
</div>
