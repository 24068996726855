import { Component } from '@angular/core';
import {TableCellComponent} from '../table/table.model';

@Component({
  selector: 'app-laatst-actief-met-icon-cell',
  templateUrl: './laatst-actief-met-icon-cell.component.html',
  styleUrls: ['./laatst-actief-met-icon-cell.component.scss']
})
export class LaatstActiefMetIconCellComponent implements TableCellComponent<string> {
  data: string;

}
