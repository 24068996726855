<app-connect-loader [loading]="(loading | async) && !initialLoadingFinished">
  <div class="contentbox">
    <app-klassendashboard-controls-header [viewmodel]="controlsViewmodel"></app-klassendashboard-controls-header>

    <div class="banner">
      <div class="titel">
        <img src="../../../assets/img/icons/Symbool/Klassendashboard_wit.svg" alt="Titel">
        <div class="tekst">{{ store.getLesgroepNaam() | async }}</div>
      </div>

      <div class="knoppen">
        <div class="individueel" (click)="onSelectieChanged('individueel')"
             [class.selected]="store.isIndividueelGeselecteerd() | async">
          Individueel
        </div>
        <div class="samenvatting" (click)="onSelectieChanged('samenvatting')"
             [class.selected]="store.isSamenvattingGeselecteerd() | async">
          Samenvatting
        </div>
      </div>
      <div class="box"></div>
    </div>
    <div class="tables">
      <app-connect-loader [loading]="(loading | async) && initialLoadingFinished">
        <app-klassendashboard-individueel-table *ngIf="store.isIndividueelGeselecteerd() | async"></app-klassendashboard-individueel-table>
        <app-klassendashboard-samenvatting *ngIf="store.isSamenvattingGeselecteerd() | async"></app-klassendashboard-samenvatting>
      </app-connect-loader>
    </div>
  </div>
</app-connect-loader>
