import {Pipe, PipeTransform} from '@angular/core';
import {createColumnDef, createModel, TableModel} from '../table/table/table.model';
import {Koppelpartij} from '../state/privacydashboard/privacydashboard.state';
import { ClickableRowIconCellComponent } from '../table/clickable-row-icon-cell/clickable-row-icon-cell.component';

@Pipe({name: 'beschikbareKoppelingen'})
export class BeschikbareKoppelingen implements PipeTransform {
  transform(value: Koppelpartij[]): TableModel<Koppelpartij> {
    const getRowId = start => _ => `id${start++}`;
    const model = createModel(value, getRowId(1));
    model.rowsClickable = true;
    model.dense = true;
    model.showHeaders = false;

    const naam = createColumnDef('displayName', ``);
    naam.body.getValue = (rowmodel: Koppelpartij) => rowmodel.koppelpartijNaam;

    const icon = createColumnDef('icon', '');
    icon.body.component = ClickableRowIconCellComponent;
    icon.body.style = {textAlign: 'right'};

    model.columnDefs = [naam, icon];
    return model;
  }
}
