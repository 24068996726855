import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable()
export class LeermiddelGebruikDataService {

  leermiddelgebruik$: Observable<Gebruiksaantallen>;

  private SERVICE_PATH = 'dashboard/leermiddelgebruik/';

  constructor(private http: HttpClient) {
    this.leermiddelgebruik$ = this.http.get<Gebruiksaantallen>
    (`${environment.apiUrl}${this.SERVICE_PATH}gebruiksaantallen`, {responseType: 'json'});
  }

  public getLeermiddelGebruik(): Observable<Gebruiksaantallen> {
    return this.leermiddelgebruik$;
  }
}

//Deze kwamen voorheen mee met het REST-contract. Ze werden gegenereerd maar na een opruimactie waarbij alle niet geïmplementeerde
// functionaliteit is verdwenen zijn deze classes komen te vervallen. Ik heb ze hier toegevoegd zodat we ze nog kunnen gebruiken op de
// style-guide, maar eigenlijk moet de style-guide worden aangepast zodat we de componenten die hiervan afhankelijk zijn niet meer
// gebruiken. Zie SC-975
export interface Gebruiksaantallen {
  aantallenPerOpleiding: OpleidingAantallen[];
}

export interface OpleidingAantallen {
  naam: string;
  aantallenPerLeermiddel: LeermiddelAantallen[];
}

export interface LeermiddelAantallen {
  naam: string;
  aantalLicenties: number;
  actiefGebruikt: number;
  geactiveerd: number;
}
