<div *ngIf="settings.isGegroepeerdOpVak() | async">
    <div *ngFor="let vak of (store.getVakken() | async)">
        <app-schooldashboard-table-row [data]="vak"></app-schooldashboard-table-row>
    </div>
</div>

<div *ngIf="settings.isGegroepeerdOpOnderwijssoort() | async">
    <div *ngFor="let os of (store.getOnderwijssoorten() | async)">
        <app-schooldashboard-table-row [data]="os"></app-schooldashboard-table-row>
    </div>
</div>

<app-schooldashboard-ongekoppeld-row>

</app-schooldashboard-ongekoppeld-row>