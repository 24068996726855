import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {AppState} from '../../state/app.state';
import {Store} from '@ngrx/store';
import {addMedewerkers, loadMedewerkers} from '../state/schooldashboard/schooldashboard.actions';
import {map, switchMap} from 'rxjs/operators';
import {fetchMedewerkers, SomtodayService} from '../../services/somtoday.service';
import {payload, unwrap} from '../../state/payload';

@Injectable()
export class MedewerkersEffect {
  loadMedewerkers$ = createEffect(() => this.actions$.pipe(
    ofType(loadMedewerkers),
    map(unwrap),
    switchMap(fetchMedewerkers(this.somtodayService)),
    map(payload),
    map(addMedewerkers)
    )
  );

  constructor(private actions$: Actions, private store: Store<AppState>, private somtodayService: SomtodayService) {
  }
}
