import { createSelector } from '@ngrx/store';
import { selectLeermiddelvakkoppelingNew } from '../app.state';

export const selectLeermiddelen = createSelector(
    selectLeermiddelvakkoppelingNew,
    (state) => state.leermiddelen
);

export const selectLeermiddelenZonderVak = createSelector(
    selectLeermiddelen,
    (state) => state.filter(lm => lm.gekoppeldAanVakken.length === 0).sort((a, b) => a.naam.localeCompare(b.naam))
);

export const selectLeermiddelenMetVak = createSelector(
    selectLeermiddelen,
    (state) => state.filter(lm => lm.gekoppeldAanVakken.length > 0).sort((a, b) => a.naam.localeCompare(b.naam))
);

export const selectLoading = createSelector(
    selectLeermiddelvakkoppelingNew,
    (state) => state.loading
);

export const selectOpen = createSelector(
    selectLeermiddelvakkoppelingNew,
    (state) => state.leermiddelVakKoppelingOpen
);

export const selectVestigingVakken = createSelector(
    selectLeermiddelvakkoppelingNew,
    (state) => state.vestigingVakken
);

export const selectSelectedLeermiddelVakKoppeling = createSelector(
  selectLeermiddelvakkoppelingNew,
  (state) => state.selectedLeermiddelVakKoppeling
);

