import {Component, Input} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-go-back-header',
  templateUrl: './go-back-header.component.html',
  styleUrls: ['./go-back-header.component.scss'],
})
export class GoBackHeaderComponent {
  @Input() public viewmodel: GoBackHeaderViewModel;

  constructor(private router: Router) {}

  terugNaarOverzicht(): void {
    this.router.navigateByUrl(this.viewmodel.backUrl);
  }
}

export interface GoBackHeaderViewModel {
  title: string;
  backUrl: string;
  backTitle: string;
}
