<div class="contentBox">
  <app-go-back-header [viewmodel]="goBackHeaderViewModel"></app-go-back-header>
  <div class="container">
      <app-koppeling-header
        [viewModel]="koppelingHeaderViewModel"
      ></app-koppeling-header>
      <div class="selectieVelden">
          <app-koppeling-aanmaken-stap1 *ngIf="(viewModel.onGetStap | async) === 1" [viewModel]="viewModel"></app-koppeling-aanmaken-stap1>
          <app-koppeling-aanmaken-stap2 *ngIf="(viewModel.onGetStap | async) === 2" [viewModel]="stap2ViewModel"></app-koppeling-aanmaken-stap2>
          <app-koppeling-aanmaken-stap3 *ngIf="(viewModel.onGetStap | async) === 3" [viewModel]="viewModel"></app-koppeling-aanmaken-stap3>
      </div>
      <app-bottombar [leftButton]="annulerenKnop" [rightButton]="volgendeKnop"></app-bottombar>
  </div>
</div>

<app-modal *ngIf="terugNaarOverzichtPopup" (closeModal)="terugNaarOverzichtPopupSluiten()">
    <div class="modal__header">
        <div class="header__icon svg_icon-warning"></div>
        <p class="header__title bodyContentBold">Let op, de koppeling is nog niet {{isBewerkenPage ? 'bewerkt' : 'aangemaakt'}}</p>
        <div class="close" (click)="terugNaarOverzichtPopupSluiten()"></div>
    </div>
    <div class="modal__body">
        <p class="body__text bodyContent">Weet je zeker dat je wilt stoppen met het {{isBewerkenPage ? 'bewerken' : 'aanmaken'}} van de koppeling? <br />
            Wijzigingen worden niet opgeslagen </p>
    </div>
    <div class="modal__buttons">
      <div class="button" (click)="jaIkWeetHetZekerTerugNaarOverzicht()">
        <p class="bodySmallContentSemi buttonCaption">Annuleer koppeling</p>
      </div>
      <div class="button button--success" (click)="terugNaarOverzichtPopupSluiten()">
          <p class="bodySmallContentSemi buttonCaption">Doorgaan met koppeling</p>
      </div>
    </div>
</app-modal>
