import {Component, OnInit, ChangeDetectionStrategy, Input, Output, OnDestroy} from '@angular/core';
import { EventEmitter } from '@angular/core';
import Quill from 'quill';
import {Observable, Subscription} from 'rxjs';

@Component({
  selector: 'app-connect-editor',
  templateUrl: './connect-editor.component.html',
  styleUrls: ['./connect-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConnectEditorComponent implements OnInit, OnDestroy {

  constructor() { }

  public editor: Quill;

  @Input() htmlText: Observable<string>;
  private subscription: Subscription;
  @Output() valueChange = new EventEmitter();

  ngOnInit(): void {
    this.editor = new Quill('#editor', {
      modules: { toolbar: '#toolbar' },
      theme: 'snow',
    });
    this.subscription = this.htmlText.subscribe((next) => {
      this.editor.root.innerHTML = '';
      this.editor.setContents(this.editor.clipboard.convert(next));
    });
    this.editor.root.addEventListener('blur', () => {this.sendEvent(); });
  }

  sendEvent(): void{
    if (this.editor.root.innerText.replaceAll('\n', '') === '') {
      this.valueChange.emit(null);
    } else {
      this.valueChange.emit(this.editor.root.innerHTML);
    }
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

}
