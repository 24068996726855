export interface Payload<T> {
  value: T;
}

export interface Payload2<T1, T2> extends Payload<T1> {
  value2: T2;
}

export const payload = <T>(value: T): Payload<T> => ({ value });

export const unwrap = <T>({value}: Payload<T>) => value;

export const payload2 = <T1, T2>(value: T1, value2: T2): Payload2<T1, T2> => ({value, value2});
