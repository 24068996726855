import {AppState} from './app.state';
import {ActionCreator, compose, Store} from '@ngrx/store';
import {payload, Payload} from './payload';
import {TypedAction} from '@ngrx/store/src/models';
import {vandaag} from '../services/datumbereik';
import {apply, Mapper, select, dispatch} from './store.fn';
import {Observable} from 'rxjs';
import {selectVestigingBevatLicenties} from '../old-dashboarding/state/schooldashboard/schooldashboard.selectors';
import {selectLoading} from './request-count/request-count.selectors';
import {selectHuidigePeriodeVanaf} from './app.selectors';

export class BaseFacade {
  constructor(private store: Store<AppState>) {
  }

  protected dispatch0(actionCreator: ActionCreator<any, () => TypedAction<any>>): void {
    this.store.dispatch(actionCreator());
  }

  protected dispatch<P>(actionCreator: ActionCreator<any, (props: Payload<P>) => (Payload<P> & TypedAction<any>)>, value: P): void {
    compose(dispatch(this.store), actionCreator, payload)(value);
  }

  protected dispatchDatumAction(actionCreator: ActionCreator<any, (props: Payload<Date>) => (Payload<Date> & TypedAction<any>)>): void {
    this.dispatch(actionCreator, vandaag());
  }

  protected select<T>(selector: (s: AppState) => T): Observable<T> {
    return this.store.select(selector);
  }

  protected selectWithDatumProp<T>(selector: Mapper<T, Date>): Observable<T> {
    return compose(select<T>(this.store), apply(selector), payload)(vandaag());
  }

  selectVestigingBevatLicenties(): Observable<boolean> {
    return this.select(selectVestigingBevatLicenties);
  }

  selectLoading(): Observable<boolean> {
    return this.select(selectLoading);
  }

  selectHuidigePeriodeVanaf(): Observable<Date> {
    return this.selectWithDatumProp(selectHuidigePeriodeVanaf);
  }

  selectHuidigePeriodeTotenmet(): Observable<Date> {
    return this.selectWithDatumProp(selectHuidigePeriodeVanaf);
  }
}
