import {Injectable} from '@angular/core';
import {BaseFacade} from '../base.facade';
import {Observable} from 'rxjs';
import {AppState, selectSchooldashboard} from '../app.state';
import {map} from 'rxjs/operators';
import {PlatformVak, SwLinkjesGebruikDetails, SwLinkjesPerPlatformOfVak} from '../../../generated/graphql';
import {Store} from '@ngrx/store';
import {selectBeschikbareKoppelingen} from '../privacydashboard/privacydashboard.selectors';
import { Koppeling, Koppelpartij } from '../privacydashboard/privacydashboard.state';

@Injectable()
export class ModalwindowFacade extends BaseFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }

  selectStudiewijzerlinksPlatformVak(): Observable<PlatformVak> {
    return this.select(selectSchooldashboard).pipe(
      map(v => v.studiewijzerPlatformVak),
      map(v => v && ({...v, getallenPerOnderwijssoort: v.getallenPerOnderwijssoort && [...v.getallenPerOnderwijssoort]}))
    );
  }

  selectBeschikbareKoppelingen(): Observable<Koppelpartij[]> {
      return this.select(selectBeschikbareKoppelingen);
  }
}
