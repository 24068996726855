<app-connect-loader [loading]="loading | async">
    <div class="contentBox">
        <app-go-back-header [viewmodel]="goBackHeaderViewModel"></app-go-back-header>
        <div class="container">
            <app-koppeling-header [viewModel]="headerViewModel">
                <app-koppeling-menu [viewModel]="menuViewModel"></app-koppeling-menu>
            </app-koppeling-header>
            <div class="paginaContent">
                <app-koppeling-omschrijving *ngIf="omschrijvingTab | async"></app-koppeling-omschrijving>
                <app-sem-koppeling-datatoegang *ngIf="datatoegangTab | async"></app-sem-koppeling-datatoegang>
            </div>
        </div>
    </div>

    <app-modal *ngIf="onGetShowModal | async" (closeModal)="closeKoppelingBeheerModal()">
        <div class="modal__header">
            <div class="my_icon"></div>
            <p class="my_title bodyContentBold">Koppeling beheren</p>
            <div class="my_close" (click)="closeKoppelingBeheerModal()"></div>
        </div>
        <div class="my_modal_body">
            <div *ngFor="let school of schools | async">
                <app-sem-koppeling-school-panel [viewModel]="school"></app-sem-koppeling-school-panel>
            </div>
        </div>
    </app-modal>
</app-connect-loader>