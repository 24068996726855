<app-connect-loader [loading]="loading | async">
  <div class="contentbox">
    <div class="headerrow">
      <app-terugknop [urlOmNaarTerugTeGaan]="goBack" [caption]="'Terug naar schooloverzicht'"></app-terugknop>
      <app-vak-onderwijs-switch *ngIf="vakData && vakData.nav"
                                [vorige]="vorigeUri" [volgende]="volgendeUri"
                                [hoofdNiveau]="hoofdNiveau" [subNiveau]="subNiveau">
      </app-vak-onderwijs-switch>
      <app-datumbereik [studiewijzerdb]="false"></app-datumbereik>
    </div>
    <app-blauwe-balk iconUrl="../../assets/img/icons/Symbool/vakdashboard-wit.svg">
      <ng-container *ngIf="vakData?.totaal">
        {{vakData.totaal.vak}} | {{vakData.totaal.onderwijssoort}}
      </ng-container>
    </app-blauwe-balk>
    <app-table *ngIf="vakData?.data?.length" [model]="vakData | vakdashboardTable" (rowClick)="navigeerNaarLesgroep($event)"></app-table>
  </div>
</app-connect-loader>
