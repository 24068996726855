<div *ngIf="(viewModel.onGetCategory | async)?.naam !== 'Schrijfpermissies' else schrijfpermissies" class="categorie">
    <p class="bodySmallContentSemi naam">{{(viewModel.onGetCategory | async)?.naam}}</p>
    <div class="velden">
        <div>
            <p *ngIf="(viewModel.onGetEndpoint | async).naam != 'Vestiging'" class="header-cell">O</p>
            <p class="header-cell">V</p>
        </div>
        <ng-container *ngFor="let veld of (viewModel.onGetCategory | async)?.velden;">
            <app-input-read-permission [viewModel]="viewModelForVeld(veld)"></app-input-read-permission>
        </ng-container>
    </div>
</div>
<ng-template #schrijfpermissies>
  <div class="categorie">
    <p class="bodySmallContentSemi naam">{{(viewModel.onGetCategory | async)?.naam}}</p>
    <div class="velden">
      <div>
        <p class="header-cell">V</p>
      </div>
      <ng-container *ngFor="let veld of (viewModel.onGetCategory | async)?.velden;">
        <app-input-write-permission [viewModel]="viewModelForVeld(veld)"></app-input-write-permission>
      </ng-container>
    </div>
  </div>
</ng-template>
