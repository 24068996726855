<div class="container">
  <div class="grid">
    <app-menu-left></app-menu-left>
    <app-sub-menu-left *ngIf="subMenu" [menu]="subMenu"></app-sub-menu-left>
    <app-header [class]="subMenu ? 'stickyHeader' : ''" [icon]="icon" [title]="title" [component]="component"
                [iconFont]="iconFont"></app-header>
    <div [class]="subMenu ? 'smallContent' : ''" class="content">
      <router-outlet></router-outlet>
    </div>
    <app-modalwindow [onderwerp]="modalWindowOnderwerp" *ngIf="toonHelp"></app-modalwindow>
    <app-leermiddelvakkoppeling *ngIf="leermiddelVakKoppelingOpen"></app-leermiddelvakkoppeling>
    <app-leermiddelvakkoppeling-new
      *ngIf="(leermiddelvakkoppelingNew.isOpen() | async)"></app-leermiddelvakkoppeling-new>
  </div>
  <ng-template [cdkPortalOutlet]="portal$ | async"></ng-template>
  <div class="overlay" [ngClass]="{'is-active': overlayActive}"></div>
</div>
