import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import moment, { Moment } from 'moment';
import { BehaviorSubject, combineLatest, Observable, of, Subscription, timer } from 'rxjs';
import { DashboardBackofficeFacade } from 'src/app/state/dashboard-backoffice/dashboard-backoffice.facade';
import { sort } from 'src/app/stateless/string';
import { BackofficeEtlTaskEvent, BackofficeEtlTaskExecution, BackofficeEtlTaskStep, BackofficeOrganization } from 'src/generated/backoffice-client';
import { BackofficeSchoolYear } from '../../../generated/backoffice-client/models/backoffice-school-year';
import { delay, distinct, filter, map, take, tap, withLatestFrom } from 'rxjs/operators';
import { currentLocalDate, formatDateTime } from 'src/app/stateless/moments';
import { SyncConfig } from 'src/app/services/dashboard-backoffice.service';

@Component({
    selector: 'app-backoffice-dashboard-new',
    templateUrl: './backoffice-dashboard-new.component.html',
    styleUrls: ['./backoffice-dashboard-new.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BackofficeDashboardNewComponent implements OnInit, OnDestroy {
    constructor(public facade: DashboardBackofficeFacade) { }

    public schoolYears: BehaviorSubject<{
        name?: string,
        include_from?: string,
        include_until?: string;
        editing: boolean;
    }[]> = new BehaviorSubject([]);

    public organizations: BehaviorSubject<BackofficeOrganization[]> = new BehaviorSubject([]);
    public syncs$: Observable<BackofficeEtlTaskExecution[]>;
    public syncsPages$: Observable<Page[]>;


    public suggestedStart: string;
    public suggestedEnd: string;

    public orgsSortKey: BehaviorSubject<string> = new BehaviorSubject('name');

    public syncsSortKey$: BehaviorSubject<string> = new BehaviorSubject('name');

    public customSyncPanelOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public customSyncPanelStyle$: Observable<string>;

    public customSyncUseRange$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    /** 
     * Het customSync panel bevat wat inputs die we soms verbergen met een ngIf. Dat breekt het
     * refereren naar template vars, dus we moeten de waarden van de inputs live bijhouden en
     * op het moment dat op de Start knop geklikt wordt hiervandaan oppikken.
     */

    public customSyncStart$: BehaviorSubject<string> = new BehaviorSubject<string>(currentLocalDate());

    public customSyncEnd$: BehaviorSubject<string> = new BehaviorSubject<string>('');

    public customSyncExtract$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    public customSyncTransform$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    public customSyncLoad$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    public customSyncRemark$: BehaviorSubject<string> = new BehaviorSubject<string>('');

    public customSyncSchoolyears$: BehaviorSubject<any> = new BehaviorSubject<any>({});

    public customSyncOrganizations$: BehaviorSubject<any> = new BehaviorSubject<any>({});

    public blockUntilFinished$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private cannotStartSyncWithoutOrgsRestrictionLifted: boolean = false;

    private subscriptions: Subscription[] = [];

    public namePairs$: Observable<NamePair[]>;

    public seperator: string = ',';

    public syncStatusPanelSubject$: Observable<BackofficeEtlTaskExecution>;

    public syncStatusSubjectSchoolYears$: Observable<BackofficeSchoolYear[]>;

    public syncStatusSubjectOrganizations$: Observable<BackofficeOrganization[]>;

    public syncStatusSubjectFailedOrgs$: Observable<BackofficeOrganization[]>;

    public syncStatusSubjectStepsLeft$: Observable<BackofficeEtlTaskStep[]>;

    public syncStatusSubjectLog$: Observable<BackofficeEtlTaskEvent[]>;
    
    public syncStatusPanelStyle$: Observable<string>;

    private schoolyearsEditing: string[] = [];

    @ViewChild('log') log: { nativeElement: { scrollTop: any; scrollHeight: any; clientHeight: any; }; };

    @ViewChild('log_bottom') logBottom: any;

    ngOnInit(): void {
        this.syncs$ = this.facade.syncs$;

        this.subscriptions.push(this.facade.schoolYears$.subscribe(arr => this.schoolYears.next(arr.map(sj => ({ ...sj, editing: false })))));
        this.subscriptions.push(timer(30000, 5000).subscribe(_ => this.facade.cleanUpFeedback()));
        this.subscriptions.push(combineLatest([this.facade.organizations$, this.orgsSortKey]).subscribe(([orgs, key]) => this.organizations.next(sort(orgs, o => o[key]))));

        this.subscriptions.push(this.syncsSortKey$.subscribe(column => this.facade.setSyncsSortColumn(column)));

        this.customSyncPanelStyle$ = this.customSyncPanelOpen$.pipe(map(open => ('backoffice-panel') + (open === true ? ' visible' : ' hidden')));

        this.syncsPages$ = combineLatest([this.facade.syncsCount$, this.facade.syncsCurrentPageNumber$, this.facade.syncsPageSize$]).pipe(
            map(this.mostImportantPageNumbers)
        );

        this.syncStatusPanelSubject$ = this.facade.selectedTask$;
        this.syncStatusSubjectSchoolYears$ = this.facade.selectedTaskSchoolYears$;
        this.syncStatusSubjectOrganizations$ = this.facade.selectedTaskOrganizations$;
        this.syncStatusSubjectFailedOrgs$ = this.facade.selectedTaskFailedOrgs$;
        this.syncStatusSubjectStepsLeft$ = this.facade.selectedTaskStepsLeft$;
        this.syncStatusSubjectLog$ = this.facade.selectedTaskLog$.pipe(map(lines => lines?.map(line => ({ ...line, style: 'log-line ' + (line.type === 'INFORMATION' ? 'inf' : line.type === 'WARNING' ? 'warn' : line.type === 'RECOVERABLE_ERROR' ? 'warn' : 'err') }))));

        this.syncStatusPanelStyle$ = this.syncStatusPanelSubject$.pipe(
            map(task => ('backoffice-panel wrapper-sync w1860') + (task === null || task === undefined ? ' hidden' : ' visible'))
        );

        this.subscriptions.push(this.syncStatusSubjectLog$.pipe(
            delay(10),
            map(_ => this.log.nativeElement.scrollHeight),
            distinct()
        ).subscribe(_ => {
            if(this.log && this.log.nativeElement)
            {
                this.log.nativeElement.scrollTop = this.log.nativeElement.scrollHeight;
            }
        }));

        this.subscriptions.push(this.customSyncOrganizations$.subscribe(orgs => {
            this.cannotStartSyncWithoutOrgsRestrictionLifted = (orgs === null || orgs === undefined || Object.keys(orgs).length === 0);
        }));

        this.setSyncsPage(0, true);
    }

    private mostImportantPageNumbers: (arg: [number, number, number]) => Page[] = ([totalCount, currentPage, pageSize]) => {
        const numberOfPages: number = Math.ceil(totalCount / pageSize);

        const pages: Page[] = [];

        if(numberOfPages > 1)
        {
            pages[0] = {title: 'Eerste', clickable: true, pageNumber: 0};
            for(let i = 1; i < numberOfPages - 1; i++) {
                pages.push({ title: '' + i, clickable: true, pageNumber: i });
            }
            pages.push({ title: 'Laatste', clickable: true, pageNumber: numberOfPages - 1 });
        }
        else {
            pages[0] = { title: 'Er is maar één page.', clickable: false, pageNumber: 0 };
        }

        return pages;
    }

    public defaultFromDate(stagedValues$: Observable<any>): Observable<string> {
        return stagedValues$.pipe(
            map(sv => sv ? sv.Naam.substring(0, 4) + '-08-01' : undefined)
        );        
    }

    public defaultUntilDate(stagedValues$: Observable<any>): Observable<string> {
        return stagedValues$.pipe(
            map(sv => sv ? sv.Naam.substring(5, 9) + '-07-31' : undefined)
        );        
    }

    public formatDateTime(dateTime: string): string {
        return formatDateTime(dateTime);
    }

    public kv(object: any, key: string) {
        if (key === 'edit')
            console.log(key + ': ' + JSON.stringify(object));
        return !!object ? object[key] : object;
    }

    stringify(o: any): string {
        return JSON.stringify(o);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    public editSchoolyear(row: BackofficeSchoolYear): void {
        this.schoolyearsEditing.push(row.name);
    }

    public saveNewSchoolYear(name: string, start: string, end: string): void {
        if (name === null || name === undefined || name === '') {
            this.facade.error('Naam van een Schooljaar kan niet leeg zijn!');
        } else if (name.indexOf('-') === -1) {
            this.facade.error('Schooljaarnaam moet in format YYYY-YYYY zijn. Bijvoorbeeld: 2020-2021');
        } else if (name.indexOf('/') >= 0) {
            this.facade.error('Er mag geen / in de Schooljaarnaam voorkomen!');
        } else if (this.schoolYears.value.find(sj => sj.name === name)) {
            this.facade.error('Schooljaar met die naam bestaat al!');
        } else {
            this.saveSchoolYear({ name, include_from: start, include_until: end });
        }
    }

    public saveSchoolYear({ name, include_from: start, include_until: end }): void {
        if (start === '' || end === '') {
            this.facade.error('Eerste en laatste syncmoment Schooljaar moeten gevuld zijn!');
        } else if (moment(start).isAfter(moment(end))) {
            this.facade.error('Eerste syncmoment Schooljaar kan niet na laatste syncmoment Schooljaar liggen!');
        } else {
            this.facade.saveSchoolYear({ name, include_from: start, include_until: end });
        }
    }

    public setOrganizationProtected(organizationUUID: string, isProtected: boolean): void {
        console.log(organizationUUID + ', ' + isProtected);
        this.facade.saveOrganization({ uuid: organizationUUID, protected: isProtected });
    }

    public suggestStartEnd(name: string): void {
        // TODO: defensive programming voor als schooljaarnaam poep is
        this.suggestedStart = name.substring(0, 4) + '-08-01';
        this.suggestedEnd = name.substring(5, 9) + '-07-31';
    }

    public sortOrgsBy(key: string): void {
        this.orgsSortKey.next(key);
    }

    public sortSyncsBy(key: string): void {
        this.syncsSortKey$.next(key);
    }

    public setSyncsPage(page: number, clickable: boolean): void {
        if(!clickable) return;

        this.facade.setSyncsPage(page);
    }

    public showTask(task: BackofficeEtlTaskExecution): void {
        this.facade.selectTask(task);
    }

    public toggleCustomETLPanel() {
        this.customSyncPanelOpen$.next(!this.customSyncPanelOpen$.value);
    }

    public toggleCustomDateRange(): void {
        this.customSyncUseRange$.next(!this.customSyncUseRange$.value);
    }

    public updateCustomSyncSchoolYears(schoolYear: BackofficeSchoolYear, useInSync: boolean): void {
        const schoolYears = { ...this.customSyncSchoolyears$.value };
        schoolYears[schoolYear.name] = useInSync;
        this.customSyncSchoolyears$.next(schoolYears);
    }

    public updateCustomSyncOrganizations(organization: BackofficeOrganization, useInSync: boolean): void {
        const organizations = { ...this.customSyncOrganizations$.value };
        organizations[organization.uuid] = useInSync;
        this.customSyncOrganizations$.next(organizations);
    }

    public startCustomSync(): void {
        const payload: SyncConfig = {
            start: this.customSyncStart$.value,
            end: this.customSyncEnd$.value,
            extract: this.customSyncExtract$.value,
            transform: this.customSyncTransform$.value,
            load: this.customSyncLoad$.value,
            remark: this.customSyncRemark$.value,
            schoolyears: Object.entries(this.customSyncSchoolyears$.value).filter(([_, v]) => v).map(([k]) => k),
            organizations: Object.entries(this.customSyncOrganizations$.value).filter(([_, v]) => v).map(([k]) => k),
            blockUntilFinished: this.blockUntilFinished$.value
        };

        let passes: boolean = true;
        if(payload.start === '') {
            passes = false;
            this.facade.error('Je moet een peildatum of begindatum opgeven voor de sync.');
        }
        if(payload.schoolyears.length === 0) {
            passes = false;
            this.facade.error('Je moet ten minste één schooljaar selecteren. Schooljaren zijn vooral belangrijk voor landelijke data.');
        }
        if(payload.organizations.length === 0 && !this.cannotStartSyncWithoutOrgsRestrictionLifted) {
            passes = false;
            this.facade.error('Je moet ten minste één organisatie selecteren.');
        }
        if(payload.remark === null || payload.remark === '') {
            passes = false;
            this.facade.error('Geef aub een reden op waarom je de sync start. (bijvoorbeeld ticket nummer of technische reden)');
        }
        else if(payload.remark.trim() === '') {
            passes = false;
            this.facade.error('Poging om stiekem toch een sync te starten zonder reden verijdeld.');
        }
        if(payload.extract === false && payload.transform === false && payload.load === false) {
            passes = false;
            this.facade.error('Het heeft geen zin om een sync te starten zonder E, T of L.');
        }
        if(payload.start !== '' && payload.end !== '' && payload.start !== payload.end && payload.extract !== false) {
            passes = false;
            this.facade.error('Je kan geen extraction draaien voor meerdere datums in één keer. Probeer het met alleen transformation en load.');
        }
        if(payload.start !== '' && payload.end !== '' && payload.start !== payload.end && payload.blockUntilFinished === true) {
            passes = false;
            this.facade.error('Je kan een sync niet synchroon uitvoeren als je een datumbereik opgeeft. Zet "Wacht tot klaar" uit.');
        }

        if(passes)
        {
            this.facade.startSync(payload);
            this.toggleCustomETLPanel();
        }
    }

    public startTL()
    {
        if(window.confirm('Dit start een Transform en Load run voor alle onbeschermde organisaties met vandaag als peildatum. Weet je het zeker?')) {
            const payload: SyncConfig = {
                start: currentLocalDate(),
                end: undefined,
                extract: false,
                transform: true,
                load: true,
                remark: 'Ad hoc Transform and Load for current date',
                schoolyears: undefined,
                organizations: undefined,
                blockUntilFinished: false
            };
            
            this.facade.startSync(payload);
        }
    }

    public pauzeerETL(): void 
    {
        this.facade.stop();
    }

    public hervatETL(): void 
    {
        this.facade.resume();
    }
}

export interface BackofficeDashboardNewModel {
    feedback$: Observable<Feedback>;
    schoolYears$: Observable<BackofficeSchoolYear[]>;
    organizations$: Observable<BackofficeOrganization[]>;
    syncs$: Observable<BackofficeEtlTaskExecution[]>;
    syncsPages$: Observable<number[]>;
    syncsCurrentPage$: Observable<number>;

    saveSchoolYear: (schoolYear: BackofficeSchoolYear) => void;
    setPage: (page: number) => void;
}

export interface Feedback {
    time: Moment;
    nature: 'error' | 'warning' | 'info';
    message: string;
}

export interface NamePair {
    veld: string;
    naam: string;
}

export interface Page {
    title: string;
    clickable: boolean;
    pageNumber: number;
}