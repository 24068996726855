<div class="container" *ngIf="bazenbannerModel">
  <div class="cell1">
    <span>Inzet digitale leermiddelen</span>
  </div>
  <div class="cell2">
    <div class="cijferblok">
      <span class="cijfergroot"><span class="cijferlicenties">{{ bazenbannerModel.licentiesPerLeerling.toFixed(1) }}</span></span>
      <span class="omschrijving">licenties per leerling</span>
    </div>
    <div class="cijferblok"> <div class="metmargin">
      <span class="cijfergroot"><span class="cijfergeactiveerd">{{ bazenbannerModel.aantalGeactiveerdeLicenties | minpercent }}</span></span>
      <span class="omschrijving">geactiveerde licenties</span>
    </div></div>
    <div class="cijferblok"> <div class="metmargin">
      <span class="cijfergroot">{{ bazenbannerModel.educatieveAanbieders }}</span>
      <span class="omschrijving">educatieve aanbieders</span>
    </div></div>
  </div>
  <div class="cell3">
    <app-enkel-activatiegraad-lesgroepen *ngIf="!(loading | async)" [procentGeactiveerd]="bazenbannerModel.lesgroepActivatieInProcenten"></app-enkel-activatiegraad-lesgroepen>
  </div>
  <div class="cell4">
    <app-range-linechart-voor-bazenbanner *ngIf="!(loading | async)" [model]="this.bazenbannerModel.linechartGebruik" [gemGebruik]="this.bazenbannerModel.gemActieveLeerlingenPerDag" [bgcolor]="bgcolor"></app-range-linechart-voor-bazenbanner>
  </div>
  <div class="cell5">
    <span>van de lesgroepen is wekelijks actief</span>
  </div>
</div>
