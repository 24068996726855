import {createReducer, on} from '@ngrx/store';

import {
  OnderwijssoortLeerjaarInzicht,
  StudiewijzerDashboardItem,
  StudiewijzerlinkjesState,
  StudiewijzerPlatformOfVakItem
} from './studiewijzerlinkjes.state';
import {
  resetStudiewijzerLinkjes,
  setStudiewijzerlinkjesAlgemeen,
  setStudiewijzerLinkjesOnderwijssoortLeerjaarInzicht,
  setStudiewijzerLinkjesPlatformDetails,
  setStudiewijzerlinkjesPlatformen,
  setStudiewijzerLinkjesVakDetails,
  setStudiewijzerlinkjesVakken
} from './studiewijzerlinkjes.actions';

const initialState: StudiewijzerlinkjesState = {
  totaalAantalLinks: null,
  totaalAantalLesgroepen: null,
  platformGebruik: [],
  vakGebruik: []
};

export const studiewijzerlinkjesReducer = createReducer(
    initialState,
    on(setStudiewijzerlinkjesAlgemeen, (state, {totaalAantalLinks, totaalAantalLesgroepen}) => ({...state, totaalAantalLinks, totaalAantalLesgroepen})),

    on(setStudiewijzerlinkjesPlatformen, (state, {platformGebruik}) => ({...state, platformGebruik})),
    on(setStudiewijzerLinkjesPlatformDetails, (state, {details, naam}) => ({
      ...state,
      platformGebruik: insertItemDetails(naam, state.platformGebruik, details)
    })),

    on(setStudiewijzerlinkjesVakken, (state, {vakGebruik}) => ({...state, vakGebruik })),
    on(setStudiewijzerLinkjesVakDetails, (state, {details, naam}) => ({
      ...state,
      vakGebruik: insertItemDetails(naam, state.vakGebruik, details)
    })),

    on(setStudiewijzerLinkjesOnderwijssoortLeerjaarInzicht, (state, {onderwijssoortLeerjaarInzicht, vakNaam, platformNaam}) => ({
      ...state,
      vakGebruik: insertOnderwijssoortLeerjaarInzicht(vakNaam, platformNaam, state.vakGebruik, onderwijssoortLeerjaarInzicht),
      platformGebruik: insertOnderwijssoortLeerjaarInzicht(platformNaam, vakNaam, state.platformGebruik, onderwijssoortLeerjaarInzicht),
    })),

    on(resetStudiewijzerLinkjes, _ => initialState)
);

function insertItemDetails(naamItem: string, items: StudiewijzerDashboardItem[], details: StudiewijzerPlatformOfVakItem[]): StudiewijzerDashboardItem[] {
  return items.map(item => {
      if (item.naam === naamItem) {
        item.details = details;
      }
      return item;
    });
}

function insertOnderwijssoortLeerjaarInzicht(naamItem: string, naamDetails: string, items: StudiewijzerDashboardItem[], onderwijssoortLeerjaarInzicht: OnderwijssoortLeerjaarInzicht[]): StudiewijzerDashboardItem[] {
  return items.map(item => {
    if (item.naam === naamItem) {
      item.details = item.details.map(detail => {
        if (detail.naam === naamDetails) {
          detail.onderwijssoortLeerjaarInzicht = onderwijssoortLeerjaarInzicht;
        }
        return detail;
      });
    }
    return item;
  });
}
