import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../app.state';
import {
  selectDatumBereik,
  selectDatumBereiken,
  selectExportDetailsById,
  selectExports,
  selectFilter,
  selectFilterBegindatum,
  selectFilterEinddatum,
  selectLoadingState,
  selectMedewerkerNamen,
  selectSidebarList,
  selectSidebarOpenState,
  selectExportsPage,
  selectAantalExports,
} from './exportoverzicht.selectors';
import {combineLatest, Observable} from 'rxjs';
import {
  closeSidebarRequested,
  fetchExportDetails,
  openSidebarRequested,
  resetFilter,
  setDatumBereik,
  setDatumBereiken,
  setFilterMedewerker,
  setPage
} from './exportoverzicht.actions';
import {AVGExport, AVGExportDetails} from 'src/generated/avg-client';
import {map} from 'rxjs/operators';
import {distinct} from '../../stateless/array-util';
import {Datumbereik} from '../dashboardsettings/dashboardsettings.state';
import {MedewerkerNaam} from './exportoverzicht.state';

@Injectable()
export class ExportoverzichtFacade {
  constructor(public store: Store<AppState>) {
  }

  // **************************
  // ACTIONS
  // **************************
  public setDatumBereiken(datumBereiken: Datumbereik[]): void {
    this.store.dispatch(setDatumBereiken({datumBereiken}));
  }

  public setDatumbereik(datumBereik: Datumbereik): void {
    this.store.dispatch(setDatumBereik({datumBereik}));
    this.resetPage();
  }

  public resetPage(): void {
    this.store.dispatch(setPage({page: 1}));
  }

  public resetFilter(): void {
    this.store.dispatch(resetFilter());
  }

  public setFilterMedewerker(medewerker: string): void {
    this.store.dispatch(setFilterMedewerker({medewerker}));
    this.resetPage();
  }

  public setPage(page: number): void {
    this.store.dispatch(setPage({page}));
  }

  public fetchExportDetails(id): void {
    return this.store.dispatch(fetchExportDetails({id}));
  }

  public openSidebarRequested(list): void {
    return this.store.dispatch(openSidebarRequested({list}));
  }

  public closeSidebarRequested(): void {
    return this.store.dispatch(closeSidebarRequested());
  }

  // **************************
  // SELECTORS
  // **************************
  public getFilterBegindatum(): Observable<string> {
    return this.store.select(selectFilterBegindatum);
  }

  public getFilterEinddatum(): Observable<string> {
    return this.store.select(selectFilterEinddatum);
  }

  public getFilterMedewerker(): Observable<MedewerkerNaam> {
    return this.store.select(selectFilter).pipe(map(f => f.medewerker));
  }

  public getExports(): Observable<AVGExport[]> {
    return this.store.select(selectExports);
  }

  public getAantalExports(): Observable<number> {
    return this.store.select(selectAantalExports);
  }

  public getPage(): Observable<number> {
    return this.store.select(selectExportsPage);
  }

  public getFilteredExports(): Observable<AVGExport[]> {
    return combineLatest([this.store.select(selectExports), this.getFilterMedewerker()]).pipe(
      map(([exports, medewerker]) => exports?.filter(e => !medewerker || e.medewerker === medewerker.naam)),
    );
  }

  public getExportDetails(id): Observable<AVGExportDetails | undefined> {
    return this.store.select(selectExportDetailsById(id));
  }

  public getSidebarOpenState(): Observable<boolean> {
    return this.store.select(selectSidebarOpenState);
  }

  public getLoadingState(): Observable<boolean> {
    return this.store.select(selectLoadingState);
  }

  public getSidebarList(): Observable<string[]> {
    return this.store.select(selectSidebarList);
  }

  public getMedewerkers(): Observable<string[]> {
    return this.store.select(selectMedewerkerNamen).pipe(
      map(medewerkers => medewerkers?.map(mw => mw?.naam).filter(distinct()))
    );
  }

  public getMedewerkerNamenNotPresent(uuids: string[]): Observable<string[]> {
    return this.store.select(selectMedewerkerNamen).pipe(
      map(namen => uuids.filter(uuid => namen.find(naam => naam.uuid === uuid) === undefined))
    );
  }

  public getDatumbereik(): Observable<Datumbereik> {
    return this.store.select(selectDatumBereik);
  }

  public getDatumbereiken(): Observable<Datumbereik[]> {
    return this.store.select(selectDatumBereiken);
  }
}
