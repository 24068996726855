<div [class]="this.isContentOpen ? 'row open' : 'row closed'">
    <div class="header" (click)="toggleOpen()">
        <div class="title">Ongekoppelde leermiddelen</div>
        <div class="numbers">
            <div>{{(header$ | async)?.aantalLeermiddelen}} leermiddelen</div>
            <div>{{(header$ | async)?.aantalLicenties}} licenties</div>
            <div>{{(header$ | async)?.percentageActieveLicenties.toFixed(0)}}% geactiveerde licenties</div>
            <div>{{(header$ | async)?.gemiddeldGebruikPerWeek.toFixed(1)}} keer p/w</div>
            <div><i [class]="this.isContentOpen ? 'icon-arrow-close' : 'icon-arrow-open'"></i></div>
        </div>
    </div>
    <div class="content" [@openContent]="this.isContentOpen ? 'open' : 'closed'">
        <table>
            <tr>
                <th>Leermiddel</th>
                <th>Licenties</th>
                <th>Onderwijssoorten</th>
                <th>Activatie</th>
                <th>Waarvan recent actief <i class="icon-vraagteken" (click)="openActiefHelp()"></i></th>
                <th>Gebruik per licentie <i class="icon-vraagteken" (click)="openGebruikHelp()"></i></th>
            </tr>
            <tr *ngFor="let row of (regels$ | async)">
                <td class="max-150"><span class="max-2-lines">{{row.naam}}</span></td>
                <td class="extra-padding-left">{{row.aantalLicenties}}</td>
                <td class="extra-padding-left">{{row.aantalOpleidingen}}</td>
                <td><app-activatiegetal [range]="createActivatiegetalModel(row)"></app-activatiegetal></td>
                <td><app-range-bar-new [range]="createRangeBarModel(row)"></app-range-bar-new></td>
                <td><app-range-linechart-keybased-new
                        [viewModel]="createRowLinechartViewModel(row)"></app-range-linechart-keybased-new></td>
                <td></td>
            </tr>
        </table>
    </div>
</div>