import {Component, OnDestroy, OnInit} from '@angular/core';
import {LeermiddelData} from '../state/vakloosleermiddeldashboard/vakloosleermiddeldashboard.selectors';
import {VakloosleermiddeldashboardFacade} from '../state/vakloosleermiddeldashboard/vakloosleermiddeldashboard.facade';
import {Subscription} from 'rxjs';
import {UrlObject} from 'query-string';
import {Params} from '@angular/router';
import {routingConstants} from '../../stateless/routing.constants';

@Component({
  selector: 'app-vakloosleermiddeldashboard',
  templateUrl: './vakloosleermiddeldashboard.component.html',
  styleUrls: ['./vakloosleermiddeldashboard.component.scss']
})
export class VakloosleermiddeldashboardComponent implements OnInit, OnDestroy {
  leermiddelData: LeermiddelData;
  leermiddel: string;
  private subscriptions: Subscription[];

  loading = this.store.selectLoading();

  constructor(
    private store: VakloosleermiddeldashboardFacade) {
  }

  ngOnInit() {
    this.subscriptions = [
      this.store.selectLeermiddelData().subscribe(v => {
        this.leermiddel = v?.totaal?.leermiddel || ' ';
        this.leermiddelData = v;
      })
    ];
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  truncateTitel(titel: string): string {
    if (titel.length > 40) {
      titel = titel.substring(0, 36) + '...';
    }
    return titel;
  }

  goBack(_: string, __: Params, query: Params): UrlObject {
    return {
      url: routingConstants.SCHOOL_DASHBOARD_OLD,
      query
    };
  }
}
