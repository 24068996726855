import {Component, OnInit, Input, ViewChild, OnDestroy} from '@angular/core';
import {FONT_FAMILY} from '../../shared/fonts';
import {SwLinkjesPerPlatformOfVak} from '../../../generated/graphql';
import {Observable, Subscription} from 'rxjs';
import {GoogleChartComponent, GoogleChartInterface} from 'ng2-google-charts';
import {StudiewijzerKleuren} from '../studiewijzer-kleuren';
import {SwLinkjesPerPlatformOfVakModel} from '../../old-dashboarding/state/studiewijzerlinkjes/studiewijzerlinkjes.selectors';

@Component({
  selector: 'app-studiewijzer-donut',
  templateUrl: './studiewijzer-donut.component.html',
  styleUrls: ['./studiewijzer-donut.component.scss']
})
export class StudiewijzerDonutComponent implements OnInit, OnDestroy {
  @ViewChild(GoogleChartComponent)
  public donut: GoogleChartComponent;

  @Input()
  public viewModel: StudiewijzerDonutViewModel;

  public totaalAantalLinks = 0;

  public totaalAantalLesgroepen = 0;

  private subscriptions: Subscription[] = [];

  public titel: string;

  constructor() { }

  public donutData: GoogleChartInterface = {
    chartType: 'PieChart',
    dataTable: {
      cols: [
          {label: 'platform', type: 'string'},
          {label: 'gebruik', type: 'number'}
      ],
      rows: []
    },
    options: {
      backgroundColor: { fill: 'transparent' },
      chartArea: {
        width: '100%',
        height: '100%',
        bottom: 0,
        left: 14,
        right: 33,
        top: 0
      },
      enableInteractivity: false,
      fontName: FONT_FAMILY,
      height: 300,
      width: 217,
      legend: {position: 'none'},
      pieHole: 0.6,
      pieSliceText: 'none',
      tooltip: {trigger: 'none'},
      slices: []
    }
  };

  ngOnInit(): void {
    this.subscriptions.push(this.viewModel.onGetData.subscribe(data => {
      let totaal = 0;
      let overigAandeel = 0;
      data.forEach(platform => {
        totaal += platform.naam === 'Overig' ? 0 : platform.links;
        if (platform.overig) {
          overigAandeel += platform.links;
        }
      });
      this.subscriptions.push(this.viewModel.onGetGroeperingOpPlatform.subscribe(groeperingIsPlatform => this.titel = groeperingIsPlatform ? 'platform' : 'vak'));

      this.totaalAantalLinks = totaal;

      // donut data vullen met alleen de platformen die niet tot Overig behoren en Overig zelf.
      const kleuren = StudiewijzerKleuren.iterate();
      this.donutData.dataTable.rows = [];
      this.donutData.options.slices = [];
      for (const platform of data) {
       if (!platform.overig && platform.naam !== 'Overig') {
          this.donutData.dataTable.rows.push({c: [{v: platform.naam}, {v: platform.links / totaal}]});
          (this.donutData.options as any).slices.push({color: kleuren.next()});
       }
       if (platform.naam === 'Overig') {
            this.donutData.dataTable.rows.push({c: [{v: platform.naam}, {v: overigAandeel / totaal}]});
            (this.donutData.options as any).slices.push({color: kleuren.last()});
       }
      }

      if (this.donut && this.donut.wrapper && data.length !== 0) {
        this.donut.draw();
      }
    }));
    this.subscriptions.push(this.viewModel.onGetAantalLesgroepen
      .subscribe(aantalLesgroepen => this.totaalAantalLesgroepen = aantalLesgroepen));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

export interface StudiewijzerDonutViewModel {
  onGetData: Observable<SwLinkjesPerPlatformOfVakModel[]>;
  onGetAantalLesgroepen: Observable<number>;
  onGetGroeperingOpPlatform: Observable<boolean>;
}
