import {Component, Input, OnInit} from '@angular/core';
import {FONT_FAMILY} from '../../shared/fonts';
import {
  accent_alt_1,
  accent_negative_1,
  accent_positive_2,
  background_1,
  background_2,
  background_3,
  secondary_1,
  typography_3
} from '../../shared/colors';
import * as moment from 'moment';
import {GoogleChartInterface} from 'ng2-google-charts';
import {LesgroepLeermiddelGebruikGrafiekData} from '../state/klassendashboard/klassendashboard.selector';
import {map} from 'rxjs/operators';
import {KlassendashboardFacade} from '../state/klassendashboard/klassendashboard.facade';

@Component({
  selector: 'app-lesgroep-detail-leermiddel-gebruik-grafiek',
  templateUrl: './lesgroep-detail-leermiddel-gebruik-grafiek.component.html',
  styleUrls: ['./lesgroep-detail-leermiddel-gebruik-grafiek.component.scss']
})
export class LesgroepDetailLeermiddelGebruikGrafiekComponent implements OnInit {
  public data;

  notLoading = this.store.selectLoading().pipe(map(v => !v));

  @Input()
  vanaf: Date;

  @Input()
  totenmet: Date;

  @Input()
  set model(input: LesgroepLeermiddelGebruikGrafiekData[]) {
    this.data = undefined;

    if (input !== null && input !== undefined) {
      this.datasetsVullen(input);

      if (this.chartData.component && this.chartData.component.wrapper &&
        this.weekendData.component && this.weekendData.component.wrapper) {
          this.chartData.component.draw();
          this.weekendData.component.draw();
      }
    }
  }

  margins: [number, number];

  chartData: GoogleChartInterface = {
    chartType: 'LineChart',
    dataTable: {
      cols: [
        {type: 'date', label: 'datum'}
      ],
      rows: []
    },
    options: {
      hAxis: {
        gridlines: {
          color: 'none'
        },
        format: 'dd/MM'
      },
      vAxis: {
        minValue: 0,
        maxValue: 100,
        gridlines: {
          color: background_3,
          multiple: 25
        },
        baseline: {
          color: background_2
        },
        textPosition: 'out',
        format: '#\'%\''
      },
      chartArea: {
        width: '100%',
        height: '100%',
        bottom: 50,
        left: 60,
        right: 25,
        top: 20
      },
      backgroundColor: { fill: 'transparent' },
      legend: {position: 'bottom', textStyle: {color: 'black', fontSize: 16}},
      fontName: FONT_FAMILY,
      colors: [accent_positive_2, background_1, typography_3, accent_negative_1, secondary_1, accent_alt_1],
      height: 300,
      tooltip: {}
    }
  };

  weekendData: GoogleChartInterface = {
    chartType: 'SteppedAreaChart',
    dataTable: {
      cols: [
        {type: 'date', label: 'datum'},
        {type: 'number', label: 'weekend'}
      ],
      rows: []
    },
    options: {
      hAxis: {
        gridlines: {
            color: 'none'
          },
        textPosition: 'none'
      },
      vAxis: {
        minValue: 0,
        maxValue: 1,
        gridlines: {
          color: 'none'
        },
        textPosition: 'none'
      },
      chartArea: {
        width: '100%',
        height: '100%',
        bottom: 50,
        left: 60,
        right: 25,
        top: 20
      },
      backgroundColor: { fill: 'transparent' },
      legend: 'none',
      fontName: FONT_FAMILY,
      colors: ['#F1F6FA'],
      height: 300
    }
  };

  constructor(
    private store: KlassendashboardFacade
  ) { }

  private datasetsVullen(input: LesgroepLeermiddelGebruikGrafiekData[]): void {
    const weekends = [];
    const ticks = [];
    const data = new Map<Date, Map<string, number>>();
    const alleLeermiddelen = new Set<string>();

    for (const datapunt of input) {
      let dag = null;
      for (const bestaandeDag of data.keys()) {
        if (bestaandeDag.valueOf() === datapunt.datum.valueOf()) {
          dag = data.get(bestaandeDag);
        }
      }

      if (dag === null) {
        dag = new Map<string, number>();
        data.set(datapunt.datum, dag);
        addWeekendDag(datapunt.datum, weekends);
        ticks.push(datapunt.datum);
      }

      dag.set(datapunt.leermiddel, datapunt.gebruikspercentage);
      alleLeermiddelen.add(datapunt.leermiddel);
    }

    // sorteren, anders krijgen we een kinderkleurplaat als grafiek.
    const sortedData = new Map([...data].sort((a, b) => {
      return a[0].valueOf() - b[0].valueOf();
    }));

    const weekendsSorted = weekends.sort((a, b) => {
      return a.datum.valueOf() - b.datum.valueOf();
    });

    const table = [];
    for (const dag of sortedData.keys()) {
      const waardenVoorDieDag = sortedData.get(dag);
      const row = [];
      row.push({v: dag});
      for (const leermiddel of alleLeermiddelen) {
        let waarde = waardenVoorDieDag.get(leermiddel);
        if (waarde === null || waarde === undefined) {
          waarde = 0;
        }
        row.push({v: waarde, f: waarde.toFixed(1) + '%'});
      }
      table.push({c: row});
    }
    this.chartData.dataTable.rows = table;
    (this.chartData.options as any).hAxis.ticks = ticks;

    this.chartData.dataTable.cols = [
      {type: 'date', label: 'datum'}
    ];
    for (const leermiddel of alleLeermiddelen) {
      this.chartData.dataTable.cols.push({type: 'number', label: leermiddel});
    }

    if (weekendsSorted.length > 0) {
      // Laatste datapunt van weekend array valt buiten domein van de grafiek dus die moeten we strippen.
      weekendsSorted.pop();
    }

    const weekendDataRows = [];
    for (const dag of weekendsSorted) {
      weekendDataRows.push({c: [{v: dag.datum}, {v: dag.isWeekend}]});
    }
    this.weekendData.dataTable.rows = weekendDataRows;

    this.data = 1;
  }

  onReady() {
    const cli = this.chartData.component.wrapper.getChart().getChartLayoutInterface();
    const {width} = cli.getChartAreaBoundingBox();
    this.margins = [
        cli.getXLocation(this.vanaf as any),
        width - cli.getXLocation(this.totenmet as any)
      ];
  }

  onWeekendReady() {
    const cli = this.weekendData.component.wrapper.getChart().getChartLayoutInterface();
    const {width} = cli.getChartAreaBoundingBox();
    this.margins = [
        cli.getXLocation(this.vanaf as any),
        width - cli.getXLocation(this.totenmet as any)
      ];
  }

  ngOnInit(): void {
    window.addEventListener('resize', () => {
      if (this.chartData.component) {
        this.chartData.component.draw();
      }
      if (this.weekendData.component) {
        this.weekendData.component.draw();
      }
    });
  }
}

function addWeekendDag(dag: Date, weekends: WeekendData[]): WeekendData {
  weekends.push({datum: dag, isWeekend: (dag.getDay() === 6 || dag.getDay() === 0)});
  const shiftyDate = moment(dag).add(12, 'hours').toDate();
  const weekendToAdd = {
    datum: shiftyDate,
    isWeekend: (shiftyDate.getDay() === 6 || shiftyDate.getDay() === 0)
  };
  weekends.push(weekendToAdd);
  return weekendToAdd;
}

export interface WeekendData {
    datum: Date;
    isWeekend: boolean;
}
