import {Component, Input, OnChanges} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Gebruiksaantallen} from '../../services/leermiddelgebruik.data-service';

@Component({
  selector: 'app-leermiddeltabel',
  templateUrl: './leermiddeltabel.component.html',
  styleUrls: ['./leermiddeltabel.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class LeermiddeltabelComponent implements OnChanges {
  displayedColumns: string[] = ['naam', 'aantalLicenties', 'actiefGebruikt', 'geactiveerd', 'activatiegraad'];
  dataSource;
  @Input() gebruik: Gebruiksaantallen;

  toRows(aantallen: Gebruiksaantallen) {
    const parents: Row[] = new Array();
    const totaal: TotaalRow = new TotaalRow();
    if (this.gebruik != null) {
      aantallen.aantallenPerOpleiding.forEach(gebruikPerOpleiding => {
        const children: LeermiddelRow[] = new Array();
        gebruikPerOpleiding.aantallenPerLeermiddel.forEach(gebruikPerLeermiddel => {
          children.push(new LeermiddelRow(gebruikPerLeermiddel.naam, gebruikPerLeermiddel.aantalLicenties,
            gebruikPerLeermiddel.actiefGebruikt, gebruikPerLeermiddel.geactiveerd));
        });
        parents.push(new ParentRow(gebruikPerOpleiding.naam, children));
      });
      parents.forEach(parentRow => totaal.updateTotalen(parentRow as ParentRow));
      parents.unshift(totaal);
      this.dataSource = parents;
    }
  }

  ngOnChanges(): void {
    this.toRows(this.gebruik);
  }
}

export interface Row {
  getNaam(): string;

  getAantalLicenties(): number;

  getActiefGebruikt(): number;

  getGeactiveerd(): number;

  getActivatieGraad(): number[];

  getChildren(): Row[];

  isExpanded(): boolean;

  toggle(): void;
}

export class TotaalRow implements Row {
  naam = 'TOTAAL';
  aantalLicenties = 0;
  actiefGebruik = 0;
  Geactiveerd = 0;
  activatiegraad: number[];

  updateTotalen(parentRow: ParentRow) {
    this.aantalLicenties += parentRow.getAantalLicenties();
    this.actiefGebruik += parentRow.getActiefGebruikt();
    this.Geactiveerd += parentRow.getGeactiveerd();
    this.activatiegraad = new Array(0, 0);
    this.activatiegraad[0] += parentRow.getAantalLicenties();
    this.activatiegraad[1] += parentRow.getGeactiveerd();
  }

  getAantalLicenties(): number {
    return this.aantalLicenties;
  }

  getActiefGebruikt(): number {
    return this.actiefGebruik;
  }

  getActivatieGraad(): number[] {
    return this.activatiegraad;
  }

  getChildren(): Row[] {
    return [];
  }

  getGeactiveerd(): number {
    return this.Geactiveerd;
  }

  getNaam(): string {
    return this.naam;
  }

  isExpanded(): boolean {
    return false;
  }

  toggle(): void {
  }

}

export class ParentRow implements Row {
  naam: string;
  children: LeermiddelRow[];
  activatiegraad: number[];
  expanded = false;

  constructor(naam: string, children: LeermiddelRow[]) {
    this.naam = naam;
    this.children = children;
    this.activatiegraad = new Array(2);
    this.activatiegraad[0] = this.getAantalLicenties();
    this.activatiegraad[1] = this.getGeactiveerd();
  }

  isExpanded(): boolean {
    return this.expanded;
  }

  toggle(): void {
    this.expanded = !this.expanded;
  }

  getNaam(): string {
    return this.naam;
  }

  getAantalLicenties() {
    let aantalLicenties = 0;
    for (const child of this.children) {
      aantalLicenties += child.getAantalLicenties();
    }
    return aantalLicenties;
  }

  getActiefGebruikt(): number {
    let actiefGebruikt = 0;
    for (const child of this.children) {
      actiefGebruikt += child.getActiefGebruikt();
    }
    return actiefGebruikt;
  }

  getActivatieGraad(): number[] {
    return this.activatiegraad;
  }

  getChildren(): Row[] {
    return this.children;
  }

  getGeactiveerd(): number {
    let geactiveerd = 0;
    for (const child of this.children) {
      geactiveerd += child.getGeactiveerd();
    }
    return geactiveerd;
  }
}

export class LeermiddelRow implements Row {
  naam: string;
  aantalLicenties: number;
  actiefGebruikt: number;
  geactiveerd: number;
  activatiegraad: number[];

  constructor(naam: string, aantalLicenties: number, actiefGebruikt: number, geactiveerd: number) {
    this.naam = naam;
    this.aantalLicenties = aantalLicenties;
    this.actiefGebruikt = actiefGebruikt;
    this.geactiveerd = geactiveerd;
    this.activatiegraad = new Array(2);
    this.activatiegraad[0] = aantalLicenties;
    this.activatiegraad[1] = geactiveerd;
  }

  getAantalLicenties(): number {
    return this.aantalLicenties;
  }

  getActiefGebruikt(): number {
    return this.actiefGebruikt;
  }

  getActivatieGraad(): number[] {
    return this.activatiegraad;
  }

  getChildren(): Row[] {
    return [];
  }

  getGeactiveerd(): number {
    return this.geactiveerd;
  }

  getNaam(): string {
    return this.naam;
  }

  isExpanded(): boolean {
    return false;
  }

  toggle(): void {
  }
}
