<ul class="key-list key-list--columns-{{numberOfColumns}}">
  <li
    class="key-list__item"
    *ngFor="let item of slicedList"
    matTooltip="{{item}}"
  >
      <span
        class="key-list__key"
      >
      {{item}}
      </span>
  </li>
  <li *ngIf="moreKeysAmount" class="key-list__item--more">
    <button click-stop-propagation type="button" class="key-list__button" (click)="openSidebarRequested()">
      Bekijk alle (+{{moreKeysAmount}})
    </button>
  </li>
</ul>
