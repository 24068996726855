import {Component, OnInit, ChangeDetectionStrategy, OnDestroy} from '@angular/core';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState} from '../../state/app.state';
import {
  Endpoint,
  ExtendedKoppeling,
  GeselecteerdeKoppelpartij,
  Veld
} from '../../state/privacydashboard/privacydashboard.state';
import {
  selectGecategoriseerdeVeldPermissies,
  selectGeselecteerdeKoppelingen,
  selectGeselecteerdeKoppelpartij,
  selectGeselecteerdeKoppelpartijAlgemeen,
} from '../../state/privacydashboard/privacydashboard.selectors';
import { localDateStringToDisplayDateString } from '../../stateless/moments';
import {VeldProperty} from '../koppeling-aanmaken-stap2/koppeling-aanmaken-stap2.component';
import {AVGExtendedKoppelpartij} from '../../dto/avg-dashboard-model-classes';
import {filter, map, withLatestFrom} from 'rxjs/operators';
import moment from 'moment';

@Component({
  selector: 'app-koppeling-datatoegang',
  templateUrl: './koppeling-datatoegang.component.html',
  styleUrls: ['./koppeling-datatoegang.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KoppelingDatatoegangComponent implements OnInit, OnDestroy {
  public koppelingAvailable: Observable<boolean>;
  public koppelingenAvailable: Observable<boolean>;
  public gelijkeKoppelingen: Observable<boolean>;

  public somethingChanged$: Observable<GeselecteerdeKoppelpartij>;
  public koppelpartij$: Observable<AVGExtendedKoppelpartij>;
  public koppelingen$: Observable<ExtendedKoppeling[]>;

  public koppelingInformatie: KoppelingInformatie = {
    lastSync: null,
    beginDatum: null,
    eindDatum: null,
    createdAt: null,
    createdBy: null,
    modifiedAt: null,
    modifiedBy: null,

    aantalVestigingen: null,
    vestigingen: null,
    toekomstigeVestigingen: null,
    aantalLeerlingen: null,
    onderwijsSoorten: null,
    leerjaren: null,
    vakken: null,
    aantalIndividueleLeerlingen: null,
    leerlingen: null,

    veldpermissies: null,
  };

  public infoBlok: Observable<string>;

  private opengeklapteEndpoints = [];
  private opengeklapteLabels = [];

  private subscriptions = [];

  constructor(private appState: Store<AppState>) { }

  ngOnInit(): void {
    this.somethingChanged$ = this.appState.select(selectGeselecteerdeKoppelpartijAlgemeen);
    this.koppelpartij$ = this.appState.select(selectGeselecteerdeKoppelpartij);
    this.koppelingen$ = this.appState.select(selectGeselecteerdeKoppelingen);
    this.koppelingAvailable = this.koppelingen$.pipe(map(k => k?.length > 0));
    this.koppelingenAvailable = this.koppelingen$.pipe(map(k => k?.length > 1));
    this.gelijkeKoppelingen = this.koppelingen$.pipe(
      withLatestFrom(this.koppelingAvailable),
      map(([koppelingen, available]) => {
        return (available) ? koppelingen.find(k => k.permissieHash !== koppelingen[0].permissieHash) === undefined : false;
      }
    ));

    this.infoBlok = this.somethingChanged$.pipe(
      withLatestFrom(this.koppelpartij$, this.koppelingen$, this.gelijkeKoppelingen),
      map(([, koppelpartij, koppelingen, gelijkeKoppelingen]) => {
        if (koppelpartij && (!koppelingen || koppelingen?.length === 0)) {
          return 'De toegang tot data is vrij instelbaar door jullie school. Onderstaande gegevens tonen de standaard dataset van deze applicatie';
        } else if ((koppelingen?.length > 0) && !gelijkeKoppelingen) {
          return 'Hieronder staat een samenvatting van de gegevens van alle vestigingen, dit kan per vestiging verschillen';
        } else {
          return null;
        }
      }));

    this.koppelingInformatie.lastSync = this.koppelingen$.pipe(
      withLatestFrom(this.koppelingAvailable, this.koppelingenAvailable),
      filter(([, oneAvailable, ]) => oneAvailable),
      map(([koppelingen, , multipleAvailable]) => {
        if (multipleAvailable) {
          const lastSyncList = koppelingen.filter(i => i.lastSync);
          switch (lastSyncList.length) {
            case 0:
              return null;
            case 1:
              return localDateStringToDisplayDateString(lastSyncList[0].lastSync);
            default:
              return localDateStringToDisplayDateString(lastSyncList.reduce((a, b) => a.lastSync > b.lastSync && a.lastSync ? a : b).lastSync);
          }
        } else {
          return (koppelingen[0].lastSync) ? localDateStringToDisplayDateString(koppelingen[0].lastSync) : null;
        }
      }));

    this.koppelingInformatie.beginDatum = this.koppelingen$.pipe(
      withLatestFrom(this.koppelingAvailable, this.koppelingenAvailable),
      filter(([, oneAvailable, ]) => oneAvailable),
      map(([koppelingen, , multipleAvailable]) => {
        if (multipleAvailable) {
          return null;
        } else {
          return 'van ' + localDateStringToDisplayDateString(koppelingen[0].begindatum);
        }
      }));

    this.koppelingInformatie.eindDatum = this.koppelingen$.pipe(
      withLatestFrom(this.koppelingAvailable, this.koppelingenAvailable),
      filter(([, oneAvailable, ]) => oneAvailable),
      map(([koppelingen, , multipleAvailable]) => {
        if (multipleAvailable) {
          return null;
        } else {
          return koppelingen[0].einddatum ? 'tot ' + localDateStringToDisplayDateString(koppelingen[0].einddatum) : '(geen einddatum)';
        }
      }));

    this.koppelingInformatie.createdAt = this.koppelingen$.pipe(
      withLatestFrom(this.koppelingAvailable, this.koppelingenAvailable),
      filter(([, oneAvailable, ]) => oneAvailable),
      map(([koppelingen, , multipleAvailable]) => {
        if (multipleAvailable) {
          const created = koppelingen.reduce((a, b) => a.createdAt > b.createdAt ? a : b);
          return localDateStringToDisplayDateString(created.createdAt);
        } else {
          return localDateStringToDisplayDateString(koppelingen[0].createdAt);
        }
      }));

    this.koppelingInformatie.createdBy = this.koppelingen$.pipe(
      withLatestFrom(this.koppelingAvailable, this.koppelingenAvailable),
      filter(([, oneAvailable, ]) => oneAvailable),
      map(([koppelingen, , multipleAvailable]) => {
        if (multipleAvailable) {
          const created = koppelingen.reduce((a, b) => a.createdAt > b.createdAt ? a : b);
          return created.createdBy;
        } else {
          return koppelingen[0].createdBy;
        }
      }));

    this.koppelingInformatie.modifiedAt = this.koppelingen$.pipe(
      withLatestFrom(this.koppelingAvailable, this.koppelingenAvailable),
      filter(([, oneAvailable, ]) => oneAvailable),
      map(([koppelingen, , multipleAvailable]) => {
          if (multipleAvailable) {
            const created = koppelingen.reduce((a, b) => a.createdAt > b.createdAt ? a : b);
            const modified = koppelingen.reduce((a, b) => a.modifiedAt > b.modifiedAt ? a : b);
            return (created.createdAt !== modified.modifiedAt) ? localDateStringToDisplayDateString(created.modifiedAt) : null;
          } else {
            return (koppelingen[0].createdAt !== koppelingen[0].modifiedAt) ? localDateStringToDisplayDateString(koppelingen[0].modifiedAt) : null;
          }
        }));

    this.koppelingInformatie.modifiedBy = this.koppelingen$.pipe(
      withLatestFrom(this.koppelingAvailable, this.koppelingenAvailable),
      filter(([, oneAvailable, ]) => oneAvailable),
      map(([koppelingen, , multipleAvailable]) => {
        if (multipleAvailable) {
          const created = koppelingen.reduce((a, b) => a.createdAt > b.createdAt ? a : b);
          const modified = koppelingen.reduce((a, b) => a.modifiedAt > b.modifiedAt ? a : b);
          return (created.createdAt !== modified.modifiedAt) ? modified.modifiedBy : null;
        } else {
          return (koppelingen[0].createdAt !== koppelingen[0].modifiedAt) ? koppelingen[0].modifiedBy : null;
        }
      }));

    this.koppelingInformatie.vestigingen = this.koppelingen$.pipe(
      withLatestFrom(this.koppelingAvailable),
      filter(([_, oneAvailable]) => oneAvailable),
      map(([koppelingen, _]) => {
          return koppelingen.filter(k => moment(k.begindatum) <= moment()).map(k => k.vestiging.naam).join(', ');
      }));

    this.koppelingInformatie.toekomstigeVestigingen = this.koppelingen$.pipe(
      withLatestFrom(this.koppelingAvailable),
      filter(([_, oneAvailable]) => oneAvailable),
      map(([koppelingen, _]) => {
        const ungroupedKoppelingen = koppelingen.filter(k => moment(k.begindatum) > moment()).map(k => ({namen: k.vestiging.naam, begindatum: k.begindatum.split('-').reverse().join('-')}));
        const groupedKoppelingen = [];
        for (const koppeling of ungroupedKoppelingen) {
          const foundGroup = groupedKoppelingen.find(k => k.begindatum === koppeling.begindatum);
          if (foundGroup) {
            foundGroup.namen = foundGroup.namen + ', ' + koppeling.namen;
          } else {
            groupedKoppelingen.push(koppeling);
          }
        }
        return groupedKoppelingen.sort((a, b) => a.begindatum > b.begindatum ? 1 : -1);
      }));

    this.koppelingInformatie.aantalVestigingen = this.koppelingen$.pipe(
      withLatestFrom(this.koppelingAvailable, this.koppelingenAvailable),
      filter(([, oneAvailable, ]) => oneAvailable),
      map(([koppelingen, , multipleAvailable]) => {
        if (multipleAvailable) {
          return koppelingen.length + ' vestigingen';
        } else {
          return '1 vestiging';
        }
      }));

    this.koppelingInformatie.aantalLeerlingen = this.koppelingen$.pipe(
      withLatestFrom(this.koppelingAvailable, this.koppelingenAvailable),
      filter(([, oneAvailable, ]) => oneAvailable),
      withLatestFrom(this.gelijkeKoppelingen),
      map(([[koppelingen, , multipleAvailable], gelijkeKoppelingen]) => {
        if ((multipleAvailable && gelijkeKoppelingen) || !multipleAvailable) {
          return (!koppelingen[0].leerjaren.length && !koppelingen[0].onderwijssoortAfkortingen.length && !koppelingen[0].vakUUIDs.length)
            ? 'alle leerlingen'
            : 'een deel van de leerlingen';
        } else {
          return 'de gegevens van leerlingen';
        }
      }));

    this.koppelingInformatie.onderwijsSoorten = this.koppelingen$.pipe(
      withLatestFrom(this.koppelingAvailable, this.koppelingenAvailable),
      filter(([, oneAvailable, ]) => oneAvailable),
      withLatestFrom(this.gelijkeKoppelingen),
      map(([[koppelingen, , multipleAvailable], gelijkeKoppelingen]) => {
        if ((multipleAvailable && gelijkeKoppelingen) || !multipleAvailable) {
          return (koppelingen[0].onderwijssoortNamen.length) ? koppelingen[0].onderwijssoortNamen.join(', ') : 'Alle onderwijssoorten';
        } else {
          return null;
        }
      }));

    this.koppelingInformatie.leerjaren = this.koppelingen$.pipe(
      withLatestFrom(this.koppelingAvailable, this.koppelingenAvailable),
      filter(([, oneAvailable, ]) => oneAvailable),
      withLatestFrom(this.gelijkeKoppelingen),
      map(([[koppelingen, , multipleAvailable], gelijkeKoppelingen]) => {
        if ((multipleAvailable && gelijkeKoppelingen) || !multipleAvailable) {
          return (koppelingen[0].leerjaren.length) ? 'Leerjaar ' + koppelingen[0].leerjaren.join(', ') : 'Alle leerjaren';
        } else {
          return null;
        }
      }));

    this.koppelingInformatie.vakken = this.koppelingen$.pipe(
      withLatestFrom(this.koppelingAvailable, this.koppelingenAvailable),
      filter(([, oneAvailable, ]) => oneAvailable),
      withLatestFrom(this.gelijkeKoppelingen),
      map(([[koppelingen, , multipleAvailable], gelijkeKoppelingen]) => {
        if ((multipleAvailable && gelijkeKoppelingen) || !multipleAvailable) {
          return (koppelingen[0].vakNamen.length) ? koppelingen[0].vakNamen.join(', ') : 'Alle vakken';
        } else {
          return null;
        }
      }));

    this.koppelingInformatie.aantalIndividueleLeerlingen = this.koppelingen$.pipe(
      withLatestFrom(this.koppelpartij$),
      filter(([_, koppelpartij]) => koppelpartij.opLeerlingNiveauGeautoriseerd),
      map(([koppelingen, _]) => {
        return [...koppelingen.filter(k => k.leerlingen.length > 0).map(k => k.leerlingen)].length;
      })
    );

    this.koppelingInformatie.leerlingen = this.koppelingen$.pipe(
      withLatestFrom(this.koppelpartij$),
      filter(([_, koppelpartij]) => koppelpartij.opLeerlingNiveauGeautoriseerd),
      map(([koppelingen, _]) => {
        const leerlingen = [...koppelingen.filter(k => k.leerlingen.length > 0).map(k => k.leerlingen)];
        return (leerlingen.length ? leerlingen.join(', ') : 'Er zijn nog geen leerlingen die toestemming hebben gegeven');
      })
    );

    this.koppelingInformatie.veldpermissies = this.appState.select(selectGecategoriseerdeVeldPermissies, {getEmptyFields: false});
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  public toggleLabel(label: HTMLElement): void {
    if (this.opengeklapteLabels.includes(label)) {
      this.opengeklapteLabels = this.opengeklapteLabels.filter(n => n !== label);
    } else {
      this.opengeklapteLabels.push(label);
    }
  }

  public toggleEndpoint(endpoint: Endpoint): void {
    if (this.opengeklapteEndpoints.includes(endpoint.naam)) {
      this.opengeklapteEndpoints = this.opengeklapteEndpoints.filter(n => n !== endpoint.naam);
    } else {
      this.opengeklapteEndpoints.push(endpoint.naam);
    }
  }

  public endpointClass(endpoint: Endpoint, index: number): string {
    return (index % 2 !== 0 ? 'endpoint' : 'endpoint uneven') + (this.opengeklapteEndpoints.includes(endpoint.naam) ? ' open' : '');
  }

  public labelClass(label: HTMLElement): string {
    return this.opengeklapteLabels.includes(label) ? 'open' : '';
  }

  public labelText(label: HTMLElement): string {
    return this.opengeklapteLabels.includes(label) ? 'Minder details' : 'Meer details';
  }
  public isLabelOpengeklapt(label: HTMLElement): boolean {
    return this.opengeklapteLabels.includes(label);
  }

  public veldIconClass(veld: Veld): string {
    return veld.verplicht ? 'icon dot' : 'icon icon-add';
  }

  public propertyClass(prop: VeldProperty): string {
    switch (prop.type) {
      case 'warning':
        return 'remark warning';
      case 'write':
        return 'remark write';
      default:
        return 'remark';
    }
  }

}

export interface KoppelingInformatie {
  lastSync: Observable<string>;
  beginDatum: Observable<string>;
  eindDatum: Observable<string>;
  createdAt: Observable<string>;
  createdBy: Observable<string>;
  modifiedAt: Observable<string>;
  modifiedBy: Observable<string>;

  aantalVestigingen: Observable<string>;
  vestigingen: Observable<string>;
  toekomstigeVestigingen: Observable<{namen: string, begindatum: string}[]>;
  aantalLeerlingen: Observable<string>;
  onderwijsSoorten: Observable<string>;
  leerjaren: Observable<string>;
  vakken: Observable<string>;
  aantalIndividueleLeerlingen: Observable<number>;
  leerlingen: Observable<string>;

  veldpermissies: Observable<Endpoint[]>;
}
