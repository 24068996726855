import { Component, OnInit } from '@angular/core';
import {WidgetBaseComponent} from '../widget-base/widget-base.component';
import { ChartType, ChartOptions } from 'chart.js';
import {SingleDataSet, Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip} from 'ng2-charts';
import {primary_3, secondary_1, typography_2, accent_positive_2, accent_negative_1} from '../../../shared/colors';

@Component({
  selector: 'app-widget-piechart',
  templateUrl: './widget-piechart.component.html',
  styleUrls: ['../widget-base/widget-base.component.scss', './widget-piechart.component.scss']
})
export class WidgetPiechartComponent extends WidgetBaseComponent<number> implements OnInit {

  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      display: true
    }
  };
  public pieChartLabels: Label[];
  public pieChartData: SingleDataSet;
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [];
  public pieChartColors = [{backgroundColor: [secondary_1, primary_3, accent_positive_2, accent_negative_1, typography_2]}];

  constructor() {
    super();
  }

  ngOnInit() {
    this.pieChartLabels = Array.from(this.widgetDataHolder.data.keys());
    this.pieChartData = Array.from(this.widgetDataHolder.data.values());
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
  }

}
