<p>Deze pagina dient als styleguide voor de Somtoday Connect-applicatie.</p>
<div class='widgets'>
  <div class="widgetContainer">
    <div class='widgetinfo'>
      <h2>Cirkeldiagram (meerdere onderdelen)</h2>
      <span>Deze widget wordt gebruikt om voor een leermiddel informatie over de activatie en het gebruik daarvan te
        laten zien.
        Groen staat voor 'geactiveerd en actief gebruikt', geel voor 'geactiveerd maar niet of weinig gebruikt', en rood
        voor 'niet geactiveerd'.
        Deze widget verwacht als input een leermiddelinfo-object met daarin de naam van het leermiddel, en de absolute
        getallen voor
        'geactiveerd en actief gebruikt', 'geactiveerd maar niet of weinig gebruikt' en 'niet geactiveerd'
      </span>
      <p>
        <button buttonstyle="flat" class="save-button" color="blue" (click)="veranderDonut1()">Random!</button>
      </p>
    </div>
    <app-enkel-leermiddel-activatie-of-gebruik [leermiddelinfo]="leermiddelinfoDonut1">
    </app-enkel-leermiddel-activatie-of-gebruik>
  </div>
  <div class="widgetContainer">
    <div class='widgetinfo'>
      <h2>Cirkeldiagram (twee onderdelen)</h2>
      <span>Deze widget wordt gebruikt om voor een leermiddel informatie over de activatiegraad te laten zien. Onder het
        percentage staat het absolute aantal leermiddelen.
        Deze widget verwacht als input een leermiddelinfo-object met daarin de naam van het leermiddel, en de absolute
        getallen voor
        'geactiveerd' en 'niet geactiveerd'. De widget bepaald zelf welke kleur de donut is op basis van de
        activatiegraad:
      </span>
      <p>
        <button buttonstyle="flat" class="save-button" color="red" (click)="veranderActivatie('rood')">Onder de 33%
        </button>
        <br />
        <button buttonstyle="flat" class="save-button" color="orange" (click)="veranderActivatie('geel')">Tussen de 33%
          en 66%
        </button>
        <br />
        <button buttonstyle="flat" class="save-button" color="green" (click)="veranderActivatie('groen')">Boven de 66%
        </button>
        <br />
        <button buttonstyle="flat" class="save-button" color="blue" (click)="veranderActivatie('random')">Random!
        </button>
      </p>
    </div>
    <app-enkel-leermiddel-activatiegraad [leermiddelinfo]="leermiddelinfo"></app-enkel-leermiddel-activatiegraad>
  </div>
  <div class="widgetContainer">
    <div class='widgetinfo'>
      <h2>Staafdiagram</h2>
      <span>Deze widget wordt bijvoorbeeld gebruikt om per uitgever informatie over de activatiegraad in klassen te
        laten zien.
        Deze widget verwacht als input een progress-barinfo-object met daarin de naam van de uitgever, twee absolute
        getallen om de bar te vullen,
        en eventueel hetgeen dat in het midden moet staan (bv. 6/8 klassen). De widget bepaald zelf welke kleur de bar
        is:
      </span>
      <p>
        <button buttonstyle="flat" class="save-button" color="red" (click)="veranderBar('rood')">Onder de 33%</button>
        <br />
        <button buttonstyle="flat" class="save-button" color="orange" (click)="veranderBar('geel')">Tussen de 33% en
          66%
        </button>
        <br />
        <button buttonstyle="flat" class="save-button" color="green" (click)="veranderBar('groen')">Boven de 66%
        </button>
        <br />
        <button buttonstyle="flat" class="save-button" color="blue" (click)="veranderBar('random')">Random!</button>
      </p>
    </div>
    <app-progress-bar-activatiegraad class="progress-bar-activatiegraad" [progressbarinfo]="progressbarinfo">
    </app-progress-bar-activatiegraad>
  </div>
  <div class="widgetContainer">
    <div class="widgetinfo">
      <h2>Kengetal</h2>
      <span>Deze widget wordt gebruikt om kengetallen weer te geven. Het component krijgt als input een array van
        kengetallen.
        Een kengetal bestaat uit een label en een aantal. Dat aantal kan een enkel getal zijn, of twee getallen waaruit
        een
        percentage wordt berekend.
      </span>
      <p>
        <button buttonstyle="flat" class="save-button" color="blue" (click)="veranderKengetallen()">Random!</button>
      </p>
    </div>
    <div>
      <app-kengetaloverzicht [kengetallen]="this.fakeKengetal"></app-kengetaloverzicht>
    </div>
    <div class="multiplecolumnspan">
      <app-kengetaloverzicht [kengetallen]="this.fakeKengetallen"></app-kengetaloverzicht>
    </div>
  </div>
  <div class="widgetContainer">
    <div class="widgetinfo">
      <h1>Lijndiagram</h1>
      <p>
        Dit lijndiagram toont procentuele waarden van een reeks categorieën.<br>
        De waarden in de tabel moeten tussen de 0 en 1 liggen. Het component maakt er dan procenten van.<br>
        De eerste cel van de eerste rij is de titel van de x-as. In de volgende cellen staan de labels van de reeksen
        die in de legenda worden getoond. Vervolgens betaat elke rij uit de van de categorie en de waarden op dat punt
        voor elke reeks.
      </p>
      <p>
        <button buttonstyle="flat" class="save-button" color="blue" (click)="veranderGLineChart()">Random!</button>
      </p>
    </div>
    <app-line-chart [model]="lineChartSubject"></app-line-chart>
  </div>
  <div class="multiplecolumnspan">
    <h1>Leermiddelentabel</h1>
    <p>
      Deze tabel kan gevuld worden met diverse data, zoals een overzicht van leermiddelgebruik per opleiding, groep of
      vak. <br>
    </p>
    <app-leermiddeltabel [gebruik]="gebruik"></app-leermiddeltabel>
  </div>
  <div class="widgetContainer">
    <div class="widgetinfo">
      <h2>Datumbereik</h2>
      <p>Kies uit een voorgedefinieerd aantal datumbereiken.</p>
    </div>
    <app-datumbereik [studiewijzerdb]="false"></app-datumbereik>
  </div>
  <div class="widgetContainer">
    <div class="widgetinfo">
      <h2>Range bar</h2>
      <p>Component om met een balk en een pijltje te laten zien: gemiddelde, min en max waarde.</p>
      <p>
        <button buttonstyle="flat" class="save-button" color="blue" (click)="veranderRangeBarmodel()">Random!</button>
      </p>
    </div>
    <app-range-bar [range]="rangeBarModel"></app-range-bar>
  </div>
  <div class="widgetContainer">
    <div class="widgetinfo">
      <h2>Activatiegetal</h2>
      <p>Component dat een percentage laat zien: gemiddelde, min en max waarde.</p>
      <p>
        <button buttonstyle="flat" class="save-button" color="blue" (click)="veranderRangeBarmodel()">Random!</button>
      </p>
    </div>
    <app-activatiegetal [range]="rangeBarModel"></app-activatiegetal>
  </div>
  <div class="widgetContainer">
    <div class="widgetinfo">
      <h2>Lijndiagram met range</h2>
      <p>Lijndiagram met een lijn voor de gemiddelde waarde en een gekleurd achtergrondvlak om de range te laten zien.
      </p>
    </div>
    <!-- Uitgecomment omdat er iets met de avg misgaat. TODO: uitzoeken wat er mis is en fixen -->
    <!--<app-range-linechart [model]="rangeLineChartModel" interval="d" [bgcolor]="bgcolor"></app-range-linechart>-->
  </div>
  <div class="multiplecolumnspan">
    <h1>Leermiddeloverzicht</h1>
    <p>
      Banner met daarin een overzicht van leermiddelactivatie en -gebruik. <br>
    </p>
    <app-leermiddeloverzicht></app-leermiddeloverzicht>
  </div>
  <div class="widgetContainer">
    <p>Kleuren van de studiewijzer in de volgorde waarin ze voorkomen in de donut en het overzicht:</p>
    <app-studiewijzer-kleuren-demo></app-studiewijzer-kleuren-demo>
  </div>
  <div class="iconsAnchor">
    <p>Divje om het uitserveren van dynamische icons te forceren</p>
    <div class="icon-leerlingen-groen"></div>
  </div>
</div>
<div class="barchart-container">
  <h1>Webservicegebruik</h1>
  <app-multi-range-week-bar-chart [viewModel]="multiRangeWeekBarChartViewModel"></app-multi-range-week-bar-chart>
  <p>
    <button buttonstyle="flat" class="save-button" color="blue" (click)="volgendeBarChart()">Volgende/random vulling</button>
    <button buttonstyle="flat" class="save-button" color="blue" (click)="vorigeBarChart()">Vorige</button>
  </p>
</div>

<div>
  <app-input-toggle [viewModel]="firstToggleViewModel" ></app-input-toggle>
  <app-input-toggle [viewModel]="secondToggleViewModel"></app-input-toggle>
</div>