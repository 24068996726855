import {Component, OnInit, ChangeDetectionStrategy, Input} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

@Component({
  selector: 'app-koppeling-header',
  templateUrl: './koppeling-header.component.html',
  styleUrls: ['./koppeling-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KoppelingHeaderComponent implements OnInit {
  @Input() viewModel: KoppelingHeaderViewModel;

  constructor() { }

  ngOnInit(): void {}

  onClick(event): void {
    event.stopPropagation();
    this.viewModel.onButtonClick();
  }

    onMenuItemClick(event, button: KoppelingHeaderButton): void {
        event.stopPropagation();
        button.onButtonClick.next();
    }
}

export interface KoppelingHeaderViewModel {
  img: Observable<string>;
  title: Observable<string>;
  subTitle: Observable<string>;

  button: Observable<string>;
  buttonClass: Observable<string>;
  buttonIcon: Observable<string>;
  onButtonClick: () => void;

  menu: Observable<KoppelingHeaderButton[]>;
  showMenu: BehaviorSubject<boolean>;
}

export interface KoppelingHeaderButton {
  button: string;
  buttonClass: string;
  buttonIcon: string;
  onButtonClick: Subject<void>;
}
