<div class="container">
  <div class="circle_diagram">
    <span class="title">Inzet digitale leermiddelen</span>
    <app-enkel-activatiegraad-lesgroepen-new [procentGeactiveerd$]="store.getActivatieGraad()"></app-enkel-activatiegraad-lesgroepen-new>
    <span class="content">van de lesgroepen is actief binnen de periode</span>
  </div>
  <div class="graph">
    <div class="cijfers">
      <div class="cijferblok">
        <div class="cijfergroot">{{ (store.getAantalLicentiesPerLeerling() | async)?.toFixed(1) }}</div>
        <div class="omschrijving">licenties per leerling</div>
      </div>
      <div class="cijferblok">
        <div class="cijfergroot">{{ (store.getGeactiveerdeLicenties() | async)?.toFixed(0) }}%</div>
        <div class="omschrijving">geactiveerde licenties</div>
      </div>
      <div class="cijferblok">
        <div class="cijfergroot">{{ store.getEducatieveAanbieders() | async }}</div>
        <div class="omschrijving">educatieve aanbieders</div>
      </div>
    </div>
    <app-range-linechart-voor-bazenbanner-new></app-range-linechart-voor-bazenbanner-new>
  </div>
</div>
