import { Component, OnInit } from '@angular/core';
import {TableCellComponent} from '../table/table.model';
import {HelpHeaderCellModel} from './help-header-cell.model';

@Component({
  selector: 'app-help-header-cell',
  templateUrl: './help-header-cell.component.html',
  styleUrls: ['./help-header-cell.component.scss']
})
export class HelpHeaderCellComponent implements TableCellComponent<HelpHeaderCellModel> {
  data: HelpHeaderCellModel;
}
