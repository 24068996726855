import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ModalwindowService } from '../services/modalwindow.service';
import { SomtodayService } from '../services/somtoday.service';
import { AppState } from '../state/app.state';
import {fetchSemParties, setGoBackURL} from '../state/privacydashboard/privacydashboard.actions';
import {PrivacydashboardFacade} from '../state/privacydashboard/privacydashboard.facade';
import { Router } from '@angular/router';
import {AVGExtendedKoppelpartij} from '../dto/avg-dashboard-model-classes';
import {map, takeWhile} from 'rxjs/operators';
import { AVGSemPartyMarketplaceEntry } from 'src/generated/avg-client';


@Component({
  selector: 'app-privacydashboard',
  templateUrl: './privacydashboard.component.html',
  styleUrls: ['./privacydashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrivacydashboardComponent implements OnInit {
    loading = this.store.selectLoading();
    koppelpartijen$: Observable<AVGExtendedKoppelpartij[]>;
    allSemParties: Observable<AVGSemPartyMarketplaceEntry[]>;

    constructor(private store: PrivacydashboardFacade, private toastr: ToastrService, private appState: Store<AppState>, private modalWindowService: ModalwindowService, private somtoday: SomtodayService, private router: Router) { }

    ngOnInit(): void {
      this.store.selectSemMarketplaceEntries()
      .pipe(takeWhile(semParties => !semParties, true)).subscribe(() => {
          this.appState.dispatch(fetchSemParties());
      });

      this.koppelpartijen$ = this.store.selectGekoppeldePartijen();
      this.allSemParties = this.store.selectSemMarketplaceEntries();
    }

    public aanbodOpenen(): void
    {
      this.router.navigateByUrl('privacydashboard/aanbod');
    }

    public koppelingOpenen(uuid): void
    {
      this.appState.dispatch(setGoBackURL({url: 'privacydashboard/koppelingen'}));
      this.router.navigateByUrl('privacydashboard/koppelpartij/details/' + encodeURIComponent(uuid) + '?menu=datatoegang');
    }

    public semKoppelingOpenen(uuid: string): void {
        this.appState.dispatch(setGoBackURL({url: 'privacydashboard/koppelingen'}));
        this.router.navigateByUrl('privacydashboard/semkoppelpartij/details/' + encodeURIComponent(uuid) + '?menu=datatoegang');
    }

    public huidigeKoppelpartijen(): Observable<AVGExtendedKoppelpartij[]> {
      return this.koppelpartijen$.pipe(
        map((koppelpartijen: AVGExtendedKoppelpartij[]) =>
          koppelpartijen.filter(k => k.koppelingen.find(koppeling => moment(koppeling.begindatum) <= moment()))
        )
      );
    }

    public toekomstigeKoppelpartijen(): Observable<AVGExtendedKoppelpartij[]> {
      return this.koppelpartijen$.pipe(
        map((koppelpartijen: AVGExtendedKoppelpartij[]) =>
          koppelpartijen.filter(k => !k.koppelingen.find(koppeling => moment(koppeling.begindatum) <= moment()))
        )
      );
    }

    public getUnconnectedSemParties(): Observable<AVGSemPartyMarketplaceEntry[]> {
        return this.allSemParties.pipe(
            map((parties: AVGSemPartyMarketplaceEntry[]) => parties?.filter(party => !party.consentSince))
        );
    }

    public getConnectedSemParties(): Observable<AVGSemPartyMarketplaceEntry[]> {
        return this.allSemParties.pipe(
            map((parties: AVGSemPartyMarketplaceEntry[]) => parties?.filter(party => party.consentSince))
        );
    }

    public geldigVanaf(koppelpartij: AVGExtendedKoppelpartij): string {
      return koppelpartij.koppelingen.map(k => k.begindatum).sort((a, b) => a > b ? 1 : -1)[0].split('-').reverse().join('-');
    }
}
