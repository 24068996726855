<div (click)="openMenu('Main')" class="menu-toggle">
  <i class="icon-menu"></i>
</div>

<div [ngClass]="gridClass" *ngIf="!isLandelijkBeheerSupport; else emptyMenu">
  <div class="home" (click)=onHomeClicked()>
    <i class="connect-logo"></i>
    <span class="menu-text">Connect</span>
    <i (click)="closeMenu('Main', $event)" class="menu-close icon-exit"></i>
  </div>

  <ng-container *ngIf="magLeermiddelDashboardsInzien">
    <div *ngIf="toonDashboardKnop(true)" class="menu-item schooldashboard" (click)="onClick(
    routingConstants.SCHOOL_DASHBOARD_OLD, routingConstants.SCHOOL_DASHBOARD_OLD)"
         [class.active]="isRouterLinkActive([routingConstants.SCHOOL_DASHBOARD_OLD,
         routingConstants.VAK_DASHBOARD_OLD,
         'vakoverstijgenddashboard',
         routingConstants.KLASSEN_DASHBOARD_OLD])">
      <i class="schooldashboard-logo"></i><br/>
      <span class="menu-text show-for-phone-only">Schooldashboard old</span>
    </div>

    <div *ngIf="toonDashboardKnop(false)" class="menu-item schooldashboard"
         (click)="onClick(routingConstants.SCHOOL_DASHBOARD_NEW, routingConstants.SCHOOL_DASHBOARD_NEW)"
         [class.active]="isRouterLinkActive([routingConstants.SCHOOL_DASHBOARD_NEW,
         routingConstants.VAK_DASHBOARD_NEW, routingConstants.KLASSEN_DASHBOARD_NEW])">
      <i class="schooldashboard-logo"></i><br/>
      <span class="menu-text show-for-phone-only">Schooldashboard</span>
    </div>

    <div *ngIf="isNietProductieOfTest()" class="menu-item leermiddelendashboard"
         (click)="onClick('leermiddelendashboard', 'leermiddelendashboard')"
         [class.active]="isRouterLinkActive(['leermiddelendashboard'])">
      <i class="leermiddelendashboard-logo"></i><br/>
      <span class="menu-text show-for-phone-only">Leermiddelendashboard</span>
    </div>

    <div *ngIf="isFeatureActief('studiewijzerlinkjesdashboard')" class="menu-item studiewijzerlinkjesdashboard"
         (click)="onClick('studiewijzerlinkjes', routingConstants.STUDIEWIJZER_LINKJES_OLD)"
         [class.active]="isRouterLinkActive([routingConstants.STUDIEWIJZER_LINKJES_OLD])">
      <i class="studiewijzerlinkjesdashboard-logo"></i><br/>
      <span class="menu-text show-for-phone-only">Studiewijzerlinkjes</span>
    </div>

    <div *ngIf="isFeatureActief('studiewijzerlinkjesdashboardnew')" class="menu-item studiewijzerlinkjesdashboard"
         (click)="onClick('studiewijzerlinkjesnew', routingConstants.STUDIEWIJZER_LINKJES_NEW)"
         [class.active]="isRouterLinkActive([routingConstants.STUDIEWIJZER_LINKJES_NEW])">
      <i class="studiewijzerlinkjesdashboard-logo"></i><br/>
      <span class="menu-text show-for-phone-only">Studiewijzerlinkjes new</span>
    </div>

  </ng-container>

  <div *ngIf="magAVGDashboardInzien" class="menu-item privacydashboard"
       (click)="onClick('privacydashboard', 'privacydashboard')"
       [class.active]="isRouterLinkActive(['privacydashboard'])">
    <i class="privacydashboard-logo"></i><br/>
    <span class="menu-text show-for-phone-only">Privacydashboard</span>
  </div>

  <div *ngIf="magBeperkteAVGDashboardInzien" class="menu-item privacydashboard"
       (click)="onClick('privacydashboard-preview', 'privacydashboard-preview')"
       [class.active]="isRouterLinkActive(['privacydashboard-preview'])">
    <i class="beperkte-avg-logo"></i><br/>
    <span class="menu-text show-for-phone-only">Privacydashboard</span>
  </div>

  <!-- Deze div zorgt ervoor dat de onderste div onderaan de pagina terecht komt -->
  <div></div>

  <div class="end-align-content">
    <app-whatsnew class="menu-item"></app-whatsnew>
    <app-vestigingsinstellingen class="menu-item" *ngIf="magLeermiddelDashboardsInzien"></app-vestigingsinstellingen>
    <div class="menu-item back-to-somtoday" (click)="somtodayButtonClick()">
      <i class="terug-somtoday" title="Terug naar Somtoday"></i>
      <span class="menu-text show-for-phone-only">Terug naar Somtoday</span>
    </div>
    <span class="buildMoment">{{buildMoment}}</span>
  </div>
</div>
<ng-template #emptyMenu>
  <div [ngClass]="gridClass">
    <div class="home">
      <i class="connect-logo"></i>
      <span class="menu-text">Connect</span>
      <i class="menu-close icon-exit"></i>
    </div>
  </div>
</ng-template>
