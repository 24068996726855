import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, Input } from '@angular/core';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { GoogleChartInterface } from 'ng2-google-charts';
import { FONT_FAMILY } from 'src/app/shared/fonts';
import { auditTime } from 'rxjs/operators';
import { formatDate } from 'src/app/stateless/moments';
import { accent_warning_1, background_3, background_5, primary_1, typography_3 } from 'src/app/shared/colors';
import {WebserviceDataPoint} from '../../state/privacydashboard/privacydashboard.state';

@Component({
    selector: 'app-multi-range-week-bar-chart',
    templateUrl: './multi-range-week-bar-chart.component.html',
    styleUrls: ['./multi-range-week-bar-chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiRangeWeekBarChartComponent implements OnInit, OnDestroy {

    @Input()
    public viewModel: MultiRangeWeekBarChartViewModel;

    public onDataReady$: Subject<void> = new Subject();

    public chart: GoogleChartInterface = {
        chartType: 'ColumnChart',
        dataTable: {
            cols: [
                { type: 'date', label: 'week' },
                { role: 'style' },
                { type: 'number', label: 'leesacties' },
                { role: 'style' },
                { type: 'number', label: 'schrijfacties' }
            ],
            rows: []
        },
        options: {
            bar: {
                groupWidth: 24
            },
            hAxis: {
                gridlines: {
                    color: 'none'
                },
                textPosition: 'out',
                textStyle: {
                    color: typography_3,
                    fontSize: 11
                },
                slantedText: false,
                maxAlternation: 1
            },
            vAxis: {
                minValue: 0,
                maxValue: 1,
                gridlines: {
                    color: background_3
                },
                textPosition: 'out',
                textStyle: {
                    color: typography_3,
                    fontSize: 11
                },
                ticks: []
            },
            chartArea: {
                width: '100%',
                height: '100%',
                bottom: 50,
                left: 60,
                right: 25,
                top: 20
            },
            tooltip: {
                trigger: 'focus'
            },
            isStacked: true,
            areaOpacity: 1.0,
            backgroundColor: { fill: background_5 },
            fontName: FONT_FAMILY,
            colors: [primary_1, accent_warning_1],
            height: 300
        }
    };

    private subscriptions: Subscription[] = [];

    constructor() { }

    ngOnInit(): void {
        window.addEventListener('resize', () => {
            if (this.chart.component) {
                this.chart.component.draw();
            }
        });

        this.subscriptions.push(combineLatest([this.viewModel.onGetDataPoints, this.viewModel.onGetToonSchrijfacties])
            .pipe(auditTime(100)).subscribe(([gd, toonSchrijfacties]) => {
                const data = [];
                if (toonSchrijfacties) {
                    data.push(['Week', 'leesacties', { role: 'style' }, { role: 'tooltip' }, 'schrijfacties', { role: 'style' }, { role: 'tooltip' }]);
                    gd.forEach(dp => data.push(
                        [this.forceNewline(formatDate(dp.eersteDagWeek, 'D MMM')),
                        dp.aantalLeesacties,
                        'stroke-width: 1;stroke-color: ' + primary_1 + ';',
                        'Week van ' + formatDate(dp.eersteDagWeek, 'D MMM'),
                        dp.aantalSchrijfacties,
                        dp.aantalSchrijfacties ? 'stroke-width: 1;stroke-color: ' + accent_warning_1 + ';' : 'color: ' + background_5,
                        'Week van ' + formatDate(dp.eersteDagWeek, 'D MMM')]));
                }
                else {
                    data.push(['Week', 'leesacties', { role: 'style' }, { role: 'tooltip' }]);
                    gd.forEach(dp => data.push(
                        [this.forceNewline(formatDate(dp.eersteDagWeek, 'D MMM')),
                        dp.aantalLeesacties,
                        'stroke-width: 1;stroke-color: ' + primary_1 + ';',
                        'Week van ' + formatDate(dp.eersteDagWeek, 'D MMM')]));
                }

                this.chart.dataTable = data;

                this.chart.options.vAxis.ticks = this.populateVTicks(gd);

                if (gd.length > 20)
                    this.chart.options.bar.groupWidth = '61.8%';
                else
                    this.chart.options.bar.groupWidth = 24;

                if (this.chart && this.chart.component && this.chart.component.wrapper) {
                    this.chart.component.draw();
                }
            }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    private forceNewline(dateString: string): string {
        return dateString.replace(' ', '\n');
    }

    private populateVTicks(data: WebserviceDataPoint[]): number[] {
        const max = data.map(d => d.aantalLeesacties + d.aantalSchrijfacties).reduce((p, c) => p > c ? p : c);
        const numberOfTicks = Math.ceil(max / 50);
        const vTicks = [];
        for (let i = 0; i <= numberOfTicks; i++) {
            vTicks.push(i * 50);
        }
        return vTicks;
    }
}

export interface MultiRangeWeekBarChartViewModel {
    onGetDataPoints: Observable<WebserviceDataPoint[]>;
    onGetToonSchrijfacties: Observable<boolean>;
}
