import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {KoppelingMenuItem, KoppelingMenuViewModel} from '../layout/koppeling-menu/koppeling-menu.component';
import {map} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {AppState} from '../state/app.state';
import {
  selectGeselecteerdeWebservice,
} from '../state/privacydashboard/privacydashboard.selectors';
import {
  removeGeselecteerdeWebservice
} from '../state/privacydashboard/privacydashboard.actions';
import {KoppelingHeaderViewModel} from '../layout/koppeling-header/koppeling-header.component';
import {Router} from '@angular/router';
import {WebserviceDatatoegangViewModel} from '../layout/webservice-datatoegang/webservice-datatoegang.component';
import {Risk} from '../layout/webservice-tegel/webservice-tegel.component';
import {
  VeldOudeWebservice,
  Webservice,
} from '../state/privacydashboard/privacydashboard.state';
import {WebserviceGebruikViewModel} from '../layout/webservice-gebruik/webservice-gebruik.component';
import {PrivacydashboardFacade} from '../state/privacydashboard/privacydashboard.facade';
import {environment} from '../../environments/environment';
import {GoBackHeaderViewModel} from '../layout/go-back-header/go-back-header.component';

@Component({
  selector: 'app-webservice',
  templateUrl: './webservice.component.html',
  styleUrls: ['./webservice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WebserviceComponent implements OnInit, OnDestroy {
  private subscriptions = [];

  public webservice$: Observable<Webservice>;

  public koppelingHeaderViewModel: KoppelingHeaderViewModel = {
    img: new Observable<string>(),
    title: new Observable<string>(),
    subTitle: new Observable<string>(),
    button: null,
    buttonClass: null,
    buttonIcon: null,
    onButtonClick: null,
    menu: null,
    showMenu: new BehaviorSubject<boolean>(false),
  };

  public koppelingMenuViewModel: KoppelingMenuViewModel;
  public gebruiksTab: Observable<boolean>;
  public datatoegangTab: Observable<boolean>;

  public webserviceDatatoegangViewModel: WebserviceDatatoegangViewModel = {
    webserviceNaam: new BehaviorSubject<string>(null),
    webserviceRisico: new BehaviorSubject<Risk>(null),
    webserviceRisicoTekst: new BehaviorSubject<string>(null),
    toegangTekst: new BehaviorSubject<string>(null),
    doelgroepenTekst: new BehaviorSubject<string>(null),
    datatoegangTekst: new BehaviorSubject<string>(null),
    datatoegangVelden: new BehaviorSubject<VeldOudeWebservice[][]>(null),
  };

  public webserviceGebruikViewModel: WebserviceGebruikViewModel;

  public goBackHeaderViewModel: GoBackHeaderViewModel = {
    title: null,
    backUrl: 'privacydashboard/datatoegang',
    backTitle: 'Terug naar overzicht'
  };

  loading$ = this.store.selectLoading();

  constructor(private store: PrivacydashboardFacade, private router: Router, private appState: Store<AppState>) {  }

  ngOnInit(): void {
    this.webservice$ = this.appState.select(selectGeselecteerdeWebservice);

    this.loadKoppelingHeaderViewModel();
    this.loadKoppelingMenuViewModel();
    this.loadWebserviceDatatoegangViewModel();
    this.loadWebserviceGebruikViewModel();
  }

  loadKoppelingHeaderViewModel(): void {
    const wsNaam$ = this.webservice$.pipe(map(w => w?.service));
    const wsIsWISOrUM$ = wsNaam$.pipe(map(w => ['WIS', 'UM'].includes(w)));
    this.koppelingHeaderViewModel.title = wsNaam$;
    this.koppelingHeaderViewModel.img = wsIsWISOrUM$.pipe(map(w => w ? 'assets/img/icons/Symbool/Protect-unsecure-orange.svg' : 'assets/img/icons/Symbool/Protect-unsure-yellow.svg'));
    this.koppelingHeaderViewModel.subTitle = wsIsWISOrUM$.pipe(map(w => w ? 'Verhoogd risico' : null));
  }

  loadKoppelingMenuViewModel(): void {
    const activeMenuKey: BehaviorSubject<string> = new BehaviorSubject('gebruik');
    const menuItems: BehaviorSubject<KoppelingMenuItem[]> = new BehaviorSubject<KoppelingMenuItem[]>([{ caption: 'Gebruik', key: 'gebruik' }, { caption: 'Datatoegang', key: 'datatoegang' }]);
    this.koppelingMenuViewModel = {
      onGetMenuItems: menuItems,
      onGetActiveKey: activeMenuKey,

      doSetMenuItems: menuItems,
      doSetActiveKey: activeMenuKey
    };

    this.gebruiksTab = this.koppelingMenuViewModel.onGetActiveKey.pipe(map(k => k === 'gebruik'));
    this.datatoegangTab = this.koppelingMenuViewModel.onGetActiveKey.pipe(map(k => k === 'datatoegang'));
  }

  loadWebserviceDatatoegangViewModel(): void {
    const writeProperty = {type: 'write', description: 'Deze data kan worden bewerkt via deze service. Dit brengt extra risico’s met zich mee.'};
    this.subscriptions.push(this.webservice$.subscribe( webservice => {
      if (webservice) {
        this.webserviceDatatoegangViewModel.webserviceNaam.next(webservice.service);
        switch (webservice.service) {
          case 'WIS':
            this.webserviceDatatoegangViewModel.webserviceRisico.next(Risk.HIGH_RISK);
            this.webserviceDatatoegangViewModel.webserviceRisicoTekst.next('Verhoogd risico');
            this.webserviceDatatoegangViewModel.toegangTekst.next('De WIS service voldoet niet meer aan de moderne standaard. Door de komst van de AVG zijn onze eigen eisen én die van klanten aangescherpt. Somtoday Connect is ontwikkeld als vervanging hiervoor en stelt scholen en koppelpartners in staat om op een veilige, privacy bestendige manier data uit te wisselen doordat wij per koppelpartner kaders vastleggen.');
            this.webserviceDatatoegangViewModel.doelgroepenTekst.next('Altijd toegang tot alle leerlingen van de hele instelling.');
            this.webserviceDatatoegangViewModel.datatoegangTekst.next('Onderstaande gegevens worden standaard meegestuurd door de WIS service. Mogelijk bevat dit (gevoelige) gegevens die externe partijen niet nodig hebben en volgens de AVG tevens geen recht op hebben. Somtoday adviseert partijen die zijn aangesloten op deze webservice aan te sluiten op het Connect-koppelvlak.');
            this.webserviceDatatoegangViewModel.datatoegangVelden.next([
              [
                { naam: 'Medewerkers', properties: [writeProperty] },
                { naam: 'Leerlingen', properties: [writeProperty] },
                { naam: 'Verzorgers', properties: [writeProperty] },
                { naam: 'Mentoren', properties: [] },
                { naam: 'Opleidingen', properties: [] },
                { naam: 'Stam- en lesgroepen', properties: [] }
              ],
              [
                { naam: 'Vakkenpakketten', properties: [] },
                { naam: 'Rooster', properties: [] },
                { naam: 'Keuzewerktijd ', properties: [writeProperty] },
                { naam: 'Huiswerkregistraties', properties: [] },
                { naam: 'Resultaten', properties: [] },
                { naam: 'Examenopdrachten en -dossiers', properties: [] }
              ],
              [
                { naam: 'Absentiemeldingen', properties: [] },
                { naam: 'Pasfoto’s', properties: [] },
                { naam: 'Vrije velden', properties: [] },
                { naam: 'Kluisjes', properties: [] },
              ],
            ]);
            break;
          case 'UM':
            this.webserviceDatatoegangViewModel.webserviceRisico.next(Risk.HIGH_RISK);
            this.webserviceDatatoegangViewModel.webserviceRisicoTekst.next('Verhoogd risico');
            this.webserviceDatatoegangViewModel.toegangTekst.next('De UM service voldoet niet meer aan de moderne standaard. Door de komst van de AVG zijn onze eigen eisen én die van klanten aangescherpt. Somtoday Connect is ontwikkeld als vervanging hiervoor en stelt scholen en koppelpartners in staat om op een veilige, privacy bestendige manier data uit te wisselen doordat wij per koppelpartner kaders vastleggen.');
            this.webserviceDatatoegangViewModel.doelgroepenTekst.next('Altijd toegang tot alle leerlingen van de hele instelling.');
            this.webserviceDatatoegangViewModel.datatoegangTekst.next('Onderstaande gegevens kunnen worden meegestuurd door de UM service, dit is afhankelijk van de specifieke accountinstellingen. Mogelijk bevat dit (gevoelige) gegevens die externe partijen niet nodig hebben en volgens de AVG tevens geen recht op hebben. Somtoday adviseert partijen die zijn aangesloten op deze webservice aan te sluiten op het Connect-koppelvlak.');
            this.webserviceDatatoegangViewModel.datatoegangVelden.next([
              [
                { naam: 'Medewerkers', properties: [writeProperty] },
                { naam: 'Leerlingen', properties: [writeProperty] },
                { naam: 'Verzorgers', properties: [writeProperty] },
                { naam: 'Wachtwoorden', properties: [writeProperty] },
                { naam: 'Mentoren', properties: [] },
              ],
              [
                { naam: 'Opleidingen', properties: [] },
                { naam: 'Stam- en lesgroepen', properties: [] },
                { naam: 'Vakkenpakketten', properties: [] },
                { naam: 'Absentiemeldingen', properties: [] },
                { naam: 'Pasfoto’s', properties: [] },
              ],
              [
                { naam: 'BSN', properties: [] },
                { naam: 'Vrije velden', properties: [] },
                { naam: 'Kluisjes', properties: [] },
                { naam: 'Financiële gegevens', properties: [] },
              ],
            ]);
            break;
          case 'Stage':
            this.webserviceDatatoegangViewModel.webserviceRisico.next(Risk.MEDIUM_RISK);
            this.webserviceDatatoegangViewModel.webserviceRisicoTekst.next('Beperkt risico');
            this.webserviceDatatoegangViewModel.toegangTekst.next('De Stage service voldoet niet meer aan de moderne standaard. Door de komst van de AVG zijn onze eigen eisen én die van klanten aangescherpt. Somtoday Connect is ontwikkeld als vervanging hiervoor en stelt scholen en koppelpartners in staat om op een veilige, privacy bestendige manier data uit te wisselen doordat wij per koppelpartner kaders vastleggen.');
            this.webserviceDatatoegangViewModel.doelgroepenTekst.next('Altijd toegang tot alle leerlingen van de hele instelling.');
            this.webserviceDatatoegangViewModel.datatoegangTekst.next('Onderstaande gegevens worden standaard meegestuurd door de Stage service. Dit bevat relatief weinig data.');
            this.webserviceDatatoegangViewModel.datatoegangVelden.next([
              [{ naam: 'Stages', properties: [writeProperty] }],
            ]);
            break;
          case 'UWLR':
            this.webserviceDatatoegangViewModel.webserviceRisico.next(Risk.MEDIUM_RISK);
            this.webserviceDatatoegangViewModel.webserviceRisicoTekst.next('Beperkt risico');
            this.webserviceDatatoegangViewModel.toegangTekst.next('UWLR is een vertrouwde service, waarin relatief weinig data gedeeld wordt. Echter, koppelingen aangemaakt via de aanbieder ‘Overig’ zijn niet te herleiden tot specifieke partijen, dit brengt een beperkt risico met zich mee. Indien gebruik gemaakt wordt van een officiële Somtoday UWLR-partner, kun je hen als aanbieder selecteren in de <a href=\'' + this.getEck2URL() + '\'>beheerschermen van UWLR</a>. Koppelingen met niet-officiële Somtoday UWLR-partners raden we aan om om te zetten naar een Connect koppeling.');
            this.webserviceDatatoegangViewModel.doelgroepenTekst.next('Altijd toegang tot alle leerlingen van de gekoppelde vestigingen.');
            this.webserviceDatatoegangViewModel.datatoegangTekst.next('Onderstaande gegevens worden standaard meegestuurd door de UWLR service.');
            this.webserviceDatatoegangViewModel.datatoegangVelden.next([
              [
                { naam: 'Medewerkers', properties: [] },
                { naam: 'Leerlingen', properties: [] },
              ],
              [
                { naam: 'Stam- en lesgroepen', properties: [] },
                { naam: 'Vakkenpakketten', properties: [] },
              ],
            ]);
            break;
          case 'Cup':
            this.webserviceDatatoegangViewModel.webserviceRisico.next(Risk.MEDIUM_RISK);
            this.webserviceDatatoegangViewModel.webserviceRisicoTekst.next('Beperkt risico');
            this.webserviceDatatoegangViewModel.toegangTekst.next('De Cup service voldoet niet meer aan de moderne standaard. Door de komst van de AVG zijn onze eigen eisen én die van klanten aangescherpt. Somtoday Connect is ontwikkeld als vervanging hiervoor en stelt scholen en koppelpartners in staat om op een veilige, privacy bestendige manier data uit te wisselen doordat wij per koppelpartner kaders vastleggen.');
            this.webserviceDatatoegangViewModel.doelgroepenTekst.next('Altijd toegang tot alle leerlingen van de hele instelling.');
            this.webserviceDatatoegangViewModel.datatoegangTekst.next('Onderstaande gegevens worden standaard meegestuurd door de Cup service. Dit bevat relatief weinig data.');
            this.webserviceDatatoegangViewModel.datatoegangVelden.next([
              [{ naam: 'Keuzewerktijd', properties: [writeProperty] }],
            ]);
            break;
          case 'HRM':
            this.webserviceDatatoegangViewModel.webserviceNaam.next('HRM');
            this.webserviceDatatoegangViewModel.webserviceRisico.next(Risk.MEDIUM_RISK);
            this.webserviceDatatoegangViewModel.webserviceRisicoTekst.next('Beperkt risico');
            this.webserviceDatatoegangViewModel.toegangTekst.next('De HRM service voldoet niet meer aan de moderne standaard. Door de komst van de AVG zijn onze eigen eisen én die van klanten aangescherpt. Somtoday Connect is ontwikkeld als vervanging hiervoor en stelt scholen en koppelpartners in staat om op een veilige, privacy bestendige manier data uit te wisselen doordat wij per koppelpartner kaders vastleggen.');
            this.webserviceDatatoegangViewModel.doelgroepenTekst.next('Altijd toegang tot alle leerlingen van de hele instelling.');
            this.webserviceDatatoegangViewModel.datatoegangTekst.next('Onderstaande gegevens kunnen alleen worden aangemaakt door de HRM service, deze service kan geen data ophalen uit Somtoday.');
            this.webserviceDatatoegangViewModel.datatoegangVelden.next([
              [
                { naam: 'Medewerkers', properties: [writeProperty] },
                { naam: 'Wachtwoorden', properties: [writeProperty] },
              ],
              [
                { naam: 'BSN', properties: [writeProperty] },
                { naam: 'Financiële gegevens', properties: [writeProperty] },
              ],
            ]);
            break;
        }
      }
    }));
  }

  loadWebserviceGebruikViewModel(): void {
    this.webserviceGebruikViewModel = {
      webserviceNaam: this.webservice$.pipe(map(ws => ws?.service)),
      webserviceAccountGebruik: this.webservice$.pipe(map(ws => ws?.accountGebruik)),
      webserviceWeekGebruik: this.webservice$.pipe(map(ws => ws?.weekGebruik))
    };
  }

  getEck2URL(): string {
    const omgeving = environment.configurationName;
    let url;
    switch (omgeving) {
      case 'Development':
        url = 'http://localhost:8080/iridium/eck2/overzicht';
        break;
      case 'Test':
      case 'Acceptatie':
        url = `https://oop.${omgeving.toLowerCase()}.somtoday.nl/eck2/overzicht`;
        break;
      case 'Nightly':
        url = `https://oop.nightly.somtoday.build/eck2/overzicht`;
        break;
      default:
        url = `https://oop.somtoday.nl/eck2/overzicht`;
    }
    return url;
  }

  ngOnDestroy(): void {
    this.appState.dispatch(removeGeselecteerdeWebservice());
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
