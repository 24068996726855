import {RangeLinechartModel} from '../../../layout/range-linechart/range-linechart.model';
import {RangeModel} from '../../../layout/model/range.model';

export interface LeermiddelData {
  data: LeermiddelOnderwijssoortDagGebruikData[];
  totaal: LeermiddelOnderwijssoortDagGebruikTotaal;
}

export interface LeermiddelOnderwijssoortDagGebruikData {
  leermiddel: string;
  onderwijssoortJaar: string;
  leerlingenAantal: number;
  leerlingenGeactiveerd: number;
  waarvanActief: number;
  leermiddelOnderwijssoortDagData: RangeLinechartModel[];
  gebruikPerLeerlingPerWeek: number;
}

export interface LeermiddelOnderwijssoortDagGebruikTotaal {
  leermiddel: string;
  leerlingenAantal: number;
  leerlingenGeactiveerd: RangeModel;
  waarvanActief: RangeModel;
  leermiddelDagData: RangeLinechartModel[];
  gebruikPerLeerlingPerWeek: RangeModel;
}

export const selectLeermiddelData =
  appState => appState.schooldashboard.berekendeVakloosData;




