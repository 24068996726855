import {Pipe, PipeTransform} from '@angular/core';
import {Koppelpartij} from '../state/privacydashboard/privacydashboard.state';
import {createColumnDef, createModel, TableModel} from '../table/table/table.model';
import {typography_2} from '../shared/colors';

@Pipe({name: 'privacydashboardTable'})
export class PrivacydashboardTablePipe implements PipeTransform {
  transform(koppelpartijen: Koppelpartij[]): TableModel<Koppelpartij> {
    const model = createModel(koppelpartijen, r => r.koppelpartijNaam);
    model.showHeaders = false;
    const naam = createColumnDef('koppelpartijNaam');
    naam.body.style = {
      color: typography_2,
      fontSize: '20px',
      fontWeight: '700',
      lineHeight: '27px',
      paddingTop: '21.5px',
      paddingBottom: '24.5px'
    };
    model.columnDefs = [naam];
    return model;
  }
}
