<div class="logo" >
  <i class="logo" [class.swdashboard]="studiewijzerdb && (vestigingBevatData | async)"></i>
  <i class="lijn" [class.swdashboard]="studiewijzerdb && (vestigingBevatData | async)"></i>
</div>
<div [class.swdashboard]="studiewijzerdb && (vestigingBevatData | async)" class="placeholder">
  <div *ngIf="vestigingBevatData | async">
    <i class="uitroepteken"></i>
    <p>Geen {{licentieOfStudiewijzer}} gevonden in dit datumbereik</p>
    <p>Kies rechtsboven een andere periode</p>
  </div>
  <div *ngIf="!(vestigingBevatData | async)">
    <i class="uitroepteken"></i>
    <p>Geen {{licentieOfStudiewijzer}} gevonden op deze vestiging</p>
  </div>
</div>
