import {createSelector} from '@ngrx/store';
import {selectSchooldashboard} from '../../../state/app.state';
import {StudiewijzerlinksFilterInput, SwLinkjesGebruikDetails, SwLinkjesPerPlatformOfVak} from '../../../../generated/graphql';
import {selectHuidigeVestiging} from '../schooldashboard/schooldashboard.selectors';
import {selectHuidigePeriode, selectVanafTotenmet} from '../../../state/app.selectors';
import {Payload2} from '../../../state/payload';

export interface SwLinkjesPerPlatformOfVakModel extends SwLinkjesPerPlatformOfVak {
  overig: boolean;
}

export const selectStudiewijzerLinkjesPerPlatform = createSelector(
  selectSchooldashboard,
  ({studiewijzerLinkjes}) => verwerkLinkjes(studiewijzerLinkjes?.studiewijzerlinksPerPlatformOfVak)
);

const cutoffRatio = 0.05;

function verwerkLinkjes(data: SwLinkjesPerPlatformOfVak[] = []): SwLinkjesPerPlatformOfVakModel[] {
  const totaal = data.map(p => p.links).reduce((som, n) => som + n, 0);
  const grenswaarde = totaal * cutoffRatio;
  const [boven, onder] = data.map(v => ({...v, overig: v.links <= grenswaarde}))
    .reduce(([a, b], v) => v.overig ? [a, [...b, v]] : [[...a, v], b], [[], []]);
  boven.sort(descBy((v: SwLinkjesPerPlatformOfVak) => v.links));
  if (onder.length > 0) {
    onder.sort(descBy((v: SwLinkjesPerPlatformOfVak) => v.links));
    return [...boven, {naam: 'Overig', links: grenswaarde}, ...onder];
  } else {
    return boven;
  }
}

const descBy = <T>(fn: (t: T) => number) => (a: T, b: T) => fn(b) - fn(a);

export const selectAantalLesgroepenMetStudiewijzerlinks = createSelector(
  selectSchooldashboard,
  ({studiewijzerLinkjes}) => studiewijzerLinkjes?.aantalLesgroepenMetStudiewijzerlinks
);

export const selectSelectedSWItem = createSelector(
  selectSchooldashboard,
  ({selectedStudiewijzerLinkjesItem}) => selectedStudiewijzerLinkjesItem
);

export const selectStudiewijzerlinkjesDetailsPerPlatform = createSelector(
  selectSchooldashboard,
  ({swLinkjesGebruikDetails}) => swLinkjesGebruikDetails
);

export const selectVestigingBevatStudiewijzers = createSelector(
  selectHuidigeVestiging,
  v => v?.vanafdatumStudiewijzers !== null
);

export const selectStudiewijzerlinksFilter = createSelector(
  selectSchooldashboard,
  selectVanafTotenmet,
  selectHuidigeVestiging,
  (state, [vanaf, totenmet], {uuid: vestiging}, {value2}: Payload2<Date, SwLinkjesGebruikDetails>): StudiewijzerlinksFilterInput => {
    const vaknaam = state.groeperenOpPlatform ? value2.naam : state.selectedStudiewijzerLinkjesItem.naam;
    const platformnaam = state.groeperenOpPlatform ? state.selectedStudiewijzerLinkjesItem.naam : value2.naam;
    return {vaknaam, platformnaam, vestiging, vanaf, totenmet};
  }
);
