import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../state/app.state';
import { AVGSemConsent, AVGSemPartyViewPageEntry } from 'src/generated/avg-client';
import { Scope } from '../../dto/sem-scope-descriptions';
import { selectSemPartyAccessedFields, selectSemPartyDetails } from 'src/app/state/privacydashboard/privacydashboard.selectors';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-sem-koppeling-datatoegang',
    templateUrl: './sem-koppeling-datatoegang.component.html',
    styleUrls: ['./sem-koppeling-datatoegang.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SemKoppelingDatatoegangComponent implements OnInit, OnDestroy {
    public semParty: Observable<AVGSemPartyViewPageEntry>;
    public firstConsent: Observable<AVGSemConsent>;
    public accessedFields: Observable<Scope[]>;

    public showDoelgroepen: boolean = false;
    private opengeklapteEndpoints: string[] = [];

    private subscriptions = [];

    constructor(private appState: Store<AppState>) { }

    ngOnInit(): void {
        this.semParty = this.appState.select(selectSemPartyDetails);
        this.accessedFields = this.appState.select(selectSemPartyAccessedFields);
        this.firstConsent = this.semParty.pipe(map(party => {
            let firstConsent = null;
            party?.consent?.filter(consent => consent.consentGivenAt)
            .forEach(consent => {
                if (firstConsent === null || consent.consentGivenAt < firstConsent?.consentGivenAt) {
                    firstConsent = consent;
                }
            });
            return firstConsent;
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    public setShowDoelgroepen(showDoelgroepen: boolean): void {
        this.showDoelgroepen = showDoelgroepen;
    }

    public toggleEndpoint(endpoint: string): void {
        if (this.opengeklapteEndpoints.includes(endpoint)) {
            this.opengeklapteEndpoints = this.opengeklapteEndpoints.filter(n => n !== endpoint);
        } else {
            this.opengeklapteEndpoints.push(endpoint);
        }
    }

    public endpointClass(endpoint: string, index: number): string {
        return (index % 2 !== 0 ? 'endpoint' : 'endpoint uneven') + (this.opengeklapteEndpoints.includes(endpoint) ? ' open' : '');
    }
}

