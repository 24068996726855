import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {AppState} from '../state/app.state';
import {OAuthService} from 'angular-oauth2-oidc';
import {filter, map} from 'rxjs/operators';
import {constNull} from 'fp-ts/function';
import {Observable} from 'rxjs';
import {Action, Store} from '@ngrx/store';
import * as either from 'fp-ts/Either';
import {payload} from '../state/payload';
import {setClaims} from '../state/claims/claims.actions';
import {appInit} from '../state/app.actions';

@Injectable()
export class ClaimsEffect {
  onToken$ = createEffect(this.from(this.oAuthService.events.pipe(filter(({type}) => type === 'token_received'))));

  onAppInit$ = createEffect(this.from(this.actions$.pipe(ofType(appInit))));

  constructor(private actions$: Actions, private store: Store<AppState>, private oAuthService: OAuthService) {
  }

  private from(observable: Observable<any>): () => Observable<Action> {
    return () => observable.pipe(
      map(() => this.oAuthService.getIdentityClaims()),
      map(either.fromNullable(null)),
      map(either.getOrElse(constNull)),
      map(payload),
      map(setClaims)
    );
  }
}
