export class Queue<T>
{
    private innerArray: T[] = [];

    public enqueue(item: T)
    {
        this.innerArray.push(item);
    }

    public dequeue(): T
    {
        const first = this.innerArray[0];
        this.innerArray = this.innerArray.slice(1, this.innerArray.length);
        return first;
    }

    public isEmpty(): boolean
    {
        return this.innerArray.length === 0;
    }
}