import {Rowgroup} from '../table/table/table.model';
import {PlatformVakOnderwijssoort} from '../../generated/graphql';
import {onderwijssoortLeerjaarComparator} from '../services/onderwijssoortleerjaar.comparator';

export interface PlatformVakData {
  platform: string;
  vak: string;
  tabelData: PlatformVakTabelData[];
}

export interface PlatformVakTabelData {
  onderwijssoort: string;
  leerjaar: number;
  aantalLinks: number;
  klikratio: number;
  aantalLesgroepen: number;
  rowgroup: Rowgroup;
}

export function createPlatformVakTabelData(data: PlatformVakOnderwijssoort[]): PlatformVakTabelData[] {
  data = data.sort(onderwijssoortLeerjaarComparator(v => v.onderwijssoort, v => v.leerjaar));
  const tabelData: PlatformVakTabelData[] = [];
  let groupIndex = 0;
  for (let i = 0; i < data.length; i++) {
    const row = data[i];
    let last = false;
    if (data[i + 1] && data[i + 1].onderwijssoort !== row.onderwijssoort) {
      last = true;
    } else if (!data[i + 1] && groupIndex % 2 === 1) {
      last = true;
    }
    tabelData.push({
      onderwijssoort: row.onderwijssoort,
      leerjaar: row.leerjaar,
      aantalLinks: row.aantalLinks,
      klikratio: row.klikratio,
      aantalLesgroepen: row.aantalLesgroepen,
      rowgroup: {groupId: groupIndex, lastOfGroup: last}});
    if (last) {
      groupIndex += 1;
    }
  }
  return tabelData;
}
