import {SchooldashboardState} from '../old-dashboarding/state/schooldashboard/schooldashboard.state';
import {schooldashboardReducer} from '../old-dashboarding/state/schooldashboard/schooldashboard.reducer';
import {routerReducer, RouterReducerState} from '@ngrx/router-store';
import {createFeatureSelector} from '@ngrx/store';
import {errorsReducer, ErrorsState} from './errors/errors.reducer';
import {RMedewerker} from '../services/somtoday.service';
import {meReducer} from './me/me.reducer';
import {PrivacydashboardState} from './privacydashboard/privacydashboard.state';
import {privacydashboardReducer} from './privacydashboard/privacydashboard.reducer';
import {requestCountReducer} from './request-count/request-count.reducer';
import {claimsReducer} from './claims/claims.reducer';
import {JsonRecord} from 'fp-ts/Either';
import {
  LeermiddelvakkoppelingState
} from '../old-dashboarding/state/leermiddelvakkoppeling/leermiddelvakkoppeling.state';
import {
  leermiddelvakkoppelingReducer
} from '../old-dashboarding/state/leermiddelvakkoppeling/leermiddelvakkoppeling.reducer';
import {DevKoppelpartijState} from './dev-koppelpartij/dev-koppelpartij.state';
import {devKoppelpartijReducer} from './dev-koppelpartij/dev-koppelpartij.reducer';
import {SchooldashboardNewState} from './schooldashboard/schooldashboard.state';
import {VakdashboardState} from './vakdashboard/vakdashboard.state';
import {KlassendashboardState} from './klassendashboard/klassendashboard.state';
import {StudiewijzerlinkjesState} from './studiewijzerlinkjes/studiewijzerlinkjes.state';
import {schooldashboardNewReducer} from './schooldashboard/schooldashboard.reducer';
import {vakdashboardReducer} from './vakdashboard/vakdashboard.reducer';
import {klassendashboardReducer} from './klassendashboard/klassendashboard.reducer';
import {studiewijzerlinkjesReducer} from './studiewijzerlinkjes/studiewijzerlinkjes.reducer';
import {LeermiddelvakkoppelingNewState} from './leermiddelvakkoppeling/leermiddelvakkoppeling.state';
import {DashboardSettingsState} from './dashboardsettings/dashboardsettings.state';
import {dashboardSettingsReducer} from './dashboardsettings/dashboardsettings.reducer';
import {leermiddelvakkoppelingNewReducer} from './leermiddelvakkoppeling/leermiddelvakkoppeling.reducer';
import { dashboardBackofficeReducer, DashboardBackofficeState } from './dashboard-backoffice/dashoard-backoffice.reducer';
import {ExportoverzichtState} from './exportoverzicht/exportoverzicht.state';
import {exportoverzichtReducer} from './exportoverzicht/exportoverzicht.reducer';

export interface AppState {
  // TODO: verwijder schooldashboard
  schooldashboard: SchooldashboardState;
  dashboardBackoffice: DashboardBackofficeState;
  errors: ErrorsState;
  me: RMedewerker;
  privacydashboard: PrivacydashboardState;
  requestCount: number;
  claims: object;
  router: RouterReducerState<any>;
  // TODO: verwijder leermiddelvakkoppeling
  leermiddelvakkoppeling: LeermiddelvakkoppelingState;
  devKoppelpartij: DevKoppelpartijState;
  // TODO: pas aan naar schooldashboard
  schooldashboardNew: SchooldashboardNewState;
  vakdashboard: VakdashboardState;
  klassendashboard: KlassendashboardState;
  studiewijzerlinkjes: StudiewijzerlinkjesState;
  // TODO: pas aan naar leermiddelvakkoppeling
  leermiddelvakkoppelingNew: LeermiddelvakkoppelingNewState;
  dashboardsettings: DashboardSettingsState;
  exportoverzicht: ExportoverzichtState;
}

export const reducers = {
  // TODO: verwijder schooldashboard
  schooldashboard: schooldashboardReducer,
  dashboardBackoffice: dashboardBackofficeReducer,
  errors: errorsReducer,
  me: meReducer,
  privacydashboard: privacydashboardReducer,
  requestCount: requestCountReducer,
  claims: claimsReducer,
  router: routerReducer,
  // TODO: verwijder leermiddelvakkoppeling
  leermiddelvakkoppeling: leermiddelvakkoppelingReducer,
  devKoppelpartij: devKoppelpartijReducer,
  // TODO: pas aan naar schooldashboard
  schooldashboardNew: schooldashboardNewReducer,
  vakdashboard: vakdashboardReducer,
  klassendashboard: klassendashboardReducer,
  studiewijzerlinkjes: studiewijzerlinkjesReducer,
  // TODO: pas aan naar leermiddelvakkoppeling
  leermiddelvakkoppelingNew: leermiddelvakkoppelingNewReducer,
  dashboardsettings: dashboardSettingsReducer,
  exportoverzicht: exportoverzichtReducer,
};

export const selectSchooldashboard = createFeatureSelector<AppState, SchooldashboardState>('schooldashboard');
export const selectErrors = createFeatureSelector<AppState, ErrorsState>('errors');
export const selectMe = createFeatureSelector<AppState, RMedewerker>('me');
export const selectPrivacydashboard = createFeatureSelector<AppState, PrivacydashboardState>('privacydashboard');
export const selectRequestCount = createFeatureSelector<AppState, number>('requestCount');
export const selectClaims = createFeatureSelector<AppState, JsonRecord>('claims');
export const selectRouter = createFeatureSelector<AppState, RouterReducerState<any>>('router');
export const selectLeermiddelvakkoppeling = createFeatureSelector<AppState, LeermiddelvakkoppelingState>('leermiddelvakkoppeling');
export const selectDevKoppelpartij = createFeatureSelector<AppState, DevKoppelpartijState>('devKoppelpartij');
export const selectDashboardBackoffice = createFeatureSelector<AppState, DashboardBackofficeState>('dashboardBackoffice');

export const selectExportoverzicht = createFeatureSelector<AppState, ExportoverzichtState>('exportoverzicht');
export const selectSchooldashboardNew = createFeatureSelector<AppState, SchooldashboardNewState>('schooldashboardNew');
export const selectVakdashboard = createFeatureSelector<AppState, VakdashboardState>('vakdashboard');
export const selectKlassendashboard = createFeatureSelector<AppState, KlassendashboardState>('klassendashboard');
export const selectStudiewijzerlinkjes = createFeatureSelector<AppState, StudiewijzerlinkjesState>('studiewijzerlinkjes');
export const selectLeermiddelvakkoppelingNew = createFeatureSelector<AppState, LeermiddelvakkoppelingNewState>('leermiddelvakkoppelingNew');
export const selectDashboardSettings = createFeatureSelector<AppState, DashboardSettingsState>('dashboardsettings');

export const PRIVACY_DASHBOARD_FORBIDDEN = 'Privacydashboard forbidden';
