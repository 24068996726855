<div class="backoffice-page">
    <p class="backoffice-header">Connect Leermiddeldashboards Backoffice</p>

    <div class="tray-feedback">
        <div *ngFor="let feedback of (facade.feedback$ | async)" class="{{feedback.nature}}">
            {{feedback.nature}}: {{feedback.message}}
        </div>
    </div>

    <p class="backoffice-subheader">Schooljaren</p>
    <div class="backoffice-listing--inset w450">
        <div class="listing-row--header">
            <div class="listing-column">Naam</div>
            <div class="listing-column">Sycnen vanaf</div>
            <div class="listing-column">Syncen tot en met</div>
        </div>
        <div *ngFor="let schoolYear of (schoolYears | async)">
            <app-schoolyear-row [schoolYear]="schoolYear" (save)="saveSchoolYear($event)"></app-schoolyear-row>
        </div>
        <div class="listing-row">
            <div class="listing-column"><input type="text" #name maxlength="9" class="input-text" (clickOutside)="suggestStartEnd(name.value)"></div>
            <div class="listing-column"><input type="date" #from class="input-text" [value]="suggestedStart"></div>
            <div class="listing-column"><input type="date" #until class="input-text" [value]="suggestedEnd"></div>
            <div class="listing-column--small"><button class="button-save--small" (click)="saveNewSchoolYear(name.value, from.value, until.value)">Add</button></div>
        </div>
    </div>

    <p class="backoffice-subheader">Organisaties</p>
    <div class="backoffice-listing--inset w1060">
        <div class="listing-row--header">
            <div class="listing-column--wide clickable {{(orgsSortKey | async) === 'name' ? 'listing-header--sorting' : ''}}" (click)="sortOrgsBy('name')">Naam</div>
            <div class="listing-column--wide clickable {{(orgsSortKey | async) === 'uuid' ? 'listing-header--sorting' : ''}}" (click)="sortOrgsBy('uuid')">UUID</div>
            <div class="listing-column clickable {{(orgsSortKey | async) === 'database' ? 'listing-header--sorting' : ''}}" (click)="sortOrgsBy('database')">Database</div>
            <div class="listing-column clickable {{(orgsSortKey | async) === 'include_from' ? 'listing-header--sorting' : ''}}" (click)="sortOrgsBy('include_from')">Sycnen vanaf</div>
            <div class="listing-column clickable {{(orgsSortKey | async) === 'include_until' ? 'listing-header--sorting' : ''}}" (click)="sortOrgsBy('include_until')">Syncen tot en met</div>
            <div class="listing-column clickable {{(orgsSortKey | async) === 'protected' ? 'listing-header--sorting' : ''}}" (click)="sortOrgsBy('protected')">Data beschermen</div>
        </div>
        <div class="listing-row" *ngFor="let organization of (organizations | async)">
            <div class="listing-column--wide {{(orgsSortKey | async) === 'name' ? 'listing-column--sorting' : ''}}">{{organization.name}}</div>
            <div class="listing-column--wide {{(orgsSortKey | async) === 'uuid' ? 'listing-column--sorting' : ''}}">{{organization.uuid}}</div>
            <div class="listing-column {{(orgsSortKey | async) === 'database' ? 'listing-column--sorting' : ''}}">{{organization.database}}</div>
            <div class="listing-column {{(orgsSortKey | async) === 'include_from' ? 'listing-column--sorting' : ''}}">{{organization.include_from}}</div>
            <div class="listing-column {{(orgsSortKey | async) === 'include_until' ? 'listing-column--sorting' : ''}}">{{organization.include_until}}</div>
            <div class="listing-column {{(orgsSortKey | async) === 'protected' ? 'listing-column--sorting' : ''}}">
                <i class="checkbox clickable checkbox-{{organization.protected ? 'checked' : 'unchecked'}}" (click)="setOrganizationProtected(organization.uuid, !organization.protected)"></i>
            </div>
        </div>
    </div>
    <button class="backoffice-button positive" (click)="startTL()">Transform en Load</button>
    <button class="backoffice-button neutral" (click)="toggleCustomETLPanel()">Custom ETL run</button>
    <div class="{{customSyncPanelStyle$ | async}}">
        <div class="form w650">
            <div class="form-label w300">Gebruik datumbereik</div>
            <div class="form-field w300"><input type="checkbox" #range class="input-checkbox" [checked]="customSyncUseRange$ | async" (click)="toggleCustomDateRange()"></div>
            <div class="form-label w300" *ngIf="!(customSyncUseRange$ | async)">Peildatum</div>
            <div class="form-label w300" *ngIf="customSyncUseRange$ | async">Begindatum</div>
            <div class="form-field w300"><input type="date" #customSyncStartInput class="input-text" [value]="customSyncStart$ | async" (change)="customSyncStart$.next($event.target.value)"></div>
            <div class="form-label w300" *ngIf="customSyncUseRange$ | async">Eindatum</div>
            <div class="form-field w300" *ngIf="customSyncUseRange$ | async"><input type="date" #customSyncEndInput class="input-text" [value]="customSyncEnd$ | async" (change)="customSyncEnd$.next($event.target.value)"></div>
            <div class="form-label w300">Extract</div>
            <div class="form-field w300"><input type="checkbox" #extractInput class="input-text" [checked]="customSyncExtract$ | async" (change)="customSyncExtract$.next($event.target.checked)"></div>
            <div class="form-label w300">Transform</div>
            <div class="form-field w300"><input type="checkbox" #transformInput class="input-text" [checked]="customSyncTransform$ | async" (change)="customSyncTransform$.next($event.target.checked)"></div>
            <div class="form-label w300">Load</div>
            <div class="form-field w300"><input type="checkbox" #loadInput class="input-text" [checked]="customSyncLoad$ | async" (change)="customSyncLoad$.next($event.target.checked)"></div>
            <div class="form-label w300">Remark</div>
            <div class="form-field--text"><textarea type="textarea" class="w450 h200" #remarkInput [value]="customSyncRemark$ | async" (change)="customSyncRemark$.next($event.target.value)"></textarea></div>

            <div class="w450 h25"></div>

            <div class="form-label w300">Schooljaren</div>
            <div class="form-subsection" *ngFor="let schoolYear of (schoolYears | async)">
                <div class="form-label w150">{{schoolYear.name}}</div>
                <div class="form-field w150"><input type="checkbox" class="input-text" [checked]="(customSyncSchoolyears$ | async)[schoolYear.name]" (change)="updateCustomSyncSchoolYears(schoolYear, $event.target.checked)"></div>
            </div>

            <div class="w450 h25"></div>

            <div class="form-label w450">Organisaties</div>
            <div class="form-subsection" *ngFor="let organization of (organizations | async)">
                <div class="form-label w450 h75">{{organization.name}} ({{organization.uuid}})</div>
                <div class="form-field w150"><input type="checkbox" class="input-text" [checked]="(customSyncOrganizations$ | async)[organization.uuid]" (change)="updateCustomSyncOrganizations(organization, $event.target.checked)"></div>
            </div>

            <div class="w450 h25"></div>

            <div class="form-label w300" *ngIf="!(customSyncUseRange$ | async)">Wacht tot klaar</div>
            <div class="form-field w300" *ngIf="!(customSyncUseRange$ | async)"><input type="checkbox" #blockUntilFinishedInput class="input-text" [checked]="blockUntilFinished$ | async" (change)="blockUntilFinished$.next($event.target.checked)"></div>

            <button class="backoffice-button positive" (click)="startCustomSync()">Start!</button>
        </div>
    </div>

    <div class="{{syncStatusPanelStyle$ | async}}">
        <div>
            Geselecteerde ETL run:
            <div class="close"></div>
            <div class="form">
                <div class="form-label w300">Vanaf</div>
                <div class="form-field w300">{{(syncStatusPanelSubject$ | async)?.date_range_start}}</div>
                <div class="form-label w300">Tot en met</div>
                <div class="form-field w300">{{(syncStatusPanelSubject$ | async)?.date_range_end}}</div>
                <div class="form-label w300">Extract</div>
                <div class="form-field w300">{{(syncStatusPanelSubject$ | async)?.extract}}</div>
                <div class="form-label w300">Transform</div>
                <div class="form-field w300">{{(syncStatusPanelSubject$ | async)?.transform}}</div>
                <div class="form-label w300">Load</div>
                <div class="form-field w300">{{(syncStatusPanelSubject$ | async)?.load}}</div>
                <div class="form-label w300">Remark</div>
                <div class="form-field w300">{{(syncStatusPanelSubject$ | async)?.remark}}</div>

                <div class="form-label w300">Schooljaren</div>
                <div class="form-subsection" *ngFor="let schoolYear of (syncStatusSubjectSchoolYears$ | async)">
                    <div class="form-label w150">{{schoolYear.name}}</div>
                </div>

                <div class="form-label w300">Organisaties</div>
                <div class="form-subsection" *ngFor="let organization of (syncStatusSubjectOrganizations$ | async)">
                    <div class="form-label w550 h75">{{organization.name}} ({{organization.uuid}})</div>
                </div>

                <div class="form-label w300">Organisaties (deels) overgeslagen door fout</div>
                <div class="form-subsection" *ngFor="let organization of (syncStatusSubjectFailedOrgs$ | async)">
                    <div class="form-label w550 h75">{{organization.name}} ({{organization.uuid}})</div>
                </div>
            </div>

            <div class="separator w750"></div>

            <button class="backoffice-button neutral marl-20" (click)="pauzeerETL()">Stoppen</button>
            <button class="backoffice-button neutral" (click)="hervatETL()">Hervatten</button>

            <div class="separator w750"></div>
                
            <div class="header w300 marl-20">Stappen over</div>
            <div class="backoffice-listing--inset listing-heightcapped w750 marl-20">
                <div *ngFor="let step of (syncStatusSubjectStepsLeft$ | async)">
                    <div class="form-label h75 w650">{{step.step + (step.reference_date ? ' (' + step.reference_date + ')' : '') + (step.organization ? ' - (' + step.organization + ')' : '')}}</div>
                </div>
            </div>
        </div>
        <div>
            <div class="header w300">Log</div>
            <div class="log" #log>
                <div *ngFor="let event of (syncStatusSubjectLog$ | async)">
                    <div class="{{event.style}}">{{formatDateTime(event.timestamp) + ' ' + event.type + ': ' + event.description}}</div>
                </div>
                <div class="log-line"><!-- Empty line to signal end of log --></div>
            </div>
        </div>
    </div>

    Recente ETL runs:

    <div class="backoffice-listing--inset w1200">
        <div class="listing-row--header">
            <div class="listing-column clickable" (click)="sortSyncsBy('daterangestart')">Vanaf</div>
            <div class="listing-column clickable" (click)="sortSyncsBy('daterangeend')">Tot en met</div>
            <div class="listing-column--narrow clickable" (click)="sortSyncsBy('extract')">E</div>
            <div class="listing-column--narrow clickable" (click)="sortSyncsBy('transform')">T</div>
            <div class="listing-column--narrow clickable" (click)="sortSyncsBy('load')">L</div>
            <div class="listing-column--ultrawide">Remark</div>
            <div class="listing-column--semiwide clickable" (click)="sortSyncsBy('startedat')">Gestart</div>
            <div class="listing-column--semiwide clickable" (click)="sortSyncsBy('doneat')">Afgerond</div>
            <div class="listing-column-narrow">Success</div>
        </div>
        <div class="listing-row clickable" *ngFor="let sync of (syncs$ | async)" (click)="showTask(sync)">
            <div class="listing-column">{{sync.date_range_start}}</div>
            <div class="listing-column">{{sync.date_range_end}}</div>
            <div class="listing-column--narrow">{{sync.extract ? 'J' : ''}}</div>
            <div class="listing-column--narrow">{{sync.transform ? 'J' : ''}}</div>
            <div class="listing-column--narrow">{{sync.load ? 'J' : ''}}</div>
            <div class="listing-column--ultrawide">{{sync.remark}}</div>
            <div class="listing-column--semiwide">{{formatDateTime(sync.started_at)}}</div>
            <div class="listing-column--semiwide">{{formatDateTime(sync.done_at)}}</div>
            <div class="listing-column--narrow">{{sync.success ? 'J' : ''}}</div>
        </div>
    </div>
    <div class="backoffice-listing--inset w1200">
        <div class="listing-row">
            <div *ngFor="let page of (syncsPages$ | async)">
                <div class="listing-column--small" (click)="setSyncsPage(page.pageNumber, page.clickable)">{{page.title}}</div>
            </div>
        </div>
    </div>
</div>
