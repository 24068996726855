import {Component, OnInit, AfterViewInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, ViewChild, OnDestroy} from '@angular/core';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import moment, { Moment } from 'moment';
import { Observable, Subject, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
    selector: 'app-select-date',
    templateUrl: './select-date.component.html',
    styleUrls: ['./select-date.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectDateComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('picker', {
        read: MatDatepicker
    }) picker: MatDatepicker<Moment>;

    @ViewChild('input', {
        read: MatInput
    }) input: MatInput;

    @Input()
    public viewModel: SelectDateViewModel;

    public selectedDate: string;

    private noSelectionPlaceholder: string = '_ _ - _ _ - _ _ _ _';

    public dateOrPlaceholderStyle = '';

    public hasClearIcon: boolean = false;

    public clearIconStyle: string = 'clear';

    public dateDisplayStyle: string = 'dateDisplay';

    public feedback: string = '';

    private subscriptions: Subscription[] = [];

    private blockPickerOpenWhileClearing: boolean = false;

    private onClear: Subject<void> = new Subject();

    private disabled: boolean = false;

    public today = moment();

    constructor(private cdr: ChangeDetectorRef) {
    }

    ngOnInit(): void {
      const isDisableSubscription: Subscription = this.viewModel.isDisabled?.subscribe(disabled => this.disabled = disabled);
      if (isDisableSubscription) {
        this.subscriptions.push(isDisableSubscription);
      }

      this.subscriptions.push(this.viewModel.onGetSelectedDate.subscribe(date => {
          if (date === null || date === undefined) {
              this.selectedDate = this.noSelectionPlaceholder;

              this.dateOrPlaceholderStyle = 'bodyContent placeholder';
          }
          else {
              this.selectedDate = date.format('D-M-YYYY');

              if (this.selectedDate === moment().format('D-M-YYYY')) this.selectedDate = 'Vandaag';

              this.dateOrPlaceholderStyle = 'bodyContent';
          }
      }));

      this.subscriptions.push(this.onClear.pipe(delay(500)).subscribe(_ => this.blockPickerOpenWhileClearing = false));

      if (this.viewModel.onGetNoSelectionPlaceholder) {
          this.subscriptions.push(this.viewModel.onGetNoSelectionPlaceholder.subscribe(placeholder => {
                  const updateDateString = this.noSelectionPlaceholder === this.selectedDate;
                  this.noSelectionPlaceholder = placeholder;
                  if (updateDateString) this.selectedDate = this.noSelectionPlaceholder;
              }));
      }

      if (this.viewModel.onGetHasClearIcon) {
          this.subscriptions.push(this.viewModel.onGetHasClearIcon.subscribe(hasClearIcon => this.hasClearIcon = hasClearIcon));
      }

      this.subscriptions.push(this.viewModel.onGetFeedback.subscribe(feedback => {
          if (feedback?.error) {
              this.dateDisplayStyle = 'dateDisplay error';
              this.feedback = feedback.message;
              this.cdr.markForCheck();
          }
          else {
              this.dateDisplayStyle = this.disabled ? 'dateDisplay disabled' : 'dateDisplay';
              this.feedback = '';
              this.cdr.markForCheck();
          }
      }));
    }

    ngAfterViewInit(): void {
        if (this.viewModel.onGetStartingView) {
            this.subscriptions.push(this.viewModel.onGetStartingView.subscribe(start => {
                if (this.selectedDate !== null && this.selectedDate !== undefined) {
                    this.picker.startAt = start;
                }
            }));
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    public onDateSelected<S>(event: MatDatepickerInputEvent<Moment, S>): void {
        this.viewModel.doSetSelectedDate.next(event.value);
    }

    public clear(): void {
        this.blockPickerOpenWhileClearing = true;
        this.viewModel.doSetSelectedDate.next(null);
        this.input.value = '';
        this.lowlightClear();
        this.onClear.next();
    }

    public open(): void {
        if (!this.blockPickerOpenWhileClearing && !this.disabled) {
            this.picker.open();
        }
    }

    public hightlightClear(): void {
        this.clearIconStyle = 'clear highlight';
    }

    public lowlightClear(): void {
        this.clearIconStyle = 'clear';
    }
}

export interface SelectDateViewModel {
    onGetSelectedDate: Observable<Moment>;
    onGetFeedback: Observable<Feedback>;
    onGetHasClearIcon?: Observable<boolean>;
    onGetNoSelectionPlaceholder?: Observable<string>;
    onGetStartingView?: Observable<Moment>;
    isDisabled?: Observable<boolean>;

    doSetSelectedDate: Subject<Moment>;
}

export interface Feedback {
    error: boolean;
    message: string;
}
