import { Component, OnInit } from '@angular/core';
import {WidgetBaseComponent} from '../widget-base/widget-base.component';

@Component({
  selector: 'app-widget-listcount',
  templateUrl: './widget-listcount.component.html',
  styleUrls: ['../widget-base/widget-base.component.scss', './widget-listcount.component.scss']
})
export class WidgetListcountComponent extends WidgetBaseComponent<number> implements OnInit {

  constructor() {
    super();
  }
}
