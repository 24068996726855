<app-connect-loader [loading]="(loading$ | async)">
  <h1 [class]="isPreview ? '' : 'sticky'">Aanbod producten</h1>
  <div class="koppelpartijen">
    <div class="koppelpartijTegel" *ngFor="let koppelpartij of (koppelpartijen$ | async)" >
      <app-koppelpartij-tegel [koppelpartij]="koppelpartij" (openKoppelpartij)="openKoppelpartij($event)"></app-koppelpartij-tegel>
    </div>
    <div class="koppelpartijTegel" *ngFor="let semParty of (semParties | async)" >
      <app-sem-koppelpartij-tegel [semParty]="semParty" (openSemParty)="openSemParty($event)"></app-sem-koppelpartij-tegel>
    </div>
  </div>
  <p class="blueText">Je hebt alle producten gezien!</p>
  <div class="meerKoppelingenLinkBox" (click)="doorverwijzingPartnerprogramma()">
    <p class="meerKoppelingen">
      Wil je koppelen met een applicatie die hier niet tussen staat? <br />
      Verwijs hen dan door naar het <em>Connect Platform</em>
    </p>
    <i class="icon-arrow"></i>
  </div>
</app-connect-loader>
