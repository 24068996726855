<table mat-table class="mat-table" [ngClass]="{'rows-clickable': model.rowsClickable, 'light-hover': model.lightHover, dense: model.dense}"
       [dataSource]="model.data">
  <ng-container *ngFor="let column of model.columns()" matColumnDef="{{column}}">
    <th mat-header-cell *matHeaderCellDef="let row;"
        [ngClass]="model.getColumnDef(column).header.class"
        [ngStyle]="model.getColumnDef(column).header.style">
      <app-cell-content [model]="row" [cellDef]="model.getColumnDef(column).header"></app-cell-content>
    </th>
    <td mat-cell *matCellDef="let row;"
        [ngClass]="model.getColumnDef(column).body.class"
        [ngStyle]="model.getColumnDef(column).body.style"
        [attr.rowspan]="model.getColumnDef(column).body.getRowspan(row)" [style.display]="model.getColumnDef(column).body.getRowspan(row) === 0 ? 'none'  : ''">
      <app-cell-content [model]="row" [cellDef]="model.getColumnDef(column).body"></app-cell-content>
    </td>
    <td mat-footer-cell *matFooterCellDef="let row"
        [ngClass]="model.getColumnDef(column).footer.class"
        [ngStyle]="model.getColumnDef(column).footer.style">
      <app-cell-content [model]="row" [cellDef]="model.getColumnDef(column).footer"></app-cell-content>
    </td>
  </ng-container>

  <ng-container *ngIf="model.showHeaders">
    <tr mat-header-row *matHeaderRowDef="model.columns(); sticky: true"></tr>
  </ng-container>

  <tr mat-row class="connect-table-row" *matRowDef="let row; columns: model.columns()"
      (click)="onClickedRow(row)" [id]="model.getRowId(row)"
      [class.alternateGroup]="model.getRowgroup && model.getRowgroup(row).groupId % 2 === 1"
      [class.lastInGroup]="model.getRowgroup && model.getRowgroup(row).lastOfGroup"
  ></tr>

  <ng-container *ngIf="model.showFooters">
    <tr mat-footer-row *matFooterRowDef="model.columns(); sticky: model.stickyFooters"></tr>
  </ng-container>
</table>
