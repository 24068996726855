<div class="infoBlok">
    <p>Hieronder staat een samenvatting van deze koppeling. Je kan per Vestiging de koppeling open of dicht zetten.</p>
</div>
<div class="infoBlok">
    <div class="titel">
        <i class="koppeling"></i>
        <h1 class="bodyContentSemi">Toegang via SEM</h1>
    </div>
    <div class="content">
        <div class="classification">
            <i class="protect"></i>
            <span class="labelLabelSemi label succes">
                Vertrouwde koppeling
            </span>
        </div>
        <p class="bodySmallContent">
            Producten die gekoppeld zijn via de SEM koppeling houden zichzelf continu up to date.
        </p>
        <p *ngIf="firstConsent | async" class="bodySmallContent">Aangemaakt op {{ (firstConsent | async).consentGivenAt }} door {{
            (firstConsent | async).consentGivenBy }}</p>
    </div>
</div>

<div *ngIf="(semParty | async)?.consentSince" class="infoBlok">
    <div class="titel">
        <i class="groepen"></i>
        <h1 class="bodyContentSemi">Doelgroepen</h1>
    </div>
    <div class="content">
        <p class="bodySmallContent">
            Toegang tot alle leerlingen van {{
            (semParty | async)?.consent?.length }} vestigingen
        </p>
        <div *ngIf="showDoelgroepen" class="details details--bordered">
            <ng-container *ngFor="let consent of (semParty | async)?.consent">
                <p class="bodySmallContentSemi disabled">
                    <i class="marker"></i>
                    {{ consent.vestigingDescription }}
                    <span class="label labelLabelSemi">Vanaf {{consent.consentGivenAt}}</span>
                </p>
            </ng-container>
        </div>
    </div>
</div>

<div class="veldentable">
    <ng-container *ngFor="let endpoint of (accessedFields | async); let i = index">
        <div (click)="toggleEndpoint(endpoint.name)" [class]="endpointClass(endpoint.name, i)">
            <p class="bodyContentBold name">{{endpoint.name}}</p>
            <i class="icon-arrow-open"></i>
            <div class="categorien">
                <div class="categorie">
                    <p class="bodySmallContentSemi naam">Algemeen</p>
                    <div class="velden">
                        <ng-container *ngFor="let veld of endpoint.fields;">
                            <div class="veld">
                                <div class="iconContainer"><i class="icon dot"></i></div>
                                <p class="bodyContent veldnaam">{{veld.name}}</p>
                                <div *ngIf="veld.description" class="remarkContainer">
                                    <div class="remark">
                                        <div class="tooltip remarkTooltip">
                                            <p class="bodySmallContent">{{veld.description}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <ng-container *ngFor="let categorie of endpoint.nestings;">
                    <div class="categorie">
                        <p class="bodySmallContentSemi naam">{{categorie.name}}</p>
                        <div class="velden">
                            <ng-container *ngFor="let veld of categorie.fields;">
                                <div class="veld">
                                    <div class="iconContainer"><i class="icon dot"></i></div>
                                    <p class="bodyContent veldnaam">{{veld.name}}</p>
                                    <div *ngIf="veld.description" class="remarkContainer">
                                        <div class="remark">
                                            <div class="tooltip remarkTooltip">
                                                <p class="bodySmallContent">{{veld.description}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>
</div>