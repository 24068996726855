import {Vestiging} from '../../generated/graphql';
import * as m from 'moment';

export function rehydrateVestigingen(vestigingen: Vestiging[]): Vestiging[] {
  return vestigingen.map(rehydrateDataSinds);
}

function rehydrateDataSinds(v: Vestiging): Vestiging {
  return {...v, dataSinds: v.dataSinds ? new Date(v.dataSinds) : m().add(1, 'd').toDate()} as Vestiging;
}
