import {RMedewerker} from '../../services/somtoday.service';
import {createReducer, on} from '@ngrx/store';
import {setMe} from './me.actions';
import {reset} from '../app.actions';
import {constant} from 'fp-ts/function';

const initialState: RMedewerker = null;

export const meReducer = createReducer(
  initialState,
  on(setMe, (_, {value}) => value),
  on(reset, constant(initialState))
);
