import {Component, Input, OnInit} from '@angular/core';
import {Kengetal} from './kengetal.model';

@Component({
  selector: 'app-kengetal',
  templateUrl: './kengetal.component.html',
  styleUrls: ['./kengetal.component.scss']
})
export class KengetalComponent implements OnInit {
  @Input() kengetal: Kengetal;
  aantal: string;

  ngOnInit() {
    if (this.kengetal.aantal.length === 1) {
      this.aantal = String(this.kengetal.aantal[0]);
    } else {
      this.aantal = (this.kengetal.aantal[0] / this.kengetal.aantal[1] * 100).toFixed(0) + '%';
    }
  }
}
