<ng-template #portalContent>
  <ng-container *ngIf="isOpen$ | async">
    <div class="sidebar">
      <div class="header">
        <h2 class="headingHead">Gegevens in export: {{(list$ | async)?.length ? (list$ | async)?.length : 'Geen'}}</h2>
        <i class="icon-close" (click)="closeSidebar()"></i>
      </div>
      <p><b>Onderwerp</b></p>
      <div class="sidebar__gegeven" *ngFor="let gegeven of list$ | async">
        <span>{{gegeven}}</span>
      </div>
    </div>
  </ng-container>
</ng-template>
