import {Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-webservice-tegel',
  templateUrl: './webservice-tegel.component.html',
  styleUrls: ['./webservice-tegel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '(click)': 'onWebserviceClicked()'
  }
})
export class WebserviceTegelComponent implements OnInit {

  @Input()
  public title: string;

  @Input()
  public icon: Risk;

  @Input()
  public txt: string;

  @Output()
  openWebservice = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  onWebserviceClicked() {
    this.openWebservice.emit();
  }

}

export const enum Risk {
  HIGH_RISK = 'high_risk',
  MEDIUM_RISK = 'medium_risk'
}
