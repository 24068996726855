import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-connect-loader',
  templateUrl: './connect-loader.component.html',
  styleUrls: ['./connect-loader.component.scss']
})
export class ConnectLoaderComponent {
  @Input()
  loading: boolean;

  @Input()
  whitebackground = false;

  @Input()
  showText = true;
}
