import {createReducer, on} from '@ngrx/store';
import {setErrorMessages} from './errors.actions';

export type ErrorsState = Set<string>;

const initialState = new Set<string>();

export const errorsReducer = createReducer(
  initialState,
  on(setErrorMessages, (_, {value}) => value)
);
