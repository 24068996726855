import { Component, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../../environments/environment';
import {AnalyticsService, Metrics} from '../services/analytics.service';
import {MatDialog} from '@angular/material/dialog';
import {UserFeedbackDialogComponent} from '../user-feedback-dialog/user-feedback-dialog.component';
import {OauthConfigurationService} from '../services/oauth.configuration.service';
import {SomtodayService} from '../services/somtoday.service';
import {ActivatedRoute, Router, RouterState} from '@angular/router';


@Component({
    selector: 'app-feedback-button',
    templateUrl: './user-feedback-button.component.html',
    styleUrls: ['./user-feedback-button.component.scss']
})
export class UserFeedbackButtonComponent implements OnInit {
    constructor(public dialog: MatDialog, private somtoday: SomtodayService, private oauthService: OAuthService, private oauthConfigService: OauthConfigurationService, private router: Router) {}

    ngOnInit(): void {
    }

    public openDialog() {
        this.dialog.open(UserFeedbackDialogComponent, {data: {message: "", score: null, route:this.router.routerState.snapshot.url}});
    }

    public isGebruikerIngelogd(): boolean {
        return this.oauthService && this.oauthService.hasValidIdToken() && this.oauthConfigService.isMedewerker();
    }
}
