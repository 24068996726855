<div class="contentbox">
  <div class="lesgroep-titlebox">
    <p class="lesgroepnaam">{{details?.naam}}</p>
    <p class="aantal-leerlingen">{{details?.aantalLeerlingen}} leerlingen</p>
  </div>
  <div class="docenten-box">
    <p class="docenten-title">Docenten</p>
    <ul *ngIf="details" class="bulletloze-lijst">
      <li class="docent-regel" *ngFor="let medewerker of (medewerkers$ | async)">{{medewerker | medewerkerVoornaamAchternaamAfkorting}}</li>
    </ul>
  </div>
</div>


