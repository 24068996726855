<div *ngIf="leermiddel.gekoppeldAanVakken.length === 0" class="koppel" (click)="open()">
    <div class="bodyContentSemi geenVak">Geen vak</div>
    <div class="icon-koppel"></div>
</div>
<div *ngIf="leermiddel.gekoppeldAanVakken.length !== 0" class="vakken" (click)="open()">
    <span class="pill">{{leermiddel.gekoppeldAanVakken[0].afkorting}}</span>
    <span *ngIf="leermiddel.gekoppeldAanVakken.length > 1"
        class="pill">{{leermiddel.gekoppeldAanVakken[1].afkorting}}</span>
    <span *ngIf="leermiddel.gekoppeldAanVakken.length === 3"
        class="pill">{{leermiddel.gekoppeldAanVakken[2].afkorting}}</span>
    <span *ngIf="leermiddel.gekoppeldAanVakken.length > 3"
        class="pill plus">+{{(leermiddel.gekoppeldAanVakken.length - 2)}}</span>
    <div class="icon-ontkoppel" (click)="ontkoppel($event)"></div>
</div>
