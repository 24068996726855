import {ChangeDetectionStrategy, Component, Input, OnChanges} from '@angular/core';
import { ChartType, ChartOptions } from 'chart.js';
import {SingleDataSet, PluginServiceGlobalRegistrationAndOptions} from 'ng2-charts';
import {
  actiefGebruik,
  geenActivatie,
  welActivatieGeenGebruik,
  geenActivatieGrijs,
  welActivatieGeenGebruikGrijs,
  actiefGebruikGrijs
} from '../../../shared/colors';
import { Leermiddelinfo, } from '../enkel-leermiddel-activatie-of-gebruik/leermiddelinfo.model';
import {FONT_FAMILY} from '../../../shared/fonts';

@Component({
  selector: 'app-enkel-leermiddel-activatiegraad',
  templateUrl: './enkel-leermiddel-activatiegraad.component.html',
  styleUrls: ['../widget-base/widget-base.component.scss', './enkel-leermiddel-activatiegraad.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnkelLeermiddelActivatiegraadComponent implements OnChanges {
  @Input() public leermiddelinfo: Leermiddelinfo;
  public doughnutChartData: SingleDataSet;
  public leermiddelNaam: string;
  public doughnutChartColors;

  public doughnutChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      display: false,
      position: 'right'
    },
    cutoutPercentage: 85,
    tooltips: {
      enabled: false
    }
  };
  public doughnutChartType: ChartType = 'doughnut';
  public doughnutChartLabels = ['Geactiveerd', 'Niet geactiveerd'];
  public doughnutChartPlugins: PluginServiceGlobalRegistrationAndOptions[] = [{
    afterDraw(chart: any) {
      const ctx = chart.ctx;
      const total = chart.tooltip._data.datasets[0].data.reduce((sum, num) => sum + num);
      let txt1 = '';
      let txt2 = '';

      if (chart.active !== undefined && chart.tooltip._active[0] !== undefined) {
        const percentage = Math.round((chart.tooltip._data.datasets[0].data[chart.tooltip._active[0]._index] / total * 100));
        txt1 = percentage + '%';
        txt2 = chart.tooltip._data.datasets[0].data[chart.tooltip._active[0]._index];
      } else {
        const percentage = Math.round((chart.tooltip._data.datasets[0].data[0] / total * 100));
        txt1 = percentage + '%';
        txt2 = chart.tooltip._data.datasets[0].data[0];
      }

      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
      const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);

      const fontSizeToUse = 35;
      ctx.font = fontSizeToUse + `px ${FONT_FAMILY}`;
      ctx.fillStyle = 'black';

      ctx.fillText(txt1, centerX + 5, centerY - 10);
      ctx.font = 20 + 'px Arial';
      ctx.fillStyle = 'gray';
      ctx.fillText(txt2, centerX, centerY + 30);
    }
  }];

  ngOnChanges(): void {
    this.doughnutChartData = this.leermiddelinfo.getallen;
    this.leermiddelNaam = this.leermiddelinfo.leermiddelNaam;
    this.bepaalkleur();
  }

  bepaalkleur() {
    const total = this.leermiddelinfo.getallen.reduce((sum, num) => sum + num);
    const percentage = Math.round((this.leermiddelinfo.getallen[0] / total * 100));
    if (percentage < 34) {
      this.doughnutChartColors = [{backgroundColor: [geenActivatie, geenActivatieGrijs]}];
    } else if (percentage > 33 && percentage < 67) {
      this.doughnutChartColors = [{backgroundColor: [welActivatieGeenGebruik, welActivatieGeenGebruikGrijs]}];
    } else {
      this.doughnutChartColors = [{backgroundColor: [actiefGebruik, actiefGebruikGrijs]}];
    }
  }
}
