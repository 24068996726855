import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'SingleDecimalNumber'})
export class SingleDecimalNumber implements PipeTransform {
  public transform(number: number): string {
    return singleDecimalNumber(number);
  }
}

export function singleDecimalNumber(number: number): string {
  if (number === null || number === undefined) {
    return '';
  } else if (number === 0) {
    return '0.0';
  } else {
    return number.toFixed(1);
  }
}
