import {Pipe, PipeTransform} from '@angular/core';
import {createColumnDef, createDefaultFooterCellDef, createModel, TableModel} from '../../table/table/table.model';
import {KeyCellComponent} from '../../table/key-cell/key-cell.component';
import {LabelCellComponent} from '../../table/label-cell/label-cell.component';
import {JustifiedNumberCellComponent} from '../../table/justified-number-cell/justified-number-cell.component';
import {LesgroepActivatiegetalCellComponent} from '../../table/lesgroep-activatiegetal-cell/lesgroep-activatiegetal-cell.component';
import {ActivatiegetalCellComponent} from '../../table/activatiegetal-cell/activatiegetal-cell.component';
import {HelpHeaderCellComponent} from '../../table/help-header-cell/help-header-cell.component';
import {ProgressbarCellComponent} from '../../table/progressbar-cell/progressbar-cell.component';
import {RangebarCellComponent} from '../../table/rangebar-cell/rangebar-cell.component';
import {LinechartCellComponent} from '../../table/linechart-cell/linechart-cell.component';
import {RangelinechartCellComponent} from '../../table/rangelinechart-cell/rangelinechart-cell.component';
import {ModalwindowService} from '../../services/modalwindow.service';
import {
  LeermiddelData,
  LeermiddelOnderwijssoortDagGebruikData
} from '../state/vakloosleermiddeldashboard/vakloosleermiddeldashboard.selectors';

@Pipe({name: 'vakloosleermiddelTable'})
export class VakloosleermiddeldashboardTabelPipe implements PipeTransform {
  constructor(private modalwindowService: ModalwindowService) {
  }

  transform({data, totaal}: LeermiddelData): TableModel<LeermiddelOnderwijssoortDagGebruikData> {
    const model = createModel<LeermiddelOnderwijssoortDagGebruikData>(data, d => d.onderwijssoortJaar);
    model.showFooters = true;
    model.rowsClickable = true;

    const ondJaar = createColumnDef<LeermiddelOnderwijssoortDagGebruikData>('onderwijssoortJaar', 'Onderwijssoort');
    ondJaar.body.component = KeyCellComponent;
    ondJaar.body.style = {width: '15%'};
    ondJaar.footer.component = LabelCellComponent;
    ondJaar.footer.getValue = () => 'Totaal';

    const leerlingenAantal = createColumnDef<LeermiddelOnderwijssoortDagGebruikData>('leerlingenAantal', '');
    leerlingenAantal.body.component = JustifiedNumberCellComponent;
    leerlingenAantal.body.style = {width: '10%'};
    leerlingenAantal.footer.component = JustifiedNumberCellComponent;
    leerlingenAantal.footer.getValue = () => totaal.leerlingenAantal;

    const leerlingenGeactiveerd = createColumnDef<LeermiddelOnderwijssoortDagGebruikData>('leerlingenGeactiveerd', 'Activatie');
    leerlingenGeactiveerd.body.component = LesgroepActivatiegetalCellComponent;
    leerlingenGeactiveerd.body.getValue = rowModel => ({geactiveerd: rowModel.leerlingenGeactiveerd, totaal: rowModel.leerlingenAantal});
    leerlingenGeactiveerd.body.style = {width: '10%'};
    leerlingenGeactiveerd.footer.component = ActivatiegetalCellComponent;
    leerlingenGeactiveerd.footer.getValue = () => totaal.leerlingenGeactiveerd;

    const waarvanActief = createColumnDef<LeermiddelOnderwijssoortDagGebruikData>('waarvanActief', );
    const actiefHulp = () => this.modalwindowService.toonOnderwerp('actief');
    waarvanActief.header.component = HelpHeaderCellComponent;
    waarvanActief.header.getValue = () => ({title: 'Waarvan recent actief', help: actiefHulp});
    waarvanActief.body.component = ProgressbarCellComponent;
    waarvanActief.body.getValue = rowModel => ({
      totaal: rowModel.leerlingenGeactiveerd,
      actief: rowModel.waarvanActief,
      hidden: rowModel.leerlingenGeactiveerd === 0
    });
    waarvanActief.body.style = {width: '30%'};
    waarvanActief.footer = createDefaultFooterCellDef('waarvanActief');
    waarvanActief.footer.component = RangebarCellComponent;
    waarvanActief.footer.getValue = () => {
      return ({
        model: totaal.waarvanActief,
        hidden: totaal.leerlingenGeactiveerd.max === 0,
        minGebruikPerWeek: totaal.gebruikPerLeerlingPerWeek.min,
        maxGebruikPerWeek: totaal.gebruikPerLeerlingPerWeek.max
      });
    };

    const gebruik = createColumnDef<LeermiddelOnderwijssoortDagGebruikData>('gebruik');
    const gebruikHulp = () => this.modalwindowService.toonOnderwerp('gebruik');
    gebruik.header.component = HelpHeaderCellComponent;
    gebruik.header.getValue = () => ({title: 'Gebruik per leerling', help: gebruikHulp});
    gebruik.body.component = LinechartCellComponent;
    gebruik.body.getValue = rowModel => ({
      gebruikPerWeek: rowModel.gebruikPerLeerlingPerWeek,
      data: rowModel.leermiddelOnderwijssoortDagData,
      hidden: rowModel.leerlingenGeactiveerd === 0
    });
    gebruik.body.style = {width: '30%'};
    gebruik.footer.component = RangelinechartCellComponent;
    gebruik.footer.getValue = () => ({
      model: totaal.leermiddelDagData,
      totaal: totaal.gebruikPerLeerlingPerWeek,
      hidden: totaal.leerlingenGeactiveerd.max === 0
    });

    model.columnDefs = [ondJaar, leerlingenAantal, leerlingenGeactiveerd, waarvanActief, gebruik];
    return model;
  }
}
