import {Component, Input, OnInit} from '@angular/core';
import {KeyCellComponent} from '../../table/key-cell/key-cell.component';
import {LabelCellComponent} from '../../table/label-cell/label-cell.component';
import {createColumnDef, createModel, TableModel} from '../../table/table/table.model';
import {PercentLabelComponent} from '../../table/percent-label/percent-label.component';
import {GebruikperweekComponent} from '../../table/gebruikperweek/gebruikperweek.component';
import {LesgroepLeermiddelGebruikTabelData, LesgroepLeermiddelGebruikTabelRegel} from '../state/klassendashboard/klassendashboard.selector';

@Component({
  selector: 'app-lesgroep-detail-leermiddel-gebruik-tabel',
  templateUrl: './lesgroep-detail-leermiddel-gebruik-tabel.component.html',
  styleUrls: ['./lesgroep-detail-leermiddel-gebruik-tabel.component.scss']
})
export class LesgroepDetailLeermiddelGebruikTabelComponent implements OnInit {

  tabelModel: TableModel<LesgroepLeermiddelGebruikTabelRegel>[];

  @Input()
  set tabeldata(tabeldata: LesgroepLeermiddelGebruikTabelData) {
    this.tabelModel = [];
    if (tabeldata !== null && tabeldata !== undefined) {
        this.tabelModel.push(this.createModel(tabeldata));
    }
  }

  constructor() { }

  ngOnInit() {
  }

  private createModel(data: LesgroepLeermiddelGebruikTabelData): TableModel<LesgroepLeermiddelGebruikTabelRegel> {
    const model = createModel<LesgroepLeermiddelGebruikTabelRegel>(data.regels, regel => regel.lesgroepNaam);
    model.showFooters = true;
    model.rowsClickable = false;
    model.stickyFooters = false;

    const leermiddelnaam = createColumnDef<LesgroepLeermiddelGebruikTabelRegel>('methodeNaam');
    leermiddelnaam.header.component = LabelCellComponent;
    leermiddelnaam.header.getValue = () => (data.methodenaam);
    leermiddelnaam.header.style = {
      backgroundColor: '#F7FAFC',
      borderRadius: '3px 0px 0px 0px',
      borderTop: '1px solid #BEE1F0',
      borderLeft: '1px solid #BEE1F0',
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '600',
      color: '#000829'
    };
    leermiddelnaam.body.component = KeyCellComponent;
    leermiddelnaam.body.getValue = rowmodel => rowmodel.lesgroepNaam;
    leermiddelnaam.body.style = {
      width: '25%',
      borderLeft: '1px solid #BEE1F0'
    };
    leermiddelnaam.footer.component = KeyCellComponent;
    leermiddelnaam.footer.getValue = () => ('Schoolbreed ');
    leermiddelnaam.footer.style = {borderLeft: '1px solid #BEE1F0'};

    const activatie = createColumnDef<LesgroepLeermiddelGebruikTabelRegel>('activatie', 'Activatie');
    activatie.header.component = LabelCellComponent;
    activatie.header.style = {
      backgroundColor: '#F7FAFC',
      borderTop: '1px solid #BEE1F0',
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '600',
      color: '#53647E'
    };
    activatie.body.component = PercentLabelComponent;
    activatie.body.getValue = rowmodel => (rowmodel.lesgroepActivatie / 100).toFixed(3);
    activatie.body.style = {
      width: '25%'
    };
    activatie.footer.component = PercentLabelComponent;
    activatie.footer.getValue = () => (data.totaalActivatie / 100).toFixed(3);

    const recentActief = createColumnDef<LesgroepLeermiddelGebruikTabelRegel>('waarvanRecentActief', 'Waarvan recent actief');
    recentActief.header.component = LabelCellComponent;
    recentActief.header.style = {
      backgroundColor: '#F7FAFC',
      borderTop: '1px solid #BEE1F0',
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '600',
      color: '#53647E'
    };
    recentActief.body.component = PercentLabelComponent;
    recentActief.body.getValue = rowmodel => (rowmodel.lesgroepRecentActief / 100).toFixed(3);
    recentActief.body.style = {
      width: '25%'
    };
    recentActief.footer.component = PercentLabelComponent;
    recentActief.footer.getValue = () => (data.totaalRecentActief / 100).toFixed(3);

    const gebruikPerWeek = createColumnDef<LesgroepLeermiddelGebruikTabelRegel>('gebruikPerWeek', 'Gebruik per week');
    gebruikPerWeek.header.component = LabelCellComponent;
    gebruikPerWeek.header.style = {
      backgroundColor: '#F7FAFC',
      borderRadius: '0px 3px 0px 0px',
      borderTop: '1px solid #BEE1F0',
      borderRight: '1px solid #BEE1F0',
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '600',
      color: '#53647E'
    };
    gebruikPerWeek.body.component = GebruikperweekComponent;
    gebruikPerWeek.body.getValue = rowmodel => ({gebruikPerWeek: (+rowmodel.lesgroepGebruikPerWeek).toFixed(1)});
    gebruikPerWeek.body.style = {
      width: '25%',
      borderRight: '1px solid #BEE1F0'
    };
    gebruikPerWeek.footer.component = GebruikperweekComponent;
    gebruikPerWeek.footer.getValue = () => ({gebruikPerWeek: (+data.totaalGebruikPerWeek).toFixed(1)});
    gebruikPerWeek.footer.style = {borderRight: '1px solid #BEE1F0'};

    model.columnDefs = [leermiddelnaam, activatie, recentActief, gebruikPerWeek];

    return model;
  }
}
