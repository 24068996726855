import {Component, HostBinding, OnDestroy} from '@angular/core';
import {MenuService} from './menu.service';
import {BaseMenu} from './base-menu';
import {ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {DeviceService} from '../services/device.service';
import {Subject} from 'rxjs';
import {stringify} from 'query-string';
import {environment} from '../../environments/environment';
import {OAuthService} from 'angular-oauth2-oidc';
import {AppState} from '../state/app.state';
import {Store} from '@ngrx/store';
import {
  isLabSupport,
  magAVGDashboardInzien,
  magBeperkteAVGDashboardInzien,
  magLeermiddelDashboardInzien,
  selectInstellingsNaam
} from '../state/me/me.selectors';
import {EnabledConfig} from '../../environments/required.connect.config';
import {routingConstants} from '../stateless/routing.constants';

@Component({
  selector: 'app-menu-left',
  templateUrl: './menu-left.component.html',
  styleUrls: ['./menu-left.component.scss']
})
export class MenuLeftComponent extends BaseMenu implements OnDestroy {

  private scholenDieNieuweDashboardsMogenInzien: string[] = [
    'Het Hooghuis',
    'Test College',
  ];

  @HostBinding('class.is-open') menuOpen: boolean;

  private unsubscribe: Subject<void> = new Subject<void>();

  public menuState: Map<string, boolean>;

  public buildMoment: string;

  public routingConstants = routingConstants;

  magLeermiddelDashboardsInzien: boolean;

  magAVGDashboardInzien: boolean;

  magBeperkteAVGDashboardInzien: boolean;

  isLandelijkBeheerSupport: boolean;

  instellingNaam: string;

  get gridClass(): object {
    return {
      'main-menu': true,
      'rows-1': !(this.magAVGDashboardInzien || this.magBeperkteAVGDashboardInzien) && !this.magLeermiddelDashboardsInzien,
      'rows-2': (this.magAVGDashboardInzien || this.magBeperkteAVGDashboardInzien) && !this.magLeermiddelDashboardsInzien,
      'rows-3': this.magLeermiddelDashboardsInzien && !(this.magAVGDashboardInzien || this.magBeperkteAVGDashboardInzien),
      'rows-4': this.magLeermiddelDashboardsInzien && (this.magAVGDashboardInzien || this.magBeperkteAVGDashboardInzien)
    };
  }

  constructor(menuService: MenuService,
              private router: Router,
              deviceService: DeviceService,
              private route: ActivatedRoute,
              private oauthService: OAuthService,
              private store: Store<AppState>) {
    super(menuService, deviceService);
    if (!environment.production) {
      this.buildMoment = environment.buildMoment;
    }
    this.menuService.menuStateChanges.pipe(takeUntil(this.unsubscribe)).subscribe(newMenuState => {
      this.menuState = newMenuState;
      this.menuOpen = this.menuState.get('Main');
    });
    this.store.select(isLabSupport).pipe(takeUntil(this.unsubscribe))
      .subscribe(v => this.isLandelijkBeheerSupport = v);
    this.store.select(magLeermiddelDashboardInzien)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(v => this.magLeermiddelDashboardsInzien = v || this.isDev());
    this.store.select(magAVGDashboardInzien)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(v => this.magAVGDashboardInzien = v || this.isDev());
    this.store.select(magBeperkteAVGDashboardInzien)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(v => this.magBeperkteAVGDashboardInzien = v || this.isDev());
    this.store.select(selectInstellingsNaam)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(n => this.instellingNaam = n);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  isRouterLinkActive(items: string[]): boolean {
    return items.some(item => this.router.isActive(item, false));
  }

  onClick(menu: string, link: string): void {
    this.router.navigateByUrl(`${link}?${stringify(this.route.snapshot.queryParams)}`);
  }

  somtodayButtonClick(): void {
    const omgeving = environment.configurationName;
    const tenant = this.oauthService.getCustomTokenResponseProperty('somtoday_tenant');
    let somtodayOopUrl;
    switch (omgeving) {
      case 'Development':
        somtodayOopUrl = 'http://localhost:8080/iridium';
        break;
      case 'Test':
      case 'Acceptatie':
        somtodayOopUrl = `https://${tenant ? tenant + '-' : ''}oop.${omgeving.toLowerCase()}.somtoday.nl`;
        break;
      case 'Nightly':
        somtodayOopUrl = `https://oop.nightly.somtoday.build`;
        break;
      default:
        somtodayOopUrl = `https://${tenant ? tenant + '-' : ''}oop.somtoday.nl`;
    }
    window.location.replace(somtodayOopUrl);
  }

  onHomeClicked(): void {
    this.router.navigate(['home']);
    this.closeMenu('Main');
  }

  toonDashboardKnop(oud: boolean): boolean {
    return(this.isFeatureActief(oud ? 'schooldashboardold' : 'schooldashboard'));
  }

  isProductie(): boolean {
    return 'Productie' === environment.configurationName;
  }

  isTest(): boolean  {
    return 'Test' === environment.configurationName;
  }

  isNietProductieOfTest(): boolean {
    return 'Test' !== environment.configurationName && 'Productie' !== environment.configurationName;
  }

  isDev(): boolean {
    return 'Development' === environment.configurationName;
  }

  isFeatureActief(feature: 'schooldashboardold' | 'schooldashboard' | 'studiewijzerlinkjesdashboard' | 'studiewijzerlinkjesdashboardnew'): boolean {
    switch (feature) {
      case routingConstants.SCHOOL_DASHBOARD_OLD:
        return this.isEnabled(environment.oldDashboardsEnabled);
      case routingConstants.SCHOOL_DASHBOARD_NEW:
        return this.isEnabled(environment.newDashboardsEnabled);
      case routingConstants.STUDIEWIJZER_LINKJES_OLD:
        return this.isEnabled(environment.oldStudiewijzerLinkjesEnabled);
      case routingConstants.STUDIEWIJZER_LINKJES_NEW:
        return this.isEnabled(environment.newStudiewijzerLinkjesEnabled);
    }

    return true;
  }

  private isEnabled(cfg: EnabledConfig): boolean {
    if (!cfg) {
      return true;
    }

    if (!cfg.changeFrom) {
      return cfg.enabled;
    }

    return new Date(Date.now()) > cfg.changeFrom
        ? !cfg.enabled
        : cfg.enabled;
  }

}
