import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../state/app.state';
import {Observable} from 'rxjs';
import {
  LesgroepDetails,
  LesgroepLeermiddelGebruikGrafiekData,
  LesgroepLeermiddelGebruikTabelData,
  selectGrafiekData,
  selectMedewerkersVanKlas,
  selectSamenvattingDetails,
  selectTabelData
} from '../klassendashboard/klassendashboard.selector';
import {loadDocenten, loadKlasSamenvatting, loadMedewerkers} from './schooldashboard.actions';
import {selectDocentenVanVak, selectVakDataMetDocenten, VakData} from '../vakdashboard/vakdashboard.selectors';
import {selectDocenten, selectLoadDocenten} from '../docenten/docenten.selector';
import {SchooldashboardModel} from '../../schooldashboard/schooldashboard.model';
import {
  schooldashboardOnderwijssoortData,
  schooldashboardVakData,
  selectBazenbannerData,
  selectOpenOnderwijssoorten,
  selectOpenVakken
} from './schooldashboard.selectors';
import {BazenbannerModel} from '../../../layout/model/bazenbanner.model';
import {selectFragment, selectGroeperenOpVak} from '../../../state/router/router.selectors';
import {selectLoadMedewerkers} from '../medewerkers/medewerkers.selector';
import {BaseFacade} from '../../../state/base.facade';
import {map, withLatestFrom} from 'rxjs/operators';
import {selectLoading} from '../../../state/request-count/request-count.selectors';
import {LesgroepDocenten} from './schooldashboard.state';

@Injectable()
export class SchooldashboardFacade extends BaseFacade{
  constructor(store: Store<AppState>) {
    super(store);
  }

  selectSchooldashboardVakData(): Observable<SchooldashboardModel[]> {
    return this.selectWithDatumProp(schooldashboardVakData);
  }

  selectSchooldashboardOnderwijssoortData(): Observable<SchooldashboardModel[]> {
    return this.selectWithDatumProp(schooldashboardOnderwijssoortData);
  }

  selectGroeperenOpVak(): Observable<boolean> {
    return this.select(selectGroeperenOpVak);
  }

  selectScrollToId(): Observable<string> {
    return this.select(selectFragment);
  }

  selectBazenBannerData(): Observable<BazenbannerModel> {
    return this.selectWithDatumProp(selectBazenbannerData);
  }

  selectVakDataMetDocenten(): Observable<VakData> {
    return this.selectWithDatumProp(selectVakDataMetDocenten);
  }

  selectLoadDocenten(): Observable<boolean> {
    return this.select(selectLoadDocenten).pipe(
      withLatestFrom(this.select(selectLoading)),
      map(([shouldLoad, isLoading]) => shouldLoad && !isLoading)
    );
  }

  loadDocenten() {
    this.dispatchDatumAction(loadDocenten);
  }

  selectDocenten(): Observable<LesgroepDocenten> {
    return this.select(selectDocenten);
  }

  loadKlasSamenvatting() {
    this.dispatchDatumAction(loadKlasSamenvatting);
  }

  selectKlasSamenvattingDetails(): Observable<LesgroepDetails> {
    return this.select(selectSamenvattingDetails);
  }

  selectKlasSamenvattingGrafiekData(): Observable<LesgroepLeermiddelGebruikGrafiekData[]> {
    return this.select(selectGrafiekData);
  }

  selectKlasSamenvattingTabelData(): Observable<LesgroepLeermiddelGebruikTabelData[]> {
    return this.select(selectTabelData);
  }

  selectLoadMedewerkers(): Observable<boolean> {
    return this.select(selectLoadMedewerkers).pipe(
      withLatestFrom(this.select(selectLoading)),
      map(([shouldLoad, isLoading]) => shouldLoad && !isLoading)
    );
  }

  loadMedewerkers(uuids: string[]) {
    this.dispatch(loadMedewerkers, uuids);
  }

  selectMedewerkersVanKlas(): Observable<string[]> {
    return this.select(selectMedewerkersVanKlas);
  }

  selectDocentenVanVak(): Observable<string[]> {
    return this.select(selectDocentenVanVak);
  }

  selectOpenVakken(): Observable<string[]> {
    return this.select(selectOpenVakken);
  }

  selectOpenOnderwijssoorten(): Observable<string[]> {
    return this.select(selectOpenOnderwijssoorten);
  }
}
