import {createAction, props} from '@ngrx/store';
import {Koppelpartij} from './dev-koppelpartij.state';
import {
  AVGExtendedVeldpermissie,
  AVGOAuthClient,
  AVGOrganisatie,
  AVGWritePermissie
} from '../../dto/avg-dashboard-model-classes';
import {Payload} from '../payload';

export const setKoppelpartij = createAction('[Dev Koppelpartij] Set the fetched koppelpartij', props<{ koppelpartij: Koppelpartij }>());

export const setLeespermissies = createAction('[Dev Koppelpartij] Set the fetched leespermissies',  props<Payload<AVGExtendedVeldpermissie[]>>());

export const setSchrijfpermissies = createAction('[Dev Koppelpartij] Set the fetched schrijfpermissies',  props<Payload<AVGWritePermissie[]>>());

export const setOrganisaties = createAction('[Dev Koppelpartij] Set the fetched organisaties',  props<Payload<AVGOrganisatie[]>>());

export const setOAuthClient = createAction('[Dev Koppelpartij] Set the fetched oauthclient',  props<Payload<AVGOAuthClient>>());

export const setEndpoints = createAction('[Dev Koppelpartij] Set the fetched endpoints',  props<Payload<string[]>>());

export const updateKoppelpartijNaam = createAction('[Dev Koppelpartij] Update KoppelpartijNaam', props<{ koppelpartijNaam: string}>());

export const updateKoppelpartijOmschrijving = createAction('[Dev Koppelpartij] Update KoppelpartijOmschrijving', props<{ koppelpartijOmschrijving: string}>());

export const updateKoppelpartijBedrijfsnaam = createAction('[Dev Koppelpartij] Update KoppelpartijBedrijfsnaam', props<{ koppelpartijBedrijfsnaam: string}>());

export const updateKoppelpartijLogoUrl = createAction('[Dev Koppelpartij] Update KoppelpartijLogoUrl', props<{ koppelpartijLogoUrl: string}>());

export const updateDocentLink = createAction('[Dev Koppelpartij] Update DocentLink', props<{ docentLink: string}>());

export const updateLeerlingLink = createAction('[Dev Koppelpartij] Update LeerlingLink', props<{ leerlingLink: string}>());

export const updateKorteOmschrijving = createAction('[Dev Koppelpartij] Update KorteOmschrijving', props<{ koppelpartijKorteOmschrijving: string}>());

export const updateOpLeerlingNiveauGeautoriseerd = createAction('[Dev Koppelpartij] Update OpLeerlingNiveauGeautoriseerd');

export const updateBenodigdeVeldpermissie = createAction('[Dev Koppelpartij] Update BenodigdeVeldpermissies', props<{ benodigdeVeldpermissies: AVGExtendedVeldpermissie[]}>());

export const updateKoppelpartijAfbeeldingen = createAction('[Dev Koppelpartij] Update Afbeeldingen', props<{ koppelpartijAfbeeldingen: string[]}>());

export const updateKoppelpartijOrganisaties = createAction('[Dev Koppelpartij] Update KoppelpartijOrganisaties', props<{ zichtbaarVoorOrganisaties: AVGOrganisatie[]}>());

export const deleteSchrijfpermissie = createAction('[Dev Koppelpartij] Delete schrijfpermissie', props<{ schrijfpermissie: AVGWritePermissie}>());

export const addSchrijfpermissie = createAction('[Dev Koppelpartij] Add schrijfpermissie', props<{ schrijfpermissie: AVGWritePermissie}>());

export const saveKoppelpartij = createAction('[Dev Koppelpartij] Save koppelpartij');

export const setSuccesfullySent = createAction('[Dev Koppelpartij] Set succesfully sent', props<{ succesfullySent: boolean }>());

export const setEndpointsSelected = createAction('[Dev Koppelpartij] Set endpoint selected', props<{ endpointNames: string[] }>());
