import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import {StudiewijzerlinkjesNewFacade} from '../../state/studiewijzerlinkjes/studiewijzerlinkjes.facade';

@Component({
  selector: 'app-studiewijzerlinkjes-vaksectie-platform-details',
  templateUrl: './studiewijzerlinkjes-vaksectie-platform-details.component.html',
  styleUrls: ['./studiewijzerlinkjes-vaksectie-platform-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudiewijzerlinkjesVaksectiePlatformDetailsComponent implements OnInit {

  constructor(store: StudiewijzerlinkjesNewFacade) { }

  ngOnInit(): void {
  }

}
