import {Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter} from '@angular/core';
import {AVGExtendedKoppelpartij} from '../../dto/avg-dashboard-model-classes';

@Component({
  selector: 'app-koppelpartij-tegel',
  templateUrl: './koppelpartij-tegel.component.html',
  styleUrls: ['./koppelpartij-tegel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '(click)': 'onKoppelpartijClicked(koppelpartij.uuid)'
  }
})
export class KoppelpartijTegelComponent implements OnInit {

  @Input()
  koppelpartij: AVGExtendedKoppelpartij;

  @Output()
  openKoppelpartij = new EventEmitter<string>();



  constructor() { }

  ngOnInit(): void {
  }

  onKoppelpartijClicked(uuid: string) {
    this.openKoppelpartij.emit(uuid);
  }
}
