import {ChangeDetectionStrategy, Component, Input, OnChanges} from '@angular/core';
import { ChartType, ChartOptions } from 'chart.js';
import {SingleDataSet, PluginServiceGlobalRegistrationAndOptions} from 'ng2-charts';
import {
  primary_3,
  accent_positive_2, accent_positive_3
} from '../../../shared/colors';
import {FONT_FAMILY} from '../../../shared/fonts';

@Component({
  selector: 'app-enkel-activatiegraad-lesgroepen',
  templateUrl: './enkel-activatiegraad-lesgroepen.component.html',
  styleUrls: ['../widget-base/widget-base.component.scss', './enkel-activatiegraad-lesgroepen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class EnkelActivatiegraadLesgroepenComponent implements OnChanges {
  @Input() public procentGeactiveerd: number;
  public doughnutChartData: SingleDataSet;
  public doughnutChartColors = [{backgroundColor: [accent_positive_2, accent_positive_3]}];

  public doughnutChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      display: false,
    },
    cutoutPercentage: 80,
    tooltips: {
      enabled: false
    },
    hover: {mode: null},
    elements: {
      arc: {
        borderWidth: 0
      }
    },
    layout: {
      padding: {
        bottom: 10
      }
    }
  };
  public doughnutChartType: ChartType = 'doughnut';
  public doughnutChartPlugins: PluginServiceGlobalRegistrationAndOptions[] = [{
    afterDraw(chart: any) {
      const ctx = chart.ctx;
      const total = chart.tooltip._data.datasets[0].data.reduce((sum, num) => sum + num);
      let txt1 = '';
      const percentage = Math.round((chart.tooltip._data.datasets[0].data[0] / total * 100));
      txt1 = percentage + '%';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
      const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2) + 2;

      const fontSizeToUse = 35;
      ctx.font = fontSizeToUse + `px ${FONT_FAMILY}`;
      ctx.fillStyle = primary_3;

      ctx.fillText(txt1, centerX, centerY );
      ctx.font = 20 + 'px Arial';
      ctx.fillStyle = 'gray';

      const originalStroke = ctx.stroke;
      ctx.stroke = function() {
        ctx.save();
        ctx.shadowColor = accent_positive_2;
        ctx.shadowBlur = 10;
        ctx.shadowOffsetX = 0;
        ctx.shadowOffsetY = 2;
        originalStroke.apply(this, arguments);
        ctx.restore();
      };
    }
  }];

  ngOnChanges(): void {
    this.doughnutChartData = [this.procentGeactiveerd, (100 - this.procentGeactiveerd)];
  }
}

