<div class="imageSlider" *ngIf="(koppelpartij$ | async)?.koppelpartijAfbeeldingen?.length">
  <ng-image-slider
                 [images]="(koppelpartijPromoImages$ | async)"
                 [infinite]="false"
                 [autoSlide]="0"
                 [imageSize]="{width: '200px', height: '200px'}"
                 [manageImageRatio]=true
                 (imageClick)="fixFirstImageByDetectingChanges()">
  </ng-image-slider>
</div>
<div *ngIf="(koppelpartij$ | async)?.koppelpartijOmschrijving; else noDescription"
     [innerHTML]="(koppelpartij$ | async)?.koppelpartijOmschrijving"></div>
<ng-template #noDescription>
  <p class="bodySmallContent"><em>Er is geen omschrijving beschikbaar voor dit product.</em></p>
</ng-template>
