import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {AppState} from '../../state/app.state';
import {
  setLeermiddelVakKoppelingData, setLeermiddelVakKoppelingOpen, updateLeermiddelVakKoppelingData
} from '../state/leermiddelvakkoppeling/leermiddelvakkoppeling.actions';
import {catchError, filter, map, switchMap, take, tap, withLatestFrom} from 'rxjs/operators';
import {payload} from '../../state/payload';
import {Periode} from '../../services/datumbereik';
import {
  DashboardFilterInput, LeermiddelVakKoppelingInfoGQL, LeermiddelVakKoppelingUpdateGQL
} from '../../../generated/graphql';
import {of, pipe} from 'rxjs';
import {reportError} from '../../state/errors/errors.actions';
import * as AppSelectors from '../../state/app.selectors';
import {LeermiddelvakkoppelingFacade} from '../state/leermiddelvakkoppeling/leermiddelvakkoppeling.facade';

@Injectable()
export class LeermiddelvakkoppelingEffect {
  fetch$ = createEffect(() => this.actions$.pipe(
    ofType(setLeermiddelVakKoppelingOpen),
    filter(({value}) => value === true),
    switchMap(
      () => this.store.select(AppSelectors.selectAppFilter).pipe(take(1))
    ),
    filter(current => hasFilterChanged(this.prev, current)),
    tap(() => this.facade.setLeermiddelVakKoppelingLoading(true)),
    tap(f => this.prev = f),
    switchMap(f => this.gql.fetch({filter: f})),
    map(pipe(
      ({data}) =>
        ({leermiddelVakKoppelingInfo: data.leermiddelVakKoppelingInfo.sort(
          (a, b) => a.titel.localeCompare(b.titel, undefined, {ignorePunctuation: true})), vestigingsVakken: data.vestigingVakken}),
      payload,
      setLeermiddelVakKoppelingData
    )),
    catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
  ));

  update$ = createEffect(() => this.actions$.pipe(
    ofType(updateLeermiddelVakKoppelingData),
    withLatestFrom(this.store.select(AppSelectors.selectAppFilter)),
    switchMap(([{value}, f]) => this.updateGql.fetch({filter: f, leermiddelVakKoppeling: value}))
  ), {dispatch: false});

  private prev: DashboardFilterInput;

  constructor(private actions$: Actions,
              private store: Store<AppState>,
              private facade: LeermiddelvakkoppelingFacade,
              private gql: LeermiddelVakKoppelingInfoGQL,
              private updateGql: LeermiddelVakKoppelingUpdateGQL) {
  }
}

const hasFilterChanged = (prev: DashboardFilterInput, current: DashboardFilterInput) => {
  const periodeChanged = (prev?.periode === Periode.VorigSchooljaar) !== (current.periode === Periode.VorigSchooljaar)
    || ((prev?.periode === undefined) !== (current.periode === undefined)) ;
  const vestigingChanged = prev?.vestiging !== current.vestiging;
  return periodeChanged ||  vestigingChanged;
};
