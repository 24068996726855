<div class="koppelpartijLogo">
    <img *ngIf="semParty.image; else defaultLogo" src="{{ semParty.image }}">
    <ng-template #defaultLogo><img src="../../../assets/img/icons/Symbool/Koppelpartij_icon_placeholder.svg">
    </ng-template>
</div>
<div class="textWrapper">
    <p class="bodyContentSemi" [class]="semParty.consentSince ? 'small' : ''">
        {{semParty.name}}
    </p>
    <span *ngIf="semParty.consentSince" class="labelLabelSemi label"><i
            class="icon-check"></i>Gekoppeld</span>
    <p class="bodySmallContent">{{semParty.oneliner}}</p>
</div>
<i class="icon-arrow"></i>