export class RoutingConstants {
    SCHOOL_DASHBOARD_OLD = 'schooldashboardold';
    VAK_DASHBOARD_OLD = 'vakdashboardold';
    KLASSEN_DASHBOARD_OLD = 'lesgroepdashboardold';

    SCHOOL_DASHBOARD_NEW = 'schooldashboard';
    VAK_DASHBOARD_NEW = 'vakdashboard';
    KLASSEN_DASHBOARD_NEW = 'klassendashboard';

    STUDIEWIJZER_LINKJES_OLD = 'studiewijzerlinkjesdashboard';
    STUDIEWIJZER_LINKJES_NEW = 'studiewijzerlinkjesdashboardnew';
}

export const routingConstants = new RoutingConstants();
