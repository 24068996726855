import {Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../state/app.state';
import {Observable} from 'rxjs';
import {selectGeselecteerdeKoppelpartij} from '../state/privacydashboard/privacydashboard.selectors';
import {map} from 'rxjs/operators';
import {AVGExtendedKoppelpartij} from '../dto/avg-dashboard-model-classes';

@Component({
  selector: 'app-koppeling-omschrijving',
  templateUrl: './koppeling-omschrijving.component.html',
  styleUrls: ['./koppeling-omschrijving.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KoppelingOmschrijvingComponent implements OnInit {

  public koppelpartij$: Observable<AVGExtendedKoppelpartij>;
  // sorry voor object array, maar ng-slider heeft dat intern defined staan, helaas pindakaas.
  public koppelpartijPromoImages$: Observable<Array<object>>;

  constructor(private appState: Store<AppState>, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.koppelpartij$ = this.appState.select(selectGeselecteerdeKoppelpartij);
    this.koppelpartijPromoImages$ = this.appState.select(selectGeselecteerdeKoppelpartij)
      .pipe(map(geselecteerdeKoppelpartij => geselecteerdeKoppelpartij?.koppelpartijAfbeeldingen),
          map(urlArray => urlArray?.map(url => Object.assign({image: url, thumbImage: url}))));
  }

  // https://github.com/sanjayV/ng-image-slider/issues/80
  public fixFirstImageByDetectingChanges(): void {
    setTimeout(() => { this.cdr.detectChanges(); } , 1500);
  }

}
