<app-connect-loader [loading]="loading$ | async">
<div class="contentBox">
  <app-go-back-header [viewmodel]="goBackHeaderViewModel"></app-go-back-header>
  <div class="container">
    <app-koppeling-header
      [viewModel]="koppelingHeaderViewModel"
    >
      <app-koppeling-menu [viewModel]="koppelingMenuViewModel"></app-koppeling-menu>
    </app-koppeling-header>
    <div class="paginaContent">
      <app-webservice-gebruik *ngIf="gebruiksTab | async" [viewModel]="webserviceGebruikViewModel"></app-webservice-gebruik>
      <app-webservice-datatoegang *ngIf="datatoegangTab | async" [viewModel]="webserviceDatatoegangViewModel"></app-webservice-datatoegang>
    </div>
  </div>
</div>
</app-connect-loader>
