import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {routerNavigatedAction} from '@ngrx/router-store';
import {catchError, filter, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {getDashboardPath} from '../../state/router/router.selectors';
import {SomtodayService} from '../../services/somtoday.service';
import {payload} from '../../state/payload';
import {of} from 'rxjs';
import {reportError} from '../../state/errors/errors.actions';
import {AppState} from '../../state/app.state';
import { Store } from '@ngrx/store';
import { loadVestigingen, setVestigingen } from '../state/schooldashboard/schooldashboard.actions';
import {AlleVestigingenVanIngelogdeGebruikerGQL} from '../../../generated/graphql';

@Injectable()
export class VestigingEffect {
  $ = createEffect(() => this.actions$.pipe(
    ofType(loadVestigingen),
    switchMap(() => this.vestigingenVanIngelogdeGebruiker.fetch()),
    map(result => result.data.vestigingen),
    map(payload),
    map(setVestigingen),
    catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
  ));

  constructor(private actions$: Actions, private vestigingenVanIngelogdeGebruiker : AlleVestigingenVanIngelogdeGebruikerGQL, private store : Store<AppState>) {
  }
}
