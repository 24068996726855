import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, Output, EventEmitter} from '@angular/core';
import {SchooldashboardModel} from '../schooldashboard/schooldashboard.model';
import {SchooldashboardDetailModel} from '../schooldashboard/schooldashboard.detail.model';
import {Subscription} from 'rxjs';
import {dashboardDetails} from '../state/schooldashboard/schooldashboard.selectors';
import {Store} from '@ngrx/store';
import {AppState} from '../../state/app.state';
import {FilterService} from '../../services/filter.service';

@Component({
  selector: 'app-schooldashboard-details',
  templateUrl: './schooldashboard-details.component.html',
  styleUrls: ['./schooldashboard-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SchooldashboardDetailsComponent implements OnInit, OnDestroy {
  @Input()
  row: SchooldashboardModel;

  details: SchooldashboardDetailModel[];

  @Input()
  groeperenOpVak = true;

  private subscriptions: Subscription[];

  @Output()
  select = new EventEmitter<string>();

  constructor(private filterService: FilterService, private store: Store<AppState>) { }

  ngOnInit(): void {
    this.subscriptions = [
      this.store.select(dashboardDetails)
        .subscribe(v => this.details = v.get(this.row.key))
    ];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  navigeerNaarVak(details: SchooldashboardDetailModel) {
    if (details.parent.path != null) {
      this.select.emit(details.parent.path);
    }
  }
}
