import {Component, Input} from '@angular/core';
import {Params} from '@angular/router';
import {FilterService} from '../../../services/filter.service';

@Component({
  selector: 'app-vak-onderwijs-switch',
  templateUrl: './vak-onderwijs-switch.component.html',
  styleUrls: ['./vak-onderwijs-switch.component.scss']
})
export class VakOnderwijsSwitchComponent {

  @Input()
  private hoofdNiveau: string;

  @Input()
  private subNiveau: string;

  @Input()
  vorige: string | ((path: Params, query: Params) => string);

  @Input()
  volgende: string | ((path: Params, query: Params) => string);

  get label(): string {
    let label: string;
    if (this.hoofdNiveau !== undefined) {
      label = `${this.hoofdNiveau} | ${this.subNiveau}`;
    } else {
      label = this.subNiveau;
    }
    return label;
  }

  constructor(private filterService: FilterService) {
  }

  navigaarNaarVorige() {
    this.filterService.transformFilterToPath((_, p, q) => ({
      url: this.vorige instanceof Function ? this.vorige(p, q) : this.vorige, query: q
    }));
  }

  navigaarNaarVolgende() {
    this.filterService.transformFilterToPath((_, p, q) => ({
      url: this.volgende instanceof Function ? this.volgende(p, q) : this.volgende, query: q
    }));
  }
}
