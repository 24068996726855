<div class="container">
  <div [class]="'ruimteBolletje ' + waarschuwing(activatie())">
    <div class="bolletje"></div>
    <div class="bolletjeinfo">
      <h3>Activatiegraad</h3>
      <ul>
        <li><i class="bolletje lage-activatie"></i> Laag: 0 - 30% </li>
        <li><i class="bolletje matige-activatie"></i> Matig: 31 - 60% </li>
      </ul>
    </div>
  </div>
  <div>
    <div class="percentage">
      <span>{{activatie() | percent}}</span>
    </div>
    <div class="absoluteGetallen">
      <span class="geactiveerd">{{geactiveerd}}</span>
      <span class="niet-geactiveerd">{{totaal - geactiveerd}}</span>
    </div>
  </div>
</div>

