import {Component, Input} from '@angular/core';
import {periodeNaarInterval} from '../../services/datumbereik';
import {SharedVariablesService} from '../../services/sharedVariables.service';
import {ModalwindowService} from '../../services/modalwindow.service';
import {
  LeermiddelOnderwijssoortDagGebruikData,
  LeermiddelOnderwijssoortDagGebruikTotaal
} from '../state/vakloosleermiddeldashboard/vakloosleermiddeldashboard.selectors';

@Component({
  selector: 'app-leermiddelonderwijssoort-tabel',
  templateUrl: './leermiddelonderwijssoort-tabel.component.html',
  styleUrls: ['./leermiddelonderwijssoort-tabel.component.scss']
})
export class LeermiddelonderwijssoortTabelComponent {

  @Input() leermiddelOnderwijssoortDagGebruik:
    {data: LeermiddelOnderwijssoortDagGebruikData[], totaal: LeermiddelOnderwijssoortDagGebruikTotaal};

  displayedColumns = ['onderwijssoortJaar', 'aantalLeerlingen', 'ooitGeactiveerd', 'actief', 'leermiddelOnderwijssoortDagData'];

  get interval() {
    return periodeNaarInterval(this.service.periode);
  }

  constructor(private service: SharedVariablesService, private modalwindowService: ModalwindowService) { }

  openGebruiksHulp(): void {
    this.modalwindowService.toonOnderwerp('gebruik');
  }

  openActiefHulp() {
    this.modalwindowService.toonOnderwerp('actief');
  }
}
