import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {deelVeilig} from '../../services/veilig-delen';
import { BehaviorSubject, Observable, Subject, Subscription, combineLatest } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
@Component({
  selector: 'app-range-bar-new',
  templateUrl: './range-bar-new.component.html',
  styleUrls: ['./range-bar-new.component.scss'],
})
export class RangeBarNewComponent implements OnInit, AfterViewInit, OnDestroy {
    public tooltip: BehaviorSubject<string> = new BehaviorSubject<string>('');
    private defaultTooltip: Observable<string>;
    private resetTooltip: Subject<void> = new Subject<void>();

    public valid: Observable<boolean>;

    private lowerEdge: Observable<number>;
    private higherEdge: Observable<number>;

    public minPercentage: Observable<string>;
    private avgPercentage: Observable<string>;
    public maxPercentage: Observable<string>;

    private mouseX: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    constructor() {
    }

    @Input() public range: RangeBarNewModel;

    @ViewChild('range') rangeEl: ElementRef;
    @ViewChild('avg') avgEl: ElementRef;
    @ViewChild('dot') dotEl: ElementRef;
    @ViewChild('rangebar') rangebarEl: ElementRef;

    private subs: Subscription[] = [];

    ngOnInit(): void {
        this.valid = combineLatest([this.range.min, this.range.avg, this.range.max, this.range.tot]).pipe(map(([min, avg, max, tot]) => this.isValid(min, avg, max, tot)));

        this.lowerEdge = combineLatest([this.range.avg, this.range.min, this.range.tot]).pipe(map(([avg, min, tot]) => 
            deelVeilig(((avg + min) / 2), tot)
        ));

        this.higherEdge = combineLatest([this.range.avg, this.range.max, this.range.tot]).pipe(map(([avg, max, tot]) => 
            deelVeilig(((avg + max) / 2), tot)
        ));

        this.minPercentage = combineLatest([this.range.min, this.range.tot]).pipe(map(([min, tot]) => this.getPercentage(deelVeilig(min, tot))));
        this.avgPercentage = combineLatest([this.range.avg, this.range.tot]).pipe(map(([avg, tot]) => this.getPercentage(deelVeilig(avg, tot))));
        this.maxPercentage = combineLatest([this.range.max, this.range.tot]).pipe(map(([max, tot]) => this.getPercentage(deelVeilig(max, tot))));

        this.defaultTooltip = this.avgPercentage.pipe(map(avgPercentage => avgPercentage + ' actief'));
        this.resetTooltip.pipe(switchMap(() => this.defaultTooltip)).subscribe(defaultTooltip => this.tooltip.next(defaultTooltip));
        this.resetTooltip.next();
    }

    ngOnDestroy(): void {
        this.subs.forEach(sub => sub.unsubscribe());
    }

    ngAfterViewInit(): void {
        this.subs.push(
        this.minPercentage.subscribe(minPercentage => this.rangeEl.nativeElement.style.left = minPercentage));

        this.subs.push(combineLatest([this.range.max, this.range.min, this.range.tot]).pipe(
                map(([max, min, tot]) => this.getPercentage(deelVeilig((max - min), tot)))
            ).subscribe(width => this.rangeEl.nativeElement.style.width = width));

        this.subs.push(this.avgPercentage.subscribe(avgPercentage => this.avgEl.nativeElement.style.left = avgPercentage));

        this.subs.push(this.mouseX.pipe(
            withLatestFrom(this.lowerEdge, this.higherEdge, this.minPercentage, this.avgPercentage, this.maxPercentage)
        ).subscribe(([mouzeX, lowerEdge, higherEdge, minPercentage, avgPercentage, maxPercentage]) => {
            const width = this.rangebarEl.nativeElement.getBoundingClientRect().width;
            const left = this.rangebarEl.nativeElement.getBoundingClientRect().left;
            const mouseX = (mouzeX - left) / width;

            if (mouseX < lowerEdge) {
                this.tooltip.next('Laagste: ' + minPercentage);
                this.dotEl.nativeElement.style.left = minPercentage;
              } else if (mouseX > lowerEdge && mouseX < higherEdge) {
                this.tooltip.next('Gemiddeld: ' + avgPercentage);
                this.dotEl.nativeElement.style.left = avgPercentage;
              } else {
                this.tooltip.next('Hoogste: ' + maxPercentage);
                this.dotEl.nativeElement.style.left = maxPercentage;
              }
        }));
    }

    public onMouseMove(event: any): void {
        this.mouseX.next(event.clientX);
    }

    public onMouseLeave(): void {
        this.resetTooltip.next();
    }

    public getPercentage(value: number): string {
      return Math.round(value * 100) + '%';
    }

    private isValid(min: number, avg: number, max: number, tot: number): boolean {
        return ([min, avg, max].every(v => v >= 0) &&
          max >= min && max >= avg && avg >= min) &&
          (tot === undefined || [min, avg, max].every(v => v <= tot));
    }
}

export interface RangeBarNewModel {
    min: Observable<number>;
    avg: Observable<number>;
    max: Observable<number>;
    tot: Observable<number>;
}
