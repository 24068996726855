import {Pipe, PipeTransform} from '@angular/core';
import {createColumnDef, createModel, TableModel} from '../../table/table/table.model';
import {primary_1, primary_3} from '../../shared/colors';
import {IconHeaderCellComponent} from '../../table/icon-header-cell/icon-header-cell.component';
import {VakkenCellComponent} from '../../table/vakken-cell/vakken-cell.component';
import {LeermiddelVakKoppelingInfo} from '../../../generated/graphql';
import {LeermiddelUitklapCellComponent} from '../../table/leermiddel-uitklap-cell/leermiddel-uitklap-cell.component';

@Pipe({name: 'leermiddelvakkoppelingPipe'})
export class LeermiddelvakkoppelingPipe implements PipeTransform {
  transform([info, showHeaders]: [LeermiddelVakKoppelingInfo[], boolean]): TableModel<LeermiddelVakKoppelingInfo> {
    const getRowId = start => _ => `id${start++}`;
    const model = createModel(info, getRowId(1));
    model.lightHover = true;
    model.dense = true;
    model.showHeaders = showHeaders;

    const leerlingen = createColumnDef('aantalLeerlingen', '');
    leerlingen.header.component = IconHeaderCellComponent;
    leerlingen.header.getValue = () => ({data: 'url(../../../assets/img/icons/Symbool/leerlingen.svg)'});
    leerlingen.header.style = {textAlign: 'right'};
    leerlingen.body.getValue((rowModel: LeermiddelVakKoppelingInfo) => (rowModel.aantalLeerlingen));
    leerlingen.body.style = {color: primary_1, fontSize: '11px', textAlign: 'right', width: '16px', cursor: 'default'};

    const leermiddelNaam = createColumnDef('leermiddelNaam', 'Leermiddel');
    leermiddelNaam.header.style = {color: primary_3, fontSize: '16px', cursor: 'default'};
    leermiddelNaam.body.component = LeermiddelUitklapCellComponent;
    leermiddelNaam.body.getValue = (rowModel: LeermiddelVakKoppelingInfo) => (rowModel);
    leermiddelNaam.body.style = {cursor: 'default'};

    const vaknaam = createColumnDef('vaknaam', 'Vak');
    vaknaam.header.style = {color: primary_3, fontSize: '16px', textAlign: 'right', paddingRight: '40px'};
    vaknaam.body.component = VakkenCellComponent;
    vaknaam.body.getValue = (rowmodel: LeermiddelVakKoppelingInfo) => (rowmodel);
    vaknaam.body.style = {width: '200px'};

    model.columnDefs = [leerlingen, leermiddelNaam, vaknaam];
    return model;
  }
}
