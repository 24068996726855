import {Component, OnInit} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {RMedewerker} from '../../services/somtoday.service';
import {
  LesgroepDetails,
  LesgroepLeermiddelGebruikGrafiekData,
  LesgroepLeermiddelGebruikTabelData
} from '../state/klassendashboard/klassendashboard.selector';
import {SchooldashboardFacade} from '../state/schooldashboard/schooldashboard.facade';
import {map, tap} from 'rxjs/operators';
import { KlassendashboardFacade } from '../state/klassendashboard/klassendashboard.facade';

@Component({
  selector: 'app-lesgroep-detail-samenvatting-dashboard',
  templateUrl: './lesgroep-detail-samenvatting-dashboard.component.html',
  styleUrls: ['./lesgroep-detail-samenvatting-dashboard.component.scss']
})
export class LesgroepDetailSamenvattingDashboardComponent implements OnInit {
  details$: Observable<LesgroepDetails>;
  medewerkers$: Observable<RMedewerker[]>;
  tabeldata$: Observable<LesgroepLeermiddelGebruikTabelData[]>;
  grafiekdata$: Observable<LesgroepLeermiddelGebruikGrafiekData[]>;
  vanaf$: Observable<Date>;
  totenmet$: Observable<Date>;

  constructor(private store: SchooldashboardFacade, private klassendashboardStore: KlassendashboardFacade) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    this.details$ = combineLatest([
      this.store.selectKlasSamenvattingDetails(),
      this.store.selectLoadMedewerkers(),
      this.store.selectMedewerkersVanKlas()
      ]).pipe(
        tap(([_, shouldLoadMedewerkers, uuids]) => {
          if (shouldLoadMedewerkers) {
            this.store.loadMedewerkers(uuids);
          }
        }),
        map(([v]) => v)
    );
    this.medewerkers$ = this.klassendashboardStore.selectMedewerkers();
    this.grafiekdata$ = this.store.selectKlasSamenvattingGrafiekData();
    this.tabeldata$ = this.store.selectKlasSamenvattingTabelData();
    this.vanaf$ = this.store.selectHuidigePeriodeVanaf();
    this.totenmet$ = this.store.selectHuidigePeriodeTotenmet();
  }
}


