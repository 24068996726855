import {Component, Input} from '@angular/core';
import {accent_positive_3} from '../../shared/colors';
import {BazenbannerModel} from '../model/bazenbanner.model';
import {SchooldashboardFacade} from '../../old-dashboarding/state/schooldashboard/schooldashboard.facade';

@Component({
  selector: 'app-leermiddeloverzicht',
  templateUrl: './leermiddeloverzicht.component.html',
  styleUrls: ['./leermiddeloverzicht.component.scss']
})
export class LeermiddeloverzichtComponent {
  @Input()
  bazenbannerModel: BazenbannerModel;

  bgcolor = accent_positive_3;

  loading = this.store.selectLoading();

  constructor(private store: SchooldashboardFacade) {}
}
