import {ChangeDetectionStrategy, Component, HostListener, Input} from '@angular/core';
import {primary_3, background_1} from '../../shared/colors';
import {FONT_FAMILY} from '../../shared/fonts';
import {RangeLinechartModel} from '../range-linechart/range-linechart.model';
import * as moment from 'moment';
import {GoogleChartInterface} from 'ng2-google-charts';

@Component({
  selector: 'app-range-linechart-voor-lesgroeptabel',
  templateUrl: './range-linechart-voor-lesgroeptabel.component.html',
  styleUrls: ['./range-linechart-voor-lesgroeptabel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RangeLinechartVoorLesgroeptabelComponent {
  private data_: RangeLinechartModel[];

  margins: [number, number];

  @Input()
  gebruikPerWeek: number;

  @Input()
  bgcolor: string;

  private dates: Date[];

  periode: string;

  gebruik: number;

  showTooltip = false;

  @Input()
  set model(data: RangeLinechartModel[]) {
    this.data_ = data;
    const chartData = createChartData();
    chartData.dataTable.rows = data.map(({date, values}) => {
      const {avg} = values;
      return ({c: [{v: date}, {v: avg}]});
    });
    this.dates = data.map(({date}) => date).sort((a, b) => a.getTime() - b.getTime());
    if (this.dates.length > 0) {
      (chartData.options as any).hAxis.ticks = [this.dates[0], this.dates[this.dates.length - 1]];
      (chartData.options as any).hAxis.viewWindow = {min: this.dates[0], max: this.dates[this.dates.length - 1]};
    }
    this.chartData = chartData;
    this.gebruik = this.gebruikPerWeek;
  }

  chartData: GoogleChartInterface = createChartData();

  get cli(): any {
    return this.chartData.component.wrapper.getChart().getChartLayoutInterface();
  }

  onReady() {
    const cli = this.chartData.component.wrapper.getChart().getChartLayoutInterface();
    const {width} = cli.getChartAreaBoundingBox();
    this.margins = [
      cli.getXLocation(this.data_[0].date as any),
      width - cli.getXLocation(this.data_[this.data_.length - 1].date as any)
    ];
  }

  onMouseMove(event: MouseEvent) {
    this.showTooltip = true;
    const {width} = this.cli.getChartAreaBoundingBox();
    if (width > 0 && this.dates.length > 0) {
      const row = Math.round((this.dates.length - 1) * (event.offsetX - (width * 0.0412757974)) / width);
      const start = this.dates[row];
      const end = moment(start).add(moment(this.dates[1]).diff(moment(this.dates[0])));

      if (moment(this.dates[1]).diff(moment(this.dates[0]), 'days') < 2) {
        this.periode = moment(start).format('dddd');
      } else {
        this.periode = moment(start).format('DD MMM') + ' t/m ' + end.format('DD MMM');
      }
      this.gebruik = this.chartData.dataTable.rows[row].c[1].v;
    }
  }

  onMouseLeave() {
    this.gebruik = this.gebruikPerWeek;
    this.showTooltip = false;
  }

  @HostListener('window:resize')
  onResize() {
    if (this.chartData.component) {
      this.chartData.component.draw();
    }
  }
}

function createChartData() {
  return {
    chartType: 'LineChart',
    dataTable: {
      cols: [
        {type: 'date', label: 'datum'},
        {type: 'number', label: 'gebruik'}
      ],
      rows: []
    },
    options: {
      hAxis: {
        gridlines: {
          color: 'none'
        },
        format: 'd MMM',
        ticks: [],
      },
      vAxis: {
        minValue: 0,
        gridlines: {
          color: 'none'
        },
        baseline: { color: 'none' },
        textPosition: 'none'
      },
      height: 70,
      chartArea: {
        width: '100%',
        height: 50,
        bottom: 20,
        left: 0,
        right: 0,
        backgroundColor: {
          stroke: background_1,
          strokeWidth: 1
        }
      },
      backgroundColor: { fill: 'none' },
      legend: 'none',
      fontName: FONT_FAMILY,
      tooltip: { trigger: 'none' },
      pointsVisible: false,
      colors: [primary_3],
      areaOpacity: 0.0,
    }
  };
}
