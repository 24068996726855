import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { BackofficeApiService, BackofficeEtlTaskEvent, BackofficeEtlTaskExecution, BackofficeEtlTaskStep, BackofficeOrganization, BackofficeSchoolYear } from '../../generated/backoffice-client/';
import { delay, map, shareReplay, take, tap } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DashboardBackofficeService {

    private events$: Subject<BackofficeEtlTaskEvent> = new Subject<BackofficeEtlTaskEvent>();
    private websocketMessagesSubscription = null;

    private schoolYears: BehaviorSubject<BackofficeSchoolYear[]> = new BehaviorSubject<BackofficeSchoolYear[]>([
        {
            include_from: '2021-08-01',
            include_until: '2022-07-31',
            name: '2021-2022'
        },
        {
            include_from: '2022-08-01',
            include_until: '2023-07-31',
            name: '2022-2023'
        },
        {
            include_from: '2023-08-01',
            include_until: '2024-07-31',
            name: '2023-2024'
        }
    ]);
    private organizations: BehaviorSubject<BackofficeOrganization[]> = new BehaviorSubject<BackofficeOrganization[]>([
        {
            protected: false,
            database: 1,
            uuid: 'c8978624-be47-4f5c-baea-332f9b04add3',
            include_from: '2021-08-01',
            name: 'Testcollege'
        }
    ]);

    private debug: boolean = false;

    constructor(private backofficeAPI: BackofficeApiService) { }

    public fetchSchoolYears(): Observable<BackofficeSchoolYear[]> {
        if (this.debug) {
            return this.schoolYears.pipe(shareReplay(1), delay(500), take(1));
        }
        else {
            return this.backofficeAPI.connectBackofficeEtlSchoolYearGet();
        }

    }

    public storeSchoolYear(newSchoolYear: BackofficeSchoolYear): Observable<BackofficeSchoolYear> {
        if (this.debug) {
            let existing = this.schoolYears.value.find(sj => sj.name === newSchoolYear.name);
            let deepCopy = this.schoolYears.value.map(sj => ({ include_from: sj.include_from, include_until: sj.include_until, name: sj.name }));
            if (existing) {
                deepCopy = deepCopy.filter(sj => sj.name !== existing.name);
            }

            deepCopy.push({ include_from: newSchoolYear.include_from, include_until: newSchoolYear.include_until, name: newSchoolYear.name });
            this.schoolYears.next(deepCopy);
            
            return of(newSchoolYear);
        }
        else {
            return this.backofficeAPI.connectBackofficeEtlSchoolYearSchoolYearNamePut({
                school_year_name: newSchoolYear.name,
                body: newSchoolYear
            }
            );
        }
    }

    public fetchOrganizations(): Observable<BackofficeOrganization[]> {
        if (this.debug) {
            return this.organizations.pipe(shareReplay(1), delay(500), take(1));
        }
        else {
            return this.backofficeAPI.connectBackofficeEtlOrganizationGet();
        }
    }

    public saveOrganization(organization: BackofficeOrganization): Observable<BackofficeOrganization> {
        if(this.debug) {
            let existing = this.organizations.value.find(o => o.uuid === organization.uuid);
            let deepCopy = this.organizations.value.map(o => ({ ...o }));
            if (existing) {
                deepCopy = deepCopy.filter(o => o.uuid !== existing.uuid);
            }

            if(!organization.uuid) {
                organization.uuid = randomUUID();
            }
            
            deepCopy.push({ ...organization });
            this.organizations.next(deepCopy);

            return of(organization);
        }
        else {
            return this.backofficeAPI.connectBackofficeEtlOrganizationPost({ body: organization });
        }
    }

    public startSync(config: SyncConfig): Observable<BackofficeEtlTaskExecution> {
        if(this.debug) {
            // do debuggy things
        }
        else {
            return this.backofficeAPI.connectBackofficeEtlTaskPost({ 
                date_range_start: config.start,
                date_range_end: config.end ? config.end : undefined,
                extract: config.extract, 
                transform: config.transform, 
                load: config.load,
                remark: config.remark,
                schoolYears: config.schoolyears,
                organizations: config.organizations,
                block_until_finished: config.blockUntilFinished
             });
        }
    }

    public fetchSyncs(page: number, pageSize: number, orderBy: string, descending: boolean): Observable<FetchSyncsResponse> {
        if(this.debug) {
            // do debuggy things
        }
        else {
            return this.backofficeAPI.connectBackofficeEtlTaskGet({ page_size: pageSize, page_number: page, order_by: orderBy, descending: descending }).pipe(map(response => ({
                syncs: response.payload,
                page: page,
                count: response.metadata.count
            })));
        }
    }

    public fetchSyncSchoolYears(taskId: number): Observable<BackofficeSchoolYear[]> {
        if(this.debug) {
            // bla
        }
        else {
            return this.backofficeAPI.connectBackofficeEtlTaskTaskIdSchoolYearsGet({ task_id: taskId });
        }
    }

    public fetchSyncOrganizations(taskId: number): Observable<BackofficeOrganization[]> {
        if(this.debug) {
            // bla
        }
        else {
            return this.backofficeAPI.connectBackofficeEtlTaskTaskIdOrganizationsGet({ task_id: taskId });
        }
    }

    public fetchSyncFailedOrganizations(taskId: number): Observable<BackofficeOrganization[]> {
        if(this.debug) {
            // bla
        }
        else {
            return this.backofficeAPI.connectBackofficeEtlTaskTaskIdFailedOrganizationsGet({ task_id: taskId });
        }
    }

    public fetchSyncStepsLeft(taskId: number): Observable<BackofficeEtlTaskStep[]> {
        if(this.debug) {
            // bla
        }
        else {
            return this.backofficeAPI.connectBackofficeEtlTaskTaskIdStepsGet({ task_id: taskId });
        }
    }

    public fetchSyncEvents(taskId: number, lastFetchedEvent: number): Observable<BackofficeEtlTaskEvent[]> {
        if(this.debug) {

        }
        else {
            return this.backofficeAPI.connectBackofficeEtlTaskTaskIdEventsGet({ task_id: taskId, from: lastFetchedEvent });
        }
    }

    public stopSync(taskId: number): Observable<string> {
        if(this.debug) {
            // bla
        }
        else {
            return this.backofficeAPI.connectBackofficeEtlTaskTaskIdStopPost({ task_id: taskId });
        }
    }

    public resumeSync(taskId: number): Observable<string> {
        if(this.debug) {
            // bla
        }
        else {
            return this.backofficeAPI.connectBackofficeEtlTaskTaskIdResumePost({ task_id: taskId });
        }
    }
}

export interface FetchSyncsResponse { syncs: BackofficeEtlTaskExecution[], page: number, count: number };

export interface SyncConfig { 
    start: string,
    end?: string,
    extract: boolean,
    transform: boolean,
    load: boolean,
    remark: string,
    schoolyears: string[],
    organizations: string[],
    blockUntilFinished?: boolean
 }

export function randomUUID(): string {
    let chars = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        'a',
        'b',
        'c',
        'd',
        'e',
        'f'
    ];

    let uuid = '';
    for(let i = 0; i < 8; i++) {
        uuid += chars[Math.floor(Math.random() * 16)];
    }
    uuid += '-';
    
    for(let i = 0; i < 4; i++) {
        uuid += chars[Math.floor(Math.random() * 16)];
    }
    uuid += '-';
    
    for(let i = 0; i < 4; i++) {
        uuid += chars[Math.floor(Math.random() * 16)];
    }
    uuid += '-';
    
    for(let i = 0; i < 4; i++) {
        uuid += chars[Math.floor(Math.random() * 16)];
    }
    uuid += '-';
    
    for(let i = 0; i < 12; i++) {
        uuid += chars[Math.floor(Math.random() * 16)];
    }

    return uuid;
}