import {Component, OnInit, ChangeDetectionStrategy, Input} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Component({
  selector: 'app-input-checkbox',
  templateUrl: './input-checkbox.component.html',
  styleUrls: ['./input-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputCheckboxComponent implements OnInit {

  @Input()
  viewModel: InputCheckboxViewModel;

  constructor() { }

  ngOnInit(): void {
  }

  onClick(): void {
    this.viewModel.doOnClick.next();
  }

  checkboxStyle(checked: boolean): string {
    return 'checkbox clickable checkbox-' + (checked ? 'checked' : 'unchecked');
  }
}

export interface InputCheckboxViewModel {
  onGetValue: Observable<boolean>;

  doOnClick: Subject<boolean>;
}
