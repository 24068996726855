import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    // tslint:disable-next-line
    selector: 'button, button[buttonstyle], a[buttonstyle]',
    styleUrls: ['./button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <i *ngIf="icon" class="icon {{icon}} {{iconcolor}}"></i>
        <span class="text">
            <ng-content></ng-content>
        </span>
    `,
    encapsulation: ViewEncapsulation.None
})
export class ButtonComponent implements OnInit {

    @Input() @HostBinding('class.has-icon') icon: string;
    @Input() @HostBinding('class.text-align-left') textalignleft = false;
    @Input() @HostBinding('class.disable-icon-on-mobile') disableIconOnMobile = false;
    @Input() buttonstyle: 'raised' | 'flat' | 'dashed';
    @Input() color: 'red' | 'blue' | 'green' | 'grey' = 'blue';
    @Input() iconcolor: 'red' | 'blue' | 'green' | 'orange' | 'grey' = 'blue';
    @Input() text: string;

    constructor() {
    }

    ngOnInit() {
    }


}
