import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Type,
  ViewChild,
  ComponentFactoryResolver,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import {ContentHostDirective} from '../directives/content-host.directive';

@Component({
  selector: 'app-header-content',
  templateUrl: './header-content.component.html',
  styleUrls: ['./header-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderContentComponent implements OnInit, OnChanges {
  @Input()
  component: Type<Component>;

  @ViewChild(ContentHostDirective, {static: true})
  contentHost: ContentHostDirective;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

  ngOnInit() {
    this.createComponent();
  }

  private createComponent() {
    const factory = this.componentFactoryResolver.resolveComponentFactory(this.component);
    const viewContainerRef = this.contentHost.viewContainerRef;
    viewContainerRef.clear();
    viewContainerRef.createComponent(factory);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.createComponent();
  }
}
