import {Component, OnInit, ChangeDetectionStrategy} from '@angular/core';
import {KlassendashboardNewFacade} from '../../state/klassendashboard/klassendashboard.facade';
import {Observable} from 'rxjs';
import {GezamenlijkGebruik} from '../../state/klassendashboard/klassendashboard.state';
import {DashboardsettingsFacade} from '../../state/dashboardsettings/dashboardsettings.facade';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-klassendashboard-samenvatting',
  templateUrl: './klassendashboard-samenvatting.component.html',
  styleUrls: ['./klassendashboard-samenvatting.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KlassendashboardSamenvattingComponent implements OnInit {

  medewerkers$: Observable<string[]>; // Medewerkernamen
  gezamenlijkGebruik: Observable<GezamenlijkGebruik>;

  constructor(public store: KlassendashboardNewFacade, public settings: DashboardsettingsFacade) { }

  ngOnInit(): void {
    this.medewerkers$ = this.store.getDocentNamen();
    this.gezamenlijkGebruik = this.store.getGezamenlijkGebruik();
  }

}
