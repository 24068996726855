import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  Input,
  QueryList,
  ViewChild
} from '@angular/core';
import {CdkPortalOutlet} from "@angular/cdk/portal";
import {TabComponent} from "../tab/tab.component";

@Component({
  selector: 'app-tab-row',
  templateUrl: './tab-row.component.html',
  styleUrls: ['./tab-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabRowComponent implements AfterContentInit {
  @Input() label;

  @ContentChildren(TabComponent, {descendants: true}) tabs: QueryList<TabComponent>

  @ViewChild(CdkPortalOutlet) portalOutlet: CdkPortalOutlet;

  private _selectedTab: TabComponent;

  @Input()
  get selectedTab(): TabComponent {
    return this._selectedTab;
  }


  constructor() {
  }

  ngAfterContentInit(): void {
    this._setActiveTab(this.tabs.first);
  }

  _setActiveTab(tab: TabComponent): void {
    this._selectedTab = tab;
  }
}
