import {RangeLinechartVoorBazenBannerModel} from '../range-linechart/range-linechart.model';
import {LesgroepSchooldashboardQuery} from '../../../generated/graphql';
import * as m from 'moment';
import {Periode, periodeNaarDatums} from '../../services/datumbereik';

export interface BazenbannerModel {
 linechartGebruik: RangeLinechartVoorBazenBannerModel[];
 lesgroepActivatieInProcenten: number;
 licentiesPerLeerling: number;
 educatieveAanbieders: number;
 gemActieveLeerlingenPerDag: number;
 aantalGeactiveerdeLicenties: number;
}

const MILLISECONDS_IN_DAY = 86400000;

export function refreshBazenBannerModel(value: LesgroepSchooldashboardQuery, periode: Periode): BazenbannerModel {
  const model: BazenbannerModel = {
    educatieveAanbieders: value.statistieken.educatieveAanbieders,
    lesgroepActivatieInProcenten: value.statistieken.percentageLesgroepenMetInzetBoven70pct,
    licentiesPerLeerling: value.statistieken.licentiesPerLeerling,
    linechartGebruik : [],
    gemActieveLeerlingenPerDag : value.gemActieveLeerlingenPerDag,
    aantalGeactiveerdeLicenties: value.statistieken.aantalGeactiveerdeLicenties
  };

  const accumulator = new Map();

  value.lesgroepschooldashboard.forEach(currentValue => {
    if (currentValue.dag) {
      if (accumulator.get(currentValue.dag.valueOf())) {
        accumulator.get(currentValue.dag.valueOf()).value += currentValue.daggebruik;
      } else {
        accumulator.set(currentValue.dag.valueOf(), {date: currentValue.dag, value: currentValue.daggebruik});
      }
    }
  });

  const keys: number[] = [];

  accumulator.forEach((_: RangeLinechartVoorBazenBannerModel, key: number) => keys.push(key));
  keys.sort();

  let lastAmountOfDaysSinceEpoch = getDaysSinceEpoch(m(periodeNaarDatums(periode).from).toDate());
  let lastDataPoint = getDaysSinceEpoch(m(periodeNaarDatums(periode).from).toDate()) - 1;

  keys.forEach((key: number) => {
    const dataPoint: RangeLinechartVoorBazenBannerModel = accumulator.get(key);
    dataPoint.date = new Date(dataPoint.date);

    // padding zodat we geen gaten in de grafiek hebben.
    // en comments hoeven niet met een space te beginnen, dit compileert prima.
    if (lastAmountOfDaysSinceEpoch === null) {
      lastAmountOfDaysSinceEpoch = getDaysSinceEpoch(dataPoint.date);
    }

    // +1 omdat we moeten vergelijken met de dag die op deze positie in de array had moeten zitten.
    while (lastAmountOfDaysSinceEpoch + 1 < getDaysSinceEpoch(dataPoint.date)) {
      lastAmountOfDaysSinceEpoch += 1;
      model.linechartGebruik.push({date: fromDaysSinceEpoch(lastAmountOfDaysSinceEpoch), value: 0});
    }

    lastAmountOfDaysSinceEpoch = getDaysSinceEpoch(dataPoint.date);

    lastDataPoint = getDaysSinceEpoch(dataPoint.date);
    model.linechartGebruik.push(dataPoint);
  });
  while (lastDataPoint + 1 < getDaysSinceEpoch(m(periodeNaarDatums(periode).to).toDate())) {
    lastDataPoint += 1;
    model.linechartGebruik.push({date: fromDaysSinceEpoch(lastDataPoint), value: 0});
  }
  return model;
}

function getDaysSinceEpoch(date: Date): number {
  return Math.floor(date.getTime() / MILLISECONDS_IN_DAY);
}

function fromDaysSinceEpoch(daysSinceEpoch: number): Date {
  // Close enough nu we zo ver bij de epoch vandaan zitten. +1 omdat we eerder Math.floor deden.
  return new Date((daysSinceEpoch + 1) * MILLISECONDS_IN_DAY);
}
