import {Pipe, PipeTransform} from '@angular/core';
import {DataPerLeerling, LesgroepDetailData} from './klassendashboard.model';
import {createColumnDef, createModel, TableModel} from '../../table/table/table.model';
import {KeyCellComponent} from '../../table/key-cell/key-cell.component';
import {LabelCellComponent} from '../../table/label-cell/label-cell.component';
import {LeermiddelnaamCellComponent} from '../../table/leermiddelnaam-cell/leermiddelnaam-cell.component';
import {GeactiveerdTotaalComponent} from '../../table/geactiveerd-totaal/geactiveerd-totaal.component';
import {GeactiveerdComponent} from '../../table/geactiveerd/geactiveerd.component';
import {HelpHeaderCellComponent} from '../../table/help-header-cell/help-header-cell.component';
import {GebruikperweekComponent} from '../../table/gebruikperweek/gebruikperweek.component';
import {LeerlingnaamActivatiegetalCellComponent} from '../../table/leerlingnaam-activatiegetal-cell/leerlingnaam-activatiegetal-cell.component';
import {LaatstActiefMetIconCellComponent} from '../../table/laatst-actief-met-icon-cell/laatst-actief-met-icon-cell.component';
import {leerlingAchternaamVoornaam} from '../../formatting/leerling.achternaam.voornaam';
import {ModalwindowService} from '../../services/modalwindow.service';
import {singleDecimalNumber} from '../../formatting/single-decimal-number';

@Pipe({name: 'klassendashboardTable'})
export class KlassendashboardTablePipe implements PipeTransform {
  constructor(private modalwindowService: ModalwindowService) {
  }

  transform(data: LesgroepDetailData): TableModel<DataPerLeerling> {
    const model = createModel<DataPerLeerling>(data.leerlingData, d => d.leerlingUUID);
    model.showFooters = true;
    model.rowsClickable = false;

    const leerlingNaam = createColumnDef<DataPerLeerling>('leerling', 'Leerling');
    leerlingNaam.body.style = {width: '30%', fontWeight: 'normal', color: '#000000'};
    leerlingNaam.body.component = KeyCellComponent;
    leerlingNaam.body.getValue = rowModel => leerlingAchternaamVoornaam(rowModel.leerling);
    leerlingNaam.footer.component = LabelCellComponent;
    leerlingNaam.footer.getValue = () => ('Totaal');
    leerlingNaam.body.getRowspan = rowModel => rowModel.rowspan;

    const leermiddelNaam = createColumnDef<DataPerLeerling>('leermiddelNaam', 'Leermiddel');
    leermiddelNaam.body.component = LeermiddelnaamCellComponent;
    leermiddelNaam.body.style = {fontSize: '14px', fontWeight: 'normal', width: '18%'};
    leermiddelNaam.body.getValue = rowModel =>  ({leermiddelNaam: rowModel.leermiddelNaam, geactiveerd: rowModel.geactiveerd});
    leermiddelNaam.footer.component = GeactiveerdTotaalComponent;
    leermiddelNaam.footer.getValue = () => data.totaalrij.geactiveerdInProcenten;

    const geactiveerd = createColumnDef<DataPerLeerling>('geactiveerd', 'Geactiveerd');
    geactiveerd.body.component = GeactiveerdComponent;
    geactiveerd.footer.component = GeactiveerdTotaalComponent;
    geactiveerd.footer.getValue = () => data.totaalrij.geactiveerdInProcenten.toFixed();

    const gebruikPerWeek = createColumnDef<DataPerLeerling>('gebruikPerWeek');
    const gebruikPerWeekHulp = () => this.modalwindowService.toonOnderwerp('gebruikPerWeek');
    gebruikPerWeek.header.component = HelpHeaderCellComponent;
    gebruikPerWeek.header.getValue = () => ({title: 'Gebruik per week', help: gebruikPerWeekHulp});
    gebruikPerWeek.body.component = GebruikperweekComponent;
    gebruikPerWeek.body.getValue = rowModel => ({gebruikPerWeek: singleDecimalNumber(rowModel.gebruikPerWeek),
      gebruikPerWeekPijltje: rowModel.gebruikPerWeekPijltje});
    gebruikPerWeek.footer.component = GebruikperweekComponent;
    gebruikPerWeek.footer.style = {fontWeight: 'bold'};
    gebruikPerWeek.footer.getValue = () => ({gebruikPerWeek: singleDecimalNumber(data.totaalrij.gemiddeldGebruikPerWeek)});

    if (data.meestGebruikteMethode.startsWith('Let op')) {
      leerlingNaam.body.component = LeerlingnaamActivatiegetalCellComponent;
      leerlingNaam.body.style = {width: '23%', fontSize: '14px', fontWeight: 'bold', color: '#000000'};
      leerlingNaam.body.getValue = rowModel => ({leerlingNaam: leerlingAchternaamVoornaam(rowModel.leerling),
        aantalGeactiveerdeLeermiddelen: rowModel.aantalGeactiveerdeLicenties});

      const laatstActief = createColumnDef<DataPerLeerling>('laatstActiefMeerdere', 'Laatst actief');
      laatstActief.body.style = {fontSize: '14px', fontWeight: 'normal'};
      laatstActief.body.component = LaatstActiefMetIconCellComponent;
      laatstActief.footer.component = LabelCellComponent;
      laatstActief.footer.style = {fontSize: '14px'};
      laatstActief.footer.getValue = () => `${data.totaalrij.recentActiefInProcenten.toFixed()}% recent actief`;
      laatstActief.body.style = {fontSize: '14px', fontWeight: 'normal', width: '13%'};

      model.columnDefs = [leerlingNaam, leermiddelNaam, laatstActief, gebruikPerWeek];
    } else {
      const laatstActief = createColumnDef<DataPerLeerling>('laatstActiefEnkel', 'Laatst actief');
      laatstActief.body.style = {fontSize: '14px', fontWeight: 'normal'};
      laatstActief.body.component = LabelCellComponent;
      laatstActief.footer.component = LabelCellComponent;
      laatstActief.footer.style = {fontSize: '14px'};
      laatstActief.footer.getValue = () => `${data.totaalrij.recentActiefInProcenten.toFixed()}% recent actief`;
      model.columnDefs = [leerlingNaam, geactiveerd, laatstActief, gebruikPerWeek];
    }
    return model;
  }

}
