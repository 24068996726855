import {createReducer, on} from '@ngrx/store';
import {decrease, increase} from './request-count.actions';

const initialState = 0;

export const requestCountReducer = createReducer(
  initialState,
  on(increase, count => count + 1),
  on(decrease, count => count > 0 ? count - 1 : 0)
);
