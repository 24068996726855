import {Component, Type} from '@angular/core';
import {LabelCellComponent} from '../label-cell/label-cell.component';

export interface TableModel<M> {
  data: M[];
  columnDefs: ColumnDef<M>[];
  showHeaders: boolean;
  showFooters: boolean;
  rowsClickable: boolean;
  lightHover: boolean;
  stickyFooters: boolean;
  dense: boolean;
  columns(): string[];
  getColumnDef(column: string): ColumnDef<M>;
  getRowId(rowModel: M);
  getRowgroup(rowModel: M): Rowgroup;
}

export interface ColumnDef<M> {
  column: string;
  header: CellDef<M, any>;
  body: CellDef<M, any>;
  footer: CellDef<M, any>;
}

export interface Rowgroup {
  groupId: number;
  lastOfGroup: boolean;
}

type Partial<T> = {[P in keyof T]?: T[P]};

export interface CellDef<M, T> {
  class?: string;
  style?: Partial<CSSStyleDeclaration>;
  component: Type<Component & TableCellComponent<T>>;
  getValue(rowModel: M): T;
  getRowspan(rowModel: M): number;
}

export interface TableCellComponent<T> {
  data: T;
}

export function createModel<M>(data: M[], getRowId: (rowModel: M) => string): TableModel<M> {
  return {
    data,
    columnDefs: [],
    showHeaders: true,
    showFooters: false,
    rowsClickable: false,
    lightHover: false,
    stickyFooters: true,
    dense: false,
    columns(): string[] {
      return this.columnDefs.map((d: ColumnDef<M>) => d.column);
    },
    getColumnDef(column: string): ColumnDef<M> {
      return this.columnDefs.find((d: ColumnDef<M>) => d.column === column);
    },
    getRowgroup: null,
    getRowId
  };
}

export function createColumnDef<M>(column: string, title?: string): ColumnDef<M> {
  return {
    column,
    header: createDefaultHeaderCellDef(column, title),
    body: createDefaultCellDef<M>(column),
    footer: createDefaultFooterCellDef<M>(column)
  };
}

export function createDefaultCellDef<M>(column: string): CellDef<M, string> {
  return {
    component: LabelCellComponent,
    getValue(rowModel: M): string {
      return rowModel[column] && rowModel[column].toString();
    },
    getRowspan(_: M): number {
      return 1;
    }
  };
}

export function createDefaultFooterCellDef<M>(column: string): CellDef<M, string> {
  return {
    component: LabelCellComponent,
    getValue(rowModel: M): string {
      return rowModel[column] && rowModel[column].toString();
    },
    getRowspan(_: M): number {
      return 1;
    }
  };
}

export function createDefaultHeaderCellDef<M>(column: string, title?: string): CellDef<M, string> {
  return {
    component: LabelCellComponent,
    getValue(): string {
      return title === undefined ? column : title;
    },
    getRowspan(_: M): number {
      return 1;
    }
  };
}
