  <div [ngClass]="information.fullRow ? 'info-block-detail-big' : 'info-block-detail'" *ngIf="(information.type === 'text')">
    <p class="info-text">
      {{ information.data }}
    </p>
  </div>
  <div class="info-block-detail" *ngIf="(information.type === 'image')">
    <p class="info-image">
      <img src="{{ information.data }}" />
    </p>
  </div>
  <div class="info-block-detail" *ngIf="(information.type !== 'image' && information.type !== 'text')">
  </div>

