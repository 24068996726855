import {Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, Subject} from 'rxjs';

@Component({
    selector: 'app-select-table',
    templateUrl: './select-table.component.html',
    styleUrls: ['./select-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectTableComponent implements OnInit, OnDestroy {

    public listHeightStyle = '';

    public isAllSelected: boolean = false;

    public showPlaceholder: boolean = false;

    @Input()
    public viewModel: SelectTableViewModel;

    private subscriptions = [];

    constructor() { }

    ngOnInit(): void {
        this.subscriptions.push(this.viewModel.onGetIsAllSelected.subscribe(isAllSelected => this.isAllSelected = isAllSelected));
        this.subscriptions.push(combineLatest([this.viewModel.onGetPlaceholderText, this.viewModel.onGetIsAllSelected, this.viewModel.onGetRows]).subscribe(([text, isAllSelected, rows]) => this.showPlaceholder =
            text !== null && text !== undefined && text !== '' && !isAllSelected && rows.length === 0));
        this.viewModel.contentIsLoaded.next(true);
    }

    ngOnDestroy(): void {
      this.viewModel.contentIsLoaded.next(false);
      this.subscriptions.forEach(s => s.unsubscribe());
    }

    public selectAll(selectAll: boolean): void {
        this.viewModel.doSelectAll.next(selectAll);
    }

    public onRowClicked(row): void {
        if (!row.selectable) return;
        this.viewModel.doSelectRow.next(row);
    }

    public rowClass(row): string {
        return row.selectable ? row.selected ? 'row checked' : 'row unchecked' : 'row unselectable';
    }

    public checkboxClass(row): string {
        return row.selectable ? row.selected ? 'checked' : 'unchecked' : 'unselectable';
    }

    public textClass(row): string[] {
        return row.selectable ? ['bodyContent', 'naam', 'selectable'] : ['bodyContent', 'naam', 'disabled'];
    }
}

export interface SelectTableViewModel {
    onGetTitle: Observable<string>;
    onGetHasSelectAll: Observable<boolean>;
    onGetIsAllSelected: Observable<boolean>;
    onGetRows: Observable<NamedSelectableRow[]>;
    onGetPlaceholderText: Observable<string>;

    doSelectRow: Subject<NamedSelectableRow>;
    doSelectAll: Subject<boolean>;

    contentIsLoaded: BehaviorSubject<boolean>;
}

export interface NamedSelectableRow {
    naam: string;
    selected: boolean;
    selectable: boolean;
}
