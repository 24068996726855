import { Component } from '@angular/core';
import {TableCellComponent} from '../table/table.model';

@Component({
  selector: 'app-justified-number-cell',
  templateUrl: './justified-number-cell.component.html',
  styleUrls: ['./justified-number-cell.component.scss']
})
export class JustifiedNumberCellComponent implements TableCellComponent<number> {
  data: number;
}
