import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {datumOverlap, Periode, periodeNaarDatums, toonPeriode} from '../../services/datumbereik';
import * as m from 'moment';
import {ActivatedRoute} from '@angular/router';
import {UrlService} from '../../services/url.service';
import {AnalyticsService, Metrics} from '../../services/analytics.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../state/app.state';
import {selectHuidigePeriode} from '../../state/app.selectors';
import {Subscription} from 'rxjs';
import {selectHuidigeVestiging} from '../../old-dashboarding/state/schooldashboard/schooldashboard.selectors';
import {filter} from 'rxjs/operators';
import {FilterService} from '../../services/filter.service';

export interface PeriodeOptie {
  label: string;
  interval: string;
  icon: string;
  periode: Periode;
  bevatLicenties: boolean;
  bevatStudiewijzers: boolean;
}

@Component({
  selector: 'app-datumbereik',
  templateUrl: './datumbereik.component.html',
  styleUrls: ['./datumbereik.component.scss']
})
export class DatumbereikComponent implements OnInit, OnDestroy {

  public opties: PeriodeOptie[] = [
    {label: 'Afgelopen week', interval: 'Dag', icon: 'icon-calendar-week', periode: Periode.Week,
      bevatLicenties: false, bevatStudiewijzers: false},
    {label: 'Afgelopen maand', interval: 'Week', icon: 'icon-calendar-maand', periode: Periode.Maand,
      bevatLicenties: false, bevatStudiewijzers: false},
    {label: 'Afgelopen periode', interval: 'Week', icon: 'icon-calendar-periode', periode: Periode.Periode,
      bevatLicenties: false, bevatStudiewijzers: false},
    {label: 'Sinds start schooljaar', interval: 'Maand', icon: 'icon-calendar-schooljaar', periode: Periode.Schooljaar,
      bevatLicenties: false, bevatStudiewijzers: false},
    {label: 'Vorig schooljaar', interval: 'Maand', icon: 'icon-calendar-schooljaar', periode: Periode.VorigSchooljaar,
      bevatLicenties: false, bevatStudiewijzers: false}
  ];

  private periode: Periode;
  public expanded = false;
  private subscriptions: Subscription[] = [];
  public getoondeOpties: PeriodeOptie[];

  @Input()
  studiewijzerdb: boolean;

  constructor(private store: Store<AppState>,
              private route: ActivatedRoute,
              private urlService: UrlService,
              private analyticsService: AnalyticsService,
              private filterService: FilterService) {
  }

  ngOnInit(): void {
    this.subscriptions = [
      this.store.select(selectHuidigePeriode).subscribe(periode => this.periode = periode),
      this.store.select(selectHuidigeVestiging).pipe(filter(v => !!v)).subscribe(v => {
        this.getoondeOpties = this.opties.filter(optie => toonPeriode(optie.periode, v.dataSinds))
          .map(optie => ({
            ...optie,
            bevatLicenties: datumOverlap(optie.periode, v.vanafdatumLicenties, v.einddatumLicenties),
            bevatStudiewijzers: datumOverlap(optie.periode, v.vanafdatumStudiewijzers, v.einddatumStudiewijzers)
          }));
      })
    ];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  public get selectie(): Periode {
    return this.periode;
  }
  public set selectie(periode: Periode) {
    this.expanded = false;
    this.analyticsService.logEvent(Metrics.DATERANGECHANGE);
    this.filterService.setFilterOption({periode});
  }

  public wijzigPeriode(optie: PeriodeOptie): void {
    if (this.studiewijzerdb ? optie.bevatStudiewijzers : optie.bevatLicenties) {
     this.selectie = optie.periode;
    }
  }

  public get optie(): PeriodeOptie {
    return this.opties.find(o => o.periode === this.periode);
  }

  public get datums(): m.FromTo {
    return periodeNaarDatums(this.optie.periode);
  }

  toonJaartallen(): boolean {
    return this.periode === Periode.VorigSchooljaar;
  }
}
