import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';

@Component({
    selector: 'app-avg-organisatie-selector',
    templateUrl: './avg-organisatie-selector.component.html',
    styleUrls: ['./avg-organisatie-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvgOrganisatieSelectorComponent implements OnInit, OnDestroy {
    @Input()
    viewModel: OrganisatieSelectorViewModel;

    public selectableOrganizations: SelectableOrganization[];

    constructor(private cdr: ChangeDetectorRef) { }

    private subscriptions: Subscription[] = [];

    ngOnInit(): void {
        this.subscriptions.push(this.viewModel.onGetAllOrganizations.subscribe(orgs => {
            this.selectableOrganizations = orgs;
            this.cdr.markForCheck();
        }
            )
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    public updateOrganisatie(organisatie: SelectableOrganization): void {
        this.viewModel.onOrganizationSelectedChanged.next(this.selectableOrganizations.map(org => {
            if (org.uuid === organisatie.uuid) {
                return ({...org, selected: !organisatie.selected});
            }
            else {
                return org;
            }
        }));
    }
}

export interface SelectableOrganization {
    naam: string;
    uuid: string;
    selected: boolean;
}

export interface OrganisatieSelectorViewModel {
    onGetAllOrganizations: Observable<SelectableOrganization[]>;

    onOrganizationSelectedChanged: Subject<SelectableOrganization[]>;
}
