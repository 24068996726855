import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, of, Subscription} from 'rxjs';
import {filter, map, take, tap, withLatestFrom} from 'rxjs/operators';
import {InputComboboxViewModel} from 'src/app/layout/input-combobox/input-combobox.component';
import {InputRadiobuttonComponentViewModel} from 'src/app/layout/input-radiobutton/input-radiobutton.component';
import {Datumbereik, PERIODE} from 'src/app/state/dashboardsettings/dashboardsettings.state';
import {getMatchingMaand, getMatchingWeek, month, schoolyear, week} from 'src/app/stateless/datumbereik';
import {Periode} from '../../services/datumbereik';

@Component({
  selector: 'app-datumbereik-selectie-panel',
  templateUrl: './datumbereik-selectie-panel.component.html',
  styleUrls: ['./datumbereik-selectie-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatumbereikSelectiePanelComponent implements OnInit, OnDestroy {

  constructor() {
  }

  @Input()
  public viewModel: DatumbereikSelectiePanelComponentViewModel;

  public active$: BehaviorSubject<PERIODE> = new BehaviorSubject(null);

  private leftOptions: BehaviorSubject<Datumbereik[]> = new BehaviorSubject([]);

  private leftSelection: BehaviorSubject<NameAndCandidates> = new BehaviorSubject(null);

  private rightSelection: BehaviorSubject<NameAndCandidates> = new BehaviorSubject(null);

  public weekRadioButtonViewModel: InputRadiobuttonComponentViewModel;

  public maandRadioButtonViewModel: InputRadiobuttonComponentViewModel;

  public jaarRadioButtonViewModel: InputRadiobuttonComponentViewModel;

  public leftComboViewModel: InputComboboxViewModel;

  public rightComboViewModel: InputComboboxViewModel;

  private subs: Subscription[] = [];

  ngOnInit(): void {
    this.weekRadioButtonViewModel = {
      onGetActive: this.active$.pipe(map(p => p === 'WEEK')),
      onGetLabel: of('Week'),
      click: () => this.active$.next('WEEK')
    };

    this.maandRadioButtonViewModel = {
      onGetActive: this.active$.pipe(map(p => p === 'MAAND')),
      onGetLabel: of('Maand'),
      click: () => this.active$.next('MAAND')
    };

    this.jaarRadioButtonViewModel = {
      onGetActive: this.active$.pipe(map(p => p === 'JAAR')),
      onGetLabel: of('Schooljaar'),
      click: () => this.active$.next('JAAR')
    };

    this.subs.push(
      this.rightSelection.pipe(
        filter(sel => !!sel),
        map(sel => sel.candidates)
      ).subscribe(sel => this.leftOptions.next(sel)));

    this.subs.push(
      combineLatest([this.rightSelection, this.active$]).pipe(
        filter(([rightSelection, active]) => {
          return !!rightSelection?.candidates
            && rightSelection.candidates.length > 0
            && !!active
        }),
        map(([rightSelection, active]) => {
          return rightSelection.candidates
            .filter(db => db.periode === active)
            .sort((a, b) => a.epochSeconds - b.epochSeconds)
        }),
        tap(datumbereiken => this.leftOptions.next(datumbereiken)),
        filter(datumbereiken => datumbereiken.length > 0),
        withLatestFrom(this.leftSelection, this.viewModel.onGetCurrentDatumbereik),
        map(([datumbereiken, curLeftSelection, prevLeftSelection]) => {
          const currentDatumbereik = curLeftSelection ? curLeftSelection.candidates[0] : prevLeftSelection;
          const eersteDatumbereik = datumbereiken.find(db => db.hasData);
          // gebruik geselecteerde bereik voor de huidige periode, anders de eerst mogelijke
          switch (eersteDatumbereik.periode) {
            case Periode.Week:
              const matchingWeek = getMatchingWeek(datumbereiken, currentDatumbereik);
              return matchingWeek ? matchingWeek : eersteDatumbereik;
            case Periode.Maand:
              const matchingMaand = getMatchingMaand(datumbereiken, currentDatumbereik);
              return matchingMaand ? matchingMaand : eersteDatumbereik;
            default:
              return eersteDatumbereik;
          }
        }),
        tap(datumbereik => this.leftSelection.next(formatLeft(datumbereik.periode, datumbereik)))
      ).subscribe());

    const rightSelectOptions = this.viewModel.onGetDatumbereiken.pipe(
      filter(datumbereiken => !!datumbereiken),
      map(datumbereiken => datumbereiken.map(db => formatRight(db))),
      map(distinct)
    );

    // Initiële vulling active
    this.subs.push(this.viewModel.onGetCurrentDatumbereik.pipe(
      tap((datumbereik) => this.active$.next(datumbereik.periode)),
      take(1)
    ).subscribe());

    // Initiële selectie
    this.subs.push(this.viewModel.onGetCurrentDatumbereik.pipe(
      take(1),
      map(db => formatRight(db)),
      withLatestFrom(rightSelectOptions),
      map(([db, options]) => options.find(o => o.naam === db.naam))
    ).subscribe(sel => this.rightSelection.next(sel)));

    this.subs.push(this.viewModel.onGetCurrentDatumbereik.pipe(
      take(1),
      map(db => formatLeft(db.periode, db))
    ).subscribe(sel => this.leftSelection.next(sel)));

    this.rightComboViewModel = {
      onGetOpties: rightSelectOptions.pipe(map(opts => opts.sort((a, b) => a.candidates[0].epochSeconds - b.candidates[0].epochSeconds))),
      onGetSelected: this.rightSelection.pipe(filter(sel => !!sel)),
      doOnOptieSelected: (arg) => arg.hasData && this.selectRight(arg)
    };

    this.leftComboViewModel = {
      onGetOpties: this.leftOptions.pipe(
        filter(opts => !!opts && opts.length > 0),
        map(opts => opts.map(db => {
          return formatLeft(db.periode, db);
        }).sort((a, b) => a.candidates[0].epochSeconds - b.candidates[0].epochSeconds))
      ),
      onGetSelected: this.leftSelection,
      doOnOptieSelected: (arg) => arg.hasData && this.selectLeft(arg)
    };
  }

  public ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  private selectLeft(selection: { naam: string }): void {
    this.leftSelection.next(selection as NameAndCandidates);
  }

  private selectRight(selection: { naam: string }): void {
    this.rightSelection.next(selection as NameAndCandidates);
  }

  public confirm(): void {
    if (
      !!this.leftSelection.value
      && this.leftSelection.value.candidates.length > 0
      && !!this.leftSelection.value.candidates[0]
    ) {
      this.viewModel.setDatumbereik(this.leftSelection.value.candidates[0]);
    }
  }

  public close(): void {
    this.viewModel.close();
  }
}

function formatLeft(active: PERIODE, datumbereik: Datumbereik): NameAndCandidates {
  switch (active) {
    case 'WEEK':
      return {naam: week(datumbereik.epochSeconds), candidates: [datumbereik], hasData: datumbereik.hasData};
    case 'MAAND':
      return {naam: month(datumbereik.epochSeconds), candidates: [datumbereik], hasData: datumbereik.hasData};
    case 'JAAR':
      return {naam: schoolyear(datumbereik.epochSeconds), candidates: [datumbereik], hasData: datumbereik.hasData};
  }
}

function formatRight(datumbereik: Datumbereik): NameAndCandidates {
  return {naam: schoolyear(datumbereik.epochSeconds), candidates: [datumbereik], hasData: datumbereik.hasData};
}

function distinct(arr: NameAndCandidates[]): NameAndCandidates[] {
  const ret: NameAndCandidates[] = [];
  arr.forEach(v => {
    let c = ret.find(r => r.naam === v.naam);
    if (c === undefined) {
      c = {naam: v.naam, hasData: v.hasData, candidates: [v.candidates[0]]};
      ret.push(c);
    } else {
      c.candidates.push(v.candidates[0]);
    }
  });
  return ret;
}

export interface DatumbereikSelectiePanelComponentViewModel {
  onGetDatumbereiken: Observable<Datumbereik[]>;
  onGetCurrentDatumbereik: Observable<Datumbereik>;

  setDatumbereik: (datumbereik: Datumbereik) => void;
  close: () => void;
}

export interface NameAndCandidates {
  naam: string;
  hasData: boolean;
  candidates: Datumbereik[];
}
