import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import {KlassendashboardNewFacade} from '../../state/klassendashboard/klassendashboard.facade';
import {ModalwindowService} from '../../services/modalwindow.service';

@Component({
  selector: 'app-klassendashboard-individueel-table',
  templateUrl: './klassendashboard-individueel-table.component.html',
  styleUrls: ['./klassendashboard-individueel-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KlassendashboardIndividueelTableComponent implements OnInit {

  constructor(public store: KlassendashboardNewFacade, private modalwindowService: ModalwindowService) { }

  ngOnInit(): void {
  }

  public openGebruikHelp(): void {
    this.modalwindowService.toonOnderwerp('gebruikPerWeek');
  }

  public getTrendClass(n: number): string {
    switch (n) {
      case -1: return 'afgenomen';
      case 0: return 'gelijkgebleven';
      case 1: return 'toegenomen';
      default: return '';
    }
  }
}
