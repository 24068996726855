import {Component, OnInit, ChangeDetectionStrategy, Input} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-terug-knop-new',
  templateUrl: './terug-knop-new.component.html',
  styleUrls: ['./terug-knop-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TerugKnopNewComponent implements OnInit {

  @Input()
  viewmodel: TerugKnopViewmodel;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  public goBack(): void {
    this.router.navigate(this.viewmodel.pathElements, {queryParamsHandling: 'preserve'});
  }
}

export interface TerugKnopViewmodel {
  caption: string;
  pathElements: string[];
}
