import {Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-slider-afbeeldingen-toevoegen',
  templateUrl: './slider-afbeeldingen-toevoegen.component.html',
  styleUrls: ['./slider-afbeeldingen-toevoegen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SliderAfbeeldingenToevoegenComponent implements OnInit {

  @Input() koppelpartijAfbeeldingen: object[]; // not typed in lib.
  @Output() afbeeldingBijgewerkt: EventEmitter<object[]> = new EventEmitter<object[]>();

  constructor() { }

  ngOnInit(): void {
  }

  deleteAfbeelding(index: number) {
    this.koppelpartijAfbeeldingen.splice(index, 1);
    this.afbeeldingBijgewerkt.emit(this.koppelpartijAfbeeldingen);
  }

  addAfbeelding() {
    this.koppelpartijAfbeeldingen.push(Object.assign({image: '', thumbImage: ''}));
  }

  updateAfbeelding(objectIndex: number, target: EventTarget) {
    const url = (target as HTMLInputElement).value;
    const newAfbeeldingen = [...this.koppelpartijAfbeeldingen];
    newAfbeeldingen[objectIndex] = Object.assign({image: url, thumbImage: url});
    this.koppelpartijAfbeeldingen = newAfbeeldingen;
    this.afbeeldingBijgewerkt.emit(this.koppelpartijAfbeeldingen);
  }

}
