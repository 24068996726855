import {ChangeDetectionStrategy, Component, Input, OnChanges} from '@angular/core';
import { ChartType, ChartDataSets, ChartOptions} from 'chart.js';

import {
  actiefGebruik,
  geenActivatie,
  welActivatieGeenGebruik,
  geenActivatieGrijs,
  welActivatieGeenGebruikGrijs,
  actiefGebruikGrijs, typography_1, background_6
} from 'src/app/shared/colors';

@Component({
  selector: 'app-progressbar-percentage-only',
  templateUrl: './progressbar-percentage-only.component.html',
  styleUrls: ['./progressbar-percentage-only.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressbarPercentageOnlyComponent implements OnChanges {
  // progressbarinfo-tuple : [totaalAantalLicenties, teRenderenPercentage]
  @Input() public progressbarinfo: number[];
  public percentage: number;
  public renderPercentage: number;
  public barChartData: ChartDataSets[];
  public barChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      display: false,
    },
    elements: {
      rectangle: {
        borderWidth: 2,
        borderColor: typography_1,
        borderSkipped: 'none'
      }
    },
    cutoutPercentage: 85,
    tooltips: {
      enabled: false
    },
    scales: {
      display: false,
      xAxes: [{
        ticks: {
          display: false
        },
        stacked: true,
        gridLines: {
          offsetGridLines: true,
          color: 'rgba(0, 0, 0, 0)',
          drawBorder: false
        }
      }],
      yAxes: [{
        ticks: {
          display: false
        },
        stacked: true,
        gridLines: {
          offsetGridLines: true,
          color: 'rgba(0, 0, 0, 0)',
          drawBorder: false
        }
      }]}};
  public barChartType: ChartType = 'horizontalBar';
  public barChartLegend = true;

  static bepaalkleur(percentage: number): string[] {
    if (percentage < 34) {
      return [geenActivatie, geenActivatieGrijs];
    } else if (percentage > 33 && percentage < 67) {
      return [welActivatieGeenGebruik, welActivatieGeenGebruikGrijs];
    } else {
      return [actiefGebruik, actiefGebruikGrijs];
    }
  }

  ngOnChanges() {
    if (this.progressbarinfo && this.progressbarinfo.length === 2) {
      const total = this.progressbarinfo[0];
      this.percentage = Math.round((this.progressbarinfo[1] / total * 100));
      this.renderPercentage = this.percentage > 100 ? 100 : this.percentage;
      if (this.progressbarinfo[1] === 0) { this.percentage = 0; this.renderPercentage = 0; }
      const kleuren = ProgressbarPercentageOnlyComponent.bepaalkleur(this.renderPercentage);
      this.barChartData = [
        {
          data: [this.renderPercentage],
          backgroundColor: kleuren[0],
          hoverBackgroundColor: kleuren[0],
          hoverBorderColor: background_6,
          borderColor: background_6,
          label: 'Actief gebruik'
        },
        {
          data: [100 - this.renderPercentage],
          backgroundColor: kleuren[1],
          hoverBackgroundColor: kleuren[1],
          hoverBorderColor: background_6,
          borderColor: background_6,

          borderSkipped: 'left',
          label: 'Niet geactiveerd'
        }
      ];
    }
  }
}
