import { Component } from '@angular/core';
import {TableCellComponent} from '../table/table.model';
import {ProgressbarCellModel} from './progressbar-cell.model';

@Component({
  selector: 'app-progressbar-cell',
  templateUrl: './progressbar-cell.component.html',
  styleUrls: ['./progressbar-cell.component.scss']
})
export class ProgressbarCellComponent implements TableCellComponent<ProgressbarCellModel> {
  data: ProgressbarCellModel;
}
