<div class="container">
    <div [class]="dateDisplayStyle" (click)="open()">
        <p [class]="dateOrPlaceholderStyle">{{selectedDate}}</p>
        <div *ngIf="hasClearIcon && (viewModel.onGetSelectedDate | async)" class="containClear" (click)="clear()" (mouseenter)="hightlightClear()" (mouseleave)="lowlightClear()">
            <div [class]="clearIconStyle"></div>
        </div>
    </div>
    <p class="labelLabelSemi feedback">{{feedback}}</p>
    <div class="hibben">
        <input #input matInput [matDatepicker]="picker" [min]="today" (dateInput)="onDateSelected($event)" >
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    </div>
</div>
<mat-datepicker #picker></mat-datepicker>

