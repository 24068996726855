import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {OAuthModule} from 'angular-oauth2-oidc';
import {NamedLoginButtonComponent} from './named-login-button/named-login-button.component';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HomeComponent} from './home/home.component';
import {SharedModule} from './shared/shared.module';
import {Oauth2TokenInterceptor} from '../authentication/oauth2.token.interceptor';
import {LoadingInterceptor} from '../authentication/loading.interceptor';
import {MenuLeftComponent} from './menu-left/menu-left.component';
import {MenuService} from './menu-left/menu.service';
import {DeviceService} from './services/device.service';
import {HeaderComponent} from './layout/header/header.component';
import {RapportagesOverzichtComponent} from './old-dashboarding/rapportages-overzicht/rapportages-overzicht.component';
import {HomeDataService} from './home/home.data-service';
import {LayoutModule} from './layout/layout.module';
import {StyleGuideComponent} from './style-guide/style-guide.component';
import {ChangelogComponent} from './changelog/changelog.component';
import {ChartsModule} from 'ng2-charts';
import {LeermiddelLicentieDataService} from './services/leermiddellicentie.data-service';
import {LeermiddelGebruikDataService} from './services/leermiddelgebruik.data-service';
import {GraphQLModule} from './graphql.module';
import {registerLocaleData} from '@angular/common';
import nl from '@angular/common/locales/nl';
import { TerugknopComponent } from './navigation/terugknop.component';
import { SharedVariablesService } from './services/sharedVariables.service';
import { SchooldashboardComponent } from './old-dashboarding/schooldashboard/schooldashboard.component';
import { OAuthRouteComponent } from './oauth-route/oauth.route.component';
import { VakloosleermiddeldashboardComponent } from './old-dashboarding/vakloosleermiddeldashboard/vakloosleermiddeldashboard.component';
import { LeermiddelonderwijssoortTabelComponent } from './old-dashboarding/leermiddelonderwijssoort-tabel/leermiddelonderwijssoort-tabel.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { LesgroepDetailSamenvattingDashboardComponent } from './old-dashboarding/lesgroep-detail-samenvatting-dashboard/lesgroep-detail-samenvatting-dashboard.component';
import { LesgroepInfoSidebarComponent } from './old-dashboarding/lesgroep-info-sidebar/lesgroep-info-sidebar.component';
import { LesgroepDetailLeermiddelGebruikGrafiekComponent } from './old-dashboarding/lesgroep-detail-leermiddel-gebruik-grafiek/lesgroep-detail-leermiddel-gebruik-grafiek.component';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { LesgroepDetailLeermiddelGebruikTabelComponent } from './old-dashboarding/lesgroep-detail-leermiddel-gebruik-tabel/lesgroep-detail-leermiddel-gebruik-tabel.component';
import { TableModule } from './table/table.module';
import { ModalwindowService } from './services/modalwindow.service';
import { SchooldashboardTable } from './old-dashboarding/schooldashboard/schooldashboard.table.pipe';
import { SomtodayService } from './services/somtoday.service';
import { VakdashboardComponent } from './old-dashboarding/vakdashboard/vakdashboard.component';
import { KlassendashboardComponent } from './old-dashboarding/klassendashboard/klassendashboard.component';
import { MedewerkerVoornaamAchternaamAfkorting } from './formatting/medewerker.voornaam-achternaam-afkorting';
import { LeerlingAchternaamVoornaam } from './formatting/leerling.achternaam.voornaam';
import { UrlService } from './services/url.service';
import { SingleDecimalNumber } from './formatting/single-decimal-number';
import { AnalyticsService } from './services/analytics.service';
import { UserFeedbackButtonComponent } from './user-feedback-button/user-feedback-button.component';
import { UserFeedbackDialogComponent } from './user-feedback-dialog/user-feedback-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { StudiewijzerlinkjesModule } from './studiewijzerlinkjes/studiewijzerlinkjes.module';
import { ModalwindowComponent } from './modalwindow/modalwindow.component';
import { Swplatformvak } from './modalwindow/swplatformvak.pipe';
import { LeermiddelendashboardComponent } from './old-dashboarding/leermiddelendashboard/leermiddelendashboard.component';
import { LeermiddelendashboardTablePipe } from './old-dashboarding/leermiddelendashboard/leermiddelendashboard.table.pipe';
import { VestigingsinstellingenComponent } from './vestigingsinstellingen/vestigingsinstellingen.component';
import { StoreModule } from '@ngrx/store';
import { reducers } from './state/app.state';
import { extModules } from './build-specifics';
import { SchooldashboardDetailsComponent } from './old-dashboarding/schooldashboard-details/schooldashboard-details.component';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { FilterService } from './services/filter.service';
import { VakdashboardTablePipe } from './old-dashboarding/vakdashboard/vakdashboard.table.pipe';
import { KlassendashboardTablePipe } from './old-dashboarding/klassendashboard/klassendashboard.table.pipe';
import { SchooldashboardFacade } from './old-dashboarding/state/schooldashboard/schooldashboard.facade';
import { StudiewijzerlinksService } from './services/studiewijzerlinks.service';
import { StudiewijzerlinkjesFacade } from './old-dashboarding/state/studiewijzerlinkjes/studiewijzerlinkjes.facade';
import { KlassendashboardFacade } from './old-dashboarding/state/klassendashboard/klassendashboard.facade';
import { VakloosleermiddeldashboardTabelPipe } from './old-dashboarding/vakloosleermiddeldashboard/vakloosleermiddeldashboard-tabel.pipe';
import { VakloosleermiddeldashboardFacade } from './old-dashboarding/state/vakloosleermiddeldashboard/vakloosleermiddeldashboard.facade';
import { effects } from './effects';
import { LeermiddelvakkoppelingComponent } from './old-dashboarding/leermiddelvakkoppeling/leermiddelvakkoppeling.component';
import { LeermiddelvakkoppelingFacade } from './old-dashboarding/state/leermiddelvakkoppeling/leermiddelvakkoppeling.facade';
import { LeermiddelvakkoppelingPipe } from './old-dashboarding/leermiddelvakkoppeling/leermiddelvakkoppeling.pipe';
import { PrivacydashboardComponent } from './privacydashboard/privacydashboard.component';
import { PrivacydashboardFacade } from './state/privacydashboard/privacydashboard.facade';
import { PrivacydashboardTablePipe } from './privacydashboard/privacydashboard.table.pipe';
import { ModalwindowFacade } from './state/modalwindow/modalwindow.facade';
import { BeschikbareKoppelingen } from './modalwindow/beschikbare.koppelingen.pipe';
import { LeermiddelendashboardFacade } from './old-dashboarding/state/leermiddelendashboard/LeermiddelendashboardFacade';
import { KoppelingAanmakenPageComponent } from './koppeling-aanmaken-page/koppeling-aanmaken-page.component';
import { KoppelpartijDetailsPageComponent } from './koppelpartij-details-page/koppelpartij-details-page.component';
import { WhatsnewComponent } from './whatsnew/whatsnew.component';
import { CanDeactivateGuard } from './guards/can-deactivate.guard';
import { DevKoppelpartijBewerkenPageComponent } from './dev-koppelpartij-bewerken-page/dev-koppelpartij-bewerken-page.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgImageSliderModule } from 'ng-image-slider';
import { SubMenuLeftComponent } from './sub-menu-left/sub-menu-left.component';
import { DatatoegangComponent } from './datatoegang/datatoegang.component';
import { WebserviceComponent } from './webservice/webservice.component';
import { AanbodKoppelpartijenComponent } from './aanbod-koppelpartijen/aanbod-koppelpartijen.component';
import { KoppelpartijDetailsPreviewPageComponent } from './koppelpartij-details-preview-page/koppelpartij-details-preview-page.component';
import { MarkdownModule } from 'ngx-markdown';
import { SchooldashboardTableComponent } from './new-dashboard/schooldashboard-table/schooldashboard-table.component';
import { SchooldashboardTableRowComponent } from './new-dashboard/schooldashboard-table-row/schooldashboard-table-row.component';
import { VakdashboardTableComponent } from './new-dashboard/vakdashboard-table/vakdashboard-table.component';
import { KlassendashboardSamenvattingComponent } from './new-dashboard/klassendashboard-samenvatting/klassendashboard-samenvatting.component';
import { KlassendashboardSamenvattingSidebarComponent } from './new-dashboard/klassendashboard-samenvatting-sidebar/klassendashboard-samenvatting-sidebar.component';
import { KlassendashboardSamenvattingGrafiekComponent } from './new-dashboard/klassendashboard-samenvatting-grafiek/klassendashboard-samenvatting-grafiek.component';
import { KlassendashboardSamenvattingTableComponent } from './new-dashboard/klassendashboard-samenvatting-table/klassendashboard-samenvatting-table.component';
import { KlassendashboardIndividueelTableComponent } from './new-dashboard/klassendashboard-individueel-table/klassendashboard-individueel-table.component';
import { StudiewijzerlinkjesNewComponent } from './new-dashboard/studiewijzerlinkjes-new/studiewijzerlinkjes-new.component';
import { StudiewijzerlinkjesTableComponent } from './new-dashboard/studiewijzerlinkjes-table/studiewijzerlinkjes-table.component';
import { StudiewijzerlinkjesDetailNewComponent } from './new-dashboard/studiewijzerlinkjes-details-new/studiewijzerlinkjes-detail-new.component';
import { StudiewijzerlinkjesVaksectiePlatformDetailsComponent } from './new-dashboard/studiewijzerlinkjes-vaksectie-details/studiewijzerlinkjes-vaksectie-platform-details.component';
import { BazenbannerComponent } from './new-dashboard/bazenbanner/bazenbanner.component';
import { ControlsNewComponent } from './new-dashboard/controls-new/controls-new.component';
import { StudiewijzerDonutNewComponent } from './new-dashboard/studiewijzer-donut-new/studiewijzer-donut-new.component';
import { StudiewijzerlinkjesTableRowComponent } from './new-dashboard/studiewijzerlinkjes-table-row/studiewijzerlinkjes-table-row.component';
import { StudiewijzerlinkjesDetailTableRowNewComponent } from './new-dashboard/studiewijzerlinkjes-detail-table-row-new/studiewijzerlinkjes-detail-table-row-new.component';
import { StudiewijzerlinkjesVaksectiePlatformDetailsTableRowComponentComponent } from './new-dashboard/studiewijzerlinkjes-vaksectie-platform-details-table-row-component/studiewijzerlinkjes-vaksectie-platform-details-table-row-component.component';
import { VestigingendropdownNewComponent } from './new-dashboard/vestigingendropdown-new/vestigingendropdown-new.component';
import { ControlsHeaderComponent } from './new-dashboard/controls-header/controls-header.component';
import { KlassendashboardNewComponent } from './new-dashboard/klassendashboard-new/klassendashboard-new.component';
import { SchooldashboardNewComponent } from './new-dashboard/schooldashboard-new/schooldashboard-new.component';
import { VakdashboardNewComponent } from './new-dashboard/vakdashboard-new/vakdashboard-new.component';
import { DashboardingService } from './services/dashboarding.service';
import { DashboardsettingsFacade } from './state/dashboardsettings/dashboardsettings.facade';
import { KlassendashboardNewFacade } from './state/klassendashboard/klassendashboard.facade';
import { LeermiddelvakkoppelingNewFacade } from './state/leermiddelvakkoppeling/leermiddelvakkoppeling.facade';
import { LeermiddelvakkoppelingComponent as LeermiddelvakkoppelingNewComponent } from './new-dashboard/leermiddelvakkoppeling/leermiddelvakkoppeling.component';
import { LeermiddelvakkoppelingVakkenComponent } from './new-dashboard/leermiddelvakkoppeling-vakken/leermiddelvakkoppeling-vakken.component';
import { SchooldashboardNewFacade } from './state/schooldashboard/schooldashboard.facade';
import { StudiewijzerlinkjesNewFacade } from './state/studiewijzerlinkjes/studiewijzerlinkjes.facade';
import { VakdashboardNewFacade } from './state/vakdashboard/vakdashboard.facade';
import { ApiModule as AvgApi } from 'src/generated/avg-client';
import { ApiModule as DashboardApi } from 'src/generated/dashboard-client';
import { ApiModule as BackofficeApi } from 'src/generated/backoffice-client';
import { environment } from 'src/environments/environment';
import { AvgBackendService } from 'src/app/services/avg-backend.service';
import { SemKoppelpartijDetailsPageComponent } from './sem-koppelpartij-details-page/sem-koppelpartij-details-page.component';
import { KlassendashboardLesgroepSwitchComponent } from './new-dashboard/klassendashboard-lesgroep-switch/klassendashboard-lesgroep-switch.component';
import { KlassendashboardControlsHeaderComponent } from './new-dashboard/klassendashboard-controls-header/klassendashboard-controls-header.component';
import { DatepickerComponent } from './new-dashboard/datepicker/datepicker.component';
import { DatumbereikSelectiePanelComponent } from './new-dashboard/datumbereik-selectie-panel/datumbereik-selectie-panel.component';
import { BackofficeDashboardNewComponent } from './new-dashboard/backoffice-dashboard-new/backoffice-dashboard-new.component';
import { DashboardBackofficeService } from './services/dashboard-backoffice.service';
import { DashboardBackofficeFacade } from './state/dashboard-backoffice/dashboard-backoffice.facade';
import { SchoolyearRowComponent } from './new-dashboard/schoolyear-row/schoolyear-row.component';
import { LeermiddelVakKoppelingSidebarSelectedComponent } from './new-dashboard/leermiddel-vak-koppeling-sidebar-selected/leermiddel-vak-koppeling-sidebar-selected.component';
import { SchooldashboardOngekoppeldRowComponent } from './new-dashboard/schooldashboard-ongekoppeld-row/schooldashboard-ongekoppeld-row.component';
import {ExportoverzichtPageComponent} from './avg/exportoverzicht/exportoverzicht-page/exportoverzicht-page.component';
import {ExportoverzichtFacade} from './state/exportoverzicht/exportoverzicht.facade';
import {
  ExportoverzichtDetailsComponent
} from './avg/exportoverzicht/exportoverzicht-details/exportoverzicht-details.component';
import {MatTabsModule} from "@angular/material/tabs";
import {MatTooltipModule} from "@angular/material/tooltip";
import {TranslateService} from "./services/translate.service";
import {ExportSidebarComponent} from './avg/exportoverzicht/export-sidebar/export-sidebar.component';
import {ExportoverzichtListComponent} from './avg/exportoverzicht/exportoverzicht-list/exportoverzicht-list.component';
import {ClickStopPropagationDirective} from './stop-click-propegation/click-stop-propegation';
import {ExportFilterComponent} from './export-filter/export-filter.component';
import {DatepickerService} from "./services/datepicker.service";
import {PortalModule} from "@angular/cdk/portal";
import {PortalBridgeService} from "./services/portal-bridge.service";
import {PaginatorComponent} from './paginator/paginator.component';

registerLocaleData(nl);

@NgModule({
  declarations: [
    AppComponent,
    NamedLoginButtonComponent,
    HomeComponent,
    MenuLeftComponent,
    HeaderComponent,
    RapportagesOverzichtComponent,
    StyleGuideComponent,
    ChangelogComponent,
    TerugknopComponent,
    SchooldashboardComponent,
    VakdashboardComponent,
    VakloosleermiddeldashboardComponent,
    OAuthRouteComponent,
    LeermiddelonderwijssoortTabelComponent,
    ErrorPageComponent,
    KlassendashboardComponent,
    LesgroepDetailSamenvattingDashboardComponent,
    LesgroepInfoSidebarComponent,
    LesgroepDetailLeermiddelGebruikGrafiekComponent,
    LesgroepDetailLeermiddelGebruikTabelComponent,
    SchooldashboardTable,
    MedewerkerVoornaamAchternaamAfkorting,
    LeerlingAchternaamVoornaam,
    SingleDecimalNumber,
    UserFeedbackButtonComponent,
    UserFeedbackDialogComponent,
    ModalwindowComponent,
    Swplatformvak,
    LeermiddelendashboardComponent,
    LeermiddelendashboardTablePipe,
    VestigingsinstellingenComponent,
    LeermiddelendashboardTablePipe,
    SchooldashboardDetailsComponent,
    VakdashboardTablePipe,
    KlassendashboardTablePipe,
    VakloosleermiddeldashboardTabelPipe,
    LeermiddelvakkoppelingComponent,
    LeermiddelvakkoppelingPipe,
    PrivacydashboardComponent,
    PrivacydashboardTablePipe,
    BeschikbareKoppelingen,
    KoppelingAanmakenPageComponent,
    KoppelpartijDetailsPageComponent,
    WhatsnewComponent,
    SubMenuLeftComponent,
    DatatoegangComponent,
    WebserviceComponent,
    AanbodKoppelpartijenComponent,
    WhatsnewComponent,
    DevKoppelpartijBewerkenPageComponent,
    KoppelpartijDetailsPreviewPageComponent,
    SchooldashboardTableComponent,
    SchooldashboardTableRowComponent,
    VakdashboardTableComponent,
    KlassendashboardSamenvattingComponent,
    KlassendashboardSamenvattingSidebarComponent,
    KlassendashboardSamenvattingGrafiekComponent,
    KlassendashboardSamenvattingTableComponent,
    KlassendashboardIndividueelTableComponent,
    StudiewijzerlinkjesNewComponent,
    StudiewijzerlinkjesTableComponent,
    StudiewijzerlinkjesDetailNewComponent,
    StudiewijzerlinkjesVaksectiePlatformDetailsComponent,
    StudiewijzerDonutNewComponent,
    StudiewijzerlinkjesTableRowComponent,
    StudiewijzerlinkjesDetailTableRowNewComponent,
    StudiewijzerlinkjesVaksectiePlatformDetailsTableRowComponentComponent,
    VestigingendropdownNewComponent,
    KlassendashboardNewComponent,
    BazenbannerComponent,
    ControlsHeaderComponent,
    SchooldashboardNewComponent,
    LeermiddelvakkoppelingNewComponent,
    LeermiddelvakkoppelingVakkenComponent,
    ControlsNewComponent,
    KlassendashboardNewComponent,
    VakdashboardNewComponent,
    SemKoppelpartijDetailsPageComponent,
    KlassendashboardLesgroepSwitchComponent,
    KlassendashboardControlsHeaderComponent,
    DatepickerComponent,
    DatumbereikSelectiePanelComponent,
    BackofficeDashboardNewComponent,
    SchoolyearRowComponent,
    LeermiddelVakKoppelingSidebarSelectedComponent,
    SchooldashboardOngekoppeldRowComponent,
    ExportoverzichtPageComponent,
    ExportoverzichtDetailsComponent,
    ExportSidebarComponent,
    ExportoverzichtListComponent,
    ExportFilterComponent,
    ClickStopPropagationDirective,
    PaginatorComponent,
  ],
  imports: [
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatProgressSpinnerModule,
    OAuthModule.forRoot(),
    ToastrModule.forRoot(),
    ChartsModule,
    GraphQLModule,
    ClickOutsideModule,
    TableModule,
    Ng2GoogleChartsModule,
    MatDialogModule,
    StudiewijzerlinkjesModule,
    StoreModule.forRoot(reducers),
    StoreRouterConnectingModule.forRoot(),
    extModules,
    EffectsModule.forRoot(effects),
    NgImageSliderModule,
    MarkdownModule.forRoot(),
    AvgApi.forRoot({ rootUrl: environment.apiUrl }),
    DashboardApi.forRoot({ rootUrl: environment.dashboardApiUrl }),
    BackofficeApi.forRoot({ rootUrl: environment.dashboardApiUrl }),
    MatTabsModule,
    MatTooltipModule,
    PortalModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: Oauth2TokenInterceptor,
    multi: true
  }, {
    provide: HTTP_INTERCEPTORS,
    useClass: LoadingInterceptor,
    multi: true
  }, {
    provide: LOCALE_ID,
    useValue: 'nl-NL'
  },
    LeermiddelLicentieDataService,
    MenuService,
    DeviceService,
    DatepickerService,
    HomeDataService,
    LeermiddelGebruikDataService,
    SharedVariablesService,
    SomtodayService,
    AvgBackendService,
    ModalwindowService,
    UrlService,
    AnalyticsService,
    PortalBridgeService,
    TranslateService,
    FilterService,
    SchooldashboardFacade,
    StudiewijzerlinkjesFacade,
    StudiewijzerlinksService,
    VakloosleermiddeldashboardFacade,
    LeermiddelvakkoppelingFacade,
    PrivacydashboardFacade,
    ModalwindowFacade,
    LeermiddelendashboardFacade,
    CanDeactivateGuard,
    DashboardingService,
    DashboardsettingsFacade,
    KlassendashboardFacade,
    KlassendashboardNewFacade,
    LeermiddelvakkoppelingNewFacade,
    SchooldashboardNewFacade,
    StudiewijzerlinkjesNewFacade,
    VakdashboardNewFacade,
    DashboardBackofficeService,
    DashboardBackofficeFacade,
    ExportoverzichtFacade
  ],
  exports: []
  ,
  bootstrap: [AppComponent]
})
export class AppModule {
}
