<div class="container">
  <h1>{{titel}}</h1>
  <table>
    <thead>
      <tr>
        <th></th><th>Links</th><th>Klikratio</th><th>Lesgroepen</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of data" [ngClass]="{'overig-titel': item.platform.naam === 'Overig'}" (click)="showDetails(item)">
        <td class="naam" [class.currentItem]="isCurrentItem(item.platform)">
          <span class="color-box" [ngStyle]="getBoxStyle(item)"></span>
          <div [class.overig]="item.platform.overig">
            {{item.platform.naam | vakorvakonbekend}}
          </div>
        </td>
        <ng-container *ngIf="item.platform.naam !== 'Overig'">
          <td class="links"><div [style.width]="linksWidth">{{item.platform.links}}</div></td>
          <td class="klikratio"><div>{{item.platform.klikratio | percent}}</div></td>
          <td class="lesgroepen"><div>{{item.platform.lesgroepen}}</div></td>
        </ng-container>
      </tr>
    </tbody>
  </table>
</div>
