import {Injectable} from '@angular/core';
import {DashboardFilterInput, StudiewijzerlinksPerPlatformGQL, StudiewijzerlinksPerVakGQL} from '../../generated/graphql';
import {Observable} from 'rxjs';
import {SWDashboardData} from '../old-dashboarding/state/schooldashboard/schooldashboard.actions';
import {map} from 'rxjs/operators';
import {rehydrateVestigingen} from './vestiging.service';

@Injectable()
export class StudiewijzerlinksService {
  constructor(private swpq: StudiewijzerlinksPerPlatformGQL, private swvq: StudiewijzerlinksPerVakGQL) {
  }

  fetch(f: DashboardFilterInput, isPerPlatform: boolean): Observable<SWDashboardData> {
    if (isPerPlatform) {
      return this.swpq.fetch({filter: f}).pipe(map(toDashboardData));
    } else {
      return this.swvq.fetch({filter: f}).pipe(map(toDashboardData));
    }
  }
}

function toDashboardData({data}): SWDashboardData {
  return {
    vestigingen: rehydrateVestigingen(data.vestigingen),
    swData: {
      studiewijzerlinksPerPlatformOfVak: data.studiewijzerlinksPerPlatform || data.studiewijzerlinksPerVak,
      aantalLesgroepenMetStudiewijzerlinks: data.aantalLesgroepenMetStudiewijzerlinks
    }
  };
}
