import {Component, Input} from '@angular/core';
import {TableCellComponent} from '../table/table.model';
import {RangebarCellModel} from './rangebar-cell.model';

@Component({
  selector: 'app-rangebar-cell',
  templateUrl: './rangebar-cell.component.html',
  styleUrls: ['./rangebar-cell.component.scss']
})
export class RangebarCellComponent implements TableCellComponent<RangebarCellModel> {
  @Input()
  data: RangebarCellModel;
}
