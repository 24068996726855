<div *ngIf="(vestigingen$ | async).length">
  <a (click)="expanded = !expanded" (clickOutside)="expanded=false" [clickOutsideEvents]="'click,touchend'">
    <img alt="Vestiging" src="../../../assets/img/icons/Symbool/map-marker.svg">
    <span>{{(vestiging$ | async).naam}}</span>
    <i [class]="expanded ? 'icon-arrow-close' : 'icon-arrow-open'"></i>
  </a>
  <div *ngIf="expanded" class="vestigingoverlay">
    <h3>Bekijk vestiging</h3>
    <ul>
      <li *ngFor="let vestiging of vestigingen$ | async"
          (click)="selectie = vestiging"
          [class.selected]="vestiging.selected"
      >
        <img alt="Vestiging" src="../../../assets/img/icons/Symbool/map-marker.svg">
        {{vestiging.naam}}
      </li>
    </ul>
  </div>
</div>




