<div class="grayout" (window:scroll)="onScroll()" [style.top]="verticalOffset + 'px'" (click)="closeVenster()">
</div>
<div class="uitlegoverlay" [style.top]="verticalOffset + 'px'">
  <div class="header">
    <i class="icon-koppelteken"></i>
    <span>Leermiddel-vak koppelingen</span>
    <a (click)="closeVenster()">
      <i class="icon-wit-kruis"></i>
    </a>
  </div>
  <div class="informatie">
    <i class="icon-uitroepteken"></i>
    <span>Persoonlijke licenties (ELF) kun je hier koppelen aan een vak,
      waarna ze getoond kunnen worden in de dashboards.</span>
  </div>
  <app-connect-loader [loading]="loading" [showText]="false">
    <div class="tabel">
      <app-table *ngIf="dataZonderVak && dataZonderVak.length !== 0" [model]="[dataZonderVak, true] | leermiddelvakkoppelingPipe"></app-table>
      <a *ngIf="dataMetVak && dataMetVak.length !== 0" (click)="toonGekoppeldeLeermiddelen = !toonGekoppeldeLeermiddelen">
        <span>{{toonGekoppeldeLeermiddelen ? 'Verberg gekoppelde leermiddelen' : 'Toon gekoppelde leermiddelen'}}</span>
        <i [class]="toonGekoppeldeLeermiddelen ? 'icon-arrow-close' : 'icon-arrow-open'"></i>
      </a>
      <app-table *ngIf="toonGekoppeldeLeermiddelen && dataMetVak && dataMetVak.length !== 0" [model]="[dataMetVak, !dataZonderVak || dataZonderVak.length === 0] | leermiddelvakkoppelingPipe"></app-table>
    </div>
    <app-leermiddelvakkenlijst *ngIf="selectedLeermiddelVakKoppeling" [leermiddelVakKoppelingInfo]="selectedLeermiddelVakKoppeling"
                               [top]="topVakkenlijst" [width]="widthVakkenlijst"></app-leermiddelvakkenlijst>
  </app-connect-loader>
</div>

