import {Observable} from 'rxjs';
import {selectQueryParam} from '../router/router.selectors';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {BaseFacade} from '../base.facade';
import {Store} from '@ngrx/store';
import {AppState} from '../app.state';
import {StudiewijzerDashboardItem} from './studiewijzerlinkjes.state';
import {selectPlatformGebruik, selectVakGebruik} from './studiewijzerlinkjes.selectors';

@Injectable()
export class StudiewijzerlinkjesNewFacade extends BaseFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }

  isGegroepeerdOpPlatform(): Observable<boolean> {
    return this.select(selectQueryParam('groeperenOpVak')).pipe(map(value => value !== 'true'));
  }

  isGegroepeerdOpVak(): Observable<boolean> {
    return this.select(selectQueryParam('groeperenOpVak')).pipe(map(value => value === 'true'));
  }

  getVakGebruik(): Observable<StudiewijzerDashboardItem[]> {
    return this.select(selectVakGebruik);
  }

  hasVakGebruik(): Observable<boolean> {
    return this.select(selectVakGebruik).pipe(map(vakGebruik => vakGebruik.length > 0));
  }

  getPlatformGebruik(): Observable<StudiewijzerDashboardItem[]> {
    return this.select(selectPlatformGebruik);
  }

  hasPlatformGebruik(): Observable<boolean> {
    return this.select(selectPlatformGebruik).pipe(map(platformGebruik => platformGebruik.length > 0));
  }
}
