import {Injectable} from '@angular/core';
import {Datumbereik, PERIODE} from "../state/dashboardsettings/dashboardsettings.state";

@Injectable({
  providedIn: 'root'
})
export class DatepickerService {

  get PERIODES() {
    return [
      'WEEK',
      'MAAND',
      'JAAR'
    ]
  }

  private beginSchooljaar: number;
  private currentDate: Date;

  constructor() {

  }

  public setDatumBereik(currentDate: Date): Datumbereik[] {
    this.beginSchooljaar = this.getBeginSchooljaarTimestamp(currentDate);
    this.currentDate = currentDate;

    return this.getDatumBereiken();
  }

  public getDateStringFromDate(date: Date): string {
    const month = `0${(date.getMonth() + 1)}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
  }

  public getDatumBereikFilter(datumBereik: Datumbereik) {
    const {epochSeconds, periode} = datumBereik;

    const beginDate = new Date(epochSeconds * 1000);

    const begindatum = this.getDateStringFromDate(beginDate);
    const einddatum = this.getEndDateBasedOnPeriod(beginDate, periode);

    return {
      begindatum,
      einddatum,
    }
  }

  private getEndDateBasedOnPeriod(date: Date, periode: PERIODE) {
    switch (periode) {
      case 'JAAR':
        return this.getNextYear(date)
      case 'MAAND':
        return this.getNextMonth(date)
      default:
        return this.getNextWeek(date)
    }
  }

  private getNextYear(date: Date): string {
    const year = date.getFullYear() + 1;
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const nextYear = new Date(`${month} ${day} ${year}`);

    return this.getDateStringFromDate(nextYear);
  }

  private getNextMonth(date: Date) {
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const nextMonth = new Date(year, month, 0);

    return this.getDateStringFromDate(nextMonth);
  }

  private getNextWeek(date: Date) {
    // epoch value will always be a Monday
    const nextWeek = date.getDate() + 6;
    const nextDate = new Date(date.setDate(nextWeek));

    return this.getDateStringFromDate(nextDate);
  }

  private getDatumBereiken(): Datumbereik[] {
    return this.PERIODES.reduce((datumBereik, period) => {
      switch (period) {
        case 'WEEK':
          const weekBereik = this.getWeekDatumBereik();
          return [
            ...datumBereik,
            ...weekBereik
          ]
        case 'MAAND':
          const maandBereik = this.getMaandDatumBereik();
          return [
            ...datumBereik,
            ...maandBereik
          ];
        case 'JAAR':
          const jaarBereik = this.getJaarBereik();

          return [
            ...datumBereik,
            ...jaarBereik
          ]
        default:
          return datumBereik;
      }
    }, [])
  }

  private getWeekDatumBereik() {
    let beginDate = new Date(this.beginSchooljaar);
    const daysUntilMonday = this.getDaysUntilMonday(beginDate.getDay());

    beginDate.setDate(beginDate.getDate() + daysUntilMonday);

    const beginTimestamp = Date.parse(beginDate.toDateString());
    const endTimestamp = Date.parse(this.currentDate.toDateString());

    let date = new Date(this.beginSchooljaar);
    let currentTimestamp = beginTimestamp;

    let datumBereik: Datumbereik[] = [
      {
        epochSeconds: beginTimestamp / 1000,
        periode: 'WEEK',
        hasData: true
      }
    ];

    while (currentTimestamp < endTimestamp) {
      date.setDate(date.getDate() + 7);

      currentTimestamp = Date.parse(date.toDateString());

      if (date > this.currentDate) {
        currentTimestamp = Date.parse(this.currentDate.toDateString());
      }

      datumBereik = [
        ...datumBereik,
        {
          epochSeconds: currentTimestamp / 1000,
          periode: 'WEEK',
          hasData: true
        }
      ]
    }

    return datumBereik;
  }

  private getMaandDatumBereik(): Datumbereik[] {
    const beginDate = new Date(this.beginSchooljaar);

    const currentYear = this.currentDate.getFullYear();
    const beginYear = beginDate.getFullYear();

    const currentMonth = this.currentDate.getMonth();
    const beginMonth = beginDate.getMonth();

    const numberOfMonths = (currentYear - beginYear) * 12 - beginMonth + currentMonth + 1;

    let datumBereik: Datumbereik[] = []

    let firstDay = new Date(beginYear, beginMonth, 1);

    for (let i = 0; i < numberOfMonths; i++) {
      datumBereik = [
        ...datumBereik,
        {
          epochSeconds: Date.parse(firstDay.toDateString()) / 1000,
          periode: 'MAAND',
          hasData: true,
        }
      ]

      firstDay = new Date(firstDay.getFullYear(), firstDay.getMonth() + 1, 1);
    }

    return datumBereik;
  }

  private getJaarBereik(): Datumbereik[] {
    return [
      {
        epochSeconds: this.beginSchooljaar / 1000,
        periode: 'JAAR',
        hasData: true
      }
    ]
  }

  private getDaysUntilMonday(day: number) {
    const days = 8 - day;

    switch (days) {
      case 7:
        return 0;
      case 8:
        return 1;
      default:
        return days;
    }
  }

  private getBeginSchooljaarTimestamp(date): number {
    let month = date.getMonth();
    let year = date.getFullYear();

    if (month < 9) {
      year = year - 1;
    }

    return Date.parse(`01 Aug ${year}`);
  }
}
