import {Component, ChangeDetectionStrategy, Input} from '@angular/core';

@Component({
    selector: 'app-info-blok',
    templateUrl: './info-blok.component.html',
    styleUrls: ['./info-blok.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoBlokComponent {
    @Input()
    infoText: string;
}
