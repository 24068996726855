import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../state/app.state';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {selectLesgroepen} from '../state/vakdashboard/vakdashboard.selectors';
import {catchError, map, switchMap, take, withLatestFrom} from 'rxjs/operators';
import {DocentenVanLesgroepGQL} from '../../../generated/graphql';
import * as AppSelectors from '../../state/app.selectors';
import {payload} from '../../state/payload';
import {loadDocenten, setDocenten} from '../state/schooldashboard/schooldashboard.actions';
import {of} from 'rxjs';
import {reportError} from '../../state/errors/errors.actions';
import {LesgroepDocenten} from '../state/schooldashboard/schooldashboard.state';

@Injectable()
export class DocentenEffect {
  loadDocenten$ = createEffect(() => this.actions$.pipe(
    ofType(loadDocenten),
    switchMap(datum => this.store.select(AppSelectors.selectAppFilter, datum).pipe(take(1))),
    withLatestFrom(this.store.select(selectLesgroepen)),
    switchMap(([filter, lesgroepUUIDs]) => this.gql.fetch({filter, lesgroepUUIDs})),
    map(({data}) => data.docentenVanLesgroep),
    map(entries => entries.reduce((a, {key, value}) => { a[key] = value; return a; }, {} as LesgroepDocenten)),
    map(payload),
    map(setDocenten),
    catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
    )
  );

  constructor(private actions$: Actions,
              private store: Store<AppState>,
              private gql: DocentenVanLesgroepGQL) {
  }
}
