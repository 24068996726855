import {createReducer, on} from '@ngrx/store';

import {GezamenlijkGebruik, KlassendashboardState} from './klassendashboard.state';
import {
  resetKlassendashboard,
  setDocenten,
  setKlassendashboardAlgemeen,
  setKlassendashboardIndividueel,
  setKlassendashboardSamenvatting, setLeerlingen
} from './klassendashboard.actions';

const initialState: KlassendashboardState = {
  lesgroepUUID: null,
  lesgroepNaam: null,
  individueelGebruik: null,
  gezamenlijkGebruik: null,
};

export const klassendashboardReducer = createReducer(
    initialState,
    on(setKlassendashboardAlgemeen, (state, { lesgroepNaam }) => (state.lesgroepNaam !== lesgroepNaam) ? ({...state, lesgroepNaam}) : state),
    on(setKlassendashboardIndividueel, (state, { individueelGebruik }) => ({...state, individueelGebruik})),
    on(setKlassendashboardSamenvatting, (state, { gezamenlijkGebruik }) => ({...state, gezamenlijkGebruik: roundGebruikPerWeek(gezamenlijkGebruik)})),
    on(setDocenten, (state, { namen }) => ({...state, gezamenlijkGebruik: ({...state.gezamenlijkGebruik, docentNamen: namen})})),
    on(setLeerlingen, (state, { value }) => ({
      ...state,
      individueelGebruik: ({
        ...state.individueelGebruik,
        leerlingRegels: state.individueelGebruik?.leerlingRegels?.map(r => ({
            ...r,
            leerlingNaam: value.find(leerling => leerling.uuid === r.leerlingUUID)?.naam
        }))
      })
    })),
    on(resetKlassendashboard, _ => initialState),
);

const roundGebruikPerWeek = (gebruik: GezamenlijkGebruik): GezamenlijkGebruik => {
  return {
      ...gebruik,
      leermiddelTabel: gebruik.leermiddelTabel.map(t => ({
        ...t,
        schoolbreed: {
          ...t.schoolbreed,
          gemiddeldGebruikPerWeek: Math.round(t.schoolbreed.gemiddeldGebruikPerWeek * 10) / 10
        },
        voorDezeLesgroep: {
          ...t.voorDezeLesgroep,
          gemiddeldGebruikPerWeek: Math.round(t.voorDezeLesgroep.gemiddeldGebruikPerWeek * 10) / 10
        }
      }))
    };
};
