<div class="NgxEditor__Wrapper">
  <!-- Create the toolbar container -->
  <div id="toolbar">
    <button class="ql-bold">Bold</button>
    <button class="ql-italic">Italic</button>
  </div>

  <!-- Create the editor container -->
  <div id="editor">
  </div>
</div>
