import { Component, OnInit } from '@angular/core';
import {Gebruiksaantallen, LeermiddelGebruikDataService} from '../../services/leermiddelgebruik.data-service';

@Component({
  selector: 'app-rapportages-overzicht',
  templateUrl: './rapportages-overzicht.component.html',
  styleUrls: ['./rapportages-overzicht.component.scss']
})
export class RapportagesOverzichtComponent implements OnInit {
  gebruiksAantallen: Gebruiksaantallen;
  initialized = false;

  constructor(private client: LeermiddelGebruikDataService) { }

  ngOnInit() {
    this.client.getLeermiddelGebruik().subscribe({
      next: leermiddelGebruik => { this.gebruiksAantallen = leermiddelGebruik; this.initialized = true;
      } });
  }
}
