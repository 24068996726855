<div class="title grid-left">
  <i class="logo {{ icon }}"></i>
  <span [class.icon-font]="iconFont"> {{title}} </span>
</div>

<div class="grid-center" *ngIf="component">
  <app-header-content [component]="component"></app-header-content>
</div>

<div class="grid-right">
  <app-feedback-button class="user-feedback"></app-feedback-button>
  <app-named-login-button class="login"></app-named-login-button>
</div>
