import {createSelector} from '@ngrx/store';
import {selectStudiewijzerlinkjes} from '../app.state';

export const selectVakGebruik = createSelector(
  selectStudiewijzerlinkjes,
  (state) => state.vakGebruik
);

export const selectPlatformGebruik = createSelector(
  selectStudiewijzerlinkjes,
  (state) => state.platformGebruik
);
