import {createSelector} from '@ngrx/store';
import {selectLeermiddelvakkoppeling} from '../../../state/app.state';

export const selectLeermiddelVakKoppelingOpen = createSelector(
  selectLeermiddelvakkoppeling,
  ({leermiddelVakKoppelingOpen}) => leermiddelVakKoppelingOpen
);

export const selectLeermiddelVakKoppelingLoading = createSelector(
  selectLeermiddelvakkoppeling,
  ({leermiddelVakKoppelingLoading}) => leermiddelVakKoppelingLoading
);

export const selectLeermiddelVakKoppelingData = createSelector(
  selectLeermiddelvakkoppeling,
  ({leermiddelVakKoppelingData}) => leermiddelVakKoppelingData
);

export const selectVestigingVakkenData = createSelector(
  selectLeermiddelvakkoppeling,
  ({vestigingVakkenData}) => vestigingVakkenData
);

export const selectGekoppeldeLeermiddelen = createSelector(
  selectLeermiddelvakkoppeling,
  ({gekoppeldeLeermiddelen}) => gekoppeldeLeermiddelen
);

export const selectSelectedLeermiddelVakKoppeling = createSelector(
  selectLeermiddelvakkoppeling,
  ({selectedLeermiddelVakKoppeling}) => selectedLeermiddelVakKoppeling
)
