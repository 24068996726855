import {Component, Input, OnInit} from '@angular/core';
import {BigInfoData} from './big-info-data';

@Component({
  selector: 'app-big-info-detail',
  templateUrl: './big-info-detail.component.html',
  styleUrls: ['./big-info-detail.component.scss']
})
export class BigInfoDetailComponent implements OnInit {

  @Input() information: BigInfoData;

  constructor() { }

  ngOnInit() {
  }

}
