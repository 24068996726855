import { Injectable }           from '@angular/core';
import { Observable }           from 'rxjs';
import { CanDeactivate,
         ActivatedRouteSnapshot,
         RouterStateSnapshot }  from '@angular/router';

import { KoppelingAanmakenPageComponent } from '../koppeling-aanmaken-page/koppeling-aanmaken-page.component';

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<KoppelingAanmakenPageComponent> {

    canDeactivate(
        component: KoppelingAanmakenPageComponent,
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | boolean {
        if (!component.magWegNavigeren && !component.terugNaarOverzichtPopup) {
          component.openPopup();
        }
        return component.magWegNavigeren === true;
    }
}
