import {Component, OnInit, ChangeDetectionStrategy, Input, ElementRef} from '@angular/core';
import { Leermiddel, Vak } from 'src/app/state/leermiddelvakkoppeling/leermiddelvakkoppeling.state';
import {LeermiddelvakkoppelingNewFacade} from '../../state/leermiddelvakkoppeling/leermiddelvakkoppeling.facade';
import {setSelectedLeermiddelVakKoppeling} from '../../state/leermiddelvakkoppeling/leermiddelvakkoppeling.actions';

@Component({
    selector: 'app-leermiddelvakkoppeling-vakken',
    templateUrl: './leermiddelvakkoppeling-vakken.component.html',
    styleUrls: ['./leermiddelvakkoppeling-vakken.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeermiddelvakkoppelingVakkenComponent implements OnInit {

    @Input()
    public viewModel: LeermiddelvakkoppelingVakkenComponentViewModel;

    @Input()
    public leermiddel: Leermiddel;

    public gekoppeldeVakken: Vak[];

    constructor(public store: LeermiddelvakkoppelingNewFacade,
                private elRef: ElementRef) { }

    ngOnInit(): void {
        this.gekoppeldeVakken = [...this.leermiddel.gekoppeldAanVakken].sort((a, b) => a?.naam?.toLocaleLowerCase()?.localeCompare(b?.naam?.toLocaleLowerCase()));
    }

    open(): void {
        this.store.setSelectedLeermiddelVakKoppeling({leermiddel: this.leermiddel, top:
          // Bound op het onderste deel van het scherm - 350px, zodat het vakkenselecteer component niet uit het scherm valt
          Math.min(this.elRef.nativeElement.getBoundingClientRect().top, window.innerHeight - 350)
        });
    }

    ontkoppel(event): void {
        event.stopPropagation();
        this.viewModel.storeLeermiddel({ ...this.leermiddel, gekoppeldAanVakken: [] });
    }
}

export interface LeermiddelvakkoppelingVakkenComponentViewModel {
    storeLeermiddel: (leermiddel: Leermiddel) => void;
}

