<div class="subheader">
    <p class="bodyContentSemi stap">
        Stap 3/3 - Akkoord
    </p>
</div>

<div class="samenvatting">
    <div class="bodySmallContentSemi samenvattingHeader">Deze applicatie vraagt toegang tot</div>
    <div class="velden">
      <div class="veld basisvelden">
        <i class="basisveldenlogo"></i>
        <div class="bodySmallContent tekst">{{basisvelden | async}}</div>
      </div>
      <div class="veld vestigingoverzicht">
        <i class="vestigingenlogo"></i>
        <div class="bodySmallContent tekst">{{vestigingen | async}}</div>
      </div>
      <div class="veld extravelden">
        <i class="extraveldenlogo"></i>
        <div class="bodySmallContent tekst">{{extraVelden | async}}</div>
      </div>
      <div class="veld leerlingenoverzicht">
        <i class="leerlingenlogo"></i>
        <div class="bodySmallContent tekst">{{leerlingSelectieText}}</div>
      </div>
      <div *ngIf="writevelden | async" class="veld writeoverzicht">
        <i class="uitwisselen"></i>
        <div class="bodySmallContent tekst">{{writevelden | async}}</div>
      </div>
    </div>
</div>

<div class="datumVelden">
    <div class="left">
        <p class="bodyContentSemi">Ingangsdatum</p>
        <app-select-date [viewModel]="ingangsdatumPickerViewModel"></app-select-date>
    </div>
    <div class="right">
        <p class="bodyContentSemi">Einddatum</p>
        <app-select-date [viewModel]="einddatumPickerViewModel"></app-select-date>
    </div>
</div>

<div class="bevestigVerwerkersOvereenkomst">
    <p class="bodyContentSemi">Akkoord</p>
    <div class="checkbox_wrapper">
        <div class="checkbox" (click)="toggleCheckbox()"><i [class]="checkboxClass()"></i></div>
        <p class="bodyContent description">Ik ga akkoord met de <span class="link" (click)="showUitleg()">voorwaarden</span> en geef bij deze akkoord tot datadeling met {{koppelpartijNaam | async}} via Somtoday Connect</p>
    </div>
</div>

<app-modal *ngIf="toonUitleg" (closeModal)="closeUitleg()">
  <div class="modal__header">
    <div class="header__icon svg_icon-info"></div>
    <p class="header__title blue bodyContentBold">Voorwaarden</p>
    <div class="close" (click)="closeUitleg()"></div>
  </div>
  <div class="modal__body">
    <p class="body__text bodyContent">Door akkoord te gaan met de voorwaarden, verklaar ik dat: </p>
    <ul>
      <li><p class="body__text bodyContent">ik bevoegd ben namens het schoolbestuur deze koppeling aan te zetten;</p></li>
      <li><p class="body__text bodyContent">ik ervan op de hoogte ben dat deze koppeling alleen aan kan worden gezet indien er een verwerkersovereenkomst bestaat tussen het bestuur van mijn onderwijsinstelling en {{koppelpartijNaam | async}};</p></li>
      <li><p class="body__text bodyContent">ik gecontroleerd heb dat de gegevens die gedeeld worden met {{koppelpartijNaam | async}} overeenkomen met de gegevens die op grond van de verwerkersovereenkomst gedeeld mogen worden.</p></li>
    </ul>
  </div>
  <div class="modal__buttons modal__buttons--no_border">
    <div class="button button--cta button--single" (click)="closeUitleg()">
      <p class="bodySmallContentSemi buttonCaption">Oké</p>
    </div>
  </div>
</app-modal>
