<app-connect-loader [loading]="loading | async">
<app-leermiddeloverzicht [bazenbannerModel]="bazenbannerModel"></app-leermiddeloverzicht>
<div class="contentbox">
  <app-controls [state]="groeperenOpVak"
                [studiewijzer]="false"
                [grayed]="!((groeperenOpVak ? selectSchooldashboardVakModel$ : selectSchooldashboardOnderwijssoortModel$) | async)[0]"
                trueLabel="Vak"
                falseLabel="Onderwijssoort"
                (groeperingChange)="onGroeperingChanged($event)">
  </app-controls>
  <app-placeholder *ngIf="!((groeperenOpVak ? selectSchooldashboardVakModel$ : selectSchooldashboardOnderwijssoortModel$) | async)[0]" [studiewijzerdb]="false"></app-placeholder>
  <div [class]="isOpen(row) ? 'row open' : 'row'" *ngFor="let row of (groeperenOpVak ? selectSchooldashboardVakModel$ : selectSchooldashboardOnderwijssoortModel$) | async">
    <div class="container" (click)="toggle(row)">
      <div class="key connect-brand">{{row.key}}<i *ngIf="isLandelijkVakGroup(row)" (click)="toonLandelijkVakHelp($event)" class="icon-vraagteken"></i></div>
      <div class="values">
        <div class="actief">{{row.actief | percent}} actieve leerlingen</div>
        <div class="frequentie">{{row.frequentie | number:'1.1-1'}} keer p/w</div>
        <div class="icon connect-brand"><i [class]="isOpen(row) ? 'icon-arrow-close' : 'icon-arrow-open'"></i></div>
      </div>
    </div>
    <div class="details" [@detailExpand]="isOpen(row) ? 'expanded' : 'collapsed'">
      <app-schooldashboard-details [row]="row" [groeperenOpVak]="groeperenOpVak" (select)="onDetailSelected($event)"></app-schooldashboard-details>
    </div>
  </div>
</div>
</app-connect-loader>

