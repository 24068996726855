import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {routerNavigatedAction} from '@ngrx/router-store';
import {catchError, filter, map, mergeMap, switchMap, take, tap, withLatestFrom} from 'rxjs/operators';
import {getDashboardPath} from '../state/router/router.selectors';
import {payload} from '../state/payload';
import {of} from 'rxjs';
import {reportError} from '../state/errors/errors.actions';
import {
    fetchOngekoppeldeLeermiddelen,
  fetchOngekoppeldeLeermiddelenRegels,
  fetchSchooldashboardOnderwijssoortDetails,
  fetchSchooldashboardVakDetails, ongekoppeldeLeermiddelenFetched, ongekoppeldeLeermiddelenRegelsFetched, resetSchooldashboard,
  setBazenbanner, setSchooldashboardOnderwijssoortDetails,
  setSchooldashboardOnderwijssoorten, setSchooldashboardVakDetails,
  setSchooldashboardVakken
} from '../state/schooldashboard/schooldashboard.actions';
import {DashboardingService} from '../services/dashboarding.service';
import {DashboardsettingsFacade} from '../state/dashboardsettings/dashboardsettings.facade';
import {SchooldashboardNewFacade} from '../state/schooldashboard/schooldashboard.facade';
import {dashboardSettingsLoaded, datumbereikChanged, vestigingChanged} from '../state/dashboardsettings/dashboardsettings.actions';
import {Router} from '@angular/router';
import {routingConstants} from '../stateless/routing.constants';
import { leermiddelStored } from '../state/leermiddelvakkoppeling/leermiddelvakkoppeling.actions';

@Injectable()
export class InvalidSchooldashboardEffect {
  $ = createEffect(() => this.actions$.pipe(
    ofType(datumbereikChanged, vestigingChanged),
    map(resetSchooldashboard)
  ));

  constructor(private actions$: Actions) {
  }
}

@Injectable()
export class SchooldashboardVakkenEffect {
  $ = createEffect(() => this.actions$.pipe(
    ofType(routerNavigatedAction, dashboardSettingsLoaded),
    filter(_ => getDashboardPath(this.router.url) === routingConstants.SCHOOL_DASHBOARD_NEW),
    withLatestFrom(this.settings.isGegroepeerdOpVak(), this.schooldashboard.hasVakken(), this.settings.areSettingsLoaded()),
    filter(([_, isGegroepeerdOpVak, hasVakken, settingsLoaded]) => isGegroepeerdOpVak && !hasVakken && settingsLoaded),
    withLatestFrom(this.settings.getBegindatumForBackend(), this.settings.getPeriodeOrDefault(), this.settings.getVestigingUUID()),
    switchMap(([_, begindatum, periode, vestigingUUID]) => this.dashboardingService.fetchSchooldashboardVakken(begindatum, periode, vestigingUUID)
      .pipe(
        map(payload),
        map(setSchooldashboardVakken),
        catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
      )),
    catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
  ));

  constructor(private actions$: Actions, private router: Router, private dashboardingService: DashboardingService, private schooldashboard: SchooldashboardNewFacade, private settings: DashboardsettingsFacade) {
  }
}

@Injectable()
export class SchooldashboardVakDetailsEffect {
  $ = createEffect(() => this.actions$.pipe(
    ofType(fetchSchooldashboardVakDetails),
    filter(action => action?.value?.naam !== null && action?.value?.naam !== undefined),
    withLatestFrom(this.settings.getBegindatumForBackend(), this.settings.getPeriodeOrDefault(), this.settings.getVestigingUUID()),
    mergeMap(([action, begindatum, periode, vestigingUUID]) => this.dashboardingService.fetchSchooldashboardVakDetails(begindatum, periode, vestigingUUID, action.value.naam)
      .pipe(
        map(vakdetails => ({vakdetails, naam: action.value.naam})),
        map(setSchooldashboardVakDetails),
        catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
      )),
    catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
  ));

  constructor(private actions$: Actions, private dashboardingService: DashboardingService, private settings: DashboardsettingsFacade) {
  }
}

@Injectable()
export class SchooldashboardOnderwijssoortenEffect {
  $ = createEffect(() => this.actions$.pipe(
    ofType(routerNavigatedAction, dashboardSettingsLoaded),
    filter(_ => getDashboardPath(this.router.url) === routingConstants.SCHOOL_DASHBOARD_NEW),
    withLatestFrom(this.settings.isGegroepeerdOpOnderwijssoort(), this.schooldashboard.hasOnderwijssoorten(), this.settings.areSettingsLoaded()),
    filter(([_, isGegroepeerdOpOnderwijssoort, hasOnderwijssoorten, settingsLoaded]) => isGegroepeerdOpOnderwijssoort && !hasOnderwijssoorten && settingsLoaded),
    withLatestFrom(this.settings.getBegindatumForBackend(), this.settings.getPeriodeOrDefault(), this.settings.getVestigingUUID()),
    switchMap(([_, begindatum, periode, vestigingUUID]) => this.dashboardingService.fetchSchooldashboardOnderwijssoorten(begindatum, periode, vestigingUUID)
      .pipe(
        map(payload),
        map(setSchooldashboardOnderwijssoorten),
        catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
      )),
    catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
  ));

  constructor(private actions$: Actions, private router: Router, private dashboardingService: DashboardingService, private schooldashboard: SchooldashboardNewFacade, private settings: DashboardsettingsFacade) {
  }
}

@Injectable()
export class SchooldashboardOnderwijssoortDetailsEffect {
  $ = createEffect(() => this.actions$.pipe(
    ofType(fetchSchooldashboardOnderwijssoortDetails),
    filter(action => action?.value?.naam !== null && action?.value?.naam !== undefined),
    withLatestFrom(this.settings.getBegindatumForBackend(), this.settings.getPeriodeOrDefault(), this.settings.getVestigingUUID()),
    mergeMap(([action, begindatum, periode, vestigingUUID]) => this.dashboardingService.fetchSchooldashboardOnderwijssoortDetails(begindatum, periode, vestigingUUID, action.value.naam)
      .pipe(
        map(onderwijssoortdetails => ({onderwijssoortdetails, naam: action.value.naam})),
        map(setSchooldashboardOnderwijssoortDetails),
        catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
      )),
    catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
  ));

  constructor(private actions$: Actions, private dashboardingService: DashboardingService, private settings: DashboardsettingsFacade) {
  }
}

@Injectable()
export class BazenbannerEffect {
  $ = createEffect(() => this.actions$.pipe(
    ofType(routerNavigatedAction, dashboardSettingsLoaded),
    filter(_ => getDashboardPath(this.router.url) === routingConstants.SCHOOL_DASHBOARD_NEW),
    withLatestFrom(this.schooldashboard.hasBazenbanner(), this.settings.areSettingsLoaded()),
    filter(([_, hasBazenbanner, areSettingsLoaded]) => !hasBazenbanner && areSettingsLoaded),
    withLatestFrom(this.settings.getBegindatumForBackend(), this.settings.getPeriodeOrDefault(), this.settings.getVestigingUUID()),
    switchMap(([_, begindatum, periode, vestigingUUID]) => this.dashboardingService.fetchBazenbanner(begindatum, periode, vestigingUUID)
      .pipe(
        map(payload),
        map(setBazenbanner),
        catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
      )),
    catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
  ));

  constructor(private actions$: Actions, private router: Router, private dashboardingService: DashboardingService, private schooldashboard: SchooldashboardNewFacade, private settings: DashboardsettingsFacade) {
  }
}

@Injectable()
export class OngekoppeldeLeermiddelenEffect {
    constructor(private actions$: Actions, private router: Router, private dashboardingService: DashboardingService, private schooldashboard: SchooldashboardNewFacade, private settings: DashboardsettingsFacade) {
    }

    onPageLoadFetchOngekoppeldeLeermiddelen = createEffect(() => this.actions$.pipe(
        ofType(routerNavigatedAction, dashboardSettingsLoaded),
        filter(_ => getDashboardPath(this.router.url) === routingConstants.SCHOOL_DASHBOARD_NEW),
        withLatestFrom(this.schooldashboard.getOngekoppeldeLeermiddelen(), this.settings.areSettingsLoaded()),
        filter(([_, ongekoppeldeLeermiddelen, settingsLoaded]) => !ongekoppeldeLeermiddelen && settingsLoaded),
        withLatestFrom(this.settings.getBegindatumForBackend(), this.settings.getPeriodeOrDefault(), this.settings.getVestigingUUID()),
        switchMap(([_, begindatum, periode, vestigingUUID]) => this.dashboardingService.fetchOngekoppeldeLeermiddelen(begindatum, periode, vestigingUUID).pipe(
            take(1),
            map((ongekoppeldeLeermiddelen) => ongekoppeldeLeermiddelenFetched({ ongekoppeldeLeermiddelen })),
            catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
        )),
        catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
    ));

    onUpdateKoppelingenFetchOngekoppeldeLeermiddelen = createEffect(() => this.actions$.pipe(
        ofType(leermiddelStored, dashboardSettingsLoaded),
        withLatestFrom(this.settings.areSettingsLoaded()),
        filter(([_, settingsLoaded]) => settingsLoaded),
        withLatestFrom(this.settings.getBegindatumForBackend(), this.settings.getPeriodeOrDefault(), this.settings.getVestigingUUID()),
        switchMap(([_, begindatum, periode, vestigingUUID]) => (
                /**
                 * Als we op de schooldashboard url zitten moet de data opnieuw geladen worden. Anders moet de data op null gezet worden,
                 * zodat de data opnieuw geladen wordt zodra we de volgende keer op het schooldashboard komen.
                 */
                getDashboardPath(this.router.url) === routingConstants.SCHOOL_DASHBOARD_NEW ? 
                this.dashboardingService.fetchOngekoppeldeLeermiddelen(begindatum, periode, vestigingUUID) : 
                of(null)
        ).pipe(
            take(1),
            map((ongekoppeldeLeermiddelen) => ongekoppeldeLeermiddelenFetched({ ongekoppeldeLeermiddelen })),
            catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
        )),
        catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
    ));

    onOpenFetchOngekoppeldeLeermiddelenRegels = createEffect(() => this.actions$.pipe(
        ofType(fetchOngekoppeldeLeermiddelenRegels),
        withLatestFrom(this.settings.areSettingsLoaded()),
        filter(([_, settingsLoaded]) => settingsLoaded),
        withLatestFrom(this.settings.getBegindatumForBackend(), this.settings.getPeriodeOrDefault(), this.settings.getVestigingUUID()),
        switchMap(([_, begindatum, periode, vestigingUUID]) => this.dashboardingService.fetchOngekoppeldeLeermiddelRegels(begindatum, periode, vestigingUUID).pipe(
            take(1),
            map((regels) => ongekoppeldeLeermiddelenRegelsFetched({ regels })),
            catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
        ))
    ));
}
