<div class="container">
  <div  #rangebar class="rangebar"
       (mousemove)="onMouseMove($event)" (mouseleave)="onMouseLeave()">
    <div class="percentages">
      <div>0%</div>
      <div>100%</div>
    </div>
    <div class="baseline">
      <div #range class="range"></div>
      <div #avg class="avg"></div>
      <div #dot class="dot"></div>
    </div>
    <div class="values">
      <div class="onderschrift">{{tooltip | async}}</div>
      <div class="gebruik">Tussen {{ minPercentage | async }} en {{ maxPercentage | async }}</div>
    </div>
  </div>
</div>
