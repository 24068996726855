import {getSelectors} from '@ngrx/router-store';
import {selectRouter} from '../app.state';
import {createSelector} from '@ngrx/store';
import {Params} from '@angular/router';
import {stringify, stringifyUrl, UrlObject} from 'query-string';
import {Payload} from '../payload';
import {encode} from '../../services/url.encode.service';

export const {
  selectCurrentRoute,   // select the current route
  selectFragment,       // select the current route fragment
  selectQueryParams,    // select the current route query params
  selectQueryParam,     // factory function to select a query param
  selectRouteParams,    // select the current route params
  selectRouteParam,     // factory function to select a route param
  selectRouteData,      // select the current route data
  selectUrl,            // select the current url
} = getSelectors(selectRouter);

const selectBooleanQueryParameter = (name: string) => createSelector(
  selectQueryParam(name),
  v => v !== 'false'
);
export const selectKoppelpartijParam = selectRouteParam('koppelpartij');
export const selectOAuthClientParam = selectRouteParam('oauthclient');
export const selectOmgevingParam = selectRouteParam('omgeving');
export const selectWebserviceParam = selectRouteParam('webservice');
export const selectVak = selectRouteParam('vak');
export const selectVakParam = selectQueryParam('vak');
export const selectOsParam = selectQueryParam('ows');
export const selectOngekoppeldeLeermiddelenParam = selectQueryParam('ongekoppelde_leermiddelen');
export const selectVestigingParam = selectQueryParam('vestiging');
export const selectVestigingenParam = selectQueryParam('vestigingen');
export const selectPeriodeParam = selectQueryParam('periode');
export const selectBegindatumParam = selectQueryParam('begindatum');
export const selectGroeperenOpVak = selectBooleanQueryParameter('groeperenOpVak');
export const selectGroeperenopUitgever = selectBooleanQueryParameter('groeperenOpUitgever');

export const getDashboardPath = (url: string) => stripSlashes((/^(\/[^\/?]+)/.exec(url) || [])[0]);

export const updateUrl = (url, queryParams, update: Payload<Params>) => {
  const params = {...queryParams, ...update.value};
  const [path] = url.split('?');
  return `${path}?${stringify(params)}`;
};

export const selectTransformFilterToPath = createSelector(
  selectUrl,
  selectRouteParams,
  selectQueryParams,
  (url: string, path: Params, query: Params, {value: fn}: Payload<(url: string, p: Params, q: Params) => UrlObject>) => {
    return stringifyUrl(fn(url, encodeEach(path), query)).replace('(', '%28').replace(')', '%29');
  }
);
export const encodeEach = (params: Params): Params => Object.entries(params)
    .map(([key, value]) => ({key, value: Array.isArray(value) ? value.map(encode) : encode(value)}))
    .reduce((object, {key, value}) => { object[key] = value; return object; }, {} as Params);

    function stripSlashes(str: string): string {
      if(str === null || str === undefined) return str;

      while(str.startsWith('/'))
        str = str.substring(1);

      while(str.endsWith('/') && str.length > 1)
        str.substring(0, str.length - 2);

      return str;
    }
