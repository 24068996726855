import {Component} from '@angular/core';
import {TableCellComponent} from '../table/table.model';
import {LeermiddelVakKoppelingInfo} from '../../../generated/graphql';

@Component({
  selector: 'app-leermiddel-uitklap-cell',
  templateUrl: './leermiddel-uitklap-cell.component.html',
  styleUrls: ['./leermiddel-uitklap-cell.component.scss']
})
export class LeermiddelUitklapCellComponent implements TableCellComponent<LeermiddelVakKoppelingInfo> {
  data: LeermiddelVakKoppelingInfo;
}
