import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {routerNavigatedAction} from '@ngrx/router-store';
import {catchError, filter, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {getDashboardPath} from '../state/router/router.selectors';
import {of} from 'rxjs';
import {reportError} from '../state/errors/errors.actions';
import {payload} from '../state/payload';
import {DashboardingService} from '../services/dashboarding.service';
import {
  fetchDocenten, fetchLeerlingen, resetKlassendashboard, setDocenten,
  setKlassendashboardAlgemeen,
  setKlassendashboardIndividueel,
  setKlassendashboardSamenvatting, setLeerlingen
} from '../state/klassendashboard/klassendashboard.actions';
import {DashboardsettingsFacade} from '../state/dashboardsettings/dashboardsettings.facade';
import {KlassendashboardNewFacade} from '../state/klassendashboard/klassendashboard.facade';
import {SomtodayService} from '../services/somtoday.service';
import {dashboardSettingsLoaded, vestigingChanged} from '../state/dashboardsettings/dashboardsettings.actions';
import {Router} from '@angular/router';
import {routingConstants} from '../stateless/routing.constants';

@Injectable()
export class InvalidKlassendashboardEffect {
  $ = createEffect(() => this.actions$.pipe(
    ofType(vestigingChanged),
    map(resetKlassendashboard)
  ));

  constructor(private actions$: Actions) {
  }
}

@Injectable()
export class KlassendashboardIndividueelEffect {
  $ = createEffect(() => this.actions$.pipe(
    ofType(routerNavigatedAction, dashboardSettingsLoaded),
    filter(_ => getDashboardPath(this.router.url) === routingConstants.KLASSEN_DASHBOARD_NEW),
    withLatestFrom(this.klassendashboard.isIndividueelGeselecteerd(), this.klassendashboard.hasIndividueelGebruik(), this.settings.areSettingsLoaded()),
    filter(([_, isIndividueelGeselecteerd, hasIndividueelGebruik, areSettingsLoaded]) => isIndividueelGeselecteerd && !hasIndividueelGebruik && areSettingsLoaded),
    withLatestFrom(
      this.settings.getBegindatumForBackend(),
      this.settings.getPeriodeOrDefault(),
      this.settings.getVestigingUUID(),
      this.klassendashboard.getLesgroepUUIDURL(),
    ),
    switchMap(([_, begindatum, periode, vestigingUUID, lesgroepUUID]) => this.dashboardingService.fetchKlassendashboardIndividueel(begindatum, periode, vestigingUUID, lesgroepUUID)
      .pipe(
        switchMap(item => ([
          setKlassendashboardAlgemeen( { lesgroepNaam: item.lesgroepNaam }),
          setKlassendashboardIndividueel({ individueelGebruik: item.individueelGebruik }),
          fetchLeerlingen()
        ])),
        catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
      )),
    catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
  ));

  constructor(private actions$: Actions, private router: Router, private dashboardingService: DashboardingService, private klassendashboard: KlassendashboardNewFacade, private settings: DashboardsettingsFacade) {
  }
}

@Injectable()
export class KlassendashboardSamenvattingEffect {
  $ = createEffect(() => this.actions$.pipe(
    ofType(routerNavigatedAction, dashboardSettingsLoaded),
    filter(_ => getDashboardPath(this.router.url) === routingConstants.KLASSEN_DASHBOARD_NEW),
    withLatestFrom(this.klassendashboard.isSamenvattingGeselecteerd(), this.klassendashboard.hasGezamenlijkGebruik(), this.settings.areSettingsLoaded()),
    filter(([_, isSamenvattingGeselecteerd, hasGezamenlijkGebruik, areSettingsLoaded]) => isSamenvattingGeselecteerd && !hasGezamenlijkGebruik && areSettingsLoaded),
    withLatestFrom(
      this.settings.getBegindatumForBackend(),
      this.settings.getPeriodeOrDefault(),
      this.settings.getVestigingUUID(),
      this.klassendashboard.getLesgroepUUIDURL(),
    ),
    switchMap(([_, begindatum, periode, vestigingUUID, lesgroepUUID]) => this.dashboardingService.fetchKlassendashboardSamenvatting(begindatum, periode, vestigingUUID, lesgroepUUID)
      .pipe(
        switchMap(item => ([
          setKlassendashboardAlgemeen( { lesgroepNaam: item.lesgroepNaam }),
          setKlassendashboardSamenvatting({ gezamenlijkGebruik: item.gezamenlijkGebruik }),
          fetchDocenten()
        ])),
        catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
      )),
    catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
  ));

  constructor(private actions$: Actions, private router: Router, private dashboardingService: DashboardingService, private klassendashboard: KlassendashboardNewFacade, private settings: DashboardsettingsFacade) {
  }
}

@Injectable()
export class KlassendashboardDocentenEffect {
  $ = createEffect(() => this.actions$.pipe(
    ofType(fetchDocenten),
    withLatestFrom(
      this.klassendashboard.getUniqueDocentUUIDs(),
    ),
    switchMap(([_, docentUUIDs]) => this.somtodayService.fetchMedewerkerNamen(docentUUIDs)
      .pipe(
        map(item => ({namen: item.map(i => i.naam)})),
        map(setDocenten),
        catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
      )),
    catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
  ));

  constructor(private actions$: Actions, private somtodayService: SomtodayService, private klassendashboard: KlassendashboardNewFacade) {
  }
}

@Injectable()
export class KlassendashboardLeerlingenEffect {
  $ = createEffect(() => this.actions$.pipe(
    ofType(fetchLeerlingen),
    withLatestFrom(
      this.klassendashboard.getUniqueLeerlingUUIDs(),
    ),
    switchMap(([_, leerlingUUIDs]) => this.somtodayService.fetchLeerlingNamen(leerlingUUIDs)
      .pipe(
        map(payload),
        map(setLeerlingen),
        catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
      )),
    catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
  ));

  constructor(private actions$: Actions, private somtodayService: SomtodayService, private klassendashboard: KlassendashboardNewFacade) {
  }
}
