import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {TerugKnopViewmodel} from '../terug-knop-new/terug-knop-new.component';
import {VakOnderwijsSwitchViewmodel} from '../vak-onderwijs-switch-new/vak-onderwijs-switch-new.component';
import {VakdashboardNewFacade} from '../../state/vakdashboard/vakdashboard.facade';
import {Observable} from 'rxjs';
import {DatepickerComponentViewModel} from '../datepicker/datepicker.component';

@Component({
  selector: 'app-controls-header',
  templateUrl: './controls-header.component.html',
  styleUrls: ['./controls-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ControlsHeaderComponent implements OnInit {

  @Input()
  viewmodel: ControlsHeaderViewModel & DatepickerComponentViewModel;

  public isNavigeerbaar$: Observable<boolean>;

  constructor(public store: VakdashboardNewFacade) {
  }

  ngOnInit(): void {
    this.isNavigeerbaar$ = this.store.isNavigeerbaar();
  }

}

export interface ControlsHeaderViewModel {
  terugKnopViewmodel: TerugKnopViewmodel;
  vakOnderwijsSwitchViewmodel: VakOnderwijsSwitchViewmodel;
}
