import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ElementRef,
  ChangeDetectorRef, OnDestroy
} from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';

@Component({
  selector: 'app-input-textarea',
  templateUrl: './input-textarea.component.html',
  styleUrls: ['./input-textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputTextareaComponent implements OnInit, OnDestroy {
  @Input()
  public viewModel: InputTextareaViewModel;

  @ViewChild('input')
  public input: ElementRef;

  public text: string;

  private subscriptions: Subscription[] = [];

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    if (this.viewModel.onGetInput !== undefined) {
      this.subscriptions.push(this.viewModel.onGetInput.subscribe(text => {this.text = (text ? text : null); this.cdr.markForCheck();}));
    }
    if (this.viewModel.onSetInput !== undefined && this.viewModel.doSetInput !== undefined) {
      this.subscriptions.push(this.viewModel.onSetInput.subscribe(_ => this.viewModel.doSetInput.next(this.text)));
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public onSave(): void {
    if(this.viewModel.doOnSave !== undefined)
      this.viewModel.doOnSave.next(this.getInputText());
  }

  public onCancel(): void {
    if(this.viewModel.doOnCancel !== undefined)
      this.viewModel.doOnCancel.next(this.getInputText());
  }

  public onBlur(): void {
    if (this.viewModel.doOnBlur !== undefined)
      this.viewModel.doOnBlur.next(this.getInputText());
  }


  private getInputText(): string {
    let inputText = this.input.nativeElement.value;
    if (inputText === '')
      inputText = null;
    return inputText;
  }

}

export interface InputTextareaViewModel {
  onGetInput?: Observable<string>;
  onSetInput?: Observable<void>;

  doSetInput?: Subject<string>;
  doOnCancel?: Subject<string>;
  doOnSave?: Subject<string>;
  doOnBlur?: Subject<string>;
}
