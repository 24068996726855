import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Long type */
  Long: any;
  /** Built-in LocalDate */
  LocalDate: any;
  /** Built-in Date */
  Date: any;
  /** Built-in UUID */
  UUID: any;
};



/** Query root type */
export type Query_Root = {
  __typename?: 'QUERY_ROOT';
  /** detailsPerPlatform */
  detailsPerPlatform?: Maybe<Array<Maybe<SwLinkjesGebruikDetails>>>;
  /** studiewijzerlinksPerVak */
  studiewijzerlinksPerVak?: Maybe<Array<Maybe<SwLinkjesPerPlatformOfVak>>>;
  /** docentenVanLesgroep */
  docentenVanLesgroep?: Maybe<Array<Maybe<MapEntry_String_Null>>>;
  /** vestigingen */
  vestigingen?: Maybe<Array<Maybe<Vestiging>>>;
  /** lesgroepschooldashboard */
  lesgroepschooldashboard?: Maybe<Array<Maybe<OpleidingVakLesgroepDagGebruik>>>;
  /** leermiddelen */
  leermiddelen?: Maybe<Array<Maybe<Leermiddel>>>;
  /** aantalActieveLeerlingenPerWeek */
  aantalActieveLeerlingenPerWeek?: Maybe<Scalars['Float']>;
  /** recentActief */
  recentActief?: Maybe<RangeModel>;
  /** lesgroepOverstijgendDagGebruik */
  lesgroepOverstijgendDagGebruik?: Maybe<Array<Maybe<LesgroepOverstijgendDagGebruik>>>;
  /** gebruiksaantallen */
  gebruiksaantallen?: Maybe<Gebruiksaantallen>;
  /** detailsPerVak */
  detailsPerVak?: Maybe<Array<Maybe<SwLinkjesGebruikDetails>>>;
  /** leerlingTotaalGebruik */
  leerlingTotaalGebruik?: Maybe<RangeModel>;
  /** vestigingVakken */
  vestigingVakken?: Maybe<Array<Maybe<VakInfo>>>;
  /** getSamenvattingData */
  getSamenvattingData?: Maybe<LesgroepDetailSamenvattingData>;
  /** getDataPerLeerling */
  getDataPerLeerling?: Maybe<LesgroepDetailDashboardData>;
  /** updateLeermiddelVakKoppeling */
  updateLeermiddelVakKoppeling?: Maybe<LeermiddelVakKoppelingInfo>;
  /** studiewijzerlinksPerPlatformVakOnderwijssoort */
  studiewijzerlinksPerPlatformVakOnderwijssoort?: Maybe<PlatformVak>;
  /** leerlingDagGebruik */
  leerlingDagGebruik?: Maybe<Array<Maybe<GebruikOpDatum>>>;
  /** vakloosschooldashboard */
  vakloosschooldashboard?: Maybe<Array<Maybe<VakloosLeermiddelOpleidingDagGebruik>>>;
  /** studiewijzerlinksPerPlatform */
  studiewijzerlinksPerPlatform?: Maybe<Array<Maybe<SwLinkjesPerPlatformOfVak>>>;
  /** methodeDagGebruik */
  methodeDagGebruik?: Maybe<Array<Maybe<MethodeGebruikVoorPeriode>>>;
  /** leermiddelVakKoppelingInfo */
  leermiddelVakKoppelingInfo?: Maybe<Array<Maybe<LeermiddelVakKoppelingInfo>>>;
  /** statistieken */
  statistieken?: Maybe<VestigingStatistieken>;
  /** aantalLesgroepenMetStudiewijzerlinks */
  aantalLesgroepenMetStudiewijzerlinks?: Maybe<Scalars['Int']>;
  /** leermiddelDashboard */
  leermiddelDashboard?: Maybe<Array<Maybe<UitgeverOfVakGebruikVoorPeriode>>>;
  /** gemActieveLeerlingenPerDag */
  gemActieveLeerlingenPerDag?: Maybe<Scalars['Float']>;
};


/** Query root type */
export type Query_RootDetailsPerPlatformArgs = {
  filter: DashboardFilterInput;
  platform: Scalars['String'];
};


/** Query root type */
export type Query_RootStudiewijzerlinksPerVakArgs = {
  filter: DashboardFilterInput;
};


/** Query root type */
export type Query_RootDocentenVanLesgroepArgs = {
  filter: DashboardFilterInput;
  lesgroepUUIDs: Array<Maybe<Scalars['String']>>;
};


/** Query root type */
export type Query_RootLesgroepschooldashboardArgs = {
  filter: DashboardFilterInput;
};


/** Query root type */
export type Query_RootAantalActieveLeerlingenPerWeekArgs = {
  arg0?: Maybe<UitgeverOfVakGebruikVoorPeriodeInput>;
};


/** Query root type */
export type Query_RootRecentActiefArgs = {
  arg0?: Maybe<MethodeGebruikVoorPeriodeInput>;
};


/** Query root type */
export type Query_RootLesgroepOverstijgendDagGebruikArgs = {
  filter: DashboardFilterInput;
};


/** Query root type */
export type Query_RootDetailsPerVakArgs = {
  filter: DashboardFilterInput;
  vak?: Maybe<Scalars['String']>;
};


/** Query root type */
export type Query_RootLeerlingTotaalGebruikArgs = {
  arg0?: Maybe<MethodeGebruikVoorPeriodeInput>;
};


/** Query root type */
export type Query_RootVestigingVakkenArgs = {
  filter: DashboardFilterInput;
};


/** Query root type */
export type Query_RootGetSamenvattingDataArgs = {
  filter?: Maybe<LesgroepDetailDashboardFilterInput>;
};


/** Query root type */
export type Query_RootGetDataPerLeerlingArgs = {
  arg0?: Maybe<LesgroepDetailDashboardFilterInput>;
};


/** Query root type */
export type Query_RootUpdateLeermiddelVakKoppelingArgs = {
  filter: DashboardFilterInput;
  leermiddelVakKoppeling: LeermiddelVakKoppelingInfoInput;
};


/** Query root type */
export type Query_RootStudiewijzerlinksPerPlatformVakOnderwijssoortArgs = {
  filter: StudiewijzerlinksFilterInput;
};


/** Query root type */
export type Query_RootLeerlingDagGebruikArgs = {
  arg0?: Maybe<MethodeGebruikVoorPeriodeInput>;
};


/** Query root type */
export type Query_RootVakloosschooldashboardArgs = {
  filter: DashboardFilterInput;
};


/** Query root type */
export type Query_RootStudiewijzerlinksPerPlatformArgs = {
  filter: DashboardFilterInput;
};


/** Query root type */
export type Query_RootMethodeDagGebruikArgs = {
  arg0?: Maybe<UitgeverOfVakGebruikVoorPeriodeInput>;
};


/** Query root type */
export type Query_RootLeermiddelVakKoppelingInfoArgs = {
  filter: DashboardFilterInput;
};


/** Query root type */
export type Query_RootStatistiekenArgs = {
  filter: DashboardFilterInput;
};


/** Query root type */
export type Query_RootAantalLesgroepenMetStudiewijzerlinksArgs = {
  filter: DashboardFilterInput;
};


/** Query root type */
export type Query_RootLeermiddelDashboardArgs = {
  filter: LeermiddelenDashboardFilterInput;
};


/** Query root type */
export type Query_RootGemActieveLeerlingenPerDagArgs = {
  filter: DashboardFilterInput;
};

export type SwLinkjesGebruikDetails = {
  __typename?: 'SWLinkjesGebruikDetails';
  /** klikratio */
  klikratio?: Maybe<Scalars['Float']>;
  /** links */
  links?: Maybe<Scalars['Long']>;
  /** naam */
  naam?: Maybe<Scalars['String']>;
};


export type DashboardFilterInput = {
  vestiging?: Maybe<Scalars['String']>;
  periode?: Maybe<Scalars['String']>;
};

export type SwLinkjesPerPlatformOfVak = {
  __typename?: 'SWLinkjesPerPlatformOfVak';
  /** klikratio */
  klikratio?: Maybe<Scalars['Float']>;
  /** links */
  links?: Maybe<Scalars['Long']>;
  /** naam */
  naam?: Maybe<Scalars['String']>;
  /** type */
  type?: Maybe<Type>;
  /** lesgroepen */
  lesgroepen?: Maybe<Scalars['Long']>;
};

export enum Type {
  Quiz = 'Quiz',
  Video = 'Video',
  Overig = 'Overig'
}

/** Map entry */
export type MapEntry_String_Null = {
  __typename?: 'mapEntry_String_null';
  /** Map key */
  key?: Maybe<Scalars['String']>;
  /** Map value */
  value?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Vestiging = {
  __typename?: 'Vestiging';
  /** dataSinds */
  dataSinds?: Maybe<Scalars['LocalDate']>;
  /** vanafdatumLicenties */
  vanafdatumLicenties?: Maybe<Scalars['LocalDate']>;
  /** vanafdatumStudiewijzers */
  vanafdatumStudiewijzers?: Maybe<Scalars['LocalDate']>;
  /** einddatumStudiewijzers */
  einddatumStudiewijzers?: Maybe<Scalars['LocalDate']>;
  /** naam */
  naam?: Maybe<Scalars['String']>;
  /** uuid */
  uuid?: Maybe<Scalars['String']>;
  /** einddatumLicenties */
  einddatumLicenties?: Maybe<Scalars['LocalDate']>;
};


export type OpleidingVakLesgroepDagGebruik = {
  __typename?: 'OpleidingVakLesgroepDagGebruik';
  /** leerlingenInLesgroep */
  leerlingenInLesgroep?: Maybe<Scalars['Int']>;
  /** lesgroepLeerjaar */
  lesgroepLeerjaar?: Maybe<Scalars['Int']>;
  /** vak */
  vak?: Maybe<Scalars['String']>;
  /** lesgroepNaam */
  lesgroepNaam?: Maybe<Scalars['String']>;
  /** daggebruik */
  daggebruik?: Maybe<Scalars['Int']>;
  /** lesgroepUuid */
  lesgroepUuid?: Maybe<Scalars['String']>;
  /** onderwijssoort */
  onderwijssoort?: Maybe<Scalars['String']>;
  /** actieveLeerlingen */
  actieveLeerlingen?: Maybe<Scalars['Int']>;
  /** dag */
  dag?: Maybe<Scalars['Date']>;
  /** leerlingenGeactiveerd */
  leerlingenGeactiveerd?: Maybe<Scalars['Int']>;
};


export type Leermiddel = {
  __typename?: 'Leermiddel';
  /** vakken */
  vakken?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** ean */
  ean?: Maybe<Scalars['String']>;
  /** methodeInformatieUUID */
  methodeInformatieUUID?: Maybe<Scalars['UUID']>;
  /** displayName */
  displayName?: Maybe<Scalars['String']>;
  /** methodeUUID */
  methodeUUID?: Maybe<Scalars['UUID']>;
  /** docentUUID */
  docentUUID?: Maybe<Scalars['String']>;
  /** uitgeverUUID */
  uitgeverUUID?: Maybe<Scalars['UUID']>;
  /** leermiddelType */
  leermiddelType?: Maybe<LeermiddelType>;
  /** uuid */
  uuid?: Maybe<Scalars['UUID']>;
  /** instellingUUID */
  instellingUUID?: Maybe<Scalars['UUID']>;
  /** url */
  url?: Maybe<Scalars['String']>;
};


export enum LeermiddelType {
  Synergy = 'Synergy',
  EduIx = 'EduIx',
  Instelling = 'Instelling',
  Docent = 'Docent',
  Studiewijzer = 'Studiewijzer'
}

export type UitgeverOfVakGebruikVoorPeriodeInput = {
  aantalActieveLeerlingenPerWeek?: Maybe<Scalars['Float']>;
  methodeDagGebruik?: Maybe<Array<Maybe<MethodeGebruikVoorPeriodeInput>>>;
  methodeGebruikVoorPeriode?: Maybe<Array<Maybe<MethodeGebruikVoorPeriodeInput>>>;
  uitgeverOfVakNaam?: Maybe<Scalars['String']>;
  percentageActieveLeerlingen?: Maybe<Scalars['Float']>;
};

export type MethodeGebruikVoorPeriodeInput = {
  activatiePercentage?: Maybe<RangeModelInput>;
  methodeNaam?: Maybe<Scalars['String']>;
  versies?: Maybe<Scalars['Int']>;
  parent?: Maybe<Scalars['String']>;
  vakOfUitgeverNaam?: Maybe<Scalars['String']>;
};

export type RangeModelInput = {
  min?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  gemiddelde?: Maybe<Scalars['Float']>;
};

export type RangeModel = {
  __typename?: 'RangeModel';
  /** gemiddelde */
  gemiddelde?: Maybe<Scalars['Float']>;
  /** min */
  min?: Maybe<Scalars['Float']>;
  /** max */
  max?: Maybe<Scalars['Float']>;
};

export type LesgroepOverstijgendDagGebruik = {
  __typename?: 'LesgroepOverstijgendDagGebruik';
  /** vak */
  vak?: Maybe<Scalars['String']>;
  /** ean */
  ean?: Maybe<Scalars['String']>;
  /** aantalLeerlingen */
  aantalLeerlingen?: Maybe<Scalars['Int']>;
  /** displayName */
  displayName?: Maybe<Scalars['String']>;
  /** aantalGeactiveerd */
  aantalGeactiveerd?: Maybe<Scalars['Int']>;
  /** dag */
  dag?: Maybe<Scalars['Date']>;
  /** leerjaar */
  leerjaar?: Maybe<Scalars['Int']>;
  /** gebruikVandaag */
  gebruikVandaag?: Maybe<Scalars['Int']>;
  /** onderwijssoortAfkorting */
  onderwijssoortAfkorting?: Maybe<Scalars['String']>;
  /** aantalActieveGebruikers */
  aantalActieveGebruikers?: Maybe<Scalars['Int']>;
};

export type Gebruiksaantallen = {
  __typename?: 'Gebruiksaantallen';
  /** aantallenPerOpleiding */
  aantallenPerOpleiding?: Maybe<Array<Maybe<OpleidingAantallen>>>;
};

export type OpleidingAantallen = {
  __typename?: 'OpleidingAantallen';
  /** naam */
  naam?: Maybe<Scalars['String']>;
  /** aantallenPerLeermiddel */
  aantallenPerLeermiddel?: Maybe<Array<Maybe<LeermiddelAantallen>>>;
};

export type LeermiddelAantallen = {
  __typename?: 'LeermiddelAantallen';
  /** actiefGebruikt */
  actiefGebruikt?: Maybe<Scalars['Int']>;
  /** naam */
  naam?: Maybe<Scalars['String']>;
  /** geactiveerd */
  geactiveerd?: Maybe<Scalars['Int']>;
  /** aantalLicenties */
  aantalLicenties?: Maybe<Scalars['Int']>;
};

export type VakInfo = {
  __typename?: 'VakInfo';
  /** afkorting */
  afkorting?: Maybe<Scalars['String']>;
  /** id */
  id?: Maybe<Scalars['Long']>;
  /** naam */
  naam?: Maybe<Scalars['String']>;
};

export type LesgroepDetailSamenvattingData = {
  __typename?: 'LesgroepDetailSamenvattingData';
  /** grafiekDetails */
  grafiekDetails?: Maybe<Array<Maybe<LeermiddelGebruik>>>;
  /** lesgroepNaam */
  lesgroepNaam?: Maybe<Scalars['String']>;
  /** detailsPerLeermiddel */
  detailsPerLeermiddel?: Maybe<Array<Maybe<LesgroepLeermiddelGebruikDetails>>>;
  /** docentUUIDs */
  docentUUIDs?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** aantalLeerlingen */
  aantalLeerlingen?: Maybe<Scalars['Int']>;
};

export type LeermiddelGebruik = {
  __typename?: 'LeermiddelGebruik';
  /** datum */
  datum?: Maybe<Scalars['Date']>;
  /** leermiddelnaam */
  leermiddelnaam?: Maybe<Scalars['String']>;
  /** gebruiksPercentage */
  gebruiksPercentage?: Maybe<Scalars['Float']>;
};

export type LesgroepLeermiddelGebruikDetails = {
  __typename?: 'LesgroepLeermiddelGebruikDetails';
  /** activatiePercentage */
  activatiePercentage?: Maybe<Scalars['Float']>;
  /** totaalGebruikPerWeek */
  totaalGebruikPerWeek?: Maybe<Scalars['Float']>;
  /** leermiddelNaam */
  leermiddelNaam?: Maybe<Scalars['String']>;
  /** totaalActivatiePercentage */
  totaalActivatiePercentage?: Maybe<Scalars['Float']>;
  /** gebruikPerWeek */
  gebruikPerWeek?: Maybe<Scalars['Float']>;
  /** totaalWaarvanRecentGebruiktPercentage */
  totaalWaarvanRecentGebruiktPercentage?: Maybe<Scalars['Float']>;
  /** waarvanRecentGebruiktPercentage */
  waarvanRecentGebruiktPercentage?: Maybe<Scalars['Float']>;
};

export type LesgroepDetailDashboardFilterInput = {
  lesgroepUUID?: Maybe<Scalars['String']>;
  vanaf?: Maybe<Scalars['String']>;
  totEnMet?: Maybe<Scalars['String']>;
};

export type LesgroepDetailDashboardData = {
  __typename?: 'LesgroepDetailDashboardData';
  /** lesgroepLeerjaar */
  lesgroepLeerjaar?: Maybe<Scalars['Int']>;
  /** lesgroepUUID */
  lesgroepUUID?: Maybe<Scalars['String']>;
  /** lesgroepNaam */
  lesgroepNaam?: Maybe<Scalars['String']>;
  /** gebruikPerLeerling */
  gebruikPerLeerling?: Maybe<Array<Maybe<LeerlingLeermiddelGebruik>>>;
  /** collegaLesgroepen */
  collegaLesgroepen?: Maybe<Array<Maybe<CollegaLesgroep>>>;
};

export type LeerlingLeermiddelGebruik = {
  __typename?: 'LeerlingLeermiddelGebruik';
  /** gebruikDezePeriode */
  gebruikDezePeriode?: Maybe<Scalars['Float']>;
  /** leerlingIdentifier */
  leerlingIdentifier?: Maybe<Scalars['String']>;
  /** gebruikVorigePeriode */
  gebruikVorigePeriode?: Maybe<Scalars['Float']>;
  /** datumLaatsteGebruik */
  datumLaatsteGebruik?: Maybe<Scalars['Date']>;
  /** leermiddelIdentifier */
  leermiddelIdentifier?: Maybe<Scalars['String']>;
};

export type CollegaLesgroep = {
  __typename?: 'CollegaLesgroep';
  /** lesgroepUUID */
  lesgroepUUID?: Maybe<Scalars['String']>;
  /** lesgroepNaam */
  lesgroepNaam?: Maybe<Scalars['String']>;
  /** leerjaar */
  leerjaar?: Maybe<Scalars['Int']>;
};

export type LeermiddelVakKoppelingInfo = {
  __typename?: 'LeermiddelVakKoppelingInfo';
  /** vakken */
  vakken?: Maybe<Array<Maybe<VakInfo>>>;
  /** titel */
  titel?: Maybe<Scalars['String']>;
  /** ean */
  ean?: Maybe<Scalars['String']>;
  /** genegeerd */
  genegeerd?: Maybe<Scalars['Boolean']>;
  /** aantalLeerlingen */
  aantalLeerlingen?: Maybe<Scalars['Int']>;
  /** ids */
  ids?: Maybe<Scalars['String']>;
  /** vakloosLeermiddel */
  vakloosLeermiddel?: Maybe<Scalars['Boolean']>;
};

export type LeermiddelVakKoppelingInfoInput = {
  titel?: Maybe<Scalars['String']>;
  vakloosLeermiddel?: Maybe<Scalars['Boolean']>;
  ids?: Maybe<Scalars['String']>;
  aantalLeerlingen?: Maybe<Scalars['Int']>;
  ean?: Maybe<Scalars['String']>;
  genegeerd?: Maybe<Scalars['Boolean']>;
  vakken?: Maybe<Array<Maybe<VakInfoInput>>>;
};

export type VakInfoInput = {
  naam?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Long']>;
  afkorting?: Maybe<Scalars['String']>;
};

export type PlatformVak = {
  __typename?: 'PlatformVak';
  /** vaknaam */
  vaknaam?: Maybe<Scalars['String']>;
  /** getallenPerOnderwijssoort */
  getallenPerOnderwijssoort?: Maybe<Array<Maybe<PlatformVakOnderwijssoort>>>;
  /** naam */
  naam?: Maybe<Scalars['String']>;
};

export type PlatformVakOnderwijssoort = {
  __typename?: 'PlatformVakOnderwijssoort';
  /** onderwijssoort */
  onderwijssoort?: Maybe<Scalars['String']>;
  /** klikratio */
  klikratio?: Maybe<Scalars['Float']>;
  /** aantalLinks */
  aantalLinks?: Maybe<Scalars['Int']>;
  /** leerjaar */
  leerjaar?: Maybe<Scalars['Int']>;
  /** aantalLesgroepen */
  aantalLesgroepen?: Maybe<Scalars['Int']>;
};

export type StudiewijzerlinksFilterInput = {
  vaknaam?: Maybe<Scalars['String']>;
  vanaf?: Maybe<Scalars['String']>;
  platformnaam?: Maybe<Scalars['String']>;
  vestiging?: Maybe<Scalars['String']>;
  totenmet?: Maybe<Scalars['String']>;
};

export type GebruikOpDatum = {
  __typename?: 'GebruikOpDatum';
  /** waardes */
  waardes?: Maybe<RangeModel>;
  /** date */
  date?: Maybe<Scalars['LocalDate']>;
};

export type VakloosLeermiddelOpleidingDagGebruik = {
  __typename?: 'VakloosLeermiddelOpleidingDagGebruik';
  /** daggebruik */
  daggebruik?: Maybe<Scalars['Int']>;
  /** titel */
  titel?: Maybe<Scalars['String']>;
  /** ean */
  ean?: Maybe<Scalars['String']>;
  /** onderwijssoort */
  onderwijssoort?: Maybe<Scalars['String']>;
  /** leerlingen */
  leerlingen?: Maybe<Scalars['Int']>;
  /** actieveLeerlingen */
  actieveLeerlingen?: Maybe<Scalars['Int']>;
  /** dag */
  dag?: Maybe<Scalars['Date']>;
  /** leerjaar */
  leerjaar?: Maybe<Scalars['Int']>;
  /** leerlingenGeactiveerd */
  leerlingenGeactiveerd?: Maybe<Scalars['Int']>;
};

export type MethodeGebruikVoorPeriode = {
  __typename?: 'MethodeGebruikVoorPeriode';
  /** activatiePercentage */
  activatiePercentage?: Maybe<RangeModel>;
  /** methodeNaam */
  methodeNaam?: Maybe<Scalars['String']>;
  /** parent */
  parent?: Maybe<Scalars['String']>;
  /** versies */
  versies?: Maybe<Scalars['Int']>;
  /** recentActief */
  recentActief?: Maybe<RangeModel>;
  /** leerlingDagGebruik */
  leerlingDagGebruik?: Maybe<Array<Maybe<GebruikOpDatum>>>;
  /** vakOfUitgeverNaam */
  vakOfUitgeverNaam?: Maybe<Scalars['String']>;
  /** leerlingTotaalGebruik */
  leerlingTotaalGebruik?: Maybe<RangeModel>;
};


export type MethodeGebruikVoorPeriodeRecentActiefArgs = {
  arg0?: Maybe<MethodeGebruikVoorPeriodeInput>;
};


export type MethodeGebruikVoorPeriodeLeerlingDagGebruikArgs = {
  arg0?: Maybe<MethodeGebruikVoorPeriodeInput>;
};


export type MethodeGebruikVoorPeriodeLeerlingTotaalGebruikArgs = {
  arg0?: Maybe<MethodeGebruikVoorPeriodeInput>;
};

export type VestigingStatistieken = {
  __typename?: 'VestigingStatistieken';
  /** educatieveAanbieders */
  educatieveAanbieders?: Maybe<Scalars['Int']>;
  /** licentiesPerLeerling */
  licentiesPerLeerling?: Maybe<Scalars['Float']>;
  /** aantalGeactiveerdeLicenties */
  aantalGeactiveerdeLicenties?: Maybe<Scalars['Float']>;
  /** percentageLesgroepenMetInzetBoven70pct */
  percentageLesgroepenMetInzetBoven70pct?: Maybe<Scalars['Float']>;
};

export type UitgeverOfVakGebruikVoorPeriode = {
  __typename?: 'UitgeverOfVakGebruikVoorPeriode';
  /** aantalActieveLeerlingenPerWeek */
  aantalActieveLeerlingenPerWeek?: Maybe<Scalars['Float']>;
  /** methodeGebruikVoorPeriode */
  methodeGebruikVoorPeriode?: Maybe<Array<Maybe<MethodeGebruikVoorPeriode>>>;
  /** percentageActieveLeerlingen */
  percentageActieveLeerlingen?: Maybe<Scalars['Float']>;
  /** uitgeverOfVakNaam */
  uitgeverOfVakNaam?: Maybe<Scalars['String']>;
  /** methodeDagGebruik */
  methodeDagGebruik?: Maybe<Array<Maybe<MethodeGebruikVoorPeriode>>>;
};


export type UitgeverOfVakGebruikVoorPeriodeAantalActieveLeerlingenPerWeekArgs = {
  arg0?: Maybe<UitgeverOfVakGebruikVoorPeriodeInput>;
};


export type UitgeverOfVakGebruikVoorPeriodeMethodeDagGebruikArgs = {
  arg0?: Maybe<UitgeverOfVakGebruikVoorPeriodeInput>;
};

export type LeermiddelenDashboardFilterInput = {
  periode?: Maybe<PeriodeInput>;
  vestiging?: Maybe<Scalars['String']>;
  groepeerOpUitgever?: Maybe<Scalars['Boolean']>;
};

export enum PeriodeInput {
  Week = 'WEEK',
  Maand = 'MAAND',
  Periode = 'PERIODE',
  Schooljaar = 'SCHOOLJAAR',
  Vorigschooljaar = 'VORIGSCHOOLJAAR'
}

/** Mutation root type */
export type Mutation_Root = {
  __typename?: 'MUTATION_ROOT';
  /** sendFeedback */
  sendFeedback?: Maybe<GraphQlSurveyMonkeyFeedbackSubmissionResponse>;
};


/** Mutation root type */
export type Mutation_RootSendFeedbackArgs = {
  submission?: Maybe<GraphQlSurveyMonkeyFeedbackSubmissionInput>;
};

export type GraphQlSurveyMonkeyFeedbackSubmissionResponse = {
  __typename?: 'GraphQLSurveyMonkeyFeedbackSubmissionResponse';
  /** success */
  success?: Maybe<Scalars['Boolean']>;
  /** hint */
  hint?: Maybe<Scalars['String']>;
};

export type GraphQlSurveyMonkeyFeedbackSubmissionInput = {
  bericht?: Maybe<Scalars['String']>;
  naam?: Maybe<Scalars['String']>;
  rollen?: Maybe<Array<Maybe<Scalars['String']>>>;
  inleverDatumTijd?: Maybe<Scalars['String']>;
  schermresolutie?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
  versie?: Maybe<Scalars['String']>;
  pagina?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
};

export type AlleVestigingenVanIngelogdeGebruikerQueryVariables = Exact<{ [key: string]: never; }>;


export type AlleVestigingenVanIngelogdeGebruikerQuery = (
  { __typename?: 'QUERY_ROOT' }
  & { vestigingen?: Maybe<Array<Maybe<(
    { __typename?: 'Vestiging' }
    & Pick<Vestiging, 'naam' | 'uuid' | 'dataSinds' | 'vanafdatumLicenties' | 'einddatumLicenties' | 'vanafdatumStudiewijzers' | 'einddatumStudiewijzers'>
  )>>> }
);

export type DocentenVanLesgroepQueryVariables = Exact<{
  filter: DashboardFilterInput;
  lesgroepUUIDs: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
}>;


export type DocentenVanLesgroepQuery = (
  { __typename?: 'QUERY_ROOT' }
  & { docentenVanLesgroep?: Maybe<Array<Maybe<(
    { __typename?: 'mapEntry_String_null' }
    & Pick<MapEntry_String_Null, 'key' | 'value'>
  )>>> }
);

export type LeermiddelVakKoppelingInfoQueryVariables = Exact<{
  filter: DashboardFilterInput;
}>;


export type LeermiddelVakKoppelingInfoQuery = (
  { __typename?: 'QUERY_ROOT' }
  & { leermiddelVakKoppelingInfo?: Maybe<Array<Maybe<(
    { __typename?: 'LeermiddelVakKoppelingInfo' }
    & Pick<LeermiddelVakKoppelingInfo, 'titel' | 'ean' | 'ids' | 'aantalLeerlingen' | 'genegeerd' | 'vakloosLeermiddel'>
    & { vakken?: Maybe<Array<Maybe<(
      { __typename?: 'VakInfo' }
      & Pick<VakInfo, 'id' | 'naam' | 'afkorting'>
    )>>> }
  )>>>, vestigingVakken?: Maybe<Array<Maybe<(
    { __typename?: 'VakInfo' }
    & Pick<VakInfo, 'id' | 'naam' | 'afkorting'>
  )>>> }
);

export type LeermiddelVakKoppelingUpdateQueryVariables = Exact<{
  filter: DashboardFilterInput;
  leermiddelVakKoppeling: LeermiddelVakKoppelingInfoInput;
}>;


export type LeermiddelVakKoppelingUpdateQuery = (
  { __typename?: 'QUERY_ROOT' }
  & { updateLeermiddelVakKoppeling?: Maybe<(
    { __typename?: 'LeermiddelVakKoppelingInfo' }
    & Pick<LeermiddelVakKoppelingInfo, 'titel' | 'ean' | 'ids' | 'aantalLeerlingen' | 'genegeerd' | 'vakloosLeermiddel'>
    & { vakken?: Maybe<Array<Maybe<(
      { __typename?: 'VakInfo' }
      & Pick<VakInfo, 'id' | 'naam' | 'afkorting'>
    )>>> }
  )> }
);

export type LeermiddelenDashboardQueryVariables = Exact<{
  filter: LeermiddelenDashboardFilterInput;
}>;


export type LeermiddelenDashboardQuery = (
  { __typename?: 'QUERY_ROOT' }
  & { vestigingen?: Maybe<Array<Maybe<(
    { __typename?: 'Vestiging' }
    & Pick<Vestiging, 'naam' | 'uuid' | 'dataSinds' | 'vanafdatumLicenties' | 'einddatumLicenties' | 'vanafdatumStudiewijzers' | 'einddatumStudiewijzers'>
  )>>>, leermiddelDashboard?: Maybe<Array<Maybe<(
    { __typename?: 'UitgeverOfVakGebruikVoorPeriode' }
    & Pick<UitgeverOfVakGebruikVoorPeriode, 'uitgeverOfVakNaam' | 'percentageActieveLeerlingen' | 'aantalActieveLeerlingenPerWeek'>
    & { methodeDagGebruik?: Maybe<Array<Maybe<(
      { __typename?: 'MethodeGebruikVoorPeriode' }
      & Pick<MethodeGebruikVoorPeriode, 'methodeNaam' | 'vakOfUitgeverNaam' | 'versies'>
      & { activatiePercentage?: Maybe<(
        { __typename?: 'RangeModel' }
        & Pick<RangeModel, 'min' | 'max' | 'gemiddelde'>
      )>, recentActief?: Maybe<(
        { __typename?: 'RangeModel' }
        & Pick<RangeModel, 'min' | 'max' | 'gemiddelde'>
      )>, leerlingDagGebruik?: Maybe<Array<Maybe<(
        { __typename?: 'GebruikOpDatum' }
        & Pick<GebruikOpDatum, 'date'>
        & { waardes?: Maybe<(
          { __typename?: 'RangeModel' }
          & Pick<RangeModel, 'min' | 'max' | 'gemiddelde'>
        )> }
      )>>>, leerlingTotaalGebruik?: Maybe<(
        { __typename?: 'RangeModel' }
        & Pick<RangeModel, 'min' | 'max' | 'gemiddelde'>
      )> }
    )>>> }
  )>>> }
);

export type LesgroepDetailSamenvattingDashboardQueryVariables = Exact<{
  filter: LesgroepDetailDashboardFilterInput;
}>;


export type LesgroepDetailSamenvattingDashboardQuery = (
  { __typename?: 'QUERY_ROOT' }
  & { vestigingen?: Maybe<Array<Maybe<(
    { __typename?: 'Vestiging' }
    & Pick<Vestiging, 'naam' | 'uuid' | 'dataSinds' | 'vanafdatumLicenties' | 'einddatumLicenties' | 'vanafdatumStudiewijzers' | 'einddatumStudiewijzers'>
  )>>>, getSamenvattingData?: Maybe<(
    { __typename?: 'LesgroepDetailSamenvattingData' }
    & Pick<LesgroepDetailSamenvattingData, 'lesgroepNaam' | 'aantalLeerlingen' | 'docentUUIDs'>
    & { grafiekDetails?: Maybe<Array<Maybe<(
      { __typename?: 'LeermiddelGebruik' }
      & Pick<LeermiddelGebruik, 'datum' | 'leermiddelnaam' | 'gebruiksPercentage'>
    )>>>, detailsPerLeermiddel?: Maybe<Array<Maybe<(
      { __typename?: 'LesgroepLeermiddelGebruikDetails' }
      & Pick<LesgroepLeermiddelGebruikDetails, 'activatiePercentage' | 'totaalGebruikPerWeek' | 'leermiddelNaam' | 'totaalActivatiePercentage' | 'gebruikPerWeek' | 'totaalWaarvanRecentGebruiktPercentage' | 'waarvanRecentGebruiktPercentage'>
    )>>> }
  )> }
);

export type LesgroepDetaildashboardQueryVariables = Exact<{
  filter: LesgroepDetailDashboardFilterInput;
}>;


export type LesgroepDetaildashboardQuery = (
  { __typename?: 'QUERY_ROOT' }
  & { vestigingen?: Maybe<Array<Maybe<(
    { __typename?: 'Vestiging' }
    & Pick<Vestiging, 'naam' | 'uuid' | 'dataSinds' | 'vanafdatumLicenties' | 'einddatumLicenties' | 'vanafdatumStudiewijzers' | 'einddatumStudiewijzers'>
  )>>>, getDataPerLeerling?: Maybe<(
    { __typename?: 'LesgroepDetailDashboardData' }
    & Pick<LesgroepDetailDashboardData, 'lesgroepNaam' | 'lesgroepUUID' | 'lesgroepLeerjaar'>
    & { collegaLesgroepen?: Maybe<Array<Maybe<(
      { __typename?: 'CollegaLesgroep' }
      & Pick<CollegaLesgroep, 'lesgroepNaam' | 'lesgroepUUID'>
    )>>>, gebruikPerLeerling?: Maybe<Array<Maybe<(
      { __typename?: 'LeerlingLeermiddelGebruik' }
      & Pick<LeerlingLeermiddelGebruik, 'leerlingIdentifier' | 'leermiddelIdentifier' | 'gebruikDezePeriode' | 'gebruikVorigePeriode' | 'datumLaatsteGebruik'>
    )>>> }
  )> }
);

export type LesgroepDetaildashboardMetSamenvattingQueryVariables = Exact<{
  filter: LesgroepDetailDashboardFilterInput;
}>;


export type LesgroepDetaildashboardMetSamenvattingQuery = (
  { __typename?: 'QUERY_ROOT' }
  & { vestigingen?: Maybe<Array<Maybe<(
    { __typename?: 'Vestiging' }
    & Pick<Vestiging, 'naam' | 'uuid' | 'dataSinds' | 'vanafdatumLicenties' | 'einddatumLicenties' | 'vanafdatumStudiewijzers' | 'einddatumStudiewijzers'>
  )>>>, getDataPerLeerling?: Maybe<(
    { __typename?: 'LesgroepDetailDashboardData' }
    & Pick<LesgroepDetailDashboardData, 'lesgroepNaam' | 'lesgroepUUID' | 'lesgroepLeerjaar'>
    & { collegaLesgroepen?: Maybe<Array<Maybe<(
      { __typename?: 'CollegaLesgroep' }
      & Pick<CollegaLesgroep, 'lesgroepNaam' | 'lesgroepUUID'>
    )>>>, gebruikPerLeerling?: Maybe<Array<Maybe<(
      { __typename?: 'LeerlingLeermiddelGebruik' }
      & Pick<LeerlingLeermiddelGebruik, 'leerlingIdentifier' | 'leermiddelIdentifier' | 'gebruikDezePeriode' | 'gebruikVorigePeriode' | 'datumLaatsteGebruik'>
    )>>> }
  )>, getSamenvattingData?: Maybe<(
    { __typename?: 'LesgroepDetailSamenvattingData' }
    & Pick<LesgroepDetailSamenvattingData, 'lesgroepNaam' | 'aantalLeerlingen' | 'docentUUIDs'>
    & { grafiekDetails?: Maybe<Array<Maybe<(
      { __typename?: 'LeermiddelGebruik' }
      & Pick<LeermiddelGebruik, 'datum' | 'leermiddelnaam' | 'gebruiksPercentage'>
    )>>>, detailsPerLeermiddel?: Maybe<Array<Maybe<(
      { __typename?: 'LesgroepLeermiddelGebruikDetails' }
      & Pick<LesgroepLeermiddelGebruikDetails, 'activatiePercentage' | 'totaalGebruikPerWeek' | 'leermiddelNaam' | 'totaalActivatiePercentage' | 'gebruikPerWeek' | 'totaalWaarvanRecentGebruiktPercentage' | 'waarvanRecentGebruiktPercentage'>
    )>>> }
  )> }
);

export type LesgroepSchooldashboardQueryVariables = Exact<{
  filter: DashboardFilterInput;
}>;


export type LesgroepSchooldashboardQuery = (
  { __typename?: 'QUERY_ROOT' }
  & Pick<Query_Root, 'gemActieveLeerlingenPerDag'>
  & { vestigingen?: Maybe<Array<Maybe<(
    { __typename?: 'Vestiging' }
    & Pick<Vestiging, 'naam' | 'uuid' | 'dataSinds' | 'vanafdatumLicenties' | 'einddatumLicenties' | 'vanafdatumStudiewijzers' | 'einddatumStudiewijzers'>
  )>>>, lesgroepschooldashboard?: Maybe<Array<Maybe<(
    { __typename?: 'OpleidingVakLesgroepDagGebruik' }
    & Pick<OpleidingVakLesgroepDagGebruik, 'onderwijssoort' | 'vak' | 'lesgroepNaam' | 'lesgroepUuid' | 'lesgroepLeerjaar' | 'leerlingenGeactiveerd' | 'actieveLeerlingen' | 'leerlingenInLesgroep' | 'dag' | 'daggebruik'>
  )>>>, vakloosschooldashboard?: Maybe<Array<Maybe<(
    { __typename?: 'VakloosLeermiddelOpleidingDagGebruik' }
    & Pick<VakloosLeermiddelOpleidingDagGebruik, 'titel' | 'ean' | 'onderwijssoort' | 'leerjaar' | 'actieveLeerlingen' | 'leerlingenGeactiveerd' | 'leerlingen' | 'dag' | 'daggebruik'>
  )>>>, lesgroepOverstijgendDagGebruik?: Maybe<Array<Maybe<(
    { __typename?: 'LesgroepOverstijgendDagGebruik' }
    & Pick<LesgroepOverstijgendDagGebruik, 'displayName' | 'ean' | 'onderwijssoortAfkorting' | 'leerjaar' | 'vak' | 'aantalLeerlingen' | 'aantalGeactiveerd' | 'aantalActieveGebruikers' | 'dag' | 'gebruikVandaag'>
  )>>>, statistieken?: Maybe<(
    { __typename?: 'VestigingStatistieken' }
    & Pick<VestigingStatistieken, 'percentageLesgroepenMetInzetBoven70pct' | 'licentiesPerLeerling' | 'educatieveAanbieders' | 'aantalGeactiveerdeLicenties'>
  )> }
);

export type SwDetailsPerPlatformQueryVariables = Exact<{
  filter: DashboardFilterInput;
  platform: Scalars['String'];
}>;


export type SwDetailsPerPlatformQuery = (
  { __typename?: 'QUERY_ROOT' }
  & { detailsPerPlatform?: Maybe<Array<Maybe<(
    { __typename?: 'SWLinkjesGebruikDetails' }
    & Pick<SwLinkjesGebruikDetails, 'naam' | 'links' | 'klikratio'>
  )>>> }
);

export type SwDetailsPerVakQueryVariables = Exact<{
  filter: DashboardFilterInput;
  vak?: Maybe<Scalars['String']>;
}>;


export type SwDetailsPerVakQuery = (
  { __typename?: 'QUERY_ROOT' }
  & { detailsPerVak?: Maybe<Array<Maybe<(
    { __typename?: 'SWLinkjesGebruikDetails' }
    & Pick<SwLinkjesGebruikDetails, 'naam' | 'links' | 'klikratio'>
  )>>> }
);

export type StudiewijzerlinksPerPlatformQueryVariables = Exact<{
  filter: DashboardFilterInput;
}>;


export type StudiewijzerlinksPerPlatformQuery = (
  { __typename?: 'QUERY_ROOT' }
  & Pick<Query_Root, 'aantalLesgroepenMetStudiewijzerlinks'>
  & { studiewijzerlinksPerPlatform?: Maybe<Array<Maybe<(
    { __typename?: 'SWLinkjesPerPlatformOfVak' }
    & Pick<SwLinkjesPerPlatformOfVak, 'naam' | 'links' | 'klikratio' | 'lesgroepen' | 'type'>
  )>>>, vestigingen?: Maybe<Array<Maybe<(
    { __typename?: 'Vestiging' }
    & Pick<Vestiging, 'naam' | 'uuid' | 'dataSinds' | 'vanafdatumLicenties' | 'einddatumLicenties' | 'vanafdatumStudiewijzers' | 'einddatumStudiewijzers'>
  )>>> }
);

export type StudiewijzerlinksPerVakQueryVariables = Exact<{
  filter: DashboardFilterInput;
}>;


export type StudiewijzerlinksPerVakQuery = (
  { __typename?: 'QUERY_ROOT' }
  & Pick<Query_Root, 'aantalLesgroepenMetStudiewijzerlinks'>
  & { studiewijzerlinksPerVak?: Maybe<Array<Maybe<(
    { __typename?: 'SWLinkjesPerPlatformOfVak' }
    & Pick<SwLinkjesPerPlatformOfVak, 'naam' | 'links' | 'klikratio' | 'lesgroepen' | 'type'>
  )>>>, vestigingen?: Maybe<Array<Maybe<(
    { __typename?: 'Vestiging' }
    & Pick<Vestiging, 'naam' | 'uuid' | 'dataSinds' | 'vanafdatumLicenties' | 'einddatumLicenties' | 'vanafdatumStudiewijzers' | 'einddatumStudiewijzers'>
  )>>> }
);

export type StudiewijzerPlatformVakQueryVariables = Exact<{
  filter: StudiewijzerlinksFilterInput;
}>;


export type StudiewijzerPlatformVakQuery = (
  { __typename?: 'QUERY_ROOT' }
  & { studiewijzerlinksPerPlatformVakOnderwijssoort?: Maybe<(
    { __typename?: 'PlatformVak' }
    & Pick<PlatformVak, 'naam' | 'vaknaam'>
    & { getallenPerOnderwijssoort?: Maybe<Array<Maybe<(
      { __typename?: 'PlatformVakOnderwijssoort' }
      & Pick<PlatformVakOnderwijssoort, 'onderwijssoort' | 'leerjaar' | 'aantalLinks' | 'klikratio' | 'aantalLesgroepen'>
    )>>> }
  )> }
);

export type SubmitSurveyMonkeyFeedbackMutationVariables = Exact<{
  arg1: GraphQlSurveyMonkeyFeedbackSubmissionInput;
}>;


export type SubmitSurveyMonkeyFeedbackMutation = (
  { __typename?: 'MUTATION_ROOT' }
  & { sendFeedback?: Maybe<(
    { __typename?: 'GraphQLSurveyMonkeyFeedbackSubmissionResponse' }
    & Pick<GraphQlSurveyMonkeyFeedbackSubmissionResponse, 'success' | 'hint'>
  )> }
);

export const AlleVestigingenVanIngelogdeGebruikerDocument = gql`
    query AlleVestigingenVanIngelogdeGebruiker {
  vestigingen {
    naam
    uuid
    dataSinds
    vanafdatumLicenties
    einddatumLicenties
    vanafdatumStudiewijzers
    einddatumStudiewijzers
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AlleVestigingenVanIngelogdeGebruikerGQL extends Apollo.Query<AlleVestigingenVanIngelogdeGebruikerQuery, AlleVestigingenVanIngelogdeGebruikerQueryVariables> {
    document = AlleVestigingenVanIngelogdeGebruikerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DocentenVanLesgroepDocument = gql`
    query DocentenVanLesgroep($filter: DashboardFilterInput!, $lesgroepUUIDs: [String]!) {
  docentenVanLesgroep(filter: $filter, lesgroepUUIDs: $lesgroepUUIDs) {
    key
    value
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DocentenVanLesgroepGQL extends Apollo.Query<DocentenVanLesgroepQuery, DocentenVanLesgroepQueryVariables> {
    document = DocentenVanLesgroepDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LeermiddelVakKoppelingInfoDocument = gql`
    query LeermiddelVakKoppelingInfo($filter: DashboardFilterInput!) {
  leermiddelVakKoppelingInfo(filter: $filter) {
    titel
    ean
    ids
    aantalLeerlingen
    genegeerd
    vakloosLeermiddel
    vakken {
      id
      naam
      afkorting
    }
  }
  vestigingVakken(filter: $filter) {
    id
    naam
    afkorting
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LeermiddelVakKoppelingInfoGQL extends Apollo.Query<LeermiddelVakKoppelingInfoQuery, LeermiddelVakKoppelingInfoQueryVariables> {
    document = LeermiddelVakKoppelingInfoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LeermiddelVakKoppelingUpdateDocument = gql`
    query LeermiddelVakKoppelingUpdate($filter: DashboardFilterInput!, $leermiddelVakKoppeling: LeermiddelVakKoppelingInfoInput!) {
  updateLeermiddelVakKoppeling(
    filter: $filter
    leermiddelVakKoppeling: $leermiddelVakKoppeling
  ) {
    titel
    ean
    ids
    aantalLeerlingen
    genegeerd
    vakloosLeermiddel
    vakken {
      id
      naam
      afkorting
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LeermiddelVakKoppelingUpdateGQL extends Apollo.Query<LeermiddelVakKoppelingUpdateQuery, LeermiddelVakKoppelingUpdateQueryVariables> {
    document = LeermiddelVakKoppelingUpdateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LeermiddelenDashboardDocument = gql`
    query LeermiddelenDashboard($filter: LeermiddelenDashboardFilterInput!) {
  vestigingen {
    naam
    uuid
    dataSinds
    vanafdatumLicenties
    einddatumLicenties
    vanafdatumStudiewijzers
    einddatumStudiewijzers
  }
  leermiddelDashboard(filter: $filter) {
    uitgeverOfVakNaam
    percentageActieveLeerlingen
    aantalActieveLeerlingenPerWeek
    methodeDagGebruik {
      methodeNaam
      vakOfUitgeverNaam
      versies
      activatiePercentage {
        min
        max
        gemiddelde
      }
      recentActief {
        min
        max
        gemiddelde
      }
      leerlingDagGebruik {
        date
        waardes {
          min
          max
          gemiddelde
        }
      }
      leerlingTotaalGebruik {
        min
        max
        gemiddelde
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LeermiddelenDashboardGQL extends Apollo.Query<LeermiddelenDashboardQuery, LeermiddelenDashboardQueryVariables> {
    document = LeermiddelenDashboardDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LesgroepDetailSamenvattingDashboardDocument = gql`
    query LesgroepDetailSamenvattingDashboard($filter: LesgroepDetailDashboardFilterInput!) {
  vestigingen {
    naam
    uuid
    dataSinds
    vanafdatumLicenties
    einddatumLicenties
    vanafdatumStudiewijzers
    einddatumStudiewijzers
  }
  getSamenvattingData(filter: $filter) {
    lesgroepNaam
    aantalLeerlingen
    grafiekDetails {
      datum
      leermiddelnaam
      gebruiksPercentage
    }
    detailsPerLeermiddel {
      activatiePercentage
      totaalGebruikPerWeek
      leermiddelNaam
      totaalActivatiePercentage
      gebruikPerWeek
      totaalWaarvanRecentGebruiktPercentage
      waarvanRecentGebruiktPercentage
    }
    docentUUIDs
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LesgroepDetailSamenvattingDashboardGQL extends Apollo.Query<LesgroepDetailSamenvattingDashboardQuery, LesgroepDetailSamenvattingDashboardQueryVariables> {
    document = LesgroepDetailSamenvattingDashboardDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LesgroepDetaildashboardDocument = gql`
    query LesgroepDetaildashboard($filter: LesgroepDetailDashboardFilterInput!) {
  vestigingen {
    naam
    uuid
    dataSinds
    vanafdatumLicenties
    einddatumLicenties
    vanafdatumStudiewijzers
    einddatumStudiewijzers
  }
  getDataPerLeerling(arg0: $filter) {
    lesgroepNaam
    lesgroepUUID
    lesgroepLeerjaar
    collegaLesgroepen {
      lesgroepNaam
      lesgroepUUID
    }
    gebruikPerLeerling {
      leerlingIdentifier
      leermiddelIdentifier
      gebruikDezePeriode
      gebruikVorigePeriode
      datumLaatsteGebruik
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LesgroepDetaildashboardGQL extends Apollo.Query<LesgroepDetaildashboardQuery, LesgroepDetaildashboardQueryVariables> {
    document = LesgroepDetaildashboardDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LesgroepDetaildashboardMetSamenvattingDocument = gql`
    query LesgroepDetaildashboardMetSamenvatting($filter: LesgroepDetailDashboardFilterInput!) {
  vestigingen {
    naam
    uuid
    dataSinds
    vanafdatumLicenties
    einddatumLicenties
    vanafdatumStudiewijzers
    einddatumStudiewijzers
  }
  getDataPerLeerling(arg0: $filter) {
    lesgroepNaam
    lesgroepUUID
    lesgroepLeerjaar
    collegaLesgroepen {
      lesgroepNaam
      lesgroepUUID
    }
    gebruikPerLeerling {
      leerlingIdentifier
      leermiddelIdentifier
      gebruikDezePeriode
      gebruikVorigePeriode
      datumLaatsteGebruik
    }
  }
  getSamenvattingData(filter: $filter) {
    lesgroepNaam
    aantalLeerlingen
    grafiekDetails {
      datum
      leermiddelnaam
      gebruiksPercentage
    }
    detailsPerLeermiddel {
      activatiePercentage
      totaalGebruikPerWeek
      leermiddelNaam
      totaalActivatiePercentage
      gebruikPerWeek
      totaalWaarvanRecentGebruiktPercentage
      waarvanRecentGebruiktPercentage
    }
    docentUUIDs
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LesgroepDetaildashboardMetSamenvattingGQL extends Apollo.Query<LesgroepDetaildashboardMetSamenvattingQuery, LesgroepDetaildashboardMetSamenvattingQueryVariables> {
    document = LesgroepDetaildashboardMetSamenvattingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LesgroepSchooldashboardDocument = gql`
    query LesgroepSchooldashboard($filter: DashboardFilterInput!) {
  vestigingen {
    naam
    uuid
    dataSinds
    vanafdatumLicenties
    einddatumLicenties
    vanafdatumStudiewijzers
    einddatumStudiewijzers
  }
  lesgroepschooldashboard(filter: $filter) {
    onderwijssoort
    vak
    lesgroepNaam
    lesgroepUuid
    lesgroepLeerjaar
    leerlingenGeactiveerd
    actieveLeerlingen
    leerlingenInLesgroep
    dag
    daggebruik
  }
  vakloosschooldashboard(filter: $filter) {
    titel
    ean
    onderwijssoort
    leerjaar
    actieveLeerlingen
    leerlingenGeactiveerd
    leerlingen
    dag
    daggebruik
  }
  lesgroepOverstijgendDagGebruik(filter: $filter) {
    displayName
    ean
    onderwijssoortAfkorting
    leerjaar
    vak
    aantalLeerlingen
    aantalGeactiveerd
    aantalActieveGebruikers
    dag
    gebruikVandaag
  }
  statistieken(filter: $filter) {
    percentageLesgroepenMetInzetBoven70pct
    licentiesPerLeerling
    educatieveAanbieders
    aantalGeactiveerdeLicenties
  }
  gemActieveLeerlingenPerDag(filter: $filter)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LesgroepSchooldashboardGQL extends Apollo.Query<LesgroepSchooldashboardQuery, LesgroepSchooldashboardQueryVariables> {
    document = LesgroepSchooldashboardDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SwDetailsPerPlatformDocument = gql`
    query SWDetailsPerPlatform($filter: DashboardFilterInput!, $platform: String!) {
  detailsPerPlatform(filter: $filter, platform: $platform) {
    naam
    links
    klikratio
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SwDetailsPerPlatformGQL extends Apollo.Query<SwDetailsPerPlatformQuery, SwDetailsPerPlatformQueryVariables> {
    document = SwDetailsPerPlatformDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SwDetailsPerVakDocument = gql`
    query SWDetailsPerVak($filter: DashboardFilterInput!, $vak: String) {
  detailsPerVak(filter: $filter, vak: $vak) {
    naam
    links
    klikratio
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SwDetailsPerVakGQL extends Apollo.Query<SwDetailsPerVakQuery, SwDetailsPerVakQueryVariables> {
    document = SwDetailsPerVakDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StudiewijzerlinksPerPlatformDocument = gql`
    query StudiewijzerlinksPerPlatform($filter: DashboardFilterInput!) {
  studiewijzerlinksPerPlatform(filter: $filter) {
    naam
    links
    klikratio
    lesgroepen
    type
  }
  aantalLesgroepenMetStudiewijzerlinks(filter: $filter)
  vestigingen {
    naam
    uuid
    dataSinds
    vanafdatumLicenties
    einddatumLicenties
    vanafdatumStudiewijzers
    einddatumStudiewijzers
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class StudiewijzerlinksPerPlatformGQL extends Apollo.Query<StudiewijzerlinksPerPlatformQuery, StudiewijzerlinksPerPlatformQueryVariables> {
    document = StudiewijzerlinksPerPlatformDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StudiewijzerlinksPerVakDocument = gql`
    query StudiewijzerlinksPerVak($filter: DashboardFilterInput!) {
  studiewijzerlinksPerVak(filter: $filter) {
    naam
    links
    klikratio
    lesgroepen
    type
  }
  aantalLesgroepenMetStudiewijzerlinks(filter: $filter)
  vestigingen {
    naam
    uuid
    dataSinds
    vanafdatumLicenties
    einddatumLicenties
    vanafdatumStudiewijzers
    einddatumStudiewijzers
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class StudiewijzerlinksPerVakGQL extends Apollo.Query<StudiewijzerlinksPerVakQuery, StudiewijzerlinksPerVakQueryVariables> {
    document = StudiewijzerlinksPerVakDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StudiewijzerPlatformVakDocument = gql`
    query StudiewijzerPlatformVak($filter: StudiewijzerlinksFilterInput!) {
  studiewijzerlinksPerPlatformVakOnderwijssoort(filter: $filter) {
    naam
    vaknaam
    getallenPerOnderwijssoort {
      onderwijssoort
      leerjaar
      aantalLinks
      klikratio
      aantalLesgroepen
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class StudiewijzerPlatformVakGQL extends Apollo.Query<StudiewijzerPlatformVakQuery, StudiewijzerPlatformVakQueryVariables> {
    document = StudiewijzerPlatformVakDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SubmitSurveyMonkeyFeedbackDocument = gql`
    mutation SubmitSurveyMonkeyFeedback($arg1: GraphQLSurveyMonkeyFeedbackSubmissionInput!) {
  sendFeedback(submission: $arg1) {
    success
    hint
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SubmitSurveyMonkeyFeedbackGQL extends Apollo.Mutation<SubmitSurveyMonkeyFeedbackMutation, SubmitSurveyMonkeyFeedbackMutationVariables> {
    document = SubmitSurveyMonkeyFeedbackDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }