import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, filter, map, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {AppState, PRIVACY_DASHBOARD_FORBIDDEN} from '../state/app.state';
import {ApolloError} from 'apollo-client';
import {payload} from '../state/payload';
import {reportError} from '../state/errors/errors.actions';
import {SomtodayService} from '../services/somtoday.service';
import {routerNavigatedAction} from '@ngrx/router-store';
import {selectOAuthClientParam, selectOmgevingParam} from '../state/router/router.selectors';
import {select, Store} from '@ngrx/store';
import {
  saveKoppelpartij,
  setEndpoints,
  setKoppelpartij,
  setLeespermissies,
  setOAuthClient,
  setOrganisaties,
  setSchrijfpermissies,
  setSuccesfullySent
} from '../state/dev-koppelpartij/dev-koppelpartij.actions';
import {selectKoppelpartij, selectUUID} from '../state/dev-koppelpartij/dev-koppelpartij.selectors';

@Injectable()
export class FetchKoppelpartij {
  $ = createEffect(() => this.actions$.pipe(
    ofType(routerNavigatedAction),
    withLatestFrom(this.store.pipe(select(selectOAuthClientParam))),
    withLatestFrom(this.store.pipe(select(selectOmgevingParam))),
    filter(([[{payload: p}, client], omgeving]) => p?.routerState?.url.includes('/dev/koppelpartij') && (client !== null) && (omgeving !== null)),
    switchMap(([[, client], omgeving]) => this.somtodayService.fetchKoppelpartijByOAuthClient((omgeving[0].toUpperCase() + omgeving.slice(1).toLowerCase()), client)),
    map(
      item => ({
        koppelpartij: item,
      })
    ),
    map(setKoppelpartij),
    catchError(e => {
      if (e.status === 404) {
        return of(e).pipe(
          withLatestFrom(this.store.select(selectOAuthClientParam)),
          withLatestFrom(this.store.select(selectOmgevingParam)),
          map(([[_, id], omgeving]) => ({ koppelpartij: { oAuthClientId: id, oAuthClientOmgeving: omgeving }})),
          map(setKoppelpartij));
      } else {
        return of(e).pipe(
          map((networkError: HttpErrorResponse) => networkError.status === 403 ?
            {networkError, errorMessage: PRIVACY_DASHBOARD_FORBIDDEN} : {networkError}),
          map(networkError => new ApolloError(networkError)),
          map(payload),
          map(reportError));
      }
    })
  ));

  constructor(private actions$: Actions, private somtodayService: SomtodayService, private store: Store<AppState>) {}
}


@Injectable()
export class FetchLeespermissies {
  $ = createEffect(() => this.actions$.pipe(
    ofType(routerNavigatedAction),
    filter(({payload: p}) => p?.routerState?.url.includes('/dev/koppelpartij')),
    switchMap(() => this.somtodayService.fetchLeespermissies()),
    map(payload),
    map(setLeespermissies),
    catchError(e => {
      return of(e).pipe(
        map((networkError: HttpErrorResponse) => networkError.status === 403 ?
          {networkError, errorMessage: PRIVACY_DASHBOARD_FORBIDDEN} : {networkError}),
        map(networkError => new ApolloError(networkError)),
        map(payload),
        map(reportError));
    })
  ));

  constructor(private actions$: Actions, private somtodayService: SomtodayService, private store: Store<AppState>) {}
}

@Injectable()
export class FetchSchrijfpermissies {
  $ = createEffect(() => this.actions$.pipe(
    ofType(routerNavigatedAction),
    filter(({payload: p}) => p?.routerState?.url.includes('/dev/koppelpartij')),
    switchMap(() => this.somtodayService.fetchSchrijfpermissies()),
    map(payload),
    map(setSchrijfpermissies),
    catchError(e => {
      return of(e).pipe(
        map((networkError: HttpErrorResponse) => networkError.status === 403 ?
          {networkError, errorMessage: PRIVACY_DASHBOARD_FORBIDDEN} : {networkError}),
        map(networkError => new ApolloError(networkError)),
        map(payload),
        map(reportError));
    })
  ));

  constructor(private actions$: Actions, private somtodayService: SomtodayService, private store: Store<AppState>) {}
}

@Injectable()
export class FetchEndpoints {
  $ = createEffect(() => this.actions$.pipe(
    ofType(routerNavigatedAction),
    filter(({payload: p}) => p?.routerState?.url.includes('/dev/koppelpartij')),
    switchMap(() => this.somtodayService.fetchEndpoints()),
    map(payload),
    map(setEndpoints),
    catchError(e => {
      return of(e).pipe(
        map((networkError: HttpErrorResponse) => networkError.status === 403 ?
          {networkError, errorMessage: PRIVACY_DASHBOARD_FORBIDDEN} : {networkError}),
        map(networkError => new ApolloError(networkError)),
        map(payload),
        map(reportError));
    })
  ));

  constructor(private actions$: Actions, private somtodayService: SomtodayService, private store: Store<AppState>) {}
}

@Injectable()
export class FetchOAuthClient {
  $ = createEffect(() => this.actions$.pipe(
    ofType(routerNavigatedAction),
    withLatestFrom(this.store.pipe(select(selectOAuthClientParam))),
    withLatestFrom(this.store.pipe(select(selectOmgevingParam))),
    filter(([[{payload: p}, client], omgeving]) => p?.routerState?.url.includes('/dev/koppelpartij') && (client !== null) && (omgeving !== null)),
    switchMap(([[_, client], omgeving]) => this.somtodayService.fetchOAuthClient((omgeving[0].toUpperCase() + omgeving.slice(1).toLowerCase()), client)),
    map(payload),
    map(setOAuthClient),
    catchError(e => {
      return of(e).pipe(
        map((networkError: HttpErrorResponse) => networkError.status === 403 ?
          {networkError, errorMessage: PRIVACY_DASHBOARD_FORBIDDEN} : {networkError}),
        map(networkError => new ApolloError(networkError)),
        map(payload),
        map(reportError));
    })
  ));

  constructor(private actions$: Actions, private somtodayService: SomtodayService, private store: Store<AppState>) {}
}

@Injectable()
export class FetchOrganisaties {
  $ = createEffect(() => this.actions$.pipe(
    ofType(routerNavigatedAction),
    filter(({payload: p}) => p?.routerState?.url.includes('/dev/koppelpartij')),
    switchMap(() => this.somtodayService.fetchOrganisaties()),
    map(payload),
    map(setOrganisaties),
    catchError(e => {
      return of(e).pipe(
        map((networkError: HttpErrorResponse) => networkError.status === 403 ?
          {networkError, errorMessage: PRIVACY_DASHBOARD_FORBIDDEN} : {networkError}),
        map(networkError => new ApolloError(networkError)),
        map(payload),
        map(reportError));
    })
  ));

  constructor(private actions$: Actions, private somtodayService: SomtodayService, private store: Store<AppState>) {}
}

@Injectable()
export class SaveKoppelpartij {
  $ = createEffect(() => this.actions$.pipe(
    ofType(saveKoppelpartij),
    withLatestFrom(this.store.select(selectKoppelpartij)),
    withLatestFrom(this.store.select(selectUUID)),
    switchMap(([[_, koppelpartij], uuid]) => {
      if (uuid)
        return this.somtodayService.putKoppelpartij(koppelpartij);
      else
        return this.somtodayService.postKoppelpartij(koppelpartij);
    }),
    map(payload),
    map(item => ({
      koppelpartij: item.value,
    })),
    mergeMap(item => [setKoppelpartij(item), setSuccesfullySent({ succesfullySent: true })]),
    catchError(e => {
      return of(e).pipe(
        map((networkError: HttpErrorResponse) => networkError.status === 403 ?
          {networkError, errorMessage: PRIVACY_DASHBOARD_FORBIDDEN} : {networkError}),
        map(networkError => new ApolloError(networkError)),
        map(payload),
        map(reportError));
    })
  ));

  constructor(private actions$: Actions, private somtodayService: SomtodayService, private store: Store<AppState>) {}
}
