import { MonoTypeOperatorFunction } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { AVGExtendedVeldpermissie } from '../dto/avg-dashboard-model-classes';
import { Categorie, Endpoint, Veld, VeldProperty } from '../state/dev-koppelpartij/dev-koppelpartij.state';

export function categoriseerEndpoints(veldpermissies: AVGExtendedVeldpermissie[], properEndpoints: string[]): Endpoint[] {
    const categorien: Categorie[] = veldpermissies.reduce((p, c) => {
        let categorie: Categorie = p.find(e => e.naam === c.entityName);
        if (categorie === undefined) {
            categorie = { naam: c.entityName, velden: [] };
            p.push(categorie);
        }
        categorie.velden.push({
            naam: c.name,
            entityIndex: c.entityIndex,
            fieldIndex: c.fieldIndex,
            selected: c.selected,
            verplicht: c.verplicht,
            properties: c.additionalProperties.map(prop => ({
                description: prop.description,
                type: prop.type
            }))
        });
        return p;
    }, []);

    const endpoints: Endpoint[] = categorien
        .filter(c => properEndpoints.includes(c.naam))
        .map(c => ({ categorieen: [{ naam: 'Algemeen', velden: c.velden }], naam: c.naam }));
    const endpointsToRemove: Endpoint[] = [];

    endpoints.forEach(e => {
        const fieldsToRemove: Veld[] = [];
        e.categorieen[0].velden.forEach(v => {
            const nestedEntity: VeldProperty = v.properties
                .find(prop => prop.type === 'NestedEntity');
            if (nestedEntity !== undefined) {
                const nestedEndpoint = categorien.find(c => c.naam === nestedEntity.description);
                if (nestedEndpoint !== undefined) {
                    e.categorieen.push({
                        naam: nestedEndpoint.naam, velden: nestedEndpoint.velden.map(nv => ({
                            entityIndex: nv.entityIndex,
                            fieldIndex: nv.fieldIndex,
                            naam: nv.naam,
                            selected: nv.selected,
                            verplicht: nv.verplicht,
                            properties: nv.properties
                        }))
                    });
                }
                fieldsToRemove.push(v);
            }
        });
        e.categorieen[0].velden = e.categorieen[0].velden.filter(v => !fieldsToRemove.includes(v));
        if (e.naam === 'Leerling pasfoto') {
            endpoints.find(ll => ll.naam === 'Leerling').categorieen[0].velden.push(
                e.categorieen[0].velden[0]);
            endpointsToRemove.push(e);
        }
        if (e.naam === 'Medewerker pasfoto') {
            endpoints.find(ll => ll.naam === 'Medewerker').categorieen[0].velden.push(
                e.categorieen[0].velden[0]);
            endpointsToRemove.push(e);
        }

        if(e.naam === 'Huiswerk') {
            const onderwijsspecifiek: Categorie = onderwijsspecifiekeCategorie(endpoints);
            onderwijsspecifiek.velden.push(
                e.categorieen[0].velden[0]);
            endpointsToRemove.push(e);
        }
    });

    return endpoints.filter(e => !endpointsToRemove.includes(e));
}

function onderwijsspecifiekeCategorie(endpoints: Endpoint[]): Categorie
{
    console.log('zoekt onderwijsspecifiek endpoint');
    const leerlingEndpoint: Endpoint = endpoints.find(ll => ll.naam === 'Leerling');
    let onderwijsspecifiek: Categorie = leerlingEndpoint.categorieen.find(c => c.naam === 'Onderwijsspecifiek');
    if(onderwijsspecifiek === undefined)
    {
        onderwijsspecifiek = {naam: 'Onderwijsspecifiek', velden: []};
        leerlingEndpoint.categorieen.push(onderwijsspecifiek);
    }
    return onderwijsspecifiek;
}

export function intrinsicDistinct<T>(): MonoTypeOperatorFunction<T> {
    return distinctUntilChanged((x1, x2) =>  x1 === x2, x => JSON.stringify(x));
}
