import {Component, Input} from '@angular/core';
import {deelVeilig} from '../../services/veilig-delen';
import {waarschuwing} from '../../services/activatie.service';

@Component({
  selector: 'app-activatiegetal-lesgroepdashboard',
  templateUrl: './activatiegetal-lesgroepdashboard.component.html',
  styleUrls: ['./activatiegetal-lesgroepdashboard.component.scss']
})
export class ActivatiegetalLesgroepdashboardComponent {
  @Input()
  totaal: number;

  @Input()
  geactiveerd: number;

  activatie() {
    return deelVeilig(this.geactiveerd, this.totaal);
  }

  waarschuwing(activatie: number): string {
    return waarschuwing(activatie);
  }
}
