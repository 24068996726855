import { Component } from '@angular/core';
import {TableCellComponent} from '../table/table.model';
import {LinechartCellModel} from './linechart-cell.model';

@Component({
  selector: 'app-linechart-cell',
  templateUrl: './linechart-cell.component.html',
  styleUrls: ['./linechart-cell.component.scss']
})
export class LinechartCellComponent implements TableCellComponent<LinechartCellModel> {
  data: LinechartCellModel;
}
