import { createReducer, on } from '@ngrx/store';

import { LeermiddelvakkoppelingNewState } from './leermiddelvakkoppeling.state';
import {
  setLeermiddelen,
  setLeermiddelVakKoppelingOpen,
  leermiddelStored,
  setSelectedLeermiddelVakKoppeling
} from './leermiddelvakkoppeling.actions';

const initialState: LeermiddelvakkoppelingNewState = {
    leermiddelVakKoppelingOpen: false,
    selectedLeermiddelVakKoppeling: null,
    leermiddelen: [],
    vestigingVakken: [],
    loading: true
};

export const leermiddelvakkoppelingNewReducer = createReducer(
    initialState,
    on(setLeermiddelen, (state, { value }) => ({ ...state,
      leermiddelen: value.leermiddelen,
      vestigingVakken: value.vestigingVakken,
      loading: false
    })),
    on(leermiddelStored, (state, { value }) => ({
        ...state,
        leermiddelen: [ ...state.leermiddelen.filter(l => l.UUID !== value.UUID), value]
    })),
    on(setLeermiddelVakKoppelingOpen, (state, { value }) =>
    ({
        ...state,
        leermiddelVakKoppelingOpen: value,
        selectedLeermiddelVakKoppeling: null
    })),
    on(setSelectedLeermiddelVakKoppeling, (state, { value }) => ({
        ...state,
        selectedLeermiddelVakKoppeling: value
    }))
);
