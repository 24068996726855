import {
  accent_positive_1,
  accent_positive_2,
  blauwaccent_positive_1,
  geel3,
  accent_alt_2,
  primary_3,
  primary_2,
  secondary_1,
  primary_1,
  secondary_2,
  paars2,
  oranje4,
  accent_warning_2,
  rood4,
  accent_negative_2,
  roze1,
  typography_3,
  typography_1,
  typography_2,
  background_3
} from '../shared/colors';

export class StudiewijzerKleuren {

    private static kleuren: string[] = [
        accent_positive_1,
        blauwaccent_positive_1,
        accent_positive_2,
        secondary_2,
        geel3,
        secondary_1,
        primary_3,
        primary_2,
        primary_1,
        accent_alt_2,
        paars2,
        oranje4,
        accent_warning_2,
        rood4,
        accent_negative_2,
        roze1,
        typography_3,
        typography_1,
        typography_2,
        background_3
    ];

    public static forIndex(index: number): string {
        if (index === null || index === undefined) { return  'unset'; }
        return StudiewijzerKleuren.kleuren[index];
    }

    public static getKleuren(): string[] {
        return StudiewijzerKleuren.kleuren;
    }

    public static iterate(): StudiewijzerKleurenIterator {
        return new StudiewijzerKleurenIterator(StudiewijzerKleuren.kleuren);
    }
}

export class StudiewijzerKleurenIterator {
    kleuren: string[];
    index: number;
    overigIndex: number;

    public constructor(kleuren: string[]) {
        this.kleuren = kleuren;
        this.index = -1;
        this.overigIndex = kleuren.length - 1;
    }

    public next(): string {
        this.index++;
        return this.kleuren[this.index];
    }

    public last(): string {
        return this.kleuren[this.overigIndex];
    }
}
