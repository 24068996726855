<div
  class="positioner"
  [ngClass]="alignment === 'left' ? 'positioner--left' : 'positioner--right'"
>
  <ng-container *ngIf="display === 'classic'; else alternative">
    <div class="datepicker-wrapper">
      <div class="datepicker-link" click-stop-propagation (click)="toggleDatepicker()">
        <i class="icon-calender"></i>
        <p>Datumbereik</p>
      </div>
      <div class="datepicker-current">
        <div class="arrow-wrapper arrow-wrapper--left" (click)="navigaarNaarVorige()">
          <i class="arrow left {{(hasPrevious | async) ? 'enabled' : 'disabled'}}"></i>
        </div>
        <span class="width-150">
          {{datumbereikFormatted$ | async}}
        </span>
        <div class="arrow-wrapper arrow-wrapper--right" (click)="navigaarNaarVolgende()">
          <i class="arrow right {{(hasNext | async) ? 'enabled' : 'disabled'}}"></i>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #alternative>
    <div class="datepicker datepicker--alternative">
      <label for="toggle">Kies datumbereik</label>
      <div class="input" click-stop-propagation (click)="toggleDatepicker()">
        <input id="toggle" value="{{datumbereikFormatted$ | async}}" type="button"/>
        <app-icon-keper></app-icon-keper>
      </div>
    </div>
  </ng-template>

  <div *ngIf="showDatepicker">
    <app-datumbereik-selectie-panel
      [viewModel]="datumbereikSelectieViewModel"
    ></app-datumbereik-selectie-panel>
  </div>

</div>
