import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {BazenbannerModel} from '../../layout/model/bazenbanner.model';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ActivatedRoute, Params} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {ONGECLASSIFICEERDE_METHODE, SchooldashboardModel} from './schooldashboard.model';
import {ModalwindowService} from '../../services/modalwindow.service';
import {FilterService} from '../../services/filter.service';
import {SchooldashboardFacade} from '../state/schooldashboard/schooldashboard.facade';

@Component({
  selector: 'app-schooldashboard',
  templateUrl: './schooldashboard.component.html',
  styleUrls: ['./schooldashboard.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])]
})
export class SchooldashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  bazenbannerModel: BazenbannerModel;

  selectSchooldashboardVakModel$: Observable<SchooldashboardModel[]>;

  selectSchooldashboardOnderwijssoortModel$: Observable<SchooldashboardModel[]>;

  vestigingBevatData$: Observable<boolean>;

  private subscriptions_: Subscription[] = [];

  private scrollToId_: string;

  private timeoutHandle_: number;

  loading = this.store.selectLoading();

  groeperenOpVak: boolean;

  private openVakken: string[] = [];

  private openOnderwijssoorten: string[] = [];

  constructor(
    private route: ActivatedRoute,
    private modalwindowService: ModalwindowService,
    private store: SchooldashboardFacade,
    private filterService: FilterService
  ) {}

  ngOnInit() {
    this.selectSchooldashboardVakModel$ = this.store.selectSchooldashboardVakData();
    this.selectSchooldashboardOnderwijssoortModel$ = this.store.selectSchooldashboardOnderwijssoortData();
    this.vestigingBevatData$ = this.store.selectVestigingBevatLicenties();
    this.subscriptions_ = [
      this.store.selectBazenBannerData().subscribe(value => this.bazenbannerModel = value),
      this.store.selectScrollToId().subscribe(value => this.scrollToId_ = value),
      this.store.selectGroeperenOpVak().subscribe(value => this.groeperenOpVak = value),
      this.store.selectOpenVakken().subscribe(value => this.openVakken = value),
      this.store.selectOpenOnderwijssoorten().subscribe(value => this.openOnderwijssoorten = value)
    ];
  }

  ngAfterViewInit(): void {
    if (!!this.scrollToId_) {
      this.timeoutHandle_ = window.setTimeout(scrollToId, 500, this.scrollToId_);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions_.forEach(s => s.unsubscribe());
    window.clearTimeout(this.timeoutHandle_);
  }

  isOpen({key}: SchooldashboardModel): boolean {
    return this.groeperenOpVak ? this.openVakken.includes(key) : this.openOnderwijssoorten.includes(key);
  }

  toggle({key}: SchooldashboardModel) {
    const update = (items: string[], item: string) => items.includes(item) ? items.filter(i => i !== item) : [...new Set([...items, item])];
    if (this.groeperenOpVak) {
      this.openVakken = update(this.openVakken, key);
    } else {
      this.openOnderwijssoorten = update(this.openOnderwijssoorten, key);
    }
  }

  public isLandelijkVakGroup(row: SchooldashboardModel): boolean {
    return row.key === ONGECLASSIFICEERDE_METHODE;
  }

  public toonLandelijkVakHelp(event) {
    this.modalwindowService.toonOnderwerp('landelijkVak');
    event.stopPropagation();
  }

  onGroeperingChanged(groeperenOpVak: boolean) {
    this.filterService.setFilterOption({groeperenOpVak});
  }

  onDetailSelected(url: string) {
    const fn = (_: string, __: Params, query: Params) => ({
      url,
      query: {...query, vak: this.openVakken, ows: this.openOnderwijssoorten}
    });
    this.filterService.transformFilterToPath(fn);
  }
}

function scrollToId(id: string) {
  const target: HTMLElement = document.getElementById(id);
  if (target !== null) {
    target.scrollIntoView({behavior: 'smooth', block: 'nearest'});
  }
}
