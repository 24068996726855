import {Component, OnInit, ChangeDetectionStrategy, OnDestroy, HostListener} from '@angular/core';
import {
  selectGeselecteerdeKoppelingen,
  selectGeselecteerdeKoppelpartij,
  selectGoBackURL,
  selectKoppelingenBeschikbaarForKoppelpartij
} from '../state/privacydashboard/privacydashboard.selectors';
import {
  deleteKoppeling,
  fetchGeselecteerdeKoppelpartij,
  setGeselecteerdeKoppelingen,
  setGeselecteerdeKoppelpartij
} from '../state/privacydashboard/privacydashboard.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../state/app.state';
import {KoppelingMenuItem, KoppelingMenuViewModel} from '../layout/koppeling-menu/koppeling-menu.component';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {KoppelingHeaderViewModel} from '../layout/koppeling-header/koppeling-header.component';
import {map, take, takeWhile, withLatestFrom} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {PrivacydashboardFacade} from '../state/privacydashboard/privacydashboard.facade';
import {Vestiging} from '../state/privacydashboard/privacydashboard.state';
import {AVGExtendedKoppeling, AVGExtendedKoppelpartij} from '../dto/avg-dashboard-model-classes';
import moment from 'moment';
import {GoBackHeaderViewModel} from '../layout/go-back-header/go-back-header.component';
import { selectKoppelpartijParam } from '../state/router/router.selectors';

@Component({
    selector: 'app-koppelpartij-details-page',
    templateUrl: './koppelpartij-details-page.component.html',
    styleUrls: ['./koppelpartij-details-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class KoppelpartijDetailsPageComponent implements OnInit, OnDestroy {

    private subscriptions = [];

    public koppelpartij$: Observable<AVGExtendedKoppelpartij>;
    private koppelpartijUUID: string;

    public goBackHeaderViewModel: GoBackHeaderViewModel;
    public headerViewModel: KoppelingHeaderViewModel;
    public menuViewModel: KoppelingMenuViewModel;

    public omschrijvingTab: Observable<boolean>;
    public datatoegangTab: Observable<boolean>;

    private menuItemOmschrijving: KoppelingMenuItem = {caption: 'Omschrijving', key: 'omschrijving'};
    private menuItemDatatoegang: KoppelingMenuItem = {caption: 'Datatoegang', key: 'datatoegang'};

    public gekoppeldeVestigingen$: Observable<Vestiging[]>;
    public koppelingenBeschikbaarForKoppelpartij$: Observable<boolean>;
    public sortedKoppelingen$: Observable<AVGExtendedKoppeling[][]>;

    public showVerwijderModal: boolean = false;
    public koppelingenToDelete: string[] = [];

    public showBewerkModal: boolean = false;

    public backURL$: Observable<string>;

    public loading = this.store.selectLoading();

    private hasKoppelingen: boolean;

    @HostListener('window:click', ['$event'])
    clickout(): void {
      this.headerViewModel.showMenu.next(false);
    }

    constructor(private store: PrivacydashboardFacade, private appState: Store<AppState>, private toastr: ToastrService, private router: Router) { }

    ngOnInit(): void {
      this.appState.select(selectKoppelpartijParam)
      .pipe(takeWhile(koppelpartijUUID => !koppelpartijUUID, true)).subscribe((koppelpartijUUID) => {
          if (koppelpartijUUID) {
              this.appState.dispatch(fetchGeselecteerdeKoppelpartij({koppelpartijUUID}));
          }
      });

      this.koppelpartij$ = this.appState.select(selectGeselecteerdeKoppelpartij);
      this.subscriptions.push(this.koppelpartij$.subscribe(k => this.koppelpartijUUID = k?.uuid));

      this.koppelingenBeschikbaarForKoppelpartij$ = this.appState.select(selectKoppelingenBeschikbaarForKoppelpartij);

      this.gekoppeldeVestigingen$ = this.appState.select(selectGeselecteerdeKoppelingen).pipe(map(klist => klist?.map(k => k.vestiging).sort(
        (a, b) => {
          if (a.naam < b.naam) { return -1; }
          if (a.naam > b.naam) { return 1; }
          return 0;
        }
      )));

      this.sortedKoppelingen$ = this.appState.select(selectGeselecteerdeKoppelingen).pipe(
        map(klist => {
          const groupedKoppelingen = [];
          if (klist) {
            for (const koppeling of klist) {
              const foundGroup = groupedKoppelingen.find(ks => {
                return ks.find(k =>
                  k.permissieHash === koppeling.permissieHash &&
                  k.einddatum === koppeling.einddatum &&
                  (k.begindatum === koppeling.begindatum || (moment(k.begindatum) < moment() && moment(koppeling.begindatum) < moment()))
                );
              });
              if (foundGroup) {
                foundGroup.push(koppeling);
              } else {
                groupedKoppelingen.push([koppeling]);
              }
            }
            for (const group of groupedKoppelingen) {
              group.sort((a, b) => {
                if (a.vestiging.naam < b.vestiging.naam) { return -1; }
                if (a.vestiging.naam > b.vestiging.naam) { return 1; }
                return 0;
              });
            }
          }
          return groupedKoppelingen;
      }));

      this.backURL$ = this.appState.select(selectGoBackURL);

      this.loadGoBackHeaderViewModel();
      this.loadMenuViewModel();
      this.loadHeaderViewModel();

    }

    loadGoBackHeaderViewModel(): void {
      this.subscriptions.push(this.backURL$.subscribe(url => {
        if (url) {
          this.goBackHeaderViewModel = {
            title: null,
            backUrl: url,
            backTitle: 'Terug naar overzicht'
          };
        }
      }));
    }

    loadMenuViewModel(): void {
      const activeMenuKey: BehaviorSubject<string> = new BehaviorSubject(this.menuItemOmschrijving.key);
      const menuItems: BehaviorSubject<KoppelingMenuItem[]> = new BehaviorSubject<KoppelingMenuItem[]>([this.menuItemOmschrijving, this.menuItemDatatoegang]);

      this.menuViewModel = {
        onGetMenuItems: menuItems,
        onGetActiveKey: activeMenuKey,

        doSetMenuItems: menuItems,
        doSetActiveKey: activeMenuKey
      };

      this.omschrijvingTab = this.menuViewModel.onGetActiveKey.pipe(map(k => k === 'omschrijving'));
      this.datatoegangTab = this.menuViewModel.onGetActiveKey.pipe(map(k => k === 'datatoegang'));
      this.backURL$.pipe(map(url => url.includes('aanbod') ? activeMenuKey.next(this.menuItemOmschrijving.key) : activeMenuKey.next(this.menuItemDatatoegang.key)));
    }

    loadHeaderViewModel(): void {
      const hasKoppelingen = this.gekoppeldeVestigingen$.pipe(map(k => k?.length > 0));

      this.subscriptions.push(hasKoppelingen.subscribe(hask => this.hasKoppelingen = hask));

      const verwijderen = {
        button: 'Koppeling (deels) opheffen',
        buttonClass: 'red',
        buttonIcon: 'svg_icon-ontkoppel',
        onButtonClick: new Subject<void>(),
      };
      this.subscriptions.push(verwijderen.onButtonClick.subscribe(() => this.deleteKoppeling()));

      const toevoegen = {
        button: 'Extra koppeling toevoegen',
        buttonClass: null,
        buttonIcon: 'svg_icon-koppel',
        onButtonClick: new Subject<void>(),
      };
      this.subscriptions.push(toevoegen.onButtonClick.subscribe(() => this.addKoppeling()));

      const bewerken = {
        button: 'Koppeling aanpassen',
        buttonClass: null,
        buttonIcon: 'svg_icon-bewerken',
        onButtonClick: new Subject<void>(),
      };
      this.subscriptions.push(bewerken.onButtonClick.subscribe(() => this.bewerkKoppeling()));

      const headerViewModelMenu = hasKoppelingen.pipe(
          withLatestFrom(this.koppelingenBeschikbaarForKoppelpartij$),
          map(([k, b]) => {
            const result = [];
            if (k) result.push(bewerken);
            if (b) result.push(toevoegen);
            if (k) result.push(verwijderen);
            return result;
          }));

      this.headerViewModel = {
        img: this.koppelpartij$.pipe(map(k => k?.koppelpartijLogoUrl ? k?.koppelpartijLogoUrl : 'assets/img/icons/Symbool/Koppelpartij_icon_placeholder.svg')),
        title: this.koppelpartij$.pipe(map(k => k?.koppelpartijNaam)),
        subTitle: this.koppelpartij$.pipe(map(k => k?.koppelpartijBedrijfsnaam)),
        button: hasKoppelingen.pipe(map(k => k ? 'Beheer koppeling' : 'Koppeling toevoegen')),
        buttonClass: hasKoppelingen.pipe(map(k => k ? '' : 'green')),
        buttonIcon: hasKoppelingen.pipe(map(k => k ? 'svg_icon-tandwiel' : null)),
        onButtonClick: () => this.onButtonClick(),
        menu: headerViewModelMenu,
        showMenu: new BehaviorSubject<boolean>(false),
      };
    }

    public onButtonClick(): void {
        this.hasKoppelingen ? this.headerViewModel.showMenu.next(!this.headerViewModel.showMenu.value) : this.addKoppeling();
    }

    deleteKoppeling(): void {
      this.headerViewModel.showMenu.next(false);
      this.showVerwijderModal = true;
    }

    selectAlleVestigingenToDelete(): void {
      this.gekoppeldeVestigingen$.pipe(take(1)).subscribe(gekoppeldeVestigingen => {
        if (gekoppeldeVestigingen.length === this.koppelingenToDelete.length) {
          this.koppelingenToDelete = [];
        } else {
          this.koppelingenToDelete = gekoppeldeVestigingen.map(v => v.UUID);
        }
      });
    }

    selectVestigingToDelete(uuid): void {
      this.koppelingenToDelete.includes(uuid) ?
        this.koppelingenToDelete = this.koppelingenToDelete.filter(k => k !== uuid) :
        this.koppelingenToDelete.push(uuid);
    }

    cancelDeleteKoppeling(): void {
      this.koppelingenToDelete = [];
      this.showVerwijderModal = false;
    }

    continueDeleteKoppeling(): void {
      if (this.koppelingenToDelete.length > 0) {
        this.koppelpartij$.pipe(take(1), map(k => k.uuid)).subscribe( koppelpartijUUID => {
          this.appState.dispatch(deleteKoppeling({uuid: koppelpartijUUID, vestigingen: this.koppelingenToDelete}));
        });
        this.koppelingenToDelete = [];
        this.showVerwijderModal = false;
      }
    }

    bewerkKoppeling(): void {
      this.headerViewModel.showMenu.next(false);
      this.showBewerkModal = true;
    }

    cancelBewerkKoppeling(): void {
      this.showBewerkModal = false;
    }

    continueBewerkKoppeling(koppelingen: AVGExtendedKoppeling[]): void {
      this.router.navigateByUrl('privacydashboard/koppelingbewerken/' + this.koppelpartijUUID + '?vestigingen=' + koppelingen.map(k => k.vestiging.UUID));
    }

    addKoppeling(): void {
      this.router.navigateByUrl('privacydashboard/koppelingaanmaken/' + this.koppelpartijUUID);
    }

    getCheckboxClass(uuid: string): string {
      return this.koppelingenToDelete.includes(uuid) ? 'checked' : 'unchecked';
    }

    getDeleteButtonClass(): string {
      return this.koppelingenToDelete.length > 0 ? 'button button--warning' : 'button button--warning button--disabled';
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
        this.appState.dispatch(setGeselecteerdeKoppelingen({koppelingen: null}));
        this.appState.dispatch(setGeselecteerdeKoppelpartij({koppelpartij: null}));
    }
}
