import {createAction, props} from '@ngrx/store';
import {Payload} from '../../../state/payload';
import {LeermiddelVakKoppelingInfo, VakInfo} from '../../../../generated/graphql';

export interface LeermiddelVakKoppelingData {
  leermiddelVakKoppelingInfo: LeermiddelVakKoppelingInfo[];
  vestigingsVakken: VakInfo[];
}

export const setLeermiddelVakKoppelingOpen = createAction('[LeermiddelVakKoppeling] Open koppelingscherm', props<Payload<boolean>>());
export const setLeermiddelVakKoppelingLoading = createAction('[LeermiddelVakKoppeling] Set loading koppelingscherm', props<Payload<boolean>>());
export const setLeermiddelVakKoppelingData = createAction(
  '[LeermiddelVakKoppeling] Set data',
  props<Payload<LeermiddelVakKoppelingData>>()
);
export const updateLeermiddelVakKoppelingData = createAction(
  '[LeermiddelVakKoppeling] Update data',
  props<Payload<LeermiddelVakKoppelingInfo>>()
);
export const setSelectedLeermiddelVakKoppeling =
  createAction('[LeermiddelVakKoppeling] Select LeermiddelVakKoppeling', props<Payload<[LeermiddelVakKoppelingInfo, number]>>());
