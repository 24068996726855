import {Component, Injectable} from '@angular/core';
import {SomtodayService} from '../services/somtoday.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.scss']
})
@Injectable()
export class ChangelogComponent {
  public changelog$: Observable<string>;

  constructor(private somtodayService: SomtodayService) {
    this.changelog$ = somtodayService.fetchChangelog();
  }
}

