import {createSelector} from '@ngrx/store';
import {selectGroeperenopUitgever} from '../../../state/router/router.selectors';
import {selectHuidigePeriode} from '../../../state/app.selectors';
import {selectHuidigeVestiging} from '../schooldashboard/schooldashboard.selectors';
import {LeermiddelenDashboardFilterInput, PeriodeInput} from '../../../../generated/graphql';
import {selectSchooldashboard} from '../../../state/app.state';

export const selectFilter = createSelector(
  selectGroeperenopUitgever,
  selectHuidigeVestiging,
  selectHuidigePeriode,
  (groeperenOpUitgever, vestiging, periode) =>
    ({groeperenOpUitgever, vestiging: vestiging?.uuid, periode: periode as unknown as PeriodeInput}) as LeermiddelenDashboardFilterInput
);

export const selectLeermiddelendashboardData = createSelector(
  selectSchooldashboard,
  state => state.leermiddelData
);
