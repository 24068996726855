import {ChangeDetectorRef, Component, Inject, OnInit, Renderer2, Type} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter, map, mergeMap} from 'rxjs/operators';
import {OauthConfigurationService} from './services/oauth.configuration.service';
import {AnalyticsService, Metrics} from './services/analytics.service';
import {ModalwindowService} from './services/modalwindow.service';
import {Observable, Subscription} from 'rxjs';
import * as moment from 'moment';
import {DOCUMENT} from '@angular/common';
import {AppState} from './state/app.state';
import {Store} from '@ngrx/store';
import {appInit} from './state/app.actions';
import {
  LeermiddelvakkoppelingFacade
} from './old-dashboarding/state/leermiddelvakkoppeling/leermiddelvakkoppeling.facade';
import {LeermiddelvakkoppelingNewFacade} from './state/leermiddelvakkoppeling/leermiddelvakkoppeling.facade';
import {SubMenu} from './app-routing.module';
import {ExportoverzichtFacade} from "./state/exportoverzicht/exportoverzicht.facade";
import {TemplatePortal} from "@angular/cdk/portal";
import {PortalBridgeService} from "./services/portal-bridge.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Somtoday Connect';
  icon = '';
  component: Type<Component>;
  iconFont = false;
  parameterSubscription: Subscription;
  toonHelp = false;
  modalWindowOnderwerp: string;
  leermiddelVakKoppelingOpen = false;
  subMenu: SubMenu;
  portal$: Observable<TemplatePortal>;
  overlayActive: boolean = false;

  private get SCROLL_LOCK_CLASS() {
    return 'scroll-lock-voor-modal-window';
  }

  constructor(private toastrService: ToastrService,
              private activatedRoute: ActivatedRoute,
              private changeDetectorRef: ChangeDetectorRef,
              private router: Router,
              private oauthConfigurationService: OauthConfigurationService,
              private portalBridgeService: PortalBridgeService,
              private modalWindowService: ModalwindowService,
              private analyticsService: AnalyticsService,
              private renderer: Renderer2,
              @Inject(DOCUMENT) private document: Document,
              private store: Store<AppState>,
              private leermiddelVakKoppelingStore: LeermiddelvakkoppelingFacade,
              public leermiddelvakkoppelingNew: LeermiddelvakkoppelingNewFacade,
              private exportoverzichtFacade: ExportoverzichtFacade
  ) {
    this.oauthConfigurationService.loadConfiguration();

    this.parameterSubscription = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)).subscribe(event => {
      this.analyticsService.logEvent(Metrics.PAGELOAD, (event as NavigationEnd).urlAfterRedirects);
    });

    this.parameterSubscription = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    ).subscribe((event) => {
      this.subMenu = event.subMenu;
      this.title = event.subtitle;
      this.icon = event.icon;
      this.component = event.component;
      this.iconFont = event.iconFont;
    });

    this.modalWindowService.onderwerp$.subscribe(onderwerp => {
      this.modalWindowOnderwerp = onderwerp;
      this.toonHelp = true;
      this.overlayActive = true;
      this.renderer.addClass(this.document.body, this.SCROLL_LOCK_CLASS);
    });

    this.modalWindowService.close$.subscribe(close => {
      this.toonHelp = !close;
      if (close) {
        this.overlayActive = false;
        this.renderer.removeClass(this.document.body, this.SCROLL_LOCK_CLASS);
      }
    });

    this.leermiddelvakkoppelingNew.isOpen().subscribe(isOpen => {
      this.setScrollLockState(isOpen);
    });

    this.exportoverzichtFacade.getSidebarOpenState().subscribe(isOpen => {
      this.setScrollLockState(isOpen);
    });

    moment.locale('nl');

    this.store.dispatch(appInit());

    this.leermiddelVakKoppelingStore.selectLeermiddelVakKoppelingOpen().subscribe(value => {
      this.leermiddelVakKoppelingOpen = value;

      this.setScrollLockState(this.leermiddelVakKoppelingOpen);
    });
  }

  ngOnInit() {
    this.portal$ = this.portalBridgeService.portal$;
  }

  private setScrollLockState(isOpen) {
    if (isOpen) {
      this.overlayActive = true;
      this.renderer.addClass(this.document.body, this.SCROLL_LOCK_CLASS);

      return;
    }

    this.overlayActive = false;
    this.renderer.removeClass(this.document.body, this.SCROLL_LOCK_CLASS);
  }
}
