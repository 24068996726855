import {Pipe, PipeTransform} from '@angular/core';
import {createColumnDef, createModel, TableModel} from '../table/table/table.model';
import {PercentageIndicatieCellComponent} from '../table/percentage-indicatie-cell/percentage-indicatie-cell.component';
import {RightAlignedCenterCellComponent} from '../table/right-aligned-center-cell/right-aligned-center-cell.component';
import {typography_2} from '../shared/colors';
import {PlatformVakTabelData} from './swplatformvak.model';

@Pipe({name: 'swplatformvak'})
export class Swplatformvak implements PipeTransform {
  transform(value: PlatformVakTabelData[]): TableModel<PlatformVakTabelData> {
    const getRowId = start => _ => `id${start++}`;
    const model = createModel(value, getRowId(1));
    model.getRowgroup = (rowModel: PlatformVakTabelData) => (rowModel.rowgroup);
    model.rowsClickable = false;
    model.dense = true;

    const naam = createColumnDef('naam', ``);
    naam.body.getValue = (rowmodel: PlatformVakTabelData) =>
      (rowmodel.leerjaar ? rowmodel.onderwijssoort + ' - ' + rowmodel.leerjaar : rowmodel.onderwijssoort);

    const links = createColumnDef('aantalLinks', 'Links');
    links.header.style = {textAlign: 'center', color: typography_2};
    links.body.component = RightAlignedCenterCellComponent;
    const grootsteGetal = value.map(v => v.aantalLinks).reduce((acc, v) => v > acc ? v : acc, 0);
    const width = Math.ceil(`${grootsteGetal}`.length / 2);
    links.body.getValue = (rowModel: PlatformVakTabelData) => ({value: rowModel.aantalLinks, width});

    const klikratio = createColumnDef('klikratio', 'Klikratio');
    klikratio.header.style = {textAlign: 'center', color: typography_2};
    klikratio.body.component = PercentageIndicatieCellComponent;
    klikratio.body.getValue = (rowModel: PlatformVakTabelData) =>
      ({waarde: rowModel.klikratio, matig: .6, laag: .3, titel: 'Klikratio', minpercent: 0});
    klikratio.body.style = {paddingLeft: '24px'};

    const lesgroepen = createColumnDef('aantalLesgroepen', 'Lesgroepen');
    lesgroepen.header.style = {textAlign: 'center', color: typography_2, paddingRight: '0'};
    lesgroepen.body.component = RightAlignedCenterCellComponent;
    const grootsteGetalLesgroep = value.map(v => v.aantalLesgroepen).reduce((acc, v) => v > acc ? v : acc, 0);
    const widthLesgroep = Math.ceil(`${grootsteGetalLesgroep}`.length / 2);
    lesgroepen.body.getValue = (rowModel: PlatformVakTabelData) => ({value: rowModel.aantalLesgroepen, widthLesgroep});
    lesgroepen.body.style = {paddingRight: 3 - (0.5 * widthLesgroep) + 'rem'};

    model.columnDefs = [naam, links, klikratio, lesgroepen];
    return model;
  }
}
