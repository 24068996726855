import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {AvgBackendService} from '../services/avg-backend.service';
import {
    exportDetailsFetched,
    exportsFetched,
    fetchExportDetails,
    fetchExports,
    medewerkerNamenFetched, medewerkerUuidsFetched,
    setDatumBereik,
    setFilterDatums, setFilterMedewerker, setPage
} from '../state/exportoverzicht/exportoverzicht.actions';
import {catchError, filter, map, switchMap, take, tap, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';
import {payload} from '../state/payload';
import {reportError} from '../state/errors/errors.actions';
import {ExportoverzichtFacade} from '../state/exportoverzicht/exportoverzicht.facade';
import {DatepickerService} from "../services/datepicker.service";
import { SomtodayService } from '../services/somtoday.service';
import { distinct } from '../stateless/array-util';
import {ExportoverzichtPageComponent} from '../avg/exportoverzicht/exportoverzicht-page/exportoverzicht-page.component';

@Injectable()
export class ExportoverzichtEffects {

  private EXPORT_PAGE_SIZE = 20;

  constructor(
    private actions$: Actions,
    private facade: ExportoverzichtFacade,
    private datepickerService: DatepickerService,
    private avgBackend: AvgBackendService,
    private somtoday: SomtodayService
  ) {
  }

  doSetDatumBereik = createEffect(() => {
    return this.actions$.pipe(
      ofType(setDatumBereik),
      map(({datumBereik}) => {
        const filterDatums = this.datepickerService.getDatumBereikFilter(datumBereik);

        return setFilterDatums(filterDatums);
      })
    );
  });

  doFetchExports = createEffect(() => this.actions$.pipe(
    ofType(fetchExports),
    withLatestFrom(this.facade.getFilterBegindatum(), this.facade.getFilterEinddatum(), this.facade.getPage(), this.facade.getFilterMedewerker()),
    filter(([_, begindatum, einddatum, page, _2]) => !!begindatum && !!einddatum && !!page),
    switchMap(([_, begindatum, einddatum, page, medewerker]) =>
        this.avgBackend.fetchExports(begindatum, einddatum, page, this.EXPORT_PAGE_SIZE, medewerker?.uuid).pipe(
            take(1),
            map(exportData => exportsFetched({exportData})),
            catchError(error => of(error).pipe(map(payload), map(reportError)))
        ))
  ));

    doFetchExportsByFilterSwitch = createEffect(() => this.actions$.pipe(
        ofType(setFilterDatums),
        withLatestFrom(this.facade.getPage(), this.facade.getFilterMedewerker()),
        switchMap(([{begindatum, einddatum}, page, medewerker]) =>
            this.avgBackend.fetchExports(begindatum, einddatum, page, this.EXPORT_PAGE_SIZE, medewerker?.uuid).pipe(
                take(1),
                map(exportData => exportsFetched({exportData})),
                catchError(error => of(error).pipe(map(payload), map(reportError)))
            ))
    ));

  doFetchExportsByMedewerkerSwitch = createEffect(() => this.actions$.pipe(
    ofType(setFilterMedewerker),
    withLatestFrom(this.facade.getFilterBegindatum(), this.facade.getFilterEinddatum(), this.facade.getPage(), this.facade.getFilterMedewerker()),
    switchMap(([_, begindatum, einddatum, page, medewerker]) =>
      this.avgBackend.fetchExports(begindatum, einddatum, page, this.EXPORT_PAGE_SIZE, medewerker?.uuid).pipe(
        take(1),
        map(exportData => exportsFetched({exportData})),
        catchError(error => of(error).pipe(map(payload), map(reportError)))
      ))
  ));

  doFetchMedewerkersByFilterSwitch = createEffect(() => this.actions$.pipe(
    ofType(setFilterDatums),
    switchMap(({begindatum, einddatum}) => this.avgBackend.fetchMedewerkers(begindatum, einddatum).pipe(
        take(1),
        map(medewerkers => medewerkerUuidsFetched({medewerkers})),
        catchError(error => of(error).pipe(map(payload), map(reportError)))
      ),
    )));

  doFetchExportsByPage = createEffect(() => this.actions$.pipe(
    ofType(setPage),
    withLatestFrom(this.facade.getFilterBegindatum(), this.facade.getFilterEinddatum(), this.facade.getFilterMedewerker()),
    switchMap(([{page}, begindatum, einddatum, medewerker]) =>
      this.avgBackend.fetchExports(begindatum, einddatum, page, this.EXPORT_PAGE_SIZE, medewerker?.uuid).pipe(
        take(1),
        map(exportData => exportsFetched({exportData})),
        catchError(error => of(error).pipe(map(payload), map(reportError)))
      ))
  ));

  doFetchExportDetails = createEffect(() => this.actions$.pipe(
    ofType(fetchExportDetails),
    switchMap(({id}) => {
      return this.avgBackend.fetchExportDetails(id).pipe(
        take(1),
        map(({id, exportDetails}) => {
          return exportDetailsFetched({id, exportDetails});
        }),
        catchError(error => of(error).pipe(map(payload), map(reportError)))
      );
    })
  ));

  doFetchMedewerkerNamen = createEffect(() => this.actions$.pipe(
      ofType(medewerkerUuidsFetched),
      map(({ medewerkers }) => medewerkers.map((exp) => exp.UUID)),
      switchMap(uuids => this.facade.getMedewerkerNamenNotPresent(uuids).pipe(take(1))),
      filter(uuids => uuids.length > 0),
      map(uuids => uuids.filter(distinct())),
      switchMap(uuids => this.somtoday.fetchMedewerkerNamen(uuids).pipe(
          take(1),
          map(namen => medewerkerNamenFetched({ namen })),
          catchError(error => of(error).pipe(map(payload), map(reportError)))
      ))
  ));
}
