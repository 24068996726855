import {Component, OnDestroy, OnInit} from '@angular/core';
import {Params} from '@angular/router';
import {Subscription} from 'rxjs';
import {LesgroepDetailData} from './klassendashboard.model';
import {FilterService} from '../../services/filter.service';
import {UrlObject} from 'query-string';
import {KlassendashboardFacade} from '../state/klassendashboard/klassendashboard.facade';
import {routingConstants} from '../../stateless/routing.constants';

@Component({
  selector: 'app-klassendashboard',
  templateUrl: './klassendashboard.component.html',
  styleUrls: ['./klassendashboard.component.scss']
})
export class KlassendashboardComponent implements OnInit, OnDestroy {

  lesgroepDetailData: LesgroepDetailData;
  volgendeUri: (p: Params, q: Params) => string;
  vorigeUri: (p: Params, q: Params) => string;
  selectie = 'individueel';

  loading = this.store.selectLoading();

  private subscriptions: Subscription[];

  constructor(private store: KlassendashboardFacade,
              private filterService: FilterService) {
  }

  ngOnInit(): void {
    this.subscriptions = [
      this.store.selectKlasdataPerLeerlingMetNaam()
        .subscribe(([lesgroepDetailData, shouldLoadLeerlingen]) => {
          this.lesgroepDetailData = lesgroepDetailData;
          if (shouldLoadLeerlingen) {
            this.store.loadLeerlingen();
          }
        }),
      this.store.selectVorigeEnVolgendeLesgroep(createPath)
        .subscribe(([vorige, volgende]) => {
          this.vorigeUri = vorige;
          this.volgendeUri = volgende;
        }),
      this.store.selectSelectie().subscribe(v => this.selectie = v)
    ];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  goBack(_, {vak, onderwijssoort}: Params, query: Params): UrlObject {
    return {
      url: `vakdashboard/${vak}/${onderwijssoort}`,
      query
    };
  }

  onSelectieChanged(selectie: string): void {
    this.filterService.setFilterOption({selectie});
  }
}

const createPath = (uuid: string) => {
  return !!uuid ? ({vak, onderwijssoort}: Params) => `/${routingConstants.KLASSEN_DASHBOARD_OLD}/${vak}/${onderwijssoort}/${uuid}` : undefined;
};
