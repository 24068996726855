<table mat-table class="mat-table" [dataSource]="dataSource" multiTemplateDataRows>
  <ng-container matColumnDef="naam">
    <th class="mat-header-cell" *matHeaderCellDef>LICENTIES</th>
    <td class="mat-cell" *matCellDef="let row"><span>{{row.getNaam()}}</span>
      <i class="icon-arrow-open" *ngIf="row.getChildren().length > 0 && !row.isExpanded()"></i>
      <i class="icon-arrow-close" *ngIf="row.isExpanded()"></i>
    </td>
  </ng-container>
  <ng-container matColumnDef="aantalLicenties">
    <th class="mat-header-cell" *matHeaderCellDef>Aantal licenties</th>
    <td class="mat-cell" *matCellDef="let row">{{row.getAantalLicenties()}}</td>
  </ng-container>
  <ng-container matColumnDef="actiefGebruikt">
    <th class="mat-header-cell" *matHeaderCellDef>Actief gebruikt</th>
    <td class="mat-cell" *matCellDef="let row">{{row.getActiefGebruikt()}}</td>
  </ng-container>
  <ng-container matColumnDef="geactiveerd">
    <th class="mat-header-cell" *matHeaderCellDef>Geactiveerd</th>
    <td class="mat-cell" *matCellDef="let row">{{row.getGeactiveerd()}}</td>
  </ng-container>
  <ng-container matColumnDef="activatiegraad">
    <th class="mat-header-cell" *matHeaderCellDef>Activatiegraad</th>
    <td class="mat-cell" *matCellDef="let row">
          <app-progressbar-percentage-only class="app-progressbar-activatiegraad"
                                           [progressbarinfo]="row.getActivatieGraad()">
          </app-progressbar-percentage-only>
    </td>
  </ng-container>

  <ng-container matColumnDef="expandedDetail">
    <td *matCellDef="let element" [attr.colspan]="displayedColumns.length">
      <div class="element-detail"
           [@detailExpand]="element.isExpanded() ? 'expanded' : 'collapsed'">
      <table mat-table class="mat-inner-table" [dataSource]="element.children" >
        <ng-container matColumnDef="naam">
          <td class="mat-cell" *matCellDef="let row">{{row.getNaam()}}</td>
        </ng-container>
        <ng-container matColumnDef="aantalLicenties">
          <td class="mat-cell" *matCellDef="let row">{{row.getAantalLicenties()}}</td>
        </ng-container>
        <ng-container matColumnDef="actiefGebruikt">
          <td class="mat-cell" *matCellDef="let row">{{row.getActiefGebruikt()}}</td>
        </ng-container>
        <ng-container matColumnDef="geactiveerd">
          <td class="mat-cell" *matCellDef="let row">{{row.getGeactiveerd()}}</td>
        </ng-container>
        <ng-container matColumnDef="activatiegraad">
          <td class="mat-cell" *matCellDef="let row">
            <app-progressbar-percentage-only class="app-progressbar-activatiegraad"
                                             [progressbarinfo]="row.getActivatieGraad()">
            </app-progressbar-percentage-only>
          </td>
        </ng-container>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row class="mat-header-row" *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"
      class="element-row"
      [class.example-expanded-row]="row.isExpanded()"
      (click)="row.toggle()">
  </tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail'];" class="detail-row"></tr>
</table>
