import {createAction, props} from '@ngrx/store';
import {Bazenbanner, OngekoppeldLeermiddel, OngekoppeldeLeermiddelen, SchooldashboardItemContent, SchooldashboardItemHeader} from './schooldashboard.state';
import {Payload} from '../payload';

export const setSchooldashboardVakken = createAction('[Schooldashboard] Set vakken', props<Payload<SchooldashboardItemHeader[]>>());
export const fetchSchooldashboardVakDetails = createAction('[Schooldashboard] Fetch vak details', props<Payload<{naam: string}>>());
export const setSchooldashboardVakDetails = createAction('[Schooldashboard] Set vak details', props<{vakdetails: SchooldashboardItemContent[], naam: string}>());

export const setSchooldashboardOnderwijssoorten = createAction('[Schooldashboard] Set onderwijssoorten', props<Payload<SchooldashboardItemHeader[]>>());
export const fetchSchooldashboardOnderwijssoortDetails = createAction('[Schooldashboard] Fetch onderwijssoort details', props<Payload<{naam: string}>>());
export const setSchooldashboardOnderwijssoortDetails = createAction('[Schooldashboard] Set onderwijssoort details', props<{onderwijssoortdetails: SchooldashboardItemContent[], naam: string}>());

export const fetchOngekoppeldeLeermiddelen = createAction('[Schooldashboard] Fetch ongekoppelde leermiddelen');
export const ongekoppeldeLeermiddelenFetched = createAction('[Schooldashboard] Ongekoppelde leermiddelen fetched', props<{ ongekoppeldeLeermiddelen: OngekoppeldeLeermiddelen }>());

export const fetchOngekoppeldeLeermiddelenRegels = createAction('[Schooldashboard] Fetch ongekoppelde leermiddelen');
export const ongekoppeldeLeermiddelenRegelsFetched = createAction('[Schooldashboard] Ongekoppelde leermiddelen regels fetched', props<{ regels: OngekoppeldLeermiddel[] }>());

export const setBazenbanner = createAction('[Schooldashboard] Set bazenbanner', props<Payload<Bazenbanner>>());

export const resetSchooldashboard = createAction('[Schooldashboard] Reset schooldashboard');
