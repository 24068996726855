import { Component, OnInit } from '@angular/core';
import {WidgetBaseComponent} from '../widget-base/widget-base.component';

@Component({
  selector: 'app-widget-progressbar',
  templateUrl: './widget-progressbar.component.html',
  styleUrls: ['../widget-base/widget-base.component.scss', './widget-progressbar.component.scss']
})
export class WidgetProgressbarComponent extends WidgetBaseComponent<number> implements OnInit {

  constructor() {
    super();
  }

  getWidthFor(dataItemElement: number) {
    return `${dataItemElement > 100 ? '100%' : `${dataItemElement}%`}`;
  }
}
