import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DeviceService {

    private mediaQueryDesktop: MediaQueryList = window.matchMedia('(min-width: 1280px)');
    private mediaQueryTabletPortrait: MediaQueryList = window.matchMedia('(min-width: 480px) and (max-width: 767px)');
    private mediaQueryTablet: MediaQueryList = window.matchMedia('(min-width: 768px) and (max-width: 1279px)');
    private mediaQueryPhone: MediaQueryList = window.matchMedia('(max-width: 479px)');

    constructor() { }

    public isPhone(): boolean {
        return this.mediaQueryPhone.matches;
    }

    public isTabletPortrait(): boolean {
        return this.mediaQueryTabletPortrait.matches;
    }

    public isTablet(): boolean {
        return this.mediaQueryTablet.matches;
    }

    public isDesktop(): boolean {
        return this.mediaQueryDesktop.matches;
    }

    public isPhoneOrTabletPortrait(): boolean {
        return this.isPhone() || this.isTabletPortrait();
    }

    public isPhoneOrTablet(): boolean {
        return this.isPhone() || this.isTabletPortrait() || this.isTablet();
    }

    public isTabletOrDesktop(): boolean {
        return this.isTabletPortrait() || this.isTablet() || this.isDesktop();
    }

    public isTabletPortraitOrTablet(): boolean {
        return this.isTabletPortrait() || this.isTablet();
    }

    public isPhoneOnly(): boolean {
        return this.isPhone() && !this.isTabletPortrait() && !this.isTablet() && !this.isDesktop();
    }

    public isTouch(): boolean {
        return this.isPhone() || this.isTabletPortraitOrTablet();
    }

}
