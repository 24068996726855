import {Component, OnDestroy, OnInit} from '@angular/core';
import {LeermiddelvakkoppelingFacade} from '../state/leermiddelvakkoppeling/leermiddelvakkoppeling.facade';
import {combineLatest, Subscription} from 'rxjs';
import {PlatformLocation} from '@angular/common';
import {LeermiddelVakKoppelingInfo} from '../../../generated/graphql';
import {Store} from '@ngrx/store';
import {AppState} from '../../state/app.state';

@Component({
  selector: 'app-leermiddelvakkoppeling',
  templateUrl: './leermiddelvakkoppeling.component.html',
  styleUrls: ['./leermiddelvakkoppeling.component.scss']
})
export class LeermiddelvakkoppelingComponent implements OnInit, OnDestroy {

  verticalOffset = window.scrollY;
  loading = true;
  dataZonderVak: LeermiddelVakKoppelingInfo[];
  dataMetVak: LeermiddelVakKoppelingInfo[];
  private subscriptions_: Subscription[] = [];
  toonGekoppeldeLeermiddelen = false;
  selectedLeermiddelVakKoppeling: LeermiddelVakKoppelingInfo;
  topVakkenlijst: number;
  widthVakkenlijst: number;

  constructor(private facade: LeermiddelvakkoppelingFacade,
              private store: Store<AppState>,
              private location: PlatformLocation) {
    // closes modal when back button is clicked
    location.onPopState(() => this.closeVenster());
  }

  ngOnInit(): void {
    this.subscriptions_ = [
      combineLatest([this.facade.selectLeermiddelVakKoppelingData(),
        this.facade.selectGekoppeldeLeermiddelen()]).subscribe(([leermiddelVakKoppelingData, gekoppeldeLeermiddelen]) => {
        if (leermiddelVakKoppelingData.length !== 0) {
          this.dataZonderVak = leermiddelVakKoppelingData.filter(d => !gekoppeldeLeermiddelen.includes(d.titel));
          this.dataMetVak = leermiddelVakKoppelingData.filter(d => gekoppeldeLeermiddelen.includes(d.titel));
        } else {
          this.dataZonderVak = null;
          this.dataMetVak = null;
        }
      }),
      this.facade.selectLeermiddelVakKoppelingLoading().subscribe(data => {
        this.loading = data;
      }),
      this.facade.selectSelectedLeermiddelVakKoppeling().subscribe(data => {
        if (data){
        const info = data[0];
        this.selectedLeermiddelVakKoppeling = info;
        this.topVakkenlijst = data[1] + document.getElementsByClassName('uitlegoverlay')[0].scrollTop + 34;
        this.widthVakkenlijst = document.getElementsByClassName('uitlegoverlay')[0].clientWidth - 50;
        } else {
          this.selectedLeermiddelVakKoppeling = null;
        }
      })
    ];
  }

  onScroll() {
    this.verticalOffset = window.scrollY;
  }

  closeVenster() {
    this.facade.setLeermiddelVakKoppelingOpen(false);
  }

  ngOnDestroy(): void {
    this.subscriptions_.forEach(s => s.unsubscribe());
  }
}
