import {Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy} from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {AppState} from '../../state/app.state';
import {selectActiveMenuItem} from '../../state/privacydashboard/privacydashboard.selectors';
import {pairwise, withLatestFrom} from 'rxjs/operators';

@Component({
  selector: 'app-koppeling-menu',
  templateUrl: './koppeling-menu.component.html',
  styleUrls: ['./koppeling-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KoppelingMenuComponent implements OnInit, OnDestroy {
  @Input()
  public viewModel: KoppelingMenuViewModel;

  private activeKey: string;

  private subscriptions: Subscription[] = [];

  constructor(private router: Router, private route: ActivatedRoute, private appState: Store<AppState>) { }

  ngOnInit(): void {
    this.subscriptions.push(this.viewModel.onGetActiveKey.subscribe(key => {
      if (key) {
        this.activeKey = key;
      }
    }));

    this.subscriptions.push(this.viewModel.onGetMenuItems.pipe(
      withLatestFrom(this.appState.select(selectActiveMenuItem))
    ).subscribe(([menuItems, activeItem]) => {
      if (menuItems?.length > 0 && activeItem) {
        const menuItem = menuItems.find(item => item.key === activeItem.toString());
        if (menuItem) {
          this.viewModel.doSetActiveKey.next(activeItem.toString());
        }
      }
      if (menuItems.length > 0 && !this.activeKey) {
        this.viewModel.doSetActiveKey.next(menuItems[0].key);
      }
    }));

    this.subscriptions.push(this.viewModel.onGetActiveKey.pipe(
      pairwise()
    ).subscribe(([prev, cur]) => {
      if (prev) {
        this.router.navigate(
          [],
          {
            relativeTo: this.route,
            queryParams: {menu: cur},
            queryParamsHandling: 'merge'
          });
      }
    }));
  }

  ngOnDestroy(): void {
      this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  public switchMenuItem(key: string): void {
    this.viewModel.doSetActiveKey.next(key);
  }

  public menuItemClass(item: KoppelingMenuItem): string {
      return item.key === this.activeKey ? 'menuItem active' : 'menuItem';
  }
}

export interface KoppelingMenuViewModel {
    onGetMenuItems: Observable<KoppelingMenuItem[]>;
    onGetActiveKey: Observable<string>;

    doSetMenuItems: Subject<KoppelingMenuItem[]>;
    doSetActiveKey: Subject<string>;
}

export class KoppelingMenuItem {
    key: string;
    caption: string;
}
