import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription, combineLatest, of } from 'rxjs';
import { filter, map, take, tap, withLatestFrom } from 'rxjs/operators';
import { RangeModel } from 'src/app/layout/model/range.model';
import { RangeLinechartKeybasedViewModel } from 'src/app/layout/range-linechart-keybased-new/range-linechart-keybased-new.component';
import { RangeLinechartModelKeyBased } from 'src/app/layout/range-linechart/range-linechart.model';
import { FilterService } from 'src/app/services/filter.service';
import { ModalwindowService } from 'src/app/services/modalwindow.service';
import { DashboardsettingsFacade } from 'src/app/state/dashboardsettings/dashboardsettings.facade';
import { PERIODE } from 'src/app/state/dashboardsettings/dashboardsettings.state';
import { SchooldashboardNewFacade } from 'src/app/state/schooldashboard/schooldashboard.facade';
import { LeerlingGrafiekPunt, OngekoppeldLeermiddel, OngekoppeldeLeermiddelen } from 'src/app/state/schooldashboard/schooldashboard.state';

@Component({
    selector: 'app-schooldashboard-ongekoppeld-row',
    templateUrl: './schooldashboard-ongekoppeld-row.component.html',
    styleUrls: ['./schooldashboard-ongekoppeld-row.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('openContent', [
            state('closed', style({ height: '0px', minHeight: '0' })),
            state('open', style({ height: '*' })),
            transition('open <=> closed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ])]
})
export class SchooldashboardOngekoppeldRowComponent implements OnInit {

    public isContentOpen = false;

    public header$: Observable<OngekoppeldeLeermiddelen>;

    public regels$: Observable<OngekoppeldLeermiddel[]>;

    private subs: Subscription[] = [];

    constructor(
        private settings: DashboardsettingsFacade,
        private router: Router,
        public store: SchooldashboardNewFacade,
        private modalwindowService: ModalwindowService,
        private filterService: FilterService, private cdr: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.header$ = this.store.getOngekoppeldeLeermiddelen();
        this.regels$ = this.store.getOngekoppeldeLeermiddelRegels();

        const open$: Observable<boolean> = this.store.getIsOngekoppeldeLeermiddelenOpen();

        this.subs.push(combineLatest([open$, this.regels$]).pipe(
            filter(([open, regels]) => open && regels === null)
        ).subscribe(() => this.store.fetchOngekoppeldeLeermiddelRegels()));

        this.subs.push(open$.subscribe(isOpen => this.isContentOpen = isOpen));
    }

    public setOpen(open: boolean): void {
        this.isContentOpen = open;

        this.isContentOpen ?
            this.filterService.addToFilterOption({
                key: 'ongekoppelde_leermiddelen',
                value: 'true'
            }) :
            this.filterService.deleteFromFilterOption({
                key: 'ongekoppelde_leermiddelen',
                value: 'true'
            });
    }

    public toggleOpen(): void {
        this.setOpen(!this.isContentOpen);
    }

    public openActiefHelp(): void {
        this.modalwindowService.toonOnderwerp('actief');
    }

    public openGebruikHelp(): void {
        this.modalwindowService.toonOnderwerp('gebruik');
    }

    public createActivatiegetalModel(row: OngekoppeldLeermiddel): RangeModel {
        return { min: row.minActivatie, avg: row.gemiddeldeActivatie, max: row.maxActivatie, tot: 100 };
    }

    public createRangeBarModel(row: OngekoppeldLeermiddel): RangeModel {
        return { min: row.minActief, avg: row.gemiddeldActief, max: row.maxActief, tot: 100 };
    }

    public createRangeLinechart(row: OngekoppeldLeermiddel): RangeLinechartModelKeyBased[] {
        return row.gebruiksGrafiek.map(lg => ({ key: lg.key, values: { min: lg.minimaalLesgroepGebruik, avg: lg.gemiddeldLesgroepGebruik, max: lg.maximaalLesgroepGebruik, tot: 100 } }));
    }

    public createRowLinechartViewModel(row: OngekoppeldLeermiddel): RangeLinechartKeybasedViewModel {
        return {
            onGetRange: of(row).pipe(map(this.createRangeLinechart)),
            onGetGemiddeldGebruik: of(row).pipe(withLatestFrom(this.settings.getPeriodeOrDefault())).pipe(map(this.getGemiddeldGebruik))
        };
    }

    public getGemiddeldGebruik([row, periode]: [OngekoppeldLeermiddel, PERIODE]): string {
        const footerStepByPeriode = periode === 'JAAR' ? 'maand' : 'dag';
        return row?.gemiddeldGebruik?.toFixed(1) + ' keer per ' + footerStepByPeriode;
    }
}
