import {Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {withLatestFrom} from 'rxjs/operators';
import { AVGExtendedVestiging } from 'src/app/dto/avg-dashboard-model-classes';
import {selectGeselecteerdeKoppelingen} from '../../state/privacydashboard/privacydashboard.selectors';
import {Store} from '@ngrx/store';
import {AppState} from '../../state/app.state';
import {selectVestigingenParam} from '../../state/router/router.selectors';

@Component({
    selector: 'app-select-vestigingen',
    templateUrl: './select-vestigingen.component.html',
    styleUrls: ['./select-vestigingen.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectVestigingenComponent implements OnInit, OnDestroy {

    public aantalGeselecteerd: number = 0;

    public toevoegenButtonStyle: string = 'toevoegenButton';

    public rows: VestigingRow[];

    public subscriptions = [];

    public listHeightStyle;

    private teBewerkenVestigingen = [];

    public infoBlock = null;

    @Input()
    public viewModel: SelectVestigingenViewModel;

    constructor(private store: Store<AppState>) {
    }


    ngOnInit(): void {
        // Deep-copy array anders gaan we de oorspronkelijke elementen aanpassen bij het selecteren van rijen.
        this.subscriptions.push(this.viewModel.onGetVestigingen
        .subscribe(rows => {
            this.rows = rows?.map(r => ({vestiging: r.vestiging, naam: r.naam, selected: r.selected}));
            this.setAantalGeselecteerd();
            this.setToevoegenButtonStyle();
        }));
        this.subscriptions.push(this.store.select(selectVestigingenParam).pipe(
          withLatestFrom(this.store.select(selectGeselecteerdeKoppelingen))
        ).subscribe(([v, k]) => {
          this.teBewerkenVestigingen = k?.filter(koppeling => v?.toLowerCase().split(',').includes(koppeling.vestiging.UUID.toLowerCase())).map(koppeling => koppeling.vestiging);
          this.setInfoBlock();
        }));

        this.viewModel.contentIsLoaded.next(true);
    }

    ngOnDestroy(): void {
      this.viewModel.contentIsLoaded.next(false);
      this.subscriptions.forEach(s => s.unsubscribe());
    }

    public sluiten(): void {
        this.viewModel.doSetIsOpen.next(false);
    }

    public selectieSelecteren(): void {
        if (this.rows.filter(row => row.selected).length === 0) return;
        this.viewModel.doUpdateSelection.next(this.rows);
        this.sluiten();
    }

    public onRowClicked(row: VestigingRow): void {
        row.selected = !row.selected;
        this.setAantalGeselecteerd();
        this.setToevoegenButtonStyle();
        this.setInfoBlock();
    }

    private setAantalGeselecteerd(): void {
        this.aantalGeselecteerd = this.rows?.filter(r => r.selected)?.length;
    }

    private setToevoegenButtonStyle(): void {
        this.toevoegenButtonStyle =  this.aantalGeselecteerd ? 'toevoegenButton' : 'toevoegenButton disabled';
    }

    private setInfoBlock(): void {
      const ongeselecteerdeVestigingen = this.teBewerkenVestigingen?.filter(v => !this.rows?.find(row => row.vestiging.UUID === v.UUID && row.selected)).map(v => v.naam);
      if (ongeselecteerdeVestigingen && ongeselecteerdeVestigingen.length > 0) {
        const isMeervoud = ongeselecteerdeVestigingen.length > 1;
        const vestigingenString = isMeervoud ? ongeselecteerdeVestigingen.slice(0, - 1).join(', ') + ' en ' + ongeselecteerdeVestigingen[ongeselecteerdeVestigingen.length - 1] : ongeselecteerdeVestigingen;
        this.infoBlock = 'De bestaande koppeling' + (isMeervoud ? 'en' : '') + ' voor ' + vestigingenString + ' blij' + ( isMeervoud ? 'ven' : 'ft') + ' ongewijzigd';
      } else {
        this.infoBlock = null;
      }
    }
}

export interface SelectVestigingenViewModel {
    onGetVestigingen: Observable<VestigingRow[]>;

    doSetIsOpen: BehaviorSubject<boolean>;
    doUpdateSelection: BehaviorSubject<VestigingRow[]>;

    contentIsLoaded: BehaviorSubject<boolean>;
}

export interface VestigingRow {
    vestiging: AVGExtendedVestiging;
    naam: string;
    selected: boolean;
}
