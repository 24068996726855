import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { Observable, combineLatest, timer } from 'rxjs';
import { BackofficeEtlTaskEvent, BackofficeEtlTaskExecution, BackofficeEtlTaskStep, BackofficeOrganization, BackofficeSchoolYear } from 'src/generated/backoffice-client';
import {
    selectFeedback,
    selectOrganizations,
    selectSchoolYears,
    selectSelectedTask,
    selectSelectedTaskEvents,
    selectSelectedTaskFailedOrgs,
    selectSelectedTaskLatestEventId,
    selectSelectedTaskOrganizations,
    selectSelectedTaskSchoolYears,
    selectSelectedTaskStepsLeft,
    selectSyncs,
    selectSyncsCachedPages,
    selectSyncsCount,
    selectSyncsCurrentPage,
    selectSyncsCurrentPageNumber,
    selectSyncsDescending,
    selectSyncsOrderBy,
    selectSyncsPageSize,
    selectSyncsPages
} from './dashboard-backoffice.selectors';
import { addFeedback, cleanUpFeedback, fetchOrganizations, fetchSchoolYears, resumeSelectedTask, saveOrganization, setSyncsPage, setSyncsSortColumn, startSync, stopSelectedTask, storeSchoolYear, taskPollStatus, taskSelected } from './dashboard-backoffice.actions';
import { catchError, distinctUntilChanged, filter, map, take, tap } from 'rxjs/operators';
import { Feedback } from 'src/app/new-dashboard/backoffice-dashboard-new/backoffice-dashboard-new.component';
import moment from 'moment';
import { DashboardBackofficeService, SyncConfig } from 'src/app/services/dashboard-backoffice.service';

@Injectable()
export class DashboardBackofficeFacade {

    schoolYears$: Observable<BackofficeSchoolYear[]>;
    organizations$: Observable<BackofficeOrganization[]>;
    feedback$: Observable<Feedback[]>;
    syncsCachedPages$: Observable<number[]>;
    syncs$: Observable<BackofficeEtlTaskExecution[]>;
    syncsCurrentPageNumber$: Observable<number>;
    syncsPageSize$: Observable<number>;
    syncsOrderBy$: Observable<string>;
    syncsOrderByDescending$: Observable<boolean>;
    syncsCount$: Observable<number>;
    selectedTask$: Observable<BackofficeEtlTaskExecution>;
    selectedTaskSchoolYears$: Observable<BackofficeSchoolYear[]>;
    selectedTaskOrganizations$: Observable<BackofficeOrganization[]>;
    selectedTaskFailedOrgs$: Observable<BackofficeOrganization[]>;
    selectedTaskStepsLeft$: Observable<BackofficeEtlTaskStep[]>;
    selectedTaskLog$: Observable<BackofficeEtlTaskEvent[]>;
    selectedTaskNewestEventId$: Observable<number>;

    constructor(private store: Store<AppState>, private backend: DashboardBackofficeService) {
        this.schoolYears$ = this.store.select(selectSchoolYears).pipe(
            distinctUntilChanged(jsonCompare),
            tap(this.doIfNull(() => this.store.dispatch(fetchSchoolYears()))),
            filter(v => v !== null)
        );

        this.organizations$ = this.store.select(selectOrganizations).pipe(
            distinctUntilChanged(jsonCompare),
            tap(this.doIfNull(() => this.store.dispatch(fetchOrganizations()))),
            filter(v => v !== null)
        );

        this.feedback$ = this.store.select(selectFeedback);

        this.syncs$ = this.store.select(selectSyncsCurrentPage);

        this.syncsCurrentPageNumber$ = this.store.select(selectSyncsCurrentPageNumber);

        this.syncsPageSize$ = this.store.select(selectSyncsPageSize);

        this.syncsOrderBy$ = this.store.select(selectSyncsOrderBy);

        this.syncsOrderByDescending$ = this.store.select(selectSyncsDescending);

        this.syncsCachedPages$ = this.store.select(selectSyncsCachedPages);

        this.syncsCount$ = this.store.select(selectSyncsCount);

        this.selectedTask$ = this.store.select(selectSelectedTask);

        this.selectedTaskSchoolYears$ = this.store.select(selectSelectedTaskSchoolYears);

        this.selectedTaskOrganizations$ = this.store.select(selectSelectedTaskOrganizations);
        
        this.selectedTaskFailedOrgs$ = this.store.select(selectSelectedTaskFailedOrgs);
        
        this.selectedTaskStepsLeft$ = this.store.select(selectSelectedTaskStepsLeft);
        
        this.selectedTaskLog$ = this.store.select(selectSelectedTaskEvents);

        this.selectedTaskNewestEventId$ = this.store.select(selectSelectedTaskLatestEventId);

        timer(500, 10000).subscribe(() => this.store.dispatch(taskPollStatus()));
    }

    public saveSchoolYear(schoolYear: BackofficeSchoolYear): void {
        this.store.dispatch(storeSchoolYear({ schoolYear }));
    }

    public saveOrganization(organization: BackofficeOrganization): void {
        this.store.dispatch(saveOrganization({ organization }));
    }

    public startSync(config: SyncConfig): void {
        this.store.dispatch(startSync(config));
    }

    public cleanUpFeedback(): void
    {
        this.store.dispatch(cleanUpFeedback());
    }

    public error(message: string): void {
        this.store.dispatch(addFeedback({feedback: {
            time: moment(),
            nature: 'error',
            message
        }}));
    }

    public warning(message: string): void {
        this.store.dispatch(addFeedback({feedback: {
            time: moment(),
            nature: 'warning',
            message
        }}));
    }

    public info(message: string): void {
        this.store.dispatch(addFeedback({feedback: {
            time: moment(),
            nature: 'info',
            message
        }}));
    }

    private doIfNull(action: () => void): (value: any | null) => void {
        return (value: any | null) => {
            if (value === null)
            {
                action();
            }
        };
    }

    public stop(): void {
        this.store.dispatch(stopSelectedTask());
    }

    public resume(): void {
        this.store.dispatch(resumeSelectedTask());
    }

    public setSyncsPage(page: number): void {
        this.store.dispatch(setSyncsPage({ page }));
    }

    public setSyncsSortColumn(column: string): void {
        this.store.dispatch(setSyncsSortColumn({ column }));
    }

    public selectTask(task: BackofficeEtlTaskExecution): void {
        this.store.dispatch(taskSelected({ task }));
    }
}

function jsonCompare(x: any, y: any): boolean {
    return JSON.stringify(x) === JSON.stringify(y);
}
