import {Injectable} from '@angular/core';
import {AppState} from '../state/app.state';
import {Store} from '@ngrx/store';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {
  selectQueryParams,
  selectTransformFilterToPath
} from '../state/router/router.selectors';
import {payload} from '../state/payload';
import {take} from 'rxjs/operators';
import {UrlObject} from 'query-string';

@Injectable()
export class FilterService {
  constructor(private store: Store<AppState>, private router: Router, private activatedRoute: ActivatedRoute) {
  }

  public setFilterOption(option: object): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: option,
      queryParamsHandling: 'merge'
    });
  }

  public deleteFromFilterOption(option: { key: string, value: string }): void {
    this.store.select(selectQueryParams).pipe(take(1)).subscribe(params => {
      const listOfAllValuesForKey = typeof params[option.key] === 'string' ? [params[option.key]] : params[option.key];
      const newListOfValuesForKey = listOfAllValuesForKey?.filter(el => el !== option.value);

      const queryParams = {};
      queryParams[option.key] = newListOfValuesForKey?.length === 0 ? null : newListOfValuesForKey;

      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams,
        queryParamsHandling: 'merge'
      });
    });
  }

  public addToFilterOption(option: { key: string, value: string }): void {
    this.store.select(selectQueryParams).pipe(take(1)).subscribe(params => {
      const listOfAllValuesForKey = typeof params[option.key] === 'string' ? [params[option.key]] : params[option.key];
      listOfAllValuesForKey?.push(option.value);
      const newListOfValuesForKey = listOfAllValuesForKey ? listOfAllValuesForKey : option.value;

      const queryParams = {};
      queryParams[option.key] = newListOfValuesForKey;

      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams,
        queryParamsHandling: 'merge'
      });
    });
  }

  // Wordt alleen nog gebruikt in oude dashboards.
  // Als nieuwe dashboards klaar zijn kan deze functie worden verwijderd.
  // Let op: Dus ook niet gaan gebruiken voor de nieuwe dashboards!
  public transformFilterToPath(fn: (url: string, path: Params, query: Params) => UrlObject): void {
    this.store.select(selectTransformFilterToPath, payload(fn)).pipe(take(1))
      .subscribe(url => this.router.navigateByUrl(url));
  }
}
