const opleidingen = [
  'Onderbouw',
  'Onderbouw tweetalig',
  'Praktijkonderwijs',
  'VMBO',
  'VMBO Kaderberoepsgerichte leerweg',
  'VMBO Kaderberoepsgerichte leerweg tweetalig',
  'VMBO Basisberoepsgerichte leerweg',
  'VMBO Basisberoepsgerichte leerweg tweetalig',
  'VMBO Kader-/Basisberoepsgerichte leerweg',
  'VMBO Theoretische/Gemengde leerweg',
  'VMBO Theoretische/Gemengde leerweg tweetalig',
  'MBO-VO',
  'HAVO/VMBO Theoretische leerweg',
  'HAVO',
  'HAVO tweetalig',
  'HAVO/VWO',
  'HAVO/VWO-TT',
  'VWO',
  'VWO-TT'
];

export function onderwijssoortComparator<T>(getKey: (v: T) => string): (a: T, b: T) => number {
  return (a, b) => {
    const aKey = getKey(a) || 'Overig';
    const bKey = getKey(b) || 'Overig';
    const aIndex = opleidingen.indexOf(aKey);
    const bIndex = opleidingen.indexOf(bKey);
    if (aIndex === -1 && bIndex === -1) {
      if (aKey === 'Overig') {
        return 1;
      } else if (bKey === 'Overig') {
        return -1;
      } else {
        return aKey.localeCompare(bKey);
      }
    } else if (aIndex === -1) {
      return 1;
    } else if (bIndex === -1) {
      return -1;
    } else {
      return aIndex - bIndex;
    }
  };
}
