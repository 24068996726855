import {createAction, props} from '@ngrx/store';
import {Leermiddel, LeermiddelVakKoppelingData, SelectedLeermiddel} from './leermiddelvakkoppeling.state';
import {Payload} from '../payload';

export const fetchLeermiddelvakkoppeling = createAction('[Leermiddelvakkoppeling] Fetch leermiddelvakkoppeling');
export const setLeermiddelen = createAction('[Leermiddelvakkoppeling] Set leermiddelen', props<Payload<LeermiddelVakKoppelingData>>());
export const setSelectedLeermiddelVakKoppeling = createAction('[Leermiddelvakkoppeling] Set selectedLeermiddelVakKoppeling', props<Payload<SelectedLeermiddel>>());
export const storeLeermiddel = createAction('[Leermiddelvakkoppeling] Store leermiddel', props<Payload<Leermiddel>>());
export const leermiddelStored = createAction('[Leermiddelvakkoppeling] Leermiddel stored', props<Payload<Leermiddel>>());
export const setLeermiddelVakKoppelingOpen = createAction('[LeermiddelVakKoppeling] Open new koppelingscherm', props<Payload<boolean>>());
