import {Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy} from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import {Categorie, Endpoint, Veld} from 'src/app/state/dev-koppelpartij/dev-koppelpartij.state';
import { intrinsicDistinct } from 'src/app/stateless/privacydashboard.functions';
import { InputReadPermissionViewModel } from '../input-read-permission/input-read-permission.component';
import {InputWritePermissionViewModel} from '../input-write-permission/input-write-permission.component';

@Component({
    selector: 'app-input-permission-category',
    templateUrl: './input-permission-category.component.html',
    styleUrls: ['./input-permission-category.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputPermissionCategoryComponent implements OnInit, OnDestroy {
    @Input()
    public viewModel: InputPermissionCategoryViewModel;

    private subscriptions: Subscription[] = [];

    constructor() { }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    public viewModelForVeld(veld: Veld): InputReadPermissionViewModel {
        let vm: InputReadPermissionViewModel = {
            onGetEndpoint: this.viewModel.onGetEndpoint,
            onGetVeld: this.viewModel.onGetCategory.pipe(
                map(c => c.velden.find(v => v.fieldIndex === veld.fieldIndex && v.entityIndex === veld.entityIndex)),
                intrinsicDistinct()
            ),

            onAdditionalPropertyClicked: new Subject(),
            onVeldOptioneelClicked: new Subject(),
            onVeldVerplichtClicked: new Subject()
        }

        this.subscriptions.push(vm.onAdditionalPropertyClicked.pipe(withLatestFrom(vm.onGetVeld)).subscribe(([property, veld]) => this.viewModel.onAdditionalPropertyClicked.next([veld, property])));
        this.subscriptions.push(vm.onVeldOptioneelClicked.pipe(withLatestFrom(vm.onGetVeld)).subscribe(([_, veld]) => this.viewModel.onVeldOptioneelClicked.next(veld)));
        this.subscriptions.push(vm.onVeldVerplichtClicked.pipe(withLatestFrom(vm.onGetVeld)).subscribe(([_, veld]) => this.viewModel.onVeldVerplichtClicked.next(veld)));

        return vm;
    }

  public viewModelForWriteVeld(veld: Veld): InputWritePermissionViewModel {
    let vm: InputWritePermissionViewModel = {
      onGetVeld: this.viewModel.onGetCategory.pipe(
        map(c => c.velden.find(v => v.fieldIndex === veld.fieldIndex && v.entityIndex === veld.entityIndex)),
        intrinsicDistinct()
      ),

      onVeldVerplichtClicked: new Subject()
    }

    this.subscriptions.push(vm.onVeldVerplichtClicked.pipe(withLatestFrom(vm.onGetVeld)).subscribe(([_, veld]) => this.viewModel.onVeldVerplichtClicked.next(veld)));

    return vm;
  }

}

export interface InputPermissionCategoryViewModel {
    onGetEndpoint: Observable<Endpoint>;
    onGetCategory: Observable<Categorie>;

    onAdditionalPropertyClicked: Subject<[Veld, string]>;
    onVeldOptioneelClicked: Subject<Veld>;
    onVeldVerplichtClicked: Subject<Veld>;
}
