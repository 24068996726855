import {createSelector} from '@ngrx/store';
import { backendString, datumbereikSearchEpochSeconds, displayString, endOfPeriodDisplayString } from 'src/app/stateless/datumbereik';
import {selectDashboardSettings} from '../app.state';
import {selectBegindatumParam, selectPeriodeParam, selectVestigingParam} from '../router/router.selectors';

export const selectVestigingen = createSelector(
  selectDashboardSettings,
  (state) => state.vestigingen
);

export const selectGeselecteerdeVestigingUUID = createSelector(
  selectDashboardSettings,
  selectVestigingParam,
  (state, vestigingParam) => vestigingParam != null ? vestigingParam : state.defaultVestiging?.uuid
);

export const selectGeselecteerdeVestiging = createSelector(
  selectDashboardSettings,
  selectVestigingen,
  selectVestigingParam,
  (state, vestigingen, vestigingParam) => {
    const relevanteVestiging = vestigingen?.find(v => v.uuid === vestigingParam);
    return relevanteVestiging ? relevanteVestiging : state.defaultVestiging;
  }
);

export const selectDatumbereiken = createSelector(
  selectDashboardSettings,
  (state) => state.datumbereiken
);

export const selectPeriode = createSelector(
  selectDashboardSettings,
  (state) => state.defaultPeriode
);

export const selectEpochSeconds = createSelector(
    selectDashboardSettings,
    (state) => state.defaultEpochSeconds
);

export const selectDatumbereik = createSelector(
    selectDashboardSettings,
    selectBegindatumParam,
    selectPeriodeParam,
    (state, begindatum, periode) => {
        if (!!begindatum && periode === 'WEEK' || periode === 'MAAND' || periode === 'JAAR') {
            let datumbereik = null;
            const epochSeconds = datumbereikSearchEpochSeconds(begindatum, periode);
            state.datumbereiken?.forEach(d => {
                    if (d.periode === periode && d.epochSeconds <= epochSeconds && (!datumbereik || d.epochSeconds >= datumbereik.epochSeconds)) {
                        datumbereik = d;
                    }
                }
            );
            if (!!datumbereik) {
                return datumbereik;
            }
        }
        return state.datumbereiken?.find(d => d.epochSeconds === state.defaultEpochSeconds && d.periode === state.defaultPeriode);
    }
);

export const selectBegindatumForBackend = createSelector(
    selectBegindatumParam, selectEpochSeconds,
    (param, epochSeconds) => !!param ? param : backendString(epochSeconds)
);

export const selectPeriodeOrDefault = createSelector(
    selectPeriodeParam, selectPeriode,
    (param, periode) => !!param && (param === 'WEEK' || param === 'MAAND' || param === 'JAAR') ? param : periode
);

export const selectBegindatum = createSelector(
    selectDatumbereik,
    (db) => db === null || db === undefined ? null : displayString(db.epochSeconds)
);

export const selectEinddatum = createSelector(
    selectDatumbereik,
    (db) => db === null || db === undefined ? null : endOfPeriodDisplayString(db)
);

