import {Component, Input} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {stringify, stringifyUrl, UrlObject} from 'query-string';
import {SharedVariablesService} from '../services/sharedVariables.service';
import {FilterService} from '../services/filter.service';

@Component({
  selector: 'app-terugknop',
  templateUrl: './terugknop.component.html',
  styleUrls: ['./terugknop.component.scss']
})
export class TerugknopComponent {
  constructor(private filterService: FilterService) {}

  @Input() urlOmNaarTerugTeGaan: (url: string, p: Params, q: Params) => UrlObject;
  @Input() caption: string;

  public goBack(): void {
    this.filterService.transformFilterToPath(this.urlOmNaarTerugTeGaan);
  }
}
