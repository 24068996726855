import {Injectable} from '@angular/core';
import {BaseFacade} from '../base.facade';
import {Store} from '@ngrx/store';
import {AppState} from '../app.state';
import {combineLatest, Observable} from 'rxjs';
import {
  selectBegindatum,
  selectBegindatumForBackend,
  selectDatumbereik,
  selectDatumbereiken,
  selectEinddatum,
  selectEpochSeconds,
  selectGeselecteerdeVestiging,
  selectGeselecteerdeVestigingUUID,
  selectPeriode,
  selectPeriodeOrDefault,
  selectVestigingen
} from './dashboardsettings.selectors';
import {filter, map, take} from 'rxjs/operators';
import {Datumbereik, PERIODE, Vestiging} from './dashboardsettings.state';
import {selectBegindatumParam, selectPeriodeParam, selectQueryParam} from '../router/router.selectors';
import {fetchDatumbereiken, datumbereikChanged, vestigingChanged} from './dashboardsettings.actions';
import {FilterService} from '../../services/filter.service';
import {backendString} from '../../stateless/datumbereik';

@Injectable()
export class DashboardsettingsFacade extends BaseFacade {
  constructor(
    store: Store<AppState>,
    private filterService: FilterService
  ) {
    super(store);
  }

  getAvailableVestigingen(): Observable<Vestiging[]> {
    return this.select(selectVestigingen);
  }

  getSelectedVestiging(): Observable<Vestiging> {
    return this.select(selectGeselecteerdeVestiging);
  }

  setSelectedVestiging(vestiging: Vestiging): void {
    this.dispatch(vestigingChanged, vestiging);
  }

  hasAvailableVestigingen(): Observable<boolean> {
    return this.select(selectVestigingen).pipe(map(vestigingen => vestigingen != null));
  }

  getVestigingUUID(): Observable<string> {
    return this.select(selectGeselecteerdeVestigingUUID);
  }

  areSettingsLoaded(): Observable<boolean> {
    return combineLatest([this.select(selectGeselecteerdeVestigingUUID), this.getDatumbereiken()]).pipe(
      map(([geselecteerdeVestiging, datumbereiken]) => geselecteerdeVestiging != null && datumbereiken !== null)
    );
  }

  getDatumbereiken(): Observable<Datumbereik[]> {
    return this.select(selectDatumbereiken);
  }

  getEpochSeconds(): Observable<number> {
      return this.select(selectEpochSeconds);
  }

  getDatumbereik(): Observable<Datumbereik> {
      return this.select(selectDatumbereik).pipe(filter(db => !!db));
  }

  getBegindatum(): Observable<string> {
      return this.select(selectBegindatum);
  }

  getEinddatum(): Observable<string> {
      return this.select(selectEinddatum);
  }

  getBegindatumParam(): Observable<string> {
      return this.select(selectBegindatumParam);
  }

  getBegindatumForBackend(): Observable<string> {
      return this.select(selectBegindatumForBackend);
  }

  getDefaultPeriode(): Observable<PERIODE> {
    return this.select(selectPeriode);
  }

  getPeriodeParam(): Observable<string> {
      return this.select(selectPeriodeParam);
  }

  getPeriodeOrDefault(): Observable<PERIODE> {
      return this.select(selectPeriodeOrDefault);
  }

  isGegroepeerdOpVak(): Observable<boolean> {
    return this.select(selectQueryParam('groeperenOpVak')).pipe(map(value => value !== 'false'));
  }

  isGegroepeerdOpOnderwijssoort(): Observable<boolean> {
    return this.select(selectQueryParam('groeperenOpVak')).pipe(map(value => value === 'false'));
  }

  toggleGroepering(): void {
    this.isGegroepeerdOpVak().pipe(take(1)).subscribe(curGegroepeerdOpVak => {
      this.filterService.setFilterOption({groeperenOpVak: !curGegroepeerdOpVak});
    });
  }

  setDatumbereik(datumbereik: Datumbereik): void {
    this.filterService.setFilterOption({begindatum: backendString(datumbereik.epochSeconds), periode: datumbereik.periode});
    this.dispatch0(datumbereikChanged);
  }

  fetchDatumbereiken(vestiging: Vestiging): void {
    this.dispatch(fetchDatumbereiken, vestiging);
  }

}
