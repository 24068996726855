import {Component, ComponentFactoryResolver, Input, OnInit, ViewChild} from '@angular/core';
import {CellDef} from '../table/table.model';
import {ContentHostDirective} from '../../layout/directives/content-host.directive';

@Component({
  selector: 'app-cell-content',
  templateUrl: './cell-content.component.html',
  styleUrls: ['./cell-content.component.scss']
})
export class CellContentComponent<M, T> implements OnInit {
  @Input()
  model: M;

  @Input()
  cellDef: CellDef<M, T>;

  @ViewChild(ContentHostDirective, {static: true})
  contentHost: ContentHostDirective;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

  ngOnInit() {
    const factory = this.componentFactoryResolver.resolveComponentFactory(this.cellDef.component);
    const viewContainerRef = this.contentHost.viewContainerRef;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent(factory);
    componentRef.instance.data = this.cellDef.getValue(this.model);
  }
}
