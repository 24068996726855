import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {setMe} from '../state/me/me.actions';
import {filter, map, mapTo, switchMap, tap} from 'rxjs/operators';
import {alt, chain, fold, fromNullable, getOrElse, map as mapOption, tryCatch} from 'fp-ts/Option';
import {RMedewerker, SomtodayService} from '../services/somtoday.service';
import {payload} from '../state/payload';
import {identity, Observable, of} from 'rxjs';
import {OAuthService} from 'angular-oauth2-oidc';
import {constNull, pipe} from 'fp-ts/function';
import {Store} from '@ngrx/store';
import {AppState} from '../state/app.state';
import {magGeenEnkelDashboardInzien} from '../state/me/me.selectors';
import {reportError} from '../state/errors/errors.actions';
import {HttpErrorResponse} from '@angular/common/http';
import {appInit} from '../state/app.actions';
import {getItemFromSessionStorage, setItemInSessionStorage} from './storage.fn';

@Injectable()
export class MeEffect
{
  $1 = createEffect(() => this.oauthService.events.pipe(
    filter(({type}) => type === 'token_received'),
    map(loadMe),
    map(mapOption<RMedewerker, Observable<RMedewerker>>(of)),
    map(alt(() => tryCatch(() => this.somtodayService.fetchMe().pipe(tap(setItemInSessionStorage('me')))))),
    switchMap(fold(() => of(null), identity)),
    map(payload),
    map(setMe)
  ));

  $2 = createEffect(() => this.actions$.pipe(
    ofType(appInit),
    map(loadMe),
    map(getOrElse(constNull)),
    map(payload),
    map(setMe)
  ));

  $3 = createEffect(() => this.store.select(magGeenEnkelDashboardInzien).pipe(
    filter(identity),
    mapTo({networkError: new HttpErrorResponse({status: 401}) as Error}),
    map(payload),
    map(reportError)
  ));

  constructor(private oauthService: OAuthService,
              private somtodayService: SomtodayService,
              private actions$: Actions,
              private store: Store<AppState>) {
  }
}

const loadMe = getItemFromSessionStorage('me');


