import {RangeModel, rangeModelBuilder} from '../../layout/model/range.model';
import {RangeLinechartModel} from '../../layout/range-linechart/range-linechart.model';
import {createIntervallen, Periode, periodeToAantalDagen} from '../../services/datumbereik';
import {onderwijssoortComparator} from '../../services/onderwijssoort.comparator';
import {
  LesgroepOverstijgendDagGebruik,
  OpleidingVakLesgroepDagGebruik,
  VakloosLeermiddelOpleidingDagGebruik
} from '../../../generated/graphql';
import {deelVeilig} from '../../services/veilig-delen';
import {encode} from '../../services/url.encode.service';

export interface ParentKey {
  path: string;
  parent?: string;
  ean?: string;
  key: string;
}

export interface SchooldashboardDetailModel {
  parent: ParentKey;
  key: string;
  aantal: number;
  activatie: RangeModel;
  actief: RangeModel;
  gebruik: RangeLinechartModel[];
  totaal: RangeModel;
}

interface ActiviteitPerGroepering {
  groepering: string;
  percentage: number;
}

interface GebruikPerGroepering {
  groepering: string;
  dagen: {
    dag: Date,
    aantal: number
  }[];
}

interface DetailAccumulatorValue {
  parent: ParentKey;
  aantal: number;
  groeperingen: string[];
  geactiveerd: ActiviteitPerGroepering[];
  actief: ActiviteitPerGroepering[];
  leerlingen: number;
  gebruik: GebruikPerGroepering[];
  totaal: TotaalGebruik[];
}

interface TotaalGebruik {
  groepering: string;
  actieveLeerlingen: number;
  gebruik: number;
}

export interface DetailAccumulatorData {
  [key: string]: DetailAccumulatorValue;
}

export function createOnderwijssoortDetails(
  onderwijssoort: string, data: OpleidingVakLesgroepDagGebruik[],
  lesgroepoverstijgendData: LesgroepOverstijgendDagGebruik[],
  periode: Periode,
  datum?: Date
): SchooldashboardDetailModel[] {
  const accumulated = data.filter(v => v.onderwijssoort === onderwijssoort)
    .reduce((a, v) => reduceDetails(
      o => ({path: `/vakdashboard/${encode(o.vak)}/${encode(onderwijssoort)}`, parent: onderwijssoort, key: o.vak}), a, v
    ), {} as DetailAccumulatorData);
  return toDashboardDetails(accumulated, periode, datum);
}

export function createVakDetails(
  vak: string, data: OpleidingVakLesgroepDagGebruik[], periode: Periode, datum?: Date): SchooldashboardDetailModel[] {
  const accumulated = data.filter(v => v.vak === vak)
    .reduce((a, v) => reduceDetails(
      o => ({
        path: `/vakdashboard/${encode(vak)}/${encode(o.onderwijssoort)}`,
        parent: vak, key: o.onderwijssoort
      }), a, v
    ), {} as DetailAccumulatorData);
  return toDashboardDetails(accumulated, periode, datum);
}

export function createVakloosDetails(
  data: VakloosLeermiddelOpleidingDagGebruik[],
  periode: Periode,
  datum?: Date
): SchooldashboardDetailModel[] {
  const accumulated = data.reduce((a, v) => reduceVakoverstijgendDetails(
    o => ({path: `/vakoverstijgenddashboard/${encode(o.ean)}`, ean: o.ean, key: o.titel}), a, v
  ), {} as DetailAccumulatorData);
  return toDashboardDetails(accumulated, periode, datum);
}

export function createLandelijkVakDetails(
    data: LesgroepOverstijgendDagGebruik[], periode: Periode, datum?: Date): SchooldashboardDetailModel[] {
  const accumulated = data.reduce((a, v) => reduceLesgroepoverstijgendDetails(
      o => ({path: null, ean: o.ean, key: o.displayName}), a, v, false
  ), {} as DetailAccumulatorData);
  return toDashboardDetails(accumulated, periode, datum);
}

export function toDashboardDetails(data: DetailAccumulatorData, periode: Periode, date?: Date): SchooldashboardDetailModel[] {
  return Object.entries(data).reduce(
    (accumulator, [key, value]: [string, DetailAccumulatorValue]) => {
      const actief: RangeModel = toRangeModel(value.actief);
      const activatie: RangeModel = toRangeModel(value.geactiveerd);
      const gebruik: RangeLinechartModel[] = toRangeLineModel(value.gebruik, periode, date);
      const dagen = periodeToAantalDagen(periode, date);
      const totaal = rangeModelBuilder(1);
      value.totaal.forEach(t => {
        const totaalPerGroepering = t.actieveLeerlingen > 0 ? 7 * t.gebruik / (t.actieveLeerlingen * dagen) : 0;
        totaal.update(totaalPerGroepering);
      });
      accumulator.push({
        key,
        parent: value.parent,
        aantal: value.aantal,
        activatie,
        actief,
        gebruik,
        totaal: totaal.build()
      });
      return accumulator;
    }, [] as SchooldashboardDetailModel[]).sort(onderwijssoortComparator(v => v.key));
}

function toRangeLineModel(gebruikPerGroepering: GebruikPerGroepering[], periode: Periode, date?: Date): RangeLinechartModel[] {
  const intervallen = createIntervallen(periode, date);
  // Per lesgroep in een interval
  const gebruikPerInterval = gebruikPerGroepering.map(g =>
    intervallen.map(i => g.dagen.filter(({dag}) => i.van <= dag && i.tot > dag)
      .reduce((a, v) => {
        a.aantal += v.aantal;
        return a;
      }, {aantal: 0, dag: i.van})));
  // Bepaal min, avg en max per interval
  const models: RangeLinechartModel[] = [];
  // tslint:disable-next-line:prefer-for-of
  for (let kolom = 0; kolom < intervallen.length; kolom++) {
    const model: RangeLinechartModel = {
      date: intervallen[kolom].van,
      values: {min: undefined, avg: 0, max: 0}
    };
    // tslint:disable-next-line:prefer-for-of
    for (let rij = 0; rij < gebruikPerGroepering.length; rij++) {
      const value = gebruikPerInterval[rij][kolom].aantal;
      if (model.values.min === undefined || value < model.values.min) {
        model.values.min = value;
      }
      model.values.avg += value;
      if (value > model.values.max) {
        model.values.max = value;
      }
    }
    model.values.avg /= gebruikPerGroepering.length;
    models.push(model);
  }
  return models;
}

function toRangeModel(activiteitPerGroepering: { groepering: string, percentage: number }[]): RangeModel {
  const percentages = activiteitPerGroepering.map(l => l.percentage);
  let avg = 0;
  let min = 0;
  let max = 0;
  if (percentages !== undefined && percentages.length > 0) {
    avg = percentages.reduce((a, b) => a + b) / percentages.length;
    [min] = percentages.sort((a, b) => a - b);
    max = percentages[percentages.length - 1];
  }
  return ({min, max, avg, tot: 1});
}

export function reduceDetails(
  keyExtractor: (v: OpleidingVakLesgroepDagGebruik) => ParentKey,
  a: DetailAccumulatorData, v: OpleidingVakLesgroepDagGebruik
): DetailAccumulatorData {
  const parent = keyExtractor(v);
  if (a[parent.key] !== undefined) {
    const entry = a[parent.key];
    if (!entry.groeperingen.includes(v.lesgroepUuid)) {
      entry.groeperingen.push(v.lesgroepUuid);
      entry.aantal += 1;
      entry.leerlingen += v.leerlingenInLesgroep;
      entry.actief.push({
        groepering: v.lesgroepUuid,
        percentage: deelVeilig(v.actieveLeerlingen, v.leerlingenGeactiveerd)
      });
      entry.geactiveerd.push({
        groepering: v.lesgroepUuid,
        percentage: deelVeilig(v.leerlingenGeactiveerd, v.leerlingenInLesgroep)
      });
      entry.gebruik.push({groepering: v.lesgroepUuid, dagen: [{dag: v.dag, aantal: deelVeilig(v.daggebruik, v.actieveLeerlingen)}]});
      entry.totaal.push({groepering: v.lesgroepUuid, actieveLeerlingen: v.actieveLeerlingen, gebruik: v.daggebruik});
    } else {
      const gebruik = entry.gebruik.find(g => g.groepering === v.lesgroepUuid);
      const dag = gebruik.dagen.find(d => d === v.dag);
      if (dag === undefined) {
        gebruik.dagen.push({dag: v.dag, aantal: deelVeilig(v.daggebruik, v.actieveLeerlingen)});
      } else {
        dag.aantal += deelVeilig(v.daggebruik, v.actieveLeerlingen);
      }
      const totaal = entry.totaal.find(t => t.groepering === v.lesgroepUuid);
      totaal.gebruik += v.daggebruik;
    }
  } else {
    a[parent.key] = {
      parent,
      aantal: 1,
      groeperingen: [v.lesgroepUuid],
      gebruik: [{groepering: v.lesgroepUuid, dagen: [{dag: v.dag, aantal: v.daggebruik}]}],
      leerlingen: v.leerlingenInLesgroep,
      actief: [{
        groepering: v.lesgroepUuid,
        percentage: deelVeilig(v.actieveLeerlingen, v.leerlingenGeactiveerd)
      }],
      geactiveerd: [{
        groepering: v.lesgroepUuid,
        percentage: deelVeilig(v.leerlingenGeactiveerd, v.leerlingenInLesgroep)
      }],
      totaal: [{groepering: v.lesgroepUuid, actieveLeerlingen: v.actieveLeerlingen, gebruik: deelVeilig(v.daggebruik, v.actieveLeerlingen)}]
    };
  }
  return a;
}

function reduceVakoverstijgendDetails(
  keyExtractor: (v: VakloosLeermiddelOpleidingDagGebruik) => ParentKey,
  a: DetailAccumulatorData, v: VakloosLeermiddelOpleidingDagGebruik
): DetailAccumulatorData {
  const groepering = v.titel.concat(v.onderwijssoort).concat(String(v.leerjaar));
  const parent = keyExtractor(v);
  if (a[parent.key] !== undefined) {
    const entry = a[parent.key];
    if (!entry.groeperingen.includes(groepering)) {
      entry.groeperingen.push(groepering);
      entry.aantal += 1;
      entry.leerlingen += v.leerlingen;
      entry.actief.push({groepering, percentage: deelVeilig(v.actieveLeerlingen, v.leerlingenGeactiveerd)});
      entry.geactiveerd.push({groepering, percentage: deelVeilig(v.leerlingenGeactiveerd, v.leerlingen)});
      entry.gebruik.push({groepering, dagen: [{dag: v.dag, aantal: v.daggebruik}]});
      entry.totaal.push({groepering, actieveLeerlingen: v.actieveLeerlingen, gebruik: v.daggebruik});
    } else {
      const gebruik = entry.gebruik.find(g => g.groepering === groepering);
      const dag = gebruik.dagen.find(d => d === v.dag);
      if (dag === undefined) {
        gebruik.dagen.push({dag: v.dag, aantal: v.daggebruik});
      } else {
        dag.aantal += v.daggebruik;
      }
      const totaal = entry.totaal.find(t => t.groepering === groepering);
      totaal.gebruik += v.daggebruik;
    }
  } else {
    a[parent.key] = {
      parent,
      aantal: 1,
      groeperingen: [groepering],
      gebruik: [{groepering, dagen: [{dag: v.dag, aantal: v.daggebruik}]}],
      leerlingen: v.leerlingen,
      actief: [{groepering, percentage: deelVeilig(v.actieveLeerlingen, v.leerlingenGeactiveerd)}],
      geactiveerd: [{groepering, percentage: deelVeilig(v.leerlingenGeactiveerd, v.leerlingen)}],
      totaal: [{groepering, actieveLeerlingen: v.actieveLeerlingen, gebruik: v.daggebruik}]
    };
  }
  return a;
}

function reduceLesgroepoverstijgendDetails( keyExtractor: (v: LesgroepOverstijgendDagGebruik) => ParentKey,
                                            a: DetailAccumulatorData, v: LesgroepOverstijgendDagGebruik,
                                            groupByOnderwijssoort: boolean
): DetailAccumulatorData {
  const groepering = v.displayName.concat(v.onderwijssoortAfkorting).concat(String(v.leerjaar));
  const parent = keyExtractor(v);
  if (a[parent.key] !== undefined) {
    const entry = a[parent.key];
    if (!entry.groeperingen.includes(groepering)) {
      entry.groeperingen.push(groepering);
      if (!groupByOnderwijssoort) {
        entry.aantal += v.aantalLeerlingen;
      }
      entry.leerlingen += v.aantalLeerlingen;
      entry.actief.push({groepering, percentage: deelVeilig(v.aantalActieveGebruikers, v.aantalGeactiveerd)});
      entry.geactiveerd.push({groepering, percentage: deelVeilig(v.aantalGeactiveerd, v.aantalLeerlingen)});
      entry.gebruik.push({groepering, dagen: [{dag: v.dag, aantal: v.gebruikVandaag}]});
      entry.totaal.push({groepering, actieveLeerlingen: v.aantalActieveGebruikers, gebruik: v.gebruikVandaag});
    } else {
      const gebruik = entry.gebruik.find(g => g.groepering === groepering);
      const dag = gebruik.dagen.find(d => d === v.dag);
      if (dag === undefined) {
        gebruik.dagen.push({dag: v.dag, aantal: v.gebruikVandaag});
      } else {
        dag.aantal += v.gebruikVandaag;
      }
      const totaal = entry.totaal.find(t => t.groepering === groepering);
      totaal.gebruik += v.gebruikVandaag;
    }
  } else {
    a[parent.key] = {
      parent,
      aantal: groupByOnderwijssoort ? null : v.aantalLeerlingen,
      groeperingen: [groepering],
      gebruik: [{groepering, dagen: [{dag: v.dag, aantal: v.gebruikVandaag}]}],
      leerlingen: v.aantalLeerlingen,
      actief: [{groepering, percentage: deelVeilig(v.aantalActieveGebruikers, v.aantalGeactiveerd)}],
      geactiveerd: [{groepering, percentage: deelVeilig(v.aantalGeactiveerd, v.aantalLeerlingen)}],
      totaal: [{groepering, actieveLeerlingen: v.aantalActieveGebruikers, gebruik: v.gebruikVandaag}]
    };
  }
  return a;
}
