export interface WhatsNewUpdate {
  id: number;
  titel: string;
  text: string;
  unread?: boolean;
}

/***
 *    STAPPENPLAN UPDATES TOEVOEGEN:
 *
 * 1. update object toevoegen aan de updates array
 * 2. latestDevUpdateId updaten naar het laatste id
 */
export const latestDevUpdateId = 6;

export const whatsnewUpdates: WhatsNewUpdate[] = [
  {
    id: 7,
    titel: 'Koppeling bewerken',
    text: 'Naast het aanmaken en inzien van een koppeling is het nu ook mogelijk om koppelingen via het Privacydashboard te bewerken. ' + 
      'Hiervoor ga je naar de pagina van de koppelpartij waarmee je deze koppeling(en) hebt opengezet. Vervolgens klik je op \'Beheer koppeling\' rechts boven in de pagina. ' +
      'Onder dit menu vind je de opties om ofwel de koppeling aan te passen, of deels te verwijderen.'
  },
  {
    id: 6,
    titel: 'Overzicht koppelpartijen',
    text: 'Onze Connect-koppelpartners zijn nu beter zichtbaar. Binnen de AVG-module is er onder de menu-knop \'Aanbod producten\' ' +
      '     een pagina bijgekomen waar per koppelpartij een duidelijke omschrijving staat. Ook is er duidelijk vermeld tot welke data deze partij toegang heeft. ' +
       '     <br/><br/>' +
      '<b>Wil je koppelen met een koppelpartij?</b><br/><br/>' +
      '<ul class="whatsnew">' +
      ' <li> Klik op een externe aanbieder  </li>' +
      ' <li> Klik rechtsboven op koppelen </li>' +
      ' <li> Doorloop het koppelproces  </li></ul>'
  },
  {
    id: 5,
    titel: 'Inzicht in webservices',
    text: 'Binnen de AVG-module is het inzicht in webservices toegevoegd. Onder het kopje datatoegang is een ' +
      'verzameling zichtbaar van de accounts die toegang hebben tot de WIS, UM, HRM, Cup, Stage en UWLR service. ' +
      '\n' +
      '<p>Als je een service aanklikt opent er een pagina waar meer informatie staat over de specifieke accounts en hoe vaak er met die accounts data wordt opgehaald. ' +
      'We houden voor de meeste services sinds eind september bij wanneer er data wordt opgehaald. ' +
      'Daarnaast is ook per service een omschrijving toegevoegd met informatie over de betreffende service.</p>' +
      '\n' +
      '<img class="gifje" src="../../assets/img/updates/webservices.gif" width="100%" height="auto"/>'
  },
  {
    id: 4,
    titel: 'Koppeling inzien',
    text: 'Op het Privacydashboard kan je vanaf nu op een koppeling klikken om een overzicht te krijgen van de instellingen en het gebruik van de koppeling.'
    + '\n'
    + '<p>Voor koppelingen die voor meerdere vestigingen zijn ingericht wordt een samenvatting getoond.</p>'
    + '\n'
    + '<img class="gifje" src="../../assets/img/updates/koppelingeninzien.gif" width="100%" height="auto"/>'
  },
  {
    id: 3,
    titel: 'Privacydashboard',
    text: 'Sinds deze zomer staat de AVG-module live! Je kan in Somtoday Connect in het privacydashboard AVG-proof een koppeling aanmaken met (tot nu toe) één van de volgende vijf koppelpartijen:'
    + '\n'
    + '<p>- Nationale Onderwijs Monitors</p>'
    + '<p>- Edulife</p>'
    + '<p>- XIMEx</p>'
    + '<p>- Numo</p>'
    + '<p>- Diatoetsen</p>'
  },
  {
    id: 2,
    titel: 'Leermiddel-vak koppeling',
    text: 'Maakt jouw school gebruik van een extern leermiddelenfonds (ELF), dan kan het nodig zijn om zelf leermiddelen aan vakken te koppelen voordat ze goed getoond kunnen worden. \n' +
      '\n' +
      '<p>Via de instellingen, te vinden achter het tandwiel-icoon, kun je de leermiddelen aan een schoolvak koppelen. Je kunt leermiddelen ook aan meerdere vakken tegelijk koppelen. </p>\n' +
      '\n' +
      '<img class="gifje" src="../../assets/img/updates/leermiddelvakkoppeling.gif" width="100%" height="auto"/>' +
      '\n' +
      '<p>Als er vestigingsvakken zijn gekoppeld aan landelijke vakken, hebben wij de leermiddelen alvast aan die vakken gekoppeld.</p>' +
      'Houd er rekening mee dat het één dag duurt voordat Somtoday Connect deze wijzigingen doorvoert in de rapportages.\n'
  }];


