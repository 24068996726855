import {Component, OnInit, ChangeDetectionStrategy, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {KoppelingMenuItem, KoppelingMenuViewModel} from '../layout/koppeling-menu/koppeling-menu.component';
import {KoppelingHeaderButton, KoppelingHeaderViewModel} from '../layout/koppeling-header/koppeling-header.component';
import {PrivacydashboardFacade} from '../state/privacydashboard/privacydashboard.facade';
import {Store} from '@ngrx/store';
import {AppState} from '../state/app.state';
import {
  selectGeselecteerdeKoppelpartij,
} from '../state/privacydashboard/privacydashboard.selectors';
import {filter, map} from 'rxjs/operators';
import {
  setGeselecteerdeKoppelpartij,
} from '../state/privacydashboard/privacydashboard.actions';
import {saveKoppeling, deleteKoppeling} from '../state/avgdashboard-preview/avgdashboard-preview.actions';
import {AVGExtendedKoppelpartij} from '../dto/avg-dashboard-model-classes';
import {GoBackHeaderViewModel} from '../layout/go-back-header/go-back-header.component';

@Component({
  selector: 'app-koppelpartij-details-preview-page',
  templateUrl: './koppelpartij-details-preview-page.component.html',
  styleUrls: ['./koppelpartij-details-preview-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KoppelpartijDetailsPreviewPageComponent implements OnInit, OnDestroy {

  private subscriptions = [];

  public goBackHeaderViewModel: GoBackHeaderViewModel;
  public menuViewModel: KoppelingMenuViewModel;
  private menuItemOmschrijving: KoppelingMenuItem = {caption: 'Omschrijving', key: 'omschrijving'};
  private menuItemDatatoegang: KoppelingMenuItem = {caption: 'Datatoegang', key: 'datatoegang'};

  public koppelpartij: Observable<AVGExtendedKoppelpartij>;

  public verwijderKoppeling: { uuid: string, koppelpartijNaam: string };
  public toevoegKoppeling: { uuid: string, koppelpartijNaam: string };

  public akkoordGegeven: boolean = false;
  public datatoegangClicked: boolean = false;
  public showVoorwaarden: boolean = false;

  public loading = this.store.selectLoading();

  public headerViewModel: KoppelingHeaderViewModel = {
    img: new Observable<string>(),
    title: new Observable<string>(),
    subTitle: new Observable<string>(),

    button: new Observable<string>(),
    buttonClass: new Observable<string>(),
    buttonIcon: new Observable<string>(),
    onButtonClick: () => this.onButtonclick(),

    menu: new Observable<KoppelingHeaderButton[]>(),
    showMenu: new BehaviorSubject<boolean>(false),
  };

  private hasKoppelingen: boolean;
  private currentKoppelpartij: AVGExtendedKoppelpartij;

  constructor(private store: PrivacydashboardFacade, private appState: Store<AppState>) { }

  ngOnInit(): void {
    this.loadGoBackHeaderViewModel();
    this.loadMenuViewModel();
    this.loadHeaderViewModel();
  }

  loadGoBackHeaderViewModel(): void {
    this.goBackHeaderViewModel = {
      title: null,
      backUrl: '/privacydashboard-preview',
      backTitle: 'Terug naar overzicht'
    };
  }

  loadMenuViewModel(): void {
    const activeMenuKey: BehaviorSubject<string> = new BehaviorSubject(this.menuItemOmschrijving.key);
    const menuItems: BehaviorSubject<KoppelingMenuItem[]> = new BehaviorSubject<KoppelingMenuItem[]>([this.menuItemOmschrijving, this.menuItemDatatoegang]);

    this.menuViewModel = {
      onGetMenuItems: menuItems,
      onGetActiveKey: of(this.menuItemOmschrijving.key),

      doSetMenuItems: menuItems,
      doSetActiveKey: activeMenuKey
    };

    this.subscriptions.push(this.menuViewModel.doSetActiveKey.pipe(
      filter(key => key === this.menuItemDatatoegang.key)
    ).subscribe(_ =>
      this.showDatatoegangPopup()
    ));
  }

  loadHeaderViewModel(): void {
    this.koppelpartij = this.appState.select(selectGeselecteerdeKoppelpartij);
    const hasKoppelingen = this.koppelpartij.pipe(map(k => k?.koppelingen?.length > 0));

    this.subscriptions.push(hasKoppelingen.subscribe(hask => this.hasKoppelingen = hask));
    this.subscriptions.push(this.koppelpartij.subscribe(koppelpartij => this.currentKoppelpartij = koppelpartij));

    this.headerViewModel.img = this.koppelpartij.pipe(map(k => k?.koppelpartijLogoUrl ? k?.koppelpartijLogoUrl : 'assets/img/icons/Symbool/Koppelpartij_icon_placeholder.svg'));
    this.headerViewModel.title = this.koppelpartij.pipe(map(k => k?.koppelpartijNaam));
    this.headerViewModel.subTitle = this.koppelpartij.pipe(map(k => k?.koppelpartijBedrijfsnaam));
    this.headerViewModel.button = hasKoppelingen.pipe(map(k => k ? 'Ontkoppelen' : 'Koppelen'));
    this.headerViewModel.buttonClass = hasKoppelingen.pipe(map(k => k ? 'red' : 'green'));
  }

  private onButtonclick(): void {
      this.hasKoppelingen ? this.deleteKoppeling(
        this.currentKoppelpartij.uuid, 
        this.currentKoppelpartij.koppelpartijNaam) 
      : this.addKoppeling(
        this.currentKoppelpartij.uuid, 
        this.currentKoppelpartij.koppelpartijNaam
      );
  }

  private deleteKoppeling(uuid: string, koppelpartijNaam: string): void {
    this.verwijderKoppeling = { uuid, koppelpartijNaam};
  }

  public cancelDeleteKoppeling(): void {
    this.verwijderKoppeling = null;
  }

  public continueDeleteKoppeling(): void {
    this.appState.dispatch(deleteKoppeling({koppelpartij: { uuid: this.verwijderKoppeling.uuid, naam: this.verwijderKoppeling.koppelpartijNaam}}));
    this.verwijderKoppeling = null;
  }

  private addKoppeling(uuid: string, koppelpartijNaam: string): void {
    this.toevoegKoppeling = {uuid, koppelpartijNaam};
  }

  public cancelAddKoppeling(): void {
    this.toevoegKoppeling = null;
  }

  public continueAddKoppeling(): void {
    if (this.akkoordGegeven) {
      this.appState.dispatch(saveKoppeling({koppelpartij: {uuid: this.toevoegKoppeling.uuid, naam: this.toevoegKoppeling.koppelpartijNaam}}));
      this.toevoegKoppeling = null;
      this.akkoordGegeven = false;
    }
  }

  private showDatatoegangPopup(): void {
    this.datatoegangClicked = true;
  }

  public closeDatatoegangPopup(): void {
    this.datatoegangClicked = false;
  }

  public toggleCheckbox(): void {
    this.akkoordGegeven = !this.akkoordGegeven;
  }

  public toggleVoorwaarden(): void {
    this.showVoorwaarden = !this.showVoorwaarden;
  }

  public checkboxClass(): string {
    return this.akkoordGegeven ? 'svg_icon-checkbox checked' : 'svg_icon-checkbox unchecked';
  }

  public buttonClass(): string {
    return this.akkoordGegeven ? '' : 'button--disabled';
  }

  public arrowClass(): string {
    return this.showVoorwaarden ? 'open' : 'closed';
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.appState.dispatch(setGeselecteerdeKoppelpartij({koppelpartij: null}));
  }
}
