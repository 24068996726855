import {createSelector} from '@ngrx/store';
import {selectSchooldashboard} from '../../../state/app.state';

export const selectLoadDocenten = createSelector(
  selectSchooldashboard,
  ({loadDocenten}) => loadDocenten
);

export const selectDocenten = createSelector(
  selectSchooldashboard,
  ({docenten}) => docenten
);
