import {createSelector} from '@ngrx/store';
import { sort } from 'src/app/stateless/string';
import {selectSchooldashboardNew, selectVakdashboard} from '../app.state';

export const selectDocentUUIDs = createSelector(
  selectVakdashboard,
  (state) => state.regels.map(r => r.docentUUIDs).reduce((x, y) => x.concat(y), [])
);

export const selectVak = createSelector(
  selectVakdashboard,
  (state) => state.vak
);

export const selectOnderwijssoort = createSelector(
  selectVakdashboard,
  (state) => state.onderwijssoort
);

export const selectNextOWS = createSelector(
  selectVakdashboard,
  selectSchooldashboardNew,
  (vakdashboard, schooldashboard) => {
    const content = schooldashboard.vakken.find(vak => vak.header.naam === vakdashboard.vak)?.content;
    if (content !== undefined && content !== null) {
      const indexOfCurrentOWS = content.indexOf(content.find(c => c.naam === vakdashboard.onderwijssoort));
      return (indexOfCurrentOWS + 1 === content.length) ? content[0] : content[indexOfCurrentOWS + 1];
    } else {
      return null;
    }
  }
);

export const selectPrevOWS = createSelector(
  selectVakdashboard,
  selectSchooldashboardNew,
  (vakdashboard, schooldashboard) => {
    const content = schooldashboard.vakken.find(vak => vak.header.naam === vakdashboard.vak)?.content;
    if (content !== undefined && content !== null) {
      const indexOfCurrentOWS = content.indexOf(content.find(c => c.naam === vakdashboard.onderwijssoort));
      return (indexOfCurrentOWS === 0) ? content[content.length - 1] : content[indexOfCurrentOWS - 1];
    } else {
      return null;
    }
  }
);

export const selectNextVak = createSelector(
  selectVakdashboard,
  selectSchooldashboardNew,
  (vakdashboard, schooldashboard) => {
    const content = schooldashboard.onderwijssoorten.find(os => os.header.naam === vakdashboard.onderwijssoort)?.content;
    if (content !== undefined && content !== null) {
      const indexOfCurrentVak = content.indexOf(content.find(c => c.naam === vakdashboard.vak));
      return (indexOfCurrentVak + 1 === content.length) ? content[0] : content[indexOfCurrentVak + 1];
    } else {
      return null;
    }
  }
);

export const selectPrevVak = createSelector(
  selectVakdashboard,
  selectSchooldashboardNew,
  (vakdashboard, schooldashboard) => {
    const content = schooldashboard.onderwijssoorten.find(os => os.header.naam === vakdashboard.onderwijssoort)?.content;
    if (content !== undefined && content !== null) {
      const indexOfCurrentVak = content.indexOf(content.find(c => c.naam === vakdashboard.vak));
      return (indexOfCurrentVak === 0) ? content[content.length - 1] : content[indexOfCurrentVak - 1];
    } else {
      return null;
    }
  }
);

export const selectRows = createSelector(
  selectVakdashboard,
  (state) => sort(state.regels, (r) => r.lesgroepNaam)
);

export const selectTotaalRegel = createSelector(
  selectVakdashboard,
  (state) => state.totaalRegel
);

export const selectLesgroepUUIDs = createSelector(
  selectVakdashboard,
  (state) => sort(state.regels, (r) => r.lesgroepNaam).map(r => r.lesgroepUUID)
);
