import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import {OauthConfigurationService} from '../services/oauth.configuration.service';
import {stringify} from 'query-string';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private oAuthService: OAuthService,
              private router: Router,
              private oauthConfigService: OauthConfigurationService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | UrlTree {
    if (this.oAuthService.hasValidAccessToken()) {
      return true;
    } else {
      if (next.url) {
        let uri = next.url.join('/');
        if (Object.entries(next.queryParams).length > 0) {
          uri += `?${stringify(next.queryParams)}`;
        }
        sessionStorage.setItem('sc-preauth-uri', uri);
      }
      // eerst wachten op configloaded, anders werkt het niet bij bookmarked URI's, maar alleen bij clicks in app.
      this.oauthConfigService.configLoaded.subscribe(configLoaded => {
        if (configLoaded) {
          this.oAuthService.initCodeFlow();
        }
      });
      return false;
    }
  }
}
