import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import {StudiewijzerlinkjesNewFacade} from '../../state/studiewijzerlinkjes/studiewijzerlinkjes.facade';

@Component({
  selector: 'app-studiewijzerlinkjes-table',
  templateUrl: './studiewijzerlinkjes-table.component.html',
  styleUrls: ['./studiewijzerlinkjes-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudiewijzerlinkjesTableComponent implements OnInit {

  constructor(store: StudiewijzerlinkjesNewFacade) { }

  ngOnInit(): void {
  }

}
