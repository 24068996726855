import {flow, pipe} from 'fp-ts/function';
import {chain, fromNullable, tryCatch} from 'fp-ts/Option';

export const getItemFromSessionStorage = (key: string) => () => pipe(
  fromNullable(sessionStorage.getItem(key)),
  chain(me => tryCatch(() => JSON.parse(me)))
);

export const setItemInSessionStorage = (key: string) => flow(
  (value: any) => JSON.stringify(value),
  value => sessionStorage.setItem(key, value)
);
