import {createAction, props} from '@ngrx/store';
import {Payload, Payload2} from '../../../state/payload';
import {
  LeermiddelVakKoppelingInfo,
  LesgroepDetailDashboardData,
  LesgroepDetailSamenvattingData,
  PlatformVak,
  SwLinkjesGebruikDetails,
  SwLinkjesPerPlatformOfVak,
  UitgeverOfVakGebruikVoorPeriode,
  VakInfo,
  Vestiging
} from '../../../../generated/graphql';
import {LesgroepDocenten, RuweSchooldashboardData, StudiewijzerLinkjesData} from './schooldashboard.state';
import {RLeerling, RMedewerker} from '../../../services/somtoday.service';
import {Periode} from '../../../services/datumbereik';

export interface KlasdashboardData {
  vestigingen: Vestiging[];
  klasData?: LesgroepDetailDashboardData;
  klasSamenvatting?: LesgroepDetailSamenvattingData;
}

export interface SWDashboardData {
  vestigingen: Vestiging[];
  swData: StudiewijzerLinkjesData;
}

export interface LeermiddelVakKoppelingData {
  leermiddelVakKoppelingInfo: LeermiddelVakKoppelingInfo[];
  vestigingsVakken: VakInfo[];
}

export const loadKlasData = createAction('[Klasendashboard] Load data', props<Payload<Date>>());
export const loadKlasSamenvatting = createAction('[Klassendashboard] Load samenvatting', props<Payload<Date>>());
export const loadVestigingen = createAction('[Vestigingen] Load vestigingen');
export const setSchooldashboardData = createAction('[Schooldashboard] Set ruwe data',
  props<Payload<[RuweSchooldashboardData, Periode, string[], string[], Date]>>());
export const setVakdashboardData = createAction('[Vakdashboard] Set data',
  props<Payload<[RuweSchooldashboardData, boolean, string, string, Periode, Date]>>());
export const setVakoverstijgendDashboardData = createAction('[VakoverstijgendDashboard] Set data',
  props<Payload<[RuweSchooldashboardData, Periode, string, Date]>>());
export const setSelectedVestiging = createAction('[Schooldashboard] Set selected vestiging', props<Payload<Vestiging>>());
export const setKlasData = createAction('[Klassendashboard] Set data', props<Payload<KlasdashboardData>>());
export const setKlasSamenvatting = createAction('[Klassendashboard] Set samenvatting', props<Payload<LesgroepDetailSamenvattingData>>());
export const loadDocenten = createAction('[Docenten] Load docenten', props<Payload<Date>>());
export const setDocenten = createAction('[Docentent] Set docenten', props<Payload<LesgroepDocenten>>());
export const loadLeerlingen = createAction('[Leerlingen] Load leerlingen', props<Payload<Date>>());
export const setLeerlingen = createAction('[Leerlingen] Set leerlingen', props<Payload<RLeerling[]>>());
export const loadMedewerkers = createAction('[Medewerkers] Load medewerkers', props<Payload<string[]>>());
export const addMedewerkers = createAction('[Medewerkers] Set medewerkers', props<Payload<RMedewerker[]>>());
export const setStudiewijzerLinkjes = createAction('[SWLinkjes] Set data', props<Payload<SWDashboardData>>());
export const setSelectedSWItem = createAction('[SWLinkjes] Set selected item', props<Payload<SwLinkjesPerPlatformOfVak>>());
export const closeSelectedSWItem = createAction('[SWLinkjes] Close selected item');
export const setSWItemDetails = createAction('[SWILinkjes] Set details', props<Payload<SwLinkjesGebruikDetails[]>>());
export const setSelectedSWItemDetail = createAction('[SWILinkjes] Set detail', props<Payload<SwLinkjesGebruikDetails>>());
export const setGroeperenOpPlatform = createAction('[SWLinkjes] Set groepering op platform', props<Payload<boolean>>());
export const setStudiewijzerLinkjesOverzichtLoading = createAction('[SWLinkjes] Set overzicht loading');
export const setStudiewijzerLinkjesDetailLoading = createAction('[SWLinkjes] Set details loading');
export const setStudiewijzerlinksPerPlatformVakOnderwijssoort = createAction(
  '[SWLinkjes] Set studiewijzerlinksPerPlatformVakOnderwijssoort',
  props<Payload<PlatformVak>>()
);
export const setVestigingen = createAction('[Vestigingen] Set vestigingen', props<Payload<Vestiging[]>>());
export const setLeermiddelVakKoppelingOpen = createAction('[LeermiddelVakKoppeling] Open koppelingscherm', props<Payload<boolean>>());
export const setLeermiddelvakkoppelingData = createAction(
  '[LeermiddelVakKoppeling] Set data',
  props<Payload<LeermiddelVakKoppelingData>>()
);
export const setLeermiddelData = createAction('[Leermiddeleldashboard] Set data',
  props<Payload2<UitgeverOfVakGebruikVoorPeriode[], Vestiging[]>>());
export const setLeermiddelendashboardGroepering = createAction('[Leermiddelendashboard] Set groepering', props<Payload<boolean>>());
