<div *ngIf="item">
  <h1>
    <a (click)="sluitDetail()"><i class="icon-arrow-l arrow"></i></a>
    <span [innerHTML]="item.naam"></span>
    <app-type *ngIf="item?.type != 'Overig'" [type]="item.type"></app-type>
  </h1>
  <h2>Gebruik</h2>
  <div class="gebruik">
    <div class="links">{{item.links}} links in totaal</div>
    <div class="klikratio">{{item.klikratio | percent}} klikratio</div>
  </div>
  <div class="details">
    <app-connect-loader [whitebackground]="true" [showText]="false" [loading]="loading | async">
      <app-table [model]="details | swdetailmodel:isPlatform" (rowClick)="selectDetail($event)"></app-table>
    </app-connect-loader>
  </div>
</div>
