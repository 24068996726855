<div class="header">
    <p class="subHeading">Leerlingen selecteren</p>
    <div [class]="submitButtonStyle" (click)="selectieSelecteren()">
        <p class="bodySmallContentSemi submitText">Kiezen</p>
    </div>
    <div class="containSluiten" (click)="sluiten()">
        <div class="sluiten"></div>
    </div>
</div>
<div class="scrollContainer" [style]="maxSelectionTablesHeightStyle">
    <app-select-table [viewModel]="onderwijssoortenSelectTableViewModel"></app-select-table>
    <div class="theGreatDivide"></div>
    <app-select-leerjaren [viewModel]="selectLeerjarenViewModel"></app-select-leerjaren>
    <div class="theGreatDivide" style="margin-top: -5px;"></div>
    <app-select-table [viewModel]="vakkenSelectTableViewModel"></app-select-table>
</div>
