<div>
  <a (click)="expanded = !expanded" (clickOutside)="expanded=false" [clickOutsideEvents]="'click,touchend'">
    <i class="icon-calender"></i>
    <span>
      {{optie.label}}
      <small *ngIf="toonJaartallen()">
        {{datums.from | date: 'd MMM yyyy' | lowercase}} - {{datums.to | date: 'd MMM yyyy' | lowercase}}
      </small>
      <small *ngIf="!toonJaartallen()">
        {{datums.from | date: 'd MMM' | lowercase}} - {{datums.to | date: 'd MMM' | lowercase}}
      </small>
    </span>
  </a>
  <div *ngIf="expanded">
    <h3>Stel datumbereik in</h3>
    <ul>
      <li *ngFor="let optie of getoondeOpties"
          (click)="wijzigPeriode(optie)"
          [class.selected]="selectie === optie.periode"
          [class.verborgen]="studiewijzerdb ? !optie.bevatStudiewijzers : !optie.bevatLicenties"
      >
        <i class="{{optie.icon}}"></i>{{optie.label}}
      </li>
    </ul>
    <dl>
      <dt>Interval van grafieken</dt>
      <dd>{{optie.interval}}</dd>
    </dl>
  </div>
</div>
