import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {AppState, selectSchooldashboard} from '../../state/app.state';
import {Store} from '@ngrx/store';
import {routerNavigatedAction} from '@ngrx/router-store';
import {catchError, filter, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {getDashboardPath} from '../../state/router/router.selectors';
import {DashboardFilterInput} from '../../../generated/graphql';
import {selectAppFilter} from '../../state/app.selectors';
import {setStudiewijzerLinkjes, setStudiewijzerLinkjesOverzichtLoading} from '../state/schooldashboard/schooldashboard.actions';
import {payload} from '../../state/payload';
import {StudiewijzerlinksService} from '../../services/studiewijzerlinks.service';
import {of} from 'rxjs';
import {reportError} from '../../state/errors/errors.actions';
import {routingConstants} from '../../stateless/routing.constants';

@Injectable()
export class StudiewijzerlinkjesEffect {
  $ = createEffect(() => this.actions$.pipe(
    ofType(routerNavigatedAction),
    filter(({payload: p}) => getDashboardPath(p?.routerState?.url) === routingConstants.STUDIEWIJZER_LINKJES_OLD),
    switchMap(() => this.store.select(selectAppFilter)),
    tap((current) => {
      this.prevFilter = current;
      this.store.dispatch(setStudiewijzerLinkjesOverzichtLoading());
    }),
    withLatestFrom(this.store.select(selectSchooldashboard)),
    switchMap(([f, state]) => this.service.fetch(f, state.groeperenOpPlatform)),
    map(payload),
    map(setStudiewijzerLinkjes),
    catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
  ));

  private prevFilter: DashboardFilterInput;

  constructor(private actions$: Actions, private store: Store<AppState>, private service: StudiewijzerlinksService) {
  }
}
