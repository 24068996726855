<div class="banner">
  <i class="icon-vak-dashboard"></i>
  <span *ngIf="leermiddelOnderwijssoortDagGebruik.totaal">{{leermiddelOnderwijssoortDagGebruik.totaal.leermiddel}}</span>
</div>
  <table mat-table class="mat-table" [dataSource]="leermiddelOnderwijssoortDagGebruik.data">

  <ng-container matColumnDef="onderwijssoortJaar">
    <th mat-header-cell *matHeaderCellDef>Onderwijssoort</th>
    <td mat-cell *matCellDef="let item">
      {{item.onderwijssoortJaar}}
    </td>
    <td mat-footer-cell *matFooterCellDef>Totaal</td>
  </ng-container>

  <ng-container matColumnDef="aantalLeerlingen">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let item"><div>{{item.leerlingenAantal}}</div></td>
    <td mat-footer-cell *matFooterCellDef>
    <div *ngIf="leermiddelOnderwijssoortDagGebruik.totaal">
      {{leermiddelOnderwijssoortDagGebruik.totaal.leerlingenAantal}}
    </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="ooitGeactiveerd">
    <th mat-header-cell *matHeaderCellDef>Activatie</th>
    <td mat-cell *matCellDef="let item">
      <app-activatiegetal-lesgroepdashboard
        [totaal]="item.leerlingenAantal"
        [geactiveerd]="item.leerlingenGeactiveerd">
      </app-activatiegetal-lesgroepdashboard>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <app-activatiegetal
        *ngIf="leermiddelOnderwijssoortDagGebruik.totaal"
        [range]="leermiddelOnderwijssoortDagGebruik.totaal.leerlingenGeactiveerd">
      </app-activatiegetal>
    </td>
  </ng-container>

  <ng-container matColumnDef="actief">
    <th mat-header-cell *matHeaderCellDef>Waarvan recent actief<i (click)="openActiefHulp()" class="icon-vraagteken"></i></th>
    <td mat-cell *matCellDef="let item">
      <app-progressbar
        *ngIf="item.leerlingenGeactiveerd > 0"
        [actief]="item.waarvanActief"
        [totaal]="item.leerlingenGeactiveerd">
      </app-progressbar>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <app-range-bar
        *ngIf="leermiddelOnderwijssoortDagGebruik.totaal && leermiddelOnderwijssoortDagGebruik.totaal.leerlingenGeactiveerd.avg > 0"
        [range]="leermiddelOnderwijssoortDagGebruik.totaal.waarvanActief">
      </app-range-bar>
    </td>
  </ng-container>

    <ng-container matColumnDef="leermiddelOnderwijssoortDagData">
      <th mat-header-cell *matHeaderCellDef>Gebruik per leerling<i (click)="openGebruiksHulp()" class="icon-vraagteken"></i></th>
      <td mat-cell *matCellDef="let item">
        <app-range-linechart-voor-lesgroeptabel
          *ngIf="item.leerlingenGeactiveerd > 0"
          [model]="item.leermiddelOnderwijssoortDagData" [gebruikPerWeek]="item.gebruikPerLeerlingPerWeek">
        </app-range-linechart-voor-lesgroeptabel>
      </td>
      <td mat-footer-cell *matFooterCellDef>
        <app-range-linechart
          *ngIf="leermiddelOnderwijssoortDagGebruik.totaal && leermiddelOnderwijssoortDagGebruik.totaal.leerlingenGeactiveerd.avg > 0"
          [model]="leermiddelOnderwijssoortDagGebruik.totaal.leermiddelDagData"
          [gebruikPerWeek]="leermiddelOnderwijssoortDagGebruik.totaal.gebruikPerLeerlingPerWeek">
        </app-range-linechart>
      </td>
    </ng-container>
    <tr mat-header-row class="mat-header-row" *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row class="element-row" *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr mat-footer-row class="mat-footer-row" *matFooterRowDef="displayedColumns"></tr>
</table>
