<div *ngIf="data.vakken.length === 0" class="geenVak" (click)="selecteer()">
  <span>Geen vak</span>
  <i class="icon koppelteken"></i></div>
<div *ngIf="data.vakken.length !== 0" class="vakken" (click)="selecteer()">
  <span class="vak">{{data.vakken[0].afkorting}}</span>
  <span *ngIf="data.vakken.length > 1" class="vak">{{data.vakken[1].afkorting}}</span>
  <span *ngIf="data.vakken.length === 3" class="vak">{{data.vakken[2].afkorting}}</span>
  <span *ngIf="data.vakken.length > 3" class="vak plus">+{{(data.vakken.length - 2)}}</span>
  <i class="icon ontkoppelteken" (click)="ontkoppel()"></i>
</div>
