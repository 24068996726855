<div class="grayBox">
    <i [class]="onGetIcon | async"></i>
    <div class="schoolName bodySmallContentBold">
        {{viewModel.onGetSchoolName | async}}
        <div class="onHoverShowSchoolId">
            schoolId: {{viewModel.onGetSchoolId | async}}
        </div>
    </div>
    <div class="toggle">
        <app-input-toggle [viewModel]="toggleViewModel"></app-input-toggle>
    </div>
    <div class="statusText bodyContent">{{onGetStatusText | async}}</div>
</div>