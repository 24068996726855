import {Pipe, PipeTransform} from '@angular/core';
import {createColumnDef, createDefaultFooterCellDef, createModel, Rowgroup, TableModel} from '../../table/table/table.model';
import {LesGroepDagGebruikData, VakData} from '../state/vakdashboard/vakdashboard.selectors';
import {KeyCellComponent} from '../../table/key-cell/key-cell.component';
import {LabelCellComponent} from '../../table/label-cell/label-cell.component';
import {JustifiedNumberCellComponent} from '../../table/justified-number-cell/justified-number-cell.component';
import {LesgroepDocentenCellComponent} from '../../table/lesgroep-docenten-cell/lesgroep-docenten-cell.component';
import {LesgroepActivatiegetalCellComponent} from '../../table/lesgroep-activatiegetal-cell/lesgroep-activatiegetal-cell.component';
import {ActivatiegetalCellComponent} from '../../table/activatiegetal-cell/activatiegetal-cell.component';
import {HelpHeaderCellComponent} from '../../table/help-header-cell/help-header-cell.component';
import {ProgressbarCellComponent} from '../../table/progressbar-cell/progressbar-cell.component';
import {RangebarCellComponent} from '../../table/rangebar-cell/rangebar-cell.component';
import {LinechartCellComponent} from '../../table/linechart-cell/linechart-cell.component';
import {RangelinechartCellComponent} from '../../table/rangelinechart-cell/rangelinechart-cell.component';
import {ClickableRowIconCellComponent} from '../../table/clickable-row-icon-cell/clickable-row-icon-cell.component';
import {ModalwindowService} from '../../services/modalwindow.service';

@Pipe({name: 'vakdashboardTable'})
export class VakdashboardTablePipe implements PipeTransform {
  constructor(private modalwindowService: ModalwindowService) {
  }

  transform({data, totaal}: VakData): TableModel<LesGroepDagGebruikData> {
    const groups = this.groupData(data);
    const model = createModel<LesGroepDagGebruikData>(data, d => d.lesgroepUuid);
    model.showFooters = true;
    model.rowsClickable = true;
    model.getRowgroup = rowModel => groups.get(rowModel.lesgroepUuid);

    const lesgroepNaam = createColumnDef<LesGroepDagGebruikData>('lesgroepNaam', 'Lesgroep');
    lesgroepNaam.body.component = KeyCellComponent;
    lesgroepNaam.body.style = {width: '10%'};
    lesgroepNaam.footer.component = LabelCellComponent;
    lesgroepNaam.footer.getValue = () => 'Totaal';

    const leerlingenAantal = createColumnDef<LesGroepDagGebruikData>('leerlingenAantal', '');
    leerlingenAantal.body.component = JustifiedNumberCellComponent;
    leerlingenAantal.body.style = {width: '5%'};
    leerlingenAantal.footer.component = JustifiedNumberCellComponent;
    leerlingenAantal.footer.getValue = () => totaal.leerlingenAantal;

    const docenten = createColumnDef<LesGroepDagGebruikData>('docent', 'Docent');
    docenten.body.component = LesgroepDocentenCellComponent;
    docenten.body.style = {width: '10px'};
    docenten.body.getValue = rowModel =>
      rowModel.docenten.map(
        ({roepnaam: voornaam, voorvoegsel: tussenvoegsel, achternaam}) => ({voornaam, tussenvoegsel, achternaam})
      );
    docenten.footer.getValue = () => '';

    const leerlingenGeactiveerd = createColumnDef<LesGroepDagGebruikData>('leerlingenGeactiveerd', 'Activatie');
    leerlingenGeactiveerd.body.component = LesgroepActivatiegetalCellComponent;
    leerlingenGeactiveerd.body.getValue = rowModel => ({geactiveerd: rowModel.leerlingenGeactiveerd, totaal: rowModel.leerlingenAantal});
    leerlingenGeactiveerd.body.style = {width: '10%'};
    leerlingenGeactiveerd.footer.component = ActivatiegetalCellComponent;
    leerlingenGeactiveerd.footer.getValue = () => totaal.leerlingenGeactiveerd;

    const waarvanActief = createColumnDef<LesGroepDagGebruikData>('waarvanActief', );
    const actiefHulp = () => this.modalwindowService.toonOnderwerp('actief');
    waarvanActief.header.component = HelpHeaderCellComponent;
    waarvanActief.header.getValue = () => ({title: 'Waarvan recent actief', help: actiefHulp});
    waarvanActief.body.component = ProgressbarCellComponent;
    waarvanActief.body.getValue = rowModel => ({
      totaal: rowModel.leerlingenGeactiveerd,
      actief: rowModel.waarvanActief,
      hidden: rowModel.leerlingenGeactiveerd === 0
    });
    waarvanActief.body.style = {width: '30%'};
    waarvanActief.footer = createDefaultFooterCellDef('waarvanActief');
    waarvanActief.footer.component = RangebarCellComponent;
    waarvanActief.footer.getValue = () => {
      return ({
        model: totaal.waarvanActief,
        hidden: totaal.leerlingenGeactiveerd.max === 0,
        minGebruikPerWeek: totaal.gebruikPerLeerlingPerWeek.min,
        maxGebruikPerWeek: totaal.gebruikPerLeerlingPerWeek.max
      });
    };

    const gebruik = createColumnDef<LesGroepDagGebruikData>('gebruik');
    const gebruikHulp = () => this.modalwindowService.toonOnderwerp('gebruik');
    gebruik.header.component = HelpHeaderCellComponent;
    gebruik.header.getValue = () => ({title: 'Gebruik per leerling', help: gebruikHulp});
    gebruik.body.component = LinechartCellComponent;
    gebruik.body.getValue = rowModel => ({
      gebruikPerWeek: rowModel.gebruikPerLeerlingPerWeek,
      data: rowModel.lesgroepDagData,
      hidden: rowModel.leerlingenGeactiveerd === 0
    });
    gebruik.body.style = {width: '30%'};
    gebruik.footer.component = RangelinechartCellComponent;
    gebruik.footer.getValue = () => ({
      model: totaal.lesgroepDagData,
      totaal: totaal.gebruikPerLeerlingPerWeek,
      hidden: totaal.leerlingenGeactiveerd.max === 0
    });

    const icon = createColumnDef<LesGroepDagGebruikData>('icon', '');
    icon.body = {
      component: ClickableRowIconCellComponent,
      getValue() { return undefined; },
      getRowspan() { return 1; },
      style: {width: '5%'}
    };
    icon.footer.component = LabelCellComponent;
    icon.footer.getValue = () => '';
    model.columnDefs = [lesgroepNaam, leerlingenAantal, docenten, leerlingenGeactiveerd, waarvanActief, gebruik, icon];
    return model;
  }

  private groupData(data: LesGroepDagGebruikData[]): Map<string, Rowgroup> {
    let groupId = 0;
    let lastOfGroup = false;
    return data.reduce((map, value, index)  => {
      if (index > 0 && data[index - 1].lesgroepLeerjaar !== value.lesgroepLeerjaar) {
        groupId++;
      }
      if (index === data.length - 1) {
        lastOfGroup = groupId % 2 === 1;
      } else {
        lastOfGroup = data[index + 1].lesgroepLeerjaar !== value.lesgroepLeerjaar;
      }
      map.set(value.lesgroepUuid, {lastOfGroup, groupId});
      return map;
    }, new Map<string, Rowgroup>());
  }
}
