<div class="header">
    <p class="bodyContentSemi">{{this.viewModel.onGetTitle | async}}</p>
    <div *ngIf="this.viewModel.onGetHasSelectAll | async" class="selectAll">
        <i [class]="isAllSelected ? 'checked' : 'unchecked'" (click)="selectAll(!isAllSelected)"></i>
        <p class="bodyContent">Alles</p>
    </div>
</div>
<ul>
    <ng-container *ngFor="let row of viewModel.onGetRows | async;">
        <li>
            <div [class]="rowClass(row)" (click)="onRowClicked(row)">
                <div class="icon"><i [class]="checkboxClass(row)"></i></div>
                <p [class]="textClass(row)">{{row.naam}}</p>
            </div>
        </li>
    </ng-container>
    <li *ngIf="showPlaceholder">
        <div class="row info">
            <div class="icon"><i class="info"></i></div>
            <p class="bodyContent info naam">{{viewModel.onGetPlaceholderText | async}}</p>
        </div>
    </li>
</ul>
