import {Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy} from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { Categorie, Endpoint, Veld } from 'src/app/state/dev-koppelpartij/dev-koppelpartij.state';
import { intrinsicDistinct } from 'src/app/stateless/privacydashboard.functions';
import { InputPermissionCategoryViewModel } from '../input-permission-category/input-permission-category.component';

@Component({
    selector: 'app-input-permission-endpoint',
    templateUrl: './input-permission-endpoint.component.html',
    styleUrls: ['./input-permission-endpoint.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputPermissionEndpointComponent implements OnInit, OnDestroy {
    @Input()
    public viewModel: InputPermissionEndpointViewModel;

    private subscriptions: Subscription[] = [];

    constructor() { }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    public viewModelForCategorie(categorie: Categorie): InputPermissionCategoryViewModel {
        let vm: InputPermissionCategoryViewModel = {
            onGetEndpoint: this.viewModel.onGetEndpoint,
            onGetCategory: this.viewModel.onGetEndpoint.pipe(
                map(e => e.categorieen.find(c => c.naam === categorie.naam)),
                intrinsicDistinct()
            ),

            onAdditionalPropertyClicked: new Subject(),
            onVeldOptioneelClicked: new Subject(),
            onVeldVerplichtClicked: new Subject()
        }

        this.subscriptions.push(vm.onAdditionalPropertyClicked.pipe(withLatestFrom (vm.onGetCategory)).subscribe(
        ([[veld, property], categorie]) => this.viewModel.onAdditionalPropertyClicked.next(([[veld, property], categorie]))
        ));

        this.subscriptions.push(vm.onVeldOptioneelClicked.pipe(withLatestFrom(vm.onGetCategory)).subscribe(
            ([veld, categorie]) => this.viewModel.onVeldOptioneelClicked.next(([veld, categorie]))
        ));

        this.subscriptions.push(vm.onVeldVerplichtClicked.pipe(withLatestFrom(vm.onGetCategory)).subscribe(
            ([veld, categorie]) => this.viewModel.onVeldVerplichtClicked.next(([veld, categorie]))
        ));

        return vm;
    }
}

export interface InputPermissionEndpointViewModel {
    onGetEndpoint: Observable<Endpoint>;

    onAdditionalPropertyClicked: Subject<[[Veld, string], Categorie]>;
    onVeldOptioneelClicked: Subject<[Veld, Categorie]>;
    onVeldVerplichtClicked: Subject<[Veld, Categorie]>;
}
