import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class ModalwindowService {
  private onderwerp_ = new Subject<string>();
  private close_ = new Subject<boolean>();

  onderwerp$ = this.onderwerp_.asObservable();
  close$ = this.close_.asObservable();

  toonOnderwerp(onderwerp: string) {
    this.onderwerp_.next(onderwerp);
    this.close_.next(false);
  }

  closeWindow() {
    this.close_.next(true);
  }
}
