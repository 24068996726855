import {Component, OnInit, ChangeDetectionStrategy, Input} from '@angular/core';

export enum BUTTON_TYPE {
  SECONDARY,
  OUTLINE,
  POSITIVE
}

export interface AbstractButton {
  text: string;
  action: () => void;
  enabled: boolean;
  buttonType: BUTTON_TYPE;
}

export interface ButtonStyling {
  disabled?: boolean;
  secondaryButton?: boolean;
  outlineButton?: boolean;
  positiveButton?: boolean;
}


export class AnnulerenKnop implements AbstractButton{

  action: () => void;
  enabled = true;
  text = 'Annuleren';
  buttonType = BUTTON_TYPE.OUTLINE;

  constructor(event: () => void){
    this.action = event;
  }
}

export class VolgendeKnop implements AbstractButton{

  action: () => void;
  enabled = true;
  text = 'Volgende';
  buttonType = BUTTON_TYPE.SECONDARY;

  constructor(event: () => void){
    this.action = event;
  }
}

@Component({
  selector: 'app-bottombar',
  templateUrl: './bottombar.component.html',
  styleUrls: ['./bottombar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BottombarComponent implements OnInit {

  @Input() leftButton: AbstractButton;
  @Input() rightButton: AbstractButton;

  constructor() { }

  ngOnInit(): void {
  }

  rightButtonAction(): void {
    if (this.rightButton && this.rightButton.enabled) { this.rightButton.action(); }
  }

  leftButtonAction(): void {
    if (this.leftButton && this.leftButton.enabled) { this.leftButton.action(); }
  }

  get leftButtonComputedStyling(): ButtonStyling {
    return {
      disabled: !this.leftButton.enabled,
      secondaryButton: (this.leftButton.enabled && this.leftButton.buttonType === BUTTON_TYPE.SECONDARY),
      outlineButton: (this.leftButton.enabled && this.leftButton.buttonType === BUTTON_TYPE.OUTLINE),
      positiveButton: (this.leftButton.enabled && this.leftButton.buttonType === BUTTON_TYPE.POSITIVE)
    };
  }

  get rightButtonComputedStyling(): ButtonStyling {
    return {
      disabled: !this.rightButton.enabled,
      secondaryButton: (this.rightButton.enabled && this.rightButton.buttonType === BUTTON_TYPE.SECONDARY),
      outlineButton: (this.rightButton.enabled && this.rightButton.buttonType === BUTTON_TYPE.OUTLINE),
      positiveButton: (this.rightButton.enabled && this.rightButton.buttonType === BUTTON_TYPE.POSITIVE)
    };
  }
}
