import {Component, Input, OnInit} from '@angular/core';
import {TableCellComponent} from '../table/table.model';
import {RangeModel} from '../../layout/model/range.model';

@Component({
  selector: 'app-activatiegetal-cell',
  templateUrl: './activatiegetal-cell.component.html',
  styleUrls: ['./activatiegetal-cell.component.scss']
})
export class ActivatiegetalCellComponent implements OnInit, TableCellComponent<RangeModel> {
  @Input()
  data: RangeModel;

  constructor() { }

  ngOnInit() {
  }

}
