<div class="instellingen-menuitem" (clickOutside)="close()" (click)="toggle()">
  <i class="icon-instellingen"></i>
  <a *ngIf="expanded" class="menu">
    <h3>Instellingen</h3>
    <div class="item" *ngIf="toonLeermiddelVakKoppelingKnop(true)" (click)="openLeermiddelVakKoppeling()">
      <img alt="Koppelteken" src="../../../assets/img/icons/Symbool/Koppelen.svg">
      <span>Leermiddel-vak koppelingen</span>
    </div>
    <div class="item" *ngIf="toonLeermiddelVakKoppelingKnop(false)" (click)="openLeermiddelVakKoppelingNew()">
      <img alt="Koppelteken" src="../../../assets/img/icons/Symbool/Koppelen.svg">
      <span>Leermiddel-vak koppelingen</span>
    </div>
  </a>
</div>
