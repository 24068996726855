import {createReducer, on} from '@ngrx/store';
import {ExportoverzichtState} from './exportoverzicht.state';
import {
    closeSidebarRequested,
    exportDetailsFetched,
    exportsFetched,
    fetchExports,
    medewerkerNamenFetched,
    medewerkerUuidsFetched,
    openSidebarRequested,
    resetFilter,
    setDatumBereik,
    setDatumBereiken,
    setFilterDatums,
    setFilterMedewerker,
    setPage
} from './exportoverzicht.actions';
import {Periode} from '../../services/datumbereik';

const initialState: ExportoverzichtState = {
  exports: null,
  exportDetails: {},
  filter: {
    datumBereiken: [],
    datumBereik: {
      epochSeconds: 0,
      periode: Periode.Week,
      hasData: true
    },
    begindatum: '',
    einddatum: '',
    medewerker: null,
    type: '',
  },
  sidebar: {
    open: false,
    list: []
  },
  isLoading: true,
  medewerkerNamen: [],
  page: 1,
  aantalExports: 0,
};

export const exportoverzichtReducer = createReducer(
  initialState,
  on(
    fetchExports,
    (state) => ({
      ...state,
      exports: null,
      isLoading: true,
    })
  ),
  on(
    setDatumBereik,
    (state, {datumBereik}) => {
      return {
        ...state,
        filter: {
          ...state.filter,
          datumBereik
        },
        isLoading: true,
      };
    }
  ),
  on(
    setDatumBereiken,
    (state, {datumBereiken}) => {
      return {
        ...state,
        filter: {
          ...state.filter,
          datumBereiken
        }
      };
    }
  ),
  on(
    setFilterDatums, (
      state,
      {
        begindatum,
        einddatum
      }) => ({
      ...state,
      filter: {...state.filter, begindatum, einddatum}
    })),
  on(
      exportsFetched,
      (state, {exportData}) => ({
          ...state,
          exports: exportData.exports,
          aantalExports: exportData.aantalExports,
          isLoading: false
      })
  ),
  on(
      medewerkerUuidsFetched,
      (state, {medewerkers}) => ({
          ...state,
          medewerkers,
          isLoading: false
      })
  ),
  on(
    exportDetailsFetched,
    (state, {id, exportDetails}) => {
      return {
        ...state,
        exportDetails: {
          ...state.exportDetails,
          [id]: exportDetails
        },
      };
    }),
  on(
    openSidebarRequested,
    (state, {list}) => {
      return {
        ...state,
        sidebar: {
          open: true,
          list
        }
      };
    }
  ),
  on(
    closeSidebarRequested,
    (state) => {
      return {
        ...state,
        sidebar: {
          open: false,
          list: []
        }
      };
    }
  ),
  on(
      setFilterMedewerker,
      (state, {medewerker}) => {
        return {
          ...state,
          filter: {
            ...state.filter,
            medewerker: medewerker !== ''
                ? state.medewerkerNamen.find(mw => mw?.naam === medewerker)
                : null
          }
        };
      }
  ),
  on(
      setPage,
      (state, {page}) => {
        return {
          ...state,
          page
        };
      }
  ),
  on(
    medewerkerNamenFetched,
    (state, {namen}) => {
      const ontdubbeldeNamen = namen.filter(naam => state.medewerkerNamen.find(n => n.uuid === naam.uuid) === undefined);

      return {...state, medewerkerNamen: [...state.medewerkerNamen, ...ontdubbeldeNamen]};
    }
  ),
  on(
    resetFilter,
    () => initialState
  )
);
