import * as m from 'moment';
import {max} from 'moment';

export const enum Periode {
  Week = 'WEEK',
  Maand = 'MAAND',
  Periode = 'PERIODE',
  Schooljaar = 'SCHOOLJAAR',
  VorigSchooljaar = 'VORIGSCHOOLJAAR'
}

export type Interval = 'd' | 'w' | 'm';

function getBeginSchoolaarjaar(date?: Date): m.Moment {
  const startSchooljaar = m(date).month(7).date(1);
  if (m(date).month() < 7)
    startSchooljaar.subtract(1, 'y');
  return startSchooljaar;
}

export function getEindSchooljaar(date?: Date): m.Moment {
  return getBeginSchoolaarjaar(date).add(1, 'year').month(6).date(31);
}

export function periodeNaarDatums(periode: Periode, date?: Date): m.FromTo {
  // Bepaal 1 augustus van dit schooljaar
  const startSchooljaar = getBeginSchoolaarjaar(date);
  let datums: m.FromTo;
  const gisteren = m(date).subtract(1, 'd').endOf('d');
  switch (periode) {
    case Periode.Week:
      datums = {from: max(startSchooljaar, m(date).subtract(1, 'w')).startOf('d'), to: gisteren};
      break;
    case Periode.Maand:
      datums = {from: max(startSchooljaar, m(date).subtract(4, 'w')).startOf('d'), to: gisteren};
      break;
    case Periode.Periode:
      datums = {from: max(startSchooljaar, m(date).subtract(12, 'w')).startOf('d'), to: gisteren};
      break;
    case Periode.Schooljaar:
      datums = {
        from: startSchooljaar.startOf('d'),
        to: m(date).date(1).subtract(1, 'd').endOf('d')
      };
      break;
    case Periode.VorigSchooljaar:
      datums = {
        from: m(startSchooljaar).subtract(1, 'y').startOf('d'),
        to: m(startSchooljaar).subtract(1, 'd').endOf('d')
      };
      break;
    default:
      throw new Error(`Ongeldige periode '${periode}'`);
  }
  return datums;
}

export function periodeNaarInterval(periode: Periode): Interval {
  switch (periode) {
    case Periode.Week:
      return 'd';
    case Periode.Maand:
      return 'w';
    case Periode.Periode:
      return 'w';
    case Periode.Schooljaar:
      return 'm';
    case Periode.VorigSchooljaar:
      return 'm';
    default:
      throw new Error(`Ongeldige periode '${periode}'`);
  }
}

export function createIntervallen(periode: Periode, date?: Date): {van: Date, tot: Date}[] {
  const {from, to} = periodeNaarDatums(periode, date);
  const intervallen = [];
  if (periode === Periode.Week) {
    const einde = m(to);
    for (const begin = m(to).startOf('d'); !begin.isBefore(from); begin.subtract(1, 'd')) {
      intervallen.unshift({van: begin.toDate(), tot: einde.toDate()});
      einde.subtract(1, 'd');
    }
  } else if ([Periode.Maand, Periode.Periode].includes(periode)) {
    let einde = m(to);
    for (const begin = m(to).subtract(1, 'w').startOf('d');
         !begin.isBefore(from); begin.subtract(1, 'w')) {
      intervallen.unshift({van: begin.toDate(), tot: einde.toDate()});
      einde = m(begin).subtract(1, 'd').endOf('d');
    }
  } else if ([Periode.Schooljaar, Periode.VorigSchooljaar].includes(periode)) {
    // Schooljaar
    const einde = m(to);
    for (const begin = m(to).startOf('M'); !begin.isBefore(from); begin.subtract(1, 'M')) {
      intervallen.unshift({van: begin.toDate(), tot: einde.toDate()});
      einde.startOf('M').subtract(1, 'd').endOf('d');
    }
  } else {
    throw new Error(`Ongeldinge periode: ${periode}`);
  }
  return intervallen;
}

export function periodeToAantalDagen(periode: Periode, datum?: Date): number {
  const {from, to} = periodeNaarDatums(periode, datum);
  return m(to).diff(from, 'd');
}

export function datumOverlap(periode: Periode, vanafdatum?: Date, einddatum?: Date): boolean {
  if (einddatum == null || vanafdatum == null) { return false; }
  const {from, to} = periodeNaarDatums(periode);
  return m(from).isSameOrBefore(m(einddatum)) && m(to).isSameOrAfter(m(vanafdatum));
}

export function toonPeriode(periode: Periode, dataSinds: Date): boolean {
  switch (periode) {
    case Periode.Week:
      return true;
    case Periode.Maand:
      return !(m().month() === 7 && m().date() < 8);
    case Periode.Periode:
      return m().month() > 9 || m().month() < 7;
    case Periode.Schooljaar:
      return m().month() !== 7;
    case Periode.VorigSchooljaar:
      return getBeginSchoolaarjaar().isAfter(dataSinds);
  }
}

export const vandaag = (): Date => m().startOf('d').toDate();
