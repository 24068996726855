import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ContentChild,
  TemplateRef,
  ChangeDetectorRef,
  ViewChild, OnDestroy, ElementRef
} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-popout-button',
    templateUrl: './popout-button.component.html',
    styleUrls: ['./popout-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class PopoutButtonComponent implements OnInit, OnDestroy {
    @ContentChild(TemplateRef) popoutPanel;

    @ViewChild('panel') panel;

    public icon: Observable<string>;

    public isOpen: boolean = false;
    public panelClass: string = 'panel';

    private subscriptions = [];

    @Input()
    public viewModel: PopoutButtonViewModel;

    constructor(private cdr: ChangeDetectorRef, private el: ElementRef) { }

    ngOnInit(): void {
        this.icon = this.viewModel.onGetIconUrl.pipe(map(icon => icon ? 'background-image: url(' + icon + ')' : ''));

        this.subscriptions.push(this.viewModel.onGetIsOpen.subscribe(isOpen => {
            this.isOpen = isOpen;
            this.cdr.detectChanges();
        }));

        this.subscriptions.push(this.viewModel.contentIsLoaded.subscribe(contentIsLoaded => {
          if (contentIsLoaded) {
            this.cdr.detectChanges();
            this.panelClass = this.panel && (window.innerHeight - this.panel.nativeElement.offsetHeight < this.el.nativeElement.offsetTop ) ? 'panel push_up' : 'panel';
            this.cdr.detectChanges();
          }
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    public openPopout(): void {
        this.viewModel.doSetIsOpen.next(true);
    }

    public sluitPopout(): void{
        this.viewModel.doSetIsOpen.next(false);
    }
  }

export interface PopoutButtonViewModel {
    onGetCaption: Observable<string>;
    onGetIconUrl: Observable<string>;
    onGetIsOpen: Observable<boolean>;
    contentIsLoaded: Observable<boolean>;

    doSetIsOpen: BehaviorSubject<boolean>;
}
