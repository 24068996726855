import {Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalComponent implements OnInit {

  @Output()
  closeModal = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  onCloseModal(): void {
    this.closeModal.emit();
  }

}
