<div class="donutcontainer" *ngIf="totaalAantalLinks > 0">
    <p class="donut-header donutcomponent">Studiewijzerinzet per {{titel}}</p>
    <div class="donut-body donutcomponent">
        <div class="donut donutcomponent">
            <google-chart class="chart" id="donut-chart" [data]="donutData"></google-chart>
        </div>
        <div class="donutcomponent">
            <p class="totaal donutcomponent">Totaal</p>
            <p class="stats donutcomponent">{{totaalAantalLinks}} links in {{totaalAantalLesgroepen}} lesgroepen</p>
        </div>
    </div>
</div>
