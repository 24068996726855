import {Component} from '@angular/core';
import {RangeBarComponent} from '../range-bar/range-bar.component';

@Component({
  selector: 'app-activatiegetal',
  templateUrl: './activatiegetal.component.html',
  styleUrls: ['./activatiegetal.component.scss']
})
export class ActivatiegetalComponent extends RangeBarComponent {

  waarschuwing(activatie: number): string {
    if (activatie <= 0.30) {
      return 'lage-activatie';
    } else if (activatie <= 0.60) {
      return 'matige-activatie';
    } else {
      return 'hoge-activatie';
    }
  }
}
