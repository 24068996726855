<div class="container">
  <div class="baseline">
    <div [style.width]="quotient | percent"></div>
  </div>
  <div class="percentage">{{quotient | percent}} actief</div>
  <div class="values">
    <div class="actief">{{actief}} actief</div>
    <div class="inactief">{{totaal - actief}} inactief</div>
  </div>
</div>
