import {Component, OnInit, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import {Observable} from 'rxjs';
import {ExportoverzichtFacade} from '../../../state/exportoverzicht/exportoverzicht.facade';
import {PortalBridgeService} from "../../../services/portal-bridge.service";
import {TemplatePortal} from "@angular/cdk/portal";

@Component({
  selector: 'app-export-sidebar',
  templateUrl: './export-sidebar.component.html',
  styleUrls: ['./export-sidebar.component.scss'],
})
export class ExportSidebarComponent implements OnInit {
  @ViewChild('portalContent', {static: true}) portalContent: TemplateRef<unknown>;

  public list$: Observable<string[]>;

  public isOpen$: Observable<boolean>;

  constructor(
    private facade: ExportoverzichtFacade,
    private portalBridgeService: PortalBridgeService,
    private viewContainerRef: ViewContainerRef
  ) {
  }

  ngOnInit(): void {
    setTimeout(() => {
      const portal = new TemplatePortal(this.portalContent, this.viewContainerRef);
      this.portalBridgeService.setPortal(portal);
    });

    this.isOpen$ = this.facade.getSidebarOpenState();
    this.list$ = this.facade.getSidebarList();
  }

  closeSidebar(): void {
    this.facade.closeSidebarRequested();
  }
}
