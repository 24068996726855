<div class="progressbaractivatiegraad">
    <span>{{ progressbarinfo.uitgever }}</span>
    <span class="klassen">{{ extraBoodschap }}</span>
    <span class="percentage">{{ percentage + '%' }}</span>
        <canvas baseChart
          [datasets]="barChartData"
          [options]="barChartOptions"
          [legend]="barChartLegend"
          [chartType]="barChartType">
        </canvas>
</div>
