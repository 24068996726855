import {createReducer, on} from '@ngrx/store';
import {BazenbannerData, RuweSchooldashboardData, SchooldashboardState} from './schooldashboard.state';
import {
  addMedewerkers,
  closeSelectedSWItem,
  setDocenten,
  setGroeperenOpPlatform,
  setKlasData,
  setLeerlingen,
  setLeermiddelData,
  setLeermiddelvakkoppelingData,
  setLeermiddelVakKoppelingOpen,
  setSchooldashboardData,
  setSelectedSWItem,
  setSelectedSWItemDetail,
  setStudiewijzerLinkjes,
  setStudiewijzerLinkjesDetailLoading,
  setStudiewijzerLinkjesOverzichtLoading,
  setStudiewijzerlinksPerPlatformVakOnderwijssoort,
  setSWItemDetails,
  setVakdashboardData,
  setVakoverstijgendDashboardData,
  setVestigingen
} from './schooldashboard.actions';
import {reset} from '../../../state/app.actions';
import {OpleidingVakLesgroepDagGebruik, SwLinkjesGebruikDetails, VakloosLeermiddelOpleidingDagGebruik} from '../../../../generated/graphql';
import {Payload} from '../../../state/payload';
import {createOnderwijssoortModel, createVakModel, SchooldashboardModel} from '../../schooldashboard/schooldashboard.model';
import {BazenbannerModel} from '../../../layout/model/bazenbanner.model';
import {createIntervallen, Periode, periodeNaarDatums, periodeToAantalDagen} from '../../../services/datumbereik';
import * as moment from 'moment';
import {SchooldashboardDetailModel} from '../../schooldashboard/schooldashboard.detail.model';
import {RangeLinechartModel} from '../../../layout/range-linechart/range-linechart.model';
import {
  LeermiddelData,
  LeermiddelOnderwijssoortDagGebruikData,
  LeermiddelOnderwijssoortDagGebruikTotaal
} from '../vakloosleermiddeldashboard/vakloosleermiddeldashboard.selectors';
import {rangeModelBuilder, RangeModelBuilder} from '../../../layout/model/range.model';
import {deelVeilig} from '../../../services/veilig-delen';
import {compareLesgroep} from '../../../services/lesgroep.comparator';
import {LesGroepDagGebruikData, LesGroepDagGebruikTotaal, VakData, VakDataParams} from '../vakdashboard/vakdashboard.selectors';
import {onderwijssoortComparator} from '../../../services/onderwijssoort.comparator';

export const initialState: SchooldashboardState = {
  ruweSchooldashboardData: {
    ruweVakData: [],
    ruweVakloosData: [],
    ruweOngeclassificeerdeData: [],
    ruweBazenbannerData: null,
    vestigingen: []
  },
  berekendeVakData: {
    data: [],
    totaal: null,
    nav: {
      vorige: null,
      volgende: null
    },
    hoofdniveau: null,
    subniveau: null
  },
  berekendeSchooldashboardModelVak: [],
  berekendeSchooldashboardModelOnderwijssoort: [],
  berekendeVakloosData: null,
  berekendeBazenbannerModel: null,
  berekendeSchooldashboardDetailModel: new Map<string, SchooldashboardDetailModel[]>(),
  vestigingen: [],
  klasData: null,
  klasSamenvatting: null,
  leerlingen: [],
  docenten: {},
  medewerkers: [],
  loadDocenten: false,
  loadLeerlingen: false,
  loadMedewerkers: false,
  studiewijzerLinkjes: null,
  selectedStudiewijzerLinkjesItem: null,
  studiewijzerLinkjesOverzichtLoading: false,
  swLinkjesGebruikDetails: [],
  selectedSWLinkjesDetail: null,
  studiewijzerPlatformVak: null,
  groeperenOpPlatform: true,
  studiewijzerLinkjesDetailLoading: false,
  leermiddelVakKoppelingOpen: false,
  leermiddelvakkoppelingData: [],
  vestigingVakkenData: [],
  leermiddelData: []
};

export const schooldashboardReducer = createReducer(
  initialState,
  on(setSchooldashboardData, berekenSchooldashboardData),
  on(setVakdashboardData, berekenVakdashboardData),
  on(setVakoverstijgendDashboardData, berekenVakoverstijgendDashboardData),
  on(setKlasData, (state, {value: {vestigingen, klasData, klasSamenvatting}}) =>
    ({...state, vestigingen, klasData, klasSamenvatting, loadLeerlingen: true})),
  on(setDocenten, (state, {value: docenten}) => ({...state, docenten, loadDocenten: false, loadMedewerkers: true})),
  on(addMedewerkers, (state, {value}) => ({
    ...state,
    loadDocenten: false,
    loadMedewerkers: false,
    medewerkers: [...state.medewerkers, ...value.filter(({UUID}) => !state.medewerkers.some(m => m.UUID === UUID))]
  })),
  on(setLeerlingen, (state, {value: leerlingen}) => ({...state, leerlingen, loadLeerlingen: false})),
  on(setStudiewijzerLinkjesOverzichtLoading, state => ({
    ...state,
    studiewijzerLinkjesOverzichtLoading: true,
    selectedStudiewijzerLinkjesItem: null,
    swLinkjesGebruikDetails: []
  })),
  on(setStudiewijzerLinkjes, (state, {value}) =>
    ({...state, vestigingen: value.vestigingen, studiewijzerLinkjes: value.swData, studiewijzerLinkjesOverzichtLoading: false})),
  on(setStudiewijzerLinkjesDetailLoading, state => ({...state, studiewijzerLinkjesDetailLoading: true})),
  on(setSWItemDetails, (state, {value}) => ({...state, swLinkjesGebruikDetails: value, studiewijzerLinkjesDetailLoading: false})),
  on(setSelectedSWItemDetail, onSetSelectedSWItemDetail),
  on(setSelectedSWItem, (state, {value: selectedStudiewijzerLinkjesItem}) => ({...state, selectedStudiewijzerLinkjesItem})),
  on(closeSelectedSWItem, state => ({...state, selectedStudiewijzerLinkjesItem: null, swLinkjesGebruikDetails: null})),
  on(setGroeperenOpPlatform, (state , {value}) => ({...state, groeperenOpPlatform: value})),
  on(setStudiewijzerlinksPerPlatformVakOnderwijssoort, (state, {value}) => ({...state, studiewijzerPlatformVak: value})),
  on(reset, _ => initialState),
  on(setVestigingen, (state, {value}) => ({...state, vestigingen: value})),
  on(setLeermiddelvakkoppelingData, (state, {value}) =>
    ({...state,
      leermiddelvakkoppelingData: value.leermiddelVakKoppelingInfo,
      vestigingenVakkenData: value.vestigingsVakken})),
  on(setLeermiddelVakKoppelingOpen, (state, {value}) => ({...state, leermiddelVakKoppelingOpen: value})),
  on(setLeermiddelData, (state, {value: leermiddelData, value2: vestigingen}) => ({...state, leermiddelData, vestigingen})),
  on(reset, _ => initialState)
);

function onSetSelectedSWItemDetail(state: SchooldashboardState, {value}: Payload<SwLinkjesGebruikDetails>): SchooldashboardState {
  return {
    ...state,
    selectedSWLinkjesDetail: value,
    studiewijzerPlatformVak: {
      naam: state.groeperenOpPlatform ? state.selectedStudiewijzerLinkjesItem.naam : value.naam,
      vaknaam: state.groeperenOpPlatform ? value.naam : state.selectedStudiewijzerLinkjesItem.naam,
      getallenPerOnderwijssoort: null
    }
  };
}

function berekenSchooldashboardData(state: SchooldashboardState,
                                    {value}: Payload<[RuweSchooldashboardData, Periode, string[], string[], Date]>): SchooldashboardState {
  const [data, periode, openVakken, openOnderwijssoorten, datum] = value;
  const details: Map<string, SchooldashboardDetailModel[]> = new Map<string, SchooldashboardDetailModel[]>();
  const modelVak = berekenSchooldashboardModel(
    data, true, openVakken, openOnderwijssoorten, periode, datum, details);
  const modelOnderwijssoort = berekenSchooldashboardModel(
    data, false, openVakken, openOnderwijssoorten, periode, datum, details);
  return {
    ...state,
    vestigingen: data.vestigingen,
    ruweSchooldashboardData: data,
    loadDocenten: true,
    berekendeSchooldashboardModelVak: modelVak,
    berekendeSchooldashboardModelOnderwijssoort: modelOnderwijssoort,
    berekendeBazenbannerModel: berekenBazenbannerData(data.ruweBazenbannerData, periode, data.ruweVakData),
    berekendeSchooldashboardDetailModel: details
  };
}

function berekenSchooldashboardModel(
  data: RuweSchooldashboardData, groeperenOpVak: boolean, openVakken: string[], openOnderwijssoorten: string[],
  periode: Periode, datum: Date, details: Map<string, SchooldashboardDetailModel[]>
): SchooldashboardModel[] {
  const createModel = groeperenOpVak ? createVakModel : createOnderwijssoortModel;
  const isOpen = (key: string) => groeperenOpVak ? openVakken.includes(key) : openOnderwijssoorten.includes(key);
  return createModel(data.ruweVakData, data.ruweVakloosData,
    data.ruweOngeclassificeerdeData, periode, isOpen, details, datum);
}

function berekenBazenbannerData(data: BazenbannerData, periode: Periode, ruweVakData): BazenbannerModel {
  const {from, to} = periodeNaarDatums(periode);
  const dagen = [];
  for (const current = moment(from); current.isSameOrBefore(moment(to)); current.add(1, 'd')) {
    dagen.push(current.toDate());
  }
  const gebruik = berekenTotaalGebruik(dagen, ruweVakData);
  if (data === null || gebruik === null || gebruik.size === 0) {
    return null;
  }
  const linechartGebruik = Array.from(gebruik.entries(), ([date, value]) => ({date, value}));
  linechartGebruik.sort((a, b) => a.date.getTime() - b.date.getTime());
  return {...data, linechartGebruik, lesgroepActivatieInProcenten: data.percentageLesgroepenMetInzetBoven70pct};
}

function berekenTotaalGebruik(dagen: Date[], data: OpleidingVakLesgroepDagGebruik[]): Map<Date, number> {
  if (data.length === 0) {
    return null;
  }
  const dagGebruik = dagen.reduce((map, dag) => map.set(dag.getTime(), 0), new Map<number, number>());
  data.filter(({dag}) => dag !== undefined).forEach(({dag, daggebruik}) => {
    const key = dag.getTime();
    if (dagGebruik.has(key)) {
      dagGebruik.set(key, dagGebruik.get(key) + daggebruik);
    }
  });
  return Array.from(dagGebruik.entries())
    .reduce((map, [k, v]) => map.set(new Date(k), v), new Map<Date, number>());
}

function berekenVakdashboardData(state: SchooldashboardState,
                                 {value}: Payload<[RuweSchooldashboardData, boolean, string, string, Periode, Date]>
): SchooldashboardState {
  const [data, groeperenOpVak, vak, onderwijssoort, periode, datum] = value;
  return {
    ...state,
    ruweSchooldashboardData: data,
    berekendeVakData: createVakData(data.ruweVakData, groeperenOpVak, vak, onderwijssoort, periode, datum),
    loadDocenten: true,
    loadMedewerkers: false
  };
}

function createVakData(
  data: OpleidingVakLesgroepDagGebruik[], groeperenOpVak: boolean, vak: string, onderwijssoort: string, periode: Periode, datum: Date
): VakData {
  const tempLesgroepData = new Map<string, LesGroepDagGebruikData>();
  let gegroepeerd: Map<string, OpleidingVakLesgroepDagGebruik[]>;
  if (groeperenOpVak) {
    gegroepeerd = groepeerData(data, vak, v => v.vak, v => v.onderwijssoort);
  } else {
    gegroepeerd = groepeerData(data, onderwijssoort, v => v.onderwijssoort, v => v.vak);
  }
  const tempData = gegroepeerd.get(groeperenOpVak ? onderwijssoort : vak) || [];
  const nav = bepaalVorigeEnVolgende(gegroepeerd.keys(), groeperenOpVak, vak, onderwijssoort);
  tempData.forEach(value => {
    const foundVakArray: LesGroepDagGebruikData = tempLesgroepData.get(value.lesgroepUuid)
      || createEmptyDagGebruikVakData(value, vak, onderwijssoort);
    foundVakArray.lesgroepDagData.push({
      date: new Date(value.dag),
      values: {
        min: value.daggebruik,
        avg: deelVeilig(value.daggebruik, value.actieveLeerlingen),
        max: value.daggebruik
      }
    });
    foundVakArray.gebruikPerLeerlingPerWeek += value.daggebruik;
    tempLesgroepData.set(value.lesgroepUuid, foundVakArray);
  });
  const dagen = periodeToAantalDagen(periode, datum);
  tempLesgroepData.forEach(value => {
    if (value.waarvanActief > 0) {
      value.gebruikPerLeerlingPerWeek = 7 * value.gebruikPerLeerlingPerWeek / (value.waarvanActief * dagen);
    }
  });
  const intervallen = createIntervallen(periode, datum);
  const vakData = Array.from(tempLesgroepData.values()).map((v) => {
    v.lesgroepDagData = intervallen.map(interval => v.lesgroepDagData
      .filter(({date}) => date >= interval.van && date <= interval.tot)
      .reduce((a, model) => {
        a.values.min += model.values.min;
        a.values.avg += model.values.avg;
        a.values.max += model.values.max;
        return a;
      }, {date: interval.van, values: {min: 0, avg: 0, max: 0}} as RangeLinechartModel));
    return v;
  }).sort(compareLesgroep(v => v.lesgroepLeerjaar, v => v.lesgroepNaam));
  const totaal = vakData.length > 0 ? createTotalRowDataVakData(vakData, intervallen, vak, onderwijssoort) : undefined;
  return {
    data: vakData,
    totaal,
    nav,
    hoofdniveau: groeperenOpVak ? vak : onderwijssoort,
    subniveau: groeperenOpVak ? onderwijssoort : vak
  };
}

function groepeerData(
  data: OpleidingVakLesgroepDagGebruik[], key: string,
  parent: (value: OpleidingVakLesgroepDagGebruik) => string, child: (value: OpleidingVakLesgroepDagGebruik) => string
): Map<string, OpleidingVakLesgroepDagGebruik[]> {
  return data.filter(value => parent(value) === key).reduce((map, value) => {
    const childKey = child(value);
    if (map.has(childKey)) {
      map.get(childKey).push(value);
    } else {
      map.set(childKey, [value]);
    }
    return map;
  }, new Map<string, OpleidingVakLesgroepDagGebruik[]>());
}

function createEmptyDagGebruikVakData(gebruik: OpleidingVakLesgroepDagGebruik, vak: string, onderwijssoort: string)
  : LesGroepDagGebruikData {
  return {
    vak,
    onderwijssoort,
    lesgroepNaam: gebruik.lesgroepNaam,
    lesgroepUuid: gebruik.lesgroepUuid,
    lesgroepLeerjaar: gebruik.lesgroepLeerjaar,
    leerlingenAantal: gebruik.leerlingenInLesgroep,
    leerlingenGeactiveerd: gebruik.leerlingenGeactiveerd,
    waarvanActief: gebruik.actieveLeerlingen,
    lesgroepDagData: [],
    gebruikPerLeerlingPerWeek: 0,
    docentUUIDs: [],
    docenten: []
  };
}

function bepaalVorigeEnVolgende(keys: Iterable<string>, groeperenOpVak: boolean, vak: string, onderwijssoort: string) {
  const sorted = Array.from(keys).sort(onderwijssoortComparator(k => k));
  if (sorted.length === 1) {
    return;
  }
  const index = sorted.findIndex(v => v === (groeperenOpVak ? onderwijssoort : vak));
  let vorige;
  let volgende;
  if (index === -1 ) {
    return;
  } else if (index === 0) {
    vorige = sorted[sorted.length - 1];
    volgende = sorted[1];
  } else if (index === sorted.length - 1) {
    vorige = sorted[index - 1];
    volgende = sorted[0];
  } else {
    vorige = sorted[index - 1];
    volgende = sorted[index + 1];
  }

  const vorigVak =  (groeperenOpVak) ? vak : vorige;
  const vorigeOnderwijssoort = (groeperenOpVak) ? vorige : onderwijssoort;

  const volgendVak =  (groeperenOpVak) ? vak : volgende;
  const volgendeOnderwijssoort = (groeperenOpVak) ? volgende : onderwijssoort;

  return {
    vorige: {vak: vorigVak, onderwijssoort: vorigeOnderwijssoort} as VakDataParams,
    volgende: {vak: volgendVak, onderwijssoort: volgendeOnderwijssoort} as VakDataParams
  };
}

function createTotalRowDataVakData(
  data: LesGroepDagGebruikData[], intervallen: {van: Date, tot: Date}[], vak: string, onderwijssoort: string
): LesGroepDagGebruikTotaal {
  let totaalAantalLeerlingen = 0;
  const totaalAantalGeactiveerd: RangeModelBuilder = rangeModelBuilder(1);
  const totaalAantalActief: RangeModelBuilder = rangeModelBuilder(1);
  const totaalGebruikPerLeerlingPerWeek = rangeModelBuilder();
  data.filter(lesgroep => lesgroep.leerlingenGeactiveerd > 0).forEach(lesgroep  => {
    totaalAantalLeerlingen += lesgroep.leerlingenAantal;
    totaalAantalGeactiveerd.update(deelVeilig(lesgroep.leerlingenGeactiveerd, lesgroep.leerlingenAantal));
    totaalAantalActief.update(deelVeilig(lesgroep.waarvanActief, lesgroep.leerlingenGeactiveerd));
    totaalGebruikPerLeerlingPerWeek.update(lesgroep.gebruikPerLeerlingPerWeek);
  });
  const totaalDagGebruik: RangeLinechartModel[] = intervallen.map((interval, kolom) => {
    const gebruik = rangeModelBuilder();
    data.forEach((value, rij) => {
      gebruik.update(data[rij].lesgroepDagData[kolom].values.avg);
    });
    return {date: interval.van, values: gebruik.build()};
  });
  return {
    vak,
    onderwijssoort,
    leerlingenAantal: totaalAantalLeerlingen,
    leerlingenGeactiveerd:  totaalAantalGeactiveerd.build(),
    waarvanActief: totaalAantalActief.build(),
    lesgroepDagData: totaalDagGebruik,
    gebruikPerLeerlingPerWeek: totaalGebruikPerLeerlingPerWeek.build()
  };
}

function berekenVakoverstijgendDashboardData(state: SchooldashboardState,
                                             {value}: Payload<[RuweSchooldashboardData, Periode, string, Date]>): SchooldashboardState {
  const [data, periode, ean, datum] = value;
  return {
    ...state,
    ruweSchooldashboardData: data,
    berekendeVakloosData: createLeermiddelData((!!ean ? data.ruweVakloosData : []).filter(d => d.ean === ean), periode, datum),
  };
}

function createLeermiddelData(
  dagGebruik: VakloosLeermiddelOpleidingDagGebruik[], periode: Periode, datum: Date): LeermiddelData {
  const tempOnderwijssoortJaarData = new Map<string, LeermiddelOnderwijssoortDagGebruikData>();
  dagGebruik.forEach(value => {
    const foundVakArray: LeermiddelOnderwijssoortDagGebruikData = tempOnderwijssoortJaarData.
    get(value.onderwijssoort?.concat(' ', String(value.leerjaar))) || createEmptyDagGebruikLeermiddelData(value);
    foundVakArray.leermiddelOnderwijssoortDagData.push({
      date: new Date(value.dag), values: {min: value.daggebruik, avg: value.daggebruik, max: value.daggebruik}
    });
    foundVakArray.gebruikPerLeerlingPerWeek += value.daggebruik;
    tempOnderwijssoortJaarData.set(value.onderwijssoort?.concat(' ', String(value.leerjaar)), foundVakArray);
  });
  const dagen = periodeToAantalDagen(periode, datum);
  tempOnderwijssoortJaarData.forEach(value => {
    if (value.waarvanActief > 0) {
      value.gebruikPerLeerlingPerWeek = 7 * value.gebruikPerLeerlingPerWeek / (value.waarvanActief * dagen);
    }
  });
  const intervallen  = createIntervallen(periode, datum);
  const data =  Array.from(tempOnderwijssoortJaarData.values()).map((v) => {
    v.leermiddelOnderwijssoortDagData = intervallen.map(interval => v.leermiddelOnderwijssoortDagData
      .filter(({date}) => date >= interval.van && date <= interval.tot)
      .reduce((a, model) => {
        a.values.min += model.values.min;
        a.values.avg += model.values.avg;
        a.values.max += model.values.max;
        return a;
      }, {date: interval.van, values: {min: 0, avg: 0, max: 0}} as RangeLinechartModel));
    return v;
  });
  const totaal = data.length !== 0 ? createTotalRowDataLeermiddelData(data, intervallen) : undefined;
  return {data, totaal};
}

function createEmptyDagGebruikLeermiddelData(gebruik: VakloosLeermiddelOpleidingDagGebruik): LeermiddelOnderwijssoortDagGebruikData {
  return {
    leermiddel: gebruik.titel,
    onderwijssoortJaar: `${gebruik.onderwijssoort} ${gebruik.leerjaar}`,
    leerlingenAantal: gebruik.leerlingen,
    leerlingenGeactiveerd: gebruik.leerlingenGeactiveerd,
    waarvanActief: gebruik.actieveLeerlingen,
    leermiddelOnderwijssoortDagData: [],
    gebruikPerLeerlingPerWeek: 0
  };
}

function createTotalRowDataLeermiddelData(data: LeermiddelOnderwijssoortDagGebruikData[], intervallen: {van: Date, tot: Date}[]):
  LeermiddelOnderwijssoortDagGebruikTotaal {
  let totaalAantalLeerlingen = 0;
  const totaalAantalGeactiveerd: RangeModelBuilder = rangeModelBuilder(1);
  const totaalAantalActief: RangeModelBuilder = rangeModelBuilder(1);
  const totaalGebruikPerLeerlingPerWeek = rangeModelBuilder();
  data.forEach(onderwijssoortJaar  => {
    totaalAantalLeerlingen += onderwijssoortJaar.leerlingenAantal;
    totaalAantalGeactiveerd.update(deelVeilig(onderwijssoortJaar.leerlingenGeactiveerd, onderwijssoortJaar.leerlingenAantal));
    totaalAantalActief.update(deelVeilig(onderwijssoortJaar.waarvanActief, onderwijssoortJaar.leerlingenGeactiveerd));
    totaalGebruikPerLeerlingPerWeek.update(onderwijssoortJaar.gebruikPerLeerlingPerWeek);
  });
  const totaalDagGebruik: RangeLinechartModel[] = intervallen.map((interval, kolom) => {
    const gebruik = rangeModelBuilder();
    data.forEach((value, rij) => {
      gebruik.update(data[rij].leermiddelOnderwijssoortDagData[kolom].values.avg);
    });
    return {date: interval.van, values: gebruik.build()};
  });
  return {
    leermiddel: data[0].leermiddel,
    leerlingenAantal: totaalAantalLeerlingen,
    leerlingenGeactiveerd:  totaalAantalGeactiveerd.build(),
    waarvanActief: totaalAantalActief.build(),
    leermiddelDagData: totaalDagGebruik,
    gebruikPerLeerlingPerWeek: totaalGebruikPerLeerlingPerWeek.build()
  };
}
