import {createAction, props} from '@ngrx/store';
import {AVGExportData, AVGExportDetails, AVGExportMederker} from '../../../generated/avg-client';
import {Datumbereik} from '../dashboardsettings/dashboardsettings.state';
import {MedewerkerNaam} from './exportoverzicht.state';

export const fetchExports = createAction('[Exportoverzicht] Fetch exports');

export const exportsFetched = createAction('[Exportoverzicht] Exports fetched', props<{ exportData: AVGExportData }>());
export const medewerkerUuidsFetched = createAction('[Exportoverzicht] Medewerker UUIDs fetched', props<{ medewerkers: AVGExportMederker[] }>());

export const fetchExportDetails = createAction('[Exportoverzicht] Fetch export details', props<{ id: number }>());

export const exportDetailsFetched = createAction('[Exportoverzicht Export details fetched]', props<{
  id: number,
  exportDetails: AVGExportDetails
}>());

export const setDatumBereik = createAction('[Exportoverzicht] Set datum bereik', props<{ datumBereik: Datumbereik }>());

export const setDatumBereiken = createAction('[Exportoverzicht] Set datum bereiken', props<{
  datumBereiken: Datumbereik[]
}>());

export const setFilterDatums = createAction('[Exportoverzicht] Set filter datums', props<{
  begindatum: string,
  einddatum: string
}>());

export const resetFilter = createAction('[Exportoverzicht] Reset export filter');

export const setFilterMedewerker = createAction('[Exportoverzicht] Set filter medewerker', props<{
  medewerker: string,
}>());

export const setPage = createAction('[Exportoverzicht] Set page', props<{
    page: number
}>());

export const openSidebarRequested = createAction('[Exportoverzicht] Open sidebar requested', props<{
  list: string[]
}>());

export const closeSidebarRequested = createAction('[Exportoverzicht] Close sidebar requested');

export const fetchMedewerkerNamen = createAction('[Exportoverzicht] Fetch medewerker namen', props<{
  uuids: string[]
}>());

export const medewerkerNamenFetched = createAction('[Exportoverzicht] Medewerker namen fetched', props<{
  namen: MedewerkerNaam[];
}>());
