import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WidgetListcountComponent } from './widget/widget-listcount/widget-listcount.component';
import { WidgetProgressbarComponent } from './widget/widget-progressbar/widget-progressbar.component';
import { WidgetPiechartComponent } from './widget/widget-piechart/widget-piechart.component';
import { WidgetEmptyComponent } from './widget/widget-empty/widget-empty.component';
import { WidgetBaseComponent } from './widget/widget-base/widget-base.component';
import { ChartsModule } from 'ng2-charts';
import { BigInfoDetailComponent } from './infoblocks/big-info-detail/big-info-detail.component';
import {
  EnkelLeermiddelActivatieOfGebruikComponent
} from './widget/enkel-leermiddel-activatie-of-gebruik/enkel-leermiddel-activatie-of-gebruik.component';
import { EnkelLeermiddelActivatiegraadComponent } from './widget/enkel-leermiddel-activatiegraad/enkel-leermiddel-activatiegraad.component';
import { KengetalComponent } from './widget/kengetal/kengetal.component';
import { KengetaloverzichtComponent } from './widget/kengetaloverzicht/kengetaloverzicht.component';
import { ProgressBarActivatiegraadComponent } from './widget/progress-bar-activatiegraad/progress-bar-activatiegraad.component';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { LineChartComponent } from './widget/line-chart/line-chart.component';
import { LeermiddeltabelComponent } from './leermiddeltabel/leermiddeltabel.component';
import { MatTableModule } from '@angular/material/table';
import { ProgressbarPercentageOnlyComponent } from './widget/progressbar-percentage-only/progressbar-percentage-only.component';
import { DatumbereikComponent } from './datumbereik/datumbereik.component';
import { RangeBarComponent } from './range-bar/range-bar.component';
import { ActivatiegetalComponent } from './activatiegetal/activatiegetal.component';
import { RangeLinechartComponent } from './range-linechart/range-linechart.component';
import { VestigingendropdownComponent } from './vestigingendropdown/vestigingendropdown.component';
import { LeermiddeloverzichtComponent } from './leermiddeloverzicht/leermiddeloverzicht.component';
import { EnkelActivatiegraadLesgroepenComponent } from './widget/enkel-activatiegraad-lesgroepen/enkel-activatiegraad-lesgroepen.component';
import { RangeLinechartVoorBazenbannerComponent } from './range-linechart-voor-bazenbanner/range-linechart-voor-bazenbanner.component';
import { ToggleButtonsComponent } from './toggle-buttons/toggle-buttons.component';
import { SharedModule } from '../shared/shared.module';
import { RangeLinechartVoorLesgroeptabelComponent} from './range-linechart-voor-lesgroeptabel/range-linechart-voor-lesgroeptabel.component';
import { ProgressbarComponent } from './progressbar/progressbar.component';
import { ActivatiegetalLesgroepdashboardComponent } from './activatiegetal-lesgroepdashboard/activatiegetal-lesgroepdashboard.component';
import { ConnectLoaderComponent } from './connect-loader/connect-loader.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { ControlsComponent } from './controls/controls.component';
import { BlauweBalkComponent } from './blauwe-balk/blauwe-balk.component';
import { MinPercent } from './pipes/min-percent';
import { FillScreenDirective } from './directives/fill-screen.directive';
import { VakOrVakOnbekend} from './pipes/vakOrVakOnbekend';
import { PlaceholderComponent } from './placeholder/placeholder.component';
import { ContentHostDirective } from './directives/content-host.directive';
import { HeaderContentComponent } from './header-content/header-content.component';
import { InstellingComponent } from './instelling/instelling.component';
import { PopoutButtonComponent } from './popout-button/popout-button.component';
import { SelectVestigingenComponent } from './select-vestigingen/select-vestigingen.component';
import { LeermiddelvakkenlijstComponent } from './leermiddelvakkenlijst/leermiddelvakkenlijst.component';
import { SelectLeerlingenComponent } from './select-leerlingen/select-leerlingen.component';
import { SelectTableComponent } from './select-table/select-table.component';
import { SelectLeerjarenComponent } from './select-leerjaren/select-leerjaren.component';
import { BottombarComponent } from '../bottombar/bottombar.component';
import { KoppelingAanmakenStap1Component } from './koppeling-aanmaken-stap1/koppeling-aanmaken-stap1.component';
import { KoppelingAanmakenStap2Component } from './koppeling-aanmaken-stap2/koppeling-aanmaken-stap2.component';
import { KoppelingAanmakenStap3Component } from './koppeling-aanmaken-stap3/koppeling-aanmaken-stap3.component';
import { GoBackHeaderComponent } from './go-back-header/go-back-header.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { SelectDateComponent } from './select-date/select-date.component';
import { KoppelingHeaderComponent } from './koppeling-header/koppeling-header.component';
import { KoppelingMenuComponent } from './koppeling-menu/koppeling-menu.component';
import { KoppelingDatatoegangComponent } from './koppeling-datatoegang/koppeling-datatoegang.component';
import { InfoBlokComponent } from './info-blok/info-blok.component';
import { WebserviceTegelComponent } from './webservice-tegel/webservice-tegel.component';
import { WebserviceGebruikComponent } from './webservice-gebruik/webservice-gebruik.component';
import { WebserviceDatatoegangComponent } from './webservice-datatoegang/webservice-datatoegang.component';
import { MultiRangeWeekBarChartComponent } from './multi-range-week-bar-chart/multi-range-week-bar-chart.component';
import { KoppelpartijTegelComponent } from './koppelpartij-tegel/koppelpartij-tegel.component';
import { KoppelingOmschrijvingComponent } from '../koppeling-omschrijving/koppeling-omschrijving.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { InputTextComponent } from './input-text/input-text.component';
import { InputReadPermissionComponent } from './input-read-permission/input-read-permission.component';
import { InputPermissionCategoryComponent } from './input-permission-category/input-permission-category.component';
import { InputPermissionEndpointComponent } from './input-permission-endpoint/input-permission-endpoint.component';
import { InputComboboxComponent } from './input-combobox/input-combobox.component';
import { InputWritePermissionComponent } from './input-write-permission/input-write-permission.component';
import { InputTextareaComponent } from './input-textarea/input-textarea.component';
import { InputCheckboxComponent } from './input-checkbox/input-checkbox.component';
import { ConnectEditorComponent } from '../connect-editor/connect-editor.component';
import { SliderAfbeeldingenToevoegenComponent } from '../slider-afbeeldingen-toevoegen/slider-afbeeldingen-toevoegen.component';
import { AvgOrganisatieSelectorComponent } from '../old-dashboarding/avg-organisatie-selector/avg-organisatie-selector.component';
import { ModalComponent } from './modal/modal.component';
import { EnkelActivatiegraadLesgroepenNewComponent } from './widget/enkel-activatiegraad-lesgroepen-new/enkel-activatiegraad-lesgroepen-new.component';
import { RangeLinechartVoorBazenbannerNewComponent } from './range-linechart-voor-bazenbanner-new/range-linechart-voor-bazenbanner-new.component';
import { RangeLinechartKeybasedComponent } from './range-linechart-keybased/range-linechart-keybased.component';
import { RangeBarNewComponent } from './range-bar-new/range-bar-new.component';
import { TerugKnopNewComponent } from '../new-dashboard/terug-knop-new/terug-knop-new.component';
import { VakOnderwijsSwitchNewComponent } from '../new-dashboard/vak-onderwijs-switch-new/vak-onderwijs-switch-new.component';
import { SemKoppelingSchoolPanelComponent } from './sem-koppeling-school-panel/sem-koppeling-school-panel.component';
import { InputToggleComponent } from './input-toggle/input-toggle.component';
import { SemKoppelpartijTegelComponent } from './sem-koppelpartij-tegel/sem-koppelpartij-tegel.component';
import { SemKoppelingDatatoegangComponent } from './sem-koppeling-datatoegang/sem-koppeling-datatoegang.component';
import { InputRadiobuttonComponent } from './input-radiobutton/input-radiobutton.component';
import { RangeLinechartKeybasedNewComponent } from './range-linechart-keybased-new/range-linechart-keybased-new.component';

@NgModule({
    declarations: [
        WidgetListcountComponent,
        WidgetProgressbarComponent,
        WidgetPiechartComponent,
        WidgetEmptyComponent,
        WidgetBaseComponent,
        BigInfoDetailComponent,
        BigInfoDetailComponent,
        EnkelLeermiddelActivatieOfGebruikComponent,
        EnkelLeermiddelActivatiegraadComponent,
        KengetalComponent,
        KengetaloverzichtComponent,
        ProgressBarActivatiegraadComponent,
        KengetalComponent,
        KengetaloverzichtComponent,
        ProgressBarActivatiegraadComponent,
        LineChartComponent,
        LeermiddeltabelComponent,
        ProgressbarPercentageOnlyComponent,
        DatumbereikComponent,
        RangeBarComponent,
        ActivatiegetalComponent,
        VestigingendropdownComponent,
        RangeLinechartComponent,
        LeermiddeloverzichtComponent,
        EnkelActivatiegraadLesgroepenComponent,
        EnkelActivatiegraadLesgroepenNewComponent,
        RangeLinechartVoorBazenbannerComponent,
        RangeLinechartVoorBazenbannerNewComponent,
        ToggleButtonsComponent,
        RangeLinechartVoorLesgroeptabelComponent,
        ProgressbarComponent,
        ActivatiegetalLesgroepdashboardComponent,
        ConnectLoaderComponent,
        ControlsComponent,
        BlauweBalkComponent,
        MinPercent,
        FillScreenDirective,
        ContentHostDirective,
        VakOrVakOnbekend,
        PlaceholderComponent,
        HeaderContentComponent,
        InstellingComponent,
        PopoutButtonComponent,
        SelectVestigingenComponent,
        LeermiddelvakkenlijstComponent,
        SelectLeerlingenComponent,
        SelectTableComponent,
        SelectLeerjarenComponent,
        BottombarComponent,
        KoppelingAanmakenStap1Component,
        KoppelingAanmakenStap2Component,
        KoppelingAanmakenStap3Component,
        GoBackHeaderComponent,
        SelectDateComponent,
        KoppelingHeaderComponent,
        KoppelingMenuComponent,
        KoppelingDatatoegangComponent,
        InfoBlokComponent,
        WebserviceTegelComponent,
        WebserviceGebruikComponent,
        WebserviceDatatoegangComponent,
        MultiRangeWeekBarChartComponent,
        KoppelpartijTegelComponent,
        KoppelingOmschrijvingComponent,
        InputTextComponent,
        InputReadPermissionComponent,
        InputPermissionCategoryComponent,
        InputPermissionEndpointComponent,
        InputComboboxComponent,
        InputWritePermissionComponent,
        InputTextareaComponent,
        InputCheckboxComponent,
        ConnectEditorComponent,
        SliderAfbeeldingenToevoegenComponent,
        AvgOrganisatieSelectorComponent,
        ModalComponent,
        RangeLinechartKeybasedComponent,
        RangeBarNewComponent,
        TerugKnopNewComponent,
        VakOnderwijsSwitchNewComponent,
        SemKoppelingSchoolPanelComponent,
        InputToggleComponent,
        SemKoppelpartijTegelComponent,
        SemKoppelingDatatoegangComponent,
        InputRadiobuttonComponent,
        RangeLinechartKeybasedNewComponent
    ],
    exports: [
        BigInfoDetailComponent,
        WidgetListcountComponent,
        WidgetProgressbarComponent,
        WidgetPiechartComponent,
        WidgetEmptyComponent,
        WidgetBaseComponent,
        WidgetBaseComponent,
        EnkelLeermiddelActivatieOfGebruikComponent,
        EnkelLeermiddelActivatiegraadComponent,
        KengetalComponent,
        KengetaloverzichtComponent,
        ProgressBarActivatiegraadComponent,
        LineChartComponent,
        LeermiddeltabelComponent,
        MatTableModule,
        DatumbereikComponent,
        RangeBarComponent,
        ActivatiegetalComponent,
        RangeLinechartComponent,
        VestigingendropdownComponent,
        ToggleButtonsComponent,
        RangeLinechartVoorLesgroeptabelComponent,
        LeermiddeloverzichtComponent,
        ProgressbarComponent,
        ActivatiegetalLesgroepdashboardComponent,
        ConnectLoaderComponent,
        ControlsComponent,
        BlauweBalkComponent,
        MinPercent,
        FillScreenDirective,
        ContentHostDirective,
        VakOrVakOnbekend,
        PlaceholderComponent,
        HeaderContentComponent,
        PopoutButtonComponent,
        SelectVestigingenComponent,
        SelectLeerlingenComponent,
        LeermiddelvakkenlijstComponent,
        KoppelingAanmakenStap1Component,
        KoppelingAanmakenStap2Component,
        KoppelingAanmakenStap3Component,
        BottombarComponent,
        GoBackHeaderComponent,
        KoppelingHeaderComponent,
        KoppelingMenuComponent,
        KoppelingDatatoegangComponent,
        MultiRangeWeekBarChartComponent,
        WebserviceTegelComponent,
        WebserviceGebruikComponent,
        WebserviceDatatoegangComponent,
        KoppelpartijTegelComponent,
        KoppelingOmschrijvingComponent,
        KoppelingDatatoegangComponent,
        InputTextComponent,
        InputReadPermissionComponent,
        InputPermissionCategoryComponent,
        InputPermissionEndpointComponent,
        InputComboboxComponent,
        InputTextareaComponent,
        InputCheckboxComponent,
        ConnectEditorComponent,
        SliderAfbeeldingenToevoegenComponent,
        AvgOrganisatieSelectorComponent,
        InfoBlokComponent,
        ModalComponent,
        EnkelActivatiegraadLesgroepenComponent,
        EnkelActivatiegraadLesgroepenNewComponent,
        RangeLinechartVoorBazenbannerComponent,
        RangeLinechartVoorBazenbannerNewComponent,
        RangeLinechartKeybasedComponent,
        RangeBarNewComponent,
        TerugKnopNewComponent,
        VakOnderwijsSwitchNewComponent,
        SemKoppelingSchoolPanelComponent,
        InputToggleComponent,
        SemKoppelpartijTegelComponent,
        SemKoppelingDatatoegangComponent,
        InputRadiobuttonComponent,
        RangeLinechartKeybasedNewComponent
    ],
  imports: [
    CommonModule,
    ChartsModule,
    Ng2GoogleChartsModule,
    MatTableModule,
    SharedModule,
    ClickOutsideModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatInputModule,
    NgImageSliderModule
  ]
})
export class LayoutModule { }
