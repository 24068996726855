import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LeermiddelvakkoppelingFacade} from '../../old-dashboarding/state/leermiddelvakkoppeling/leermiddelvakkoppeling.facade';
import {Subscription} from 'rxjs';
import {LeermiddelVakKoppelingInfo, VakInfo} from '../../../generated/graphql';
import {Store} from '@ngrx/store';
import {AppState} from '../../state/app.state';
import {createTabelData, LeermiddelvakkenlijstData} from './leermiddelvakkenlijst.model';

@Component({
  selector: 'app-leermiddelvakkenlijst',
  templateUrl: './leermiddelvakkenlijst.component.html',
  styleUrls: ['./leermiddelvakkenlijst.component.scss']
})
export class LeermiddelvakkenlijstComponent implements OnInit, OnDestroy {

  @Input() leermiddelVakKoppelingInfo: LeermiddelVakKoppelingInfo;
  @Input() top: number;
  @Input() width: number;

  vakkenlijst: VakInfo[];
  data: LeermiddelvakkenlijstData[];
  private subscriptions_: Subscription[] = [];

  constructor(private facade: LeermiddelvakkoppelingFacade,
              private store: Store<AppState>) {
  }

  ngOnInit(): void {
    this.subscriptions_ = [
      this.facade.selectVestigingVakkenData().subscribe(data => {
        if (data.length !== 0) {
          this.vakkenlijst = data;
          this.data = createTabelData(this.leermiddelVakKoppelingInfo, this.vakkenlijst);
        }
      })
    ];
  }

  toggle(row: LeermiddelvakkenlijstData) {
    const info = {...this.leermiddelVakKoppelingInfo};
    let vakken = [...info.vakken];
    if (row.geselecteerd) {
      vakken = vakken.filter(v => v.afkorting !== row.afkorting);
    } else {
      vakken.push({
        naam: row.naam,
        afkorting: row.afkorting,
        id: row.id
      });
    }
    info.vakken = vakken;
    this.facade.updateLeermiddelVakKoppeling(info);
    this.leermiddelVakKoppelingInfo = info;
    row.geselecteerd = !row.geselecteerd;
  }

  sluit() {
    this.facade.setSelectedLeermiddelVakKoppeling(null);
  }

  ngOnDestroy(): void {
    this.subscriptions_.forEach(s => s.unsubscribe());
  }
}
