import { Observable, BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import {map, withLatestFrom} from 'rxjs/operators';
import { AVGExtendedOnderwijssoort } from '../../dto/avg-dashboard-model-classes';
import { LeerlingFilters } from '../select-leerlingen/select-leerlingen-component-view-model';
import { VestigingRow } from '../select-vestigingen/select-vestigingen.component';

export class KoppelingAanmakenStap1ComponentViewModel {
    public onGetKoppelpartijNaam: Observable<string>;
    public onGetVestigingenSelectie: Observable<VestigingRow[]>;
    public onGetVestigingenSelectieFiltered: Observable<VestigingRow[]>;
    public onGetMaxVestigingSelectieComponentHeight: BehaviorSubject<number> = new BehaviorSubject(0);
    public onGetIsVestigingSelectieOpen: Observable<boolean>;
    public doSetIsVestigingSelectieOpen: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public doSetVestigingenSelectie: BehaviorSubject<VestigingRow[]>;
    public doSetVestigingenSelectieFiltered: BehaviorSubject<VestigingRow[]>;
    public onGetGrayOutYOffset: BehaviorSubject<number> = new BehaviorSubject(0);
    public onGetVestigingPanelYOffset: BehaviorSubject<number> = new BehaviorSubject(0);
    public onGetToonVestigingSelectieButton: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public doSetVestigingPanelOffset: BehaviorSubject<number> = new BehaviorSubject(0);

    public onGetToonLeerlingenToggleButton: Observable<boolean>;
    public onGetToonInfoBlock: Observable<boolean>;

    public onGetMaxLeerlingSelectieComponentHeight: BehaviorSubject<number> = new BehaviorSubject(0);
    public onGetAlleOnderwijssoorten: BehaviorSubject<AVGExtendedOnderwijssoort[]> = new BehaviorSubject([]);
    public onGetLeerlingFilters: Observable<LeerlingFilters>;
    public doSetIsLeerlingSelectieOpen: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public doSubmitLeerlingFilters: BehaviorSubject<LeerlingFilters>;
    public doSetLeerlingSelectieDesiredHeight: BehaviorSubject<number> = new BehaviorSubject(0);
    public onGetLeerlingPanelYOffset: BehaviorSubject<number> = new BehaviorSubject(0);
    public onGetIsLeerlingSelectieOpen: Observable<boolean>;
    public onGetToonLeerlingSelectie: Observable<boolean>;
    public doSetToonLeerlingSelectie: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public doSetLeerlingPanelOffset: BehaviorSubject<number> = new BehaviorSubject(0);
    public doSetIsSelectAlleLeerlingen: BehaviorSubject<boolean> = new BehaviorSubject(false);

    public onGetToonGeselecteerdeLeerlingen: Observable<boolean>;

    public doSetWindowHeight: BehaviorSubject<number> = new BehaviorSubject(0);
    public doSetScrollOffset: BehaviorSubject<number> = new BehaviorSubject(0);

    private subscriptions: Subscription[] = [];

    public constructor(private parent: KoppelingAanmakenStap1ComponentViewModelParent) {
        this.onGetVestigingenSelectie = this.parent.onGetVestigingenSelectie;
        this.onGetVestigingenSelectieFiltered = this.parent.onGetVestigingenSelectieFiltered;
        this.doSetVestigingenSelectie = this.parent.doSetVestigingenSelectie;
        this.doSetVestigingenSelectieFiltered = this.parent.doSetVestigingenSelectieFiltered;
        this.onGetLeerlingFilters = this.parent.onGetLeerlingFilters;
        this.doSubmitLeerlingFilters = this.parent.doSubmitLeerlingFilters;
        this.onGetKoppelpartijNaam = this.parent.onGetKoppelpartijNaam;

        this.onGetToonInfoBlock = this.parent.onGetLeerlingFiltersEnabled.pipe(
          map(f => (f === false))
        );

        this.setupVestigingenPanel();
        this.setupLeerlingenPanel();

        this.subscriptions.push(combineLatest([this.onGetToonLeerlingenToggleButton, this.onGetToonInfoBlock, this.doSetIsSelectAlleLeerlingen, this.onGetLeerlingFilters]).subscribe(([t, i, v, f]) => {
            this.parent.doSetIsStap1Compleet.next(i || (t && (v || f.validSelection))); }));
    }

    public onDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    private setupVestigingenPanel(): void {
        // vestigingenpanel
        this.onGetIsVestigingSelectieOpen = this.doSetIsVestigingSelectieOpen;

        this.subscriptions.push(combineLatest([this.doSetWindowHeight, this.doSetScrollOffset, this.onGetVestigingenSelectie, this.doSetVestigingPanelOffset])
            .subscribe(([windowHeight, scrollOffset, vestigingen, vestigingPanelOffset]) => {
                const vals = this.determineVestigingPanelPositionAndHeight(vestigingen.length, vestigingPanelOffset, windowHeight, scrollOffset);
                this.onGetVestigingPanelYOffset.next(vals.vestigingPanelYOffset);
                this.onGetGrayOutYOffset.next(vals.grayOutYOffset);
                this.onGetMaxVestigingSelectieComponentHeight.next(vals.vestigingPanelHeight);
            }));

        this.subscriptions.push(combineLatest([
          this.onGetVestigingenSelectie,
          this.onGetIsVestigingSelectieOpen,
          this.parent.onGetLeerlingFiltersEnabled
        ]).subscribe(
            ([selection, isOpen, filterEnabled]) => {
              this.onGetToonVestigingSelectieButton.next(
                (selection.filter(s => s.selected).length === 0 || isOpen) && filterEnabled
              );
            }
        ));

        this.subscriptions.push(this.onGetVestigingenSelectie.pipe(
          withLatestFrom(this.onGetLeerlingFilters)
        ).subscribe(([selectie, leerlingFilters]) => {
            const aangebodenOnderwijssoorten = selectie.filter(v => v.selected).map(v => v.vestiging.aangebodenOnderwijssoorten).reduce((pos, cos) => pos.concat(cos), []);
            this.onGetAlleOnderwijssoorten.next(aangebodenOnderwijssoorten);
            let resetLeerlingFilters = false;
            leerlingFilters.leerjaren.forEach(lj => {
              if (!aangebodenOnderwijssoorten.find(os => os.leerjaren.includes(lj))) {
                resetLeerlingFilters = true;
              }
            });
            leerlingFilters.onderwijssoortNamen.forEach(osn => {
              if (!aangebodenOnderwijssoorten.find(os => os.naam.toLowerCase() === osn)) {
                resetLeerlingFilters = true;
              }
            });
            leerlingFilters.vakNamen.forEach(vn => {
              if (!aangebodenOnderwijssoorten.find(os => os.aangebodenVakken.map(v => v.naam.toLowerCase()).includes(vn))) {
                resetLeerlingFilters = true;
              }
            });
            if (selectie.find(v => v.selected) && !leerlingFilters.validSelection) {
              resetLeerlingFilters = true;
            }
            if (resetLeerlingFilters) {
                this.doSubmitLeerlingFilters.next(this.emptyLeerlingFilters());
            }
        }));
    }

    private determineVestigingPanelPositionAndHeight(aantalBeschikbareVestigingen: number, vestigingSelectieYOffset: number, windowHeight: number, scrollOffset: number)
        : { vestigingPanelHeight: number, vestigingPanelYOffset: number, grayOutYOffset: number } {
        let panelHeight = 0;
        const desiredHeight = aantalBeschikbareVestigingen * 49 + 51;
        const originalOffset = vestigingSelectieYOffset;

        let popoutTop = originalOffset;

        if (originalOffset + desiredHeight > windowHeight) {
            popoutTop = originalOffset - ((originalOffset + desiredHeight) - windowHeight);
        }
        if (popoutTop < 0) {
            panelHeight = desiredHeight + popoutTop;
            popoutTop = 0;
        }
        popoutTop += scrollOffset;

        return { vestigingPanelHeight: panelHeight, vestigingPanelYOffset: popoutTop, grayOutYOffset: window.pageYOffset };
    }

    private emptyLeerlingFilters(): LeerlingFilters {
        return {
            validSelection: false,
            alleLeerlingenGeselecteerd: true,
            onderwijssoortNamen: [],
            geselecteerdeOnderwijssoorten: [],
            alleOnderwijssoortenGeselecteerd: false,
            leerjaren: [],
            geselecteerdeLeerjaren: [],
            alleLeerjarenGeselecteerd: false,
            vakNamen: [],
            geselecteerdeVakken: [],
            alleVakkenGeselecteerd: false
        };
    }

    private setupLeerlingenPanel(): void {
        // Leerlingen toggle button
        this.onGetToonLeerlingenToggleButton = combineLatest([this.onGetVestigingenSelectie, this.parent.onGetLeerlingFiltersEnabled]).pipe(
          map(([v, f]) => v.find(vestiging => vestiging.selected) && f));

        this.subscriptions.push(this.onGetLeerlingFilters.subscribe(filters => {
            this.doSetIsSelectAlleLeerlingen.next(filters.alleLeerlingenGeselecteerd);
            this.doSetToonLeerlingSelectie.next(!filters.alleLeerlingenGeselecteerd);
        }));


        // Leerlingen panel

        // toon leerlingselectieknop (verborgen als je alle leerlingen geselecteerd hebt)
        this.onGetToonLeerlingSelectie = this.doSetToonLeerlingSelectie;
        // toon leerlingselectiepanel (dus of het modal window getoond moet worden)
        this.onGetIsLeerlingSelectieOpen = this.doSetIsLeerlingSelectieOpen;

        this.subscriptions.push(combineLatest([this.doSetWindowHeight, this.doSetScrollOffset, this.doSetLeerlingPanelOffset, this.doSetLeerlingSelectieDesiredHeight, this.onGetToonLeerlingSelectie])
            .subscribe(([windowHeight, scrollOffset, leerlingPanelOffset, desiredHeight]) => {
                if (desiredHeight === undefined || Number.isNaN(desiredHeight) || desiredHeight === 0) return;
                const vals = this.determineLeerlingPanelPositionAndHeight(leerlingPanelOffset, windowHeight, scrollOffset, desiredHeight);
                this.onGetLeerlingPanelYOffset.next(vals.leerlingPanelYOffset);
                this.onGetGrayOutYOffset.next(vals.grayOutYOffset);
                this.onGetMaxLeerlingSelectieComponentHeight.next(vals.leerlingPanelHeight);
            }));

        this.onGetToonGeselecteerdeLeerlingen = combineLatest([this.onGetLeerlingFilters, this.doSetIsLeerlingSelectieOpen]).pipe(map(([leerlingFilters, isLeerlingSelectieOpen]) => {
            return !isLeerlingSelectieOpen && leerlingFilters.validSelection;
        }));
    }

    private determineLeerlingPanelPositionAndHeight(leerlingSelectieYOffset: number, windowHeight: number, scrollOffset: number, desiredHeight: number)
        : { leerlingPanelHeight: number, leerlingPanelYOffset: number, grayOutYOffset: number } {
        const originalOffset = leerlingSelectieYOffset + 68;

        let popoutTop = originalOffset;
        let panelHeight = desiredHeight;
        if (panelHeight > windowHeight * 0.8) panelHeight = windowHeight * 0.8;

        if ((originalOffset + panelHeight + 51) > windowHeight) {
            popoutTop = originalOffset - ((originalOffset + panelHeight + 55) - windowHeight);
        }
        if (popoutTop < 0) {
            panelHeight = panelHeight + popoutTop;
            popoutTop = 0;
        }
        popoutTop += scrollOffset;
        return { leerlingPanelHeight: panelHeight, leerlingPanelYOffset: popoutTop, grayOutYOffset: window.pageYOffset };
    }
}

export interface KoppelingAanmakenStap1ComponentViewModelParent {
    onGetVestigingenSelectie: Observable<VestigingRow[]>;
    onGetVestigingenSelectieFiltered: Observable<VestigingRow[]>;
    doSetVestigingenSelectie: BehaviorSubject<VestigingRow[]>;
    doSetVestigingenSelectieFiltered: BehaviorSubject<VestigingRow[]>;
    onGetLeerlingFilters: Observable<LeerlingFilters>;
    doSubmitLeerlingFilters: BehaviorSubject<LeerlingFilters>;
    onGetLeerlingFiltersEnabled: Observable<boolean>;
    onGetKoppelpartijNaam: Observable<string>;
    doSetIsStap1Compleet: BehaviorSubject<boolean>;
}
