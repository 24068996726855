import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, ViewChild} from '@angular/core';
import {TableModel} from './table.model';
import { MatTable } from '@angular/material/table';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./table.component.scss']
})
export class TableComponent<M> implements OnChanges {
  @Input()
  model: TableModel<M>;

  @Output()
  rowClick = new EventEmitter<M>();

  @ViewChild(MatTable, {static: true})
  private table_: MatTable<M>;

  onClickedRow(rowModel: M) {
    this.rowClick.emit(rowModel);
  }

  ngOnChanges(): void {
    if (this.model.showFooters) {
      // Forceer een update van de footer row
      this.table_.removeFooterRowDef(null);
    }
  }
}
