import {Injectable} from '@angular/core';
import {BaseFacade} from '../base.facade';
import {Store} from '@ngrx/store';
import {AppState, selectVakdashboard} from '../app.state';
import {combineLatest, Observable} from 'rxjs';
import {selectRouteParam} from '../router/router.selectors';
import {map, withLatestFrom} from 'rxjs/operators';
import {
  selectDocentUUIDs,
  selectNextOWS,
  selectNextVak,
  selectPrevOWS,
  selectPrevVak,
  selectRows,
  selectTotaalRegel
} from './vakdashboard.selectors';
import {DashboardsettingsFacade} from '../dashboardsettings/dashboardsettings.facade';
import {LesgroepInformatieRegel, TotaalInformatieRegel} from './vakdashboard.state';
import {RangeModel} from '../../layout/model/range.model';
import {RangeLinechartModelKeyBased} from '../../layout/range-linechart/range-linechart.model';
import {PERIODE} from '../dashboardsettings/dashboardsettings.state';
import {routingConstants} from '../../stateless/routing.constants';

@Injectable()
export class VakdashboardNewFacade extends BaseFacade {
  constructor(store: Store<AppState>, private settings: DashboardsettingsFacade) {
    super(store);
  }

  getVakNaamURL(): Observable<string> {
    return this.select(selectRouteParam('vak'));
  }

  getOnderwijssoortNaamURL(): Observable<string> {
    return this.select(selectRouteParam('onderwijssoort'));
  }

  isVakdashboardFilled(): Observable<boolean> {
    return combineLatest([
      this.select(selectVakdashboard),
      this.getVakNaamURL(),
      this.getOnderwijssoortNaamURL()
    ]).pipe(map(([vakdashboard, vak, os]) => vakdashboard.vak === vak && vakdashboard.onderwijssoort === os));
  }

  getUniqueDocentUUIDs(): Observable<string[]> {
    return this.select(selectDocentUUIDs).pipe(map(uuids => ([...new Set(uuids)])));
  }

  getNextOWS(): Observable<string> {
    return this.select(selectNextOWS).pipe(map(ows => ows?.naam));
  }

  getPrevOWS(): Observable<string> {
    return this.select(selectPrevOWS).pipe(map(ows => ows?.naam));
  }

  getNextVak(): Observable<string> {
    return this.select(selectNextVak).pipe(map(vak => vak?.naam));
  }

  getPrevVak(): Observable<string> {
    return this.select(selectPrevVak).pipe(map(vak => vak?.naam));
  }

  getPeriode(): Observable<PERIODE> {
    return this.settings.getPeriodeOrDefault();
  }

  getHoofdItem(): Observable<string> {
    return combineLatest([
      this.settings.isGegroepeerdOpVak(),
      this.getVakNaamURL(),
      this.getOnderwijssoortNaamURL(),
      ]).pipe(map(([isGegroepeerdOpVak, vak, ows]) => {
        return isGegroepeerdOpVak ? vak : ows;
    }));
  }

  getSubItem(): Observable<string> {
    return combineLatest([
      this.settings.isGegroepeerdOpVak(),
      this.getVakNaamURL(),
      this.getOnderwijssoortNaamURL(),
    ]).pipe(map(([isGegroepeerdOpVak, vak, ows]) => {
      return isGegroepeerdOpVak ? ows : vak;
    }));
  }

  getPrevPathElements(): Observable<string[]> {
    return combineLatest([
      this.settings.isGegroepeerdOpVak(),
      this.getVakNaamURL(),
      this.getOnderwijssoortNaamURL(),
      this.getPrevVak(),
      this.getPrevOWS(),
    ]).pipe(map(([isGegroepeerdOpVak, vak, ows, prevVak, prevOWS]) => {
      if (isGegroepeerdOpVak) {
        return [routingConstants.VAK_DASHBOARD_NEW, vak, prevOWS];
      } else {
        return [routingConstants.VAK_DASHBOARD_NEW, prevVak, ows];
      }
    }));
  }

  getNextPathElements(): Observable<string[]> {
    return combineLatest([
      this.settings.isGegroepeerdOpVak(),
      this.getVakNaamURL(),
      this.getOnderwijssoortNaamURL(),
      this.getNextVak(),
      this.getNextOWS(),
    ]).pipe(map(([isGegroepeerdOpVak, vak, ows, nextVak, nextOWS]) => {
      if (isGegroepeerdOpVak) {
        return [routingConstants.VAK_DASHBOARD_NEW, vak, nextOWS];
      } else {
        return [routingConstants.VAK_DASHBOARD_NEW, nextVak, ows];
      }
    }));
  }

  getRows(): Observable<LesgroepInformatieRegel[]> {
    return this.select(selectRows);
  }

  getTotaalRegel(): Observable<TotaalInformatieRegel> {
    return this.select(selectTotaalRegel);
  }

  getActivatieTotaal(): Observable<RangeModel> {
    return this.select(selectTotaalRegel).pipe(map( row => (
      row ? {min: row.laagsteActivatie, avg: row.activatiePercentage, max: row.hoogsteActivatie, tot: 100} : null
    )));
  }

  getRecentActiefTotaal(): Observable<RangeModel> {
    return this.select(selectTotaalRegel).pipe(map( row => (
      row ? {min: row.laagstePercentageActief, avg: row.gemiddeldPercentageActief, max: row.hoogstePercentageActief, tot: 100} : null
    )));
  }

  getGebruikTotaal(): Observable<RangeLinechartModelKeyBased[]> {
    return this.select(selectTotaalRegel).pipe(map( row =>
      row ? row.totaalGebruik.map(lg => ({key: lg.key, values: {min: lg.minimaalLeerlingGebruik, avg: lg.gemiddeldLeerlingGebruik, max: lg.maximaalLeerlingGebruik, tot: 100}})) : null
    ));
  }

  isNavigeerbaar(): Observable<boolean> {
    return combineLatest([
      this.getNextVak(),
      this.getNextOWS(),
    ]).pipe(
      withLatestFrom(
        this.settings.isGegroepeerdOpVak(),
        this.getVakNaamURL(),
        this.getOnderwijssoortNaamURL()
      ),
      map(([[nextVak, nextOWS], isGegroepeerdOpVak, curVak, curOWS]) => {
        return isGegroepeerdOpVak ? nextOWS && nextOWS !== curOWS : nextVak && nextVak !== curVak;
      })
    );
  }
}
