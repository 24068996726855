import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError, take, withLatestFrom, filter, tap, takeUntil, shareReplay } from 'rxjs/operators';
import { DashboardBackofficeService } from '../services/dashboard-backoffice.service';
import {
    fetchSchoolYears,
    schoolYearsFetched,
    storeSchoolYear,
    schoolYearStored,
    fetchOrganizations,
    organizationsFetched,
    saveOrganization,
    organizationSaved,
    startSync,
    fetchSyncs,
    syncsFetched,
    setSyncsPage,
    taskSelected,
    taskSchoolYearsFetched,
    taskOrganizationsFetched,
    taskFailedOrgsFetched,
    taskPollStatus,
    taskStepsFetched,
    stopSelectedTask,
    addFeedback,
    resumeSelectedTask,
    taskEventsFetched,
    setSyncsSortColumn
} from '../state/dashboard-backoffice/dashboard-backoffice.actions';
import { reportError } from '../state/errors/errors.actions';
import { payload } from '../state/payload';
import { DashboardBackofficeFacade } from '../state/dashboard-backoffice/dashboard-backoffice.facade';
import moment from 'moment';

@Injectable()
export class DashboardBackofficeEffects {
    constructor(private actions$: Actions, private backofficeService: DashboardBackofficeService, private facade: DashboardBackofficeFacade) {}

    doFetchSchoolYears = createEffect(() => this.actions$.pipe(
        ofType(fetchSchoolYears),
        switchMap(() => this.backofficeService.fetchSchoolYears().pipe(
            take(1),
            map(schoolYears => schoolYearsFetched({ schoolYears })),
            catchError(err => of(reportError(payload(err))))
        ))
    ));

    doStoreSchoolYear = createEffect(() => this.actions$.pipe(
        ofType(storeSchoolYear),
        switchMap(({ schoolYear }) => this.backofficeService.storeSchoolYear(schoolYear).pipe(
            take(1),
            map(storedSchoolYear => schoolYearStored({ schoolYear: storedSchoolYear })),
            catchError(err => of(reportError(payload(err))))
        ))
    ));

    doFetchOrganizations = createEffect(() => this.actions$.pipe(
        ofType(fetchOrganizations),
        switchMap(() => this.backofficeService.fetchOrganizations().pipe(
            take(1),
            map(organizations => organizationsFetched({ organizations })),
            catchError(err => of(reportError(payload(err))))
        ))
    ));

    doSaveOrganization = createEffect(() => this.actions$.pipe(
        ofType(saveOrganization),
        switchMap(({ organization }) => this.backofficeService.saveOrganization(organization).pipe(
            take(1),
            map(savedOrganization => organizationSaved({ organization: savedOrganization })),
            catchError(err => of(reportError(payload(err))))
        ))
    ));

    doStartSync = createEffect(() => this.actions$.pipe(
        ofType(startSync),
        switchMap((config) => this.backofficeService.startSync(config).pipe(
            take(1),
            map(task => taskSelected({ task })),
            catchError(err => of(reportError(payload(err))))
        ))
    ));

    doSetSyncsPage = createEffect(() => this.actions$.pipe(
        ofType(setSyncsPage),
        withLatestFrom(this.facade.syncsCachedPages$),
        filter(([{ page }, pages]) => !pages.includes(page)),
        map(([page]) => fetchSyncs(page))
    ));

    doSetSyncsSortColumn = createEffect(() => this.actions$.pipe(
        ofType(setSyncsSortColumn),
        withLatestFrom(this.facade.syncsOrderBy$),
        filter(([{ column }, oldColumn]) => column !== oldColumn),
        map(() => fetchSyncs({ page: 0 }))
    ));

    doFetchSyncs = createEffect(() => this.actions$.pipe(
        ofType(fetchSyncs),
        withLatestFrom(this.facade.syncsPageSize$, this.facade.syncsOrderBy$, this.facade.syncsOrderByDescending$),
        switchMap(([{ page }, pageSize, orderBy, descending]) => this.backofficeService.fetchSyncs(page, pageSize, orderBy, descending).pipe(
            take(1),
            map(response => syncsFetched(response)),
            catchError(err => of(reportError(payload(err))))
        ))
    ));

    doFetchTaskSchoolYears = createEffect(() => this.actions$.pipe(
        ofType(taskSelected),
        switchMap(({ task }) => this.backofficeService.fetchSyncSchoolYears(task.etlTaskId).pipe(
            take(1),
            map(schoolYears => taskSchoolYearsFetched({ schoolYears })),
            catchError(err => of(reportError(payload(err))))
        ))
    ));

    doFetchTaskOrganizations = createEffect(() => this.actions$.pipe(
        ofType(taskSelected),
        switchMap(({ task }) => this.backofficeService.fetchSyncOrganizations(task.etlTaskId).pipe(
            take(1),
            map(organizations => taskOrganizationsFetched({ organizations })),
            catchError(err => of(reportError(payload(err))))
        ))
    ));

    doFetchTaskFailedOrgs = createEffect(() => this.actions$.pipe(
        ofType(taskPollStatus),
        withLatestFrom(this.facade.selectedTask$),
        filter(([_, task]) => task !== null && task !== undefined),
        switchMap(([_, task]) => this.backofficeService.fetchSyncFailedOrganizations(task.etlTaskId).pipe(
            take(1),
            map(organizations => taskFailedOrgsFetched({ organizations })),
            catchError(err => of(reportError(payload(err))))
        ))
    ));

    doFetchTaskStepsLeft = createEffect(() => this.actions$.pipe(
        ofType(taskPollStatus),
        withLatestFrom(this.facade.selectedTask$),
        filter(([_, task]) => task !== null && task !== undefined),
        switchMap(([_, task]) => this.backofficeService.fetchSyncStepsLeft(task.etlTaskId).pipe(
            take(1),
            map(steps => taskStepsFetched({ steps })),
            catchError(err => of(reportError(payload(err))))
        ))
    ));

    doFetchTaskEvents = createEffect(() => this.actions$.pipe(
        ofType(taskPollStatus),
        withLatestFrom(this.facade.selectedTask$, this.facade.selectedTaskNewestEventId$),
        filter(([_, task]) => task !== null && task !== undefined),
        switchMap(([_, task, lastEventId]) => this.backofficeService.fetchSyncEvents(task.etlTaskId, lastEventId).pipe(
            take(1),
            map(events => taskEventsFetched({ events })),
            catchError(err => of(reportError(payload(err))))
        ))
    ));

    doStopSelectedTask = createEffect(() => this.actions$.pipe(
        ofType(stopSelectedTask),
        withLatestFrom(this.facade.selectedTask$),
        switchMap(([_, task]) => this.backofficeService.stopSync(task.etlTaskId).pipe(
            take(1),
            map(message => addFeedback({ feedback: { message, nature: 'info', time: moment()} })),
            catchError(err => of(addFeedback({ feedback: { message: err?.error?.text, nature: 'error', time: moment() } })))
        ))
    ));

    doResumeSelectedTask = createEffect(() => this.actions$.pipe(
        ofType(resumeSelectedTask),
        withLatestFrom(this.facade.selectedTask$),
        switchMap(([_, task]) => this.backofficeService.resumeSync(task.etlTaskId).pipe(
            take(1),
            map(message => addFeedback({ feedback: { message, nature: 'info', time: moment()} })),
            catchError(err => of(addFeedback({ feedback: { message: err?.error?.text, nature: 'error', time: moment() } })))
        ))
    ));
}
