import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {filter, map, withLatestFrom} from 'rxjs/operators';
import {Datumbereik, Vestiging} from 'src/app/state/dashboardsettings/dashboardsettings.state';
import {formatPeriode} from 'src/app/stateless/datumbereik';
import {
  DatumbereikSelectiePanelComponentViewModel
} from '../datumbereik-selectie-panel/datumbereik-selectie-panel.component';
import {Periode} from '../../services/datumbereik';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatepickerComponent implements OnInit, OnDestroy {

  @Input() public viewModel: DatepickerComponentViewModel;

  @Input() public alignment: Alignment = 'right';

  @Input() public display: Display = 'classic';

  public datumbereikSelectieViewModel: DatumbereikSelectiePanelComponentViewModel;

  public datumbereikFormatted$: Observable<string>;

  public showDatepicker: boolean = false;

  public hasNext: Observable<boolean>;

  public hasPrevious: Observable<boolean>;

  private previous: Datumbereik | null;

  private next: Datumbereik | null;

  private subs: Subscription[] = [];

  private canSelectNext: boolean = false;

  private canSelectPrevious: boolean = false;

  public week = Periode.Week;
  public maand = Periode.Maand;
  public schooljaar = Periode.Schooljaar;

  constructor() {
  }

  ngOnInit(): void {
    const bereikenWithSamePeriod: Observable<[Datumbereik[], Datumbereik]> =
      combineLatest([this.viewModel.onGetMogelijkeDatumbereiken, this.viewModel.onGetCurrentDatumbereik]).pipe(
        filter(([bereiken]) => !!bereiken),
        map(([bereiken, huidig]) => {
          return bereiken
            .filter(bereik => bereik.periode === huidig.periode)
            .sort((a, b) => a.epochSeconds - b.epochSeconds)
        }),
        withLatestFrom(this.viewModel.onGetCurrentDatumbereik),
      );

    const previous = bereikenWithSamePeriod.pipe(
      map(([bereiken, huidig]) => {
        const huidigeArrayIndex = this.getBereikIndexOfHuidigBereik(bereiken, huidig);

        if (huidigeArrayIndex < 0) return null;

        const reversedPrevItems = bereiken.slice(0, huidigeArrayIndex).reverse();

        return reversedPrevItems.find(bereik => bereik.hasData);
      })
    );

    const next = bereikenWithSamePeriod.pipe(
      map(([bereiken, huidig]) => {
        const huidigeArrayIndex = this.getBereikIndexOfHuidigBereik(bereiken, huidig);

        if (huidigeArrayIndex < 0 || bereiken.length < (huidigeArrayIndex + 2)) return null;

        const nextItems = bereiken.slice(huidigeArrayIndex + 1);

        return nextItems.find(bereik => bereik.hasData);
      })
    );

    this.subs.push(previous.subscribe(bereik => this.previous = bereik));

    this.subs.push(next.subscribe(bereik => this.next = bereik));

    this.hasNext = next.pipe(map(bereik => !!bereik));
    this.hasPrevious = previous.pipe(map(bereik => !!bereik));

    this.datumbereikSelectieViewModel = {
      onGetCurrentDatumbereik: this.viewModel.onGetCurrentDatumbereik,
      onGetDatumbereiken: this.viewModel.onGetMogelijkeDatumbereiken,
      setDatumbereik: (db) => this.navigeerNaarGekozenDatumbereik(db),
      close: () => this.hideDatepicker()
    };

    this.datumbereikFormatted$ = this.viewModel.onGetCurrentDatumbereik.pipe(
      filter(db => !!db),
      map(db => formatPeriode(db))
    );

    this.subs.push(this.hasNext.subscribe(v => this.canSelectNext = v));

    this.subs.push(this.hasPrevious.subscribe(v => this.canSelectPrevious = v));
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  private getBereikIndexOfHuidigBereik(bereiken, huidigBereik) {
    return bereiken.indexOf(bereiken.find(b => b?.epochSeconds === huidigBereik.epochSeconds))
  }

  public toggleDatepicker(): void {
    this.showDatepicker = !this.showDatepicker;
  }

  public hideDatepicker(): void {
    this.showDatepicker = false;
  }

  public navigaarNaarVorige(): void {
    if (this.canSelectPrevious) {
      this.viewModel.setSelectedDatumbereik(this.previous);
    }
  }

  public navigaarNaarVolgende(): void {
    if (this.canSelectNext) {
      this.viewModel.setSelectedDatumbereik(this.next);
    }
  }

  public navigeerNaarGekozenDatumbereik(datumbereik: Datumbereik): void {
    this.viewModel.setSelectedDatumbereik(datumbereik);
    this.toggleDatepicker();
  }
}

type Display = 'classic' | 'alternative';

type Alignment = 'right' | 'left';

export interface DatepickerComponentViewModel {
  onGetCurrentDatumbereik: Observable<Datumbereik>;
  onGetSelectedVestiging: Observable<Vestiging>;
  onGetMogelijkeDatumbereiken: Observable<Datumbereik[]>;

  setSelectedDatumbereik: (datumbereik: Datumbereik) => void;
}
