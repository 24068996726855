<div *ngIf="isSidebarOpen$ | async">
  <app-export-sidebar click-stop-propagation (clickOutside)="onSidebarClose()"></app-export-sidebar>
</div>
<div class="container">
  <h1 class="headingHead headingHead--page">Exportoverzicht</h1>
  <div class="overzicht">
    <div class="filter">
      <app-export-filter></app-export-filter>
    </div>
    <app-connect-loader [loading]="isLoading$ | async">

      <ng-container *ngIf="hasExports$ | async; else noExports">
        <div class="group" *ngFor="let row of (rows$ | async)">
          <div class="header-text no-outline">{{row.datum}}</div>

          <div class="group__row">
            <div class="group__header flex">
              <div class="w320 header-text">Type</div>
              <div class="paddingElement"></div>
              <div class="w495 header-text">Geëxporteerd door</div>
              <div class="w70 header-text">Tijdstip</div>
            </div>
          </div>
          <div class="group__row" *ngFor="let subrow of row.subrows">
            <div
              class="group__item flex"
              (click)="toggleActive(subrow.id)"
              [ngClass]="{'is-active':  activeSubRow === subrow.id}"
            >
              <i [class]="'icon ' + subrow.icon"></i>
              <div class="w320 typ2">{{subrow.type}}</div>
              <div class="w495 typ2">{{subrow.medewerker}}</div>
              <div class="w70 typ2">{{subrow.tijd}}</div>
            </div>
            <div *ngIf="activeSubRow === subrow.id" class="group__child is-active">
              <app-exportoverzicht-details [id]="subrow.id"></app-exportoverzicht-details>
            </div>
          </div>

        </div>
        <div class="row empty"></div>
        <app-paginator [pages]="pageSize$ | async"></app-paginator>
      </ng-container>

      <ng-template #noExports>
        <p>Er zijn geen exports gevonden voor de huidige periode</p>
      </ng-template>

    </app-connect-loader>
  </div>
</div>
