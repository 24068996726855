<div class="wrapper-size-restrict" (clickOutside)="closeDropdown()">
  <label *ngIf="viewModel.label">{{viewModel.label}}</label>
  <div class="container" #container>
    <div class="input {{(dropdownExpanded | async) ? 'open' : ''}}" (click)="toggleDropdown()">
      <div class="selector">
        <input
          #inputElement
          type="text"
          [placeholder]="placeholder"
          (keyup)="onType()"
          (keyup.enter)="onEnter()"
          [(ngModel)]="searchString"
        >
        <app-icon-keper [active]="dropdownExpanded | async"></app-icon-keper>
      </div>
    </div>
    <div class="shadow" [ngStyle]="{'height.px': shadowElementHeight}"></div>
    <div
      class="dropdown"
      [ngClass]="{'is-open': dropdownExpanded | async}"
      #dropdown
    >
      <div
        *ngFor="let optie of (filteredOptions$ | async)"
        (click)="viewModel.doOnOptieSelected(optie)"
        class="dropdown__optie"
      >
        {{optie.naam}}
      </div>
    </div>
  </div>
</div>
