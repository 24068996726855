export function byStringProp<T>(prop: (item: T) => string, asc?: boolean): (item1: T, item2: T) => number {
    return (item1: T, item2: T) => {
        let string1 = prop(item1);
        let string2 = prop(item2);

        if(string1 < string2)
        {
            return asc ? 1 : -1;
        }
        if(string1 > string2)
        {
            return asc ? -1 : 1;
        }
        return 0;
    };
}