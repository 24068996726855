import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState, PRIVACY_DASHBOARD_FORBIDDEN, selectErrors} from '../state/app.state';
import {setErrorMessages} from '../state/errors/errors.actions';
import {payload} from '../state/payload';

@Component({
    selector: 'app-error-page',
    templateUrl: './error-page.component.html',
    styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit, OnDestroy {
    meldingen = new Set<string>();
    subscription: Subscription;

    public constructor(private store: Store<AppState>) {
    }

    get unauthenticated(): boolean {
      return this.meldingen.has('Unauthenticated');
    }

    get geenVestigingen(): boolean {
      return this.meldingen.has('Geen vestigingen');
    }

    get ongeldigeVestiging(): boolean {
      return this.meldingen.has('Ongeldige vestiging');
    }

    get geenToegangToPrivacydashboard(): boolean {
      return this.meldingen.has(PRIVACY_DASHBOARD_FORBIDDEN);
    }

    get overig(): string[] {
      return [...this.meldingen]
        .filter(v => !['Unauthenticated', 'Geen vestigingen', 'Ongeldige vestiging', PRIVACY_DASHBOARD_FORBIDDEN]
          .includes(v));
    }

    ngOnInit(): void {
        this.subscription = this.store.select(selectErrors).subscribe(v => this.meldingen = v);
    }

    ngOnDestroy() {
      this.subscription.unsubscribe();
      this.store.dispatch(setErrorMessages(payload(new Set<string>())));
    }
}
