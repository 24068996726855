/**
 * Zorg dat elke waarde maar één keer voorkomt in een array. Gebruik als volgt:
 *
 * ```array = array.filter(distinct());```
 *
 * Gebruikt default de === operator om elementen in de array te vergelijken. Als
 * je objecten wilt vergelijken kan je de optionele comparisonPredicate functie
 * meegeven:
 *
 * ```array = array.filter(distinct((arg1, arg2) => arg1.equals(arg2)));```
 *
 * @param comparisonPredicate Optionele functie om aan te geven wanneer twee
 * elementen in de array gelijk zijn.
 *
 * @returns Een functie die ervoor zorgt dat de filter methode van een Array
 * ervoor zorgt dat er geen dubbele elementen in de array voorkomen.
 */
export function distinct<T>(comparisonPredicate?: (arg1: T, arg2: T) => boolean): (value: T, index: number, array: T[]) => boolean {
    if (!comparisonPredicate) {
        return distinct((arg1: T, arg2:  T) => arg1 === arg2);
    }

    return (currentValue: T, index: number, array: T[]) => array.findIndex((valueAlreadyInArray: T) => comparisonPredicate(valueAlreadyInArray, currentValue)) === index
}
