import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {InputComboboxRow, InputComboboxViewModel} from '../layout/input-combobox/input-combobox.component';
import {ExportoverzichtFacade} from '../state/exportoverzicht/exportoverzicht.facade';
import {filter, map} from 'rxjs/operators';
import {Datumbereik} from "../state/dashboardsettings/dashboardsettings.state";
import {DatepickerComponentViewModel} from "../new-dashboard/datepicker/datepicker.component";
import {DatepickerService} from "../services/datepicker.service";
import {Periode} from "../services/datumbereik";

@Component({
  selector: 'app-export-filter',
  templateUrl: './export-filter.component.html',
  styleUrls: ['./export-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportFilterComponent implements OnInit, OnDestroy {

  private ALLE_MEDEWERKERS = {
    naam: 'Alle medewerkers',
    hasData: true,
  };

  datumbereikFilterViewmodel: DatepickerComponentViewModel;

  medewerkerFilterViewmodel: InputComboboxViewModel;

  constructor(
    private facade: ExportoverzichtFacade,
    private datepickerService: DatepickerService,
  ) {
  }

  ngOnInit(): void {
    const datumBereiken = this.datepickerService.setDatumBereik(new Date());
    const currentDatumBereik = datumBereiken
      .filter(bereik => bereik.periode === Periode.Week)
      .sort((a, b) => a.epochSeconds - b.epochSeconds)
      .pop();

    this.facade.setDatumbereik(currentDatumBereik);
    this.facade.setDatumBereiken(datumBereiken);

    this.datumbereikFilterViewmodel = {
      onGetCurrentDatumbereik: this.facade.getDatumbereik(),
      onGetMogelijkeDatumbereiken: this.facade.getDatumbereiken(),
      onGetSelectedVestiging: null,
      setSelectedDatumbereik: (datumbereik: Datumbereik) => {
        return this.facade.setDatumbereik(datumbereik)
      }
    };

    this.medewerkerFilterViewmodel = {
      label: 'Medewerkers',
      canSearch: true,
      onGetOpties: this.getMedewerkerOpties(),
      onGetSelected: this.facade.getFilterMedewerker().pipe(
        map(mw => mw === null
          ? this.ALLE_MEDEWERKERS
          : {
            naam: mw.naam,
            hasData: true,
          })
      ),
      doOnOptieSelected: (selection) => this.medewerkerChanged(selection)
    };
  }

  ngOnDestroy() {
    this.facade.resetFilter();
  }

  private medewerkerChanged(selection: InputComboboxRow): void {
    let selectionStr = selection.naam;
    if (selection.naam === this.ALLE_MEDEWERKERS.naam) {
      selectionStr = '';
    }
    this.facade.setFilterMedewerker(selectionStr);
  }

  private getMedewerkerOpties(): Observable<InputComboboxRow[]> {
    return this.facade.getMedewerkers().pipe(
      filter(mws => !!mws),
      map(mws => mws
        .filter(mw => !!mw)
        .sort()
        .map(mw => {
          return {
            naam: mw,
            hasData: true
          };
        })),
      map(mws => {
        mws.unshift(this.ALLE_MEDEWERKERS);
        return mws;
      })
    );
  }
}
