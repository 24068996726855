import {AVGExtendedVeldpermissie} from '../dto/avg-dashboard-model-classes';

export enum EntiteitNaamEnum {
  PLAATSING = 'Plaatsing',
  RESULTATEN = 'Resultaten',
  MEDEWERKER_PASFOTO = 'Medewerker pasfoto',
  LEERLING_PASFOTO = 'Leerling pasfoto',
  HUISWERK = 'Huiswerk',
  ROOSTER = 'Rooster',
  KLUISAFNAMES = 'Kluisafnames',
  TOEGANGSPAS_LEERLING = 'Toegangspassen',
  TOEGANGSPAS_MEDEWERKER = 'Toegangspassen',
  VRIJE_VELDEN = 'Vrije velden'
}

export enum EndpointNaamEnum {
  LEERLING = 'Leerling',
  MEDEWERKER = 'Medewerker',
  LESGROEP = 'Lesgroep',
  OUDER_VERZORGER = 'Ouder/Verzorger',
  LEERLING_ACCOUNT = 'Leerling account',
  MEDEWERKER_ACCOUNT = 'Medewerker account',
  OUDER_VERZORGER_ACCOUNT = 'Ouder/Verzorger account',
  VESTIGING = 'Vestiging'
}

export enum PermissionNaamEnum {
  VESTIGING = 'Vestiging',
  HOOFDVESTIGING = 'Hoofdvestiging',
  LESGROEPEN = 'Lesgroepen',
  VAKKEUZES = 'Vakkeuzes',
  IS_DOCENT = 'Is docent',
  FUNCTIE = 'Functie',
  LEERLING_REFERENTIE = 'Leerling referentie',
  ACCOUNT = 'Account'
}

export function mapEndpointUitzonderingen(permission: AVGExtendedVeldpermissie, permissionMapped: {
  fieldIndex: any;
  entityIndex: any;
  naam: any;
  subFields: null;
  selected: any;
  properties: any;
  verplicht: any
}, leerlingEndpoint: any, medewerkerEndpoint: any, ouderEndpoint: any) {
  if (permission.entityName === EntiteitNaamEnum.LEERLING_PASFOTO) {
    leerlingEndpoint.categorieen[0].velden.push(permissionMapped);
  }
  if (permission.entityName === EntiteitNaamEnum.PLAATSING) {
    leerlingEndpoint.categorieen[1].velden.push(permissionMapped);
  }
  if (permission.entityName === EntiteitNaamEnum.HUISWERK) {
    leerlingEndpoint.categorieen[2].velden.push(permissionMapped);
  }
  if (permission.entityName === EntiteitNaamEnum.RESULTATEN) {
    leerlingEndpoint.categorieen[3].velden.push(permissionMapped);
  }
  if(permission.entityName === EntiteitNaamEnum.TOEGANGSPAS_LEERLING && permission.entityIndex === 27) {
    leerlingEndpoint.categorieen[4].velden.push(permissionMapped);
  }
  if(permission.entityName === EntiteitNaamEnum.KLUISAFNAMES) {
    leerlingEndpoint.categorieen[5].velden.push(permissionMapped);
  }
  if (permission.entityName === EntiteitNaamEnum.ROOSTER) {
    leerlingEndpoint.categorieen[6].velden.push(permissionMapped);
  }
  if (permission.entityIndex === 3 && permission.entityName === EntiteitNaamEnum.VRIJE_VELDEN) {
    leerlingEndpoint.categorieen[7].velden.push(permissionMapped);
  }
  if (permission.entityName === EntiteitNaamEnum.MEDEWERKER_PASFOTO) {
    medewerkerEndpoint.categorieen[0].velden.push(permissionMapped);
  }
  if(permission.entityName === EntiteitNaamEnum.TOEGANGSPAS_MEDEWERKER && permission.entityIndex === 28) {
    medewerkerEndpoint.categorieen[2].velden.push(permissionMapped);
  }
  if (permission.entityIndex === 4 && permission.entityName === EntiteitNaamEnum.VRIJE_VELDEN) {
    medewerkerEndpoint.categorieen[3].velden.push(permissionMapped);
  }
  if (permission.entityIndex === 9 && permission.entityName === EntiteitNaamEnum.VRIJE_VELDEN) {
    ouderEndpoint.categorieen[2].velden.push(permissionMapped);
  }
}
