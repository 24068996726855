<div class="enkelleermiddelactivatieofgebruik">
  <span>{{ leermiddelinfo.leermiddelNaam }}</span>   
    <canvas baseChart 
      [data]="doughnutChartData" 
      [labels]="doughnutChartLabels" 
      [chartType]="doughnutChartType" 
      [colors]="doughnutChartColors" 
      [options]="doughnutChartOptions"
      [plugins]="doughnutChartPlugins">
    </canvas>
</div>