import {Injectable} from '@angular/core';
import {BaseFacade} from '../../../state/base.facade';
import {Store} from '@ngrx/store';
import {AppState} from '../../../state/app.state';
import {Observable} from 'rxjs';
import {LeermiddelData, selectLeermiddelData} from './vakloosleermiddeldashboard.selectors';

@Injectable()
export class VakloosleermiddeldashboardFacade extends BaseFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }

  selectLeermiddelData(): Observable<LeermiddelData> {
    return this.selectWithDatumProp(selectLeermiddelData);
  }
}
