import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-input-combobox',
  templateUrl: './input-combobox.component.html',
  styleUrls: ['./input-combobox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputComboboxComponent implements OnInit, OnDestroy, AfterViewChecked {
  @Input()
  public viewModel: InputComboboxViewModel;

  @ViewChild('container') container: ElementRef;

  @ViewChild('dropdown') dropdown: ElementRef;

  @ViewChild('inputElement') inputElement: ElementRef;

  public searchString: string = '';

  public filteredOptions$: Observable<InputComboboxRow[]>;

  private filteredOptionsValue: InputComboboxRow[];

  private userTextInput: BehaviorSubject<string> = new BehaviorSubject('');

  public dropdownExpanded: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public shadowElementHeight: number = 0;

  public placeholder: string;

  private subs: Subscription[] = [];

  listenerFn = () => {
  };

  constructor(private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.filteredOptions$ = combineLatest([this.viewModel.onGetOpties, this.userTextInput]).pipe(
      map(([opties, naam]) => {
        if (!!naam) {

          if (this.viewModel.customSearch) {
            return this.viewModel.customSearch(naam, opties);
          }

          return this.defaultSearch(naam, opties);
        }
        return opties;
      })
    );

    this.subs.push(
      this.viewModel.onGetSelected.subscribe(data => {
        this.placeholder = data.naam.replace('Week', '').trim();
        this.dropdownExpanded.next(false);
        this.searchString = '';
      })
    );

    this.subs.push(
      this.filteredOptions$.subscribe(val => this.filteredOptionsValue = val)
    );
  }

  ngAfterViewChecked() {
    const dropdownElement = this.dropdown.nativeElement;
    const containerElement = this.container.nativeElement;

    setTimeout(() => {
      const isDropdownOpen = dropdownElement.classList.contains('is-open');

      if (isDropdownOpen) {
        dropdownElement.scrollTop = 0;

        const containerHeight = containerElement.offsetHeight;
        const dropdownHeight = dropdownElement.offsetHeight;

        this.shadowElementHeight = containerHeight + dropdownHeight;
        this.changeDetectorRef.detectChanges();

        return;
      }

      this.shadowElementHeight = 0;
      this.changeDetectorRef.detectChanges();
    })
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
    this.listenerFn();
  }

  public toggleDropdown(): void {
    if (this.dropdownExpanded.value) {
      this.searchString = '';

      this.dropdownExpanded.next(!this.dropdownExpanded.value);

      return;
    }

    if (this.viewModel.canSearch) {
      this.inputElement.nativeElement.focus();
    }

    this.userTextInput.next(this.searchString);
    this.dropdownExpanded.next(!this.dropdownExpanded.value);
  }

  public closeDropdown(): void {
    if (this.dropdownExpanded.value) {
      this.dropdownExpanded.next(false);
      this.searchString = '';
    }
  }

  public onType(): void {
    this.userTextInput.next(this.searchString);
    this.dropdownExpanded.next(true);
  }

  public onEnter(): void {
    if (this.filteredOptionsValue.length === 1) {
      this.dropdownExpanded.next(false);
      this.searchString = '';
      this.viewModel.doOnOptieSelected(this.filteredOptionsValue[0]);
    }
  }

  public defaultSearch(searchString: string, options: InputComboboxRow[]): InputComboboxRow[] {
    const searchStringLowerCase = searchString.toLowerCase();

    return options.filter(o => o.naam.toLowerCase().includes(searchStringLowerCase));
  }
}

export interface InputComboboxViewModel {
  onGetOpties: Observable<InputComboboxRow[]>;
  onGetSelected: Observable<InputComboboxRow>;

  doOnOptieSelected: (selection: InputComboboxRow) => void;
  customSearch?: (searchString: string, options: InputComboboxRow[]) => InputComboboxRow[];
  label?: string;
  canSearch?: boolean;
}

export interface InputComboboxRow {
  naam: string;
  hasData: boolean;
}
