import {Component, Input, OnInit} from '@angular/core';
import { Observable } from 'rxjs';
import {RMedewerker} from '../../services/somtoday.service';
import {LesgroepDetails} from '../state/klassendashboard/klassendashboard.selector';

@Component({
  selector: 'app-lesgroep-info-sidebar',
  templateUrl: './lesgroep-info-sidebar.component.html',
  styleUrls: ['./lesgroep-info-sidebar.component.scss']
})
export class LesgroepInfoSidebarComponent implements OnInit {

  @Input()
  details: LesgroepDetails;

  @Input()
  medewerkers$: Observable<RMedewerker[]>;

  constructor() { }

  ngOnInit() {
  }

}
