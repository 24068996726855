import {Component, Input} from '@angular/core';
import {deelVeilig} from '../../services/veilig-delen';

@Component({
  selector: 'app-progressbar',
  templateUrl: './progressbar.component.html',
  styleUrls: ['./progressbar.component.scss']
})
export class ProgressbarComponent {
  @Input()
  actief: number;

  @Input()
  totaal: number;

  get quotient() {
    return deelVeilig(this.actief, this.totaal);
  }
}
