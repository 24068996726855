import {createSelector} from '@ngrx/store';
import {selectMe} from '../app.state';

export const magAVGDashboardInzien = createSelector(
  selectMe,
  me => me?.additionalObjects['avg-dashboard-toegang'] === 'VOLLEDIGE TOEGANG'
);

export const magBeperkteAVGDashboardInzien = createSelector(
  selectMe,
  me => me?.additionalObjects['avg-dashboard-toegang'] === 'BEPERKTE TOEGANG'
);

export const magLeermiddelDashboardInzien = createSelector(
  selectMe,
  me => me?.additionalObjects['leermiddel-dashboard-inzage'] === true
);

export const magGeenEnkelDashboardInzien = createSelector(
  selectMe,
  me => me?.additionalObjects['avg-dashboard-toegang'] === 'GEEN TOEGANG' &&
    me?.additionalObjects['leermiddel-dashboard-inzage'] === false &&
    me?.isLandelijkBeheerSupport === false
);

export const selectInstellingsNaam = createSelector(
  selectMe,
  me => me?.additionalObjects?.organisatieNaam
);

export const isLabSupport = createSelector(
  selectMe,
  me => me?.isLandelijkBeheerSupport
);
