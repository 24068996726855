import {LesgroepDetailDashboardData} from '../../../generated/graphql';
import * as m from 'moment';
import {deelVeilig} from '../../services/veilig-delen';
import {Periode, periodeNaarDatums} from '../../services/datumbereik';
import {RLeerling} from '../../services/somtoday.service';

export interface LesgroepDetailData {
  lesgroepNaam: string;
  meestGebruikteMethode: string;
  leerlingData: DataPerLeerling [];
  totaalrij: TotaalRij;
}

export interface DataPerLeerling {
  leerling: RLeerling;
  leerlingUUID: string;
  leermiddelNaam: string;
  geactiveerd: string;
  laatstActiefEnkel: string;
  laatstActiefMeerdere: string;
  gebruikPerWeek: number;
  gebruikPerWeekPijltje: string;
  rowspan: number;
  aantalGeactiveerdeLicenties: number;
}

export interface TotaalRij {
  geactiveerdInProcenten: number;
  recentActiefInProcenten: number;
  gemiddeldGebruikPerWeek: number;
}

export function createLesgroepDetailData(lesgroepData: LesgroepDetailDashboardData,
                                         periode: Periode, datum: Date): LesgroepDetailData {
  const lesgroepDetailData = {lesgroepNaam: lesgroepData.lesgroepNaam, meestGebruikteMethode: '', leerlingData: [], totaalrij: null};

  let totaalLeerlingenMetGeactiveerdeLicentie = 0;
  let totaalAantalLeerlingen = 0;
  let totaalRecentActieveLeerlingen = 0;
  let totaalGebruikPerWeek = 0;
  const verschillendeLeermiddelen = new Set<string>();
  let vorigeLeerling;
  let vorigeLeerlingHeeftEenGeactiveerdeLicentie = false;
  let vorigeLeerlingWasRecentActief = false;
  let rowspanCount = 0;
  const {from, to} = periodeNaarDatums(periode, datum);

  lesgroepData.gebruikPerLeerling.forEach(gebruik => {
    if (gebruik.leermiddelIdentifier) {
      verschillendeLeermiddelen.add(gebruik.leermiddelIdentifier);
    }
    const datumLaatsteGebruik = new Date(gebruik.datumLaatsteGebruik);
    let rowspan = 1;
    if (vorigeLeerling === gebruik.leerlingIdentifier) {
      rowspanCount += 1;
      lesgroepDetailData.leerlingData[lesgroepDetailData.leerlingData.length - rowspanCount].rowspan += 1;
      rowspan = 0;
      if (gebruik.datumLaatsteGebruik !== null) {
        lesgroepDetailData.leerlingData[lesgroepDetailData.leerlingData.length - rowspanCount].aantalGeactiveerdeLicenties += 1;
      }
    } else {
      rowspanCount = 0;
      totaalAantalLeerlingen += 1;
      vorigeLeerlingHeeftEenGeactiveerdeLicentie = false;
      vorigeLeerlingWasRecentActief = false;
    }

    vorigeLeerling = gebruik.leerlingIdentifier;
    lesgroepDetailData.leerlingData.push({
      leerling: null,
      leerlingUUID: gebruik.leerlingIdentifier,
      leermiddelNaam: gebruik.leermiddelIdentifier,
      geactiveerd: gebruik.datumLaatsteGebruik ? 'Ja' : gebruik.leermiddelIdentifier !== null ? 'Nee' : 'Geen licentie gevonden',
      laatstActiefEnkel: gebruik.datumLaatsteGebruik ? bepaalLaatstActief(datumLaatsteGebruik, datum) : null,
      laatstActiefMeerdere: gebruik.datumLaatsteGebruik ? bepaalLaatstActief(datumLaatsteGebruik, datum) : gebruik.leermiddelIdentifier ? 'Niet geactiveerd' : 'Geen licentie gevonden',
      gebruikPerWeek: gebruik.datumLaatsteGebruik ? gebruik.gebruikDezePeriode : null,
      gebruikPerWeekPijltje: bepaalGebruikstrend(gebruik.gebruikDezePeriode,
        gebruik.gebruikVorigePeriode),
      rowspan: rowspan.valueOf(),
      aantalGeactiveerdeLicenties: gebruik.datumLaatsteGebruik ? 1 : 0
    });

    if (gebruik.datumLaatsteGebruik !== null && !vorigeLeerlingHeeftEenGeactiveerdeLicentie) {
      totaalLeerlingenMetGeactiveerdeLicentie += 1;
      vorigeLeerlingHeeftEenGeactiveerdeLicentie = true;
    }

    if ((m(datumLaatsteGebruik).startOf('d') >= m(from).startOf('d')
      && m(datumLaatsteGebruik).startOf('d') <= m(to).startOf('d')) && !vorigeLeerlingWasRecentActief) {
      totaalRecentActieveLeerlingen += 1;
      vorigeLeerlingWasRecentActief = true;
    }
    totaalGebruikPerWeek += gebruik.gebruikDezePeriode;
  });

  lesgroepDetailData.totaalrij = {
    geactiveerdInProcenten: Math.round(deelVeilig(totaalLeerlingenMetGeactiveerdeLicentie, totaalAantalLeerlingen) * 100),
    recentActiefInProcenten: Math.round(deelVeilig(totaalRecentActieveLeerlingen, totaalLeerlingenMetGeactiveerdeLicentie) * 100),
    gemiddeldGebruikPerWeek: deelVeilig(totaalGebruikPerWeek, totaalLeerlingenMetGeactiveerdeLicentie)
  };
  const leermiddelSize = verschillendeLeermiddelen.size;
  lesgroepDetailData.meestGebruikteMethode = leermiddelSize === 0 ? '' : leermiddelSize === 1 ?
      verschillendeLeermiddelen.values().next().value : 'Let op: meerdere leermiddelen gevonden voor deze lesgroep';
  return lesgroepDetailData;
}

export function bepaalGebruikstrend(gebruikDezePeriode: number, gebruikVorigePeriode: number): string {
  if ((gebruikVorigePeriode === 0 && gebruikDezePeriode > 0) || deelVeilig(gebruikDezePeriode, (gebruikVorigePeriode / 100)) > 110) {
    return 'toegenomen';
  } else if (gebruikDezePeriode === 0 && gebruikVorigePeriode === 0) {
    return 'gelijkgebleven';
  } else if (deelVeilig(gebruikDezePeriode, (gebruikVorigePeriode / 100)) < 90) {
    return 'afgenomen';
  } else {
    return 'gelijkgebleven';
  }
}

export function bepaalLaatstActief(datumLaatsteGebruik: Date, tenOpzichteVan?: Date): string {
  const vandaag = m(tenOpzichteVan).startOf('d');
  const datumLaatsteGebruikAsMoment = m(datumLaatsteGebruik).startOf('d');
  if (datumLaatsteGebruikAsMoment.isSameOrAfter(m(vandaag).subtract(1, 'd'))) {
    return 'gisteren';
  } else if (m(periodeNaarDatums(Periode.Week, tenOpzichteVan).from).isBefore(datumLaatsteGebruikAsMoment)) {
    return 'afgelopen week';
  } else if (m(periodeNaarDatums(Periode.Maand, tenOpzichteVan).from).isBefore(datumLaatsteGebruikAsMoment)) {
    return 'afgelopen maand';
  }
  return 'langer dan een maand geleden';
}
