import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, filter, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';
import {reportError} from '../state/errors/errors.actions';
import {payload} from '../state/payload';
import {DashboardingService} from '../services/dashboarding.service';
import {
  fetchLeermiddelvakkoppeling, storeLeermiddel, setLeermiddelen, leermiddelStored
} from '../state/leermiddelvakkoppeling/leermiddelvakkoppeling.actions';
import {LeermiddelvakkoppelingNewFacade} from '../state/leermiddelvakkoppeling/leermiddelvakkoppeling.facade';

@Injectable()
export class LeermiddelenEffect {
  $ = createEffect(() => this.actions$.pipe(
    ofType(fetchLeermiddelvakkoppeling),
    withLatestFrom(this.leermiddelvakkoppeling.hasLeermiddelen()),
    filter(([_, hasLeermiddelen]) => !hasLeermiddelen),
    switchMap(() => this.dashboardingService.fetchLeermiddelen()
      .pipe(
        map(payload),
        map(setLeermiddelen),
        catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
      )),
    catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
  ));

  constructor(private actions$: Actions, private dashboardingService: DashboardingService, private leermiddelvakkoppeling: LeermiddelvakkoppelingNewFacade) {
  }
}

@Injectable()
export class KoppelLeermiddelVakkenEffect {
  $ = createEffect(() => this.actions$.pipe(
    ofType(storeLeermiddel),
    switchMap(({value: leermiddel}) => this.dashboardingService.postLeermiddelvakkoppeling(leermiddel)
      .pipe(
        map(payload),
        map(leermiddelStored),
        catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
      )),
    catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
  ));

  constructor(private actions$: Actions, private dashboardingService: DashboardingService) {
  }
}
