import {Observable} from 'rxjs';
import {LeermiddelVakKoppelingInfo, VakInfo} from '../../../../generated/graphql';
import {selectLeermiddelVakKoppelingOpen, selectLeermiddelVakKoppelingLoading, selectLeermiddelVakKoppelingData, selectVestigingVakkenData,
  selectGekoppeldeLeermiddelen, selectSelectedLeermiddelVakKoppeling} from './leermiddelvakkoppeling.selectors';
import {Injectable} from '@angular/core';
import {
  setLeermiddelVakKoppelingOpen, setLeermiddelVakKoppelingLoading, setSelectedLeermiddelVakKoppeling, updateLeermiddelVakKoppelingData
} from './leermiddelvakkoppeling.actions';
import {BaseFacade} from '../../../state/base.facade';
import {Store} from '@ngrx/store';
import {AppState} from '../../../state/app.state';

@Injectable()
export class LeermiddelvakkoppelingFacade extends BaseFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }

  selectLeermiddelVakKoppelingOpen(): Observable<boolean> {
    return this.select(selectLeermiddelVakKoppelingOpen);
  }

  selectLeermiddelVakKoppelingLoading(): Observable<boolean> {
    return this.select(selectLeermiddelVakKoppelingLoading);
  }

  selectLeermiddelVakKoppelingData(): Observable<LeermiddelVakKoppelingInfo[]> {
    return this.select(selectLeermiddelVakKoppelingData);
  }

  selectVestigingVakkenData(): Observable<VakInfo[]> {
    return this.select(selectVestigingVakkenData);
  }

  selectGekoppeldeLeermiddelen(): Observable<string[]> {
    return this.select(selectGekoppeldeLeermiddelen);
  }

  selectSelectedLeermiddelVakKoppeling(): Observable<[LeermiddelVakKoppelingInfo, number]> {
    return this.select(selectSelectedLeermiddelVakKoppeling);
  }

  setLeermiddelVakKoppelingOpen(open: boolean) {
    this.dispatch(setLeermiddelVakKoppelingOpen, open);
  }

  setLeermiddelVakKoppelingLoading(loading: boolean) {
    this.dispatch(setLeermiddelVakKoppelingLoading, loading);
  }

  updateLeermiddelVakKoppeling(data: LeermiddelVakKoppelingInfo) {
    this.dispatch(updateLeermiddelVakKoppelingData, data);
  }

  setSelectedLeermiddelVakKoppeling(data: [LeermiddelVakKoppelingInfo, number]) {
    this.dispatch(setSelectedLeermiddelVakKoppeling, data);
  }
}
