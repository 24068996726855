import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class MenuService {

    private menuState = new Map<string, boolean>();
    public menuStateChanges = new BehaviorSubject(this.menuState);

    constructor() { }

    public openMenu(menu: string) {
        this.menuState.set(menu, true);
        this.menuStateChanges.next(this.menuState);
    }
    public closeMenu(menu: string) {
        this.menuState.set(menu, false);
        this.menuStateChanges.next(this.menuState);
    }
    public closeAllMenus() {
        this.menuState.forEach((value, key) => this.menuState.set(key, false));
        this.menuStateChanges.next(this.menuState);
    }

    public logMenu() {
        this.menuState.forEach((value, key) => console.log(key, ': ', value));
    }
}
