import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LayoutModule} from '../layout/layout.module';
import {TableModule} from '../table/table.module';
import {StudiewijzerlinkjesdashboardComponent} from './studiewijzerlinkjesdashboard/studiewijzerlinkjesdashboard.component';
import {StudiewijzerlinkjesOverzichtComponent} from './studiewijzerlinkjes-overzicht/studiewijzerlinkjes-overzicht.component';
import {StudiewijzerlinkjesDetailComponent} from './studiewijzerlinkjes-detail/studiewijzerlinkjes-detail.component';
import {StudiewijzerlinkjesDetail} from './studiewijzerlinkjes-detail/studiewijzerlinkjes-detail.pipe';
import { TypeComponent } from './type/type.component';
import {StudiewijzerDonutComponent} from './studiewijzer-donut/studiewijzer-donut.component';
import {StudiewijzerKleurenDemoComponent} from './studiewijzer-kleuren-demo/studiewijzer-kleuren-demo.component';
import {Ng2GoogleChartsModule} from 'ng2-google-charts';



@NgModule({
  declarations: [
    StudiewijzerlinkjesOverzichtComponent,
    StudiewijzerlinkjesDetailComponent,
    StudiewijzerlinkjesDetail,
    StudiewijzerlinkjesdashboardComponent,
    StudiewijzerDonutComponent,
    StudiewijzerKleurenDemoComponent,
    TypeComponent
  ],
  imports: [
    CommonModule,
    LayoutModule,
    TableModule,
    Ng2GoogleChartsModule,
  ],
  exports: [
    StudiewijzerlinkjesdashboardComponent,
    StudiewijzerlinkjesOverzichtComponent,
    StudiewijzerDonutComponent,
    StudiewijzerKleurenDemoComponent,
    StudiewijzerlinkjesDetailComponent
  ]
})
export class StudiewijzerlinkjesModule { }
