import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import {ModalwindowService} from '../services/modalwindow.service';
import {latestDevUpdateId} from './updates';
import { whatsnewUpdates} from '../whatsnew/updates';

@Component({
  selector: 'app-whatsnew',
  templateUrl: './whatsnew.component.html',
  styleUrls: ['./whatsnew.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WhatsnewComponent implements OnInit {

  public hideAlert = true;

  constructor(private modelwindowService: ModalwindowService) { }

  ngOnInit(): void {
    this.hideAlert = this.getLaatstGelezenUpdate() >= latestDevUpdateId;
  }

  openWhatsNew() {
    whatsnewUpdates.forEach(x => x.unread = (x.id > this.getLaatstGelezenUpdate()));
    this.modelwindowService.toonOnderwerp('whatsnew');
    localStorage.setItem('laatstGelezenUpdate', latestDevUpdateId.toString());
  }

  getLaatstGelezenUpdate(): number {
    const laatstGelezenUpdate = parseInt(localStorage.getItem('laatstGelezenUpdate'));
    return isNaN(laatstGelezenUpdate) ? 0 : laatstGelezenUpdate;
  }

}
