<div class="listing-row" *ngIf="schoolYear.editing === false">
    <div class="listing-column">{{schoolYear.name}}</div>
    <div class="listing-column">{{schoolYear.include_from}}</div>
    <div class="listing-column">{{schoolYear.include_until}}</div>

    <div class="listing-column--small">
        <div class="icon-edit" (click)="editClicked()"></div>
    </div>
</div>

<div class="listing-row" *ngIf="schoolYear.editing === true">
    <div class="listing-column">{{schoolYear.name}}</div>
    <div class="listing-column">
        <input #from type="date" class="input-text" [value]="schoolYear.include_from">
    </div>

    <div class="listing-column">
        <input #until type="date" class="input-text" [value]="schoolYear.include_until">
    </div>
    <div class="listing-column--small" *ngIf="schoolYear.editing === true">
        <button class="button-save--small" (click)="saveClicked(schoolYear.name, from.value, until.value)">OK</button>
    </div>
</div>