<div class="container">
    <p class="label">OAuthClient: </p>
    <div class="input">
      {{ (oauthclient$ | async)?.omschrijving }}
    </div>
    <p class="label">Koppelpartij naam:</p>
    <div class="input">
        <app-input-text [viewModel]="koppelpartijNaamViewModel"></app-input-text>
    </div>
    <p class="label">Bedrijfsnaam:</p>
    <div class="input">
        <app-input-text [viewModel]="koppelpartijBedrijfsnaamViewModel"></app-input-text>
    </div>
    <p class="label">Logo URL:</p>
    <div class="input">
        <app-input-text [viewModel]="koppelpartijLogoUrlViewModel"></app-input-text>

        <div class="preview">
          <em>Preview:</em>
          <div *ngIf="!(koppelpartijLogoUrlViewModel.onGetInput | async) else uploadedLogo" class="logo">
          </div>
          <ng-template #uploadedLogo>
            <div class="logo" [style.background-image]=
              "'url(\'' +
              (koppelpartijLogoUrlViewModel.onGetInput | async) +
              '\')'"
            ></div>
          </ng-template>
        </div>
    </div>
    <p class="label">Docent link:</p>
    <div class="input">
        <app-input-text [viewModel]="koppelpartijDocentLinkViewModel"></app-input-text>
    </div>
    <p class="label">Leerling link:</p>
    <div class="input">
        <app-input-text [viewModel]="koppelpartijLeerlingLinkViewModel"></app-input-text>
    </div>
    <p class="label">Korte omschrijving:</p>
    <div class="input">
      <app-input-textarea [viewModel]="koppelpartijKorteOmschrijvingViewModel"></app-input-textarea>
    </div>
    <p class="label">Lange omschrijving:</p>
    <div class="input">
      <app-connect-editor [htmlText]="koppelpartijOmschrijving$" (valueChange)="updateKoppelpartijOmschrijving($event)"></app-connect-editor>
    </div>
    <p class="label">Op leerlingniveau geautoriseerd:</p>
    <div class="input">
      <app-input-checkbox [viewModel]="koppelpartijOpLeerlingNiveauGeautoriseerdViewModel"></app-input-checkbox>
    </div>
    <p *ngIf="toonDefaultPermissieKnop()" class="label">Selecteer alle standaard veldpermissies</p>
    <button class="permissie-button" *ngIf="toonDefaultPermissieKnop()" (click)="setDefaultPermissies()">Set standaard veldpermissies</button>
    <div class="leespermissies-container">
        <div class="veldentable">
            <ng-container *ngFor="let endpoint of (endpoints$ | async); let i = index">
                <div class="endpoint">
                    <app-input-permission-endpoint [viewModel]="viewModelForEndpoint(endpoint)"></app-input-permission-endpoint>
                </div>
            </ng-container>
        </div>
    </div>
  <p class="label">Afbeeldingen:</p>
  <div>
    <app-slider-afbeeldingen-toevoegen [koppelpartijAfbeeldingen]="koppelpartijAfbeeldingen$ | async" (afbeeldingBijgewerkt)="updateAfbeeldingen($event)"></app-slider-afbeeldingen-toevoegen>
    <div class="imageSlider" *ngIf="(koppelpartijAfbeeldingen$ | async)?.length">
      <ng-image-slider
        [images]="koppelpartijAfbeeldingen$ | async"
        [infinite]="false"
        [autoSlide]="0"
        [imageSize]="{width: '200px', height: '200px'}"
        [manageImageRatio]=true
        (imageClick)="fixFirstImageByDetectingChanges()">
      </ng-image-slider>
    </div>
  </div>
  <p class="label">Koppelpartij specifiek voor organisaties: </p>
  <div class="input">
    <app-avg-organisatie-selector [viewModel]="organizationsViewModel"></app-avg-organisatie-selector>
  </div>
</div>
<button (click)="save()">Opslaan</button>
<p *ngIf="sent$ | async" class="buttonFeedback"><em>Succesvol opgeslagen</em></p>
