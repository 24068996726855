import { Component, OnInit } from '@angular/core';
import {WidgetBaseComponent} from '../widget-base/widget-base.component';

@Component({
  selector: 'app-widget-empty',
  templateUrl: './widget-empty.component.html',
  styleUrls: ['../widget-base/widget-base.component.scss', './widget-empty.component.scss']
})
export class WidgetEmptyComponent extends WidgetBaseComponent<number> implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
  }

}
