import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';

@Component({
    selector: 'app-input-text',
    templateUrl: './input-text.component.html',
    styleUrls: ['./input-text.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputTextComponent implements OnInit, OnDestroy {

    @Input()
    public viewModel: InputTextViewModel;

    @ViewChild('input')
    public input: ElementRef;

    public text: string;

    private subscriptions: Subscription[] = [];

    constructor(private cdr: ChangeDetectorRef) { }

    ngOnInit(): void {
        if(this.viewModel.onGetInput !== undefined)
            this.subscriptions.push(this.viewModel.onGetInput.subscribe(text => {this.text = text; this.cdr.markForCheck();}));
        if(this.viewModel.onSetInput !== undefined && this.viewModel.doSetInput !== undefined)
            this.subscriptions.push(this.viewModel.onSetInput.subscribe(_ => this.viewModel.doSetInput.next(this.text)));
        if(this.viewModel.onSetFocus !== undefined)
            this.subscriptions.push(this.viewModel.onSetFocus.subscribe(_ => this.input.nativeElement.focus()));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    public onEnter(): void {
        if(this.viewModel.doOnEnter !== undefined)
            this.viewModel.doOnEnter.next(this.getInputText());
    }

    public onBlur(): void {
        if(this.viewModel.doOnBlur !== undefined)
            this.viewModel.doOnBlur.next(this.getInputText());
    }

    private getInputText(): string {
      let inputText = this.input.nativeElement.value;
      if (inputText === '')
        inputText = null;
      return inputText;
    }
}

export interface InputTextViewModel {
    onGetInput?: Observable<string>;
    onSetInput?: Observable<void>;
    onSetFocus?: Observable<void>;

    doSetInput?: Subject<string>;
    doOnKeyUp?: Subject<string>;
    doOnEnter?: Subject<string>;
    doOnBlur?: Subject<string>;
}
