import {Component, ElementRef, Input, OnChanges} from '@angular/core';
import {RangeModel, isValid} from '../model/range.model';
import {deelVeilig} from '../../services/veilig-delen';

@Component({
  selector: 'app-range-bar',
  templateUrl: './range-bar.component.html',
  styleUrls: ['./range-bar.component.scss']
})
export class RangeBarComponent implements OnChanges {
  actualTooltip: string;

  defaultTooltip: string;

  lowerEdge: number;
  higherEdge: number;

  constructor(private element: ElementRef) {}

  @Input() public range: RangeModel;

  public valid = true;
  public minOffset: number;
  public maxOffset: number;
  public rangeWidth: number;
  public avgOffset: number;
  @Input()
  public minGebruik: number;
  @Input()
  public maxGebruik: number;

  public dotStyle: {[klass: string]: any}|null;

  displayPercentages = false;

  ngOnChanges(): void {
    const {min = 0, avg = 0, max = 0, tot = 0} = this.range || {};
    this.valid = isValid(this.range);
    this.minOffset = deelVeilig(min, tot);
    this.maxOffset = deelVeilig(max, tot);
    this.rangeWidth = deelVeilig((max - min), tot);
    this.avgOffset = deelVeilig(avg, tot);

    this.lowerEdge = deelVeilig(((avg + min) / 2), tot);
    this.higherEdge = deelVeilig(((avg + max) / 2), tot);

    this.actualTooltip = this.defaultTooltip = (percent(this.avgOffset)) + ' actief';
    this.dotStyle = {visibility: 'hidden'};
  }

  onMouseMove(event: any) {
    this.displayPercentages = true;
    const width = this.element.nativeElement.firstChild.firstChild.getBoundingClientRect().width;
    const elementLeft = this.element.nativeElement.firstChild.firstChild.getBoundingClientRect().left;
    const mouseX = (event.clientX - elementLeft) / width;

    if (mouseX < this.lowerEdge) {
      this.actualTooltip = 'Laagste: ' + percent(this.minOffset);
      this.dotStyle = {visibility: 'visible', 'margin-left': (Math.round(this.minOffset * width) - 7) + 'px'};
    } else if (mouseX > this.lowerEdge && mouseX < this.higherEdge) {
      this.actualTooltip = 'Gemiddeld: ' + percent(this.avgOffset);
      this.dotStyle = {visibility: 'visible', 'margin-left': (Math.round(this.avgOffset * width) - 7) + 'px'};
    } else {
      this.actualTooltip = 'Hoogste: ' + percent(this.maxOffset);
      this.dotStyle = {visibility: 'visible', 'margin-left': (Math.round(this.maxOffset * width) - 7) + 'px'};
    }
  }

  onMouseLeave() {
    this.displayPercentages = false;
    this.actualTooltip = this.defaultTooltip;
    this.dotStyle = {visibility: 'hidden'};
  }
}

function percent(value: number): string {
  return Math.round(value * 100) + '%';
}
