export function compareLesgroep<T>(leerjaarFn: (v: T) => number, naamFn: (v: T) => string): (a: T, b: T) => number {
  return (a, b): number => {
    const aLeerjaar = leerjaarFn(a);
    const aNaam = naamFn(a) || '';
    const bLeerjaar = leerjaarFn(b);
    const bNaam = naamFn(b) || '';
    if (aLeerjaar === bLeerjaar) {
      return aNaam.localeCompare(bNaam);
    } else if (aLeerjaar !== undefined && bLeerjaar !== undefined) {
      return aLeerjaar - bLeerjaar;
    } else {
      return aLeerjaar === undefined ? 1 : -1;
    }
  };
}
