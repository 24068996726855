import {Component, Input} from '@angular/core';
import {TableCellComponent} from '../table/table.model';

@Component({
  selector: 'app-percent-label',
  templateUrl: './percent-label.component.html',
  styleUrls: ['./percent-label.component.scss']
})
export class PercentLabelComponent implements TableCellComponent<number> {
  @Input()
  data: number;
}
