import {SwLinkjesGebruikDetails, SwLinkjesPerPlatformOfVak} from '../../../generated/graphql';
import {Component, ChangeDetectionStrategy, Input, OnInit, OnDestroy} from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';
import {SharedVariablesService} from '../../services/sharedVariables.service';
import {SchooldashboardFacade} from '../../old-dashboarding/state/schooldashboard/schooldashboard.facade';
import {StudiewijzerlinkjesFacade} from '../../old-dashboarding/state/studiewijzerlinkjes/studiewijzerlinkjes.facade';

@Component({
  selector: 'app-studiewijzerlinkjes-detail',
  templateUrl: './studiewijzerlinkjes-detail.component.html',
  styleUrls: ['./studiewijzerlinkjes-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class StudiewijzerlinkjesDetailComponent implements OnInit, OnDestroy {
  public item: SwLinkjesPerPlatformOfVak;

  isPlatform = true;

  @Input()
  public viewModel: StudiewijzerlinkjesDetailViewModel;

  public details: SwLinkjesGebruikDetails[];

  private subscriptions: Subscription[] = [];

  loading = this.store.selectStudiewijzerLinkjesDetailLoading();

  constructor(private store: StudiewijzerlinkjesFacade) {
  }

  ngOnInit(): void {
    this.subscriptions = [
      this.viewModel.onGetCurrentItem.subscribe(platform => this.item = platform),
      this.viewModel.onGetDetails.subscribe(details => this.details = details),
      this.viewModel.onGetGroeperingOpPlatform.subscribe(isPlatform => this.isPlatform = isPlatform)
    ];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  sluitDetail() {
    this.viewModel.sluitItem();
  }

  selectDetail(detail: SwLinkjesGebruikDetails) {
    this.viewModel.selectDetail(detail);
  }
}

export interface StudiewijzerlinkjesDetailViewModel {
  onGetDetails: Observable<SwLinkjesGebruikDetails[]>;
  onGetCurrentItem: Observable<SwLinkjesPerPlatformOfVak>;
  onGetGroeperingOpPlatform: Observable<boolean>;
  selectDetail(item: SwLinkjesGebruikDetails);
  sluitItem();
}
