<div class="table" *ngFor="let tabel of (tabeldata$ | async)">
  <div class="tr-header">
    <div class="samenvatting-th"><b>{{tabel.leermiddelNaam}}</b></div>
    <div class="samenvatting-th">Activatie</div>
    <div class="samenvatting-th">Waarvan recent actief</div>
    <div class="samenvatting-th">Gebruik per week</div>
  </div>
  <div class="samenvatting-tr">
    <div class="samenvatting-td">{{lesgroepNaam}}</div>
    <div class="samenvatting-td">{{tabel.voorDezeLesgroep.activatiePercentage?.toFixed(0)}}%</div>
    <div class="samenvatting-td">{{tabel.voorDezeLesgroep.recentActiefVanGeactiveerd?.toFixed(0)}}%</div>
    <div class="samenvatting-td">{{tabel.voorDezeLesgroep.gemiddeldGebruikPerWeek?.toFixed(1)}}</div>
  </div>
  <div class="samenvatting-tr">
    <div class="samenvatting-td">Schoolbreed</div>
    <div class="samenvatting-td">{{tabel.schoolbreed.activatiePercentage?.toFixed(0)}}%</div>
    <div class="samenvatting-td">{{tabel.schoolbreed.recentActiefVanGeactiveerd?.toFixed(0)}}%</div>
    <div class="samenvatting-td">{{tabel.schoolbreed.gemiddeldGebruikPerWeek?.toFixed(1)}}</div>
  </div>
</div>
