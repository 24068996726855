import {Component, OnInit, ChangeDetectionStrategy, OnDestroy} from '@angular/core';
import {SchooldashboardNewFacade} from '../../state/schooldashboard/schooldashboard.facade';
import {of, Subscription} from 'rxjs';
import {ControlsViewModel} from '../controls-new/controls-new.component';
import { DashboardsettingsFacade } from 'src/app/state/dashboardsettings/dashboardsettings.facade';
import { Datumbereik } from 'src/app/state/dashboardsettings/dashboardsettings.state';

@Component({
  selector: 'app-schooldashboard-new',
  templateUrl: './schooldashboard-new.component.html',
  styleUrls: ['./schooldashboard-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SchooldashboardNewComponent implements OnInit, OnDestroy {

  public loading = true;
  private subscriptions: Subscription[] = [];

  constructor(private store: SchooldashboardNewFacade, private settingsStore: DashboardsettingsFacade) { }

  ngOnInit(): void {
    this.subscriptions.push(this.store.selectLoading().subscribe(loading => { if (!loading) this.loading = false; }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

    getControlsViewModel(): ControlsViewModel {
        return {
            onGetTitle: of('Groepeer op:'),
            onGetLeftLabel: of('Vak'),
            onGetRightLabel: of('Onderwijssoort'),
            onGetLeftActive: this.settingsStore.isGegroepeerdOpVak(),
            onGetRightActive: this.settingsStore.isGegroepeerdOpOnderwijssoort(),

            onGetCurrentDatumbereik: this.settingsStore.getDatumbereik(),
            onGetMogelijkeDatumbereiken: this.settingsStore.getDatumbereiken(),
            onGetSelectedVestiging: this.settingsStore.getSelectedVestiging(),

            toggle: () => this.settingsStore.toggleGroepering(),
            setSelectedDatumbereik: (datumbereik: Datumbereik) => this.settingsStore.setDatumbereik(datumbereik)
        };
    }
}
