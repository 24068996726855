import {createSelector} from '@ngrx/store';
import {Periode, periodeNaarDatums} from '../services/datumbereik';
import {selectPeriodeParam, selectVestigingParam} from './router/router.selectors';
import {Payload} from './payload';
import * as moment from 'moment';
import * as m from 'moment';
import {DashboardFilterInput, PeriodeInput} from '../../generated/graphql';

export const selectHuidigePeriode = createSelector(
  selectPeriodeParam,
  v => {
    switch (v) {
      case Periode.Maand as string:
        return Periode.Maand;
      case Periode.Periode as string:
        return Periode.Periode;
      case Periode.Schooljaar as string:
        return Periode.Schooljaar;
      case Periode.VorigSchooljaar as string:
        return Periode.VorigSchooljaar;
      default:
        return Periode.Week;
    }
  }
);

const fmtLocalDate = (input: m.MomentInput) => m(input).format('YYYY-MM-DD');

export const selectVanafTotenmet = createSelector(
  selectHuidigePeriode,
  (periode, {value: datum}: Payload<Date>) =>
    Object.entries(periodeNaarDatums(periode, datum)).map(([_, v]) => fmtLocalDate(v))
);

const toPeriodeInput = (periode: Periode) => {
  switch (periode) {
    case Periode.Maand:
      return PeriodeInput.Maand;
    case Periode.Periode:
      return PeriodeInput.Periode;
    case Periode.Schooljaar:
      return PeriodeInput.Schooljaar;
    case Periode.VorigSchooljaar:
      return PeriodeInput.Vorigschooljaar;
    default:
      return PeriodeInput.Week;
  }
};

export const selectAppFilter = createSelector(
  selectVestigingParam,
  selectHuidigePeriode,
  (vestiging, periode): DashboardFilterInput => (
    {vestiging, periode: toPeriodeInput(periode)}
  )
);

export const selectAlleDagenInPeriode = createSelector(
  selectHuidigePeriode,
  (periode, datum: Payload<Date>): Date[] => {
    const {from, to} = periodeNaarDatums(periode, datum.value);
    const dagen = [];
    for (const current = moment(from); current.isSameOrBefore(moment(to)); current.add(1, 'd')) {
      dagen.push(current.toDate());
    }
    return dagen;
  }
);

export const selectHuidigePeriodeVanaf = createSelector(
  selectHuidigePeriode,
  (periode, {value: datum}) => moment(periodeNaarDatums(periode, datum).from).toDate()
);

export const selectHuidigePeriodeTotenmet = createSelector(
  selectHuidigePeriode,
  (periode, {value: datum}) => moment(periodeNaarDatums(periode, datum).to).toDate()
);
