import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {AppState} from '../../state/app.state';
import {ModalwindowService} from '../../services/modalwindow.service';
import {setSelectedSWItemDetail, setStudiewijzerlinksPerPlatformVakOnderwijssoort} from '../state/schooldashboard/schooldashboard.actions';
import {catchError, filter, map, switchMap, take, tap} from 'rxjs/operators';
import {selectStudiewijzerlinksFilter} from '../state/studiewijzerlinkjes/studiewijzerlinkjes.selectors';
import {payload, payload2} from '../../state/payload';
import {vandaag} from '../../services/datumbereik';
import {StudiewijzerlinksFilterInput, StudiewijzerPlatformVakGQL} from '../../../generated/graphql';
import {of, pipe} from 'rxjs';
import {reportError} from '../../state/errors/errors.actions';

@Injectable()
export class StudiewijzerlinkjesdetailselectionEffect {
  $ = createEffect(() => this.actions$.pipe(
    ofType(setSelectedSWItemDetail),
    switchMap(({value}) => this.store.select(selectStudiewijzerlinksFilter, payload2(vandaag(), value)).pipe(take(1))),
    filter(current => hasFilterChanged(this.prev, current)),
    tap(f => this.prev = f),
    tap(_ => this.modalwindowService.toonOnderwerp('platformVak')),
    switchMap(f => this.gql.fetch({filter: f})),
    map(pipe(
      ({data}) => data.studiewijzerlinksPerPlatformVakOnderwijssoort,
      payload,
      setStudiewijzerlinksPerPlatformVakOnderwijssoort
    )),
    catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
  ));

  private prev: StudiewijzerlinksFilterInput;

  constructor(private actions$: Actions,
              private store: Store<AppState>,
              private gql: StudiewijzerPlatformVakGQL,
              private modalwindowService: ModalwindowService) {
  }
}

const hasFilterChanged = (prev: StudiewijzerlinksFilterInput, current: StudiewijzerlinksFilterInput) =>
      prev?.vaknaam !== current.vaknaam ||
      prev?.platformnaam !== current.platformnaam ||
      prev?.vestiging !== current.vestiging ||
      prev?.vanaf !== current.vanaf ||
      prev?.totenmet !== current.totenmet;
