import {ChangeDetectionStrategy, Component, Input, OnChanges} from '@angular/core';
import { ChartType, ChartDataSets, ChartOptions} from 'chart.js';
import { Progressbarinfo } from './progress-barinfo.model';
import { background_6, typography_1 } from '../../../shared/colors';
import {
  actiefGebruik,
  geenActivatie,
  welActivatieGeenGebruik,
  geenActivatieGrijs,
  welActivatieGeenGebruikGrijs,
  actiefGebruikGrijs
} from 'src/app/shared/colors';

@Component({
  selector: 'app-progress-bar-activatiegraad',
  templateUrl: './progress-bar-activatiegraad.component.html',
  styleUrls: ['./progress-bar-activatiegraad.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressBarActivatiegraadComponent implements OnChanges {
  @Input() public progressbarinfo: Progressbarinfo;
  public percentage: number;
  public extraBoodschap: string;
  public barChartData: ChartDataSets[];
  public barChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      display: false,
    },
    elements: {
      rectangle: {
        borderWidth: 2,
        borderColor: typography_1,
        borderSkipped: 'none'
      }
    },
    cutoutPercentage: 85,
    tooltips: {
      enabled: false
    },
    scales: {
      display: false,
        xAxes: [{
          ticks: {
            display: false
        },
          stacked: true,
          gridLines: {
            offsetGridLines: true,
            color: 'rgba(0, 0, 0, 0)',
            drawBorder: false
        }
        }],
        yAxes: [{
          ticks: {
            display: false
        },
          stacked: true,
          gridLines: {
            offsetGridLines: true,
            color: 'rgba(0, 0, 0, 0)',
            drawBorder: false
        }
  }]}};
  public barChartType: ChartType = 'horizontalBar';
  public barChartLegend = true;

  static bepaalkleur(percentage: number): string[] {
    if (percentage < 34) {
      return [geenActivatie, geenActivatieGrijs];
    } else if (percentage > 33 && percentage < 67) {
      return [welActivatieGeenGebruik, welActivatieGeenGebruikGrijs];
    } else {
      return [actiefGebruik, actiefGebruikGrijs];
    }
  }

  ngOnChanges() {
    const total = this.progressbarinfo.getallenActivatieOfGebruik.reduce((sum, num) => sum + num);
    this.percentage = Math.round((this.progressbarinfo.getallenActivatieOfGebruik[0] / total * 100));
    const kleuren = ProgressBarActivatiegraadComponent.bepaalkleur(this.percentage);
    this.barChartData = [
      {
        data: [this.percentage],
        backgroundColor: kleuren[0],
        hoverBackgroundColor: kleuren[0],
        hoverBorderColor: background_6,
        borderColor: background_6,
        label: 'Actief gebruik'
      },
      {
        data: [100 - this.percentage],
        backgroundColor: kleuren[1],
        hoverBackgroundColor: kleuren[1],
        hoverBorderColor: background_6,
        borderColor: background_6,

        borderSkipped: 'left',
        label: 'Niet geactiveerd'
      }
    ];
    this.extraBoodschap = this.progressbarinfo.getallenActivatieOfGebruik[0] + '/' + total + ' ' + this.progressbarinfo.extraBoodschap;
  }
}
