import {Component, Input, OnInit} from '@angular/core';

export interface WidgetDataSet<T> {
  title: string;
  data: Map<string, T>;
  xLabel?: string;
  yLabel?: string;
  type?: string;
  typePlural?: string;
}

@Component({
  selector: 'app-widget-base',
  templateUrl: './widget-base.component.html',
  styleUrls: ['./widget-base.component.scss']
})
export class WidgetBaseComponent<T> implements OnInit {

  @Input() widgetDataHolder: WidgetDataSet<T>;

  constructor() { }

  ngOnInit() {
  }

  static get fontFamily(): string {
    return 'Open Sans';
  }

}
