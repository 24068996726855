import {createReducer, on} from '@ngrx/store';

import {TotaalGrafiekPunt, VakdashboardState} from './vakdashboard.state';
import {resetVakdashboard, setDocenten, setVakdashboard} from './vakdashboard.actions';

const initialState: VakdashboardState = {
  onderwijssoort: null,
  vak: null,
  regels: [],
  totaalRegel: null,
};

export const vakdashboardReducer = createReducer(
    initialState,
    on(setVakdashboard, (_, {onderwijssoort, vak, regels, totaalRij}) => ({ onderwijssoort, vak, regels, totaalRegel: totaalRij })),
    on(setDocenten, (state, { value}) => {
      return {
      ...state,
      regels: [...state.regels.map(r => ({...r, docentNamen: value.filter(docent => r.docentUUIDs.includes(docent.uuid)).map(docent => docent.naam)}))]
    }; }),
  on(resetVakdashboard, _ => initialState),
);
