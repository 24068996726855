import {ChangeDetectionStrategy, Component, HostListener, Input, OnInit} from '@angular/core';
import {primary_3, background_1} from '../../shared/colors';
import {FONT_FAMILY} from '../../shared/fonts';
import {RangeLinechartModel} from './range-linechart.model';
import {RangeModel} from '../model/range.model';
import * as moment from 'moment';
import {GoogleChartInterface} from 'ng2-google-charts';

@Component({
  selector: 'app-range-linechart',
  templateUrl: './range-linechart.component.html',
  styleUrls: ['./range-linechart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RangeLinechartComponent implements OnInit {
  defaultTooltip: string;

  actualTooltip: string;

  private data_: RangeLinechartModel[];

  private dates: Date[];

  gemiddeldGebruik: number;

  showTooltip = false;

  @Input()
  private gebruikPerWeek: RangeModel;

  @Input()
  bgcolor: string;

  @Input()
  set model(data: RangeLinechartModel[]) {
    this.data_ = data;

    let minValue;
    let maxValue = 0;
    data.forEach(datum => {
      if (minValue > datum.values.min) {
        minValue = datum.values.min;
      }
      if (maxValue < datum.values.max) {
        maxValue = datum.values.max;
      }
    });
    if (minValue === undefined) {
      minValue = 0;
    }

    this.chartData.dataTable.rows = data.map(({date, values}) => {
      const {min, avg, max} = values;
      return ({c: [{v: date}, {v: avg}, {v: min}, {v: max}]});
    });

    const dates = data.map(({date}) => date).sort((a, b) => a.getTime() - b.getTime());
    this.dates = dates;
    if (dates.length > 0) {
      (this.chartData.options as any).hAxis.ticks = [dates[0], dates[dates.length - 1]];
      (this.chartData.options as any).hAxis.viewWindow = {min: dates[0], max: dates[dates.length - 1]};
    }

    this.defaultTooltip = 'Tussen ' + minValue.toFixed(1) + ' en ' + maxValue.toFixed(1) + ' p/w';
    this.actualTooltip = this.defaultTooltip;
  }

  chartData: GoogleChartInterface = {
    chartType: 'LineChart',
    dataTable: {
      cols: [
        {type: 'date', label: 'datum'},
        {type: 'number', label: 'activatie'},
        {id: 'i0', type: 'number', p: {role: 'interval'}},
        {id: 'i1', type: 'number', p: {role: 'interval'}}
      ],
      rows: []
    },
    options: {
      height: 70,
      hAxis: {
        gridlines: {
          color: 'none'
        },
        format: 'd MMM',
        ticks: [],
      },
      vAxis: {
        minValue: 0,
        gridlines: {
          color: 'none'
        },
        baseline: { color: 'none' },
        textPosition: 'none'
      },
      chartArea: {
        width: '100%',
        height: 50,
        bottom: 20,
        left: 0,
        right: 0,
        backgroundColor: {
          stroke: background_1,
          strokeWidth: 1
        }
      },
      backgroundColor: { fill: 'none' },
      legend: 'none',
      intervals: {style: 'area', color: background_1},
      fontName: FONT_FAMILY,
      tooltip: {
        trigger: 'none',
        isHTML: true
      },
      pointsVisible: false,
      colors: [primary_3],
      areaOpacity: 0.0,
    }
  };

  public get values(): RangeModel {
    const v = this.data_.map(d => d.values.avg);
    const min = Math.min(...v);
    const avg = v.reduce((a, b) => a + b, 0) / v.length;
    const max = Math.max(...v);
    return ({min, avg, max, tot:  0});
  }

  get cli(): any {
    return this.chartData.component.wrapper.getChart().getChartLayoutInterface();
  }

  ngOnInit(): void {
    this.gemiddeldGebruik = this.gebruikPerWeek.avg;
  }


  onReady() {

  }

  onMouseMove(event: MouseEvent) {
    this.showTooltip = true;
    const {width} = this.cli.getChartAreaBoundingBox();
    if (width > 0 && this.dates.length > 0) {
      const row = Math.round((this.dates.length - 1) * event.offsetX / width);
      const start = this.dates[row];
      const end = moment(start).add(moment(this.dates[1]).diff(moment(this.dates[0])));

      if (moment(this.dates[1]).diff(moment(this.dates[0]), 'days') < 2) {
        this.actualTooltip = moment(start).format('dddd');
      } else {
        this.actualTooltip = moment(start).format('DD MMM') + ' t/m ' + end.format('DD MMM');
      }
      this.gemiddeldGebruik = this.chartData.dataTable.rows[row].c[1].v;
    }
  }

  onMouseLeave() {
    this.showTooltip = false;
    this.actualTooltip = this.defaultTooltip;
    this.gemiddeldGebruik = this.gebruikPerWeek.avg;
  }

  @HostListener('window:resize')
  onResize() {
    if (this.chartData.component) {
      this.chartData.component.draw();
    }
  }
}
