import {Component, OnInit, ChangeDetectionStrategy, Input} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Veld} from '../../state/dev-koppelpartij/dev-koppelpartij.state';

@Component({
  selector: 'app-input-write-permission',
  templateUrl: './input-write-permission.component.html',
  styleUrls: ['./input-write-permission.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputWritePermissionComponent implements OnInit {

  @Input()
  viewModel: InputWritePermissionViewModel;

  constructor() { }

  ngOnInit(): void {
  }

  public verplichtCheckboxStyle(veld: Veld): string {
    return 'checkbox clickable checkbox-' + (veld.verplicht ? 'checked' : 'unchecked');
  }

  onVerplichtClicked(): void {
    this.viewModel.onVeldVerplichtClicked.next();
  }
}


export interface InputWritePermissionViewModel {
  onGetVeld: Observable<Veld>;

  onVeldVerplichtClicked: Subject<void>;
}
