<app-connect-loader [loading]="loading | async">
  <div class="contentBox">
    <app-go-back-header [viewmodel]="goBackHeaderViewModel"></app-go-back-header>
    <div class="container">
      <app-koppeling-header
        [viewModel]="headerViewModel"
      >
        <app-koppeling-menu [viewModel]="menuViewModel"></app-koppeling-menu>
      </app-koppeling-header>
      <div class="paginaContent">
        <app-koppeling-omschrijving></app-koppeling-omschrijving>
      </div>
    </div>
  </div>

  <app-modal *ngIf="verwijderKoppeling" (closeModal)="cancelDeleteKoppeling()">
    <div class="modal__header">
      <div class="header__icon svg_icon-warning"></div>
      <p class="header__title bodyContentBold">Let op, je staat op het punt alle koppelingen met deze partij te verwijderen</p>
      <div class="close" (click)="cancelDeleteKoppeling()"></div>
    </div>
    <div class="modal__body">
      <p class="body__text bodyContent">Weet je zeker dat je door wil gaan met het verwijderen van de koppelingen</p>
    </div>
    <div class="modal__buttons">
      <div class="button" (click)="cancelDeleteKoppeling()">
        <p class="bodySmallContentSemi buttonCaption">Annuleer</p>
      </div>
      <div class="button button--warning" (click)="continueDeleteKoppeling()">
        <p class="bodySmallContentSemi buttonCaption">Verwijder</p>
      </div>
    </div>
  </app-modal>

  <app-modal *ngIf="toevoegKoppeling" (closeModal)="cancelAddKoppeling()" class="modal--big">
      <div class="modal__header">
        <div class="header__icon svg_icon-koppel"></div>
        <p class="header__title blue bodyContentBold">Koppeling aanmaken</p>
        <div class="close" (click)="cancelAddKoppeling()"></div>
      </div>
      <div class="modal__body">
        <p class="body__text bodyContent">
          De koppeling met {{(koppelpartij | async)?.koppelpartijNaam}} wordt als volgt aangemaakt:
        </p>
        <ul>
          <li><p class="body__text bodyContent">Alle vestigingen</p></li>
          <li><p class="body__text bodyContent">Alle data die ze mogelijk nodig hebben</p></li>
          <li><p class="body__text bodyContent">Geldig vanaf vandaag, tot handmatige beëindiging</p></li>
          <li *ngIf="(koppelpartij | async).opLeerlingNiveauGeautoriseerd"><p class="body__text bodyContent">Leerling geeft zelf zijn/haar data vrij</p></li>
        </ul>
        <app-info-blok [infoText]="
          'Zelf exacte doelgroep, datavelden en looptijd kiezen? Neem de <a href=&quot;https://som.today/somtoday-connect/avg-module/&quot; target=&quot;_blank&quot;>AVG module</a> af'
        "></app-info-blok>
        <div class="bevestigVerwerkersOvereenkomst">
          <p class="body__text bodyContentSemi">Akkoord</p>
          <div class="checkbox_wrapper" (click)="toggleCheckbox()">
            <i [class]="checkboxClass()"></i>
            <p class="body__text bodyContent description">Ik ga akkoord met de voorwaarden en geef bij deze akkoord tot datadeling met {{(koppelpartij | async)?.koppelpartijNaam}} via Somtoday Connect</p>
          </div>
        </div>
        <p (click)="toggleVoorwaarden()" class="body__text bodySmallContentSemi">Voorwaarden<i class="icon-arrow" [class]="arrowClass()"></i></p>
        <div *ngIf="showVoorwaarden">
          <p class="body__text bodySmallContent">Door akkoord te gaan met de voorwaarden, verklaar ik dat:</p>
          <ul>
            <li><p class="body__text bodySmallContent">ik bevoegd ben namens het schoolbestuur deze koppeling aan te zetten;</p></li>
            <li><p class="body__text bodySmallContent"> ik ervan op de hoogte ben dat deze koppeling alleen aan kan worden gezet indien er een verwerkersovereenkomst bestaat tussen het bestuur van mijn onderwijsinstelling en {{(koppelpartij | async)?.koppelpartijNaam}}.</p></li>
          </ul>
        </div>
      </div>
      <div class="modal__buttons modal__buttons--no_border">
        <div class="button button--single button--success" [class]="buttonClass()" (click)="continueAddKoppeling()">
          <p class="bodySmallContentSemi buttonCaption">Aanmaken</p>
        </div>
      </div>
  </app-modal>

  <app-modal *ngIf="datatoegangClicked" (closeModal)="closeDatatoegangPopup()">
    <div class="modal__header">
      <div class="header__icon svg_icon-connect"></div>
      <p class="header__title bodyContentBold">Functie alleen beschikbaar met de AVG module</p>
      <div class="close" (click)="closeDatatoegangPopup()"></div>
    </div>
    <div class="modal__body">
      <p class="body__text bodyContent">
        Deze functie is alleen beschikbaar met de AVG module van Somtoday Connect. Met de AVG module kun je koppelingen fijnmazig instellen en krijg je inzicht in de dataoverdracht van lopende koppelingen.
        <br><br>
        Neem contact op met je relatiebeheerder voor een vrijblijvende demo of kijk op de <a href="https://som.today/somtoday-connect/avg-module/" target="_blank">website</a> voor meer informatie.
      </p>
    </div>
    <div class="modal__buttons modal__buttons--no_border">
      <div class="button button--single button--cta" (click)="closeDatatoegangPopup()">
        <p class="bodySmallContentSemi buttonCaption">Oké</p>
      </div>
    </div>
  </app-modal>

</app-connect-loader>
