import {Component, Input, OnInit} from '@angular/core';
import {Kengetal} from '../kengetal/kengetal.model';

@Component({
  selector: 'app-kengetaloverzicht',
  templateUrl: './kengetaloverzicht.component.html',
  styleUrls: ['./kengetaloverzicht.component.scss']
})
export class KengetaloverzichtComponent implements OnInit {
  @Input() kengetallen: Kengetal[];

  constructor() {
  }

  ngOnInit() {
  }

}
