import {createSelector} from '@ngrx/store';
import {selectKlassendashboard} from '../app.state';
import {selectQueryParam, selectRouteParam} from '../router/router.selectors';
import {selectLesgroepUUIDs, selectOnderwijssoort, selectVak} from '../vakdashboard/vakdashboard.selectors';
import { sort } from '../../stateless/string';
import {routingConstants} from '../../stateless/routing.constants';

export const selectIndividueelGebruik = createSelector(
  selectKlassendashboard,
  (state) => state.individueelGebruik
);

export const selectGezamenlijkGebruik = createSelector(
  selectKlassendashboard,
  (state) => state.gezamenlijkGebruik
);

export const selectLesgroepUUID = createSelector(
  selectKlassendashboard,
  (state) => state.lesgroepUUID
);

export const selectLesgroepNaam = createSelector(
  selectKlassendashboard,
  (state) => state.lesgroepNaam
);

export const selectDocentUUIDs = createSelector(
  selectKlassendashboard,
  (state) => state.gezamenlijkGebruik?.docentUUIDs
);

export const selectDocentNamen = createSelector(
  selectKlassendashboard,
  (state) => state.gezamenlijkGebruik?.docentNamen
);

export const selectAantalLeerlingen = createSelector(
  selectKlassendashboard,
  (state) => state.gezamenlijkGebruik?.aantalLeerlingen
);

export const selectLeermiddelGrafiek = createSelector(
  selectKlassendashboard,
  (state) => state.gezamenlijkGebruik?.leermiddelGrafiek
);

export const selectLeermiddelTabel = createSelector(
  selectKlassendashboard,
  (state) => state.gezamenlijkGebruik?.leermiddelTabel
);

export const selectLeerlingUUIDs = createSelector(
  selectKlassendashboard,
  (state) => state.individueelGebruik?.leerlingRegels?.map(r => r.leerlingUUID)
);

export const selectSelectie = createSelector(
  selectQueryParam('selectie'),
  v => v || 'individueel'
);

export const selectTotaalActivatie = createSelector(
  selectKlassendashboard,
  (state) => state.individueelGebruik?.activatiePercentage
);

export const selectTotaalRecentActief = createSelector(
  selectKlassendashboard,
  (state) => state.individueelGebruik?.percentageActief
);

export const selectTotaalGebruikPerWeek = createSelector(
  selectKlassendashboard,
  (state) => state.individueelGebruik?.gebruikPerWeek
);

export const selectIndividueelGebruikPerLeerling = createSelector(
  selectKlassendashboard,
  (state) => sort(state.individueelGebruik?.leerlingRegels, (r) => r?.leerlingNaam)
);

export const selectPrevLesgroep = createSelector(
  selectRouteParam('lesgroepuuid'),
  selectLesgroepUUIDs,
  (huidigeLesgroep, lesgroepen) => {
    const curIndex = lesgroepen?.indexOf(huidigeLesgroep);
    if (curIndex == null || curIndex === -1) {
      return null;
    }
    return curIndex === 0 ? lesgroepen[lesgroepen.length - 1] : lesgroepen[curIndex - 1];
  }
);

export const selectPrevLesgroepPathElements = createSelector(
  selectPrevLesgroep,
  selectVak,
  selectOnderwijssoort,
  (prevLesgroep, vak, ows) => {
    return [routingConstants.KLASSEN_DASHBOARD_NEW, vak, ows, prevLesgroep];
  }
);

export const selectNextLesgroep = createSelector(
  selectRouteParam('lesgroepuuid'),
  selectLesgroepUUIDs,
  (huidigeLesgroep, lesgroepen) => {
    const curIndex = lesgroepen?.indexOf(huidigeLesgroep);
    if (curIndex == null || curIndex === -1) {
      return null;
    }
    return curIndex === lesgroepen.length - 1 ? lesgroepen[0] : lesgroepen[curIndex + 1];
  }
);

export const selectNextLesgroepPathElements = createSelector(
  selectNextLesgroep,
  selectVak,
  selectOnderwijssoort,
  (nextLesgroep, vak, ows) => {
    return [routingConstants.KLASSEN_DASHBOARD_NEW, vak, ows, nextLesgroep];
  }
);
