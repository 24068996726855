import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Risk } from '../layout/webservice-tegel/webservice-tegel.component';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState} from '../state/app.state';
import {selectOudeWebservices} from '../state/privacydashboard/privacydashboard.selectors';
import {Webservice} from '../state/privacydashboard/privacydashboard.state';
import {Router} from '@angular/router';
import {setGeselecteerdeWebservice} from '../state/privacydashboard/privacydashboard.actions';

@Component({
  selector: 'app-datatoegang',
  templateUrl: './datatoegang.component.html',
  styleUrls: ['./datatoegang.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatatoegangComponent implements OnInit {
  public webservices$: Observable<Webservice[]>;

  public iconHighRisk = Risk.HIGH_RISK;
  public iconMediumRisk = Risk.MEDIUM_RISK;

  constructor(private appState: Store<AppState>, private router: Router) { }

  ngOnInit(): void {
    this.webservices$ = this.appState.select(selectOudeWebservices);
  }

  getTxt(ws: Webservice): string {
    let result = ws.accounts.toString() + ' account';
    if (ws.accounts > 1) {
      result  += 's';
    }
    return result;
  }

  openWebservice(ws: Webservice): void {
    this.appState.dispatch(setGeselecteerdeWebservice({webservice: ws}));
    this.router.navigateByUrl('privacydashboard/datatoegang/' + ws.service.toLowerCase());
  }
}
