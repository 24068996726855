<div>
  <google-chart [data]="chartData" (chartReady)="onReady()"></google-chart>
  <div *ngIf="gemiddelde">
    <span class="bg getal" [style.width]="bgGetalContainerWidth"></span>
    <span class="bg ondertekst"></span>
  	<span id="getal" #getal>{{gemiddelde}}</span>
  	<span id="ondertekst">leerlingen gemiddeld actief per dag</span>
  </div>
</div>


