import {Component, ChangeDetectionStrategy, AfterViewInit, ElementRef} from '@angular/core';
import {TableCellComponent} from '../table/table.model';
import {TitelondertitelCellModel} from './titelondertitel-cell.model';

@Component({
  selector: 'app-titelondertitel-cell',
  templateUrl: './titelondertitel-cell.component.html',
  styleUrls: ['./titelondertitel-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TitelondertitelCellComponent implements TableCellComponent<TitelondertitelCellModel> {
  data: TitelondertitelCellModel;
}

