<div *ngIf="exportDetails$ | async">
  <app-tab-row>
    <app-tab label="Gegevens">
      <app-exportoverzicht-list [list]="(exportDetails$ | async).keys"></app-exportoverzicht-list>
    </app-tab>
    <app-tab label="Inhoud">
      <app-exportoverzicht-list [list]="(exportDetails$ | async).entiteiten"></app-exportoverzicht-list>
    </app-tab>
  </app-tab-row>
</div>
