import {Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {KlassendashboardNewFacade} from '../../state/klassendashboard/klassendashboard.facade';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {FilterService} from '../../services/filter.service';
import {LesgroepControlsHeaderViewModel} from '../klassendashboard-controls-header/klassendashboard-controls-header.component';
import { VakdashboardNewFacade } from 'src/app/state/vakdashboard/vakdashboard.facade';
import {DashboardsettingsFacade} from '../../state/dashboardsettings/dashboardsettings.facade';
import {Datumbereik} from '../../state/dashboardsettings/dashboardsettings.state';
import {map, takeWhile, withLatestFrom} from 'rxjs/operators';
import {routingConstants} from '../../stateless/routing.constants';
import { schoolYearStartingYear } from 'src/app/stateless/datumbereik';

@Component({
  selector: 'app-klassendashboard-new',
  templateUrl: './klassendashboard-new.component.html',
  styleUrls: ['./klassendashboard-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KlassendashboardNewComponent implements OnInit, OnDestroy {

  public loading: Observable<boolean> = this.store.selectLoading();
  public initialLoadingFinished = false;

  public controlsViewmodel: LesgroepControlsHeaderViewModel;

  private subscriptions: Subscription[] = [];

  constructor(public store: KlassendashboardNewFacade,
              private vakdashboard: VakdashboardNewFacade,
              private settings: DashboardsettingsFacade,
              private filterService: FilterService,
              private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.subscriptions.push(
      combineLatest([
        this.vakdashboard.getVakNaamURL(),
        this.vakdashboard.getOnderwijssoortNaamURL(),
        this.store.getLesgroepNaam(),
        this.store.getPrevPathElements(),
        this.store.getNextPathElements()]
      ).subscribe((([vak, ows, lesgroepNaam, prevPathElements, nextPathElements]) => {
        this.controlsViewmodel = this.createViewmodel(vak, ows, lesgroepNaam, prevPathElements, nextPathElements);
      })));
    this.cdr.detectChanges();

    this.loading.pipe(
      takeWhile((loading) => loading === true, true),
    ).subscribe((loading) => {
      if (loading === false) {
        this.initialLoadingFinished = true;
      }
    });
  }

  private createViewmodel(vak: string, ows: string, lesgroepNaam: string, prevPathElements: string[], nextPathElements: string[]): LesgroepControlsHeaderViewModel {
    const terugKnopViewmodel = {
      caption: 'Terug naar Vakdashboard',
      pathElements: [routingConstants.VAK_DASHBOARD_NEW, vak, ows]
    };

    const lesgroepSwitchViewmodel = {
      lesgroepNaam,
      prevPathElements,
      nextPathElements
    };

    const datepickerViewmodel = {
      onGetCurrentDatumbereik: this.settings.getDatumbereik(),
      onGetMogelijkeDatumbereiken: this.settings.getDatumbereiken().pipe(
        withLatestFrom(this.settings.getDatumbereik()),
        map(([datumbereiken, huidig]) => 
        {
          const huidigSchooljaar: number = schoolYearStartingYear(huidig.epochSeconds);

          return datumbereiken.filter(datumbereik => {
            return schoolYearStartingYear(datumbereik.epochSeconds) === huidigSchooljaar;
          });
        }
      )),
      onGetSelectedVestiging: this.settings.getSelectedVestiging(),

      setSelectedDatumbereik: (datumbereik: Datumbereik) => this.settings.setDatumbereik(datumbereik)
    };

    return {
      terugKnopViewmodel,
      lesgroepSwitchViewmodel,
      datepickerViewmodel
    };
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onSelectieChanged(selectie: string): void {
    this.filterService.setFilterOption({selectie});
  }
}
