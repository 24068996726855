<div class="containerHeader">
  <div class="koppelingInfo">
    <div class="koppelpartijLogo">
      <img src="{{viewModel.img | async}}"/>
    </div>
    <div class="koppelingDescriptors">
      <h1 class="headingHead koppelingDisplayName">{{ viewModel.title | async}}</h1>
      <p class="bodyContent koppelpartijDisplayName">{{ viewModel.subTitle | async }}</p>
    </div>
  </div>
  <div (click)="onClick($event)" *ngIf="viewModel.button" class="button {{viewModel.buttonClass | async}}">
    <i *ngIf="viewModel.buttonIcon | async" class="{{viewModel.buttonIcon | async}}"></i>
    <span>{{viewModel.button | async}}</span>
    <div *ngIf="(viewModel.showMenu | async)" class="menu">
      <div (click)="onMenuItemClick($event, option)" *ngFor="let option of (viewModel.menu | async)" class="menuItem" [class]="option.buttonClass">
        <i class="{{ option.buttonIcon }}"></i>
        <span>{{option.button}}</span>
        <i class="icon-arrow"></i>
      </div>
    </div>
  </div>
</div>

<ng-content></ng-content>

<div class="divider"></div>
