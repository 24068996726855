// start nieuwe import docent
export const background_1 = '#d0dae4';
export const background_2 = '#d7dfe6';
export const background_3 = '#e3e9ee';
export const background_4 = '#f0f3f5';
export const background_5 = '#f7f9fa';
export const background_6 = '#ffffff';

export const typography_1 = '#000829';
export const typography_2 = '#465a77';
export const typography_3 = '#557195';
export const typography_4 = '#ffffff';

export const primary_1 = '#296bd7';
export const primary_2 = '#005eb8';
export const primary_3 = '#004f9b';
export const primary_4 = '#e5edfa';

export const secondary_1 = '#e6ab4a';
export const secondary_2 = '#c38831';
export const secondary_3 = '#fbf2e4';

export const accent_positive_1 = '#3f8541';
export const accent_positive_2 = '#1a6d1c';
export const accent_positive_3 = '#e8f0e8';

export const accent_negative_1 = '#d32f0d';
export const accent_negative_2 = '#b62507';
export const accent_negative_3 = '#f8e0db';

export const accent_warning_1 = '#da6710';
export const accent_warning_2 = '#b64603';
export const accent_warning_3 = '#f9e8db';

export const accent_alt_1 = '#793ff5';
export const accent_alt_2 = '#5012d2';
export const accent_alt_3 = '#ebe2fe';

// end nieuwe import docent

export const blauw6 = 'rgba(190, 225, 240, 0.3)';

export const groen3 = '#F5FFFD';
export const groen4 = '#E7F9F5';
export const groen5 = '#DAF0EB';

export const actiefGebruik = '#18c39c';
export const actiefGebruikGrijs = '#a4e3db';
export const welActivatieGeenGebruik = '#f8cc2f';
export const welActivatieGeenGebruikGrijs = '#f2e199';
export const geenActivatie = '#f35939';
export const geenActivatieGrijs = '#f0a498';

export const rood3 = '#FEF8F7';

export const oranje3 = '#FFF4EB';

export const geel2 = '#F6BF00';

export const boxShadowColor = 'rgba(0,8,41,0.35)';

// Start clean up colors:
export const deepblack = '#000000';
export const almostwhite = '#EFEFEF';
export const bazenbannerlinechartbackground = '#D3F2F0';

export const blauwaccent_positive_1 = '#008A6E';
export const geel3 = '#BD9200';
export const paars2 = '#9F78F1';
export const oranje4 = '#F29855';
export const rood4 = '#BC3418';
export const roze1 = '#FE8971';
export const grijs9 = '#F1F6FA';

export const graph1 = '#00B490';
export const graph2 = '#DAF0EB';
// End clean up colors
