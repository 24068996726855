import {createAction, props} from '@ngrx/store';
import {GezamenlijkGebruik, IndividueelGebruik } from './klassendashboard.state';
import {Payload} from '../payload';

export const setKlassendashboardAlgemeen = createAction('[Klassendashboard] Set klassendashboard algemeen', props<{ lesgroepNaam: string }>());
export const setKlassendashboardIndividueel = createAction('[Klassendashboard] Set klassendashboard individueel', props<{ individueelGebruik: IndividueelGebruik }>());
export const setKlassendashboardSamenvatting = createAction('[Klassendashboard] Set klassendashboard samenvatting', props<{ gezamenlijkGebruik: GezamenlijkGebruik }>());
export const fetchDocenten = createAction('[Klassendashboard] Fetch docentnamen');
export const setDocenten = createAction('[Klassendashboard] Set docentnamen', props<{ namen: string[] }>());
export const fetchLeerlingen = createAction('[Klassendashboard] Fetch leerlingnamen');
export const setLeerlingen = createAction('[Klassendashboard] Set leerlingnamen', props<Payload<{ uuid: string, naam: string }[]>>());
export const resetKlassendashboard = createAction('[Klassendashboard] Reset klassendashboard');
