import {Injectable} from '@angular/core';
import {BaseFacade} from '../../../state/base.facade';
import {Observable} from 'rxjs';
import {
  selectAantalLesgroepenMetStudiewijzerlinks, selectSelectedSWItem, selectStudiewijzerlinkjesDetailsPerPlatform,
  selectStudiewijzerLinkjesPerPlatform, selectVestigingBevatStudiewijzers,
  SwLinkjesPerPlatformOfVakModel
} from './studiewijzerlinkjes.selectors';
import {AppState, selectSchooldashboard} from '../../../state/app.state';
import {map} from 'rxjs/operators';
import {PlatformVak, SwLinkjesGebruikDetails, SwLinkjesPerPlatformOfVak} from '../../../../generated/graphql';
import {
  closeSelectedSWItem,
  setGroeperenOpPlatform,
  setSelectedSWItem,
  setSelectedSWItemDetail
} from '../schooldashboard/schooldashboard.actions';
import {Store} from '@ngrx/store';

@Injectable()
export class StudiewijzerlinkjesFacade extends BaseFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }

  selectStudiewijzerLinkjesPerPlatform(): Observable<SwLinkjesPerPlatformOfVakModel[]> {
    return this.select(selectStudiewijzerLinkjesPerPlatform);
  }

  selectAantalLesgroepenMetStudiewijzerlinks(): Observable<number> {
    return this.select(selectAantalLesgroepenMetStudiewijzerlinks);
  }

  selectIsGroeperenOpPlatform(): Observable<boolean> {
    return this.select(selectSchooldashboard).pipe(map(v => v.groeperenOpPlatform));
  }

  selectSelectedSWItem(): Observable<SwLinkjesPerPlatformOfVak> {
    return this.select(selectSelectedSWItem);
  }

  setSelectedSWItem(item: SwLinkjesPerPlatformOfVak) {
    this.dispatch(setSelectedSWItem, item);
  }

  closeSelectedSWItem() {
    this.dispatch0(closeSelectedSWItem);
  }

  selectStudiewijzerlinkjesDetailsPerPlatform(): Observable<SwLinkjesGebruikDetails[]> {
    return this.select(selectStudiewijzerlinkjesDetailsPerPlatform);
  }

  setSelectedSWItemDetail(detail: SwLinkjesGebruikDetails) {
    this.dispatch(setSelectedSWItemDetail, detail);
  }

  setGroeperenOpPlatform(groeperenOpPlatform: boolean) {
    this.dispatch(setGroeperenOpPlatform, groeperenOpPlatform);
  }

  selectVestigingBevatStudiewijzers(): Observable<boolean> {
    return this.select(selectVestigingBevatStudiewijzers);
  }

  selectStudiewijzerLinkjesOverzichtLoading(): Observable<boolean> {
    return this.select(selectSchooldashboard).pipe(map(v => v.studiewijzerLinkjesOverzichtLoading));
  }

  selectCurrentSWItem(): Observable<SwLinkjesPerPlatformOfVak> {
    return this.select(selectSchooldashboard).pipe(map(v => v.selectedStudiewijzerLinkjesItem));
  }

  selectStudiewijzerLinkjesDetailLoading(): Observable<boolean> {
    return this.select(selectSchooldashboard).pipe(map(v => v.studiewijzerLinkjesDetailLoading));
  }

  selectStudiewijzerlinksPlatformVak(): Observable<PlatformVak> {
    return this.select(selectSchooldashboard).pipe(
      map(v => v.studiewijzerPlatformVak),
      map(v => v && ({...v, getallenPerOnderwijssoort: v.getallenPerOnderwijssoort && [...v.getallenPerOnderwijssoort]}))
    );
  }
}
