import {createAction, props} from '@ngrx/store';
import {Payload} from '../payload';
import { Datumbereik, Vestiging } from './dashboardsettings.state';

export const setVestigingen = createAction('[DashboardSettings] Set vestigingen', props<Payload<Vestiging[]>>());
export const fetchDatumbereiken = createAction('[DashboardSettings] Fetch datumbereiken', props<Payload<Vestiging>>());
export const dashboardSettingsLoaded = createAction('[DashboardSettings] Reload dashboards');
export const datumbereikenFetched = createAction('[DashboardSettings] Datumbereiken Fetched', props<Payload<Datumbereik[]>>());
export const datumbereikChanged = createAction('[DashboardSettings] Datumbereik changed');
export const vestigingChanged = createAction('[DashboardSettings] Vestiging changed', props<Payload<Vestiging>>());
