import {PrivacydashboardState} from './privacydashboard.state';
import {createReducer, on} from '@ngrx/store';
import {
  setKoppelpartijen,
  setGeselecteerdeKoppelpartij,
  setGeselecteerdeKoppelingen,
  setWebservices,
  setGeselecteerdeWebservice,
  setWebserviceAccountGebruik,
  setWebserviceWeekGebruik,
  removeGeselecteerdeWebservice,
  setGoBackURL,
  setVestigingen,
  semPartiesFetched,
  semPartyDetailsFetched,
  semPartyConsentUpdated
} from './privacydashboard.actions';
import {reset} from '../app.actions';
import {
  AVGExtendedKoppelpartij,
  AVGWritePermissie
} from '../../dto/avg-dashboard-model-classes';
import {environment} from '../../../environments/environment';

const initialState: PrivacydashboardState = {
  koppelpartijen: [],
  semParties: null,
  semPartyDetails: null,
  geselecteerdeKoppelpartij: { koppelpartij: null, koppelingen: null},
  webservices: null,
  geselecteerdeWebservice: null,
  goBackURL: 'privacydashboard',
  vestigingen: null,
};

export const privacydashboardReducer = createReducer(
    initialState,
    on(setKoppelpartijen, (state, {value: koppelpartijen}) => ({...state, koppelpartijen: koppelpartijen.map(k => addFakeFields(k))})),
    on(semPartiesFetched, (state, {semParties}) => ({...state, semParties})),
    on(semPartyDetailsFetched, (state, {semPartyDetails}) => ({...state, semPartyDetails})),
    on(semPartyConsentUpdated, (state, {consent}) => ({...state, semPartyDetails: {...state.semPartyDetails, consent}})),
    on(setGeselecteerdeKoppelingen, (state, {koppelingen}) => ({...state, geselecteerdeKoppelpartij : { koppelpartij: state.geselecteerdeKoppelpartij.koppelpartij, koppelingen }})),
    on(setGeselecteerdeKoppelpartij, (state, { koppelpartij}) => ({...state, geselecteerdeKoppelpartij : { koppelpartij: addFakeFields(koppelpartij) , koppelingen : state.geselecteerdeKoppelpartij.koppelingen}})),
    on(setVestigingen, (state, { value: vestigingen}) => ({...state, vestigingen })),
    on(setWebservices, (state, { value: webservices}) => ({...state, webservices: (webservices.map(ws => ((['STAGE', 'CUP'].includes(ws.service)) ? {...ws, service: (ws.service[0] + ws.service.slice(1).toLowerCase())} : ws)))})),
    on(setGeselecteerdeWebservice, (state, {webservice: ws} ) => ({...state, geselecteerdeWebservice: {...state.geselecteerdeWebservice, service: (['STAGE', 'CUP'].includes(ws.service) ? (ws.service[0] + ws.service.slice(1).toLowerCase()) : ws.service), accounts: ws.accounts}})),
    on(removeGeselecteerdeWebservice, (state) => ({...state, geselecteerdeWebservice: null})),
    on(setWebserviceAccountGebruik, (state, {value: wag}) => ({...state, geselecteerdeWebservice: {...state.geselecteerdeWebservice, accountGebruik: wag}})),
    on(setWebserviceWeekGebruik, (state, {value: wwg}) => ({...state, geselecteerdeWebservice: {...state.geselecteerdeWebservice, weekGebruik: {datapoints: wwg}}})),
    on(setGoBackURL, (state, { url }) => ({...state, goBackURL: url})),
    on(reset, _ => initialState)
);

function addFakeFields(koppelpartij: AVGExtendedKoppelpartij): AVGExtendedKoppelpartij {
  switch (koppelpartij?.uuid) {
    case environment.bureauICEKoppelpartijUUID:
      return addJijToetsenField(koppelpartij);
    default:
      return koppelpartij;
  }
}

function addJijToetsenField(koppelpartij: AVGExtendedKoppelpartij): AVGExtendedKoppelpartij {
    const jijToetsenPermissie: AVGWritePermissie = {
      endpoint: 'Resultaten',
      naam: 'JIJ! toetsen',
      index: null
    };

    const writePermissies = (koppelpartij?.writePermissies) ? [...koppelpartij.writePermissies, jijToetsenPermissie] : [jijToetsenPermissie];

    return {...koppelpartij, writePermissies};
}
