
<div class="header">
    <p class="bodyContentSemi">Leerjaar</p>
    <div class="selectAll">
        <i [class]="isAllSelected ? 'checked' : 'unchecked'" (click)="setSelectAlleLeerjaren(!isAllSelected)"></i>
        <p class="bodyContent">Alles</p>
    </div>
</div>
<div class="leerjaren">
    <ng-container *ngFor="let leerjaar of viewModel.onGetLeerjaren | async;">
        <div class="leerjaarButton" [className]="leerjaarButtonStyle(leerjaar)" (click)="select(leerjaar)">
            <p class="bodySmallContentSemi">{{leerjaar.leerjaar}}</p>
        </div>
    </ng-container>
</div>
