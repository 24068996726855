import {ChangeDetectionStrategy, Component} from '@angular/core';
import {TableCellComponent} from '../table/table.model';
import {RightAlignedCenterCellModel} from './right-aligned-center-cell.model';

@Component({
  selector: 'app-right-aligned-center-cell',
  templateUrl: './right-aligned-center-cell.component.html',
  styleUrls: ['./right-aligned-center-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RightAlignedCenterCellComponent<T> implements TableCellComponent<RightAlignedCenterCellModel<T>> {
  data: RightAlignedCenterCellModel<T>;

  get width(): string {
    return this.data && this.data.width && `${this.data.width}rem`;
  }
}
