import {Injectable} from '@angular/core';
import {TemplatePortal} from "@angular/cdk/portal";
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PortalBridgeService {
  private activePortal = new Subject<TemplatePortal>();

  readonly portal$ = this.activePortal.asObservable();

  constructor() {
  }

  public setPortal(portal: TemplatePortal) {
    this.activePortal.next(portal);
  }
}
