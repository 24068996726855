import { createSelector } from '@ngrx/store';
import { sort } from 'src/app/stateless/string';
import { selectDashboardBackoffice } from '../app.state';

export const selectFeedback = createSelector(
    selectDashboardBackoffice,
    state => state.feedback
);

export const selectSchoolYears = createSelector(
    selectDashboardBackoffice,
    state => sort(state.schoolYears, sj => sj.name)
);

export const selectOrganizations = createSelector(
    selectDashboardBackoffice,
    state => state.organizations
);

export const selectSyncs = createSelector(
    selectDashboardBackoffice,
    state => state.syncs
);

export const selectSyncsCachedPages = createSelector(
    selectSyncs,
    state => collectKeys(state.pagedSyncs)
);

export const selectSyncsCurrentPage = createSelector(
    selectSyncs,
    state => state.pagedSyncs[state.currentPageIndex] === undefined ? [] : state.pagedSyncs[state.currentPageIndex]
);

export const selectSyncsCurrentPageNumber = createSelector(
    selectSyncs,
    state => state.currentPageIndex
);

export const selectSyncsPageSize = createSelector(
    selectSyncs,
    state => state.pageSize
);

export const selectSyncsCount = createSelector(
    selectSyncs,
    state => state.count
);

export const selectSyncsOrderBy = createSelector(
    selectSyncs,
    state => state.orderBy
);

export const selectSyncsDescending = createSelector(
    selectSyncs,
    state => state.descending
);

export const selectCurrentSyncsPage = createSelector(
    selectDashboardBackoffice,
    state => state.syncs.currentPageIndex
);

export const selectSyncsPages = createSelector(
    selectDashboardBackoffice,
    state => state.syncs.pagedSyncs.keys
);

export const selectSelectedTask = createSelector(
    selectDashboardBackoffice,
    state => state.selectedTask
)

export const selectSelectedTaskSchoolYears = createSelector(
    selectDashboardBackoffice,
    state => state.selectedTaskSchoolYears
);

export const selectSelectedTaskOrganizations = createSelector(
    selectDashboardBackoffice,
    state => state.selectedTaskOrganizations
);

export const selectSelectedTaskFailedOrgs = createSelector(
    selectDashboardBackoffice,
    state => state.selectedTaskFailedOrgs
);

export const selectSelectedTaskStepsLeft = createSelector(
    selectDashboardBackoffice,
    state => state.selectedTaskStepsLeft
);

export const selectSelectedTaskEvents = createSelector(
    selectDashboardBackoffice,
    state => state.selectedTaskEvents
);

export const selectSelectedTaskLatestEventId = createSelector(
    selectDashboardBackoffice,
    state => state.selectedTaskEventsLastId
);

function collectKeys(arr: any[]): number[] {
    if(arr === undefined || arr === null || arr.length === 0)
        return [];
    
    let keys: number[] = [];
    for(let key of arr.keys()) {
        keys.push(key);
    }

    return keys;
}
