import {createSelector} from '@ngrx/store';
import {selectSchooldashboard} from '../../../state/app.state';
import {Vestiging} from '../../../../generated/graphql';
import {payload, Payload} from '../../../state/payload';
import {
  selectGroeperenOpVak,
  selectQueryParam,
  selectQueryParams,
  selectUrl,
  selectVestigingParam,
  updateUrl
} from '../../../state/router/router.selectors';

export interface VestigingExt extends Vestiging {
  selected: boolean;
}

export const selectVestigingen = createSelector(
  selectSchooldashboard,
  selectVestigingParam,
  ({vestigingen}, current) => vestigingen.map((value, index) => ({
    ...value,
    selected: current !== undefined ? current === value.uuid : index === 0
  }))
);

export const selectHuidigeVestiging = createSelector(
  selectSchooldashboard,
  selectVestigingParam,
  ({vestigingen}, current) => {
    const [vestiging] = vestigingen.filter((v, i) => current !== undefined ? v.uuid === current : i === 0);
    return vestiging;
});

const createListParamSelector = (param: string) => createSelector(
  selectQueryParam(param),
  (v: string): string[] => {
    if (Array.isArray(v)) {
      return v;
    } else if (v !== undefined) {
      return [v];
    } else {
      return [];
    }
  }
);

export const selectOpenVakken = createListParamSelector('vak');

export const selectOpenOnderwijssoorten = createListParamSelector('ows');

export const selectUpdatedOpenVakkenOfOnderwijssoorten = createSelector(
  selectUrl,
  selectQueryParams,
  selectOpenVakken,
  selectOpenOnderwijssoorten,
  selectGroeperenOpVak,
  (url, queryParams, openVakken, openOnderwijssoorten, groeperenOpVak, vakOfOnderwijssoort: Payload<string>) => {
    let vak = openVakken;
    let ows = openOnderwijssoorten;
    if (groeperenOpVak) {
      vak = addOrRemove(vak, vakOfOnderwijssoort.value);
    } else {
      ows = addOrRemove(ows, vakOfOnderwijssoort.value);
    }
    return updateUrl(url, queryParams, payload({vak, ows}));
  }
);

function addOrRemove(list: string[], item: string): string[] {
  return list.includes(item) ? list.filter(v => v !== item) : [...list, item];
}

export const selectBazenbannerData =
  appState => appState.schooldashboard.berekendeBazenbannerModel;

export const schooldashboardVakData =
  appState => appState.schooldashboard.berekendeSchooldashboardModelVak;

export const schooldashboardOnderwijssoortData =
  appState => appState.schooldashboard.berekendeSchooldashboardModelOnderwijssoort;

export const dashboardDetails =
  appState => appState.schooldashboard.berekendeSchooldashboardDetailModel;

export const selectVestigingBevatLicenties = createSelector(
  selectHuidigeVestiging,
  v => v?.vanafdatumStudiewijzers !== null
);
