import {Injectable} from '@angular/core';
import {AuthConfig, OAuthEvent, OAuthService} from 'angular-oauth2-oidc';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {BehaviorSubject, Subject} from 'rxjs';
import { CookieStorage } from 'cookie-storage';


@Injectable({providedIn: 'root'})
export class OauthConfigurationService {
  constructor(private oauthService: OAuthService,
              private httpClient: HttpClient,
              private toastrService: ToastrService,
              private router: Router) {
  }

  private cookieStorage_ = new CookieStorage({path: '/'});

  private configLoaded_: Subject<boolean> = new BehaviorSubject<boolean>(false);

  public loadConfiguration(): Subject<boolean> {
      const authConfig = this.authConfig;
      const usedStorage = this.isCookieStorageInit() ? this.cookieStorage_ : sessionStorage;
      this.oauthService.setStorage(usedStorage);
      this.oauthService.configure(authConfig);
      this.oauthService.setupAutomaticSilentRefresh();
      this.oauthService.events.pipe(filter((event: OAuthEvent) => event.type === 'logout')).subscribe({
        next: (_: OAuthEvent) => this.router.navigateByUrl('/')
      });
      this.configLoaded_.next(true);
      return this.configLoaded;
  }

  get authConfig(): AuthConfig {
    const authConfig: AuthConfig = {
      showDebugInformation: true,
      clockSkewInSec: 600,
      issuer: environment.idpIssuer,
      jwks: environment.validationJwks,
      loginUrl: `${environment.idpUrl}/oauth2/authorize`,
      tokenEndpoint: `${environment.idpUrl}/oauth2/token`,
      redirectUri: environment.ourRedirectUrl,
      logoutUrl: `${environment.idpUrl}/oauth2/logout`,
      clientId: environment.idpClientId,
      requestAccessToken: true,
      scope: 'openid',
      responseType: 'code', // responseType: 'token id_token', // voor implicit flow
      customTokenParameters: ['somtoday_tenant', 'somtoday_api_url'],
      disableAtHashCheck: true,
      customQueryParams: { claims: '{"id_token":{"given_name":null, "vestigingen":null, "scauth":null, "affiliation":null}}'}
    };
    return authConfig;
  }

  get configLoaded(): Subject<boolean> {
    return this.configLoaded_;
  }

  public isMedewerker(): boolean {
    const claims: any = this.oauthService.getIdentityClaims();
    const affiliation: string = claims.affiliation;
    return  affiliation === 'employee';
  }

  public isCookieStorageInit(): boolean {
    return this.cookieStorage_.getItem('sc_init_key_e0b9b7a8cd3f') === 'broken_session_storage';
  }

  public resetCookieStore() {
    this.cookieStorage_.clear();
  }

  public useCookieStorageAndRetryLogin() {
    this.cookieStorage_.setItem('sc_init_key_e0b9b7a8cd3f', 'broken_session_storage');
    this.oauthService.setStorage(this.cookieStorage_);
    this.oauthService.configure(this.authConfig);
    this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService.events.pipe(filter((event: OAuthEvent) => event.type === 'logout')).subscribe({
      next: (_: OAuthEvent) => this.router.navigateByUrl('/')
    });
    this.configLoaded_.next(true);
    this.oauthService.initCodeFlow();
  }
}
