import {createSelector} from '@ngrx/store';
import {selectSchooldashboard} from '../../../state/app.state';
import {selectHuidigePeriode, selectVanafTotenmet} from '../../../state/app.selectors';
import {Payload} from '../../../state/payload';
import {createLesgroepDetailData, LesgroepDetailData} from '../../klassendashboard/klassendashboard.model';
import {compareLesgroep} from '../../../services/lesgroep.comparator';
import {selectQueryParam, selectRouteParam} from '../../../state/router/router.selectors';
import {CollegaLesgroep, LesgroepDetailDashboardFilterInput} from '../../../../generated/graphql';
import {selectLeerlingenMap} from '../leerlingen/leerlingen.selector';
import { selectLesgroepMedewerkers } from '../vakdashboard/vakdashboard.selectors';

export const selectLesgroepDetailFilter = createSelector(
  selectVanafTotenmet,
  selectRouteParam('lesgroepuuid'),
  ([vanaf, totEnMet], lesgroepUUID, _: Payload<Date>): LesgroepDetailDashboardFilterInput =>
    ({vanaf, totEnMet, lesgroepUUID})
);

const selectKlasdataPerLeerling = createSelector(
  selectSchooldashboard,
  selectHuidigePeriode,
  ({klasData}, periode, {value: datum}: Payload<Date>) =>
    klasData && createLesgroepDetailData(klasData, periode, datum)
);

export const selectKlasdataPerLeerlingMetNaam = createSelector(
  selectKlasdataPerLeerling,
  selectLeerlingenMap,
  (data, leerlingen, _): LesgroepDetailData => data && ({
    ...data,
    leerlingData: data.leerlingData.map(v => ({...v, leerling: leerlingen.get(v.leerlingUUID)}))
  })
);

export const selectLeerlingUUIDs = createSelector(
  selectKlasdataPerLeerling,
  klasData =>
    (klasData?.leerlingData || [])
      .map(v => v.leerlingUUID)
      .filter(Boolean)
      .reduce((a, v) => a.includes(v) ? a : [...a, v], [] as string[])
);

const selectSortedLesgroepen = createSelector(
  selectSchooldashboard,
  ({klasData}) =>
    [...(klasData?.collegaLesgroepen || [])].sort(compareLesgroep(l => l.leerjaar, l => l.lesgroepNaam))
);

export interface VorigeEnVolgende {
  vorige?: string;
  volgende?: string;
}

export const selectVorigeEnVolgendeLesgroep = createSelector(
  selectSortedLesgroepen,
  selectRouteParam('lesgroepuuid'),
  (lesgroepen, uuid): VorigeEnVolgende => {
    const index = lesgroepen.findIndex(v => v.lesgroepUUID === uuid);
    let vorige: CollegaLesgroep;
    let volgende: CollegaLesgroep;

    if (index === -1 || lesgroepen.length === 1) {
      return {};
    } else if (index === 0) {
      vorige = lesgroepen[lesgroepen.length - 1];
      volgende = lesgroepen[1];
    } else if (index === lesgroepen.length - 1) {
      vorige = lesgroepen[index - 1];
      volgende = lesgroepen[0];
    } else {
      vorige = lesgroepen[index - 1];
      volgende = lesgroepen[index + 1];
    }

    return {vorige: vorige.lesgroepUUID, volgende: volgende.lesgroepUUID};
  }
);

export const selectSelectie = createSelector(
  selectQueryParam('selectie'),
  v => v || 'individueel'
);

export const selectIsSamenvattingSelected = createSelector(
  selectSelectie,
  v => v === 'samenvatting'
);

export interface LesgroepLeermiddelGebruikGrafiekData {
  datum: Date;
  leermiddel: string;
  gebruikspercentage: number;
}

export interface LesgroepDetails {
  naam: string;
  aantalLeerlingen: number;
  docenten: string[];
}

export interface LesgroepLeermiddelGebruikTabelData {
  methodenaam: string;
  regels: LesgroepLeermiddelGebruikTabelRegel[];
  totaalActivatie: number;
  totaalRecentActief: number;
  totaalGebruikPerWeek: number;
}

export interface LesgroepLeermiddelGebruikTabelRegel {
  lesgroepNaam: string;
  lesgroepActivatie: number;
  lesgroepRecentActief: number;
  lesgroepGebruikPerWeek: number;
}

export const selectSamenvattingDetails = createSelector(
  selectSchooldashboard,
  ({klasSamenvatting: data}): LesgroepDetails => data && ({
    naam: data.lesgroepNaam,
    aantalLeerlingen: data.aantalLeerlingen,
    docenten: data.docentUUIDs.map(_ => 'Docent')
  })
);

export const selectGrafiekData = createSelector(
  selectSchooldashboard,
  ({klasSamenvatting: data}): LesgroepLeermiddelGebruikGrafiekData[] => data?.grafiekDetails.map(backendGrafiekdata => ({
      datum: new Date(backendGrafiekdata.datum),
      leermiddel: backendGrafiekdata.leermiddelnaam,
      gebruikspercentage: backendGrafiekdata.gebruiksPercentage
    })
  ) || []
);

export const selectTabelData = createSelector(
  selectSchooldashboard,
  ({klasSamenvatting: data}) => data?.detailsPerLeermiddel.map(backendTabeldata => ({
      methodenaam: backendTabeldata.leermiddelNaam,
      regels: [{
        lesgroepNaam: data.lesgroepNaam,
        lesgroepActivatie: backendTabeldata.activatiePercentage,
        lesgroepRecentActief: backendTabeldata.waarvanRecentGebruiktPercentage,
        lesgroepGebruikPerWeek: backendTabeldata.gebruikPerWeek
      }],
      totaalActivatie: backendTabeldata.totaalActivatiePercentage,
      totaalRecentActief: backendTabeldata.totaalWaarvanRecentGebruiktPercentage,
      totaalGebruikPerWeek: backendTabeldata.totaalGebruikPerWeek
    })
  ) || []
);

export const selectMedewerkersVanKlas = createSelector(
  selectSchooldashboard,
  ({klasSamenvatting, medewerkers}) => klasSamenvatting?.docentUUIDs.filter(v => !medewerkers.some(({UUID}) => v === UUID)) || []
);

export const selectRMedewerkersVanKlas = createSelector(
  selectLesgroepMedewerkers,
  selectRouteParam('lesgroepuuid'),
  (mwks, lesgroep) => mwks.get(lesgroep)
);
